(function () {
	var brandName = window.BRAND_NAME;
	var allLocs = [
		{
			"value": "medical",
			"prettyValue": "Medical"
		},
		{
			"value": "dental",
			"prettyValue": "Dental"
		},
		{
			"value": "vision",
			"prettyValue": "Vision"
		},
		{
			"value": "life",
			"prettyValue": "Life"
		},
		{
			"value": "lifenew",
			"prettyValue": "Life"
		},
		{
			"value": "std",
			"prettyValue": "Std"
		},
		{
			"value": "ltd",
			"prettyValue": "Ltd"
		},
		{
			"value": "add",
			"prettyValue": "ADD"
		},
		{
			"value": "acc",
			"prettyValue": "Accident"
		},
		{
			"value": "cancer",
			"prettyValue": "Cancer"
		},
		{
			"value": "ci",
			"prettyValue": "Critical Illness"
		},
		{
			"value": "hi",
			"prettyValue": "Hospital Indemnity"
		},
		{
			"value": "life_ba2",
			"prettyValue": "Life Basic"
		},
		{
			"value": "basic_life",
			"prettyValue": "Life Basic"
		},
		{
			"value": "vol_life",
			"prettyValue": "Life Voluntary"
		},
		{
			"value": "basic_adnd",
			"prettyValue": "Basic ADND"
		},
		{
			"value": "vol_adnd",
			"prettyValue": "Voluntary ADND"
		},
		{
			"value": "basic_std",
			"prettyValue": "Basic Std"
		},
		{
			"value": "vol_std",
			"prettyValue": "Voluntary Std"
		},
		{
			"value": "basic_ltd",
			"prettyValue": "Basic Ltd"
		},
		{
			"value": "vol_ltd",
			"prettyValue": "Voluntary Ltd"
		},
		{
			"value": "acc_new",
			"prettyValue": "Accident"
		},
		{
			"value": "cancer_new",
			"prettyValue": "Cancer"
		},
		{
			"value": "ci_new",
			"prettyValue": "Critical Illness"
		},
		{
			"value": "hi_new",
			"prettyValue": "Hospital Indemnity"
		},
		{
			"value": "telemed",
			"prettyValue": "Telemedicine"
		},
		{
			"value": "pet",
			"prettyValue": "Pet"
		},
		{
			"value": "cat",
			"prettyValue": "Cat"
		},
		{
			"value": "dog",
			"prettyValue": "Dog"
		},
		{
			"value": "legal",
			"prettyValue": "Legal"
		},
		{
			"value": "fertility",
			"prettyValue": "Fertility"
		},
		{
			"value": "id_theft",
			"prettyValue": "Identity Theft"
		},
		{
			"value": "auto_home",
			"prettyValue": "Auto Home"
		},
		{
			"value": "wellness",
			"prettyValue": "Wellness"
		},
		{
			"value": "discount",
			"prettyValue": "Discount Purchasing"
		},
		{
			"value": "travel",
			"prettyValue": "Travel"
		},
		{
			"value": "ltc",
			"prettyValue": "Long Term Care"
		},
		{
			"value": "supp_med",
			"prettyValue": "Supplemental Medical"
		},
		{
			"value": "liability",
			"prettyValue": "Liability"
		},
		{
			"value": "cyber",
			"prettyValue": "Cyber"
		},
		{
			"value": "stu_loan",
			"prettyValue": "Student Loan"
		},
		{
			"value": "fsa_ext",
			"prettyValue": "FSA External"
		},
		{
			"value": "hsa_ext",
			"prettyValue": "HSA External"
		},
		{
			"value": "hra_ext",
			"prettyValue": "HRA External"
		},
		{
			"value": "comm_ext",
			"prettyValue": "Commuter Benefits"
		},
		{
			"value": "ee_assist",
			"prettyValue": "Employee Assistance"
		},
		{
			"value": "mental",
			"prettyValue": "Mental"
		},
		{
			"value": "dcfsa_ext",
			"prettyValue": "Dependent Care FSA External"
		},
		{
			"value": "lpfsa_ext",
			"prettyValue": "Limited Use FSA External"
		}
	];
	// Models
	var attr = DS.attr;

	App.Store = App.Store || Ember.Model.Store.extend();

	var _PermissionsAbstractModel;
	_PermissionsAbstractModel = App._PermissionsAbstractModel = DS.Model.extend({
		permissions: attr('raw'),
		permission: attr('raw'),
		hasPerm: function (name) {
			var permissions = this.get('permissions');
			if (!permissions || !permissions.length) {
				return false;
			}
			return permissions.indexOf(name) >= 0;
		},
		canCompleteI9: Ember.computed.oneWay('permission.canCompleteI9'),
		canViewSubordinatesComp: Ember.computed.oneWay('permission.canViewSubordinatesComp'),
		canAccessSensitive: Ember.computed.not('permission.restrictSensitive'),
		canAccessSubordinate: Ember.computed.oneWay('permission.canViewSubordinates'),
		canAddRemoveAdmins: Ember.computed.oneWay('permission.canAddRemoveAdmins'),
		canOnlyAccessContractors: Ember.computed.oneWay('permission.contractorOnly'),
		canAccessStockOption: Ember.computed.oneWay('permission.canAccessStockOption'),
		canAccessITProvisioning: Ember.computed.oneWay('permission.canAccessItProvision'),
		isAdminDepartment: Ember.computed.oneWay('permission.canViewDepartmentEmployees'),
		isAdminLocation: Ember.computed.oneWay('permission.canViewLocationEmployees'),
		isAdminContractors: Ember.computed.oneWay('permission.contractorOnly'),
		isReadOnly: Ember.computed.oneWay('permission.readOnly'),
		notReadOnly: Ember.computed.not('isReadOnly'),
		isAdmin: Ember.computed.oneWay('permission.isAdmin'),
		isManager: Ember.computed.oneWay('permission.canViewSubordinates'),
		isAdminAll: Ember.computed.oneWay('permission.canViewEveryone'),
		isNotAdminAll: Ember.computed.not('isAdminAll'),
		isReadOnlyOrRestrictSensitiveAdmin: Ember.computed.or('isReadOnly', 'permission.restrictSensitive'),
		isAdminOrManager: Ember.computed.or('isAdmin', 'isManager'),
		isFullAdminOrManager: Ember.computed.or('isAdminWithWriteAccess', 'isManager'),
		canAccessContractors: Ember.computed.or('isAdmin', 'canOnlyAccessContractors'),
		canAccessContractorsWithWriteAccess: Ember.computed.or('isAdminWithWriteAccess', 'canOnlyAccessContractors'),
		notReadOnlyAndCanAccessSensitiveAdmin: Ember.computed.and('notReadOnly', 'canAccessSensitive'),
		isAdminWithWriteAccess: Ember.computed.and('notReadOnly', 'isAdmin'),
		isAddRemoveAdminsDisabled: Ember.computed.or('isNotAdminAll', 'isReadOnly', 'permission.restrictSensitive'),
		managerAndNotAdminWithHirePermissions: function () {
			return this.get('isManager') && (!this.get('isAdmin') || this.get('isReadOnly'));
		}.property('isManager', 'isAdmin', 'isReadOnly'),
		isZenefitsPayrollSignatory: Ember.computed.oneWay('permission.isZenefitsPayrollSignatory'),
		canEditManagerProduct: function () {
			return this.hasPerm('edit_manager_product');
		}.property('permissions'),
	});

	App.EmployeePermission = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		isMainAdmin: attr('boolean'),
		isAdmin: Ember.computed.or('canViewEveryone', 'canViewLocationEmployees', 'canViewDepartmentEmployees'),
		canViewSubordinates: attr('boolean'),
		canViewSubordinatesComp: attr('boolean'),
		canViewSubordinatesEquity: attr('boolean'),
		canViewEveryone: attr('boolean'),
		canViewDepartmentEmployees: attr('boolean'),
		canViewLocationEmployees: attr('boolean'),
		contractorOnly: attr('boolean'),
		readOnly: attr('boolean'),
		restrictSensitive: attr('boolean'),
		canAccessStockOption: attr('boolean'),
		canAccessItProvision: attr('boolean'),
		canAccessTK: attr('boolean'),
		canAddRemoveAdmins: attr('boolean'),
		isZenefitsPayrollSignatory: attr('boolean'),
		canCompleteI9: attr('boolean'),
		canOnlyAccessContractors: Ember.computed.oneWay('contractorOnly'),
		isAddRemoveAdminsDisabled: function () {
			return !this.get('canViewEveryone') || this.get('readOnly') || this.get('restrictSensitive');
		}.property('canViewEveryone', 'readOnly', 'restrictSensitive'),
	});

	App.ManagerEmployeePermission = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		isMainAdmin: attr('boolean')
	});


	// Growth
	App.ProductInterest = DS.Model.extend({
		companyId: attr('number'),
		payroll: attr('boolean'),
		hr: attr('boolean'),
		benefits: attr('boolean'),
		compliance: attr('boolean'),
		integrations: attr('boolean'),
		time: attr('boolean'),
		createdAt: attr('string'),
	})

	App.CompanyEnrollmentApi = _PermissionsAbstractModel.extend({
		additionalCompanyMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		additionalCompanyMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyDentalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyVisionCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		additionalPreviousMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		previousMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		previousDentalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		previousVisionCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		additionalCompanyMedicalHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyMedicalHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyDentalHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyVisionHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		additionalCompanyMedicalPlans: DS.hasMany('App.CPlan'),
		companyMedicalPlans: DS.hasMany('App.CPlan'),
		companyDentalPlans: DS.hasMany('App.CDentalPlan'),
		companyVisionPlans: DS.hasMany('App.CVisionPlan'),
		additionalMedicalPlans: DS.hasMany('App.Plan'),
		medicalPlans: DS.hasMany('App.Plan'),
		dentalPlans: DS.hasMany('App.DentalPlan'),
		visionPlans: DS.hasMany('App.VisionPlan'),
		lastCoverageStartDate: attr('date'),
		lastLineOfCoverage: attr('string'),
		lastPlan: attr('string')
	}).reopenClass({
		noBatch: true
	});


	App.CompanyEnrollmentReviewApi = _PermissionsAbstractModel.extend({
		companyMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyDentalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyVisionCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyMedicalHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyDentalHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyVisionHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		companyMedicalPlans: DS.hasMany('App.CPlan'),
		companyDentalPlans: DS.hasMany('App.CDentalPlan'),
		companyVisionPlans: DS.hasMany('App.CVisionPlan')
	}).reopenClass({
		noBatch: true
	});

	App.CompanyEnrollmentFinishEditApi = _PermissionsAbstractModel.extend({
		companyMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyLifenewEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyAddEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyStdEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyLtdEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyDentalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyVisionCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyLifenewCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyAddCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyStdCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyLtdCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
	}).reopenClass({
		noBatch: true
	});

	App.CompanyEnrollmentReviewfinishApi = _PermissionsAbstractModel.extend({
		companyMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyMedicalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyDentalCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyVisionCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
	}).reopenClass({
		noBatch: true
	});


	App.CompanyEnrollmentSettingsApi = _PermissionsAbstractModel.extend({
		companyMedicalEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyDentalEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyVisionEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyLifeEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyAdndEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyStdEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyLtdEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		companyMedicalCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyDentalCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyVisionCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyLifeCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyAdndCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyStdCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		companyLtdCarriers: DS.hasMany('App.CompanyHealthCarrier'),
	}).reopenClass({
		noBatch: true
	});


	App.CompanyBorEnrollmentCarriersApi = _PermissionsAbstractModel.extend({
		companyHealthCarriers: DS.hasMany('App.CompanyHealthCarrier')
	}).reopenClass({
		noBatch: true
	});


	App.BenefitsInterest = DS.Model.extend({
		companyId: attr('number'),
		currentlyOffered: attr('boolean'),
		medical: attr('boolean'),
		dental: attr('boolean'),
		vision: attr('boolean'),
		life: attr('boolean'),
		shortTerm: attr('boolean'),
		longTerm: attr('boolean'),
		hsa: attr('boolean'),
		fsa: attr('boolean'),
		createdAt: attr('string'),
	});


	App.CompanyEnrollmentBorApi = _PermissionsAbstractModel.extend({
		borMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borPreviousMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borPreviousDentalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borPreviousVisionEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borAdditionalMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borPreviousAdditionalMedicalEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borLifeEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borStDisabilityEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borLtDisabilityEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		borMedicalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borPreviousMedicalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borDentalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borPreviousDentalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borVisionHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borPreviousVisionHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borAdditionalMedicalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borPreviousAdditionalMedicalHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borLifeHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borStDisabilityHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		borLtDisabilityHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),

		medicalCarrierLocked: attr('boolean', { defaultValue: false }),
		additionalMedicalCarrierLocked: attr('boolean', { defaultValue: false }),
		dentalCarrierLocked: attr('boolean', { defaultValue: false }),
		visionCarrierLocked: attr('boolean', { defaultValue: false }),
	}).reopenClass({
		noBatch: true
	});

	App.BusinessInsuranceEnrollmentBorApi = _PermissionsAbstractModel.extend({
		borBusinessInsuranceEnrollments: DS.hasMany('App.BusinessInsuranceEnrollment'),
		borBusinessInsuranceCarriers: DS.hasMany('App.BusinessInsuranceCarrier'),
	}).reopenClass({
		noBatch: true
	});


	App.CarrierAndEmployerCredentialsApi = _PermissionsAbstractModel.extend({
		//Admin credentials
		medicalAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		additionalMedicalAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		dentalAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		visionAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		stdAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		ltdAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		lifeAdminCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		//Broker Credential
		medicalBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		additionalMedicalBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		dentalBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		visionBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		stdBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		ltdBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		lifeBrokerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		//Producer Credential
		medicalProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		additionalMedicalProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		dentalProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		visionProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		stdProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		ltdProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		lifeProducerCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		//SecureMsg Credential
		medicalSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		additionalMedicalSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		visionSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		dentalSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		stdSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		ltdSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
		lifeSecureCredential: DS.belongsTo('App.CarrierAndEmployerCredentialStat'),
	}).reopenClass({
		noBatch: true
	});

	App.StateCarrierApi = _PermissionsAbstractModel.extend({
		medicalCarriers: DS.hasMany('App.Carrier'),
		dentalCarriers: DS.hasMany('App.Carrier'),
		visionCarriers: DS.hasMany('App.Carrier'),
		lifeCarriers: DS.hasMany('App.Carrier'),
		disabilityCarriers: DS.hasMany('App.Carrier'),
	}).reopenClass({
		noBatch: true
	});


	App.BorDocumentStats = DS.Model.extend({
		medicalDocuments: DS.hasMany('App.Document'),
		additionalMedicalDocuments: DS.hasMany('App.Document'),
		dentalDocuments: DS.hasMany('App.Document'),
		visionDocuments: DS.hasMany('App.Document'),

		documentsIdToPlanLabel: DS.attr('raw'),
		medicalDocumentsToChpId: DS.attr('raw'),
		additionalMedicalDocumentsToChpId: DS.attr('raw'),
		dentalDocumentsToChpId: DS.attr('raw'),
		visionDocumentsToChpId: DS.attr('raw'),

		medicalDocumentsCompleted: DS.attr('boolean'),
		additionalMedicalDocumentsCompleted: DS.attr('boolean'),
		dentalDocumentsCompleted: DS.attr('boolean'),
		visionDocumentsCompleted: DS.attr('boolean'),

		medicalDocumentsSBCLabels: DS.attr('raw'),
		additionalMedicalDocumentsSBCLabels: DS.attr('raw'),
		dentalDocumentsSBCLabels: DS.attr('raw'),
		visionDocumentsSBCLabels: DS.attr('raw'),
	});


	App.CompanyBusinessCarriersByLineOfCoverage = DS.Model.extend({
		lineOfCoverage: attr('string'),
		displayName: attr('string'),
		carriers: DS.hasMany('App.Carrier'),
		company: DS.belongsTo('App.Company'),
	});

	App.CplansApi = _PermissionsAbstractModel.extend({
		cplans: DS.hasMany('App.CPlan'),
	}).reopenClass({
		noBatch: true
	});

	App.CompanyPlanImpactReportApi = DS.Model.extend({
		companyID: Ember.attr('number'),
		employeeID: Ember.attr('number'),
		groupName: Ember.attr('string'),
		employeeFirstName: Ember.attr('string'),
		employeeLastName: Ember.attr('string'),
		employeeEmail: Ember.attr('string'),
		adminName: Ember.attr('string'),
		adminEmail: Ember.attr('string'),
		employeeURL: Ember.attr('string'),
		companyURL: Ember.attr('string'),
		payrollSync: Ember.attr('string'),
		accountOwner: Ember.attr('string'),
		lineOfCoverage: Ember.attr('string'),
		planName: Ember.attr('string'),
		planURL: Ember.attr('string'),
		policyID: Ember.attr('string'),
		enrollmentType: Ember.attr('string'),
		contributionType: Ember.attr('string'),
		employeeContribution: Ember.attr('string'),
		dependentContribution: Ember.attr('string'),
		employeeTier: Ember.attr('string'),
		effectiveDate: Ember.attr('string'),
		employeeCost: Ember.attr('number'),
		dependentCost: Ember.attr('number'),
		totalCost: Ember.attr('number'),
		employerContribution: Ember.attr('number'),
		deduction: Ember.attr('number'),
		expectedEmployeeCost: Ember.attr('number'),
		expectedDependentCost: Ember.attr('number'),
		totalExpectedCost: Ember.attr('number'),
		expectedEmployerContribution: Ember.attr('number'),
		expectedDeduction: Ember.attr('number'),
		employeeCostMonthlyImpact: Ember.attr('number'),
		dependentCostMonthlyImpact: Ember.attr('number'),
		totalCostMonthlyImpact: Ember.attr('number'),
		numberOfMonths: Ember.attr('number'),
		numberOfPayPeriods: Ember.attr('number'),
		totalCostImpact: Ember.attr('number'),
		employerContributionImpactPerPayPeriod: Ember.attr('number'),
		employeeDeductionImpactPerPayPeriod: Ember.attr('number'),
		totalEmployeeDeductionImpact: Ember.attr('number'),
		isFailed: Ember.attr('boolean', { defaultValue: false }),
	}).reopenClass({
		noBatch: true
	});

	App.ConsoleUserTaskApi = DS.Model.extend({
		userId: Ember.attr('number'),
		userName: Ember.attr('string'),
		etaId: Ember.attr('number'),
		createdTime: Ember.attr('string'),
		taskName: Ember.attr('string'),
		fileName: Ember.attr('string'),
		fileUrl: Ember.attr('string'),
		startTime: Ember.attr('string'),
		finishTime: Ember.attr('string'),
		errorMessage: Ember.attr('string'),
		status: Ember.attr('string'),
		_formatDateTime: function (datetimeString) {
			var datetime = zen.parseISODateTime(datetimeString);
			if (!datetime) {
				return "";
			} else {
				return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
			}
		},

		createdTimeFormatted: function () {
			return this._formatDateTime(this.get('createdTime'));
		}.property('createdTime'),
		startTimeFormatted: function () {
			return this._formatDateTime(this.get('startTime'));
		}.property('startTime'),
		finishTimeFormatted: function () {
			return this._formatDateTime(this.get('finishTime'));
		}.property('finishTime'),

		pending: function () {
			return this.get('status') == 'submitted' || this.get('status') == 'queued';
		}.property('status'),
		running: function () {
			return this.get('status') == 'running';
		}.property('status'),
		completed: function () {
			return this.get('status') == 'succeeded';
		}.property('status'),
		failed: function () {
			return this.get('status') == 'failed';
		}.property('status'),

	});

	App.TaskUser = DS.Model.extend({
		first_name: Ember.attr('string'),
		last_name: Ember.attr('string'),
		email: Ember.attr('string'),
		full_name: function () {
			return (this.get('first_name') || "") + " " + (this.get('last_name') || "");
		}.property('first_name', 'last_name'),
		hasName: function () {
			return this.get('first_name') || this.get('last_name');
		}.property('first_name', 'last_name'),
		nameOrUsername: function () {
			if (this.get('hasName')) {
				return this.get('full_name');
			}
			return this.get('email');
		}.property('hasName', 'email', 'full_name'),
		nameOrUsernameWithTitle: function () {
			if (this.get('nameOrUsername')) {
				if (this.get('title')) {
					return this.get('nameOrUsername') + " (" + this.get('title') + ")";
				}
				return this.get('nameOrUsername') + " " + "(No Title)";
			}
			return "No name or username";
		}.property('nameOrUsername', 'title')
	});

	App.EmployeeLifeDisabilityEnrollmentApi = _PermissionsAbstractModel.extend({
		/******** Life *********/
		/* Current enrollment */
		companyLifeEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		lifePlans: DS.hasMany("App.LifePlanNew"),
		employeeLifeEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentLifeEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeLifePlans: DS.hasMany("App.EmployeeLifePlanNew"),
		dependentLifePlans: DS.hasMany("App.DependentLifePlan"),

		/* Previous enrollment */
		prevCompanyLifeEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevLifePlans: DS.hasMany("App.LifePlanNew"),
		prevEmployeeLifeEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentLifeEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeLifePlans: DS.hasMany("App.EmployeeLifePlanNew"),
		prevDependentLifePlans: DS.hasMany("App.DependentLifePlan"),

		/******** AD&D *******/
		/* Current enrollment */
		companyAddEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		addPlans: DS.hasMany("App.AddPlan"),
		employeeAddEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentAddEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeAddPlans: DS.hasMany("App.EmployeeAddPlan"),
		dependentAddPlans: DS.hasMany("App.DependentAddPlan"),

		/* Previous enrollment */
		prevCompanyAddEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevAddPlans: DS.hasMany("App.AddPlan"),
		prevEmployeeAddEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentAddEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeAddPlans: DS.hasMany("App.EmployeeAddPlan"),
		prevDependentAddPlans: DS.hasMany("App.DependentAddPlan"),

		/******** STD *********/
		/* Current enrollment */
		companyStdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		stdPlans: DS.hasMany("App.StdPlanNew"),
		employeeStdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentStdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeStdPlans: DS.hasMany("App.EmployeeStdPlanNew"),
		dependentStdPlans: DS.hasMany("App.DependentStdPlan"),

		/* Previous enrollment */
		prevCompanyStdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevStdPlans: DS.hasMany("App.StdPlanNew"),
		prevEmployeeStdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentStdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeStdPlans: DS.hasMany("App.EmployeeStdPlanNew"),
		prevDependentStdPlans: DS.hasMany("App.DependentStdPlan"),

		/******** LTD *********/
		/* Current enrollment */
		companyLtdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		ltdPlans: DS.hasMany("App.LtdPlanNew"),
		employeeLtdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentLtdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeLtdPlans: DS.hasMany("App.EmployeeLtdPlanNew"),
		dependentLtdPlans: DS.hasMany("App.DependentLtdPlan"),

		/* Previous enrollment */
		prevCompanyLtdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevLtdPlans: DS.hasMany("App.LtdPlanNew"),
		prevEmployeeLtdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentLtdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeLtdPlans: DS.hasMany("App.EmployeeLtdPlanNew"),
		prevDependentLtdPlans: DS.hasMany("App.DependentLtdPlan"),
	}).reopenClass({
		noBatch: true
	});


	App.CompanyLifeDisabilityEnrollmentApi = _PermissionsAbstractModel.extend({
		/******** Life *********/
		lifePlans: DS.hasMany("App.LifePlanNew"),
		lifeRestrictions: DS.hasMany("App.LifePlanRestriction"),
		lifeRates: DS.hasMany("App.LifePlanRate"),

		/******** AD&D *******/
		addPlans: DS.hasMany("App.AddPlan"),
		addRestrictions: DS.hasMany("App.AddPlanRestriction"),
		addRates: DS.hasMany("App.AddPlanRate"),

		/******** STD *******/
		stdPlans: DS.hasMany("App.StdPlanNew"),
		stdRestrictions: DS.hasMany("App.StdPlanRestriction"),
		stdRates: DS.hasMany("App.StdPlanRate"),

		/******** LTD *******/
		ltdPlans: DS.hasMany("App.LtdPlanNew"),
		ltdRestrictions: DS.hasMany("App.LtdPlanRestriction"),
		ltdRates: DS.hasMany("App.LtdPlanRate"),
	}).reopenClass({
		noBatch: true
	});

	App.CompanyPaySchedule = DS.Model.extend({
		company: Ember.belongsTo('App.Company'),
		smpRuns: DS.hasMany('App.SmpRun'),

		isMutable: Ember.attr('boolean'),
		payFrequency: Ember.attr('string'),
		payDayOfWeek: Ember.attr('number'),
		payDayOfMonth: Ember.attr('number'),
		secondPayDayOfMonth: Ember.attr('number'),
		unshiftedAnchorCheckDate: Ember.attr('string'),
		anchorStartDate: Ember.attr('string'),

		arrearsDays: Ember.attr('number'),
		arrearsDayType: Ember.attr('string', { defaultValue: 'C' }),
		holidayShift: Ember.attr('string'),
		saturdayShift: Ember.attr('string'),
		sundayShift: Ember.attr('string'),

		payPeriodEndDayOfMonth: Ember.attr('number'),
		payPeriodEndDayPayDayMonthDelta: Ember.attr('number'),

		secondArrearsDays: Ember.attr('number'),
		secondArrearsDayType: Ember.attr('string', { defaultValue: 'C' }),
		secondPayPeriodEndDayOfMonth: Ember.attr('number'),
		secondPayPeriodEndDayPayDayMonthDelta: Ember.attr('number'),
		secondHolidayShift: Ember.attr('string'),
		secondSaturdayShift: Ember.attr('string'),
		secondSundayShift: Ember.attr('string'),

		effectiveStartDate: Ember.attr('string'),
		effectiveEndDate: Ember.attr('string'),

		creationMethod: Ember.attr('string'),
		isContractorPaymentsCompany: Ember.attr('boolean'),
		status: Ember.attr('string'),
		checkDayPolicy: Ember.attr('string'),

		compType: Ember.attr('string'),
		name: Ember.attr('string'),

		root: Ember.belongsTo('App.CompanyPaySchedule'),
		descendants: DS.hasMany('App.CompanyPaySchedule'),

		// This is a computed @property on the backend
		shiftedAnchorCheckDate: Ember.attr('string'),

		isPending: Ember.computed.equal('status', 'REQUESTED'),
		// This overrides the deprecated isActive from back end
		isActive: Ember.computed.equal('status', 'ACTIVE'),

		// Defaults to the comp Type and falls back to frequency
		displayName: function () {
			return this.get('displayCompTypeName') ||
				this.get('displayFrequencyName');
		}.property('name', 'displayCompTypeName', 'displayFrequencyName'),

		expectedEffectiveEndDate: function () {
			var endDate = this.get('descendants').filterBy('isPending').filterBy('compType', this.get('compType'));
			return !Ember.isEmpty(endDate) ? moment(endDate.objectAt(0).get('effectiveStartDate')).subtract('day', 1).format('MM/DD/YYYY') : null;
		}.property('descendants.[]', 'descendants.@each.status', 'effectiveStartDate', 'compType'),

		displayCompTypeName: function () {
			var mapping = {
				H: 'Hourly and Non-exempt',
				S: 'Salaried Exempt',
				B: 'All types'
			};

			if (this.get('creationMethod') === 'SMP') {
				mapping = {
					H: 'Hourly',
					S: 'Salaried',
					B: 'Both Hourly and Salaried'
				};
			}

			if (this.get('isContractorPaymentsCompany')) {
				mapping = {
					H: 'Hourly',
					S: 'Fixed Amount',
					B: 'Both Hourly and Fixed Amount'
				};
			}

			return mapping[this.get('compType')];
		}.property('compType'),

		displayFrequencyName: Ember.attr('string'),

		displayFrequencyNameLowerCase: function () {
			var payFrequency = this.get('payFrequency');
			if (payFrequency == 'We' || payFrequency == 'BW') {
				var frequencyArray = this.get('displayFrequencyName').split(' ');
				var dayOfWeek = frequencyArray.pop();
				return frequencyArray.join(' ').toLowerCase() + ' ' + dayOfWeek;
			}
			return this.get('displayFrequencyName').toLowerCase();

		}.property('displayFrequencyName', 'payFrequency'),

		isComplete: function () {
			var payFrequency = this.get('payFrequency');
			if (payFrequency === 'We' && Ember.isEmpty(this.get('payDayOfWeek'))) {
				return false;
			} else if (payFrequency === 'BW' && Ember.isEmpty(this.get('payDayOfWeek'))) {
				return false;
			} else if (payFrequency === 'Mo' && !this.get('payDayOfMonth')) {
				return false;
			} else if (payFrequency === 'SM' && !(this.get('payDayOfMonth') && this.get('secondPayDayOfMonth'))) {
				return false;
			}
			if (!(!Ember.isEmpty(this.get('arrearsDays')) && this.get('unshiftedAnchorCheckDate') &&
				this.get('holidayShift') && this.get('saturdayShift') && this.get('sundayShift'))) {
				return false;
			}
			return true;
		}.property('payFrequency', 'payDayOfWeek', 'payDayOfMonth', 'secondPayDayOfMonth',
			'arrearsDays', 'holidayShift', 'saturdayShift', 'sundayShift',
			'unshiftedAnchorCheckDate'),

		isValid: function () {
			if (!this.get('isComplete')) {
				return false;
			}
			var payFrequency = this.get('payFrequency');

			var payDayOfWeek = this.get('payDayOfWeek');
			if ((payFrequency === 'We' || payFrequency === 'BW') &&
				!(payDayOfWeek >= 0 && payDayOfWeek <= 4)) {
				return false;
			}
			// Only Monthly can have negative arrears
			var hasNegativeArrears = this.get('arrearsDays') < 0;
			if (hasNegativeArrears && payFrequency !== 'Mo') {
				return false;
			}
			return true;
		}.property('isComplete', 'payFrequency', 'payDayOfWeek', 'arrearsDays'),

		hasHourly: Ember.computed.equal('compType', 'H'),
		hasSalaried: Ember.computed.equal('compType', 'S'),
		hasBoth: Ember.computed.equal('compType', 'B'),

		compTypeHuman: function () {
			return {
				H: 'Hourly',
				S: 'Salaried',
				B: 'Both'
			}[this.get('compType')];
		}.property('compType'),

		payFrequencyHuman: function () {
			switch (this.get('payFrequency')) {
				case 'SM': return "Semi-monthly";
				case 'BW': return "Bi-weekly";
				case 'Mo': return "Monthly";
				case 'We': return "Weekly";
			}
		}.property('payFrequency'),

		payFrequencyHumanLowercase: function () {
			var str = this.get('payFrequencyHuman') || '';
			return str.toLowerCase();
		}.property('payFrequencyHuman'),
	});

	App.User = _PermissionsAbstractModel.extend({
		is_active: attr('boolean', { defaultValue: true }),
		first_name: attr('string'),
		last_name: attr('string'),
		email: attr('string'),
		isRegistered: attr('boolean'),
		username: attr('string'),
		hasName: function () {
			return this.get('first_name') || this.get('last_name');
		}.property('first_name', 'last_name'),
		nameOrUsername: function () {
			if (this.get('hasName')) {
				return this.get('full_name');
			}
			return this.get('email');
		}.property('hasName', 'email', 'full_name'),
		full_name: function () {
			return (this.get('first_name') || "") + " " + (this.get('last_name') || "");
		}.property('first_name', 'last_name'),
		displayName: Ember.computed.alias('email'),
		changePermission: function (perm, value) {
			var permissions = this.get('permissions');
			if (permissions == undefined) {
				return;
			}
			var index = permissions.indexOf(perm);
			// don't edit in place as we need a new object to trigger the permissions property watchers
			if (value && index == -1) {
				permissions = permissions.concat(perm);
			}
			else if (!value && index >= 0) {
				permissions = permissions.filter(function (o) { return perm != o; });
			}
			this.set('permissions', permissions);
		},
		hasSupportCasesPermission: function () {
			if (this.get('is_superuser') || this.get('permissions').contains('request_support_cases_reports')) {
				return true;
			}
			return false;
		}.property('permissions'),
		hasRAPermission: function () {
			if (this.get('is_superuser') || this.get('permissions').contains('create_required_actions')) {
				return true;
			}
			return false;
		}.property('permissions'),

		hasRATemplatePermission: function () {
			if (this.get('is_superuser') || this.get('permissions').contains('create_required_actions_templates')) {
				return true;
			}
			return false;
		}.property('permissions'),
	});

	App.AdminOpenEnrollment = DS.Model.extend({
		isActive: attr('boolean'),
		status: attr('string'),
		premiumsMap: attr('string'),
	});

	App.ProposalPackage = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		adminProposal: DS.belongsTo('App.AdminProposal', { inverse: null }),
		packageType: attr('string'),
		carrierToPlansMap: attr('string'),
		plans: attr('string'),
		carriers: attr('string'),
		feedback: attr('string'),
		notes: attr('string'),
		packageLabel: attr('string'),
		ieCarriers: attr('raw'),
		oeCarriers: attr('raw'),
		swCarriers: attr('raw'),
		isFullyInsured: attr('boolean'),
	});

	App.NgeProposalPackage = DS.Model.extend({
		packageType: attr('string'),
		carrierToPlansMap: attr('string'),
		plans: attr('raw'),
		carriers: attr('raw'),
		lineOfCoverage: attr('string'),
		initialEnrollmentWizardNavId: attr('number'),
		isActive: attr('boolean'),
	});

	App.AdminProposal = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		lineOfCoverage: attr('string'),
		proposal: attr('string'),
		proposalPackages: DS.hasMany('App.ProposalPackage'),
		showCurrentPlans: attr('boolean'),
		showRenewalPlans: attr('boolean'),
		hiddenCarriers: attr('string'),
		carrierIds: attr('string'),
		selectedPackage: attr('string'), // deprecated in plan shopping
		selectedPackageId: attr('number'),
		packageUidMap: attr('string'),
		customPackage: attr('string'), // deprecated in plan shopping
		isProposalDeclined: attr('boolean'),
		subsidiariesWithSamePolicy: attr('string'),
		subsidiaryLinesOfCoverage: attr('string'),
		progress: attr('string'),
		effectiveDate: attr('string'),
		endDate: attr('string'),
		status: attr('string'),
		leaveOfAbsenceLengthMedical: attr('number'),
		leaveOfAbsenceLengthPersonal: attr('number'),
		waitingPeriod: attr('string'),
		waitingPeriodDisplayText: attr('string'),
		renewalPolicies: DS.hasMany('App.RenewalPolicy'),
		switchCancellationPolicy: attr('string'),

		achSignature: DS.belongsTo('App.Signature'),
		enrollmentSignature: DS.belongsTo('App.Signature'),
		switchCancellationSignature: DS.belongsTo('App.Signature'),
		waitingPeriodChangeSignature: DS.belongsTo('App.Signature'),

		progressJSON: function () {
			var progress = this.get('progress');
			return progress ? JSON.parse(progress) : {};
		}.property('progress'),
		plans: function () {
			var that = this;
			var plansPromises = [];
			var selectedPackage = JSON.parse(this.get('selectedPackage'));
			var planMapping = { medical: 'plan', dental: 'dentalPlan', vision: 'visionPlan' };

			if (selectedPackage && selectedPackage.get('length')) {
				selectedPackage.forEach(function (pkg) {
					if (planMapping[that.get('lineOfCoverage')] && pkg.renewal_plan) {
						plansPromises.push(DS.appStore.find(planMapping[that.get('lineOfCoverage')], pkg.renewal_plan));
					}
				});
			}

			return plansPromises;
		}.property('selectedPackage'),
	});

	App.RenewalPolicy = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier', { inverse: null }),
		proposal: DS.belongsTo('App.AdminProposal', { inverse: null }),
		contributionType: DS.attr('string'),
		contributionTypeDisplay: attr('string'),
		basePlan: DS.belongsTo('App.Plan', { inverse: null }),
		dentalBasePlan: DS.belongsTo('App.DentalPlan', { inverse: null }),
		contributionEmployee: DS.attr('number'),
		contributionDependents: DS.attr('number'),
		isCustom: DS.attr('boolean'),
		lineOfCoverage: DS.attr('string'),
	});

	App.DocumentRejectionReason = DS.Model.extend({
		reason: attr('string'),
		description: attr('string'),
		isDefault: attr('boolean'),
		documentApplicationType: attr('string'),
		documentRequestType: DS.belongsTo('App.DocumentRequestCopy'),
	});

	App.Document = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		agreement: DS.belongsTo('App.Agreement'),
		description: attr('string'),
		name: attr('string'),
		url: attr('string'),
		date: attr('string'),
		uploadTime: attr('string'),
		uploadedBy: DS.belongsTo('App.User'),
		reviewState: attr('string'),
		reviewTime: attr('string'),
		reviewedBy: attr('string'),
		reviewComment: attr('string'),
		mimetype: attr('string'),
		rejectionReasons: attr('rejectionReasons'),
		isActive: attr('isActive'),
	});

	App.UploadedDocumentTag = DS.Model.extend({
		name: attr('string'),
	});

	App.UploadedDocument = DS.Model.extend({
		name: attr('string'),
		file_id: attr('number'),
		tags: DS.hasMany('App.UploadedDocumentTag'),
		restricted_admin_can_access: attr('boolean'),
		manager_can_access: attr('boolean'),
		employee_can_access: attr('boolean'),
		is_deleted: attr('boolean')
	});

	App.CompanyDocument = DS.Model.extend({
		name: attr('string'),
		uploadedFile_id: attr('number'),
		companyId: attr('number'),
		is_deleted: attr('boolean'),
		url: attr('string')
	});

	App.Agreement = DS.Model.extend({
		document: DS.belongsTo('App.Document'),
		agreementType: attr('string'),
		typeIsCompanyMedical: Ember.computed.equal('agreementType', 'medical'),
		typeIsCompanyDental: Ember.computed.equal('agreementType', 'dental'),
		typeIsCompanyVision: Ember.computed.equal('agreementType', 'vision'),
		typeIsHealthEnrollment: Ember.computed.or('typeIsCompanyMedical', 'typeIsCompanyDental', 'typeIsCompanyVision')
	});

	App.AgreementChe = App.Agreement.extend({
		company: DS.belongsTo('App.Company'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		documentDescription: attr('string'),
	});


	App.AgreementEhe = App.Agreement.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		employeeHealthEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		displayName: function () {
			var result = this.get('agreementType');
			result += ' ' + this.get('employeeHealthEnrollment.medicalPlan.carrier');
			result += ' ' + this.get('employeeHealthEnrollment.dentalPlan.carrier');
			result += ' ' + this.get('employeeHealthEnrollment.visionPlan.carrier');
			result += ' ' + this.get('employeeHealthEnrollment.enrollmentCompleteDate');
			result += ' Agreement';
			return result;
		}.property(
			'agreementType',
			'employeeHealthEnrollment.medicalPlan.carrier',
			'employeeHealthEnrollment.dentalPlan.carrier',
			'employeeHealthEnrollment.visionPlan.carrier',
			'employeeHealthEnrollment.enrollmentCompleteDate'
		)
	});


	App.AgreementEmployeeCobra = App.Agreement.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		displayName: function () {
			var result = 'COBRA Coverage ';
			var agreementType = this.get('agreementType');
			if (agreementType === 'cobra_confirmation') {
				result += 'Confirmation';
			} else if (agreementType === 'cobra_cancellation') {
				result += 'Cancellation';
			} else {
				result += 'Unknown';
			}
			return result;
		}.property('agreementType')
	});


	App.AgreementCompanyFsa = App.Agreement.extend({
		company: DS.belongsTo('App.Company'),
		fsaCompanyEnrollment: DS.belongsTo('App.FsaCompanyEnrollment'),
		displayName: function () {
			return 'FSA ' + this.get('fsaCompanyEnrollment.companyEnrollmentProviderDisplay') + ' ' + this.get('fsaCompanyEnrollment.authDate') + ' Agreements'; // TODO: provider on console
		}.property('fsaCompanyEnrollment.companyEnrollmentProviderDisplay', 'fsaCompanyEnrollment.authDate')
	});


	App.AgreementEmployeeFsa = App.Agreement.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		fsaEmployeeEnrollment: DS.belongsTo('App.FsaEmployeeEnrollment'),
		displayName: function () {
			return 'FSA ' + this.get('fsaEmployeeEnrollment.companyEnrollmentProviderDisplay') + ' ' + this.get('fsaEmployeeEnrollment.date') + ' Agreements';
		}.property('fsaEmployeeEnrollment.companyEnrollmentProviderDisplay', 'fsaEmployeeEnrollment.date')
	});

	App.AgreementSection125 = App.Agreement.extend({
		company: DS.belongsTo('App.Company'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		effectiveDate: attr('string'),
		isActive: attr('boolean'),
		validThru: attr('string'),
		hasDocumentSigned: attr('boolean')
	});

	(function () {
		var companyPayrollAttribute = DS.Model.extend({
			company: DS.belongsTo('App.Company'),
			code: attr('string'),
			description: attr('string'),
			isActive: attr('boolean'),
		});

		App.CostCenters1 = companyPayrollAttribute.extend({});
		App.CostCenters2 = companyPayrollAttribute.extend({});
		App.CostCenters3 = companyPayrollAttribute.extend({});
		App.PositionCodes = companyPayrollAttribute.extend({});
	})();

	App.DocumentRequest = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		isActive: attr('boolean'),
		status: attr('string'),
		displayDocumentTypeIncludingDate: attr('string'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		description: attr('string'),
		documentType: attr('string'),
		documentObject: DS.belongsTo('App.Document'),
		documentReviewState: attr('string'),
		copy: DS.belongsTo('App.DocumentRequestCopy'),
		url: attr('string')
	});


	App.DocumentRequestCopy = DS.Model.extend({
		documentType: attr('string'),
		copy: attr('string'),
		helpCenterUrl: attr('string')
	});


	App.Company = DS.Model.extend({
		primaryBank: function () {
			return this.get('banks').filterProperty('isActive', true).findProperty('isPrimaryAccount', true);
		}.property('banks.@each.isPrimaryAccount', 'banks.@each.isActive'),
		customPlans: DS.hasMany('App.Plan', { inverse: null }),
		adminProposals: DS.hasMany('App.AdminProposal'),
		objId: attr('number'),
		version_id: attr('number'),
		name: attr('string'),
		companyInfo: DS.belongsTo('App.CompanyInfo'),
		isReal: attr('boolean'),
		adminOpenEnrollment: DS.belongsTo('App.AdminOpenEnrollment'),
		address: attr('string'),
		address2: attr('string'),
		addressHelper: function () {
			// duplicating logic from addressHelper in register_company/models.py
			if (!this.get('address')) {
				return '';
			}
			return this.get('address') + (this.get('address2') ? ' ' + this.get('address2') : '');
		}.property('address', 'address2'),
		bankName: Ember.computed.oneWay('primaryBank.bankName'),
		bankAccountNumber: Ember.computed.oneWay('primaryBank.bankAccountNumber'),
		bankRoutingNumber: Ember.computed.oneWay('primaryBank.bankRoutingNumber'),
		bankAccountType: Ember.computed.oneWay('primaryBank.bankAccountType'),
		city: attr('string'),
		state: attr('string'),
		stateName: attr('string'),
		zip: attr('string'),
		phone: attr('string'),
		ein: attr('string'),
		sicCode: attr('string'),
		businessDescription: attr('string'),
		isLargeGroup: attr('boolean'),
		benefitsOnly: attr('boolean'),
		benefitsPreview: DS.belongsTo('App.BenefitsPreview'),
		legalName: attr('string'),
		legalAddress: attr('string'),
		legalAddress2: attr('string'),
		legalAddressHelper: function () {
			// duplicating logic from legalAddressHelper in register_company/models.py
			if (!this.get('legalAddress')) {
				return '';
			}
			return this.get('legalAddress') + (this.get('legalAddress2') ? ' ' + this.get('legalAddress2') : '');
		}.property('legalAddress', 'legalAddress2'),
		legalCity: attr('string'),
		legalState: attr('string'),
		legalZip: attr('string'),
		allAgreements: DS.hasMany("App.AgreementChe"),
		type: attr('string'),
		eeoReport: DS.belongsTo('App.EeoReport'),
		missingEeoEmployees: function () {
			var emps = this.get('employees');
			return emps.filterProperty('eeoInfoComplete', false);
		}.property('employees', 'employees.@each.eeoInfoComplete'),
		browserQueue: attr('string'),
		lastCommunication: attr('number'),
		payrollProvider: attr('string'),
		regEmployeeCount: attr('number'),
		isContractorPaymentsCompany: attr('boolean'),
		isIomCompany: attr('boolean'),
		isCpWithIomCompany: attr('boolean'),
		isOwnerOnlyCompany: attr('boolean'),
		isOwnerOnlyCompanyDisplay: function () {
			if (this.get('isOwnerOnlyCompany')) {
				return 'Yes';
			} else {
				return 'No';
			}
		}.property('isOwnerOnlyCompany'),
		isOnZenefitsPayroll: Ember.computed.oneWay('zpCompany.isComplete'),
		isInZenefitsPayrollSetup: Ember.computed.oneWay('zpCompany.isSetup'),
		isZenefitsPayrollPending: Ember.computed.oneWay('zpCompany.isPending'),
		isPayrollProviderIntuit: function () {
			var payrollProvider = this.get('payrollProvider');
			return (payrollProvider == 'IN' || payrollProvider == 'MP' || payrollProvider == 'QB');
		}.property('payrollProvider'),
		isPayrollProviderWfOrAr: function () {
			var payrollProvider = this.get('payrollProvider');
			return (payrollProvider == 'WF' || payrollProvider == 'AR');
		}.property('payrollProvider'),
		payrollProviderMetadata: function () {
			var payrollProvider = this.get('payrollProvider');
			if (!payrollProvider) {
				return null;
			}
			var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

			return ObjectPromiseProxy.create({
				promise: Ember.ajaxGet('/custom_api/payroll_provider_metadata/' + payrollProvider)
			});
		}.property('payrollProvider'),
		hasPayScheduleFeatureEnabled: attr('boolean'),
		paySchedules: DS.hasMany('App.CompanyPaySchedule'),
		hasPayScheduleReadOnly: function () {
			return (this.get('payrollProvider') == 'YP' && !Ember.isEmpty(this.get('paySchedules')));
		}.property('payrollProvider', 'paySchedules'),
		payrollDirectDeposit: DS.hasMany('App.PayrollDirectDeposit'),
		payrollDepartments: DS.hasMany('App.PayrollDepartment'),
		scrapedWfCoCodes: DS.hasMany('App.WfCoCode'),
		compClasses: DS.hasMany('App.CompClass'),
		wfJobTitles: DS.hasMany('App.WfJobTitle'),
		wfBusinessUnits: DS.hasMany('App.WfBusinessUnit'),
		payrollTerminationReasons: DS.hasMany('App.WfTerminationReason'),
		wfLocations: DS.hasMany('App.WfLocation'),
		wfWorkerCategories: DS.hasMany('App.WfWorkerCategory'),
		payroll: DS.belongsTo('App.PayrollCompanySettings'),
		allValidWFCodes: attr('string'),
		wfPayClasses: DS.hasMany('App.WfPayClass'),
		pxLaborAssignments: DS.hasMany('App.PxLaborAssignment'),
		pxBusinessLocations: DS.hasMany('App.PxBusinessLocation'),
		syncBack: DS.belongsTo('App.SyncLog'),
		costCenters1: DS.hasMany('App.CostCenters1'),
		costCenters2: DS.hasMany('App.CostCenters2'),
		costCenters3: DS.hasMany('App.CostCenters3'),
		positionCodes: DS.hasMany('App.PositionCodes'),
		// Username and password aren't sent they're here for setting only
		payrollUsername: attr('string'),
		payrollPassword: attr('string'),
		payrollPin: attr('string'),
		payrollClientId: attr('string'),
		payrollEmail: attr('string'),
		accountantEmail: attr('string'),
		payrollMaxBankNumberChecking: attr('number'),
		payrollMaxBankNumberSavings: attr('number'),
		employeeCount: attr('number'),
		shortCircuitEmployeeCount: attr('number'),
		hasApprovedEmployees: attr('boolean'),
		hasInternationalEmployee: attr('boolean'),
		allActiveEmployeesCount: attr('number'),
		terminatedEmployeeCount: attr('number'),
		payrollEmployeesCount: attr('number'),
		cobraEmployeeCount: attr('number'),
		syncEmployeeCount: attr('number'),
		stateTaxID: attr('string'),
		stateOfIncorporation: attr('string'),
		dayBusinessEstablished: attr('string'),
		monthBusinessEstablished: attr('string'),
		yearBusinessEstablished: attr('string'),
		naicsCode: attr('string'),
		monthYearBusinessEstablished: function () {
			return this.get('monthBusinessEstablished') + '/' + this.get('yearBusinessEstablished');
		}.property('monthBusinessEstablished', 'yearBusinessEstablished'),

		businessEstablishedDate: function () {
			var month = this.get('monthBusinessEstablished');
			var year = this.get('yearBusinessEstablished');
			var day = this.get('dayBusinessEstablished');
			if (!(month && year)) {
				return null;
			}
			day = day ? day : 1;
			return moment().set({ 'year': Number(year), 'month': Number(month) - 1, 'date': day });
		}.property('monthBusinessEstablished', 'yearBusinessEstablished'),

		establishedMonthYear: function () {
			var date = this.get('businessEstablishedDate');
			return date ? date.format('MMMM, YYYY') : date;
		}.property('businessEstablishedDate'),

		isBusinessDateAYearAgo: function () {
			var businessEstablishedDate = this.get('businessEstablishedDate');
			var today = moment();
			if (this.get('hasLifeBorEnrollment') || this.get('hasADDBorEnrollment') || this.get('hasSTDBorEnrollment') || this.get('hasLTDBorEnrollment')) {
				return true;
			}

			if (businessEstablishedDate && (Math.abs(businessEstablishedDate.diff(today, 'days')) > (365 - 60))) {
				return true;
			}

			return false;
		}.property('businessEstablishedDate', 'hasLifeBorEnrollment', 'hasADDBorEnrollment', 'hasSTDBorEnrollment', 'hasLTDBorEnrollment'),
		selectedCarrier: attr('string'),
		selectedStateCarrier: DS.belongsTo('App.Carrier'),
		policy: DS.belongsTo('App.CompanyPolicy'),
		enrollmentStatus: attr('string'),
		medicalEnrollmentStatus: attr('string'),
		dentalEnrollmentStatus: attr('string'),
		visionEnrollmentStatus: attr('string'),
		medicalType: attr('string'),
		dentalType: attr('string'),
		visionType: attr('string'),
		medicalReviewing: attr('boolean'),
		dentalReviewing: attr('boolean'),
		visionReviewing: attr('boolean'),
		medicalSubmittedDate: attr('string'),
		dentalSubmittedDate: attr('string'),
		visionSubmittedDate: attr('string'),
		medicalTieredContributionField: DS.belongsTo('App.CustomField'),
		dentalTieredContributionField: DS.belongsTo('App.CustomField'),
		visionTieredContributionField: DS.belongsTo('App.CustomField'),
		tieredWaitingPeriodField: DS.belongsTo('App.CustomField'),
		genericContributionScheme: DS.belongsTo('App.ContributionScheme'),
		businessInsuranceEnrollments: DS.belongsTo('App.BusinessInsuranceEnrollment'),
		logoUrl: attr("string"),
		logoKey: attr("string"),
		adminEmail: attr("string"),
		adminName: attr("string"),
		partner: DS.belongsTo('App.Partner'),
		selectedPlans: DS.hasMany("App.CPlan"),
		selectedDentalPlans: DS.hasMany("App.CDentalPlan"),
		allFsaAgreements: DS.hasMany("App.AgreementCompanyFsa"),
		section125: DS.hasMany("App.AgreementSection125"),
		selectedDentalPlan: attr('number'),
		selectedVisionPlans: DS.hasMany("App.CVisionPlan"),
		selectedVisionPlan: attr('number'),
		dentalPlan: DS.belongsTo('App.CDentalPlan'),
		visionPlan: DS.belongsTo('App.CVisionPlan'),
		hasShortCircuitPlanWithoutRates: attr('boolean'),
		needDocuments: attr('boolean'),
		isOnboardingSetup: attr('boolean'),
		isCompanyAlegeusCustomer: attr('boolean'),
		payFreq: attr('string'),
		// NOTE: 'payFreq' corresponds to the default pay frequency of the companies that have sync'ed payroll with us (computed field),
		// whereas 'payFrequency' refers to the default pay frequency of companies that have manual sync.
		// Use 'payFrequency' only where it's absolutely necessary.
		payFrequency: attr('string'),
		paySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		syncStatus: attr('string'),
		payrollSyncType: attr('string'),
		hasActiveSyncedPayroll: attr('boolean'),
		syncOrManual: function () {
			return this.get('hasActiveSyncedPayroll') ? 'Sync' : 'Manual';
		}.property('hasActiveSyncedPayroll'),
		syncErrorMessage: attr('string'),
		isWrongCredentials: Ember.computed.equal('syncErrorMessage', 'Incorrect Username or Password'),
		isMFA: Ember.computed.equal('syncErrorMessage', 'MFA'),
		isResetPassword: Ember.computed.equal('syncErrorMessage', 'Reset Password'),
		isInactiveAccount: Ember.computed.equal('syncErrorMessage', 'Inactive Account'),
		syncErrorMessageDisplay: function () {
			if (this.get('isWrongCredentials')) {
				return 'Your username or password were wrong.';
			}
			if (this.get('isMFA')) {
				return 'You have multi-factor authentication enabled on your account.';
			}
			if (this.get('isResetPassword')) {
				return 'You need to reset your password.';
			}
			if (this.get('isInactiveAccount')) {
				return 'Your account is not active.';
			}
			return 'We were unable to connect with your payroll.';
		}.property('syncErrorMessage'),
		lastSyncRun: attr('string'),
		lastGoodSync: attr('string'),
		isSupported: attr('boolean'),
		settings: DS.belongsTo('App.CompanySettings'),
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		contractorSettings: DS.belongsTo('App.ContractorSettings'),
		newPayroll: DS.belongsTo('App.NewPayroll'),
		smp: DS.belongsTo('App.Smp'),

		//TODO: See note in mirrored props in the App.Smp object. Once we consolidate
		//Company.payrollProvider and Smp.payrollProvider, these computed props may change.
		isSMP: attr('boolean'),
		isPayrollReports: attr('boolean'),
		isFileSync: attr('boolean'),

		payrollSwitch: DS.belongsTo('App.PayrollSwitch'),
		hsa: DS.belongsTo('App.HsaCompanyEnrollment'),
		company401kPayrolls: DS.hasMany('App.Company401kPayroll'),
		company401kEnrollment: DS.belongsTo('App.Company401kEnrollment'),
		fsa: DS.belongsTo('App.FsaCompanyEnrollment'),
		hra: DS.belongsTo('App.HraCompanyEnrollment'),
		unicardCommuter: DS.belongsTo('App.UnicardCommuterCompanyEnrollment'),
		alegeusSettings: DS.belongsTo('App.AlegeusCompanySettings'),
		stockOption: DS.belongsTo('App.CompanyStockOption'),
		stockOptionAdditionalDetails: DS.belongsTo('App.CompanyStockOptionAdditionalDetails'),
		showLifeAndDisability: attr('boolean'),
		showLife: attr('boolean'),
		showLTD: attr('boolean'),
		showSTD: attr('boolean'),
		lifeOrDisabilitySwitched: attr('boolean'),
		lifequotes: attr('boolean'),
		hasLifeActiveQuotes: attr('boolean'),
		showBusinessInsurance: attr('boolean'),
		isBoRSCOEComplete: attr('boolean'),
		numQuoteDependents: attr('number'),
		postACA: attr('boolean'),
		hrContact: DS.belongsTo('App.EmployeeHrContact'),
		hasPaystubs: attr('boolean'),
		ta: DS.belongsTo('App.TaCompany'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		departments: DS.hasMany('App.Department'),
		customReport: DS.hasMany('App.CustomReport'),
		reportDocuments: DS.hasMany('App.ReportDocument'),
		numDepartments: Ember.computed.oneWay('departments.length'),
		companyItServices: DS.hasMany('App.CompanyItService'),
		locations: DS.hasMany('App.CompanyLocation'),
		numLocations: Ember.computed.oneWay('locations.length'),
		installedITServices: attr('string'),
		terminationSettings: DS.belongsTo('App.TerminationSettings'),
		deductionHeuristics: DS.belongsTo('App.CompanyDeductionHeuristics'),
		isAdminSignOff: attr('boolean'),
		isContractorPaymentsCompany: attr('boolean'),
		isProposalLive: function (linesOfCoverage) {
			var adminProposals = this.get('adminProposals');
			var isProposalLive = false;
			adminProposals.forEach(function (adminProposal) {
				if (linesOfCoverage.indexOf(adminProposal.get('lineOfCoverage')) > -1) {
					isProposalLive = true;
				}
			});

			return isProposalLive;
		},
		isMedicalProposalLive: function () {
			return this.isProposalLive(['medical']);
		}.property('adminProposals.@each.lineOfCoverage'),
		isDentalProposalLive: function () {
			return this.isProposalLive(['dental']);
		}.property('adminProposals.@each.lineOfCoverage'),
		isVisionProposalLive: function () {
			return this.isProposalLive(['vision']);
		}.property('adminProposals.@each.lineOfCoverage'),
		isLifeDisabilityProposalLive: function () {
			return this.isProposalLive(['lifenew', 'add', 'ltd', 'std']);
		}.property('adminProposals.@each.lineOfCoverage'),
		isAdminProposalReview: Ember.computed.or('isMedicalProposalLive', 'isDentalProposalLive', 'isVisionProposalLive', 'isLifeDisabilityProposalLive'),
		getProposalsWithStatus: function (status) {
			var adminProposals = this.get('adminProposals');
			return adminProposals.filter(function (adminProposal) {
				return (adminProposal.get('status') == status);
			});
		},
		isProposalInAdminReview: function () {
			return this.getProposalsWithStatus('Admin Review').length > 0;
		}.property('adminProposals.@each.status'),
		isProposalInBAReview: function () {
			return this.getProposalsWithStatus('BA Review').length > 0;
		}.property('adminProposals.@each.status'),
		isProposalInError: function () {
			return this.getProposalsWithStatus('Error').length > 0;
		}.property('adminProposals.@each.status'),
		isActive: attr('boolean'),
		numEmployeeNoSSN: attr('string'),
		companyProductTags: DS.hasMany('App.CompanyProductTag'),
		intuitPaySchedules: DS.hasMany('App.IntuitPaySchedule'),
		isAggregatedCompany: attr('boolean'),
		acaCompanySettings: DS.hasMany('App.AcaCompanySetting'),
		acaCompanyPlans: DS.hasMany('App.AcaCompanyPlan'),
		aggregatedNameEinPairs: attr('raw'),
		isProvisioningEmail: function () {
			return this.get('isGmailSetup');
		}.property('isGmailSetup'),
		isGmailSetup: function () {
			return (this.get('installedITServices') || "").split(",").filter(function (value) {
				return value == 'Google Apps';
			}).length > 0;
		}.property('installedITServices'),
		showProvisioningInHiringAndTermination: function () {
			var companyItServices = this.get('companyItServices') || [];
			return companyItServices.some(function (service) {
				return !service.get('hideFromHiringAndTermination');
			});
		}.property('companyItServices.@each.hideFromHiringAndTermination'),
		requireProvisioningInHiringAndTermination: function () {
			var companyItServices = this.get('companyItServices') || [];
			return companyItServices.some(function (service) {
				return service.get('requireInHiringFlow');
			});
		}.property('companyItServices.@each.requireInHiringFlow'),
		gmailService: Ember.computed.findByProperty('companyItServices', 'itService.name', 'Google Apps'),
		actionNotifications: DS.hasMany('App.ActionNotification'),
		taSettingsComplete: function () {
			return true;
		}.property('taSettings'),
		taPayPeriod: DS.hasMany('App.TaPayPeriod'),
		ptos: DS.hasMany('App.EmployerPto'),
		hasCompletePto: function () {
			// Warning! This property returns a promise. Use with .then, and with caution!!
			return this.get('ptos').then(function (ptos) {
				return ptos.some(function (pto) {
					return pto.get('status') === 'complete';
				})
			});
		}.property('ptos.@each.status'),
		firstIncompletePto: function () {
			return this.get('ptos').find(function (pto) {
				return pto.get('status') !== 'complete' && pto.get('status') !== 'deleted';
			});
		}.property('ptos.@each.status'),

		medicalSwitched: function () {
			var medicalType = this.get('medicalType');
			if (medicalType) {
				return medicalType.indexOf('switched') > -1;
			}
			return false;
		}.property('medicalType'),
		dentalSwitched: function () {
			var dentalType = this.get('dentalType');
			if (dentalType) {
				return dentalType.indexOf('switched') > -1;
			}
			return false;
		}.property('dentalType'),
		visionSwitched: function () {
			var visionType = this.get('visionType');
			if (visionType) {
				return visionType.indexOf('switched') > -1;
			}
			return false;
		}.property('visionType'),
		taAutomationMethod: function () {
			var supportedProviders = ['IN', 'WF', 'ZN', 'ZD'];
			return supportedProviders.contains(this.get('payrollProvider')) === true ? 'pushed to payroll' : 'emailed to admin(s)';
		}.property('payrollProvider'),
		healthCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		healthEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		allHealthCarriers: DS.hasMany('App.CompanyHealthCarrier'),
		allHealthEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),
		getEnrollmentsFor: function (lineOfCoverage) {
			var healthEnrollments = this.get('allHealthEnrollments');
			return healthEnrollments.filter(function (healthEnrollment) {
				return (healthEnrollment.get('lineOfCoverage') == lineOfCoverage &&
					healthEnrollment.get('isActive') &&
					healthEnrollment.get('isEnrollmentComplete'));
			});
		},
		medicalEnrollments: function () {
			return this.getEnrollmentsFor('medical');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		dentalEnrollments: function () {
			return this.getEnrollmentsFor('dental');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		visionEnrollments: function () {
			return this.getEnrollmentsFor('vision');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		lifeEnrollments: function () {
			return this.getEnrollmentsFor('lifenew');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		stdEnrollments: function () {
			return this.getEnrollmentsFor('std');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		ltdEnrollments: function () {
			return this.getEnrollmentsFor('ltd');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		addEnrollments: function () {
			return this.getEnrollmentsFor('add');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		accEnrollments: function () {
			return this.getEnrollmentsFor('acc');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		cancerEnrollments: function () {
			return this.getEnrollmentsFor('cancer');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		hiEnrollments: function () {
			return this.getEnrollmentsFor('hi');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		ciEnrollments: function () {
			return this.getEnrollmentsFor('ci');
		}.property('allHealthEnrollments.@each.lineOfCoverage', 'allHealthEnrollments.@each.isActive', 'allHealthEnrollments.@each.isEnrollmentComplete'),
		offersMedical: Ember.computed.gt('medicalEnrollments.length', 0),
		offersDental: Ember.computed.gt('dentalEnrollments.length', 0),
		offersVision: Ember.computed.gt('visionEnrollments.length', 0),
		offersLifenew: Ember.computed.gt('lifeEnrollments.length', 0),
		offersStd: Ember.computed.gt('stdEnrollments.length', 0),
		offersLtd: Ember.computed.gt('ltdEnrollments.length', 0),
		offersAdd: Ember.computed.gt('addEnrollments.length', 0),
		offersAcc: Ember.computed.gt('accEnrollments.length', 0),
		offersCancer: Ember.computed.gt('cancerEnrollments.length', 0),
		offersHi: Ember.computed.gt('hiEnrollments.length', 0),
		offersCi: Ember.computed.gt('ciEnrollments.length', 0),
		medicalOpenEnrollments: function () {
			return this.getWithDefault('medicalEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') !== 'BoR' &&
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('medicalEnrollments.@each.enrollmentType', 'medicalEnrollments.@each.endDate'),
		dentalOpenEnrollments: function () {
			return this.getWithDefault('dentalEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') !== 'BoR' &&
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('dentalEnrollments.@each.enrollmentType', 'dentalEnrollments.@each.endDate'),
		visionOpenEnrollments: function () {
			return this.getWithDefault('visionEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') !== 'BoR' &&
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('visionEnrollments.@each.enrollmentType', 'visionEnrollments.@each.endDate'),
		lifeOpenEnrollments: function () {
			return this.getWithDefault('lifeEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'OE' && // only support OE CHE for life
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('lifeEnrollments.@each.enrollmentType', 'lifeEnrollments.@each.endDate'),
		addOpenEnrollments: function () {
			return this.getWithDefault('addEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'OE' && // only support OE CHE for ADD
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('addEnrollments.@each.enrollmentType', 'addEnrollments.@each.endDate'),
		stdOpenEnrollments: function () {
			return this.getWithDefault('stdEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'OE' && // only support OE CHE for STD
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('stdEnrollments.@each.enrollmentType', 'stdEnrollments.@each.endDate'),
		ltdOpenEnrollments: function () {
			return this.getWithDefault('ltdEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'OE' && // only support OE CHE for LTD
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('ltdEnrollments.@each.enrollmentType', 'ltdEnrollments.@each.endDate'),
		openEnrollments: function () {
			return this.getWithDefault('healthEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') !== 'BoR' &&
					moment(enrollment.get('endDate'), 'MM/DD/YYYY') > moment();
			});
		}.property('healthEnrollments.@each.enrollmentType', 'healthEnrollments.@each.endDate'),
		lifeBorEnrollments: function () {
			return this.getWithDefault('lifeEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'BoR';
			});
		}.property('lifeEnrollments.@each.enrollmentType'),
		addBorEnrollments: function () {
			return this.getWithDefault('addEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'BoR';
			});
		}.property('addEnrollments.@each.enrollmentType'),
		stdBorEnrollments: function () {
			return this.getWithDefault('stdEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'BoR';
			});
		}.property('stdEnrollments.@each.enrollmentType'),
		ltdBorEnrollments: function () {
			return this.getWithDefault('ltdEnrollments', []).filter(function (enrollment) {
				return enrollment.get('enrollmentType') === 'BoR';
			});
		}.property('ltdEnrollments.@each.enrollmentType'),
		hasMedicalOpenEnrollments: Ember.computed.gt('medicalOpenEnrollments.length', 0),
		hasDentalOpenEnrollments: Ember.computed.gt('dentalOpenEnrollments.length', 0),
		hasVisionOpenEnrollments: Ember.computed.gt('visionOpenEnrollments.length', 0),
		hasLifeOpenEnrollments: Ember.computed.gt('lifeOpenEnrollments.length', 0),
		hasADDOpenEnrollments: Ember.computed.gt('addOpenEnrollments.length', 0),
		hasSTDOpenEnrollments: Ember.computed.gt('stdOpenEnrollments.length', 0),
		hasLTDOpenEnrollments: Ember.computed.gt('ltdOpenEnrollments.length', 0),
		hasLifeBorEnrollment: Ember.computed.gt('lifeBorEnrollments.length', 0),
		hasADDBorEnrollment: Ember.computed.gt('addBorEnrollments.length', 0),
		hasSTDBorEnrollment: Ember.computed.gt('stdBorEnrollments.length', 0),
		hasLTDBorEnrollment: Ember.computed.gt('ltdBorEnrollments.length', 0),
		hasOpenEnrollments: Ember.computed.gt('openEnrollments.length', 0),
		isAdminOpenEnrollmentActive: Ember.computed.equal('adminOpenEnrollment.status', 'active'),
		isAdminOpenEnrollmentPaused: Ember.computed.equal('adminOpenEnrollment.status', 'waiting'),
		canOfferFSA: function () {
			var canOfferFSA = false;
			var healthEnrollments = this.get('healthEnrollments');
			healthEnrollments.forEach(function (healthEnrollment) {
				if (healthEnrollment.get('lineOfCoverage') == 'medical') {
					canOfferFSA = true;
				}
			});

			return canOfferFSA;
		}.property('healthEnrollments.@each.lineOfCoverage'),
		deactivationDate: attr('string'),
		isCorporation: Ember.computed.equal('type', 'CO'),
		isLLC: Ember.computed.equal('type', 'LC'),
		isPartnership: Ember.computed.equal('type', 'PA'),
		isSCorporation: Ember.computed.equal('type', 'SC'),
		isSoleProprietorship: Ember.computed.equal('type', 'SP'),
		isLLP: Ember.computed.equal('type', 'LP'),
		isNonProfitCorporation: Ember.computed.equal('type', 'NP'),

		getFullCompanyType: attr('string'),
		getFullBankAccountType: function () {
			switch (this.get('bankAccountType')) {
				case 'C':
					return 'Checking';
				case 'S':
					return 'Saving';
				default:
					return null;
			}
		}.property('bankAccountType'),
		getFullPayrollProviderName: attr('string'),
		getFullPayFreq: function () {
			switch (this.get('payFreq')) {
				case 'BW':
					return 'Bi-weekly';
				case 'SM':
					return 'Semi-monthly';
				case 'Mo':
					return 'Monthly';
				case 'We':
					return 'Weekly';
				default:
					return 'Null';
			}
		}.property('payFreq'),
		hasHsaPlans: attr('boolean'),
		showHSA: function () {
			return this.get('alegeusSettings.isBorElsewhere') || (!Ember.isEmpty(this.get('enrollmentStatus')) && this.get('hasHsaPlans'));
		}.property('hasHsaPlans', 'enrollmentStatus', 'alegeusSettings.isBorElsewhere'),
		showHRA: function () {
			var shouldShowHraCard = this.get('hra.shouldShowHraCard') || this.get('hra.firstAvailablePlan.status') != null;
			var status = this.get('enrollmentStatus');
			return shouldShowHraCard && (status == 'approved' || status == 'complete' || status == 'document');
		}.property('enrollmentStatus', 'hra.shouldShowHraCard', 'hra.firstAvailablePlan.status'),
		isCA: Ember.computed.equal('state', 'CA'),
		isCO: Ember.computed.equal('state', 'CO'),
		isNY: Ember.computed.equal('state', 'NY'),
		isUT: Ember.computed.equal('state', 'UT'),
		isMA: Ember.computed.equal('state', 'MA'),
		isWA: Ember.computed.equal('state', 'WA'),
		isIL: Ember.computed.equal('state', 'IL'),
		isOH: Ember.computed.equal('state', 'OH'),
		isTX: Ember.computed.equal('state', 'TX'),
		isFL: Ember.computed.equal('state', 'FL'),
		isGA: Ember.computed.equal('state', 'GA'),
		isAZ: Ember.computed.equal('state', 'AZ'),
		isCT: Ember.computed.equal('state', 'CT'),
		isVA: Ember.computed.equal('state', 'VA'),
		isMD: Ember.computed.equal('state', 'MD'),
		isNJ: Ember.computed.equal('state', 'NJ'),
		isNV: Ember.computed.equal('state', 'NV'),
		isNC: Ember.computed.equal('state', 'NC'),
		isMO: Ember.computed.equal('state', 'MO'),
		isNH: Ember.computed.equal('state', 'NH'),
		isDE: Ember.computed.equal('state', 'DE'),
		isRI: Ember.computed.equal('state', 'RI'),
		isWI: Ember.computed.equal('state', 'WI'),
		isID: Ember.computed.equal('state', 'ID'),
		isIN: Ember.computed.equal('state', 'IN'),
		isSC: Ember.computed.equal('state', 'SC'),
		isTN: Ember.computed.equal('state', 'TN'),
		isNM: Ember.computed.equal('state', 'NM'),
		isIA: Ember.computed.equal('state', 'IA'),
		isME: Ember.computed.equal('state', 'ME'),
		isPA: Ember.computed.equal('state', 'PA'),
		isOtherState: function () {
			var state = this.get('state');
			if (state != 'CA' && state != 'CO' && state != 'NY' && state != 'UT' &&
				state != 'WA' && state != 'IL' && state != 'TX' && state != 'FL' &&
				state != 'GA' && state != 'AZ' && state != 'CT' && state != 'MD' && state != 'VA' &&
				state != 'NJ' && state != 'NC' && state != 'NV' && state != 'MO' && state != 'NH' &&
				state != 'DE' && state != 'RI' && state != 'WI' && state != 'ID' && state != 'IN' &&
				state != 'SC' && state != 'TN' && state != 'NM' && state != 'IA' && state != 'ME' &&
				state != 'PA') {
				return true;
			}
			return false;
		}.property('state'),
		isWaitingOnQuotes: Ember.computed.equal('enrollmentStatus', 'quotes'),
		isApproved: Ember.computed.equal('enrollmentStatus', 'approved'),
		doesNeedDocuments: Ember.computed.equal('enrollmentStatus', 'document'),
		hasEnrollmentBegun: Ember.computed.equal('enrollmentStatus', 'begin'),
		isEnrollmentComplete: Ember.computed.equal('enrollmentStatus', 'complete'),
		inBoRIntermediateState: Ember.computed.equal('enrollmentStatus', 'switched'),
		isEnrollmentCompleteOrApproved: Ember.computed.or('isEnrollmentComplete', 'isApproved'),
		isInBorImplementation: attr('boolean'),
		isUnderImplementation: attr('boolean'),
		shortCircuitOESignedOff: attr('boolean'),
		isFsaPromotionDecember2015: attr('boolean'),
		isCommuterPromotionDecember2015: attr('boolean'),
		isInAudit: attr('boolean'),
		canEmployeeEnroll: function () {
			// this function is used for computing the dashboard card for ZApps, if you
			// update this property you must make equivalent updates on the server side property
			var status = this.get('enrollmentStatus');
			return (status == 'approved' || status == 'complete' || status == 'document' || status == 'switched' ||
				this.get('medicalReviewing') || this.get('dentalReviewing') || this.get('visionReviewing'));
		}.property('enrollmentStatus', 'medicalReviewing', 'dentalReviewing', 'visionReviewing'),
		showMedicalSettings: function () {
			var status = this.get('enrollmentStatus');
			return status == 'approved';
		}.property('enrollmentStatus'),
		isBlueCrossSelected: function () {
			return (
				(/Blue Cross/.test(this.get('selectedCarrier')) && this.get('selectedCarrier') != 'Blue Cross RI') ||
				/Regence/.test(this.get('selectedCarrier')) ||
				/Lifewise/.test(this.get('selectedCarrier')) ||
				/Optima/.test(this.get('selectedCarrier')) ||
				/Providence/.test(this.get('selectedCarrier')) ||
				/Moda Health/.test(this.get('selectedCarrier')) ||
				/Excellus/.test(this.get('selectedCarrier')) ||
				/Coventry/.test(this.get('selectedCarrier')) ||
				/HealthPartners/.test(this.get('selectedCarrier')) ||
				/Highmark/.test(this.get('selectedCarrier')) ||
				/Anthem/.test(this.get('selectedCarrier')) ||
				/Cigna/.test(this.get('selectedCarrier')) ||
				/GHC/.test(this.get('selectedCarrier')) ||
				/SelectHealth/.test(this.get('selectedCarrier')) ||
				/AmeriHealth/.test(this.get('selectedCarrier')) ||
				/PreferredOne/.test(this.get('selectedCarrier')) ||
				/Assurant/.test(this.get('selectedCarrier')) ||
				/Guidestone/.test(this.get('selectedCarrier')) ||
				/Health Services Administrators/.test(this.get('selectedCarrier')) ||
				/Starmark/.test(this.get('selectedCarrier')) ||
				/Humana/.test(this.get('selectedCarrier')) ||
				/Independence/.test(this.get('selectedCarrier')) ||
				/Geoblue/.test(this.get('selectedCarrier')) ||
				/Priority Health/.test(this.get('selectedCarrier')) ||
				/ConnectiCare CT/.test(this.get('selectedCarrier')) ||
				/Presbyterian/.test(this.get('selectedCarrier')) ||
				(/HealthNet/.test(this.get('selectedCarrier')) && this.get('selectedCarrier') != 'HealthNet') ||
				/Guardian/.test(this.get('selectedCarrier')) ||
				/EMI Health/.test(this.get('selectedCarrier'))
			);
		}.property('selectedCarrier'),
		isBlueCrossRISelected: Ember.computed.equal('selectedCarrier', 'Blue Cross RI'),
		isEmpireSelected: Ember.computed.equal('selectedCarrier', 'Empire Blue Cross'),
		isUnitedNYSelected: Ember.computed.equal('selectedCarrier', 'United NY'),
		isOxfordSelected: Ember.computed.equal('selectedCarrier', 'Oxford'),
		isHarvardPilgrimSelected: Ember.computed.match('selectedCarrier', /Harvard Pilgrim/),
		isSeeChangeSelected: Ember.computed.match('selectedCarrier', /SeeChange/),
		isAetnaNYSelected: function () {
			return (/Aetna/.test(this.get('selectedCarrier')) ||
				/Medical Mutual/.test(this.get('selectedCarrier')) ||
				/Tufts Health/.test(this.get('selectedCarrier')) ||
				/Neighborhood Health/.test(this.get('selectedCarrier')) ||
				/Health Republic/.test(this.get('selectedCarrier')) ||
				/HealthPass/.test(this.get('selectedCarrier'))
			);
		}.property('selectedCarrier'),
		isEmblemSelected: Ember.computed.equal('selectedCarrier', 'Emblem Health'),
		isBlueShieldSelected: Ember.computed.equal('selectedCarrier', 'Blue Shield'),
		isUnitedMDSelected: Ember.computed.equal('selectedCarrier', 'United MD'),
		isUnitedSelected: function () {
			return (
				(/United/.test(this.get('selectedCarrier')) && this.get('selectedCarrier') != 'United NY') ||
				/UHC River Valley/.test(this.get('selectedCarrier')) ||
				/NHP/.test(this.get('selectedCarrier'))
			);
		}.property('selectedCarrier'),
		isHealthNetSelected: Ember.computed.equal('selectedCarrier', 'HealthNet'),
		isKaiserSelected: Ember.computed.match('selectedCarrier', /Kaiser Permanente/),
		isCalChoiceSelected: Ember.computed.equal('selectedCarrier', 'CalChoice'),
		showExperimental: function () {
			return /YourPeople/i.test(this.get('name')) || /Zenefits/i.test(this.get('name'));
		}.property('name'),
		disableStockOption: function () {
			var ids = [2380, 40191, 11236, 59075, 27384, 28340];
			return $.inArray(this.get('id'), ids) != -1;
		}.property('id'),
		isTimeAttendanceBeta: function () {
			return this.get('showExperimental') ||
				this.get('id') == 1 ||
				this.get('id') == 2 ||
				this.get('id') == 6 ||
				this.get('id') == 7 ||
				this.get('id') == 17908 ||
				this.get('id') == 9955 ||
				this.get('id') == 1260 ||
				this.get('id') == 13 ||
				this.get('id') == 14099 ||
				this.get('id') == 15071 ||
				this.get('id') == 20750 ||
				this.get('id') == 48;
		}.property('showExperimental'),
		isSemiManualPayrollBeta: function () {
			return this.get('showExperimental') ||
				/SMPDemo/.test(this.get('name')) ||
				this.get('id') == 2 ||
				this.get('id') == 17723 ||
				this.get('id') == 52996 ||
				this.get('id') == 50342 ||
				this.get('id') == 59828 ||
				this.get('id') == 55020 ||
				this.get('id') == 10496 ||
				this.get('id') == 20290;
		}.property('showExperimental'),
		payrollDemo: function () {
			return /ADP Demo/.test(this.get('name'));
		}.property('name'),
		commuterExperimental: function () {
			return (this.get('name') == 'True Link Financial') ||
				(this.get('name') == 'Coinbase') ||
				(this.get('name') == 'Weebly Inc');
		}.property('name'),
		hasBenefitsFor401k: function () {
			return this.get('enrollmentStatus') == 'complete' || this.get('enrollmentStatus') == 'approved' ||
				this.get('enrollmentStatus') == 'switched' || this.get('enrollmentStatus') == 'document';
		}.property('enrollmentStatus'),
		hasSyncedPayroll: Ember.computed.oneWay('syncStatus'),
		isSyncStatusSuccess: function () {
			return this.get('syncStatus') == "success" || this.get('syncStatus') == 'retrying' || this.get('syncStatus') == 'SMP';
		}.property('syncStatus'),
		isLessTenEmployees: function () {
			var employeeCount = this.get('employeeCount');
			if (employeeCount && employeeCount <= 10) {
				return true;
			}
			return false;
		}.property('employeeCount'),
		isLessNineEmployees: function () {
			var employeeCount = this.get('employeeCount');
			if (employeeCount && employeeCount <= 9) {
				return true;
			}
			return false;
		}.property('employeeCount'),
		show401kAddExisting: function () {
			var companyName = this.get('name');
			// Modify the condition below appropriately for one-offs to display the "Add Existing" option for 401(k) plans.
			if (/YourPeople/.test(companyName) || /Zenefits/.test(companyName) || /VaynerMedia/.test(companyName) || /Good Done Great/.test(companyName)) {
				return true;
			}
			return false;
		}.property('name'),
		isMedicalEnrollmentComplete: function () {
			return (this.get('medicalEnrollmentStatus') == 'complete' ||
				this.get('medicalEnrollmentStatus') == 'approved' ||
				this.get('medicalEnrollmentStatus') == 'document' ||
				this.get('medicalEnrollmentStatus') == 'submitted');
		}.property('medicalEnrollmentStatus'),
		isDentalEnrollmentComplete: function () {
			return (this.get('dentalEnrollmentStatus') == 'complete' ||
				this.get('dentalEnrollmentStatus') == 'approved' ||
				this.get('dentalEnrollmentStatus') == 'document' ||
				this.get('dentalEnrollmentStatus') == 'submitted');
		}.property('dentalEnrollmentStatus'),
		isVisionEnrollmentComplete: function () {
			return (this.get('visionEnrollmentStatus') == 'complete' ||
				this.get('visionEnrollmentStatus') == 'approved' ||
				this.get('visionEnrollmentStatus') == 'document' ||
				this.get('visionEnrollmentStatus') == 'submitted');
		}.property('visionEnrollmentStatus'),
		overrideAnchorPayDate: attr('string'),
		companyCobra: DS.belongsTo('App.CompanyCobra'),
		companyFederalTaxes: DS.hasMany('App.CompanyFederalTax'),
		banks: DS.hasMany('App.CompanyBank'),
		isBackgroundCheckInfoComplete: function () {
			return Boolean(this.get('legalName')) &&
				Boolean(this.get('ein')) && Boolean(this.get('type')) && Boolean(this.get('legalZip')) &&
				Boolean(this.get('legalState')) && Boolean(this.get('legalCity')) &&
				Boolean(this.get('stateOfIncorporation'));
		}.property('legalName', 'ein', 'type', 'legalZip', 'legalState', 'legalCity', 'stateOfIncorporation'),
		isCompanyLegalInfoComplete: attr('boolean'),
		existingVerificationRequestTypes: attr('raw'),
		approverSettings: DS.belongsTo('App.ApproverCompanySettings'),
		hasTieredContributions: Ember.computed.or('medicalTieredContributionField', 'dentalTieredContributionField', 'visionTieredContributionField'),
		hasCustomContributions: function () {
			var hasCustomContributions = false;
			var chcs = this.get('healthCarriers');
			chcs.forEach(function (chc) {
				if (chc.get('hasCustomContributions')) {
					hasCustomContributions = true;
				}
			});
			return hasCustomContributions || this.get('hasTieredContributions');
		}.property('hasTieredContributions', 'healthCarriers'),
		isInternational: function () {
			return this.get('locations').filterProperty('isInternational').get('length') > 0;
		}.property('locations'),

		isPLComplete: function () {
			var provider = 'PL';
			var attrs = App.Utils.EmployeePayrollEnumFields;
			for (var i = 0; i < attrs.length; i++) {
				if (attrs[i].supported_providers.indexOf(provider) < 0) {
					continue;
				}
				var cc = this.get(attrs[i].company_related_name).filterProperty('isActive', true);
				if (cc && cc.length > 0) {
					return true;
				}
			}
			return false;
		}.property('costCenters1.@each.isActive', 'costCenters2.@each.isActive', 'costCenters3.@each.isActive', 'positionCodes.@each.isActive'),

		isPGComplete: function () {
			var provider = 'PG';
			var attrs = App.Utils.EmployeePayrollEnumFields;
			for (var i = 0; i < attrs.length; i++) {
				if (attrs[i].supported_providers.indexOf(provider) < 0) {
					continue;
				}
				var cc = this.get(attrs[i].company_related_name).filterProperty('isActive', true);
				if (cc && cc.length > 0) {
					return true;
				}
			}
			return false;
		}.property('costCenters1.@each.isActive', 'costCenters2.@each.isActive', 'costCenters3.@each.isActive', 'positionCodes.@each.isActive'),

		isPXComplete: function () {
			var payrolldepartments = this.get('payrollDepartments').filterProperty('isActive', true);
			if (payrolldepartments && payrolldepartments.length > 0) {
				return true;
			}

			var pxBusinessLocations = this.get('pxBusinessLocations').filterProperty('isActive', true);
			if (pxBusinessLocations && pxBusinessLocations.length > 0) {
				return true;
			}

			var pxLaborAssignments = this.get('pxLaborAssignments').filterProperty('isActive', true);
			if (pxLaborAssignments && pxLaborAssignments.length > 0) {
				return true;
			}

			return false;
		}.property('pxBusinessLocations', 'pxBusinessLocations.@each.isActive', 'pxLaborAssignments', 'pxLaborAssignments.@each.isActive', 'payrollDepartments', 'payrollDepartments.@each.isActive'),
		isIntuitComplete: function () {
			var intuitPaySchedules = this.get('intuitPaySchedules');
			if (intuitPaySchedules && intuitPaySchedules.get('length') > 0) {
				return true;
			} else {
				return false;
			}
		}.property('intuitPaySchedules'),
		fieldAliases: DS.hasMany('App.CompanyPayrollFieldAlias'),
	});

	App.CompanyTwofactorSettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		settingType: DS.attr('string'),
	});

	App.SyncLog = Ember.Model.extend({
		company: Ember.belongsTo('App.Company'),
		runDate: Ember.attr('date'),
		status: Ember.attr('string'),
		message: Ember.attr('string'),
		syncProgressState: Ember.attr('string'),
		interactive: Ember.attr('boolean'),
		syncType: Ember.attr('string'),
		isOpen: Ember.attr('boolean'),
		report_runs: Ember.hasMany('App.SyncBackReportRun'),
		spreadsheetSyncBackStatus: attr('string'),
	});

	App.SyncBackReportRun = Ember.Model.extend({
		report: Ember.attr('string'),
		company: Ember.belongsTo('App.Company'),
		syncLog: Ember.belongsTo('App.SyncLog'),
		status: Ember.attr('string'),
		statusExplanation: Ember.attr('string'),
		ignoreParseErrors: Ember.attr('boolean'),
		newHires: Ember.hasMany('App.SyncBackEmployeeRunNewEmployee'),
		syncDiffs: Ember.hasMany('App.SyncBackEmployeeRunSyncDiff'),
		deductionDisparities: Ember.hasMany('App.SyncBackEmployeeDeductionDisparity'),
		companyDeductionDiffs: Ember.hasMany('App.SyncBackCompanyDeductionDiff'),
	});

	App.SyncBackEmployeeRunNewEmployee = Ember.Model.extend({
		syncBackReportRun: Ember.belongsTo('App.SyncBackReportRun'),
		employeeId: Ember.attr('string'),
		first_name: Ember.attr('string'),
		last_name: Ember.attr('string'),
		fullName: Ember.attr('string'),
		photoUrl: Ember.attr('string'),
	});

	App.SyncBackEmployeeRunSyncDiff = Ember.Model.extend({
		syncBackReportRun: Ember.belongsTo('App.SyncBackReportRun'),
		employeeId: Ember.attr('string'),
		first_name: Ember.attr('string'),
		last_name: Ember.attr('string'),
		fullName: Ember.attr('string'),
		photoUrl: Ember.attr('string'),
		key: Ember.attr('string'),
		keyPretty: Ember.attr('string'),
		inPayroll: Ember.attr('string'),
		inZenefits: Ember.attr('string'),
		ignored: Ember.attr('boolean'),
		resolution: Ember.attr('string'),
	});

	App.SyncBackSimpleDeds = Ember.Model.extend({
		asStringHelper: function (dedKey, percentKey, maxKey) {
			var ded = this.get(dedKey);
			if (ded <= 0.01) {
				return '-';
			}
			var max = this.get(maxKey);
			var result = ded.toFixed(2);
			if (this.get(percentKey)) {
				result += '%';
			} else {
				result = '$' + result;
			}
			if (max) {
				result += ' (Max ' + max.toFixed(0) + ')';
			}
			return result;
		},
	});

	App.SyncBackSimpleDeduction = App.SyncBackSimpleDeds.extend({
		deduction: Ember.attr('number'),
		deductionAnnualMaximum: Ember.attr('number'),
		isDeductionAsPercentage: Ember.attr('boolean'),
		asString: function () {
			return this.asStringHelper('deduction', 'isDeductionAsPercentage', 'deductionAnnualMaximum');
		}.property('deduction', 'deductionAnnualMaximum', 'isDeductionAsPercentage'),
	});

	App.SyncBackSimpleContribution = App.SyncBackSimpleDeds.extend({
		contribution: Ember.attr('number'),
		contributionAnnualMaximum: Ember.attr('number'),
		isContributionAsPercentage: Ember.attr('boolean'),
		asString: function () {
			return this.asStringHelper('contribution', 'isContributionAsPercentage', 'contributionAnnualMaximum');
		}.property('contribution', 'contributionAnnualMaximum', 'isContributionAsPercentage'),
	});

	App.SyncBackEmployeeDeductionDisparity = Ember.Model.extend({
		syncBackReportRun: Ember.belongsTo('App.SyncBackReportRun'),
		employeeId: Ember.attr('string'),
		first_name: Ember.attr('string'),
		last_name: Ember.attr('string'),
		fullName: Ember.attr('string'),
		photoUrl: Ember.attr('string'),
		deductionType: Ember.attr('string'),
		payrollCode: Ember.attr('string'),
		disparityType: Ember.attr('string'),
		payrollDeduction: DS.belongsTo('App.SyncBackSimpleDeduction', { embedded: true, key: 'payrollDeduction' }),
		zenefitsDeduction: DS.belongsTo('App.SyncBackSimpleDeduction', { embedded: true, key: 'zenefitsDeduction' }),
		payrollContribution: DS.belongsTo('App.SyncBackSimpleContribution', { embedded: true, key: 'payrollContribution' }),
		zenefitsContribution: DS.belongsTo('App.SyncBackSimpleContribution', { embedded: true, key: 'zenefitsContribution' }),
		attrEqual: function (key, val1, val2) {
			if (['isDeductionAsPercentage', 'isContributionAsPercentage'].indexOf(key) >= 0) {
				return val1 === val2;
			}
			return Math.abs(val1 - val2) <= 0.01;
		},
		isSameHelper: function (payroll, zenefits, dedKey, maxKey, pctKey) {
			if (payroll.get(dedKey) <= 0.01 && zenefits.get(dedKey) <= 0.01) {
				return true;
			}
			var _this = this;

			return [dedKey, maxKey, pctKey].every(function (attr) {
				return _this.attrEqual(attr, payroll.get(attr), zenefits.get(attr));
			});
		},

		isDeductionSame: function () {
			var payroll = this.get('payrollDeduction');
			var zenefits = this.get('zenefitsDeduction');
			return this.isSameHelper(payroll, zenefits, 'deduction', 'deductionAnnualMaximum', 'isDeductionAsPercentage');
		}.property('payrollDeduction', 'zenefitsDeduction'),

		isContributionSame: function () {
			var payroll = this.get('payrollContribution');
			var zenefits = this.get('zenefitsContribution');
			return this.isSameHelper(payroll, zenefits, 'contribution', 'contributionAnnualMaximum', 'isContributionAsPercentage');
		}.property('payrollContribution', 'zenefitsContribution'),
	});

	App.SyncBackCompanyDeductionDiff = Ember.Model.extend({
		code: Ember.attr('string'),
		oldDescription: Ember.attr('string'),
		newDescription: Ember.attr('string'),
		syncBackReportRun: Ember.belongsTo('App.SyncBackReportRun'),
		type: function () {
			if (!this.get('oldDescription')) {
				return 'add';
			}
			if (!this.get('newDescription')) {
				return 'del';
			}
			return 'edit';
		}.property('oldDescription', 'newDescription'),
	});

	App.CompanyExemption = Ember.Model.extend({
		company: Ember.belongsTo('App.Company'),
		taxType: attr('string'),
		jurisdiction: attr('string'),
		// TODO(ed): when we have a good date picker, change this to date
		startDate: attr('string'),
		endDate: attr('string'),
		exemptionStatus: attr('string', { defaultValue: 'NEX' }),
		notExempt: function () {
			return this.get('exemptionStatus') === 'NEX';
		}.property('exemptionStatus'),
	});

	App.BusinessInsuranceEnrollment = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		lineOfCoverage: attr('string'),
		effectiveDate: attr('string'),
		policyStatus: attr('string'),
		policyTerm: attr('string'),
		netPremium: attr('number'),
		renewalDate: attr('string'),
		enrollmentType: attr('string'),
		enrollmentStatus: attr('string'),
		isEnrollmentActive: attr('boolean'),
		lineOfCoverageDisplayName: attr('string'),
		authName: attr('string'),
		authTitle: attr('string'),
		authPhone: attr('string'),
		authWebsite: attr('string'),
		authSignature: attr('string'),
		authDate: attr('string'),
		addExistingTimestamp: attr('date'),
		enrollmentCompleteTimestamp: attr('date'),
		isStatusComplete: function () {
			return this.get('enrollmentStatus') == 'complete';
		}.property('enrollmentStatus'),
		daysUntilRenewal: function () {
			if (this.get('renewalDate')) {
				var now = moment();
				var renewalDate = moment(this.get('renewalDate'), "MM/DD/YYYY");
				return renewalDate.diff(now, 'days');
			}
		}.property('renewalDate'),
	});

	App.BusinessInsuranceCarrier = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		businessInsuranceEnrollment: DS.belongsTo('App.BusinessInsuranceEnrollment'),
		carrier: DS.belongsTo('App.Carrier'),
		status: attr('string'),
		groupID: attr('string'),
		policyLetterUrl: attr('string'),
		approvalLetterUrl: attr('string'),
	});

	App.BusinessInsuranceInfoRequest = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		lineOfCoverage: attr('string'),
		status: attr('string'),
	});

	App.CompanyVerification = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		user: DS.belongsTo('App.User'),
		serviceType: attr('string'),
		status: attr('string'),
		verificationReference: attr('string')
	});

	App.PlaidInstitution = DS.Model.extend({
		typeCode: attr('string'),
		name: attr('string'),
		hasMfa: attr('boolean'),
		isAuthSupported: attr('boolean'),
		credentialFields: DS.hasMany('App.PlaidInstitutionCredentialField'),
		logoImageName: function () {
			return this.get('typeCode') + '.png';
		}.property('typeCode'),
		logoImageUrl: function () {
			var logoImageName = this.get('logoImageName') || 'default.png';
			var href = '/static/img/verification/banks/' + this.get('logoImageName');
			return href;
		}.property('logoImageName'),
		logoImageStyle: function () {
			var logoImageUrl = this.get('logoImageUrl');
			return "background-image: url('" + logoImageUrl + "');";
		}.property('logoImageUrl'),
	});

	App.PlaidInstitutionCredentialField = DS.Model.extend({
		institution: DS.belongsTo('App.PlaidInstitution'),
		fieldName: attr('string'),
		fieldLabel: attr('string'),
	});

	App.Signature = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee', { inverse: null }),
		signature: attr('string'),
		signatureName: attr('string'),
		signatureTitle: attr('string'),
		signatureDate: attr('string'),
		//add here
	});

	App.Partner = DS.Model.extend({
		name: attr('string'),
		loginLink: attr('string'),
		registerLink: attr('string'),
		partnerLogoClass: attr('string'),
		splitCommission: attr('string'),
		partnerBorName: attr('string'),
		partnerBorTaxID: attr('string'),
		percentageCommission: attr('number'),
	});


	App.EeoReport = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		companyId: attr('string'),
		userIdNORC: attr('string'),
		questionC1: attr('raw'),
		questionC2: attr('raw'),
		questionC3: attr('raw'),
		includeOptionals: attr('raw'),
		isReqQuestionsComplete: attr('boolean'),
		startDate: attr('string'),
		endDate: attr('string'),
		ReportType: attr('number'),
		year: attr('number')
	});

	App.TerminationSettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),

		isCompanyPerformingTerminations: attr('boolean'),
		defaultVoluntaryRegretted: attr('string'),
		defaultVoluntaryNonRegretted: attr('string'),
		defaultInvoluntary: attr('string'),

		eligibleForRehireIfVR: attr('raw'),
		eligibleForRehireIfVNR: attr('raw'),
		eligibleForRehireIfIV: attr('raw'),

		reassignEmployeesToManager: attr('raw'),
		reassignEmployeesDefaultPerson: DS.belongsTo('App.AllEmployee'),
		emailNewManagerWhenAssigned: attr('raw'),

		defaultCobraRule: DS.belongsTo('App.CobraRule'),
		federalCobraRule: DS.belongsTo('App.CobraRule'),
		stateCobraRule: DS.belongsTo('App.CobraRule'),
		companyCobraStatus: attr('string'),

		cancelAutoPay: attr('raw'),
		noFinalPaymentIfLastDay: attr('raw'),

		benefitsCoverageCycle: attr('string'),
		benefitsTermDateInvoluntary: attr('string'),
	});

	App.CheckEmployeeAttributeHistory = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		hasSalaryHistory: attr('boolean'),
		hasTitleHistory: attr('boolean'),
		hasLocationHistory: attr('boolean'),
		hasDepartmentHistory: attr('boolean'),
		hasManagerHistory: attr('boolean'),
		hasHireDateHistory: attr('boolean'),
		hasEmploymentTypeHistory: attr('boolean'),
		hasPayScheduleHistory: attr('boolean'),
	});

	App.EmployeeAttributeHistory = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		attributeType: attr('string'),
		changeDate: attr('string'),
		effectiveDate: attr('string'),
		changedBy: DS.hasMany('App.AllEmployee'),
		changedByNames: Ember.computed.mapBy('changedBy', 'informalFullName'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		reason: attr('string'),
		salary: attr('string'),
		salaryAnnual: function () {
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			return Number(Number(String(salary).replace(/,/g, '') * 12).toFixed(2)).toLocaleString();
		}.property('salary'),
		payRate: attr('string'),
		compType: attr('string'),
		compCurrency: attr('string'),
		isSalaryChange: Ember.computed.equal('attributeType', 'comp'),
		isTitleChange: Ember.computed.equal('attributeType', 'title'),
		isLocationChange: Ember.computed.equal('attributeType', 'location'),
		isDepartmentChange: Ember.computed.equal('attributeType', 'department'),
		isManagerChange: Ember.computed.equal('attributeType', 'manager'),
		isHireDateChange: Ember.computed.equal('attributeType', 'hireDate'),
		isEmploymentTypeChange: Ember.computed.equal('attributeType', 'employment'),
		isPayScheduleChange: Ember.computed.equal('attributeType', 'paySchedule'),
		isHourly: Ember.computed.equal('compType', 'H'),
		isSalaried: Ember.computed.equal('compType', 'S'),
		isRequesterSameAsApprover: attr('boolean'),
		title: attr('string'),
		location: DS.belongsTo('App.CompanyLocation'),
		department: DS.belongsTo('App.Department'),
		manager: DS.belongsTo('App.AllEmployee'),
		hireDate: attr('string'),
		employmentType: attr('string'),
		paySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		isBackFilled: attr('boolean'),
		showRequesterAsAdmin: function () {
			if (this.get('isBackFilled')) {
				return false;
			}
			return this.get('requestedBy.isAdmin');
		}.property('isBackFilled', 'requestedBy.isAdmin'),
		showRequester: function () {
			if (this.get('isBackFilled')) {
				return false;
			}
			return !this.get('isRequesterSameAsApprover');
		}.property('isRequesterSameAsApprover', 'isBackFilled'),
		showApprover: function () {
			if (this.get('isBackFilled')) {
				return false;
			}
			return true;
		}.property('isBackFilled'),
		isEffectiveDateInFuture: function () {
			var changeDate = this.get("changeDate");
			if (!changeDate || changeDate.length == 0) {
				return false;
			}
			changeDate = new Date(changeDate);
			return (changeDate > new Date());
		}.property('changeDate'),
		employmentTypeHelper: function () {
			return {
				'FT': 'Full Time',
				'PT': 'Part Time',
				'TP': 'Temp/Intern',
				'IN': 'Intern',
				'CO': 'Contingent Worker',
			}[this.get('employmentType')] || 'N/A';
		}.property('employmentType'),
		isChangeDateDifferentFromEffectiveDate: Ember.computed.arePropertiesUnEqual('changeDate', 'effectiveDate'),
	});

	App.EmployeeChangesHistory = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		changeDate: attr('string'),
		effectiveDate: attr('string'),
		changedBy: DS.hasMany('App.AllEmployee'),
		changedByNames: Ember.computed.mapBy('changedBy', 'informalFullName'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		reason: attr('string'),
		annualSalary: attr('string'),
		fixedAmount: attr('string'),
		annualSalaryInUSD: attr('number'),
		payRate: attr('string'),
		payRateInUSD: attr('number'),
		compType: attr('string'),
		compCurrency: attr('string'),
		isHourly: Ember.computed.equal('compType', 'H'),
		isSalaried: Ember.computed.equal('compType', 'S'),
		compensation: attr('raw'),
		isRequesterSameAsApprover: attr('boolean'),
		title: attr('string'),
		location: DS.belongsTo('App.CompanyLocation'),
		department: DS.belongsTo('App.Department'),
		manager: DS.belongsTo('App.AllEmployee'),
		hireDate: attr('string'),
		endDate: attr('string'),
		zeroSalaryReason: attr('string'),
		employmentType: attr('string'),
		workerType: attr('string'),
		paySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		isBackFilled: attr('boolean'),
		isFlsaExempt: attr('raw'),
		isFlsaExemptSystemComputed: attr('boolean'),
		hasNonExemptJobDuties: attr('raw'),
		isRehireChangeRequest: attr('boolean'),
		showTerminationDateAndRehireInformation: attr('boolean'),
		hasRehireChangeRequests: attr('boolean'),
		showRequester: function () {
			return !this.get('isRequesterSameAsApprover');
		}.property('isRequesterSameAsApprover'),
		isEffectiveDateInFuture: function () {
			var changeDate = this.get("changeDate");
			if (!changeDate || changeDate.length == 0) {
				return false;
			}
			changeDate = new Date(changeDate);
			return (changeDate > new Date());
		}.property('changeDate'),
		employmentTypeHelper: function () {
			return {
				'FT': 'Full Time',
				'PT': 'Part Time',
				'TP': 'Temp/Intern',
				'IN': 'Intern',
				'CO': 'Contingent Worker',
			}[this.get('employmentType')] || 'N/A';
		}.property('employmentType'),
		workerTypeHelper: Ember.computed('workerType', function () {
			return {
				"AW": "Agency-paid Temp",
				"CW": "Company-paid Temp",
				"VE": "Vendor Employee",
				"IC": "Independent Contractor",
				"IN": "Intern",
				"VO": "Volunteer",
			}[this.get('workerType')] || "N/A";
		}),
		showRecord: function () {
			if (this.get('employee.canAdminister')) {
				return true;
			} else if (this.get('employee.canManage')) {
				if (this.get('isRehireChangeRequest')) {
					return true;
				} else {
					if (this.get('hasRehireChangeRequests')) {
						return false;
					} else {
						return true;
					}
				}
			}
		}.property('employee.canAdminister', 'employee.canManage', 'isRehireChangeRequest', 'hasRehireChangeRequests'),
		changedCurrencyHasDollarSign: function () {
			var currency = this.get('compCurrency');
			const supportCurrencyList = ['AUD', 'BBD', 'BMD', 'BND', 'BSD', 'BZD', 'CAD', 'FJD', 'GYD', 'HKD', 'JMD', 'KYD', 'LRD', 'MXN', 'NAD', 'NZD', 'SBD', 'SGD', 'SRD', 'TTD', 'USD', 'XCD', 'ZWL'];
			return supportCurrencyList.includes(currency);
		}.property('compCurrency'),
		isChangeDateDifferentFromEffectiveDate: Ember.computed.arePropertiesUnEqual('changeDate', 'effectiveDate'),
	});

	App.EmployeeContact = DS.Model.extend({
		alternativePhone: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		isPersonalPhoneVisibleToPeers: attr('boolean')
	});

	App.ContractorDetail = DS.Model.extend({
		company_id: Ember.attr('number'),
		employee_id: Ember.attr('number'),
		employment_id: Ember.attr('number'),
		workerType: Ember.attr('string'),
		fixedAmount: Ember.attr('number'),
		w9CompletionDate: Ember.attr('string'),
	});

	App._EmployeeLockboxData = DS.Model.extend({
		firstName: attr('string'),
		lastName: attr('string'),
		middleName: attr('string'),
		first_name: Ember.computed.alias('firstName'),
		last_name: Ember.computed.alias('lastName'),
		middle_name: Ember.computed.alias('middleName'),
		fullName: attr('string'),
		preferredName: attr('string'),
		informalFirstName: function () {
			return this.get('preferredName') ? this.get('preferredName') : this.get('firstName');
		}.property('firstName', 'preferredName'),
		middleInitial: function () {
			return this.get('middleName').slice(0, 1);
		}.property('middleName'),
		informalFullName: function () {
			return (this.get('informalFirstName') || "") + " " + (this.get('lastName') || "").trim();
		}.property('informalFirstName', 'lastName'),
		email: attr('string'),
		userEmail: attr('string'),
		workEmail: attr('string'),
		address: attr('string'),
		address2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		phone: attr('string'),
		workPhone: attr('string'),
	});

	// begin data-deletion lockbox section
	(function () { // avoid functions escaping to global scope
		function _lockboxPromiseWrapper(usecase) {
			var queue = [];
			var lockboxKey = '_lockbox_' + usecase;
			function _lockboxGraphqlQuery(employee) {
				var employeesLockboxDataQuery = "query employeesLockboxData($usecase: String!, $employeeIds: [ID!]!) { employeesLockboxData(usecase: $usecase, employeeIds: $employeeIds) { employeeId, firstName, middleName, lastName, preferredName, fullName, socialSecurity, photoUrl, address, city, state, zip, email, phone, dob } } ";
				var employeeIdMap = {};
				while (queue.length) {
					var o = queue.pop();
					employeeIdMap[o.employee.get('id')] = o;
				}
				var employeesLockboxDataVars = {
					employeeIds: Object.keys(employeeIdMap),
					usecase: usecase,
				};
				/* eslint-disable no-lazy-globals */
				Ember.graphqlRequest(employeesLockboxDataQuery, employeesLockboxDataVars).then(function (response) {
					/* eslint-disable no-lazy-globals */
					try {
						response.data.employeesLockboxData.forEach(function (data) {
							var o = employeeIdMap[data.employeeId];
							var d = App._EmployeeLockboxData.create(data);
							// Return Employee object if the lockbox does not have the retained data.
							if (!(d.get('first_name') && d.get('last_name'))) {
								d = o.employee;
							}
							o.employee.set(lockboxKey, d);
							o.deferred.resolve(d);
						});
					} catch (error) {
						console.log(error);
					}
				}).catch(function (error) {
					console.log(error);
				});

			};
			return Ember.computed('isRedacted', function () {
				if (!this.get('isRedacted')) {
					return this;
				}
				if (this.get(lockboxKey)) {
					return this.get(lockboxKey);
				}
				var deferred = Ember.RSVP.defer();
				var promiseObj = Ember.PromiseObject.create({ promise: deferred.promise });
				queue.push({ "employee": this, "deferred": deferred });
				Ember.run.scheduleOnce('afterRender', null, _lockboxGraphqlQuery);
				this.set(lockboxKey, promiseObj);
				return promiseObj;
			});
		};
		App._EmployeeLockboxesMixin = Ember.Mixin.create({
			withPayrollPastPayRunHubLockbox: _lockboxPromiseWrapper('payroll_past_pay_run_hub'),
		});
	})();
	// end data-deletion lockbox section

	App.AllEmployee = DS.Model.extend(zen._CustomFieldValueSectionGroupsMixin, App._EmployeeLockboxesMixin, {
		notContractor: Ember.computed.not('isContractor'),
		contractor: DS.belongsTo('App.Contractor'),
		employeeContact: DS.belongsTo('App.EmployeeContact'),
		creationMethod: DS.attr('string'),
		customFieldValueSectionGroups: function () {
			return this.getCustomFieldValueSectionGroups('customFieldValues');
		}.property('customFieldValues'),
		arrangedCustomFieldValues: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['customField.rank'],
				content: this.get('customFieldValues'),
			});
		}.property('customFieldValues'),
		// this requires prefetch data otherwise wont work
		workerType: Ember.computed('currentEmployment.employmentType', function () {
			if (this.get('currentEmployment.employmentType') == 'CO') {
				return "Contingent Worker";
			} else {
				return null;
			}
		}),
		isWorkerOnLOA: attr('boolean'),
		typeOfContractor: Ember.computed('contractorDetails.[]', 'contractorDetails.isLoaded', 'contractorDetails.workerType', function () {
			const formatted = {
				'AW': 'Agency-paid Temp',
				'CW': 'Company-paid Temp',
				'VE': 'Vendor Employee',
				'IC': 'Independent Contractor',
				'IN': 'Intern',
				'VO': 'Volunteer'
			};
			return formatted[this.get('contractorDetails.firstObject.workerType')];
		}),
		description: Ember.computed('employmentType', function () {
			if (this.get('employmentType') == 'CO') {
				return ' - Contingent Worker';
			} else {
				return null;
			}
		}),
		badgeText: Ember.computed('employmentType', function () {
			return this.get('employmentType') == 'CO' ? 'C' : null;
		}),
		employeeCustomFieldValueSectionGroups: function () {
			return this.getCustomFieldValueSectionGroups('employeeCustomFieldValues');
		}.property('employeeCustomFieldValues'),
		permission: DS.belongsTo('App.EmployeePermission'),
		managerEmployeePermission: DS.belongsTo('App.ManagerEmployeePermission'),
		inboxSubActions: DS.hasMany('App.InboxSubAction'),
		version_id: attr('number'),
		objId: attr('number'),
		newHires: DS.hasMany('App.NewHire'),
		atsNewHires: DS.hasMany('App.AtsNewHire'),
		showEarlyEmailBanner: attr('boolean', { default: false }),
		newHireWithOfferLetter: function () {
			var newHiresExcludeBulkRequest = this.get('newHires').filterBy('fromBulkRequest', false);
			if (Ember.isEmpty(newHiresExcludeBulkRequest)) {
				return null;
			}
			return newHiresExcludeBulkRequest.filterBy('isDoingOfferLetter', true).rejectBy('status', 'complete').get('firstObject') || newHiresExcludeBulkRequest.get('lastObject');
		}.property('newHires.@each.isDoingOfferLetter', 'newHires.@each.status', 'newHires.@each.fromBulkRequest'),
		isEligibleAsApprover: function () {
			var typeList = ['RE', 'IN', 'AD'];
			return this.get('isTerminated') == false && typeList.indexOf(this.get('type')) != -1;
		}.property('isTerminated', 'type'),
		// WARNING: Potentially dangerous. Should be looking for offerLetterSig instead of the timestamp. Can be deprecated easily
		isReqOrSetWithUnSignedOffer: function () {
			return (this.get('status') == 'Req' || this.get('status') == 'Set') && this.get('newHireWithOfferLetter.status') != 'signed' && !this.get('newHireWithOfferLetter.signingCompleteTimestamp');
		}.property('status', 'newHireWithOfferLetter.status', 'newHireWithOfferLetter.signingCompleteTimestamp'),
		isReqAndNotSentForApproval: function () {
			return this.get('status') == 'Req' && this.get('newHireWithOfferLetter.status') == 'setting_up';
		}.property('status', 'newHireWithOfferLetter.status'),
		isReqOrSetAndNotSentForApproval: function () {
			return (this.get('status') == 'Req' || this.get('status') == 'Set') && this.get('newHireWithOfferLetter.status') == 'setting_up';
		}.property('status', 'newHireWithOfferLetter.status'),
		isReqAndSentForApproval: function () {
			return this.get('status') == 'Req' && this.get('newHireWithOfferLetter.status') == 'waiting_on_app';
		}.property('status', 'newHireWithOfferLetter.status'),
		// WARNING: Potentially dangerous. Should be looking for offerLetterSig instead of the timestamp. Can be deprecated easily
		isSetWithUnsignedOffer: function () {
			return this.get('status') == 'Set' && this.get('newHireWithOfferLetter.status') != 'signed' && !this.get('newHireWithOfferLetter.signingCompleteTimestamp');
		}.property('status', 'newHireWithOfferLetter.status', 'newHireWithOfferLetter.signingCompleteTimestamp'),
		isWithin30Days: function () {
			// TODO[kmcandrews]: Delete once employee tearsheet and team tearsheet
			// are deleted
			if (!this.get('hireDate')) {
				return false;
			}
			var isWithin30Days = false;
			var now = moment();
			var hireDate = moment(this.get('hireDate'), "MM/DD/YYYY");
			var diff = now.diff(hireDate, 'days');
			isWithin30Days = diff < 30;
			return isWithin30Days;
		}.property('hireDate'),
		isReqOrSet: function () {
			return this.get('status') == 'Req' || this.get('status') == 'Set';
		}.property('status'),
		isReqOrSetOrWithin30Days: function () {
			// TODO[kmcandrews]: Delete once employee tearsheet and team tearsheet
			// are deleted
			var alreadyHasTermination = this.get('isTerminated')
				|| this.get('isPendingTermination')
				|| this.get('isTerminationRequested');
			return this.get('isReqOrSet') || (this.get('isWithin30Days') && !alreadyHasTermination);
		}.property('isReqOrSet', 'isWithin30Days', 'isTerminated'),
		hasIncompleteEeoNewHire: function () {
			var incompleteEeoNewHire = this.get('newHires').filter(function (h) {
				return h.get('eeoAsked') && h.get('status') != 'complete';
			});
			return this.get('status') == 'Req' || this.get('status') == 'Set' || incompleteEeoNewHire.length > 0;
		}.property('status', 'newHires.@each.status', 'newHires.@each.eeoAsked'),
		isTerminatable: function () {
			if (this.get('isPendingTermination')
				|| this.get('isTerminationRequested')
				|| this.get('isTerminated')) {
				return false;
			}
			if (this.get('isExternalAdmin')) {
				return false;
			}
			// if we do not add !Ember.isEmpty(this.get('hireDate')) , and if hireDate is empty,
			// 	isTerminatable will be true because !moment().isBefore(this.get('hireDate')) is true
			return (this.get('status') == 'Set' || this.get('isActive')) && !Ember.isEmpty(this.get('hireDate')) && !moment().isBefore(this.get('hireDate'));
		}.property(
			'hireDate',
			'isActive',
			'isExternalAdmin',
			'isPendingTermination',
			'isTerminated',
			'isTerminationRequested',
			'status'
		),
		showOnboardingToPayrollAction: function () {
			return this.get('type') === 'RE' &&
				this.get('company.hasActiveSyncedPayroll') &&
				!this.get('newHireWithOfferLetter.isDoingOnboarding') &&
				!this.get('payrollId') &&
				!this.get('inDE9C');
		}.property(
			'company.hasActiveSyncedPayroll',
			'inDE9C',
			'newHireWithOfferLetter.isDoingOnboarding',
			'payrollId',
			'type'
		),
		enrolledHealthProducts: function () {
			var products = [];
			if (this.get('medicalStatus') === 'approved' || this.get('medicalStatus') === 'complete') {
				products.push('medical');
			}
			if (this.get('dentalStatus') === 'approved' || this.get('dentalStatus') === 'complete') {
				products.push('dental');
			}
			if (this.get('visionStatus') === 'approved' || this.get('visionStatus') === 'complete') {
				products.push('vision');
			}
			return products;
		}.property('medicalStatus', 'dentalStatus', 'visionStatus'),
		enrolledProducts: function () {
			var products = [];
			products = products.concat(this.get('enrolledHealthProducts') || []);
			if (this.get('lifeEnrollment.status') == _LDEAbstractModel.prototype.APPROVED ||
				this.get('lifeEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
				products.push('Life');
			}
			if (this.get('addEnrollment.status') == _LDEAbstractModel.prototype.APPROVED ||
				this.get('addEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
				products.push('AD&D');
			}
			if (this.get('stdEnrollment.status') == _LDEAbstractModel.prototype.APPROVED ||
				this.get('stdEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
				products.push('Std');
			}
			if (this.get('ltdEnrollment.status') == _LDEAbstractModel.prototype.APPROVED ||
				this.get('ltdEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
				products.push('Ltd');
			}
			if (this.get('is401kSetupComplete')) {
				products.push('401(k)');
			}
			if (this.get('fsa.status') === 'complete') {
				products.push('FSA');
			}
			if (this.get('hsa.status') === 'complete') {
				products.push('HSA');
			}
			if (this.get('hra.firstAvailablePlan.status') === 'complete') {
				products.push('HRA');
			}

			return products.join(', ');
		}.property('enrolledHealthProducts',
			'is401kSetupComplete', 'fsa.status', 'hsa.status', 'hra.firstAvailablePlan.status',
			'lifeEnrollment.status', 'addEnrollment.status', 'stdEnrollment.status', 'ltdEnrollment.status'),
		isEnrolledInPayrollOrProducts: function () {
			return this.get('payrollId') || this.get('enrolledProducts') !== "";
		}.property('payrollId', 'enrolledProducts'),
		newEmployeeDirectoryStatusFilterHelper: function () {
			if (this.get('isSettingUp')) {
				return 'Onboarding Incomplete';
			} else {
				var res = this.get('employeeDirectoryStatusHelper');
				if (res.status.indexOf('Terminated') == 0) {
					res.status = 'Terminated';
				}
				return res.status;
			}
		}.property('employeeDirectoryStatusHelper'),
		newEmployeeDirectoryStatus: function () {
			if (this.get('isSettingUp')) {
				return 'Onboarding Incomplete';
			} else {
				var str = this.get('employeeDirectoryStatusHelper.status');
				if (str === 'Terminated') {
					var terminationDate = this.get('terminationDate');
					if (terminationDate) {
						str += ' (' + terminationDate + ')';
					}
				}
				return str;
			}
		}.property('employeeDirectoryStatusHelper', 'terminationDate'),
		employeeDirectoryStatusHelper: function () {
			var status = '';
			var banner = '';
			try {
				if (this.get('isActive')) {
					status = this.get('employmentTypeHelper');
					if (this.get('isTerminationRequested')) {
						status += ' (Termination Requested)';
						banner = 'termination_requested';
					} else if (this.get('isPendingTermination')) {
						status += ' (Pending Termination)';
						banner = 'termination_in_progress';
					}
					return { status: status, banner: banner };
				}
				if (this.get('isTerminated')) {
					status = 'Terminated';
					if (this.get('terminationDate')) {
						status += ' (' + this.get('terminationDate') + ')';
					}
					return { status: status, banner: 'terminated' };
				}
				if (this.get('isRequested')) {
					if (this.get('newHireWithOfferLetter.status') == 'setting_up') {
						return { status: 'Not sent, edit to complete set-up', banner: 'offer_not_completed' };
					}
					if (this.get('newHireWithOfferLetter.status') == 'waiting_on_app') {
						return { status: 'Not sent, waiting for approval', banner: 'offer_not_approved' };
					}
				}
				if (this.get('isSettingUp')) {
					if (this.get('newHireWithOfferLetter.status') == 'setting_up') {
						return { status: 'Not sent, edit to complete set-up', banner: 'offer_not_completed' };
					}
					if (this.get('newHireWithOfferLetter.isDoingOfferLetter')) {
						if (this.get('newHireWithOfferLetter.offerLetterSig')) {
							status = 'Signed Offer letter. ';
						} else {
							status = 'Offer letter not signed. ';
							banner = 'offer_not_signed';
						}
					}
					if (this.get('newHireWithOfferLetter.isDoingOnboarding')) {
						if (this.get('newHireWithOfferLetter.isDoingOfferLetter')) {
							if (this.get('newHireWithOfferLetter.offerLetterSig')) {
								status += 'Onboarding In Progress';
								banner = 'onboarding_in_progress';
							}
						} else {
							status += 'Onboarding In Progress';
						}
					} else {
						if (this.get('newHireWithOfferLetter.isDoingTax')) {
							status += this.get('isInternational') ? 'Tax ' : 'W-4 ';
						}
						if (this.get('newHireWithOfferLetter.isDoingCustomFields') && this.get('isEmployeeFillableCustomFieldsPresent')) {
							status += 'Custom Fields, ';
						}
						if (this.get('newHireWithOfferLetter.isDoingEligibility')) {
							status += this.get('isInternational') ? 'Employment Eligibility ' : 'I-9 ';
						}
						status += 'In Progress';
					}
					return { status: status, banner: banner };
				}
			} catch (error) {
				console.log(error);
			}
			return { status: status, banner: banner };
		}.property('isActive', 'isInternational', 'intlEmploymentTypeHelper', 'employmentTypeIncludingWorkerTypesHelper', 'employmentTypeHelper', 'isTerminationRequested',
			'isPendingTermination', 'isTerminated', 'isRequested', 'isSettingUp', 'newHireWithOfferLetter.status', 'newHireWithOfferLetter.isDoingOfferLetter',
			'newHireWithOfferLetter.offerLetterSig', 'newHireWithOfferLetter.isDoingTax', 'newHireWithOfferLetter.isDoingCustomFields',
			'newHireWithOfferLetter.isDoingEligibility', 'isEmployeeFillableCustomFieldsPresent'),
		employeeDirectoryStatusBannerMessage: function () {
			try {
				var banner = this.get('employeeDirectoryStatusHelper').banner;
				var date = '';
				if (this.get('terminationDate')) {
					date = ' on ' + this.get('terminationDate');
				}
				switch (banner) {
					case 'offer_not_completed':
						return "This employee's offer is not complete. The offer letter will not be sent until you've entered the missing details.";
					case 'offer_not_approved':
						return "This employee's offer has not been approved yet. This offer letter will not be sent until this done.";
					case 'offer_not_signed':
						return "This employee has not signed their offer letter yet.";
					case 'onboarding_in_progress':
						return "This employee has signed their offer letter, but hasn't completed onboarding.";
					case 'termination_requested':
						return "A request has been submitted to terminate this employee.";
					case 'termination_in_progress':
						return "This employee will be terminated" + date;
					case 'terminated':
						return "This employee was terminated" + date;
				}
			} catch (error) {
				console.log(error);
			}
			return '';
		}.property('employeeDirectoryStatusHelper', 'terminationDate'),
		paystubsEmployee: DS.hasMany('App.PaystubEmployee'), // new paystubs model
		hasPaystubsEmployee: Ember.computed.gt('paystubsEmployee.length', 0),
		ta: DS.belongsTo('App.TaEmployee'),
		first_name: attr('string'), // do not use for display, please use informalFirstName instead
		firstName: Ember.computed.alias('first_name'), // do not use for display, please use informalFirstName instead
		last_name: attr('string'),
		lastName: Ember.computed.alias('last_name'),
		middle_name: attr('string'),
		fullName: function () {
			return (this.get('firstName') || "") + " " + (this.get('lastName') || "").trim();
		}.property('firstName', 'lastName'),
		fullNameInverted: function () {
			return this.get('last_name') + ", " + this.get('informalFirstName');
		}.property('first_name', 'last_name'),
		preferredName: attr('string'),
		informalFirstName: function () {
			return this.get('preferredName') ? this.get('preferredName') : this.get('first_name');
		}.property('first_name', 'preferredName'),
		middleInitial: function () {
			return this.get('middle_name').slice(0, 1);
		}.property('middle_name'),
		informalFullName: function () {
			return (this.get('informalFirstName') || "") + " " + (this.get('last_name') || "").trim();
		}.property('informalFirstName', 'last_name'),
		firstNamePossesive: function () {
			var name = this.get('first_name');
			if (name.toLocaleLowerCase().endsWith('s')) {
				return name + "'";
			}
			return name + "'s";
		}.property('first_name'),
		fullNameWithEmail: function () {
			return (this.get('fullName')) + " (" + (this.get('email')) + ")"
		}.property('fullName', 'email'),
		fullNameWithWokerType: Ember.computed('fullName', 'employmentType', function () {
			return this.get('employmentType') == 'CO' ? this.get('fullName') + ' - Contingent Worker' : this.get('fullName');
		}),
		hourlySalary: function () {
			var hourlySalary = 0.00;
			if (this.get('isSalary') && Ember.$.isNumeric(this.get('salary'))) {
				hourlySalary = (parseFloat(this.get('salary')) * 12) / (52 * 40);
			} else if (this.get('isHourly') && Ember.$.isNumeric(this.get('payRate'))) {
				hourlySalary = parseFloat(this.get('payRate'));
			}
			return Number(hourlySalary);
		}.property('isSalary', 'isHourly', 'salary', 'payRate'),
		// monthlySalary handles the salary for hourly employees but otherwise is same as salary
		monthlySalary: function () {
			var monthlySalary = 0.00;
			if (this.get('isSalary') && Ember.$.isNumeric(this.get('salary'))) {
				return this.get('salary');
			} else if (this.get('isHourly') && Ember.$.isNumeric(this.get('payRate'))) {
				var hourlySalary = parseFloat(this.get('payRate'));
				monthlySalary = hourlySalary * 40 * 52 / 12;
			}
			return Number(monthlySalary).toFixed(2);
		}.property('isSalary', 'isHourly', 'salary', 'payRate'),
		// projectedAnnualSalary handles the salary for hourly employees but otherwise is same as salaryAnnual
		projectedAnnualSalary: function () {
			var monthlySalary = this.get('monthlySalary');
			if (monthlySalary) {
				return Number(monthlySalary * 12).toFixed(2);
			}
			return 0;
		}.property('isSalary', 'isHourly', 'salary', 'payRate'),
		isRedacted: function () {
			// Returns true if the Employee's data has been deleted per their request
			if (this.get('status') === 'Ter' && this.get('first_name') === 'Redacted') {
				return true;
			} else {
				return false;
			}
		}.property('status', 'first_name'),
		hasMinimalFields: Ember.computed.and('first_name', 'last_name'),
		hasFirstOrLastName: Ember.computed.or('first_name', 'last_name'),
		email: attr('string'),
		userEmail: attr('string'),
		workEmail: attr('string'),
		hasEmail: Ember.computed.notEmpty('email'),
		emailGuessed: attr('string'), /* Used only on the client side */
		address: attr('string'),
		address2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		qeCreated: attr('boolean'),
		phone: attr('string'),
		workPhone: attr('string'),
		workPhoneExtension: attr('string'),
		hireDate: attr('string'),
		hireDateMonth: function () {
			if (!this.get('hireDate')) {
				return '';
			}
			return moment(this.get('hireDate')).format('MMM');
		}.property('hireDate'),
		hireDateDay: function () {
			if (!this.get('hireDate')) {
				return '';
			}
			return moment(this.get('hireDate')).format('DD');
		}.property('hireDate'),
		hireDateSortValue: function () {
			if (this.get('hireDate')) {
				var dateMMDDYYYY = this.get('hireDate').split('/');
				dateMMDDYYYY.unshift(dateMMDDYYYY.pop());
				return dateMMDDYYYY.join('');
			}
			return '';
		}.property('hireDate'),
		isPastHireDate: attr('boolean'),
		showInOrgChart: function () {
			return this.get('isAllActiveEmployee') && (this.get('canAdminister') || this.get('isTeamMember') || this.get('isPastHireDate'));
		}.property('isAllActiveEmployee', 'canAdminister', 'isTeamMember', 'isPastHireDate'),
		socialSecurity: attr('string'),
		newSocialSecurity: attr('string'),
		socialSecurityEnc: attr('string'),
		isWaitingForSSNInfo: attr('boolean'),
		socialSecurityExpectedDate: attr('string'),
		socialSecurityProofUrl: attr('string'),
		hasSufficientSSNInfo: function () {
			return (this.get('socialSecurity') || (this.get('socialSecurityExpectedDate') && this.get('socialSecurityProofUrl')));
		}.property('socialSecurity', 'socialSecurityExpectedDate', 'socialSecurityProofUrl'),
		dob: attr('string'),
		dateOfBirth: Ember.computed.alias('dob'),
		currentAge: function () {
			var dob = moment(this.get('dob'), ['MM/DD/YY', 'MM/DD/YYYY']);
			if (dob.isValid()) {
				return moment().diff(dob, 'years');
			}
		}.property('dob'),
		age: attr('number'),
		isUnder18YearsOld: function () {
			return moment().diff(this.get('dob'), 'years') < 18;
		}.property('dob'),
		tShirtSize: attr('string'),
		dietaryRestrictions: attr('string'),
		title: attr('string'),
		department: DS.belongsTo('App.Department'),
		emergencyContacts: DS.belongsTo('App.EmergencyContacts'),
		employeeEeo: DS.belongsTo('App.EmployeeEeo'),
		eeoInfoComplete: function () {
			return this.get('employeeEeo.eeoJobCategory') && this.get('employeeEeo.eeoRace');
		}.property('employeeEeo.eeoJobCategory', 'employeeEeo.eeoRace'),
		reportToEmployee: DS.belongsTo('App.AllEmployee', { inverse: null }),
		employeeTaxFields: DS.belongsTo('App.EmployeeTaxFields'),
		federalTaxes: DS.hasMany('App.EmployeeFederalTax'),
		stateTaxes: DS.hasMany('App.EmployeeStateTax'),
		payroll: DS.belongsTo('App.PayrollEmployeeSettings'),
		zpEmployee: DS.belongsTo('App.ZPayrollEmployee'),
		isWfSupervisor: Ember.computed.alias('payroll.isWfSupervisor'),
		wfSupervisorPayrollId: Ember.computed.alias('payroll.wfSupervisorPayrollId'),
		user: DS.belongsTo('App.User'),
		location: DS.belongsTo('App.CompanyLocation'),
		rawCustomFieldValues: DS.hasMany('App.CustomFieldValue'),
		rawEmployeeCustomFieldValues: DS.hasMany('App.CustomFieldValue'),
		allAgreements: DS.hasMany("App.AgreementEhe"),
		allFsaAgreements: DS.hasMany("App.AgreementEmployeeFsa"),
		allCobraAgreements: DS.hasMany("App.AgreementEmployeeCobra"),
		isSkippingBgcheck: attr('boolean'),
		canAccessContractors: Ember.computed.or('permission.isAdmin', 'permission.contractorOnly'),
		isAdmin: Ember.computed.oneWay('permission.isAdmin'),
		isCompanyAdmin: Ember.computed.oneWay('permission.isMainAdmin'),
		isExternalAdmin: Ember.computed.equal('type', 'AD'),
		isStockHolder: Ember.computed.equal('type', 'SH'),
		employeePermissionHelp: function () {
			if (this.get('isCompanyAdmin')) {
				return 'Main Admin';
			}
			if (this.get('isExternalAdmin')) {
				return 'External Admin';
			}
			if (this.get('isAdmin')) {
				return 'Employee Admin';
			}
			if (this.get('isStockHolder')) {
				return 'Stock Holder';
			}
			return 'Employee';
		}.property('isAdmin', 'isCompanyAdmin', 'isExternalAdmin', 'isStockHolder'),
		canViewSubordinates: Ember.computed.alias('permission.canViewSubordinates'),
		isRegistered: attr('boolean'),
		isRequester: attr('boolean'),
		isTeamMember: attr('boolean'),
		canManage: attr('boolean'),
		canAdminister: attr('boolean'),
		canManageOrAdminister: Ember.computed.or('canManage', 'canAdminister'),
		employeeTags: DS.hasMany("App.EmployeeTag"),
		isManager: attr('boolean'),
		// The following places need to be in sync
		// BorEmployeeWizardNav isComplete in django and ember, EmployeeDAO.isEmployeeDetailsComplete
		updatedRecords: function () {
			if (this.get('first_name') &&
				this.get('email') &&
				this.get('address') &&
				this.get('city') &&
				this.get('state') &&
				this.get('zip') &&
				this.get('socialSecurity') &&
				this.get('dob') &&
				this.get('sex') &&
				this.get('phone')
			) {
				return true;
			}
			return false;
		}.property('first_name', 'email', 'address', 'city', 'state', 'zip', 'socialSecurity', 'dob', 'sex', 'status', 'phone'),
		// Special fields (for compliance)
		keyEmployee: function () {
			var isKeyEmployee = this.get('isKeyEmployee');
			if (isKeyEmployee == null) {
				return null;
			}
			return '' + isKeyEmployee;
		}.property('isKeyEmployee'),
		isKeyEmployee: attr('raw'),
		showBankAndTaxSection: function () {
			//always show bank and tax section to admins
			if (this.get('canAdminister')) {
				return true;
			}
			if (this.get('company.benefitsOnly')) {
				return false;
			}
			var hasCompDetails = this.get('salary') || this.get('payRate');
			return !this.get('isWaitingForSSNInfo') && hasCompDetails;
		}.property('canAdminister', 'salary', 'payRate', 'isWaitingForSSNInfo', 'company.benefitsOnly'),
		highlyCompensated: function () {
			var isHighlyCompensated = this.get('isHighlyCompensated');
			if (isHighlyCompensated == null) {
				return null;
			}
			return '' + isHighlyCompensated;
		}.property('isHighlyCompensated'),
		isHighlyCompensated: attr('raw'),

		photoUrl: attr("string"),
		photoThumbnailUrl: attr("string"),
		avatarPhotoThumbnailUrl: function () {
			if (this.get('photoThumbnailUrl')) {
				return this.get('photoThumbnailUrl') + '?.png';
			}
			else {
				return "";
			}
		}.property('photoThumbnailUrl'),
		photoHuman: function () {
			if (this.get('photoUrl')) {
				return 'Uploaded';
			}
			return 'Not Uploaded';
		}.property('photoUrl'),

		// TAX
		hasMultiStateTaxSetup: function () {
			// duplicating logic from hasMultiStateTaxSetup in register_company/models.py
			return this.get('isMultiStateTaxRequired') || this.get('residenceTaxState');
		}.property('isMultiStateTaxRequired', 'residenceTaxState'),
		isMultiStateTaxRequired: function () {
			// duplicating logic from isMultiStateTaxRequired in register_company/models.py
			if (!this.get('isInternational') && this.get('residenceState') && this.get('workState')) {
				return this.get('workState') != this.get('residenceState');
			}
			return false;
		}.property('isInternational', 'residenceState', 'workState'),
		workState: function () {
			// duplicating logic from workState in register_company/models.py
			if (this.get('isInternational')) {
				return null;
			}
			if (this.get('location')) {
				return this.get('location.state');
			}
			else {
				return this.get('taxState');
			}
		}.property('isInternational', 'location.state', 'taxState'),
		residenceState: function () {
			// duplicating logic from residenceState in register_company/models.py
			if (this.get('isInternational')) {
				return null;
			}
			return this.get('state') || this.get('residenceTaxState');
		}.property('isInternational', 'state', 'residenceTaxState'),

		currentFederalTax: function () {
			var currFedTax = this.get('federalTaxes').filterProperty('isActive', true).get('lastObject');
			return currFedTax;
		}.property('federalTaxes', 'federalTaxes.@each.isActive'),
		federalWithholdingAllowance: function () {
			return this.get('currentFederalTax.federalWithholdingAllowance');
		}.property('currentFederalTax.federalWithholdingAllowance'),
		federalFilingStatus: function () {
			return this.get('currentFederalTax.federalFilingStatus');
		}.property('currentFederalTax.federalFilingStatus'),
		federalFormVersion: function () {
			return this.get('currentFederalTax.formVersion');
		}.property('currentFederalTax.formVersion'),
		workStateFormVersion: function () {
			return this.get('currentWorkStateTax.formVersion');
		}.property('currentWorkStateTax.formVersion'),
		residenceStateFormVersion: function () {
			return this.get('currentResidenceStateTax.formVersion');
		}.property('currentResidenceStateTax.formVersion'),
		additionalFederalWitholdings: function () {
			return this.get('currentFederalTax.additionalFederalWitholdings');
		}.property('currentFederalTax.additionalFederalWitholdings'),

		currentWorkStateTax: function () {
			return this.get('stateTaxes').filterProperty('isActive', true).filterProperty('isWorkState', true).get('lastObject');
		}.property('stateTaxes', 'stateTaxes.@each.isActive', 'stateTaxes.@each.isWorkState'),
		currentResidenceStateTax: function () {
			return this.get('stateTaxes').filterProperty('isActive', true).filterProperty('isWorkState', false).get('lastObject');
		}.property('stateTaxes', 'stateTaxes.@each.isActive', 'stateTaxes.@each.isWorkState'),
		taxState: function () {
			return this.get('currentWorkStateTax.taxState');
		}.property('currentWorkStateTax.taxState'),
		residenceTaxState: function () {
			return this.get('currentResidenceStateTax.taxState');
		}.property('currentResidenceStateTax.taxState'),
		stateWithholdingAllowance: function () {
			return this.get('currentWorkStateTax.stateWithholdingAllowance');
		}.property('currentWorkStateTax.stateWithholdingAllowance'),
		stateFilingStatus: function () {
			return this.get('currentWorkStateTax.stateFilingStatus');
		}.property('currentWorkStateTax.stateFilingStatus'),
		additionalStateWitholdings: function () {
			return this.get('currentWorkStateTax.additionalStateWitholdings');
		}.property('currentWorkStateTax.additionalStateWitholdings'),
		localWithholdingAllowance: function () {
			return this.get('currentWorkStateTax.localWithholdingAllowance');
		}.property('currentWorkStateTax.localWithholdingAllowance'),
		additionalLocalWithholdings: function () {
			return this.get('currentWorkStateTax.additionalLocalWithholdings');
		}.property('currentWorkStateTax.additionalLocalWithholdings'),
		residenceStateWithholdingAllowance: function () {
			return this.get('currentResidenceStateTax.stateWithholdingAllowance');
		}.property('currentResidenceStateTax.stateWithholdingAllowance'),
		residenceStateFilingStatus: function () {
			return this.get('currentResidenceStateTax.stateFilingStatus');
		}.property('currentResidenceStateTax.stateFilingStatus'),
		residenceStateAdditionalWithholdings: function () {
			return this.get('currentResidenceStateTax.additionalStateWitholdings');
		}.property('currentResidenceStateTax.additionalStateWitholdings'),
		residenceStateLocalWithholdingAllowance: function () {
			return this.get('currentResidenceStateTax.localWithholdingAllowance');
		}.property('currentResidenceStateTax.localWithholdingAllowance'),
		residenceStateAdditionalLocalWithholdings: function () {
			return this.get('currentResidenceStateTax.additionalLocalWithholdings');
		}.property('currentResidenceStateTax.additionalLocalWithholdings'),

		hasAdditionalFederalWitholdings: Ember.computed.gt('additionalFederalWitholdings', 0),
		hasAdditionalStateWitholdings: Ember.computed.gt('additionalStateWitholdings', 0),
		hasAdditionalResidenceStateWitholdings: Ember.computed.gt('residenceStateAdditionalWithholdings', 0),
		hasCertOfNonResidenceForTaxState: attr('raw'),
		employeeBenefitsInfo: DS.belongsTo('App.EmployeeBenefitsInfo'),
		// LOCAL TAX META DATA
		livesInNyc: attr('boolean'),
		livesInYonkers: attr('boolean'),
		livesInMetroMultnomah: attr('boolean'),
		worksInMetroMultnomah: attr('boolean'),
		livesInMichiganLocalTaxedCities: attr('boolean'),
		worksInMichiganLocalTaxedCities: attr('boolean'),
		federalFilingStatusDescription: function () {
			var federalFilingStatus = this.get('federalFilingStatus');
			var federalFormVersion = this.get('federalFormVersion');
			var statusOptions = zen.FEDERAL_FILING_STATUS_OPTIONS[federalFormVersion]
			var statusObject = statusOptions.filter(function (o) {
				return o.id == federalFilingStatus;
			});
			return statusObject.length && statusObject[0].name;
		}.property('federalFilingStatus', 'federalFormVersion'),

		stateFilingStatusDescription: function () {
			var stateFilingStatus = this.get('stateFilingStatus');
			var workStateFormVersion = this.get('workStateFormVersion');
			var state = this.get('taxState') || this.get('state');
			var statusOptions = STATE_FILING_STATUSES[state] || zen.FEDERAL_FILING_STATUS_OPTIONS[workStateFormVersion];
			var statusObject = statusOptions.filter(function (o) {
				return o.id == stateFilingStatus;
			});
			return statusObject.length && statusObject[0].name;
		}.property('stateFilingStatus', 'taxState', 'workStateFormVersion'),

		residenceStateFilingStatusDescription: function () {
			var stateFilingStatus = this.get('residenceStateFilingStatus');
			var residenceStateFormVersion = this.get('residenceStateFormVersion');
			var state = this.get('state');
			var statusOptions = STATE_FILING_STATUSES[state] || zen.FEDERAL_FILING_STATUS_OPTIONS[residenceStateFormVersion];
			var statusObject = statusOptions.filter(function (o) {
				return o.id == stateFilingStatus;
			});
			return statusObject.length && statusObject[0].name;
		}.property('residenceStateFilingStatus', 'state', 'residenceStateFormVersion'),

		nonResidenceTaxStateFormUrl: function () {
			var NON_RESIDENCE_CERT_TAX_FORM_URLS = {
				'AZ': 'https://gao.az.gov/sites/default/files/APG_V_C_a_A-4.pdf',
				'AR': 'http://www.dfa.arkansas.gov/offices/incomeTax/withholding/Documents/AR4EC(TX).pdf',
				'CT': 'https://portal.ct.gov/-/media/DRS/Forms/2019/Withholding/2019-CT-W4NA.pdf?la=en',
				'DE': 'https://revenuefiles.delaware.gov/docs/WithholdingWorksheet_NonResident.pdf',
				'DC': 'https://otr.cfo.dc.gov/sites/default/files/dc/sites/otr/publication/attachments/2018%20D-4A.pdf',
				'HI': 'http://files.hawaii.gov/tax/forms/2018/hw6.pdf',
				'IL': 'https://www2.illinois.gov/rev/forms/withholding/Documents/currentyear/il-w-5-nr.pdf#search=W%2D5%2DNR',
				'IN': 'http://www.in.gov/dor/4100.htm',
				'IA': 'https://tax.iowa.gov/sites/default/files/2019-08/EmployeesStatementOfNonresidence%2844016%29.pdf',
				'KS': 'http://www.ksrevenue.org/pdf/k-4c.pdf',
				'KY': 'https://revenue.ky.gov/Forms/42A809.pdf',
				'MD': 'http://forms.marylandtaxes.com/current_forms/MW507.pdf',
				'MI': 'https://secure.zenefits.com/static/pdf/Michigan_nonresident_certificate.pdf',
				'MN': 'https://www.revenue.state.mn.us/sites/default/files/2019-06/mwr_19.pdf',
				'MO': 'https://dor.mo.gov/forms/MO%20W-4A_2019.pdf',
				'MT': 'https://app.mt.gov/myrevenue/Endpoint/DownloadPdf?yearId=871',
				'NE': 'http://www.revenue.nebraska.gov/tax/current/fill-in/f_w4na.pdf',
				'NJ': 'http://www.state.nj.us/treasury/taxation/pdf/current/nj165.pdf',
				'NY': 'https://www.tax.ny.gov/pdf/current_forms/it/it2104_1_fill_in.pdf',
				'ND': 'https://www.nd.gov/tax/data/upfiles/media/ndwrfillable.pdf',
				'OH': 'https://www.tax.ohio.gov/portals/0/forms/employer_withholding/Generic/WTH_IT4NR.pdf',
				'PA': 'https://www.revenue.pa.gov/FormsandPublications/FormsforBusinesses/EmployerWithholding/Documents/rev-419.pdf',
				'VA': 'https://www.tax.virginia.gov/sites/default/files/taxforms/withholding/any/va-4-any.pdf',
				'WV': 'https://tax.wv.gov/Documents/TaxForms/it104.pdf',
				'WI': 'https://www.revenue.wi.gov/TaxForms2017through2019/w-220f.pdf',
			};
			return NON_RESIDENCE_CERT_TAX_FORM_URLS[this.get('taxState') || this.get('location.state')];
		}.property('location.state', 'taxState'),

		taxFormUrl: function (state) {
			var STATE_TAX_FORM_URLS = {
				'AL': 'https://revenue.alabama.gov/wp-content/uploads/2017/05/A4_201403.pdf',
				'AR': 'https://www.dfa.arkansas.gov/images/uploads/incomeTaxOffice/AR4EC.pdf',
				'AZ': 'https://gao.az.gov/sites/default/files/APG_V_C_a_A-4.pdf',
				'CA': 'https://www.edd.ca.gov/pdf_pub_ctr/de4.pdf',
				'CT': 'https://portal.ct.gov/-/media/DRS/Forms/2019/Withholding/2019-CT-W4.pdf?la=en',
				'DC': 'https://www.exim.gov/sites/default/files/newsreleases/DCStateTaxForm-1.pdf',
				'GA': 'https://dor.georgia.gov/sites/dor.georgia.gov/files/related_files/document/TSD/Form/TSD_Employees_Withholding_Allowance_Certificate_G-4.pdf',
				'IA': 'https://tax.iowa.gov/sites/default/files/idr/forms1/2019IAW-4%2844019%29_2.pdf',
				'IL': 'https://www2.illinois.gov/rev/forms/withholding/Documents/currentyear/il-w-4.pdf',
				'IN': 'https://forms.in.gov/Download.aspx?id=2702',
				'KS': 'https://www.ksrevenue.org/pdf/k-4.pdf',
				'KY': 'https://revenue.ky.gov/forms/42a8041113.pdf',
				'LA': 'http://revenue.louisiana.gov/TaxForms/1300(4_11)F.pdf',
				'MA': 'https://www.mass.gov/doc/form-m-4-massachusetts-employees-withholding-exemption-certificate/download',
				'MD': 'https://forms.marylandtaxes.gov/18_forms/MW507.pdf',
				'ME': 'https://www.maine.gov/nrsc/forms/Maine%20W-4%20(Updated%202018).pdf',
				'MI': 'https://www.michigan.gov/documents/taxes/MI-W4_370050_7.pdf',
				'MN': 'https://www.revenue.state.mn.us/sites/default/files/2019-09/w-4mn.pdf',
				'MO': 'https://dor.mo.gov/forms/MO%20W-4.pdf',
				'MS': 'https://www.dor.ms.gov/Business/Documents/89350188.pdf',
				'MT': 'https://app.mt.gov/myrevenue/Endpoint/Form/367',
				'NC': 'https://files.nc.gov/ncdor/documents/files/NC-4-Web.pdf',
				'ND': 'https://www.irs.gov/pub/irs-pdf/fw4.pdf',
				'NE': 'https://revenue.nebraska.gov/files/doc/tax-forms/f_w4.pdf',
				'NJ': 'https://www.state.nj.us/treasury/taxation/pdf/current/njw4.pdf',
				'NY': 'https://www.tax.ny.gov/pdf/current_forms/it/it2104_fill_in.pdf',
				'OH': 'https://www.tax.ohio.gov/portals/0/forms/employer_withholding/generic/wth_it4.pdf',
				'OR': 'https://www.oregon.gov/DOR/forms/FormsPubs/form-or-W-4_101-402_2019.pdf',
				'RI': 'http://www.tax.ri.gov/forms/2018/Withholding/RI%20W-4%202018.pdf',
				'VA': 'https://www.tax.virginia.gov/sites/default/files/taxforms/withholding/any/va-4-any.pdf',
				'VT': 'https://tax.vermont.gov/sites/tax/files/documents/W-4VT.pdf',
				'WI': 'https://www.revenue.wi.gov/TaxForms2017through2019/w-204f.pdf',
				'WV': 'https://tax.wv.gov/Documents/TaxForms/it104.pdf'
			};
			return STATE_TAX_FORM_URLS[state];
		},
		// TODO(Rad): What about missing states, how does that work?
		stateTaxFormUrl: function () {
			return this.taxFormUrl(this.get('taxState'));
		}.property('taxState'),

		residenceStateTaxFormUrl: function () {
			return this.taxFormUrl(this.get('state'));
		}.property('state'),

		w4Signature: DS.belongsTo('App.Signature'),

		ageRange: attr('string'),
		ageProxy: Ember.computed(function (key, value) {
			if (arguments.length < 2) {
				var ageRange = this.get('ageRange');

				if (ageRange) {
					return ageRange;
				}

				var dob = this.get('dob');

				if (dob) {
					var age = moment().diff(dob, 'years');

					this.set('ageRange', age);
				}
			} else {
				this.set('ageRange', value);
			}

			return this.get('ageRange');
		}).property('ageRange', 'dob'),
		customFieldValues: Ember.computed.filterByProperty('rawCustomFieldValues', 'isNotFiltered'),
		employeeCustomFieldValues: Ember.computed.filterByProperty('rawEmployeeCustomFieldValues', 'isNotFiltered'),
		_fullyLoadedCustomFieldValues: function () {
			return this.get('customFieldValues').filterProperty('customFieldLoaded');
		}.property('customFieldValues.@each.customFieldLoaded'),
		isCustomFieldsPresent: Ember.computed.notEmpty('_fullyLoadedCustomFieldValues'),
		isEmployerHiringCustomFieldsPresent: Ember.computed.anyProperty('_fullyLoadedCustomFieldValues', 'customField.employerDuringHiring'),
		isEmployeeCustomFieldsPresent: Ember.computed.anyProperty('_fullyLoadedCustomFieldValues', 'customField.canEmployeeViewField'),
		isEmployeeOnboardingCustomFieldsPresent: Ember.computed.anyProperty('_fullyLoadedCustomFieldValues', 'customField.employeeDuringOnboarding'),
		isEmployeeFillableCustomFieldsPresent: Ember.computed.anyProperty('_fullyLoadedCustomFieldValues', 'customField.fieldCompleterEmployee'),

		_requiredIncompleteCustomFieldValues: function () {
			return this.get('_fullyLoadedCustomFieldValues').filterBy('customField.isFieldRequired').filter(function (fieldValue) {
				return Ember.isEmpty(fieldValue.get('value'));
			});
		}.property('_fullyLoadedCustomFieldValues'),
		isEmployerHiringCustomFieldsComplete: function () {
			return Ember.isEmpty(this.get('_requiredIncompleteCustomFieldValues').filterBy('customField.employerDuringHiring'));
		}.property('_requiredIncompleteCustomFieldValues'),
		isEmployeeCustomFieldsComplete: function () {
			return Ember.isEmpty(this.get('_requiredIncompleteCustomFieldValues').filterBy('customField.fieldCompleterEmployee'));
		}.property('_requiredIncompleteCustomFieldValues'),
		isEmployeeOnboardingCustomFieldsComplete: function () {
			return Ember.isEmpty(this.get('_requiredIncompleteCustomFieldValues').filterBy('customField.employeeDuringOnboarding'));
		}.property('_requiredIncompleteCustomFieldValues'),

		sex: attr('string'),
		gender: Ember.computed.alias('sex'),
		sexHuman: function () {
			var sex = this.get('sex');
			if (sex == 'M') { return 'Male'; }
			if (sex == 'F') { return 'Female'; }
			return sex;
		}.property('sex'),
		genderText: Ember.computed.alias('sexHuman'),
		personalPronounsId: attr('number'),
		personalPronouns: function () {
			var personalPronounsMap = App.PersonalPronounMap;
			var personalPronounsId = this.get('personalPronounsId');
			if (personalPronounsId === null || personalPronounsId === undefined) {
				return '';
			}
			var pronouns = personalPronounsMap.get(personalPronounsId.toString());
			if (pronouns) {
				return pronouns.get("subjective").capitalize() + '/' + pronouns.get("objective").capitalize() + '/' + pronouns.get('possessive').capitalize();
			}
			return '';
		}.property('personalPronounsId'),
		genderIdentity: attr('string'),
		status: attr('string'),
		allStatus: attr('string'),
		employmentType: attr('string'),
		employmentTypeIncludingWorkerTypes: attr('string'),
		fixedAmount: attr('string'),
		compType: attr('string'),
		isHourly: Ember.computed.equal('compType', 'H'),
		isSalary: Ember.computed.equal('compType', 'S'),
		isFixedAmount: Ember.computed.equal('compType', 'A'),
		compTypeHuman: function () {
			var compType = this.get('compType');
			if (compType == 'H') { return 'Hourly'; }
			if (compType == 'S') { return 'Salaried'; }
			if (compType == 'A') { return 'Fixed Amount'; }
			if (compType == 'N') { return 'Not Applicable'; }
			return compType;
		}.property('compType'),
		compTypeDetail: function () {
			if (this.get('isHourly')) {
				return 'Hourly';
			}
			if (this.get('isSalary')) {
				var isFlsaExempt = this.get('currentEmployment.isFlsaExempt');
				var shouldBeSalaried = isFlsaExempt || Ember.isNone(isFlsaExempt);

				return shouldBeSalaried ? 'Salaried (exempt)' : 'Salaried (non-exempt)';
			}
			if (this.get('isFixedAmount')) {
				return 'Fixed Amount';
			}
			return '';
		}.property('compType', 'currentEmployment.isFlsaExempt'),
		isSalariedExempt: Ember.computed('currentEmployment.isFlsaExempt', 'isSalary', function () {
			if (this.get('isSalary')) {
				var isFlsaExempt = this.get('currentEmployment.isFlsaExempt');
				return isFlsaExempt || Ember.isNone(isFlsaExempt);
			}
			return false;
		}),
		shouldDoEligibility: Ember.computed('employmentTypeIncludingWorkerTypes', 'isInternational', function () {
			const eligibilityBlackArray = ['AW', 'VO', 'VE', 'IC'];
			return !this.get('isInternational') && !eligibilityBlackArray.includes(this.get('employmentTypeIncludingWorkerTypes'));
		}),
		shouldDoEeo: Ember.computed.alias('shouldDoEligibility'),
		payrollId: attr('string'),
		employeePayrollProperties: DS.belongsTo('App.EmployeePayrollProperties'),
		isInPayroll: Ember.computed.alias('employeePayrollProperties.isInPayroll'),
		payFrequency: function () {
			var overridePayFrequency = this.get('overridePayFrequency');
			if (overridePayFrequency != null) {
				return overridePayFrequency;
			}
			return this.get('payroll.payFrequency');
		}.property('overridePayFrequency', 'payroll.payFrequency'),
		payFrequencyHuman: function () {
			switch (this.get('payFrequency')) {
				case 'SM': return "Semi-monthly";
				case 'BW': return "Bi-weekly";
				case 'Mo': return "Monthly";
				case 'We': return "Weekly";
			}
		}.property('payFrequency'),
		paySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		selectedPaySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		overridePayFrequency: attr('string'),
		overrideAnchorPayDate: attr('string'),
		standardHours: attr('string'),
		payRate: attr('string'),
		hasPayRate: Ember.computed.notNone('payRate'),
		overtimeRate: function () {
			return this.get('payRate') * 1.5;
		}.property('payRate'),
		salary: attr('string'),
		hasSalary: Ember.computed.notNone('salary'),
		salaryByPayFrequency: function () {
			var compType = this.get('compType');
			var payRate = this.get('payRate');
			var salary = this.get('salary');
			if (compType == 'H' && payRate) {
				salary = (payRate * 52 * 40 / 12);
			}
			if (salary == null || salary == "") {
				return "";
			}
			var payFreq = this.get('payFrequency');
			if (payFreq == 'SM') {
				salary *= 0.5;
			} else if (payFreq == 'BW') {
				salary *= (1 / 26) * 12;
			} else if (payFreq == 'We') {
				salary *= (1 / 52) * 12;
			} else if (payFreq == 'Mo') {
				salary *= 1;
			} else {
				salary *= 1; // assume default payFrequency to be Monthly
			}
			return Number(Number(String(salary).replace(/,/g, '')).toFixed(2)).toLocaleString();
		}.property('salary', 'payFrequency', 'compType', 'payRate'),
		salaryCommas: function () {
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			return Math.round(String(salary).replace(/,/g, '')).toLocaleString();
		}.property('salary'),
		salaryAnnual: function () {
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			return Number(Number(String(salary).replace(/,/g, '') * 12).toFixed(2)).toLocaleString();
		}.property('salary'),
		salaryAnnualNoCommas: function () {
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			return Number(Number(String(salary).replace(/,/g, '') * 12).toFixed(2)).toString();
		}.property('salary'),
		currency: attr('string'),
		currencyHasDollarSign: function () {
			const currency = this.get('currency');
			const currencyList = ['AUD', 'BBD', 'BMD', 'BND', 'BSD', 'BZD', 'CAD', 'FJD', 'GYD', 'HKD', 'JMD', 'KYD', 'LRD', 'MXN', 'NAD', 'NZD', 'SBD', 'SGD', 'SRD', 'TTD', 'USD', 'XCD', 'ZWL'];
			return currencyList.includes(currency);
		}.property('currency'),
		annualSalaryInUSD: Ember.computed.alias('currentEmployment.annualSalaryInUSD'),
		payRateInUSD: Ember.computed.alias('currentEmployment.payRateInUSD'),
		payRateCommas: Ember.computed.commas('payRate'),
		compHourlyOrAnnual: Ember.computed('compType', 'contractorDetails', 'contractorDetails.isLoaded', function () {
			var compType = this.get('compType');
			var payRate = this.get('payRate');
			var salary = this.get('salary');
			var employmentType = this.get('employmentType');

			if (compType == 'H' && payRate) {
				return +this.get('payRate');
			} else if (compType == 'S' && salary) {
				return +this.get('salary') * 12;
			} else if (employmentType == 'CO') {

				if (this.get('contractorDetails')) {
					const contractors = this.get('contractorDetails').content || [];
					const employee_id = this.get('id');
					const contractor = contractors.filter(function (c) {
						return (c && c.fixedAmount && c.get('employee_id') == employee_id);
					});
					if (contractor.length > 0) {
						return +contractor[0].get('fixedAmount');
					} else {
						return null;
					}
				}
				else if (compType == 'A') {
					return +this.get('fixedAmount');
				}
				return null;
			} else {
				return null;
			}
		}),
		/* Used for L&D volume calculation. We assume 40 hr week for hourly employees */
		annualCompensation: function () {
			if (this.get('compType') == 'H') {
				return this.get('payRate') * 40 * 52;
			}
			if (this.get('compType') == 'S') {
				return this.get('salaryAnnualNoCommas');
			}
			return null;
		}.property('salaryAnnualNoCommas', 'payRate', 'compType'),
		compHourlyOrAnnualForeignCurrency: function () {
			var comp = this.get('compHourlyOrAnnual');
			if (comp == null) {
				return "";
			}

			var curString = this.get('currency');
			var compWithComma = Number(Number(String(comp).replace(/,/g, '')).toFixed(2)).toLocaleString();

			if (curString && curString !== 'USD') {
				return curString + ' ' + compWithComma;
			}
			else {
				return compWithComma;
			}
		}.property('compHourlyOrAnnual'),
		fullTimeStartDate: Ember.computed.alias('currentEmployment.fullTimeStartDate'),
		fullTimeEndDate: Ember.computed.alias('currentEmployment.fullTimeEndDate'),
		terminationDate: attr('string'),
		terminationType: attr('string'),
		terminationTypeHelper: function () {
			return {
				IN: 'Involuntary',
				IR: 'Involuntary, Can Rehire',
				IO: 'Involuntary, No Rehire',
				VR: 'Voluntary, Can Rehire',
				VN: 'Voluntary, No Rehire',
				UN: 'Unclassified',
			}[this.get('terminationType')] || '';
		}.property('terminationType'),
		terminationCategory: attr('string'),
		dependents: DS.hasMany('App.Dependent'),
		selfDependent: Ember.computed.findByProperty('dependents', 'isSelf'),
		nonSelfDependents: Ember.computed.filterByProperty('dependents', 'isSelf', false),
		nonSelfDepCountHuman: function () {
			var numNonSelfDependents = this.get('nonSelfDependents.length');
			if (numNonSelfDependents && numNonSelfDependents > 0) {
				var plural = numNonSelfDependents > 1 ? 's' : '';
				return numNonSelfDependents + ' dependent' + plural;
			}
			return 'No dependent';
		}.property('nonSelfDependents.length'),
		quoteDependents: DS.hasMany('App.QuoteDependent'),
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		inDE9C: attr('raw'),
		underMedicare: attr('boolean'),
		employeeHealthStatusProperties: DS.belongsTo('App.EmployeeHealthStatusProperties'),
		enrollmentStatus: Ember.computed.alias('employeeHealthStatusProperties.enrollmentStatus'),
		medicalStatus: Ember.computed.alias('employeeHealthStatusProperties.medicalStatus'),
		dentalStatus: Ember.computed.alias('employeeHealthStatusProperties.dentalStatus'),
		visionStatus: Ember.computed.alias('employeeHealthStatusProperties.visionStatus'),
		hasStatusSet: Ember.computed.any('medicalStatus', 'dentalStatus', 'visionStatus'),
		medicalApprovalStatus: attr('string'),
		dentalApprovalStatus: attr('string'),
		visionApprovalStatus: attr('string'),
		employeeTieredProperties: DS.belongsTo('App.EmployeeTieredProperties'),
		medicalTieredContributionField: Ember.computed.alias('employeeTieredProperties.medicalTieredContributionField'),
		dentalTieredContributionField: Ember.computed.alias('employeeTieredProperties.dentalTieredContributionField'),
		visionTieredContributionField: Ember.computed.alias('employeeTieredProperties.visionTieredContributionField'),
		tieredWaitingPeriodField: Ember.computed.alias('employeeTieredProperties.tieredWaitingPeriodField'),
		employeeImplementationProperties: DS.belongsTo('App.EmployeeImplementationProperties'),
		isMedicalInImplementation: Ember.computed.alias('employeeImplementationProperties.isMedicalInImplementation'),
		isDentalInImplementation: Ember.computed.alias('employeeImplementationProperties.isDentalInImplementation'),
		isVisionInImplementation: Ember.computed.alias('employeeImplementationProperties.isVisionInImplementation'),
		medicalEnrollmentStage: function () {
			if (this.get('medicalStatus') == 'approved') {
				return "Covered";
			} else if (this.get('medicalStatus') == 'decline') {
				return "Coverage Declined";
			} else if (this.get('medicalStatus') == 'complete') {
				return "Applied for Coverage";
			} else if (this.get('isMedicalInImplementation')) {
				return "In Implementation";
			} else {
				return "Waiting on Employee";
			}
		}.property('medicalStatus', 'isMedicalInImplementation'),
		dentalEnrollmentStage: function () {
			if (this.get('dentalStatus') == 'approved') {
				return "Covered";
			} else if (this.get('dentalStatus') == 'decline') {
				return "Coverage Declined";
			} else if (this.get('dentalStatus') == 'complete') {
				return "Applied for Coverage";
			} else if (this.get('isDentalInImplementation')) {
				return "In Implementation";
			} else {
				return "Waiting on Employee";
			}
		}.property('dentalStatus', 'isDentalInImplementation'),
		visionEnrollmentStage: function () {
			if (this.get('visionStatus') == 'approved') {
				return "Covered";
			} else if (this.get('visionStatus') == 'decline') {
				return "Coverage Declined";
			} else if (this.get('visionStatus') == 'complete') {
				return "Applied for Coverage";
			} else if (this.get('isVisionInImplementation')) {
				return "In Implementation";
			} else {
				return "Waiting on Employee";
			}
		}.property('visionStatus', 'isVisionInImplementation'),
		numberOfDependents: function () {
			// was  max(0, len(allDependents) - 1) on the python side
			var length = this.get('dependents.length');
			return Math.max(0, length - 1);
		}.property('dependents.length'),
		employeeSettings: DS.belongsTo('App.EmployeeSettings'),
		selectedPlan: attr('number'),
		selectedMedicalPlan: Ember.computed.alias('selectedPlan'),
		medicalPlan: DS.belongsTo('App.Plan'),
		medicalPlanName: function () {
			if (this.get('medicalPlan')) {
				return this.get('medicalPlan.name');
			}
			if (this.get('medicalApprovalStatus') == 'decline') {
				return 'Declined';
			}
			return 'Not Enrolled';
		}.property('medicalPlan', 'medicalApprovalStatus', 'medicalPlan.isLoaded'),
		selectedDentalPlan: attr('number'),
		company: DS.belongsTo('App.Company'),
		dentalPlan: DS.belongsTo('App.DentalPlan'),
		dentalPlanName: function () {
			if (this.get('dentalPlan')) {
				return this.get('dentalPlan.name');
			}
			if (this.get('dentalApprovalStatus') == 'decline') {
				return 'Declined';
			}
			return 'Not Enrolled';
		}.property('dentalPlan', 'dentalApprovalStatus', 'dentalPlan.isLoaded'),
		selectedVisionPlan: attr('number'),
		visionPlan: DS.belongsTo('App.VisionPlan'),
		visionPlanName: function () {
			if (this.get('visionPlan')) {
				return this.get('visionPlan.name');
			}
			if (this.get('visionApprovalStatus') == 'decline') {
				return 'Declined';
			}
			return 'Not Enrolled';
		}.property('visionPlan', 'visionApprovalStatus', 'visionPlan.isLoaded'),
		beneficiaries: DS.hasMany('App.Beneficiary'),
		pto: DS.belongsTo('App.EmployeePto'),
		employeeEnrollmentProperties: DS.belongsTo('App.EmployeeEnrollmentProperties'),
		currentEnrollment: Ember.computed.alias('currentMedicalEnrollment'),
		allEnrollments: Ember.computed.alias('employeeEnrollmentProperties.allEnrollments'),
		currentMedicalEnrollment: Ember.computed.alias('employeeEnrollmentProperties.currentMedicalEnrollment'),
		currentDentalEnrollment: Ember.computed.alias('employeeEnrollmentProperties.currentDentalEnrollment'),
		currentVisionEnrollment: Ember.computed.alias('employeeEnrollmentProperties.currentVisionEnrollment'),

		lifeDisabilityEnrollments: DS.hasMany('App.EmployeeLifeDisabilityEnrollment'),
		getLifeDisabilityEnrollment: function (lineOfCoverage) {
			return this.get('lifeDisabilityEnrollments').findProperty('lineOfCoverage', lineOfCoverage);
		},
		lifeEnrollment: function () {
			return this.getLifeDisabilityEnrollment(_LDEAbstractModel.prototype.LIFE);
		}.property('lifeDisabilityEnrollments.@each'),
		addEnrollment: function () {
			return this.getLifeDisabilityEnrollment(_LDEAbstractModel.prototype.ADD);
		}.property('lifeDisabilityEnrollments.@each'),
		stdEnrollment: function () {
			return this.getLifeDisabilityEnrollment(_LDEAbstractModel.prototype.STD);
		}.property('lifeDisabilityEnrollments.@each'),
		ltdEnrollment: function () {
			return this.getLifeDisabilityEnrollment(_LDEAbstractModel.prototype.LTD);
		}.property('lifeDisabilityEnrollments.@each'),

		dependentCoverageChanged: function () {
			var currentEnrollment = this.get('currentEnrollment');
			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			var currentVisionEnrollment = this.get('currentVisionEnrollment');

			if ((currentEnrollment && currentEnrollment.get('dependentCoverageChanged')) ||
				(currentDentalEnrollment && currentDentalEnrollment.get('dependentCoverageChanged')) ||
				(currentVisionEnrollment && currentVisionEnrollment.get('dependentCoverageChanged'))) {
				return true;
			}

			return false;
		}.property('currentEnrollment', 'currentEnrollment.dependentCoverageChanged',
			'currentDentalEnrollment', 'currentDentalEnrollment.dependentCoverageChanged',
			'currentVisionEnrollment', 'currentVisionEnrollment.dependentCoverageChanged'),

		qualifyingEvents: DS.hasMany('App.QualifyingEvent'),

		switchCarrierLinesOfCoverage: function () {
			// used for ZApps, update server fn if you change this!
			var linesOfCoverage = [], count = 0;
			if (this.get('currentEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'medical';
			}
			if (this.get('currentDentalEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'dental';
			}
			if (this.get('currentVisionEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'vision';
			}

			return linesOfCoverage.join(', ');
		}.property('currentEnrollment', 'currentEnrollment.isSwitchCarrierEnrollment',
			'currentDentalEnrollment', 'currentDentalEnrollment.isSwitchCarrierEnrollment',
			'currentVisionEnrollment', 'currentVisionEnrollment.isSwitchCarrierEnrollment'),
		switchCarrierLinesOfCoverageDisplayText: function () {
			var linesOfCoverage = [], count = 0;
			if (this.get('currentEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'Medical';
			}
			if (this.get('currentDentalEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'Dental';
			}
			if (this.get('currentVisionEnrollment.isSwitchCarrierEnrollment')) {
				linesOfCoverage[count++] = 'Vision';
			}

			return linesOfCoverage.join(', ');
		}.property('currentEnrollment', 'currentEnrollment.isSwitchCarrierEnrollment',
			'currentDentalEnrollment', 'currentDentalEnrollment.isSwitchCarrierEnrollment',
			'currentVisionEnrollment', 'currentVisionEnrollment.isSwitchCarrierEnrollment'),
		switchCarrierCompletedLinesOfCoverage: function () {
			// used for ZApps, update server fn if you change this!
			var linesOfCoverage = [], count = 0;
			if (this.get('currentEnrollment.isSwitchCarrierEnrollment') && this.get('currentEnrollment.isComplete')) {
				linesOfCoverage[count++] = 'medical';
			}
			if (this.get('currentDentalEnrollment.isSwitchCarrierEnrollment') && this.get('currentDentalEnrollment.isComplete')) {
				linesOfCoverage[count++] = 'dental';
			}
			if (this.get('currentVisionEnrollment.isSwitchCarrierEnrollment') && this.get('currentVisionEnrollment.isComplete')) {
				linesOfCoverage[count++] = 'vision';
			}

			return linesOfCoverage.join(', ');
		}.property('currentEnrollment', 'currentEnrollment.isSwitchCarrierEnrollment', 'currentEnrollment.isComplete',
			'currentDentalEnrollment', 'currentDentalEnrollment.isSwitchCarrierEnrollment', 'currentDentalEnrollment.isComplete',
			'currentVisionEnrollment', 'currentVisionEnrollment.isSwitchCarrierEnrollment', 'currentVisionEnrollment.isComplete'),
		switchCarrierDeclinedLinesOfCoverage: function () {
			// used for ZApps, update server fn if you change this!
			var linesOfCoverage = [], count = 0;
			if (this.get('currentEnrollment.isSwitchCarrierEnrollment') && this.get('currentEnrollment.hasDeclined')) {
				linesOfCoverage[count++] = 'medical';
			}
			if (this.get('currentDentalEnrollment.isSwitchCarrierEnrollment') && this.get('currentDentalEnrollment.hasDeclined')) {
				linesOfCoverage[count++] = 'dental';
			}
			if (this.get('currentVisionEnrollment.isSwitchCarrierEnrollment') && this.get('currentVisionEnrollment.hasDeclined')) {
				linesOfCoverage[count++] = 'vision';
			}

			return linesOfCoverage.join(', ');
		}.property('currentEnrollment', 'currentEnrollment.isSwitchCarrierEnrollment', 'currentEnrollment.hasDeclined',
			'currentDentalEnrollment', 'currentDentalEnrollment.isSwitchCarrierEnrollment', 'currentDentalEnrollment.hasDeclined',
			'currentVisionEnrollment', 'currentVisionEnrollment.isSwitchCarrierEnrollment', 'currentVisionEnrollment.hasDeclined'),
		isOEAncillaryActionRequired: function () {
			return this.get('currentDentalEnrollment') && this.get('currentDentalEnrollment.type') == 'open_enrollment' &&
				this.get('currentVisionEnrollment') && this.get('currentVisionEnrollment.type') == 'open_enrollment';
		}.property('currentDentalEnrollment', 'currentVisionEnrollment', 'currentDentalEnrollment.type', 'currentVisionEnrollment.type'),
		hasBenefits: function () {
			if (this.get('enrollmentStatus') == 'approved' || this.get('enrollmentStatus') == 'complete') {
				return true;
			}
			if (this.get('enrollmentStatus') == 'decline') {
				if (this.get('selectedVisionPlan') != null || this.get('selectedDentalPlan') != null) {
					return true;
				}
			}
			return false;
		}.property('enrollmentStatus', 'selectedVisionPlan', 'selectedDentalPlan'),
		hasHsa: function () {
			return this.get('hsa.status') == 'complete' && this.get('isFulltime') && !this.get('isTerminated');
		}.property('hsa.status', 'isFulltime', 'isTerminated'),
		showHsa: function () {
			var status = this.get('hsa.status');
			if (status != 'complete') {
				return false;
			}
			return this.get('hasHsa');
		}.property('hsa.status', 'hasHsa'),
		hsaPlan: function () {
			if (this.get('hsa.status') == 'complete') {
				return this.get('hsa');
			}
			return null;
		}.property('hsa', 'hsa.status'),

		hasDependents: Ember.computed.gt('numberOfDependents', 0),
		hsa: DS.belongsTo('App.HsaEmployeeEnrollment'),
		employee401kEnrollment: DS.belongsTo('App.Employee401kEnrollment'),
		changeRequests: DS.hasMany('App.ChangeRequest'),
		is401kSetupComplete: Ember.computed.oneWay('employee401kEnrollment.isSetupComplete'),
		company401kDollarContribution: Ember.computed.oneWay('employee401kEnrollment.actualCompanyContribution'),
		fsa: DS.belongsTo('App.FsaEmployeeEnrollment'),
		hra: DS.belongsTo('App.HraEmployeeEnrollment'),
		unicardCommuter: DS.belongsTo('App.UnicardCommuterEmployeeEnrollment'),
		alegeusSettings: DS.belongsTo('App.AlegeusEmployeeSettings'),
		taReportingMethod: attr('string'),
		hasPin: attr('boolean'),
		authorizedSigner: DS.belongsTo('App.AuthorizedSigner'),
		// document urls
		hasNewhireDocuments: attr('boolean'),
		offerLetterUrl: attr('string'),
		ipAssignmentUrl: attr('string'),
		employeeHandbookUrl: attr('string'),
		gbStarterChecklistUrl: function () {
			if (this.get('isInternational') && this.get('currentFederalTaxIntl.employeeSignature_id') && this.get('country') == 'GB') {
				return '/forms/' + this.get('id') + '/gbStarterChecklist';
			}
			return null;
		}.property('isInternational', 'currentFederalTaxIntl.employeeSignature_id', 'country'),
		nlWageTaxFormUrl: function () {
			if (this.get('isInternational') && this.get('currentFederalTaxIntl.employeeSignature_id') && this.get('country') == 'NL') {
				return '/forms/' + this.get('id') + '/nlWageTaxForm';
			}
			return null;
		}.property('isInternational', 'currentFederalTaxIntl.employeeSignature_id', 'country'),
		caTd1FederalUrl: function () {
			if (this.get('isInternational') && this.get('currentFederalTaxIntl.employeeSignature_id') && this.get('country') == 'CA') {
				return '/forms/' + this.get('id') + '/caTd1Federal';
			}
			return null;
		}.property('isInternational', 'currentFederalTaxIntl.employeeSignature_id', 'country'),
		caTd1StateUrl: function () {
			if (this.get('isInternational') && this.get('currentFederalTaxIntl.employeeSignature_id') && this.get('country') == 'CA') {
				return '/forms/' + this.get('id') + '/caTd1State';
			}
			return null;
		}.property('isInternational', 'currentFederalTaxIntl.employeeSignature_id', 'country'),
		customDocuments: DS.hasMany('App.EmployeeCustomDocument'),
		hasCustomDocuments: Ember.computed.gt('customDocuments.length', 0),
		hasDocuments: function () {
			return (this.get('newHires.length') > 0 &&
				(this.get('newHires').findProperty('isDoingOfferLetter') ||
					this.get('newHires').findProperty('isDoingEmployeeHandbook') ||
					this.get('newHires').findProperty('isDoingAgreements') ||
					this.get('newHires').findProperty('nonExemptNoticeUrl'))) ||
				this.get('hasCustomDocuments') ||
				this.get('allAgreements.length') > 0 ||
				this.get('currentFederalTax.employeeSignature') ||
				this.get('gbStarterChecklistUrl') ||
				this.get('nlWageTaxFormUrl') ||
				this.get('caTd1FederalUrl') ||
				this.get('caTd1StateUrl') ||
				this.get('fsa.summaryPlanDescriptionUrl') ||
				this.get('hra.summaryPlanDescriptionUrl') ||
				this.get('fsa.agreement.url') ||
				this.get('hra.firstAvailablePlan.agreement.url') ||
				this.get('unicardCommuter.agreement.url') ||
				this.get('employeeCobra.agreementConfirmation.url') ||
				this.get('employeeCobra.agreementCancellation.url');
		}.property(
			'newHires.@each.isDoingOfferLetter',
			'newHires.@each.isDoingAgreements',
			'newHires.@each.nonExemptNoticeUrl',
			'hasCustomDocuments',
			'currentFederalTax.employeeSignature',
			'gbStarterChecklistUrl',
			'nlWageTaxFormUrl',
			'caTd1FederalUrl',
			'caTd1StateUrl',
			'employeeCobra.agreementConfirmation.url',
			'employeeCobra.agreementCancellation.url'
		),
		hasI9: function () {
			return this.get('newHires').find(function (h) { return h.get('isComplete') && h.get('isDoingEligibility'); });
		}.property('newHires.@each.isComplete', 'newHires.@each.isDoingEligibility'),
		// document upload
		type: attr('string'),
		isEmployee: Ember.computed.match('type', /RE|TE|IN/),
		isFullTimeEmployee: Ember.computed.equal('type', 'RE'),
		isAllEmployee: Ember.computed.alias('isEmployee'),
		isAllActiveEmployee: Ember.computed.and('isAllEmployee', 'isAllActive'),
		isAllActiveEmployeesIncludingAdmins: Ember.computed('type', 'allStatus', function () {
			if (this.get('type') == 'AD') {
				return this.get('allStatus') == '' || this.get('allStatus') == 'Act';
			}
			return this.get('allStatus') == 'Act';
		}),
		isAllActiveFullTimeEmployee: Ember.computed.and('isFullTimeEmployee', 'isAllActive'),
		isEligibleForHealth: attr('boolean'),
		isEligibleForMedical: attr('boolean'),
		isEligibleForDental: attr('boolean'),
		isEligibleForVision: attr('boolean'),
		isEligibleForLifeDisability: attr('boolean'),
		isEligibleForLifeAndAdd: attr('boolean'),
		isEligibleForLife: attr('boolean'),
		isEligibleForAdd: attr('boolean'),
		isEligibleForSTD: attr('boolean'),
		isEligibleForLTD: attr('boolean'),
		isEligibleForHSA: attr('boolean'),
		isEligibleForFSA: attr('boolean'),
		isEligibleForHRA: attr('boolean'),
		isEligibleForCommuter: attr('boolean'),
		isEligibleFor401K: attr('boolean'),
		isEligibleForPTO: attr('boolean'),
		isEligibleForTA: attr('boolean'),
		isEligibleForSO: attr('boolean'),
		isEligibleForCobra: attr('boolean'),
		isEligibleForExpenses: attr('boolean'),
		isEligibleForAccident: attr('boolean'),
		isEligibleForCancer: attr('boolean'),
		isEligibleForCriticalIllness: attr('boolean'),
		isEligibleForHospitalIndemnity: attr('boolean'),
		isInternationalEmployee: attr('boolean'),
		employeeBenefitsOfferingProperties: DS.belongsTo('App.EmployeeBenefitsOfferingProperties'),
		isMedicalOffered: Ember.computed.alias('employeeBenefitsOfferingProperties.isMedicalOffered'),
		isDentalOffered: Ember.computed.alias('employeeBenefitsOfferingProperties.isDentalOffered'),
		isVisionOffered: Ember.computed.alias('employeeBenefitsOfferingProperties.isVisionOffered'),
		isFulltime: Ember.computed.equal('employmentType', 'FT'),
		isParttime: Ember.computed.equal('employmentType', 'PT'),
		// the old variable is isTP and new one is isIN, isTemp is to cover the gap before we delete the old code but turn on the switch for new code already during which both isTP and isIN exists
		isTP: Ember.computed.equal('employmentType', 'TP'),
		isIN: Ember.computed.equal('employmentType', 'IN'),
		isTemp: Ember.computed.or('isTP', 'isIN'),
		isEligibleForBenefits: Ember.computed.or('isEligibleForHealth', 'isEligibleForLifeDisability'),
		isMissingHeadcountChartFields: function () {
			var fields = ['title', 'department.name', 'location.name', 'employmentType', 'hireDate'];
			for (var i = 0; i < fields.length; i++) { //forEach doesn't work here for some reason
				var field = this.get(fields[i]);
				if (field == null || field == undefined) { return true; }
			}
			return false;
		}.property('title'),
		isMissingCompensationChartFields: function () {
			var fields = ['title', 'department.name', 'location.name', 'employmentType', 'compType', 'compHourlyOrAnnual'];
			for (var i = 0; i < fields.length; i++) { //forEach doesn't work here for some reason
				var field = this.get(fields[i]);
				if (field == null || field == undefined) { return true; }
			}
			return false;
		}.property('title'),
		isMissingTurnoverChartFields: function () {
			var fields = ['title', 'department.name', 'location.name', 'hireDate'];
			for (var i = 0; i < fields.length; i++) { //forEach doesn't work here for some reason
				var field = this.get(fields[i]);
				if (field == null || field == undefined) { return true; }
			}
			return false;
		}.property('title'),
		mainStatusHelper: function (status) {
			return {
				'Req': 'Waiting-for-approval',
				'Act': 'Active',
				'Del': 'Deleted',
				'Ter': 'Terminated',
				'Set': 'Setting-up',
				'LOA': 'Leave of Absence',
			}[status] || 'N/A';
		},
		statusHelper: function () {
			return this.mainStatusHelper(this.get('status'));
		}.property('status'),
		allStatusHelper: function () {
			return this.mainStatusHelper(this.get('allStatus'));
		}.property('allStatus'),
		employmentTypeHelper: function () {
			return {
				'FT': 'Full Time',
				'PT': 'Part Time',
				'TP': 'Temp/Intern',
				'IN': 'Intern',
				'CO': 'Contingent Worker',
			}[this.get('employmentType')] || 'N/A';
		}.property('employmentType'),
		statusText: function () {
			var currentEnrollment = this.get('currentEnrollment');
			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			var currentVisionEnrollment = this.get('currentVisionEnrollment');

			if ((currentEnrollment && !currentEnrollment.get('status')) ||
				(currentDentalEnrollment && !currentDentalEnrollment.get('status')) ||
				(currentVisionEnrollment && !currentVisionEnrollment.get('status'))) {
				return "Hasn't Started";
			}

			var statusList = ['start', 'begin'];
			if ((currentEnrollment && statusList.indexOf(currentEnrollment.get('status')) > -1) ||
				(currentDentalEnrollment && statusList.indexOf(currentDentalEnrollment.get('status')) > -1) ||
				(currentVisionEnrollment && statusList.indexOf(currentVisionEnrollment.get('status')) > -1)) {
				return "Started / In Progress";
			}

			if ((currentEnrollment && currentEnrollment.get('status') == 'decline' && !currentEnrollment.get('hasSignedWaiver')) ||
				(currentDentalEnrollment && currentDentalEnrollment.get('status') == 'decline' && !currentDentalEnrollment.get('hasSignedWaiver')) ||
				(currentVisionEnrollment && currentVisionEnrollment.get('status') == 'decline' && !currentVisionEnrollment.get('hasSignedWaiver'))) {
				return "Started / In Progress";
			}

			statusList = ['complete', 'reviewed', 'decline'];
			if ((currentEnrollment && statusList.indexOf(currentEnrollment.get('status')) > -1) ||
				(currentDentalEnrollment && statusList.indexOf(currentDentalEnrollment.get('status')) > -1) ||
				(currentVisionEnrollment && statusList.indexOf(currentVisionEnrollment.get('status')) > -1)) {
				return "Complete!";
			}

			var status = this.get('enrollmentStatus');
			if (status == 'decline') {
				return "Declined";
			}

			if (status == 'approved') {
				return "Covered";
			}

			// If all else fails, return the status as is
			return status;
		}.property('currentEnrollment', 'currentEnrollment.status', 'currentEnrollment.hasSignedWaiver',
			'currentDentalEnrollment', 'currentDentalEnrollment.status', 'currentDentalEnrollment.hasSignedWaiver',
			'currentVisionEnrollment', 'currentVisionEnrollment.status', 'currentVisionEnrollment.hasSignedWaiver',
			'enrollmentStatus'),
		enrollmentText: function () {
			var openEnrollmentSuffix = ' period';
			var switchCarrierPrefix = 'group\'s ';
			if (this.get('isMedicalOEOn')) {
				return 'open enrollment' + openEnrollmentSuffix;
			}
			if (this.get('isMedicalSWOn')) {
				return switchCarrierPrefix + 'carrier switch';
			}
			if (this.get('isDentalOEOn')) {
				return 'open enrollment' + openEnrollmentSuffix;
			}
			if (this.get('isDentalSWOn')) {
				return switchCarrierPrefix + 'carrier switch';
			}
			if (this.get('isVisionOEOn')) {
				return 'open enrollment' + openEnrollmentSuffix;
			}
			if (this.get('isVisionSWOn')) {
				return switchCarrierPrefix + 'carrier switch';
			}

			return null;
		}.property('isMedicalOEOn', 'isMedicalSWOn', 'isDentalOEOn', 'isDentalSWOn', 'isVisionOEOn', 'isVisionSWOn'),
		isAllEnrollmentsInEndState: function () {
			return (this.get('statusText') == 'Complete!');
		}.property('statusText'),
		isEnrollmentCompleteOrApproved: function () {
			return this.get('enrollmentStatus') == 'complete' || this.get('enrollmentStatus') == 'approved';
		}.property('enrollmentStatus'),
		isApproved: Ember.computed.equal('enrollmentStatus', 'approved'),
		isContractor: Ember.computed.equal('type', 'HC'),
		isContingent: function () {
			if (['IC', 'CW', 'AW', 'VE', 'VO'].indexOf(this.get('employmentTypeIncludingWorkerTypes')) != -1) {
				return true;
			}
			return false;
		}.property('employmentTypeIncludingWorkerTypes'),
		// warning: this is for old contractor!
		isInternationalContractor: function () {
			if (this.get('isContractor')) {
				if (this.get('homeAddress.country')) {
					return this.get('homeAddress.country') != "US";
				}
			}
			return false;
		}.property('isContractor', 'homeAddress.country'),
		isNewContractor: Ember.computed.equal('employmentType', 'CO'),
		// this is for new contractor
		isMedicalApproved: Ember.computed.equal('medicalApprovalStatus', 'approved'),
		isDentalApproved: Ember.computed.equal('dentalApprovalStatus', 'approved'),
		isVisionApproved: Ember.computed.equal('visionApprovalStatus', 'approved'),
		isMedicalDeclined: Ember.computed.equal('medicalApprovalStatus', 'decline'),
		isDentalDeclined: Ember.computed.equal('dentalApprovalStatus', 'decline'),
		isVisionDeclined: Ember.computed.equal('visionApprovalStatus', 'decline'),
		isMedicalApprovedOrDeclined: Ember.computed.or('isMedicalApproved', 'isMedicalDeclined'),
		isDentalApprovedOrDeclined: Ember.computed.or('isDentalApproved', 'isDentalDeclined'),
		isVisionApprovedOrDeclined: Ember.computed.or('isVisionApproved', 'isVisionDeclined'),
		isApprovedForAll: Ember.computed.and('isMedicalApproved', 'isDentalApproved', 'isVisionApproved'),
		isEnrollmentComplete: Ember.computed.equal('enrollmentStatus', 'complete'),
		isMedicalEnrollmentComplete: Ember.computed.equal('medicalStatus', 'complete'),
		isDentalEnrollmentComplete: Ember.computed.equal('dentalStatus', 'complete'),
		isVisionEnrollmentComplete: Ember.computed.equal('visionStatus', 'complete'),
		hasEnrollmentBegun: Ember.computed.equal('enrollmentStatus', 'begin'),
		hasMedicalEnrollmentBegun: Ember.computed.equal('medicalStatus', 'begin'),
		hasDentalEnrollmentBegun: Ember.computed.equal('dentalStatus', 'begin'),
		hasVisionEnrollmentBegun: Ember.computed.equal('visionStatus', 'begin'),
		canSeeEmployeeDashboard: Ember.computed.unequal('type', 'AD'),
		hasChosen: Ember.computed.or('enrollmentStatus'),
		isMedicalCompleteOrApproved: Ember.computed.or('isMedicalApproved', 'isMedicalEnrollmentComplete'),
		isDentalCompleteOrApproved: Ember.computed.or('isDentalApproved', 'isDentalEnrollmentComplete'),
		isVisionCompleteOrApproved: Ember.computed.or('isVisionApproved', 'isVisionEnrollmentComplete'),
		locCostInfo: function (loc) {
			if (this.get('employeeSettings.total' + loc + 'Cost') > 0) {
				return '$' + this.get('employeeSettings.total' + loc + 'Cost');
			} else if (this.get('is' + loc + 'Declined')) {
				return 'Declined';
			} else if (this.get('is' + loc + 'EnrollmentComplete')) {
				return 'Pending Group Approval';
			} else if (this.get('has' + loc + 'EnrollmentBegun')) {
				return 'Enrollment In Progress';
			} else {
				return 'Pending Selection';
			}
		},
		medicalCostInfo: function () {
			return this.locCostInfo('Medical');
		}.property('employeeSettings.totalMedicalCost', 'isMedicalDeclined', 'isMedicalEnrollmentComplete', 'hasMedicalEnrollmentBegun'),
		dentalCostInfo: function () {
			return this.locCostInfo('Dental');
		}.property('employeeSettings.totalDentalCost', 'isDentalDeclined', 'isDentalEnrollmentComplete', 'hasDentalEnrollmentBegun'),
		visionCostInfo: function () {
			return this.locCostInfo('Vision');
		}.property('employeeSettings.totalVisionCost', 'isVisionDeclined', 'isVisionEnrollmentComplete', 'hasVisionEnrollmentBegun'),
		inProcess: function () {
			var status = this.get('enrollmentStatus');
			if (!status || (status && status == 'begin')) {
				return true;
			}

			return false;
		}.property('enrollmentStatus'),
		hasDeclinedCoverage: Ember.computed.equal('enrollmentStatus', 'decline'),
		hasDeclinedMedicalCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			return (this.get('medicalApprovalStatus') == 'decline' ||
				(this.get('currentEnrollment.isInitialEnrollment') && this.get('currentEnrollment.status') == 'decline'));
		}.property('medicalApprovalStatus', 'currentEnrollment.isInitialEnrollment', 'currentEnrollment.status'),
		isDecliningMedicalCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			var currentEnrollment = this.get('currentEnrollment');
			return (currentEnrollment &&
				(currentEnrollment.get('status') == 'decline' ||
					(currentEnrollment.get('isOpenEnrollment') &&
						(currentEnrollment.get('status') == 'decline' || (this.get('medicalApprovalStatus') == 'decline' && currentEnrollment.get('hasReviewed'))))));
		}.property('currentEnrollment', 'currentEnrollment.status', 'currentEnrollment.isOpenEnrollment', 'medicalApprovalStatus'),
		hasDeclinedDentalCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			return (this.get('dentalApprovalStatus') == 'decline' ||
				(this.get('currentDentalEnrollment.isInitialEnrollment') && this.get('currentDentalEnrollment.status') == 'decline'));
		}.property('dentalApprovalStatus', 'currentDentalEnrollment.isInitialEnrollment', 'currentDentalEnrollment.status'),
		isDecliningDentalCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			return (currentDentalEnrollment &&
				(currentDentalEnrollment.get('status') == 'decline' ||
					(currentDentalEnrollment.get('isOpenEnrollment') &&
						(currentDentalEnrollment.get('status') == 'decline' || (this.get('dentalApprovalStatus') == 'decline' && currentDentalEnrollment.get('hasReviewed'))))));
		}.property('currentDentalEnrollment', 'currentDentalEnrollment.status', 'currentDentalEnrollment.isOpenEnrollment', 'dentalApprovalStatus'),
		hasDeclinedVisionCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			return (this.get('visionApprovalStatus') == 'decline' ||
				(this.get('currentVisionEnrollment.isInitialEnrollment') && this.get('currentVisionEnrollment.status') == 'decline'));
		}.property('visionApprovalStatus', 'currentVisionEnrollment.isInitialEnrollment', 'currentVisionEnrollment.status'),
		isDecliningVisionCoverage: function () {
			// used in ZApps, update server-side fn if you change this!

			var currentVisionEnrollment = this.get('currentVisionEnrollment');
			return (currentVisionEnrollment &&
				(currentVisionEnrollment.get('status') == 'decline' ||
					(currentVisionEnrollment.get('isOpenEnrollment') &&
						(currentVisionEnrollment.get('status') == 'decline' || (this.get('visionApprovalStatus') == 'decline' && currentVisionEnrollment.get('hasReviewed'))))));
		}.property('currentVisionEnrollment', 'currentVisionEnrollment.status', 'currentVisionEnrollment.isOpenEnrollment', 'visionApprovalStatus'),
		isDecliningMedicalAndDentalCoverage: Ember.computed.and('isDecliningMedicalCoverage', 'isDecliningDentalCoverage'),
		isMedicalAndDentalCompleteOrReviewed: Ember.computed.or('currentEnrollment.isCompleteOrReviewed', 'currentDentalEnrollment.isCompleteOrReviewed'),
		visionOnlyInProcess: function () {
			return (!this.get('currentEnrollment') && !this.get('currentDentalEnrollment'));
		}.property('currentEnrollment', 'currentDentalEnrollment'),
		visionAndMedicalInProcess: function () {
			return (this.get('currentEnrollment') && !this.get('currentDentalEnrollment') && this.get('currentVisionEnrollment'));
		}.property('currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'),
		visionAndDentalInProcess: function () {
			return (!this.get('currentEnrollment') && this.get('currentDentalEnrollment') && this.get('currentVisionEnrollment'));
		}.property('currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'),

		allowDependentEnrollment: function (enrollment, lineOfCoverage) {
			if (enrollment) {
				return !this.get('isDeclining' + lineOfCoverage + 'Coverage');
			}
			else {
				return !this.get('hasDeclined' + lineOfCoverage + 'Coverage');
			}
		},

		allowDependentMedicalEnrollment: function () {
			return this.allowDependentEnrollment(this.get('currentEnrollment'), 'Medical');
		}.property('currentEnrollment', 'hasDeclinedMedicalCoverage', 'isDecliningMedicalCoverage'),
		allowDependentDentalEnrollment: function () {
			return this.allowDependentEnrollment(this.get('currentDentalEnrollment'), 'Dental');
		}.property('currentDentalEnrollment', 'hasDeclinedDentalCoverage', 'isDecliningDentalCoverage'),
		allowDependentVisionEnrollment: function () {
			return this.allowDependentEnrollment(this.get('currentVisionEnrollment'), 'Vision');
		}.property('currentVisionEnrollment', 'hasDeclinedVisionCoverage', 'isDecliningVisionCoverage'),

		isMedicalOEOn: Ember.computed.or('currentEnrollment.isOpenEnrollment'),
		isMedicalSWOn: Ember.computed.or('currentEnrollment.isSwitchCarrierEnrollment'),
		showEnrollmentDiffs: Ember.computed.or('isMedicalOEOn', 'isMedicalSWOn', 'isDentalOEOn', 'isDentalSWOn', 'isVisionOEOn', 'isVisionSWOn'),
		isDentalOEOn: Ember.computed.or('currentDentalEnrollment.isOpenEnrollment'),
		isDentalSWOn: Ember.computed.or('currentDentalEnrollment.isSwitchCarrierEnrollment'),
		isVisionOEOn: Ember.computed.or('currentVisionEnrollment.isOpenEnrollment'),
		isVisionSWOn: Ember.computed.or('currentVisionEnrollment.isSwitchCarrierEnrollment'),
		isOEOrSWOn: Ember.computed.or('isMedicalOEOn', 'isDentalOEOn', 'isVisionOEOn', 'isMedicalSWOn', 'isDentalSWOn', 'isVisionSWOn'),
		isRequested: Ember.computed.equal('status', 'Req'),
		isSettingUp: Ember.computed.equal('status', 'Set'),
		isActive: Ember.computed.equal('status', 'Act'),
		isLeaveOfAbsence: Ember.computed.equal('status', 'LOA'),
		isAllActive: Ember.computed.equal('allStatus', 'Act'),
		isRealTerminated: Ember.computed.equal('status', 'Ter'),
		statusDeleted: Ember.computed.equal('status', 'Del'),
		isTerminated: Ember.computed.or('isRealTerminated', 'statusDeleted'),
		showActionsRequiredModal: function () {
			return this.get('isSettingUp') || (this.get('isActive') && !this.get('isRegistered'));
		}.property('isSettingUp', 'isActive', 'isRegistered'),
		isTerminatedOrAdmin: function () {
			return this.get('isAdmin') || this.get('isTerminated');
		}.property('isAdmin', 'isTerminated'),
		shouldShowAsEnrolledInHsa: Ember.computed.and('isActive', 'hasHsa'), // TODO: move to HSA object
		shouldShowHighlyCompensatedAndKeyEmployeesStatus: Ember.computed.or('isActive', 'isTerminated', 'isLeaveOfAbsence'),
		terminationAction: DS.belongsTo('App.EmployeeTerminationAction'),
		shouldShowHighlyCompensatedAndKeyEmployees: Ember.computed.and('isFulltime', 'shouldShowHighlyCompensatedAndKeyEmployeesStatus'),

		isEmptyEmployee: function () {
			return !(this.get('first_name') || this.get('last_name') || this.get('email') || this.get('department_id') || this.get('location_id') ||
				this.get('hireDate') || this.get('payRate') || this.get('salary') || this.get('employmentType') || this.get('location_id'));
		}.property('first_name', 'last_name', 'email', 'department_id', 'location_id', 'hireDate', 'payRate', 'salary', 'employmentType', 'location_id'),
		isPendingTermination: function () {
			return this.get('terminationAction.status') == 'approved';
		}.property('terminationAction.status'),
		isTerminationRequested: function () {
			return this.get('terminationAction.status') == 'requested';
		}.property('terminationAction.status'),
		isFullTimeEndDateInFuture: function () {
			var fullTimeEndDate = this.get("fullTimeEndDate");
			if (!fullTimeEndDate || fullTimeEndDate.length == 0) {
				return false;
			}
			fullTimeEndDate = new Date(fullTimeEndDate);
			return (fullTimeEndDate > new Date());
		}.property('fullTimeEndDate'),
		shouldAdministerCOBRA: Ember.computed.oneWay('employeeCobra.administerCOBRA'),
		shouldZenefitsAdministerCOBRA: Ember.computed.oneWay('employeeCobra.cobraRule.isZenefitsAdministering'),
		hasPlansForCobra: Ember.computed.or('selectedPlan', 'selectedDentalPlan', 'selectedVisionPlan'),
		employeeCobraEnrolleeProperties: DS.belongsTo('App.EmployeeCobraEnrolleeProperties'),
		isCobraEnrollee: Ember.computed.alias('employeeCobraEnrolleeProperties.isCobraEnrollee'),
		isCobraPossible: function () {
			var cobraType = this.get('employeeCobra.cobraType');
			if (!cobraType) {
				cobraType = this.get('company.settings.areEmployeesFedCobraEligible') ? 'F' : 'S';
			}
			return (((this.get('isTerminated') && this.get('employeeCobra.qualifyingEvent') == 'termination') ||
				(this.get('employmentType') != 'FT') && this.get('employeeCobra.qualifyingEvent') == 'move_out_of_ft') &&
				!this.get('isFullTimeEndDateInFuture') && this.get('shouldAdministerCOBRA') && this.get('shouldZenefitsAdministerCOBRA') &&
				this.get('hasPlansForCobra'));
		}.property('isTerminated', 'isFullTimeEndDateInFuture', 'shouldAdministerCOBRA', 'hasPlansForCobra',
			'company.settings.areEmployeesFedCobraEligible', 'shouldZenefitsAdministerCOBRA', 'employeeCobra.cobraType', 'employeeCobra.qualifyingEvent'),
		showCobra: function () {
			if (!this.get('isCobraPossible')) {
				return false;
			}

			if (this.get('status') == 'Act' && this.get('employmentType') == 'FT') {
				return false;
			}

			var employeeCobra = this.get('employeeCobra');
			var remainingCobraDays = employeeCobra.get('remainingCobraDays');
			if (employeeCobra.get('status') == null || employeeCobra.get('status') == 'filling-out') {
				if (employeeCobra.get('isBorEmployeeAlreadyCovered') || (remainingCobraDays != null && remainingCobraDays >= 0)) {
					return true;
				}
			}
			else if (employeeCobra.get('status') == 'declined' ||
				employeeCobra.get('status') == 'enrolled' ||
				employeeCobra.get('status') == 'submitted' ||
				employeeCobra.get('status') == 'canceled') {
				return true;
			}
			return false;
		}.property('employeeCobra.isBorEmployeeAlreadyCovered', 'isCobraPossible', 'employeeCobra.remainingCobraDays', 'employeeCobra.status', 'status', 'employmentType'),
		canEnrollInCobra: function () {
			if (this.get('employeeCobra.remainingCobraDays') != null && this.get('employeeCobra.remainingCobraDays') >= 0) {
				return true;
			}
			return false;
		}.property('employeeCobra.remainingCobraDays'),

		employeeCancellationProperties: DS.belongsTo('App.EmployeeCancellationProperties'),
		dentalCancellationStatus: Ember.computed.alias('employeeCancellationProperties.dentalCancellationStatus'),
		isDentalCancellationApproved: Ember.computed.equal('dentalCancellationStatus', 'approved'),
		dentalCancellationEffectiveDate: Ember.computed.alias('employeeCancellationProperties.dentalCancellationEffectiveDate'),
		isDentalMemberIDEqualsSSN: function () {
			return this.get('employeeSettings.dentalGroupID') == this.get('socialSecurity');
		}.property('employeeSettings.dentalGroupID', 'socialSecurity'),

		medicalCancellationStatus: Ember.computed.alias('employeeCancellationProperties.medicalCancellationStatus'),
		isMedicalCancellationApproved: Ember.computed.equal('medicalCancellationStatus', 'approved'),
		medicalCancellationEffectiveDate: Ember.computed.alias('employeeCancellationProperties.medicalCancellationEffectiveDate'),
		isMedicalMemberIDEqualsSSN: function () {
			return this.get('employeeSettings.groupID') == this.get('socialSecurity');
		}.property('employeeSettings.groupID', 'socialSecurity'),

		visionCancellationStatus: Ember.computed.alias('employeeCancellationProperties.visionCancellationStatus'),
		isVisionCancellationApproved: Ember.computed.equal('visionCancellationStatus', 'approved'),
		visionCancellationEffectiveDate: Ember.computed.alias('employeeCancellationProperties.visionCancellationEffectiveDate'),
		isVisionMemberIDEqualsSSN: function () {
			return this.get('employeeSettings.visionGroupID') == this.get('socialSecurity');
		}.property('employeeSettings.visionGroupID', 'socialSecurity'),

		showDentalAndVisionID: function () {
			var yetToSent = this.get('employeeSettings.isMedicalNewHireApplicationYetToBeSent');
			var begun = this.get('hasMedicalEnrollmentBegun');
			var declined = this.get('isMedicalDeclined');
			var groupID = this.get('employeeSettings.groupID');
			return yetToSent || begun || declined || groupID;
		}.property('employeeSettings.isMedicalNewHireApplicationYetToBeSent', 'hasMedicalEnrollmentBegun', 'isMedicalDeclined', 'employeeSettings.groupID'),

		loginUrl: attr('string'),
		canAddDependentInDental: function () {
			if (!this.get('dentalPlan')) {
				return false;
			}
			if (this.get('isDentalCancellationApproved')) {
				return false;
			}
			return true;
		}.property('dentalPlan', 'isDentalCancellationApproved'),
		canAddDependentInMedical: function () {
			if (!this.get('medicalPlan')) {
				return false;
			}
			if (this.get('isMedicalCancellationApproved')) {
				return false;
			}
			return true;
		}.property('medicalPlan', 'isMedicalCancellationApproved'),
		canAddDependentInVision: function () {
			if (!this.get('visionPlan')) {
				return false;
			}
			if (this.get('isVisionCancellationApproved')) {
				return false;
			}
			return true;
		}.property('visionPlan', 'isVisionCancellationApproved'),
		isAddingDependentsPossible: Ember.computed.or('canAddDependentInDental', 'canAddDependentInMedical', 'canAddDependentInVision'),

		isAnyPlanActive: Ember.computed.or('dentalPlan', 'visionPlan', 'medicalPlan'),
		areAllPlansDeactive: Ember.computed.not('isAnyPlanActive'),
		hasPaystubs: Ember.computed.gt('payStubs.length', 0),

		residenceTaxStateDisplay: function () {
			var state = "";
			if (this.get('taxState')) {
				state = this.get('residenceTaxState') || this.get('state');
			} else {
				state = this.get('state');
			}
			return STATE_ABBREV_TO_NAME[state] || "";
		}.property('state', 'residenceTaxState', 'taxState'),

		stateDisplay: function () {
			if (this.get('state')) {
				return STATE_ABBREV_TO_NAME[this.get('state')];
			}
			return this.get('state');
		}.property('state'),

		taxStateDisplay: function () {
			var state = this.get('taxState') || this.get('location.state');
			return STATE_ABBREV_TO_NAME[state] || "";
		}.property('taxState', 'location.state'),

		isInCalifornia: function () {
			return this.get('state') == 'CA' || this.get('location.state') == 'CA' || this.get('company.legalState') == 'CA';
		}.property('state', 'location.state', 'company.legalState'),
		firstCardString: function () {
			return "medical";
		}.property(),
		secondCardString: function () {
			return "dental";
		}.property(),
		thirdCardString: function () {
			return "vision";
		}.property(),
		homeAddress: DS.belongsTo('App.Address'),
		isOECobraParticipant: function () {
			var isEligibleForCobra = this.get('isEligibleForCobra');
			var showCobra = this.get('showCobra');
			var isActiveCobra = !!this.get('employeeCobra.authDate');
			var isOpenEnrollment = this.get('currentEnrollment.isOpenEnrollment');
			var isDentalOpenEnrollment = this.get('currentDentalEnrollment.isOpenEnrollment');
			var isVisionOpenEnrollment = this.get('currentVisionEnrollment.isOpenEnrollment');
			var isMedicalPastEnrollmentDeadline = this.get('currentEnrollment.isPastEnrollmentDeadline');
			var isDentalPastEnrollmentDeadline = this.get('currentDentalEnrollment.isPastEnrollmentDeadline');
			var isVisionPastEnrollmentDeadline = this.get('currentVisionEnrollment.isPastEnrollmentDeadline');
			return isActiveCobra && isEligibleForCobra && showCobra && (
				(isOpenEnrollment && !isMedicalPastEnrollmentDeadline) ||
				(isDentalOpenEnrollment && !isDentalPastEnrollmentDeadline) ||
				(isVisionOpenEnrollment && !isVisionPastEnrollmentDeadline)
			);
		}.property('isEligibleForCobra', 'showCobra', 'currentEnrollment.isOpenEnrollment', 'currentDentalEnrollment.isMedicalPastEnrollmentDeadline', 'currentVisionEnrollment.isOpenEnrollment', 'currentEnrollment.isMedicalPastEnrollmentDeadline', 'currentDentalEnrollment.isOpenEnrollment', 'currentVisionEnrollment.isMedicalPastEnrollmentDeadline'),
		isCurrentEnrollmentValid: function () {
			return this.get('currentEnrollment') && !this.get('currentEnrollment.isPastEnrollmentDeadline');
		}.property('currentEnrollment.isPastEnrollmentDeadline'),
		isCurrentDentalEnrollmentValid: function () {
			return this.get('currentDentalEnrollment') && !this.get('currentDentalEnrollment.isPastEnrollmentDeadline');
		}.property('currentDentalEnrollment.isPastEnrollmentDeadline'),
		isCurrentVisionEnrollmentValid: function () {
			return this.get('currentVisionEnrollment') && !this.get('currentVisionEnrollment.isPastEnrollmentDeadline');
		}.property('currentVisionEnrollment.isPastEnrollmentDeadline'),
		country: function () {
			return this.get('location.country');
		}.property('location.country'),
		isInternational: function () {
			return this.get('type') === 'IN';
		}.property('type'),
		countryHumanReadable: function () {
			return this.get('country') ? countryCodeToName(this.get('country')) : "";
		}.property('country'),
		marital_status: attr('string'),
		isDisabled: attr('boolean'),
		banks: DS.hasMany('App.EmployeeBank'),
		employments: DS.hasMany('App.EmployeeEmployment'),
		eligibilities: DS.hasMany('App.EmployeeEligibility'),
		eligibilityHistory: function () {
			var eligibilityHistory = this.get('eligibilities').filter(function (eligibility) {
				return eligibility.get('isActive') && eligibility.get('isSealed');
			});
			if (!this.get('currentEligibility.isSealed')) {
				return eligibilityHistory;
			}
			eligibilityHistory.splice(eligibilityHistory.length - 1, 1);
			return eligibilityHistory;
		}.property('eligibilities', 'eligibilities.@each.isActive', 'eligibilities.@each.isSealed'),
		hasMoreThanOneEligibilities: function () {
			var eligibilitiesNumber = this.get('eligibilities').filterProperty('isActive', true);
			return eligibilitiesNumber.length > 1;
		}.property('eligibilities.@each.isActive'),
		pensions: DS.hasMany('App.EmployeePension'),
		currentEmployment: function () {
			// need to load employments before you use this property. otherwise the filter won't work >:(
			var currEmployments = this.get('employments').sortBy('id').filter(function (employment) {
				return employment.get('isActive');
			});
			return currEmployments.get('lastObject');
		}.property('employments.@each.isActive'),
		currentEligibility: function () {
			var currEligibility = this.get('eligibilities').filterProperty('isActive', true).get('lastObject');
			return currEligibility;
		}.property('eligibilities.@each.isActive'),
		hasI9CorrectionRequest: function () {
			return !this.get('currentEligibility.isSealed') && this.get('currentEligibility.currentSectionOneCorrection') && !this.get('currentEligibility.currentSectionOneCorrection.isEmployeeSideComplete');
		}.property('currentEligibility.isSealed', 'currentEligibility.currentSectionOneCorrection', 'currentEligibility.currentSectionOneCorrection.isEmployeeSideComplete'),

		//Note: Do not use this for payroll-specific functions
		unorderedBanks: function () {
			var bankList = this.get('banks').filterProperty('isActive', true);
			return bankList;
		}.property('banks.@each.isActive'),

		// ******************************************************************************//
		// Payroll-specific properties - START
		// TODO (HR) Clean up immediately after the cards go live
		// ******************************************************************************//

		// Bank
		bankName: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		bankAccountTypeHuman: function () {
			var bankAccountType = this.get('bankAccountType');
			if (bankAccountType == 'C') {
				return "Checking";
			} else if (bankAccountType == 'S') {
				return "Savings";
			} else if (bankAccountType == 'B') {
				return "Business";
			}
			return bankAccountType;
		}.property('bankAccountType'),
		clearBanksDistribution: function () {
			var method = this.get('bankDistributionMethod');
			this.get('activeBanks').forEach(function (bank) {
				if (method == 'DA') {
					bank.set('percentagePerPaycheck', null);
				} else if (method == 'PE') {
					bank.set('amountPerPaycheck', null);
				}
			});
		}.observes('bankDistributionMethod'),
		isBankDistributionMethodPE: function () {
			return this.get('bankDistributionMethod') == 'PE';
		}.property('bankDistributionMethod'),
		noBankDistributionMethodSelected: Ember.computed.not('bankDistributionMethod'),

		currentBank: function () {
			var currBank = this.get('banks').filterProperty('isActive', true).filterProperty('isSalaryAccount', true).findProperty('isPrimaryAccount', true);
			return currBank;
		}.property('banks.@each.isPrimaryAccount', 'banks.@each.isActive'),
		primaryBank: function () {
			var primBank = this.get('banks').filterProperty('isActive', true).filterProperty('isSalaryAccount', true).findProperty('isPrimaryAccount', true);
			return primBank;
		}.property('banks.@each.isPrimaryAccount', 'banks.@each.isActive'),
		hasPrimaryBank: Ember.computed.notEmpty('primaryBank'),
		activeBanks: function () {
			var bankList = this.get('banks').filterProperty('isActive', true).filterProperty('isSalaryAccount', true).filterProperty('isPrimaryAccount', false);
			bankList.sort(function (a, b) {
				if (a.get('priority') < b.get('priority')) {
					return -1;
				} else if (a.get('priority') > b.get('priority')) {
					return 1;
				}
				return 0;
			});
			if (this.get('hasPrimaryBank')) {
				bankList.push(this.get('primaryBank'));
			}
			return bankList;
		}.property('banks.@each.isEditingBank', 'banks.@each.isConfirmingBankAccountNumber', 'banks.@each.isActive', 'banks.@each.isPrimaryAccount', 'banks.@each.priority'),

		banksNumberChecking: Ember.computed('banks', function () {
			return this.get('banks').filterProperty('isActive', true).filterProperty('isSalaryAccount', true).filterBy('bankAccountType', 'C').length;
		}).property('banks.[]', 'banks.@each.bankAccountType'),

		banksNumberSavings: Ember.computed('banks', function () {
			return this.get('banks').filterProperty('isActive', true).filterProperty('isSalaryAccount', true).filterBy('bankAccountType', 'S').length;
		}).property('banks.[]', 'banks.@each.bankAccountType'),

		payrollMaxBankNumberChecking: function () {
			return this.get('company.payrollMaxBankNumberChecking');
		}.property('company.payrollMaxBankNumberChecking'),

		payrollMaxBankNumberSavings: function () {
			return this.get('company.payrollMaxBankNumberSavings');
		}.property('company.payrollMaxBankNumberSavings'),

		activeBanksNumber: Ember.computed.alias("activeBanks.length"),
		hasMultipleBanks: function () {
			return this.get('activeBanks.length') > 1;
		}.property('activeBanks.length'),

		lessThanPayrollMaxBank: function () {
			return this.get('activeBanks.length') < this.get('company.payrollProviderMetadata.maxBanks');
		}.property('activeBanks.length', 'company.payrollProviderMetadata.maxBanks'),

		// ******************************************************************************//
		// Payroll-specific properties - END
		// ******************************************************************************//

		currentPension: function () {
			var currPension = this.get('pensions').findProperty('country', this.get('country'));
			return currPension;
		}.property('pensions.@each.country', 'country'),
		caFederalTax: DS.belongsTo('App.CanadaFederalTax'),
		caStateTaxes: DS.hasMany('App.CanadaStateTax'),
		currentFederalTaxIntl: function () {
			var countries = ['caFederalTax', 'deFederalTax', 'nlFederalTax', 'gbFederalTax', 'auFederalTax', 'hkFederalTax', 'inFederalTax', 'sgFederalTax', 'frFederalTax', 'ieFederalTax', 'brFederalTax'];
			for (var k = 0; k < countries.length; k++) {
				var curr = this.get(countries[k]);
				if (curr && curr.get('isActive')) {
					return curr;
				}
			}
		}.property('caFederalTax.isActive', 'deFederalTax.isActive', 'nlFederalTax.isActive', 'gbFederalTax.isActive', 'auFederalTax.isActive', 'hkFederalTax.isActive', 'inFederalTax.isActive', 'sgFederalTax.isActive', 'frFederalTax.isActive', 'ieFederalTax.isActive', 'brFederalTax.isActive'),
		currentCanadaStateTax: function () {
			var curr = this.get('caStateTaxes').findProperty('isActive', true);
			return curr;
		}.property('caStateTaxes.@each.isActive'),
		auFederalTax: DS.belongsTo('App.AustraliaFederalTax'),
		gbFederalTax: DS.belongsTo('App.UnitedKingdomFederalTax'),
		deFederalTax: DS.belongsTo('App.GermanyFederalTax'),
		nlFederalTax: DS.belongsTo('App.NetherlandsFederalTax'),
		hkFederalTax: DS.belongsTo('App.HongKongFederalTax'),
		inFederalTax: DS.belongsTo('App.IndiaFederalTax'),
		sgFederalTax: DS.belongsTo('App.SingaporeFederalTax'),
		frFederalTax: DS.belongsTo('App.FranceFederalTax'),
		ieFederalTax: DS.belongsTo('App.IrelandFederalTax'),
		brFederalTax: DS.belongsTo('App.BrazilFederalTax'),
		lastGmailAccount: Ember.computed.alias('itServiceUsers.lastObject'),
		swapLocation2: null,
		notSelectingServiceUser: Ember.computed.not('selectingServiceUser'),
		notSelectingNonSwappedServiceUser: Ember.computed.and('notSelectingServiceUser', 'nonSwappedServiceUser', 'noServiceUsers'),
		nonSwappedServiceUser: Ember.computed.not('swappedServiceUser'),
		selectServiceUser: function () {
			this.set('selectingServiceUser', !this.get('selectingServiceUser'));
		},
		saveServiceUser: function (employee) {
			var serviceUserSwap = this.get('swapLocation2');
			if (serviceUserSwap) {
				serviceUserSwap.set('employee', employee);
				this.set('swappedServiceUser', true);
				serviceUserSwap.save();
			}
		},
		isDoingBackgroundCheck: function () {
			return (this.get('bgcheckNewHires.length') > 0);
		}.property('bgcheckNewHires'),
		bgcheckNewHires: function () {
			return this.get('newHires').filterProperty('isDoingBackgroundCheck', true);
		}.property('newHires.@each.isDoingBackgroundCheck'),
		isCountryCanada: Ember.computed.equal('location.country', 'CA'),
		isCountryAustralia: Ember.computed.equal('location.country', 'AU'),
		isCountryGermany: Ember.computed.equal('location.country', 'DE'),
		isCountryUnitedKingdom: Ember.computed.equal('location.country', 'GB'),
		isCountryHongKong: Ember.computed.equal('location.country', 'HK'),
		isCountryIndia: Ember.computed.equal('location.country', 'IN'),
		isCountryNetherlands: Ember.computed.equal('location.country', 'NL'),
		isCountrySingapore: Ember.computed.equal('location.country', 'SG'),
		isCountryFrance: Ember.computed.equal('location.country', 'FR'),
		isCountryIreland: Ember.computed.equal('location.country', 'IE'),
		isCountryBrazil: Ember.computed.equal('location.country', 'BR'),
		isPaymentMethodCheck: Ember.computed.equal('paymentMethod', 'MA'),
		isPaymentMethodDirectDeposit: Ember.computed.equal('paymentMethod', 'DD'),
		intlEmploymentTypeHelper: function () {
			return {
				'FT': 'Full Time',
				'PT': 'Part Time',
				'TP': 'Temp/Intern',
				'IN': 'Intern',
				'CA': 'Casual',
				'CO': 'Contract',
				'LH': 'Labour Hire',
			}[this.get('employmentType')] || 'N/A';
		}.property('employmentType'),
		employmentTypeIncludingWorkerTypesHelper: function () {
			return {
				'FT': 'Full Time',
				'PT': 'Part Time',
				'TP': 'Temp/Intern',
				'IN': 'Intern',
				'CA': 'Casual',
				'CO': 'Contingent Worker',
				'LH': 'Labour Hire',
				'AW': 'Agency-paid Temp',
				'CW': 'Company-paid Temp',
				'VE': 'Vendor Employee',
				'IC': 'Independent Contractor',
				'VO': 'Volunteer',
			}[this.get('employmentTypeIncludingWorkerTypes')] || 'N/A';
		}.property('employmentTypeIncludingWorkerTypes'),
		paymentMethod: attr('string'),
		paymentMethodHelper: function () {
			return {
				'MA': 'Check',
				'DD': 'Direct Deposit',
			}[this.get('paymentMethod')] || 'N/A';
		}.property('paymentMethod'),
		allowedLocations: function () {
			if (!this.get('location')) {
				return this.get('company.locations').filterProperty('country', 'US');
			}

			if (!this.get('country')) {
				return this.get('company.locations');
			}

			return this.get('company.locations').filterProperty('country', this.get('country'));
		}.property('company.locations.@each.country', 'country', 'location'),
		isCaEmployee: Ember.computed.equal('location.state', 'CA'),
		hasPayFrequency: function () {
			if (this.get('payFrequency') != null &&
				this.get('payFrequency') != "") {
				return true;
			}
			return false;
		}.property('payFrequency'),
		isFicaExempt: attr('boolean'),
		isFicaExemptEligible: function () {
			if (!this.get('isInternational')) {
				var visaType = this.get('currentEligibility.workVisaType');
				if (visaType == 'F1' || visaType == 'J1' || visaType == 'M1' || visaType == 'Q1' || visaType == 'Q2') {
					return true;
				}
			}
			return false;
		}.property('isInternational', 'currentEligibility.workVisaType'),
		employerOnboardingTasks: function () {
			if (this.get('isActive')) {
				return [{ 'status': 'done', 'task': 'Send Offer Letter & Registration Link', 'showEmail': true }];
			}
			var tasks = [];
			var additionalInfo;
			if (this.get('newHireWithOfferLetter.areOfferDetailsComplete')) {
				tasks.pushObject({ 'status': 'done', 'task': 'Complete offer details' });
			} else {
				tasks.pushObject({ 'status': 'not-done', 'task': 'Complete offer details' });
			}

			if (this.get('newHireWithOfferLetter.isHiringSent')) {
				var showEmail = false;
				if (!this.get('newHireWithOfferLetter.skipEmailingOfferLetter')) {
					showEmail = true;
				}
				tasks.pushObject({ 'status': 'done', 'task': 'Send Offer Letter & Registration Link', 'showEmail': showEmail });
			} else {
				var resendRegistrationLink = false;
				additionalInfo = null;
				if (this.get('newHireWithOfferLetter.isHiringInfoComplete')) {
					resendRegistrationLink = true;
				} else {
					additionalInfo = '(Offer details incomplete)';
				}
				tasks.pushObject({ 'status': 'not-done', 'task': 'Send Offer Letter & Registration Link', 'resendRegistrationLink': resendRegistrationLink, 'additionalInfo': additionalInfo });
			}

			if (this.get('newHireWithOfferLetter.showEmployerSideEligibility') && this.get('newHireWithOfferLetter.isHiringSent') && this.get('newHireWithOfferLetter.isDoingEligibilityProofUpload')) {
				var task = 'Verify employer side of I-9';
				var status = null;
				additionalInfo = null;
				if (this.get('newHireWithOfferLetter.eligibility.hasProofs')) {
					if (this.get('newHireWithOfferLetter.eligibility.isSealed')) {
						status = 'done';
					} else {
						status = 'not-done';
					}
				} else {
					status = 'not-done';
					additionalInfo = "(waiting on employee's completion)";
				}
				tasks.pushObject({ 'status': status, 'task': task, 'additionalInfo': additionalInfo });
			}
			return tasks;
		}.property('isActive', 'newHireWithOfferLetter.areOfferDetailsComplete', 'newHireWithOfferLetter.isHiringSent', 'newHireWithOfferLetter.showEmployerSideEligibility', 'newHireWithOfferLetter.isDoingEligibilityProofUpload', 'newHireWithOfferLetter.eligibility.hasProofs', 'newHireWithOfferLetter.eligibility.isSealed'),
		employeeOnboardingTasks: function () {
			if (this.get('isActive')) {
				return [{ 'status': 'not-done', 'task': 'Register with '+brandName, 'resendRegistrationLink': true }];
			}
			var tasks = [];
			if (!this.get('newHireWithOfferLetter.areOfferDetailsComplete')) {
				return tasks;
			}

			if (this.get('isRegistered')) {
				tasks.pushObject({ 'status': 'done', 'task': 'Register with '+brandName });
			} else {
				var resendRegLink = false;
				if (this.get('newHireWithOfferLetter.isHiringSent')) {
					resendRegLink = true;
				}
				tasks.pushObject({ 'status': 'not-done', 'task': 'Register with '+brandName, 'resendRegistrationLink': resendRegLink });
			}

			if (this.get('newHireWithOfferLetter.isDoingOfferLetter')) {
				var task = 'Accept and sign offer letter';
				var status = null;
				if (this.get('newHireWithOfferLetter.offerLetterSig')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.hasAgreements')) {
				var task = 'Accept and sign additional contracts';
				var status = null;
				if (this.get('newHireWithOfferLetter.isAgreementsComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.isPersonalInfoComplete')) {
				tasks.pushObject({ 'status': 'done', 'task': 'Add personal information' });
			} else {
				tasks.pushObject({ 'status': 'not-done', 'task': 'Add personal information' });
			}

			if (this.get('newHireWithOfferLetter.isDoingCustomFields')) {
				var task = 'Fill in custom fields';
				var status = null;
				if (this.get('newHireWithOfferLetter.isEmployeeCustomFieldsComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.eeoAsked')) {
				var task = 'Complete equal opportunity survey';
				var status = null;
				if (this.get('newHireWithOfferLetter.isEeoComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.isDoingTax')) {
				var task = 'Provide tax withholding information';
				var status = null;
				if (this.get('newHireWithOfferLetter.isTaxInfoComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.isPaymentByCheck')) {
				var task = 'Add bank account information';
				var status = null;
				if (this.get('newHireWithOfferLetter.isBankInfoComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.isDoingEligibility')) {
				var task = 'Verify employment eligibility';
				var status = null;
				if (this.get('newHireWithOfferLetter.isEligibilityVerificationComplete')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			if (this.get('newHireWithOfferLetter.isDoingEligibilityProofUpload')) {
				var task = 'Upload employment eligibility documents';
				var status = null;
				if (this.get('newHireWithOfferLetter.eligibility.hasEligibilityProofs')) {
					status = 'done';
				} else {
					status = 'not-done';
				}
				tasks.pushObject({ 'status': status, 'task': task });
			}

			return tasks;
		}.property('isActive', 'newHireWithOfferLetter.areOfferDetailsComplete', 'isRegistered', 'newHireWithOfferLetter.isDoingOfferLetter',
			'newHireWithOfferLetter.offerLetterSig', 'newHireWithOfferLetter.hasAgreements', 'newHireWithOfferLetter.isAgreementsComplete',
			'newHireWithOfferLetter.isPersonalInfoComplete', 'newHireWithOfferLetter.isDoingCustomFields', 'newHireWithOfferLetter.isEmployeeCustomFieldsComplete',
			'newHireWithOfferLetter.eeoAsked', 'newHireWithOfferLetter.isEeoComplete', 'newHireWithOfferLetter.isDoingTax', 'newHireWithOfferLetter.isTaxInfoComplete',
			'newHireWithOfferLetter.isPaymentByCheck', 'newHireWithOfferLetter.isBankInfoComplete', 'newHireWithOfferLetter.isDoingEligibility',
			'newHireWithOfferLetter.isEligibilityVerificationComplete', 'newHireWithOfferLetter.isDoingEligibilityProofUpload',
			'newHireWithOfferLetter.eligibility.hasEligibilityProofs'),
		// Following properties are used in the hiring overview page
		hiringProgressPopoverContent: Ember.computed('newHireWithOfferLetter.isHiringBasicComplete',
			'newHireWithOfferLetter.isHiringJobDetailsComplete',
			'newHireWithOfferLetter.isHiringEmploymentInfoComplete',
			'newHireWithOfferLetter.isHiringPayrollSettingsComplete',
			'newHireWithOfferLetter.doesHiringHaveCustomFieldsSection',
			'newHireWithOfferLetter.doesHiringHavePayrollSettingsSection',
			'newHireWithOfferLetter.isHiringCustomFieldsComplete',
			'newHireWithOfferLetter.isHiringComplianceFieldsComplete',
			'newHireWithOfferLetter.doesHiringHaveIntegrationsSection',
			'newHireWithOfferLetter.isHiringIntegrationsComplete',
			'newHireWithOfferLetter.doesHiringHaveBenefitsInfoSection',
			'newHireWithOfferLetter.isDoingOfferLetter',
			'newHireWithOfferLetter.isHiringPreviewComplete',
			'hireDate', function () {
				var statuses = [
					{
						statusLabel: 'Contact Info',
						statusState: this.get('newHireWithOfferLetter.isHiringBasicComplete') ? 'complete' : undefined,
					},
					{
						statusLabel: 'Job Details',
						statusState: this.get('newHireWithOfferLetter.isHiringJobDetailsComplete') ? 'complete' : undefined,
					},
					{
						statusLabel: this.get('company.isContractorPaymentsCompany') ? 'Contract Details' : 'Offer Details',
						statusState: this.get('newHireWithOfferLetter.isHiringEmploymentInfoComplete') ? 'complete' : undefined,
					},
				];

				if (this.get('newHireWithOfferLetter.doesHiringHavePayrollSettingsSection')) {
					statuses.push(
						{
							statusLabel: 'Payroll Settings',
							statusState: this.get('newHireWithOfferLetter.isHiringPayrollSettingsComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.doesHiringHaveCustomFieldsSection')) {
					statuses.push(
						{
							statusLabel: 'Custom Fields',
							statusState: this.get('newHireWithOfferLetter.isHiringCustomFieldsComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.doesHiringHaveIntegrationsSection')) {
					statuses.push(
						{
							statusLabel: 'Apps',
							statusState: this.get('newHireWithOfferLetter.isHiringIntegrationsComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.doesHiringHaveBenefitsInfoSection')) {
					statuses.push(
						{
							statusLabel: 'Benefits Info',
							statusState: this.get('newHireWithOfferLetter.isHiringBenefitsInfoComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.isDoingOfferLetter')) {
					statuses.push(
						{
							statusLabel: 'Preview',
							statusState: this.get('newHireWithOfferLetter.isHiringPreviewComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('isReqAndSentForApproval')) {
					statuses.push(
						{
							statusLabel: 'Approval',
							statusState: undefined,
						}
					);
				}

				var popoverContent = Ember.Object.create({
					employee: this,
					employeeCustomField: {
						label: 'Start Date',
						value: this.get('hireDate') ? moment(this.get('hireDate')).format("MMM DD, YYYY") : 'Not specified',
					},
					statusesHeader: 'Administrator Tasks',
					statuses: statuses,
				});

				return popoverContent;

			}),
		onboardingProgressPopoverContent: Ember.computed('isRegistered',
			'isNewContractor',
			'shouldDoEligibility',
			'shouldDoEeo',
			'isReqAndSentForApproval',
			'newHireWithOfferLetter.isDoingOfferLetter',
			'newHireWithOfferLetter.isContractorDoingOfferLetter',
			'newHireWithOfferLetter.offerLetterSig',
			'newHireWithOfferLetter.isDoingAgreements',
			'newHireWithOfferLetter.isDoingEmployeeHandbook',
			'newHireWithOfferLetter.isDoingBackgroundCheck',
			'newHireWithOfferLetter.signingCompleteTimestamp',
			'newHireWithOfferLetter.isDoingOnboarding',
			'newHireWithOfferLetter.isDoingBackgroundCheck',
			'newHireWithOfferLetter.isDoingTax',
			'newHireWithOfferLetter.isDoingEligibility',
			'newHireWithOfferLetter.isDoingPersonalInfo',
			'newHireWithOfferLetter.isPersonalInfoComplete',
			'newHireWithOfferLetter.isDoingCustomFields',
			'newHireWithOfferLetter.isEmployeeCustomFieldsComplete',
			'newHireWithOfferLetter.eeoAsked',
			'newHireWithOfferLetter.isEeoComplete',
			'newHireWithOfferLetter.isFederalTaxInfoComplete',
			'newHireWithOfferLetter.isStateTaxInfoComplete',
			'newHireWithOfferLetter.isBankInfoComplete',
			'newHireWithOfferLetter.isDistributionInfoComplete',
			'newHireWithOfferLetter.isEligibilityVerificationComplete',
			'newHireWithOfferLetter.isDoingEligibilityProofUpload',
			'newHireWithOfferLetter.isContractorDoingOfferLetterComplete',
			'newHireWithOfferLetter.isContractorDoingTax',
			'newHireWithOfferLetter.isW9TaxInfoComplete',
			'newHireWithOfferLetter.isDoingFederalTax',
			'newHireWithOfferLetter.isDocumentVerificationComplete', function () {
				var statuses = [
					{
						statusLabel: 'Registered with '+brandName,
						statusState: this.get('isRegistered') ? 'complete' : undefined,
					},
				];

				if (this.get('newHireWithOfferLetter.isDoingOfferLetter') || this.get('newHireWithOfferLetter.isContractorDoingOfferLetter')) {
					statuses.push(
						{
							statusLabel: this.get('company.isContractorPaymentsCompany')?'Accepted and signed contract':'Accepted and signed offer letter',
							statusState: this.get('newHireWithOfferLetter.offerLetterSig') || this.get('newHireWithOfferLetter.isContractorDoingOfferLetterComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.isDoingAgreements') ||
					this.get('newHireWithOfferLetter.isDoingEmployeeHandbook') ||
					this.get('newHireWithOfferLetter.isDoingBackgroundCheck')) {
					statuses.push(
						{
							statusLabel: 'Accepted and signed additional documents',
							statusState: this.get('newHireWithOfferLetter.signingCompleteTimestamp') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.isDoingPersonalInfo')) {
					statuses.push(
						{
							statusLabel: 'Added personal information',
							statusState: this.get('newHireWithOfferLetter.isPersonalInfoComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.isDoingCustomFields')) {
					statuses.push(
						{
							statusLabel: 'Filled in custom fields',
							statusState: this.get('newHireWithOfferLetter.isEmployeeCustomFieldsComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('shouldDoEeo') && this.get('newHireWithOfferLetter.eeoAsked')) {
					statuses.push(
						{
							statusLabel: 'Completed equal opportunity survey',
							statusState: this.get('newHireWithOfferLetter.isEeoComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('newHireWithOfferLetter.isContractorDoingTax')) {
					statuses.push(
						{
							statusLabel: 'Provided taxpayer identification number',
							statusState: this.get('newHireWithOfferLetter.isW9TaxInfoComplete') ? 'complete' : undefined,
						}
					);
				}

				// TODO: Independent Contractors and Company Paid Temporary Worker should use this line item if they use PYP,
				// we can remove _isContractor and do a more specific filter when that happens (https://jira.inside-zen.com/browse/HRHUB-2406)
				if (this.get('newHireWithOfferLetter.isDoingFederalTax')) {
					if (this.get('newHireWithOfferLetter.isDoingOnboarding') || this.get('newHireWithOfferLetter.isDoingTax')) {
						var taxInfoComplete = this.get('newHireWithOfferLetter.isFederalTaxInfoComplete');
						if (!this.get('isInternational')) {
							taxInfoComplete &= this.get('newHireWithOfferLetter.isStateTaxInfoComplete');
						}

						statuses.push(
							{
								statusLabel: 'Provided tax information',
								statusState: taxInfoComplete ? 'complete' : undefined,
							}
						);
					}
				}

				if (!this.get('isNewContractor') && this.get('newHireWithOfferLetter.isDoingOnboarding')) {
					statuses.push(
						{
							statusLabel: 'Provided payment method information',
							statusState: this.get('newHireWithOfferLetter.isDistributionInfoComplete') ? 'complete' : undefined,
						}
					);
				}

				if (this.get('shouldDoEligibility')) {
					if (this.get('newHireWithOfferLetter.isDoingEligibility')) {
						statuses.push(
							{
								statusLabel: 'Verified employment eligibility',
								statusState: this.get('newHireWithOfferLetter.isEligibilityVerificationComplete') ? 'complete' : undefined,
							}
						);
					}

					if (this.get('newHireWithOfferLetter.isDoingEligibilityProofUpload')) {
						statuses.push(
							{
								statusLabel: 'Upload employment eligibility documents',
								statusState: this.get('newHireWithOfferLetter.isDocumentVerificationComplete') ? 'complete' : undefined,
							}
						);
					}
				}

				var popoverContent = Ember.Object.create({
					employee: this,
					employeeCustomField: {
						label: 'Start Date',
						value: this.get('hireDate') ? moment(this.get('hireDate')).format("MMM DD, YYYY") : 'Not specified',
					},
					statusesHeader: 'Onboarding Tasks',
					statuses: statuses,
				});

				return popoverContent;
			}),
		isSetAndOfferEditableByManager: function () {
			return this.get('status') == 'Set' &&
				(!this.get('newHireWithOfferLetter.isDoingOfferLetter') ||
					(this.get('newHireWithOfferLetter.isDoingOfferLetter') && !this.get('newHireWithOfferLetter.offerLetterSig')));
		}.property('status', 'newHireWithOfferLetter.isDoingOfferLetter', 'newHireWithOfferLetter.offerLetterSig'),
		employeeNumber: Ember.computed('currentEmployment.employeeNumber', function () {
			return this.get('currentEmployment.employeeNumber');
		}),
	});

	App.EmployeeBenefitsOfferingProperties = Ember.Model.extend({
		isMedicalOffered: attr('boolean'),
		isDentalOffered: attr('boolean'),
		isVisionOffered: attr('boolean'),
	});

	App.EmployeeCancellationProperties = Ember.Model.extend({
		medicalCancellationStatus: attr('string'),
		medicalCancellationEffectiveDate: attr('string'),
		dentalCancellationStatus: attr('string'),
		dentalCancellationEffectiveDate: attr('string'),
		visionCancellationStatus: attr('string'),
		visionCancellationEffectiveDate: attr('string'),
	});

	App.EmployeeCobraEnrolleeProperties = Ember.Model.extend({
		isCobraEnrollee: attr('boolean'),
	});

	App.EmployeeEnrollmentProperties = Ember.Model.extend({
		allEnrollments: DS.hasMany('App.EmployeeHealthEnrollment'),
		currentMedicalEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		currentDentalEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		currentVisionEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
	});

	App.EmployeeHealthStatusProperties = Ember.Model.extend({
		enrollmentStatus: attr('string'),
		medicalStatus: attr('string'),
		dentalStatus: attr('string'),
		visionStatus: attr('string'),
	});

	App.EmployeeImplementationProperties = Ember.Model.extend({
		isMedicalInImplementation: attr('boolean'),
		isDentalInImplementation: attr('boolean'),
		isVisionInImplementation: attr('boolean'),
	});

	App.EmployeeTieredProperties = Ember.Model.extend({
		medicalTieredContributionField: DS.belongsTo('App.CustomFieldValue'),
		dentalTieredContributionField: DS.belongsTo('App.CustomFieldValue'),
		visionTieredContributionField: DS.belongsTo('App.CustomFieldValue'),
		tieredWaitingPeriodField: DS.belongsTo('App.CustomFieldValue'),
	});


	App.EmployeeExemption = Ember.Model.extend({
		employee: Ember.belongsTo('App.AllEmployee'),
		taxType: attr('string'),
		jurisdiction: attr('string'),
		isExempt: attr('boolean'),
		status: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		displayName: attr('string'),
	});

	App.EmployeePayrollProperties = Ember.Model.extend({
		isInPayroll: attr('boolean'),
	});

	App.RequestedEmployeeExemption = Ember.Model.extend({
		employee: Ember.belongsTo('App.AllEmployee'),
		changes: DS.belongsTo('App.TaxExemptionChange'),
		taxType: attr('string'),
		jurisdiction: attr('string'),
		isExempt: attr('boolean'),
		startDate: attr('string'),
		endDate: attr('string'),
		displayName: attr('string'),
	});

	App.AllSyncEmployee = DS.Model.extend({
		email: attr('string'),
		first_name: attr('string'),
		last_name: attr('string'),
		inDE9C: attr('boolean'),
		payrollId: attr('string'),
		payrollProvider: attr('string'),
		wfCoCode: attr('string'),
		wfFileNumber: attr('string'),
		wfProposedFileNumber: attr('string'),
		usesWfTimeAndAttendance: attr('raw'),
		fullName: function () {
			return (this.get('first_name') || "") + " " + (this.get('last_name') || "").trim();
		}.property('first_name', 'last_name'),
		hireDate: attr('string'),
	});

	App.QuoteDependent = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		firstName: attr('string'),
		lastName: attr('string'),
		age: attr('string'),
		zipCode: attr('string'),
		type: attr('string'),
		gender: attr('string'),
		isSelected: attr('boolean'),

		fullName: Ember.computed(function () {
			return (this.get('firstName') || "") + " " + (this.get('lastName') || "").trim();
		}).property('firstName', 'lastName'),
	});

	App.CobraAutoPay = DS.Model.extend({
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		date: attr('string'),
		debitAmount: attr('string'),
		debitCheckNumber: attr('string'),
		debitType: attr('string'),
		monthPaidFor: attr('number'),
		yearPaidFor: attr('number'),
		numberOfMonthsPaidFor: attr('number'),
		processingStatus: attr('string'),
		billingPeriodEndDate: attr('string'),
		isFailedPayment: function () {
			return this.get('processingStatus') == 'failed';
		}.property('processingStatus'),
	});

	App.CobraRule = DS.Model.extend({
		state: attr('string'),
		isCobraOffered: attr('boolean'),
		isZenefitsAdministering: attr('boolean'),
		administrator: attr('string'),
		enrollDental: attr('boolean'),
		enrollVision: attr('boolean'),
		coveragePeriod: attr('number'),
		prettyCoveragePeriodUnits: attr('string'),
		coveragePeriodInMonths: attr('number'),
		adminFee: attr('string'),
		cobraName: attr('string'),
		firstPaymentDays: attr('number'),
		firstPaymentDue: attr('string'),
		phoneNumber: attr('string'),
		websiteLink: attr('string'),
		stateName: attr('string'),
		notIsZenefitsAdministering: Ember.computed.not('isZenefitsAdministering'),
		isCarrierCobra: Ember.computed.and('isCobraOffered', 'notIsZenefitsAdministering'),
		isFederal: function () {
			return this.get('state') == 'F';
		}.property('state'),
		stateCobraName: function () {
			if (this.get("isFederal")) {
				return this.get("cobraName");
			} else {
				if (this.get("cobraName") === "Mini-COBRA") {
					return this.get("stateName") + " " + this.get("cobraName");
				} else {
					return this.get("cobraName");
				}
			}
		}.property('stateName', 'isFederal', 'cobraName', 'stateName'),
		classification: function () {
			if (this.get('isFederal')) {
				return "federal";
			} else {
				return "state";
			}
		}.property('isFederal'),
		adminFeePercent: Ember.computed('adminFee', function () { return Math.round(this.get('adminFee') * 100, 0); }),
		hasAdminFee: function () {
			var adminFee = this.get('adminFeePercentOver100');
			if (!adminFee) {
				return false;
			}
			return adminFee != 0;
		}.property('adminFeePercentOver100'),
		adminFeePercentOver100: Ember.computed('adminFee', function () {
			var adminFee = Math.round(this.get('adminFee') * 100, 0) - 100;
			if (adminFee > 0) {
				return adminFee;
			} else {
				return null;
			}
		}),
	});

	App.EmployeeCobra = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		version_id: attr('number'),
		status: attr('string'),
		coveredPreviously: attr('boolean'),
		groupBenAdminStatusDuringTermination: attr('raw'),
		externalCobraNotificationDate: attr('string'),
		medicalCost: attr('number'),
		dentalCost: attr('number'),
		visionCost: attr('number'),
		totalCost: attr('number'),
		medicalCostWithAdminFee: attr('number'),
		dentalCostWithAdminFee: attr('number'),
		visionCostWithAdminFee: attr('number'),
		totalCostWithAdminFee: attr('number'),
		administerCOBRA: attr('boolean'),
		coverFullCobra: attr('boolean'),
		cobraMonths: attr('number'),
		cobraType: attr('string'),
		qualifyingEvent: attr('string'),
		qualifyingEventDate: attr('string'),
		overriddenQualifyingEventDate: attr('string'),
		cobraCosts: DS.hasMany('App.CobraCost'),
		bankName: attr('string'),
		bankAccountNumber: attr('string'),
		decryptedBankAccountNumber: attr('string'),
		bankAccountNumberEnc: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		agreementConfirmation: DS.belongsTo('App.Document'),
		agreementCancellation: DS.belongsTo('App.Document'),
		electionNotice: DS.belongsTo('App.Document'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		cancelAuthName: attr('string'),
		cancelAuthDate: attr('string'),
		cobraIneligibilityReason: attr('string'),
		cancelAuthSignature: attr('string'),
		addOneTimePayment: attr('boolean'),
		numberOfMonthsForOneTimePay: attr('number'),
		cobraAutoPays: DS.hasMany('App.CobraAutoPay'),
		cobraCancelationActions: DS.hasMany('App.CobraCancelationAction'),
		cobraCoverages: DS.hasMany('App.CobraCoverageInfo'),
		autoPayStart: attr('string'),
		numberOfMonthsFirstAutoPay: attr('number'),
		cobraCoverageStartDate: attr('string'),
		cobraCoverageEndDate: attr('string'),
		cobraPayDate: attr('string'),
		computedAdminFee: attr('string'),
		computedCobraNotificationDate: attr('string'),
		cobraRule: DS.belongsTo("App.CobraRule"),
		defaultAutoPayStart: attr('string'),
		remainingCobraDays: attr('number'),
		firstAutoPayAmount: attr('number'),
		paymentMode: attr('string'),
		dependentsInMedical: attr('string'),
		dependentsInDental: attr('string'),
		dependentsInVision: attr('string'),
		isCobraEligible: attr('boolean'),
		predictedEndDate: attr('string'),
		borUploadValidationRequired: attr('raw'),
		isUpcomingOEWindow: attr('boolean'),
		isEligibleForMedical: attr('boolean'),
		isEligibleForDental: attr('boolean'),
		isEligibleForVision: attr('boolean'),
		internalCobraNotificationDate: attr('string'),
		hasMedicalMinimumEligibility: attr('boolean'),
		hasDentalMinimumEligibility: attr('boolean'),
		hasVisionMinimumEligibility: attr('boolean'),
		firstPaymentAmount: function () {
			var numberOfMonthsForOneTimePay = parseFloat(this.get('numberOfMonthsForOneTimePay'));
			var totalCost = parseFloat(this.get('totalCostWithAdminFee'));

			return (numberOfMonthsForOneTimePay * totalCost).toFixed(2);
		}.property('numberOfMonthsForOneTimePay', 'totalCostWithAdminFee'),
		isFillingOut: Ember.computed.equal('status', 'filling-out'),
		isEnrolled: Ember.computed.equal('status', 'enrolled'),
		isDeclined: Ember.computed.equal('status', 'declined'),
		isSubmitted: Ember.computed.equal('status', 'submitted'),
		isCanceled: Ember.computed.equal('status', 'canceled'),
		isTpaAdmin: Ember.computed.equal('status', 'tpa-admin'),
		isNotEligible: function () {
			var remainingCobraDays = this.get('remainingCobraDays');
			var status = this.get('status');
			if (remainingCobraDays < 0 && !(status == 'enrolled' && status == 'declined' && status == 'canceled')) {
				return true;
			}
			return false;
		}.property('remainingCobraDays', 'status'),
		isExpired: function () {
			return this.get('status') == 'enrolled' && this.get('hasCoverageEnded');
		}.property('status', 'hasCoverageEnded'),
		isEnrolledOrCanceledOrSubmitted: function () {
			return this.get('status') == 'canceled' || this.get('status') == 'enrolled' || this.get('status') == 'submitted';
		}.property('status'),
		isEnrolledOrSubmitted: function () {
			return this.get('status') == 'enrolled' || this.get('status') == 'submitted';
		}.property('status'),
		noStatus: function () {
			return !this.get('status') || this.get('status') == 'filling-out';
		}.property('status'),
		endDate: attr('string'),
		cancelationType: attr('string'),
		reasonForManualCancelation: attr('string'),
		isManualCancelation: function () {
			return this.get('isCanceled') && this.get('cancelationType') == 'manual';
		}.property('isCanceled', 'cancelationType'),
		isLackOfPaymentCancelation: function () {
			return this.get('isCanceled') && this.get('cancelationType') == 'lack-of-payment';
		}.property('isCanceled', 'cancelationType'),
		isFinalCancelation: function () {
			return this.get('isCanceled') && !(this.get('cancelationType') == 'lack-of-payment');
		}.property('isCanceled', 'cancelationType'),
		isEnrollmentSectionComplete: null,
		isDependentSectionComplete: null,
		hasCoverageEnded: function () {
			var endDate = this.get('endDate');
			if (endDate) {
				var date = new Date(endDate);
				var today = new Date();
				today.setHours(0, 0, 0, 0);
				if (date < today) {
					return true;
				}
			}
			return false;
		}.property('endDate'),
		isCobraEndDateInFuture: function () {
			var cobraEndDate = this.get('endDate');
			if (!cobraEndDate || cobraEndDate == null) {
				return true;
			}
			else {
				var today = new Date();
				today.setHours(0, 0, 0, 0);
				cobraEndDate = new Date(cobraEndDate);
				var isCobraEndDateInFuture = (cobraEndDate - today) > 0 ? true : false;
				return isCobraEndDateInFuture;
			}
		}.property('endDate'),
		isCanceledAndCobraEndDateNotInFuture: function () {
			return this.get('status') == 'canceled' && !this.get('isCobraEndDateInFuture');
		}.property('status', 'isCobraEndDateInFuture'),
		isNoLongerCovered: function () {
			return (this.get('isCanceled') || this.get('isTpaAdmin')) && !this.get('isCobraEndDateInFuture');
		}.property('isCanceled', 'isTpaAdmin', 'isCobraEndDateInFuture'),
		isBorEmployeeAlreadyCovered: function () {
			if (this.get('coveredPreviously') && this.get('externalCobraNotificationDate')) {
				return true;
			}
			return false;
		}.property('coveredPreviously', 'externalCobraNotificationDate'),
		isInBor: function () {
			return this.get('isBorEmployeeAlreadyCovered');
		}.property('isBorEmployeeAlreadyCovered'),
		isEligible: function () {
			var remainingCobraDays = this.get('remainingCobraDays');
			if (remainingCobraDays > 0) {
				return true;
			}
			return false;
		}.property('remainingCobraDays'),
		isPaymentDueOnElection: function () {
			return this.get('cobraRule.firstPaymentDays') == 0 && this.get('cobraRule.firstPaymentDue') == 'firstPaymentAfterElection';
		}.property('cobraRule.firstPaymentDays', 'cobraRule.firstPaymentDue'),
		isPaymentDueAfterElection: function () {
			return this.get('cobraRule.firstPaymentDays') > 0 && this.get('cobraRule.firstPaymentDue') == 'firstPaymentAfterElection';
		}.property('cobraRule.firstPaymentDays', 'cobraRule.firstPaymentDue'),
		isPaymentDueAfterNotice: function () {
			return this.get('cobraRule.firstPaymentDue') == 'firstPaymentAfterNotice';
		}.property('cobraRule.firstPaymentDue'),
		isQualifyingEventForTermination: Ember.computed.equal('qualifyingEvent', 'termination'),
		isQualifyingEventForPTSwitch: Ember.computed.equal('qualifyingEvent', 'move_out_of_ft'),
		eligibilityDate: function () {
			var isEligible = this.get('isEligible');
			var remainingCobraDays = this.get('remainingCobraDays');
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			var d = new Date();
			if (remainingCobraDays < 0 || !isEligible) {
				return;
			}
			d.setDate(today.getDate() + remainingCobraDays);
			return (1 + d.getMonth()) + "/" + d.getDate() + "/" + d.getFullYear();
		}.property('isEligible', 'remainingCobraDays'),
		cobraCoverageStartDateString: function () {
			var cobraCoverageStartDate = this.get('cobraCoverageStartDate');
			if (!cobraCoverageStartDate) {
				return "";
			}
			return cobraCoverageStartDate;
		}.property('cobraCoverageStartDate'),
		dayBeforeCobraPayDate: function () {
			var cobraPayDate = this.get('cobraPayDate');
			if (!cobraPayDate) {
				return "";
			}
			cobraPayDate = moment(cobraPayDate, 'MM/DD/YYYY');
			cobraPayDate.subtract(1, 'days');
			return cobraPayDate.format('MM/DD/YYYY');
		}.property('cobraPayDate'),
		isCobraPayDateInFuture: function () {
			var cobraPayDate = this.get('cobraPayDate');
			if (!cobraPayDate) {
				return "";
			}
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			cobraPayDate = new Date(cobraPayDate);
			var isInFuture = (cobraPayDate - today) > 0 ? true : false;
			return isInFuture;
		}.property('cobraPayDate'),
		isEnrolledInMedical: function () {
			var dependents = this.get('dependentsInMedical');
			var hasMedicalPlan = this.get('employee.medicalPlan');
			return dependents && dependents.length > 0 && hasMedicalPlan;
		}.property('dependentsInMedical', 'employee.medicalPlan'),
		isEnrolledInDental: function () {
			var dependents = this.get('dependentsInDental');
			var hasDentalPlan = this.get('employee.dentalPlan');
			return dependents && dependents.length > 0 && hasDentalPlan;
		}.property('dependentsInDental', 'employee.dentalPlan'),
		isEnrolledInVision: function () {
			var dependents = this.get('dependentsInVision');
			var hasVisionPlan = this.get('employee.visionPlan');
			return dependents && dependents.length > 0 && hasVisionPlan;
		}.property('isEnrolledInVision', 'employee.visionPlan'),
		saveOriginalCobraMonths: function () {
			this.set('originalCobraMonths', this.get('cobraMonths'));
		}.on('didLoad'),
	});


	App.EmployeeCobraTakeover = DS.Model.extend({
		employeeSSN: DS.attr('string'),
		firstName: DS.attr('string'),
		lastName: DS.attr('string'),
		dob: DS.attr('string'),
		hireDate: DS.attr('string'),
		qualifyingEvent: DS.attr('string'),
		qualifyingEventDate: DS.attr('string'),
		coveredPreviously: DS.attr('string'),
		externalCobraNotificationDate: DS.attr('string'),
		email: DS.attr('string'),
		enrollInMedical: DS.attr('string'),
		enrollInDental: DS.attr('string'),
		enrollInVision: DS.attr('string'),

		medicalPlanName: DS.attr('string'),
		medicalPlanId: DS.attr('number'),
		medicalMemberId: DS.attr('string'),
		medicalPlanType: DS.attr('string'),
		medicalCost: DS.attr('string'),
		medicalEffectiveDate: DS.attr('string'),

		dentalPlanName: DS.attr('string'),
		dentalPlanId: DS.attr('number'),
		dentalMemberId: DS.attr('string'),
		dentalPlanType: DS.attr('string'),
		dentalCost: DS.attr('string'),
		dentalEffectiveDate: DS.attr('string'),

		visionPlanName: DS.attr('string'),
		visionPlanId: DS.attr('number'),
		visionMemberId: DS.attr('string'),
		visionPlanType: DS.attr('string'),
		visionCost: DS.attr('string'),
		visionEffectiveDate: DS.attr('string'),

		validationRequired: DS.attr('boolean'),
		administerCOBRA: DS.attr('boolean'),
		cobraType: DS.attr('string'),
		employerCoveredCobraMonths: DS.attr('string'),
		coverFullCobra: DS.attr('boolean'),
		borRemoveBenefits: DS.attr('boolean'),
		cobraPayDate: attr('string'),

		reason: attr('string'),

		fullNameInverted: function () {
			return this.get('lastName') + ", " + this.get('firstName');
		}.property('firstName', 'lastName'),

		isCobraPreviouslyCovered: Ember.computed.equal('coveredPreviously', 'previously_covered'),
		isCobraNotified: Ember.computed.equal('coveredPreviously', 'notified'),
		isCobraNew: Ember.computed.equal('coveredPreviously', 'new'),

		hasMedicalPlanType: function () {
			return this.get("medicalPlanType") && this.get("medicalPlanType") !== "";
		}.property('medicalPlanType'),

		hasDentalPlanType: function () {
			return this.get("dentalPlanType") && this.get("dentalPlanType") !== "";
		}.property('dentalPlanType'),

		hasVisionPlanType: function () {
			return this.get("visionPlanType") && this.get("visionPlanType") !== "";
		}.property('visionPlanType'),

		hasExternalNotificationDate: function () {
			return this.get('coveredPreviously') != 'new';
		}.property('coveredPreviously'),
	});


	App.CobraCost = DS.Model.extend({
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		medicalCost: attr('number'),
		dentalCost: attr('number'),
		visionCost: attr('number'),
	});

	App.CobraCancelationAction = DS.Model.extend({
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		lineOfCoverage: attr('string'),
		status: attr('string'),
		coverageEndDate: attr('string'),
		effectiveDate: attr('string'),
		cancelationType: attr('string'),
		cancelationReason: attr('string'),
		cancelationSignature: DS.belongsTo('App.Signature'),
		lastPayment: DS.belongsTo('App.CobraAutoPay'),
		notRealized: function () {
			return this.get('status') != "realized";
		}.property('status')
	});

	App.CobraCoverageInfo = DS.Model.extend({
		employeeCobra: DS.belongsTo('App.EmployeeCobra'),
		lineOfCoverage: attr('string'),
		status: attr('string'),
		coverageStartDate: attr('string'),
		coverageEndDate: attr('string'),
		paidThroughDate: attr('string'),
		submitToCarrierDate: attr('string'),
		submitToCarrier: attr('boolean'),
		isSubmitted: Ember.computed.equal('status', 'submitted'),
		isEnrolled: Ember.computed.equal('status', 'enrolled'),
		isSubmittedOrEnrolled: Ember.computed.or('isSubmitted', 'isEnrolled'),
	});

	App.CobraPayment = DS.Model.extend({
		creditInvoicePdfUrl: attr('string'),
		creditDate: attr('string'),
		creditAmount: attr('number'),
		status: attr('string'),
		name: attr('string'),
		isCompleted: Ember.computed.equal('status', 'C'),
		isPending: Ember.computed.equal('status', 'P'),
		isSent: Ember.computed.equal('status', 'S'),
		isFailed: Ember.computed.equal('status', 'F'),
		isCancelled: Ember.computed.equal('status', 'X'),
	});


	App.CompanyCobra = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		cobraClassification: DS.hasMany('App.CobraClassification'),
		status: attr('string'),
		coolingOffDays: attr('number'),
		thirdPartyAdministrators: DS.hasMany('companyCobraThirdPartyAdministrator'),
		previousAdministrator: DS.belongsTo('App.CobraThirdPartyAdministrator'),
		previousAdminName: attr('string'),
		previousAdminEmail: attr('string'),
		previousHaveParticipants: attr('boolean'),
		previousAdministratorType: attr('string'),
		companyCobraTakeover: DS.belongsTo('App.CompanyCobraTakeover'),
		takeoverDate: attr('string'),
		takeoverSignDate: attr('string'),
		takeoverAdminName: attr('string'),
		takeoverAdminSignature: attr('string'),
		takeoverAdminTitle: attr('string'),
		cobraBankName: attr('string'),
		cobraBankAccountNumber: attr('string'),
		cobraBankRoutingNumber: attr('string'),
		cobraBankAccountType: attr('string'),
		cobraBankAuthName: attr('string'),
		cobraBankAuthSignature: attr('string'),
		cobraFullTimeCount: attr('number'),
		cobraPartTimeCount: attr('number'),
		cobraPartTimeCountRawData: attr('string'),
		countEmployees: attr('number'),
		hasActiveCobraEmployees: attr('boolean'),
		isBankInfoComplete: attr('boolean'),
		isNotZenefitsAdministered: attr('boolean'),
		hasCompleteBankRecord: function () {
			return this.get('cobraBankAccountNumber') && this.get('cobraBankRoutingNumber');
		}.property('cobraBankAccountNumber', 'cobraBankRoutingNumber'),
		closeoutDate: function () {
			var takeover = this.get('takeoverDate');
			if (!takeover || takeover.length == 0) {
				return null;
			}

			takeover = moment(takeover, 'MM/DD/YYYY');
			return takeover.subtract(1, 'days');
		}.property('takeoverDate'),
		isStatusConfirmed: Ember.computed.equal('status', 'confirmed'),
		cobraEmployeesExist: function () {
			return this.get('countEmployees') > 0;
		}.property('countEmployees'),
		isStatusSetupOrRequested: function () {
			return this.get('status') == 'requested' || this.get('status') == 'setup';
		}.property('status'),
		isStatusRequested: Ember.computed.equal('status', 'requested'),
		isStatusFillingOut: Ember.computed.equal('status', 'filling-out'),
		isStatusUnsetOrThirdParty: function () {
			return !this.get('status') || this.get('status') === '' || this.get('status') === 'third-party';
		}.property('status'),
		wasSelfAdministered: function () {
			return this.get('previousAdministratorType') === 'self';
		}.property('previousAdministratorType'),
	});


	App.CompanyCobraTakeover = DS.Model.extend({
		letterhead: attr('string'),
		letterbody: attr('string'),
	});


	App.CobraThirdPartyAdministrator = DS.Model.extend({
		name: attr('string'),
		email: attr('string'),
		phoneNumber: attr('string'),
		employeeContactName: attr('string'),
		employeeContactEmail: attr('string'),
		employeeContactPhoneNumber: attr('string'),
		isVerified: attr('boolean'),
		defaultCoolingOffDays: attr('number'),
		isCloseOutReportRequired: attr('boolean'),
		isTerminationFormRequired: attr('boolean'),
		isKnownPartner: attr('boolean'),
		claimsAddress: DS.belongsTo('App.Address'),
		type: attr('string'),
	});

	App.CobraClassification = DS.Model.extend({
		companyCobra: DS.belongsTo('App.CompanyCobra'),
		documents: DS.hasMany('App.CobraClassificationDocument'),
		cobraType: attr('string'),
		effectiveDate: attr('string'),
		reason: attr('string'),
		isVerified: attr('boolean'),
		countingMethod: attr('string'),
		cobraFullTimeCount: attr('number'),
		cobraPartTimeCount: attr('number'),
		cobraPartTimeCountRawData: attr('string'),
		adminName: attr('string'),
		adminSignature: attr('string'),
		adminTitle: attr('string'),
		adminSignDate: attr('string'),

		cobraTotalCount: function () {
			return parseFloat(this.get('cobraFullTimeCount')) + parseFloat(this.get('cobraPartTimeCount'));
		}.property('cobraFullTimeCount', 'cobraPartTimeCount'),

		isCurrentYear: function () {
			var currentYear = moment([moment().year(), 0, 1]);
			var edate = moment(this.get('effectiveDate'), "MM/DD/YYYY");
			return edate.isValid() && currentYear.isSame(edate);
		}.property('effectiveDate'),

		cobraPartTimeCountObjects: function () {
			var results = Ember.A();
			var rawData = this.get('cobraPartTimeCountRawData');
			if (rawData) {
				var parsedData = JSON.parse(rawData);
				if (parsedData && parsedData.length > 0) {
					parsedData.forEach(function (item) {
						results.pushObject(Ember.Object.create({
							count: item[0],
							hours: item[1]
						}));
					});
				}
			}

			return results;
		}.property('cobraPartTimeCountRawData'),
	});


	App.CobraClassificationDocument = DS.Model.extend({
		classification: DS.belongsTo("App.CobraClassification"),
		document: DS.belongsTo("App.Document"),
	});


	App.CompanyCobraEnrollmentApi = _PermissionsAbstractModel.extend({
		employees: DS.hasMany('App.AllEmployee'),
		employeeCobra: DS.hasMany('App.EmployeeCobra'),
	}).reopenClass({
		noBatch: true
	});


	App._EnrollmentStatusMixin = Ember.Mixin.create({
		status: attr('string'),
		statusDisplayName: attr('string'),
		isNotEnrolling: Ember.computed.none('status'),
		isFillingOut: Ember.computed.equal('status', 'filling-out'),
		isDeclineFilling: Ember.computed.equal('status', 'decline-filling'),
		isSetupComplete: Ember.computed.equal('status', 'complete'),
		isComplete: Ember.computed.equal('status', 'complete'),
		isDecline: Ember.computed.equal('status', 'decline'),
		isCanceled: Ember.computed.equal('status', 'canceled'),
		toSendEmails: Ember.computed.equal('status', 'confirm-send-to-employees'),
		isEmails: Ember.computed.equal('status', 'emails'),
	});

	App._CompanyEnrollmentValidationMixin = Ember.Mixin.create({
		isCompanyInfoComplete: attr('boolean'),
		isBankInfoComplete: attr('boolean'),
	});

	App._EmployeeEnrollmentValidationMixin = Ember.Mixin.create({
		isBasicInfoComplete: attr('boolean'),
		isContactInfoComplete: attr('boolean'),
	});



	App.Company401kPayroll = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		journalUrl: attr('string'),
		providerUploadUrl: attr('string'),
		journalUploadDate: attr('string'),
		providerUploadDate: attr('string'),
		onCyclePayroll: attr('boolean'),
		checkDate: attr('string'),
		payPeriodStartDate: attr('string'),
		payPeriodEndDate: attr('string'),
		journalVersion: attr('number'),
		status: attr('string'),
		statusText: attr('string'),
		isStatusSent: attr('boolean'),
		isStatusProcessing: attr('boolean'),
		hasMultipleVersionsOfPayroll: attr('boolean'),
		fullJournalUrl: attr('string'),
		paystubConversionResults: attr('string'),
		isBefore2016Jan1: function () {
			var checkDate = this.get('checkDate');
			return checkDate === null || moment(checkDate).isBefore(moment('01/01/2016', 'MM/DD/YYYY'));
		}.property('checkDate'),
	});

	App.CompanyPayroll401kRun = DS.Model.extend({
		company401kEnrollment: DS.belongsTo('App.Company401kEnrollment'),
		paystubStartTime: attr('string'),
		paystubEndTime: attr('string'),
		runStartTime: attr('string'),
		runEndTime: attr('string'),
		status: attr('string'),
		isGenerated: attr('boolean'),
		isSent: attr('boolean'),
		sentStatus: attr('string'),
		isManual: attr('boolean'),
		payDate: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		payrollUrl: attr('string'),
		comments: attr('string'),
	});

	App.Company401kEnrollment = DS.Model.extend(App._EnrollmentStatusMixin, {
		company: DS.belongsTo('App.Company'),
		customk: DS.belongsTo('App.Company401kCustomk'),
		isCustomk: attr('boolean'),
		isSafeHarbor: attr('boolean'),
		company401kTrustee: DS.belongsTo('App.Company401kTrustee'),
		planType: attr('string'), // Can be 'safe-harbor', 'custom', 'no-contrib'
		matchingContribution: attr('number'),
		fixedContribution: attr('number'),
		fiscalYearEndDate: attr('string'),
		stateOfIncorporation: attr('string'),
		dateOfIncorporation: attr('string'),
		llcTaxType: attr('string'),
		rollOver: Ember.computed.boolString('shouldRollOver'),
		simpleIra: Ember.computed.boolString('hasSimpleIra'),
		jointOwnership: Ember.computed.boolString('hasJointOwnership'),
		autoEnroll: Ember.computed.boolString('isAutoEnroll'),
		keyEmployeeInfo: null,
		highlyCompensatedEmployeeInfo: null,
		complianceTestingInfo: null,
		shouldRollOver: attr('raw'),
		existingProvider: attr('string'),
		existingProviderDisplay: attr('string'),
		setupFee: attr('string'),
		providerUsername: attr('string'),
		providerPassword: attr('string'),
		hasSimpleIra: attr('raw'),
		hasJointOwnership: attr('raw'),
		isAutoEnroll: attr('raw'),
		startDate: attr('string'),
		effectiveDate: attr('string'),
		firstPayrollAfterStart: attr('string'),
		custodialAccountId: attr('string'),
		payrollJournalUrl: attr('string'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		agreementAuthDate: attr('string'),
		agreementAuthSignature: attr('string'),
		adoptionAgreementUrl: attr('string'),
		loanPolicyAgreementUrl: attr('string'),
		custodialAccountAgreementUrl: attr('string'),
		expressKA4SUrl: attr('string'),
		autoEnrollContribution: attr('number'),
		payrollManagementMode: attr('string'),
		nhceADP: attr('number'),
		hceADP: attr('number'),
		adpLimit: attr('number'),
		nhceACP: attr('number'),
		hceACP: attr('number'),
		acpLimit: attr('number'),
		participatingEmployeeCount: attr('number'),
		totalCostToCompany: attr('number'),
		canCompanyContribute: attr('boolean'),
		showNoteOnTotalCost: attr('boolean'),
		hceIncomeThreshold: attr('string'),
		isSafeHarborPlan: Ember.computed.equal('planType', 'safe-harbor'),
		isNoContributionPlan: Ember.computed.equal('planType', 'no-contrib'),
		isCustomPlan: Ember.computed.equal('planType', 'custom'),
		isPayrollProviderADP: Ember.computed.equal('company.payrollProvider', 'AD'),
		hasBegun: function () {
			return (this.get('status') != null && this.get('status') != 'add-existing-in-progress');
		}.property('status'),
		isCustomPlanInProgress: Ember.computed.equal('status', 'custom-in-progress'),
		isAddExisting: Ember.computed.equal('status', 'add-existing'),
		isAddExistingInProgress: Ember.computed.equal('status', 'add-existing-in-progress'),
		toSendEmails: Ember.computed.equal('status', 'confirm-send-to-employees'),
		isFixedContribution: function () {
			var planType = this.get('planType');
			if (planType && planType == 'custom') {
				if (this.get('fixedContribution') != null) {
					return true;
				}
			}
			return false;
		}.property('planType', 'matchingContribution'),
		companyContribution: function () {
			var planType = this.get('planType');
			if (!planType || planType == 'no-contrib') {
				return 0;
			}
			else if (planType == 'safe-harbor') {
				return 4;
			}
			else { // For custom plans
				if (this.get('matchingContribution') != null) {
					return this.get('matchingContribution');
				}
				else {
					return this.get('fixedContribution');
				}
			}
		}.property('planType', 'matchingContribution', 'fixedContribution'),
		isPayrollSynced: attr('boolean'),
		//Temporary function
		isStartDateIn2014: function () {
			var startDateStr = this.get('startDate');
			var startDate = new Date(startDateStr);
			return startDate.getFullYear() == 2014;
		}.property('startDate'),
	});

	App.Company401kCustomk = DS.Model.extend({
		company401kEnrollment: DS.belongsTo('App.Company401kEnrollment'),
		firstTierPercentageContribution: attr('number'),
		firstTierThreshold: attr('number'),
		secondTierPercentageContribution: attr('number'),
		secondTierThreshold: attr('number'),
		secondTier: attr('number'),
		firstTierDollarMatch: attr('number'),
		secondTierDollarMatch: attr('number'),
		allowRothContributions: attr('boolean'),
		customMatchType: attr('string'),
		eligibilityPeriod: attr('string'),
		eligibilityPeriodDisplay: attr('string'),
		eligibilityFrequency: attr('number'),
		isNonElective: attr('boolean'),
		nonElectivePercentageContribution: attr('number'),
		annualPercentageContributionLimit: attr('number'),
		percentageEmployeeContributionMatch: attr('number'),
		showRothData: function () {
			var allowRothContributions = this.get('allowRothContributions');
			var isCustomk = this.get('company401kEnrollment').get('isCustomk');

			if (allowRothContributions != null && allowRothContributions == true && isCustomk != null && isCustomk == true) {
				return true;
			}
			else {
				return false;
			}
		}.property('company401kEnrollment.@each.isCustomk', 'allowRothContributions'),
		showWaitingPeriodData: function () {
			var isCustomk = this.get('company401kEnrollment').get('isCustomk');
			var eligibilityPeriod = this.get('eligibilityPeriod');
			if (isCustomk != null && isCustomk == true && eligibilityPeriod != null && eligibilityPeriod != 'none') {
				return true;
			}
			else {
				return false;
			}
		}.property('company401kEnrollment.@each.isCustomk', 'eligibilityPeriod'),
		eligibilityFrequencyDisplay: function () {
			var eligibilityFrequency = this.get('eligibilityFrequency');
			if (!eligibilityFrequency) {
				return 0;
			}
			else {
				return eligibilityFrequency;
			}
		}.property('eligibilityFrequency'),
		showFirstTierData: function () {
			var firstTierDollarMatch = this.get('firstTierDollarMatch');
			if (firstTierDollarMatch) {
				return true;
			}
			else {
				return false;
			}
		}.property('firstTierDollarMatch'),
	});

	App.Company401kTrustee = DS.Model.extend({
		company401kEnrollment: DS.belongsTo('App.Company401kEnrollment'),
		socialSecurity: attr('string'),
		socialSecurityEnc: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		title: attr('string'),
		dob: attr('string'),
		email: attr('string'),
		hireDate: attr('string'),
		isTrusteeParticipating: attr('raw'),
		trusteeParticipating: function () {
			if (this.get('isTrusteeParticipating') == null || this.get('isTrusteeParticipating') == undefined) {
				return null;
			}
			if (this.get('isTrusteeParticipating')) {
				return 'yes';
			}

			return 'no';
		}.property('isTrusteeParticipating'),
		fullName: function () {
			return (this.get('firstName') || '') + " " + (this.get('lastName') || '');
		}.property('firstName', 'lastName')
	});


	App.Employee401kCompanyContrib = DS.Model.extend({
		employee401kEnrollment: DS.belongsTo('App.Employee401kEnrollment'),
		matchingContribution: attr('number'),
		fixedContribution: attr('number'),
		firstTierPercentageContribution: attr('number'),
		firstTierThreshold: attr('number'),
		secondTierPercentageContribution: attr('number'),
		secondTierThreshold: attr('number'),
		secondTier: attr('number'),
		firstTierDollarMatch: attr('number'),
		secondTierDollarMatch: attr('number'),
		nonElectivePercentageContribution: attr('number'),
		annualPercentageContributionLimit: attr('number'),
		percentageEmployeeContributionMatch: attr('number'),
		canCompanyContribute: attr('boolean'),
		effectiveDate: attr('string'),

		isFixedContribution: Ember.computed.notEmpty('fixedContribution'),
		companyContribution: function () {
			if (this.get('matchingContribution') != null) {
				return parseFloat(this.get('matchingContribution'));
			}
			else if (this.get('fixedContribution') != null) {
				return parseFloat(this.get('fixedContribution'));
			}
			else {
				return null;
			}
		}.property('matchingContribution', 'fixedContribution'),
		showFirstTierData: Ember.computed.bool('firstTierDollarMatch'),
	});

	App.Employee401kContributionChange = DS.Model.extend(zen._Employee401KMixin, {
		employee: DS.belongsTo('App.AllEmployee'),
		status: attr('string'),
		contribution: attr('number'),
		fixedContribution: attr('number'),
		rothPercentageContribution: attr('number'),
		rothFixedContribution: attr('number'),
		effectiveDate: attr('string'),
		reason: attr('string'),
		considerRothContributions: attr('boolean'),
		effectiveDateAsDate: function () {
			return moment(this.get('effectiveDate'), 'MM/DD/YYYY').toDate();
		}.property('effectiveDate'),
		canExpressContributionInBothFormat: function () {
			var salary = Number(this.get('employee.monthlySalary'));
			var contribution = this.get('contribution');
			var fixedContribution = this.get('fixedContribution');
			var rothFixedContribution = this.get('rothFixedContribution');
			var rothPercentageContribution = this.get('rothPercentageContribution');
			if (salary && (contribution != null || fixedContribution != null ||
				rothFixedContribution != null || rothPercentageContribution != null)) {
				return true;
			}
			return false;
		}.property('employee.monthlySalary', 'contribution', 'fixedContribution', 'rothFixedContribution', 'rothPercentageContribution'),
		canExpressContributionInDollars: function () {
			var salary = Number(this.get('employee.monthlySalary'));
			var fixedContribution = this.get('fixedContribution');
			var rothFixedContribution = this.get('rothFixedContribution');
			if (salary || fixedContribution != null || rothFixedContribution != null) {
				return true;
			}
			return false;
		}.property('employee.monthlySalary', 'fixedContribution', 'rothFixedContribution'),
		employeeContributionPerMonth: function () {
			var contribution = this.get('contribution');
			var salary = Number(this.get('employee.monthlySalary'));
			var fixedContribution = this.get('fixedContribution');
			return this.calculateEmployeeContributionPerMonth(contribution, salary, fixedContribution);
		}.property('contribution', 'employee.monthlySalary', 'fixedContribution'),
		employeeRothContributionPerMonth: function () {
			var considerRothContributions = this.get('considerRothContributions');
			if (!considerRothContributions) {
				return 0;
			}
			var rothPercentageContribution = this.get('rothPercentageContribution');
			var salary = Number(this.get('employee.monthlySalary'));
			var rothFixedContribution = this.get('rothFixedContribution');
			return this.calculateEmployeeContributionPerMonth(rothPercentageContribution, salary, rothFixedContribution);
		}.property('rothPercentageContribution', 'employee.monthlySalary', 'rothFixedContribution', 'considerRothContributions'),
		isFixedContribution: function () {
			if (this.get('fixedContribution') != null) {
				return true;
			}
			return false;
		}.property('fixedContribution'),
		isFixedRothContribution: function () {
			if (this.get('rothFixedContribution') != null) {
				return true;
			}
			return false;
		}.property('rothFixedContribution'),
	});

	App.Employee401kEnrollment = DS.Model.extend(App._EnrollmentStatusMixin, zen._Employee401KMixin, {
		employee: DS.belongsTo('App.AllEmployee'),
		employee401kBeneficiary: DS.belongsTo('App.Employee401kBeneficiary'),
		actualContributionYTDSeed: attr('number'),
		actualRothContributionYTDSeed: attr('number'),
		ytdStartDateSeed: attr('string'),
		ytdEndDateSeed: attr('string'),
		pendingContributionChanges: DS.hasMany('App.Employee401kContributionChange'),
		upcomingContributionDate: attr('string'),
		employee401kCompanyContrib: DS.belongsTo('App.Employee401kCompanyContrib'),
		// This property shows the actual contribution employee has made for 401k YTD.  Its a computed server side property, based on employee401kPeriodicContribution
		employeeActualContributionYTD: DS.attr('number'),
		employeeActualRothContributionYTD: DS.attr('number'),
		contribution: attr('number'),
		fixedContribution: attr('number'),
		rothPercentageContribution: attr('number'),
		rothFixedContribution: attr('number'),
		isKeyEmployee: attr('boolean'),
		isHighlyCompensatedEmployee: attr('boolean'),
		maxAllowedDeductionPerMonthAmount: attr('number'),
		warningAllowedDeductionPerMonthAmount: attr('number'),
		considerRothContributions: attr('boolean'),
		isDeductionTypeCatchup: attr('boolean'),
		isEligible: attr('boolean'),
		canExpressContributionInBothFormat: function () {
			var salary = Number(this.get('employee.monthlySalary'));
			var contribution = this.get('contribution');
			var fixedContribution = this.get('fixedContribution');
			var rothFixedContribution = this.get('rothFixedContribution');
			var rothPercentageContribution = this.get('rothPercentageContribution');
			if (salary && (contribution != null || fixedContribution != null ||
				rothFixedContribution != null || rothPercentageContribution != null)) {
				return true;
			}
			return false;
		}.property('employee.monthlySalary', 'contribution', 'fixedContribution', 'rothFixedContribution', 'rothPercentageContribution'),
		canExpressContributionInDollars: function () {
			var salary = Number(this.get('employee.monthlySalary'));
			var fixedContribution = this.get('fixedContribution');
			var rothFixedContribution = this.get('rothFixedContribution');
			if (salary || fixedContribution != null || rothFixedContribution != null) {
				return true;
			}
			return false;
		}.property('employee.monthlySalary', 'fixedContribution', 'rothFixedContribution'),
		employeeContributionPerMonth: function () {
			var contribution = this.get('contribution');
			var salary = Number(this.get('employee.monthlySalary'));
			var fixedContribution = this.get('fixedContribution');
			return this.calculateEmployeeContributionPerMonth(contribution, salary, fixedContribution);
		}.property('contribution', 'employee.monthlySalary', 'fixedContribution'),
		payPeriodDivisor: attr('number'),
		employeeRothContributionPerMonth: function () {
			var considerRothContributions = this.get('considerRothContributions');
			if (!considerRothContributions) {
				return 0;
			}
			var rothPercentageContribution = this.get('rothPercentageContribution');
			var salary = Number(this.get('employee.monthlySalary'));
			var rothFixedContribution = this.get('rothFixedContribution');
			return this.calculateEmployeeContributionPerMonth(rothPercentageContribution, salary, rothFixedContribution);
		}.property('rothPercentageContribution', 'employee.monthlySalary', 'rothFixedContribution', 'considerRothContributions'),
		isFixedRothContribution: function () {
			if (this.get('rothFixedContribution') != null) {
				return true;
			}
			return false;
		}.property('rothFixedContribution'),
		rothPercentageContributionDisplay: function () {
			if (this.get('isFixedRothContribution')) {
				var salary = Number(this.get('employee.monthlySalary'));
				if (salary) {
					var percentageContribution = ((this.get('rothFixedContribution') / salary) * 100).toFixed(2);
					return parseFloat(percentageContribution);
				}
				else {
					return null;
				}
			}
			var rothPercentageContribution = this.get('rothPercentageContribution');
			if (rothPercentageContribution != null) {
				return parseFloat(rothPercentageContribution);
			}
			return 0;
		}.property('isFixedRothContribution', 'rothPercentageContribution', 'rothFixedContribution', 'employee.monthlySalary'),
		isFixedContribution: function () {
			if (this.get('fixedContribution') != null) {
				return true;
			}
			return false;
		}.property('fixedContribution'),
		percentageContributionDisplay: function () {
			if (this.get('isFixedContribution')) {
				var salary = Number(this.get('employee.monthlySalary'));
				if (salary) {
					var percentageContribution = ((this.get('fixedContribution') / salary) * 100).toFixed(2);
					return parseFloat(percentageContribution);
				}
				else {
					return null;
				}
			}
			var contribution = this.get('contribution');
			if (contribution != null) {
				return parseFloat(contribution);
			}
			return 0;
		}.property('isFixedContribution', 'contribution', 'fixedContribution', 'employee.monthlySalary'),
		companyFixedMatchingMaxContribution: function () { /** company contribution for fixed and matching contribution ONLY, max because we do not consider employee contribution*/
			var companyContribution = this.get('employee401kCompanyContrib.companyContribution');
			var isFixed = this.get('employee401kCompanyContrib.isFixedContribution');
			if (isFixed) {
				return companyContribution;
			}
			else {
				var salary = Number(this.get('employee.monthlySalary'));
				return parseFloat(parseFloat((salary * companyContribution) / 100).toFixed(2));
			}
		}.property('employee401kCompanyContrib.companyContribution', 'employee401kCompanyContrib.isFixedContribution', 'employee.monthlySalary'),
		companyContribPercentageWithSalary: function () {
			var salary = Number(this.get('employee.monthlySalary'));
			var actualCompanyContribution = this.get('actualCompanyContribution');
			return this.calculateCompanyContribPercentageWithSalary(salary, actualCompanyContribution);
		}.property('employee.monthlySalary', 'actualCompanyContribution'),
		companyContribPercentageWithoutSalary: function () {
			/**
			 * This function returns the company contribution as a percentage of salary, ONLY when the employees has no salary
			 * AND employees are contributing as percentage (which implies that company is also contributing as percentage)
			 */
			var nonElectivePercentageContribution = parseFloat(this.get('employee401kCompanyContrib.nonElectivePercentageContribution') || 0),
				firstTierPercentageContribution = this.get('employee401kCompanyContrib.firstTierPercentageContribution'),
				firstTierThreshold = this.get('employee401kCompanyContrib.firstTierThreshold'),
				secondTierPercentageContribution = this.get('employee401kCompanyContrib.secondTierPercentageContribution'),
				secondTier = this.get('employee401kCompanyContrib.secondTier'),
				contribution = parseFloat(this.get('contribution') || 0),
				rothPercentageContribution = parseFloat(this.get('rothPercentageContribution') || 0),
				companyMatchingContribution = this.get('employee401kCompanyContrib.matchingContribution');

			return this.calculateCompanyContribPercentageWithoutSalary(nonElectivePercentageContribution, firstTierPercentageContribution, firstTierThreshold,
				secondTierPercentageContribution, secondTier, contribution, rothPercentageContribution, companyMatchingContribution);
		}.property('contribution', 'rothPercentageContribution', 'employee401kCompanyContrib.nonElectivePercentageContribution',
			'employee401kCompanyContrib.firstTierPercentageContribution', 'employee401kCompanyContrib.firstTierThreshold',
			'employee401kCompanyContrib.secondTierPercentageContribution', 'employee401kCompanyContrib.secondTier',
			'employee401kCompanyContrib.matchingContribution'),
		actualCompanyContribution: function () {
			var companyContribution = this.get('employee401kCompanyContrib.companyContribution');
			var isFixed = this.get('employee401kCompanyContrib.isFixedContribution');
			var employeeContributionPerMonth = this.get('employeeContributionPerMonth');
			var employeeRothContributionPerMonth = this.get('employeeRothContributionPerMonth');
			var companyFixedMatchingMaxContribution = this.get('companyFixedMatchingMaxContribution');
			var salary = Number(this.get('employee.monthlySalary'));
			var companyContributionAnnualMaximum = this.get('companyContributionAnnualMaximum');
			var employerActualContributionYTD = this.get('employerActualContributionYTD');
			var nonElectivePercentageContribution = this.get('employee401kCompanyContrib.nonElectivePercentageContribution');
			var percentageEmployeeContributionMatch = this.get('employee401kCompanyContrib.percentageEmployeeContributionMatch');
			var firstTierPercentageContribution = this.get('employee401kCompanyContrib.firstTierPercentageContribution');
			var firstTierThreshold = this.get('employee401kCompanyContrib.firstTierThreshold');
			var secondTierPercentageContribution = this.get('employee401kCompanyContrib.secondTierPercentageContribution');
			var secondTier = this.get('employee401kCompanyContrib.secondTier');

			return this.calculateActualCompanyContribution(companyContribution, isFixed, employeeContributionPerMonth, employeeRothContributionPerMonth,
				companyFixedMatchingMaxContribution, salary, companyContributionAnnualMaximum, employerActualContributionYTD,
				nonElectivePercentageContribution, percentageEmployeeContributionMatch, firstTierPercentageContribution,
				firstTierThreshold, secondTierPercentageContribution, secondTier);
		}.property('employee.monthlySalary', 'employee401kCompanyContrib.companyContribution',
			'employee401kCompanyContrib.isFixedContribution', 'employeeContributionPerMonth', 'employeeRothContributionPerMonth',
			'companyContributionAnnualMaximum', 'employee401kCompanyContrib.nonElectivePercentageContribution',
			'employee401kCompanyContrib.firstTierPercentageContribution', 'employee401kCompanyContrib.nonElectivePercentageContribution',
			'employee401kCompanyContrib.percentageEmployeeContributionMatch', 'employerActualContributionYTD',
			'employee401kCompanyContrib.firstTierThreshold', 'employee401kCompanyContrib.secondTierPercentageContribution',
			'employee401kCompanyContrib.secondTier', 'companyFixedMatchingMaxContribution'),
		companyDollarContributionPerPayPeriod: function () {
			var companyDollarContributionPerMonth = this.get('actualCompanyContribution');
			var companyDollarContributionPerYear = parseFloat(companyDollarContributionPerMonth) * 12;
			var amount = companyDollarContributionPerYear / this.get('payPeriodDivisor');
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}
			return 0;
		}.property('actualCompanyContribution', 'payPeriodDivisor'),
		employeeContributionPerPayPeriod: function () {
			var amount = this.get('employeeContributionPerMonth') * 12 / this.get('payPeriodDivisor');
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}
			return 0;
		}.property('employeeContributionPerMonth', 'payPeriodDivisor'),

		employeeRothContributionPerPayPeriod: function () {
			var amount = this.get('employeeRothContributionPerMonth') * 12 / this.get('payPeriodDivisor');
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}
			return 0;
		}.property('employeeRothContributionPerMonth', 'payPeriodDivisor'),
		nonRothContributionPresent: function () {
			return (this.get('contribution') != null && this.get('contribution') > 0) || (this.get('fixedContribution') != null && this.get('fixedContribution') > 0);
		}.property('contribution', 'fixedContribution'),
		rothContributionPresent: function () {
			return (this.get('rothPercentageContribution') != null && this.get('rothPercentageContribution') > 0) || (this.get('rothFixedContribution') != null && this.get('rothFixedContribution') > 0);
		}.property('rothPercentageContribution', 'rothFixedContribution'),
		employeeTotalYTDContribution: function () {
			var employeeActualContributionYTD = this.get('employeeActualContributionYTD');
			if (!employeeActualContributionYTD) {
				employeeActualContributionYTD = 0;
			}
			var employeeActualRothContributionYTD = this.get('employeeActualRothContributionYTD');
			if (!employeeActualRothContributionYTD) {
				employeeActualRothContributionYTD = 0;
			}
			var total = (employeeActualContributionYTD + employeeActualRothContributionYTD);
			return parseFloat(total.toFixed(2));
		}.property('employeeActualContributionYTD', 'employeeActualRothContributionYTD'),
		employerActualContributionYTD: attr('number'),
		status: attr('string'),
		statusDisplayName: attr('string'),
		startDate: attr('string'),
		formattedStartDate: attr('string'),
		effectiveDate: attr('string'),
		computedStartDate: attr('string'),
		computedEffectiveDate: attr('string'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		companyContributionAnnualMaximum: attr('number'),
		isFillingOut: function () {
			return this.get('status') == 'filling-out';
		}.property('status'),
		isSetupComplete: function () {
			return this.get('status') == 'complete';
		}.property('status'),
		isPastEffectiveDate: function () {
			var today = new Date();
			var effectiveDate = new Date(this.get('effectiveDate'));
			return today.getTime() > effectiveDate.getTime();
		}.property('effectiveDate'),
		maxAnnualContribution: attr('number'),
		maxAnnualContributionDisplay: function () {
			var maxAnnualContribution = this.get('maxAnnualContribution');
			if (maxAnnualContribution == 24500) {
				return "$24,500 since you are more than 50 years of age";
			}
			return "$18,500 since you are less than 50 years of age";
		}.property('maxAnnualContribution'),
		formattedMaxAnnualContribution: function () {
			var maxAnnualContribution = this.get('maxAnnualContribution');
			if (maxAnnualContribution == 24500) {
				return "24,500";
			}
			return "18,500";
		}.property('maxAnnualContribution'),
		contributionAboveAllowedLimit: function () {
			return "Your proposed contribution is above the max amount of salary we calculated that you have left after insurance and benefits deductions. And you'll still have to pay Medicare and Social Security taxes as well! <br/>  Please reduce your contribution.";
		}.property()
	});

	App.Employee401kBeneficiary = DS.Model.extend({
		employee401kEnrollment: DS.belongsTo('App.Employee401kEnrollment'),
		firstName: attr('string'),
		lastName: attr('string'),
		relationship: attr('string'),
		dob: attr('string'),
		socialSecurity: attr('string'),
		socialSecurityEnc: attr('string'),
		email: attr('string'),
		fullName: function () {
			return this.get('firstName') + " " + this.get('lastName');
		}.property('firstName', 'lastName')
	});

	App.Employee401kPeriodicContribution = DS.Model.extend({
		employee401kEnrollment: DS.belongsTo('App.Employee401kEnrollment'),
		fromDate: attr('string'),
		toDate: attr('string'),
		employeeContribution: attr('number'),
		employeeRothContribution: attr('number'),
		employerContribution: attr('number'),
		reason: attr('string'),
		computationKeyValueJson: attr('string')
	});

	App.AuthorizedSigner = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		socialSecurity: attr('string'),
		socialSecurityEnc: attr('string'),
		dob: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		address: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		phone: attr('string'),
		isActive: attr('boolean'),
		fullName: function () {
			return (this.get('firstName') || '') + " " + (this.get('lastName') || '');
		}.property('firstName', 'lastName')
	});

	App.EmployeeCustomDocument = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		name: attr('string'),
		url: attr('string'),
		hasSensitiveInformation: attr('boolean'),
	});


	App.TerminationBenefits = DS.Model.extend({
		isCobraEligibleOnDate: attr('boolean'),
		isEnrolledInBenefits: attr('boolean'),
		cobraIneligibilityReason: attr('string'),
		isEligibleForBenefitsTermination: attr('boolean')
	});


	App.EmployeeCurrentCoverage = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		medicalStatus: attr('string'),
		dentalStatus: attr('string'),
		visionStatus: attr('string'),
		medicalPlan: DS.belongsTo('App.EPlan'),
		dentalPlan: DS.belongsTo('App.EDentalPlan'),
		visionPlan: DS.belongsTo('App.EVisionPlan'),
		getStatus: function (loc) {
			return this.get(loc.toLowerCase() + 'Status');
		},
		setStatus: function (loc, status) {
			return this.set(loc.toLowerCase() + 'Status', status);
		},
		getPlan: function (loc) {
			return this.get(loc.toLowerCase() + 'Plan');
		},
		setPlan: function (loc, plan) {
			return this.set(loc.toLowerCase() + 'Plan', plan);
		},
	});


	App.EmployeeTerminationAction = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		employment: DS.belongsTo('App.EmployeeEmployment'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		employment_id: attr('number'),
		employeeID: attr('number'),
		removeFromPayroll: attr('raw'),
		removeFromBenefits: attr('raw'),
		askedBenefitsTerminationQuestion: attr('boolean'),
		removeFromIT: attr('raw'),
		deactivateEmailIfRemoving: attr('boolean'),
		lastMonthOfBenefits: attr('string'),
		benefitsEndDate: attr('string'),
		issueFinalPaycheck: attr('raw'),
		status: attr('string'),
		administerCOBRA: attr('raw'),
		coverFullCobra: attr('raw'),
		cobraMonths: attr('string'),
		terminationType: attr('string'),
		terminationCategory: attr('string'),
		terminationDate: attr('string'),
		reason: attr('string'),
		terminatedOn: attr('string'),
		terminationReason: attr('string'),
		payrollTerminationReason: DS.belongsTo('App.WfTerminationReason'),
		address: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		email: attr('string'),
		socialSecurity: attr('string'),
		dob: attr('string'),
		reportsNewManager: DS.belongsTo('App.AllEmployee', { inverse: null }),
		customReport: DS.hasMany('App.CustomReport'),
		unusedPTOHours: attr('string'),
		unusedPTOAmount: attr('string'),
		uniqueId: attr('string'),
		eligibleForRehire: attr('boolean'),
		cancelAutoPay: attr('boolean'),
		fromConsole: attr('raw'),
		isCanceledInEditing: attr('boolean'),
		isRequestedInEditing: attr('boolean'),
		payrollAction: DS.belongsTo('App.PayrollAction'),
		termFlowVersion: attr('number'),
		isCancelable: attr('boolean'),
		isEditable: attr('boolean'),
		isCanceled: Ember.computed.equal('status', 'canceled'),
		isActive: Ember.computed.not('isCanceled'),
		isVoluntary: Ember.computed.equal('terminationClassification', 'VY'),
		isInvoluntary: Ember.computed.equal('terminationClassification', 'IY'),
		isUnclassified: Ember.computed.equal('terminationClassification', 'UN'),
		pluralMonths: function () {
			var cobraMonths = this.get('cobraMonths');
			if (cobraMonths && cobraMonths > 1) {
				return "s";
			}
			return "";
		}.property('cobraMonths'),
		terminationTypeHelper: function () {
			const isCPCompany = this.get('employee.company.isContractorPaymentsCompany');
			return isCPCompany ? {
				'IN': 'Involuntary',
				'IR': 'Involuntary, Can Contract',
				'IO': 'Involuntary, No Contract',
				'VR': 'Voluntary, Can Contract',
				'VN': 'Voluntary, No Contract',
				'UN': 'Unclassified',
				'NS': 'Never Started',
			}[this.get('terminationType')] || 'N/A' :
				{
					'IN': 'Involuntary',
					'IR': 'Involuntary, Can Rehire',
					'IO': 'Involuntary, No Rehire',
					'VR': 'Voluntary, Can Rehire',
					'VN': 'Voluntary, No Rehire',
					'UN': 'Unclassified',
					'NS': 'Never Started',
				}[this.get('terminationType')] || 'N/A';
		}.property('terminationType', 'employee.company.isContractorPaymentsCompany'),
		terminationCategoryHelper: function () {
			return {
				'NS': 'Never Started',
				'RT': 'Regular Termination',
			}[this.get('terminationCategory')] || 'N/A';
		}.property('terminationCategory'),
		statusHelper: function () {
			if (this.get('status') == 'requested') {
				return 'Termination Requested';
			}
			if (this.get('status') == 'approved') {
				return 'Pending Termination';
			}
			if (this.get('status') == 'processed') {
				return 'Termination Details';
			}
		}.property('status'),
		availableTerminationReason: function () {
			if (this.get('terminationAction.payrollTerminationReason')) {
				if (this.get('terminationAction.payrollTerminationReason.payrollProvider') === 'WF') {
					return this.get('terminationAction.payrollTerminationReason.codeAndDescription');
				} else {
					return this.get('terminationAction.payrollTerminationReason.description');
				}
			} else {
				return this.get('terminationAction.reason');
			}
		}.property('terminationAction.payrollTerminationReason.payrollProvider',
			'terminationAction.payrollTerminationReason.codeAndDescription',
			'terminationAction.payrollTerminationReason.description',
			'terminationAction.reason'),
	});


	App.QualifyingEvent = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		dependent: DS.belongsTo('App.Dependent'),
		type: attr('string'),
		typeDisplay: attr('string'),
		subtype: attr('string'),
		subtypeDisplay: attr('string'),
		eventDate: attr('string'),
		knowledgeTime: attr('date'),
		proofURL: attr('string'),
		proofUpdateTimestamp: attr('date'),
		proofType: attr('string'),
		proofDocumentTypes: attr('raw'),
		reason: attr('string'),
		documentStatus: attr('string'),
		documentReviewDetail: attr('string'),
		enrollments: DS.hasMany('App.EmployeeHealthEnrollment'),
		dependentHealthEnrollments: DS.hasMany('App.DependentHealthEnrollment'),

		// Computed properties
		//   documents
		requiresProofDocument: Ember.computed.bool('proofDocumentTypes.length'),
		missingDocument: function () {
			var documentStatus = this.get('documentStatus');
			return documentStatus == 'M' || documentStatus == 'R';
		}.property('documentStatus'),
		missingProofURL: function () {
			return this.get('requiresProofDocument') && !this.get('proofURL');
		}.property('requiresProofDocument', 'proofURL'),
		documentDeadline: function () {
			// used in ZApps, update server-side fn if you change this!
			var requiresProofDocument = this.get('requiresProofDocument');
			var eventDate = this.get('eventDateAsDate');
			if (requiresProofDocument && eventDate) {
				return moment(eventDate).add(28, 'days');
			}
			return null;
		}.property('requiresProofDocument', 'eventDateAsDate'),
		//   event date
		eventDateAsDate: function () {
			return zen.americanDateAsDate(this.get('eventDate'));
		}.property('eventDate'),
		//   enrollments
		medicalEnrollment: function () {
			return this.get('enrollments').findBy('coverage_type', 'medical');
		}.property('enrollments.@each.coverage_type'),
		dentalEnrollment: function () {
			return this.get('enrollments').findBy('coverage_type', 'dental');
		}.property('enrollments.@each.coverage_type'),
		visionEnrollment: function () {
			return this.get('enrollments').findBy('coverage_type', 'vision');
		}.property('enrollments.@each.coverage_type'),
		isAnyEnrollmentComplete: function () {
			// used in ZApps, update server-side fn if you change this!
			return this.get('enrollments').isAny('isComplete', true);
		}.property('enrollments.@each.isComplete'),
		areEnrollmentsActive: function () {
			return this.get('enrollments').isEvery('isActive', true);
		}.property('enrollments.@each.isActive'),
		allowEditReviewPlans: function () {
			return this.get('enrollments').isEvery('allowEditReviewPlans', true);
		}.property('enrollments.@each.allowEditReviewPlans'),

		//   DEPRECATED PROPERTIES
		isProofPending: function () {
			// DEPRECATED
			// used in ZApps, update server-side fn if you change this!
			// QLE is proof pending iff proofURL is missing, there is complete enrollment and document deadline is not more than 28 days ago.
			var documentDeadline = this.get('documentDeadline');
			return !this.get('proofURL') && this.get('isAnyEnrollmentComplete') && documentDeadline && moment(documentDeadline).add(28, 'days') > moment();
		}.property('proofURL', 'isAnyEnrollmentComplete', 'documentDeadline'),
	});


	App.DependentHealthEnrollment = DS.Model.extend({
		dependent: DS.belongsTo('App.Dependent', { inverse: 'dependentHealthEnrollments' }),
		type: attr('string'),
		lineOfCoverage: attr('string'),
		enrollmentStatus: attr('string'),
		isActive: attr('boolean'),
		isEnrollmentComplete: attr('boolean'),
		qualifyingEvent: DS.belongsTo('App.QualifyingEvent'),
		employeeHealthEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		effectiveStartDate: attr('string'),
		endDate: attr('string'),
		// the following signature related field are depreated
		authName: attr('string'),
		authTitle: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		// use the signature object instead
		signature: DS.belongsTo('App.Signature'),
		typeText: function () {
			if (this.get('type') == '+') {
				return "Add";
			}
			if (this.get('type') == '-') {
				return "Remove";
			}
			return null;
		}.property('type'),
		effectiveDate: function () {
			if (this.get('type') == '+') {
				return this.get('effectiveStartDate');
			}
			if (this.get('type') == '-') {
				return this.get('endDate');
			}
		}.property('type', 'effectiveStartDate', 'endDate'),
	});

	App.EmployeeHealthEnrollment = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		version_id: attr('number'),
		companyEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		qualifyingEvent: DS.belongsTo('App.QualifyingEvent'),
		type: attr('string'),
		enrollmentType: attr('string'),
		granularEnrollmentType: attr('string'),
		coverage_type: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		applicationStatus: attr('string'),
		isApplicationSubmitted: attr('boolean'),
		status: attr('string'),
		companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		medicalPlan: DS.belongsTo('App.Plan'),
		dentalPlan: DS.belongsTo('App.DentalPlan'),
		visionPlan: DS.belongsTo('App.VisionPlan'),
		oldCompanyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		oldPlan: attr('number'),
		defaultPlan: attr('number'),
		dependents: DS.hasMany('App.EnrollmentDependent'),
		employeeEnrollmentAudit: DS.belongsTo('App.EmployeeHealthEnrollmentAudit'),
		addedDependents: DS.hasMany('App.Dependent', { inverse: null }),
		removedDependents: DS.hasMany('App.Dependent', { inverse: null }),
		dependentHealthEnrollments: DS.hasMany('App.DependentHealthEnrollment'),
		dependentCoverageChanged: attr('boolean'),
		employeeEheAgreement: DS.belongsTo('App.AgreementEhe'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		currentPlanOffered: attr('boolean'),
		currentDentalPlanOffered: attr('boolean'),
		currentVisionPlanOffered: attr('boolean'),
		isEnrolledPlanHraCompatible: attr('boolean'),
		isOffCycleEnrollment: attr('boolean'),
		disableDependentCoverageChanges: attr('boolean'),
		medicalCarrierStatus: attr('string'),
		dentalCarrierStatus: attr('string'),
		visionCarrierStatus: attr('string'),
		companyMedicalEnrollmentCompleteDate: attr('string'),
		companyDentalEnrollmentCompleteDate: attr('string'),
		companyVisionEnrollmentCompleteDate: attr('string'),
		enrollmentCompleteDate: attr('string'),
		effectiveDate: attr('string'),
		effectiveDateFormatted: function () {
			return moment(this.get('effectiveDate'), 'MM/DD/YYYY').format('MMM Do');
		}.property('effectiveDate'),
		previousCoverageExpirationDate: attr('string'),
		companyEffectiveDate: attr('string'),
		isPastEnrollmentDeadline: attr('boolean'),
		daysUntilDeadline: attr('number'),
		hasSignedWaiver: attr('boolean'),
		premiumsMap: attr('string'),
		// the following signature related field are depreated
		name: attr('string'),
		title: attr('string'),
		signature: attr('string'),
		date: attr('string'),
		// use the signature object instead
		authSignature: DS.belongsTo('App.Signature'),
		isActive: attr('boolean'),
		isSubmitted: attr('boolean'),
		submittedDate: attr('string'),
		isOpenEnrollment: attr('boolean'),
		isInitialEnrollment: attr('boolean'),
		isSwitchCarrierEnrollment: attr('boolean'),
		isEnrollmentException: attr('boolean'),
		isNewHireOEOrSW: attr('boolean'),
		healthRiders: attr('raw'),
		isCardBlocked: attr('boolean'),
		isEnrollmentOngoing: attr('boolean'),
		progress: attr('json'),
		includedInGroupApp: attr('boolean'),
		// only valid when it is completed
		initialNewGroupEnrollment: Ember.computed.and('isInitialEnrollment', 'includedInGroupApp'),
		newGroupEnrollment: Ember.computed.or('initialNewGroupEnrollment', 'isSwitchCarrierEnrollment'),
		isOpenEnrollmentOrSwitchCarrier: Ember.computed.or('isOpenEnrollment', 'isSwitchCarrierEnrollment'),
		isNewMedical: function () {
			return ((this.get('type') == 'new_group' || (this.get('type') == 'initial_enrollment') || this.get('type') == 'switch_carrier') && this.get('coverage_type') == 'medical');
		}.property('type', 'coverage_type'),
		isNewDental: function () {
			return ((this.get('type') == 'new_group' || (this.get('type') == 'initial_enrollment') || this.get('type') == 'switch_carrier') && this.get('coverage_type') == 'dental');
		}.property('type', 'coverage_type'),
		isNewVision: function () {
			return ((this.get('type') == 'new_group' || (this.get('type') == 'initial_enrollment') || this.get('type') == 'switch_carrier') && this.get('coverage_type') == 'vision');
		}.property('type', 'coverage_type'),
		medicalOE: function () {
			return (this.get('type') == 'open_enrollment' && this.get('coverage_type') == 'medical');
		}.property('type', 'coverage_type'),
		dentalOE: function () {
			return (this.get('type') == 'open_enrollment' && this.get('coverage_type') == 'dental');
		}.property('type', 'coverage_type'),
		anyInProcess: function () {
			return (this.get('medicalPlan') || this.get('dentalPlan') || this.get('visionPlan') || this.get('dependentCoverageChanged')) && this.get('status') != 'complete';
		}.property('status', 'medicalPlan', 'dentalPlan', 'visionPlan', 'dependentCoverageChanged'),
		inProcess: function () {
			return (this.get('medicalPlan') || this.get('dentalPlan') || this.get('visionPlan'));
		}.property('status', 'medicalPlan', 'dentalPlan', 'visionPlan'),
		medicalInProcess: function () {
			return (this.get('medicalPlan') || this.get('dependentCoverageChanged')) && this.get('status') != 'complete';
		}.property('status', 'medicalPlan', 'dependentCoverageChanged'),
		dentalOrVisionInProcess: function () {
			return (this.get('dentalPlan') || this.get('visionPlan') || this.get('dependentCoverageChanged')) && this.get('status') != 'complete';
		}.property('status', 'dentalPlan'),
		dentalInProcess: function () {
			return (this.get('dentalPlan') || this.get('dependentCoverageChanged')) && this.get('status') != 'complete';
		}.property('status', 'dentalPlan', 'dependentCoverageChanged'),
		visionInProcess: function () {
			return (this.get('visionPlan') || this.get('dependentCoverageChanged')) && this.get('status') != 'complete';
		}.property('status', 'visionPlan', 'dependentCoverageChanged'),
		isRenewalEnrollment: function () {
			return this.get('type') == 'open_enrollment' || this.get('type') == 'switch_carrier';
		}.property('type'),
		hasBegun: Ember.computed.equal('status', 'begin'),
		hasReviewed: Ember.computed.equal('status', 'reviewed'),
		isCompanyApproved: function () {
			// used in ZApps, update server-side fn if you change this!

			if (this.get('coverage_type') == 'medical' && this.get('medicalPlan')) {
				return this.get('medicalCarrierStatus') == 'approved';
			}
			else if (this.get('coverage_type') == 'dental' && this.get('dentalPlan')) {
				return this.get('dentalCarrierStatus') == 'approved';
			}
			else if (this.get('coverage_type') == 'vision' && this.get('visionPlan')) {
				return this.get('visionCarrierStatus') == 'approved';
			}

			return false;
		}.property('coverage_type', 'medicalPlan', 'medicalCarrierStatus', 'dentalPlan', 'dentalCarrierStatus', 'visionPlan', 'visionCarrierStatus'),
		isCompanyEnrollmentComplete: function () {
			// used in ZApps, update server-side fn if you change this!

			if (this.get('coverage_type') == 'medical' && this.get('medicalPlan')) {
				return (this.get('medicalCarrierStatus') == 'submitted' || this.get('medicalCarrierStatus') == 'complete' || this.get('medicalCarrierStatus') == 'document');
			}
			else if (this.get('coverage_type') == 'dental' && this.get('dentalPlan')) {
				return (this.get('dentalCarrierStatus') == 'submitted' || this.get('dentalCarrierStatus') == 'complete' || this.get('dentalCarrierStatus') == 'document');
			}
			else if (this.get('coverage_type') == 'vision' && this.get('visionPlan')) {
				return (this.get('visionCarrierStatus') == 'submitted' || this.get('visionCarrierStatus') == 'complete' || this.get('visionCarrierStatus') == 'document');
			}

			return false;
		}.property('isOpenEnrollment', 'isSwitchCarrierEnrollment', 'status', 'coverage_type', 'medicalPlan', 'medicalCarrierStatus', 'dentalPlan', 'dentalCarrierStatus', 'visionPlan', 'visionCarrierStatus'),
		companyPreviousCoverageExpirationDate: function () {
			var companyEffectiveDate = this.get('companyEffectiveDate');
			if (companyEffectiveDate) {
				return moment(companyEffectiveDate).subtract(1, 'd').format('L');
			}

			return null;
		}.property('companyEffectiveDate'),
		isEnrollmentStatusComplete: function () {
			// used in ZApps, update server-side fn if you change this!

			if (this.get('coverage_type') == 'medical' && this.get('medicalPlan')) {
				return this.get('status') == 'complete';
			}
			else if (this.get('coverage_type') == 'dental' && this.get('dentalPlan')) {
				return this.get('status') == 'complete';
			}
			else if (this.get('coverage_type') == 'vision' && this.get('visionPlan')) {
				return this.get('status') == 'complete';
			}
			return false;
		}.property('status', 'medicalPlan', 'visionPlan', 'dentalPlan'),
		enrollmentTypeDisplayName: Ember.computed(function () {
			return enrollmentTypePrettyMap[this.get('enrollmentType')];
		}).property('enrollmentType'),
		isComplete: Ember.computed.equal('status', 'complete'),
		needDocument: attr('boolean'),
		isCompleteOrReviewed: Ember.computed.or('isComplete', 'hasReviewed'),
		hasDeclined: Ember.computed.equal('status', 'decline'),
		hasDeclinedAndSignedWaiver: Ember.computed.and('hasDeclined', 'hasSignedWaiver'),
		isEndState: function () {
			// used in ZApps, update server-side fn if you change this!
			return (this.get('isComplete') ||
				(this.get('hasDeclined') && this.get('hasSignedWaiver')) ||
				this.get('hasReviewed') ||
				this.get('isPastEnrollmentDeadline'));
		}.property('isComplete', 'hasDeclined', 'hasSignedWaiver', 'hasReviewed', 'isPastEnrollmentDeadline'),
		completeDate: function () {
			if (this.get('coverage_type') == 'medical' && this.get('medicalPlan')) {
				return this.get('companyMedicalEnrollmentCompleteDate');
			}
			else if (this.get('coverage_type') == 'dental' && this.get('dentalPlan')) {
				return this.get('companyDentalEnrollmentCompleteDate');
			}
			else if (this.get('coverage_type') == 'vision' && this.get('visionPlan')) {
				return this.get('companyVisionEnrollmentCompleteDate');
			}

			return null;
		}.property('coverage_type', 'medicalPlan', 'dentalPlan', 'visionPlan',
			'companyMedicalEnrollmentCompleteDate', 'companyDentalEnrollmentCompleteDate', 'companyVisionEnrollmentCompleteDate'),
		inBoRIntermediateState: function () {
			if (this.get('coverage_type') == 'medical' && this.get('medicalPlan')) {
				return this.get('medicalCarrierStatus') == 'switched';
			}
			else if (this.get('coverage_type') == 'dental' && this.get('dentalPlan')) {
				return this.get('dentalCarrierStatus') == 'switched';
			}
			else if (this.get('coverage_type') == 'vision' && this.get('visionPlan')) {
				return this.get('visionCarrierStatus') == 'switched';
			}

			return false;
		}.property('coverage_type', 'medicalPlan', 'medicalCarrierStatus', 'dentalPlan', 'dentalCarrierStatus', 'visionPlan', 'visionCarrierStatus'),
		allowEditReviewPlans: Ember.computed.alias('isEnrollmentOngoing'),
		renewalMonth: function () {
			var effectiveDate = new Date(this.get('effectiveDate'));
			var months = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"];

			return months[effectiveDate.getMonth()];
		}.property('effectiveDate'),
		isLastDayToEnroll: function () {
			return (this.get('daysUntilDeadline') == 0);
		}.property('daysUntilDeadline'),
		isCurrentlyInWaitingPeriod: function () {
			var effectiveDate = this.get('effectiveDate');

			return effectiveDate && moment(effectiveDate, 'MM/DD/YYYY') > moment();
		}.property('effectiveDate'),
		coverageTypeString: function () {
			var coverage_type = this.get('coverage_type');
			if (!coverage_type) {
				return 'Dental';
			}

			return coverage_type.replace(/\w+/g, function (str) { return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase(); });
		}.property('coverage_type'),
		detailsString: function () {
			return "details";
		}.property(),
		buttonsString: function () {
			return "buttons";
		}.property(),
		isCobraEnrollee: function () {
			return true;
		}.property(),

		enrollmentState: function () {
			// used for ZApps, duplicated in a server side function of the same name
			// if modified, you *MUST* ensure the duplicated logic matches

			// this function is for determining card combination
			// 3 states: not_start, in_progress, finished
			// (because the card info is different for different endingState, return status for ending_state)
			var status = this.get('status') || '';
			var hasSignedWaiver = this.get('hasSignedWaiver');
			if (status == 'begin' || status == 'start' || (status == 'decline' && !hasSignedWaiver)) {
				return 'in_progress';
			}
			if (status == '') {
				return 'not_start';
			}
			return status;
		}.property('status', 'hasSignedWaiver'),

		hasDocUploadReminder: Ember.computed.and("isComplete", "needDocument"),

		// Application Status
		isApplicationNoNeedToSend: Ember.computed.equal('applicationStatus', 'no_need_to_send'),
		isApplicationYetToBeSent: Ember.computed.equal('applicationStatus', 'waiting_to_send'),
		isApplicationSentManual: Ember.computed.equal('applicationStatus', 'sent_to_carrier_manual'),
		isApplicationSentViaEdi: Ember.computed.equal('applicationStatus', 'sent_via_edi'),
		isApplicationSentViaStp: Ember.computed.equal('applicationStatus', 'sent_via_stp'),
		isApplicationSentElectronic: Ember.computed.or('isApplicationSentViaEdi', 'isApplicationSentViaStp'),
		isApplicationSent: Ember.computed.or('isApplicationSentManual', 'isApplicationSentElectronic'),

		isOEApplicationNoNeedToSend: Ember.computed.and('isOpenEnrollment', 'isApplicationNoNeedToSend'),
		isOEApplicationYetToBeSent: Ember.computed.and('isOpenEnrollment', 'isApplicationYetToBeSent'),
		isOEApplicationSent: Ember.computed.and('isOpenEnrollment', 'isApplicationSent'),
		isOEDeclinedAndSignedWaiver: Ember.computed.and('isOpenEnrollment', 'hasDeclinedAndSignedWaiver'),

		relevantPlan: Ember.computed(function () {
			var lineOfCoverage = this.get('coverage_type');

			if (lineOfCoverage == 'medical') {
				return this.get('medicalPlan');
			} else if (lineOfCoverage == 'dental') {
				return this.get('dentalPlan');
			} else if (lineOfCoverage == 'vision') {
				return this.get('visionPlan');
			}
		}).property('coverage_type', 'medicalPlan', 'dentalPlan', 'visionPlan'),
	});

	App.EmployeeHealthEnrollmentStatus = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		coverageType: attr('string'),
		applicationSubmitDate: attr('string'),
		applicationSentDate: attr('string'),
	});

	App.EnrollmentDependent = DS.Model.extend({
		enrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		dependent: DS.belongsTo('App.Dependent'),
		firstName: attr('string'),
		lastName: attr('string'),
		type: attr('string'),
		enrollInMedical: attr('boolean'),
		enrollInDental: attr('boolean'),
		enrollInVision: attr('boolean'),
		isSelf: Ember.computed.equal('type', 'self'),
	});

	App.Network = DS.Model.extend({
		name: attr('string'),
		carriers: DS.hasMany('App.Carrier', { inverse: null }),
		medicalPlans: DS.hasMany('App.Plan', { inverse: null }),
		dentalPlans: DS.hasMany('App.DentalPlan', { inverse: null }),
		visionPlans: DS.hasMany('App.VisionPlan', { inverse: null }),
		isNationwide: attr('boolean'),
		isNarrowOverride: attr('boolean'),
		lineOfCoverage: attr('string'),
		isEditing: attr('boolean'),
		isMedical: Ember.computed.equal('lineOfCoverage', 'Medical'),
		isDental: Ember.computed.equal('lineOfCoverage', 'Dental'),
		isVision: Ember.computed.equal('lineOfCoverage', 'Vision'),

		carrierNames: function () {
			return this.get('carriers').getEach('name').join(", ");
		}.property('carriers.@each.name'),

		carrierIdString: function () {
			return this.get('carrierIds').join(", ");
		}.property('carrierIds.@each'),

		carrierIds: function () {
			return this.get('carriers').getEach('id');
		}.property('carriers.@each.id'),

		state: function () {
			return this.get('carriers').getEach('state').join(", ");
		}.property('carriers.@each.state'),
	});

	App.CarrierRiderRule = DS.Model.extend({
		carrier: DS.belongsTo("App.Carrier"),
		riderType: attr('string'),
		rateHandledType: attr('string'),
		companyAdminOptOutAllowed: attr('string'),
		employeeOptOutAllowed: attr('string'),
		ageLimit: attr('number'),
		isApplicableToMedical: attr('boolean'),
		isApplicableToDental: attr('boolean'),
		isApplicableToVision: attr('boolean'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		isActive: attr('boolean'),

		lineOfCoverages: function () {
			var result = [];
			if (this.get('isApplicableToMedical')) {
				result.push('medical');
			}
			if (this.get('isApplicableToDental')) {
				result.push('dental');
			}
			if (this.get('isApplicableToVision')) {
				result.push('vision');
			}
			return result.join('/');
		}.property('isApplicableToMedical', 'isApplicableToDental', 'isApplicableToVision'),

		historyUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.CarrierRiderRule";
		}.property('id'),
	});


	App.AccountManager = DS.Model.extend({
		username: attr('string'),
		companies: DS.hasMany('App.Company', { inverse: null }),
		accounts: DS.hasMany('App.Company', { inverse: null }),
		ongoingRenewals: DS.hasMany('App.CompanyHealthCarrier', { inverse: null }),
		upcomingRenewals: DS.hasMany('App.CompanyHealthCarrier', { inverse: null }),
		fullName: attr('string'),
	});

	App.PlanBundle = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		isMedicalBundled: attr('boolean'),
		isDentalBundled: attr('boolean'),
		isVisionBundled: attr('boolean'),
		isLifeBundled: attr('boolean'),
		isADnDBundled: attr('boolean'),
		isSTDBundled: attr('boolean'),
		isLTDBundled: attr('boolean'),

		historyUrl: function () {
			return "/console/object_history/" + this.get('id') + "/implementation.models.PlanBundle";
		}.property('id'),
	});

	App.PlanBundleDependency = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		lineOfCoverage: attr('string'),
		dependOnLineOfCoverage: attr('string'),

		historyUrl: function () {
			return "/console/object_history/" + this.get('id') + "/implementation.models.PlanBundleDependency";
		}.property('id'),
	});

	App.CompanyPlanBundle = DS.Model.extend({
		optOut: attr('boolean'),
		company: DS.belongsTo('App.Company'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		isMedicalBundled: attr('boolean'),
		isDentalBundled: attr('boolean'),
		isVisionBundled: attr('boolean'),
		isLifeBundled: attr('boolean'),
		isADnDBundled: attr('boolean'),
		isSTDBundled: attr('boolean'),
		isLTDBundled: attr('boolean'),

		medicalBundledCarrierId: attr('string'),
		dentalBundledCarrierId: attr('string'),
		visionBundledCarrierId: attr('string'),
		lifeBundledCarrierId: attr('string'),
		ADnDBundledCarrierId: attr('string'),
		STDBundledCarrierId: attr('string'),
		LTDBundledCarrierId: attr('string'),
	});

	App.ActivePlanBundle = DS.Model.extend({
		id: attr('number'),
		isPlanBundle: attr('boolean'),
		isCompanyPlanBundle: attr('boolean'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		isMedicalBundled: attr('boolean'),
		isDentalBundled: attr('boolean'),
		isVisionBundled: attr('boolean'),
		isLifeBundled: attr('boolean'),
		isADnDBundled: attr('boolean'),
		isSTDBundled: attr('boolean'),
		isLTDBundled: attr('boolean'),
	});

	App.Carrier = DS.Model.extend({
		appointmentStatus: attr('string'),
		carrierID: attr('number'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		isTrust: attr('boolean'),
		networks: DS.hasMany('App.Network', { inverse: null }),
		planBundles: DS.hasMany('App.PlanBundle', { inverse: null }),
		carrierYearMetadata: DS.hasMany('App.CarrierYearMetadata'),
		copy: DS.belongsTo('App.CarrierCopy'),
		biCopy: DS.belongsTo('App.BiCarrierCopy'),
		isYourPeopleEntityApptComplete: attr('boolean'),
		agencyId: attr('string'),
		agentId: attr('string'),
		usesLocalBroker: attr('boolean'),
		isActive: attr('boolean'),
		isMedicalCarrier: attr('boolean'),
		isDentalCarrier: attr('boolean'),
		isVisionCarrier: attr('boolean'),
		isLifeCarrier: attr('boolean'),
		isDisabilityCarrier: attr('boolean'),
		isBusinessInsuranceCarrier: attr('boolean'),
		dentalSupported: attr('boolean'),
		visionSupported: attr('boolean'),
		liveQuoteMedical: attr('boolean'),
		liveQuoteDental: attr('boolean'),
		liveQuoteVision: attr('boolean'),
		logoUrl: attr('string'),
		isCA: Ember.computed.equal('state', 'CA'),
		isNY: Ember.computed.equal('state', 'NY'),
		isMA: Ember.computed.equal('state', 'MA'),
		isWA: Ember.computed.equal('state', 'WA'),
		isKaiser: Ember.computed.equal('name', 'Kaiser Permanente'),
		carrierViewUrl: function () {
			return "/console/carrier/" + this.get('id');
		}.property('id'),
		isAutopayEnabled: function () {
			return (this.get('name') == 'Blue Cross' ||
				this.get('name') == 'Blue Shield' ||
				this.get('name') == 'Kaiser Permanente');
		}.property('name'),
		carrierLogoUrl: function () {
			// Defensive check against really really bad URL names to avoid frontend errors.
			if (this.get('logoUrl') && this.get('logoUrl') != 'None') {
				return this.get('logoUrl');
			}
		}.property('logoUrl'),

		// TODO: we need to get carrier ops to upload the logos, then we can
		// just use the real logoURL instead of this hacky thing
		logoUrlWithDefault: function () {
			// use logoUrl if available otheruse use static ones
			var logoUrl = this.get('logoUrl');
			if (!Ember.isEmpty(logoUrl)) { return logoUrl; }

			var name = this.get('name');
			if (Ember.isEmpty(name)) { return ''; }
			name = name.trim().toLowerCase();
			if (name === 'aetna') {
				return "/static/img/carrier-logos_aetna.png";
			} else if (name === 'anthem blue cross') {
				return "/static/img/carrier-logos_bluecross.png";
			} else if (name === 'blue cross') {
				return "/static/img/carrier-logos_bluecross.png";
			} else if (name === 'blue shield') {
				return "/static/img/carrier-logos_blueshield.png";
			} else if (name === 'cigna') {
				return "/static/img/carrier-logos_cigna.png";
			} else if (name === 'healthnet') {
				return "/static/img/carrier-logos_healthnet.png";
			} else if (name === 'kaiser permanente') {
				return "/static/img/carrier-logos_kaiser.png";
			} else if (name === 'kaiser') {
				return "/static/img/carrier-logos_kaiser.png";
			} else if (name === 'united') {
				return "/static/img/carrier-logos_united.png";
			} else if (name === 'calchoice') {
				return "/static/img/carrier-logos_calchoice.png";
			}
		}.property('name', 'logoUrl'),

		policyRelationship: DS.hasMany('App.CarrierPolicyNumberMetadata'),

		policyInvoiceUrls: Ember.computed(function () {
			return this.get('policyRelationship').filterBy('invoiceUrl').mapBy('invoiceUrl');
		}).property('policyRelationship.@each.invoiceUrl'),

		policyNumberPatterns: Ember.computed(function () {
			return this.get('policyRelationship').filterBy('policyNumberPattern').mapBy('policyNumberPattern');
		}).property('policyRelationship.@each.policyNumberPattern'),
		policyNumberSamples: Ember.computed(function () {
			return this.get('policyRelationship').filterBy('policyNumberSample').mapBy('policyNumberSample');
		}).property('policyRelationship.@each.policyNumberSample'),
		commonlyConfusedCarrierList: Ember.computed(function () {
			return this.get('policyRelationship').filterBy('commonlyConfusedCarrier').mapBy('commonlyConfusedCarrier');
		}).property('policyRelationship.@each.commonlyConfusedCarrier'),
	});

	App.CarrierCopy = DS.Model.extend({
		carrierID: attr('number'),
		companyAgreement: attr('string'),
		achAuthorization: attr('string'),
		employeeAgreement: attr('string'),
		lgEmployeeAgreement: attr('string'),
		employeeDeclineWaiver: attr('string'),
		lgEmployeeDeclineWaiver: attr('string'),
		borLetterBodyContent: attr('string'),
		borCompanyLetterHeadContent: attr('string'),
		borCarrierLetterHeadContent: attr('string'),
		borAuthLetterBodyContent: attr('string'),
		borUsesCarrierSpecificTemplate: attr('boolean'),
		borUsesCarrierSpecificLetterHead: attr('boolean'),
		officerStatement: attr('string'),
		hmoMedicalUrl: attr('string'),
		hmoMedicalDisplayText: attr('string'),
		hmoDentalUrl: attr('string'),
		hmoDentalDisplayText: attr('string'),
		website: attr('string'),
		phone: attr('string'),
		onlyPercentPolicy: attr('boolean'),
		claimsAddress: attr('string'),
		ppoClaimsPhone: attr('string'),
		hmoClaimsPhone: attr('string'),
	});

	App.BiCarrierCopy = DS.Model.extend({
		borLetterBodyContent: attr('string'),
		borCompanyLetterHeadContent: attr('string'),
		borAuthLetterBodyContent: attr('string')

	});

	App.StateCarrier = App.Carrier.extend();

	App.ZWaitingPeriod = DS.Model.extend({
		name: attr('string'),
		displayName: attr('string'),
		days: attr('number'),
		months: attr('number'),
		years: attr('number'),
		day: attr('number'),
		isSameMonth: attr('boolean'),
	});

	App.ZCarrierWaitingPeriod = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		zenefitsWaitingPeriod: DS.belongsTo('App.ZWaitingPeriod'),
		carrierWaitingPeriodName: attr('string'),
		isAllowedForLimitedCompanies: attr('boolean'),
		allowedCompanyIds: attr('string'),
		isWaitingPeriodMappingVerified: attr('boolean'),
	});

	App.PediatricDentalRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		typeOfCoverage: attr('string'),
		inNetworkCopayment: attr('number'),
		outOfNetworkCopayment: attr('number'),
		inNetworkCoInsurance: attr('number'),
		outOfNetworkCoInsurance: attr('number'),
		individualOutOfPocketMax: attr('number'),
		familyOutOfPocketMax: attr('number'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.PediatricDentalRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=pediatricdentalrider";
		}.property('id'),

		riderTypeToNameMap: {
			'pediatricdentalrider': 'Pediatric Dental Rider',
			'orthodontiarider': 'Orthodontia Rider',
			'chiropracticrider': 'Chiropractic Rider',
			'acupuncturerider': 'Acupuncture Rider',
			'prescriptiondrugcoveragerider': 'Prescription Drug Coverage Rider',
		},

		displayRiderType: function () {
			return this.get('riderTypeToNameMap')[this.get('riderType')];
		}.property('riderType'),
	});

	App.CommonRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),
		liveQuote: attr('boolean'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		typeOfCoverage: attr('string'),
		inNetworkCopayment: attr('number'),
		outOfNetworkCopayment: attr('number'),
		inNetworkCoInsurance: attr('number'),
		outOfNetworkCoInsurance: attr('number'),
		individualOutOfPocketMax: attr('number'),
		familyOutOfPocketMax: attr('number'),
		maxVisitsPerYear: attr('number'),
		lifeTimeMax: attr('number'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.CommonRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=commonrider";
		}.property('id'),

		planConsolePageUrl: function () {
			return "/console/implementation/#/rider/" + this.get('id');
		}.property('id'),

		riderTypeToNameMap: {
			'pediatricdentalrider': 'Pediatric Dental Rider',
			'orthodontiarider': 'Orthodontia Rider',
			'chiropracticrider': 'Chiropractic Rider',
			'acupuncturerider': 'Acupuncture Rider',
			'prescriptiondrugcoveragerider': 'Prescription Drug Coverage Rider',
			'chirooracurider': 'Chiro/Acu Rider',
			'infertilitybenefitsrider': 'Infertility Benefits Rider',
			'extendeddependentcoveragerider': 'Extended Dependent Coverage Rider',
			'acafeerider': 'ACA Fee Rider'
		},

		displayRiderType: function () {
			return this.get('riderTypeToNameMap')[this.get('riderType')];
		}.property('riderType'),
	});

	App.OrthodontiaRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		typeOfCoverage: attr('string'),
		copayment: attr('number'),
		lifeTimeMax: attr('number'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.OrthodontiaRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=orthodontiarider";
		}.property('id'),
	});

	App.ChiropracticRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		typeOfCoverage: attr('string'),
		copayment: attr('number'),
		lifeTimeMax: attr('number'),
		maxVisitsPerYear: attr('number'),
		includesAcupuncture: attr('boolean'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.ChiropracticRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=chiropracticrider";
		}.property('id'),
	});

	App.AcupunctureRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		typeOfCoverage: attr('string'),
		copayment: attr('number'),
		lifeTimeMax: attr('number'),
		maxVisitsPerYear: attr('number'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.AcupunctureRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=acupuncturerider";
		}.property('id'),
	});

	App.PrescriptionDrugCoverageRider = DS.Model.extend({
		riderType: attr('string'),
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
		minLives: attr('number'),
		maxLives: attr('number'),
		expiryDate: attr('string'),
		rateStyle: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),

		group: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		productCode: attr('string'),
		network: attr('string'),
		networkUrl: attr('string'),
		planUrl: attr('string'),
		applyRaf: attr('boolean'),

		deductible: attr('number'),
		annualMax: attr('number'),
		inNetworkCopymentTier1: attr('number'),
		outOfNetworkCopaymentTier1: attr('number'),
		inNetworkCopymentTier2: attr('number'),
		outOfNetworkCopaymentTier2: attr('number'),
		inNetworkCopymentTier3: attr('number'),
		outOfNetworkCopaymentTier3: attr('number'),
		inNetworkCopymentTier4: attr('number'),
		outOfNetworkCopaymentTier4: attr('number'),

		availableGroups: attr('string'),

		realName: function () {
			return this.get('name');
		}.property('name'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.PrescriptionDrugCoverageRider";
		}.property('id'),

		planRateHistoryUrl: function () {
			return "/console/rider_plan_rate_history/" + this.get('id') + "?riderType=prescriptiondrugcoveragerider";
		}.property('id'),
	});

	App.ExclusionaryPlanPackage = DS.Model.extend({
		name: attr('string'),
		carrier: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		number: attr('string'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		notes: attr('string'),
		maxNumOfMedicalPlans: attr('number'),
		maxNumOfDentalPlans: attr('number'),
		maxNumOfVisionPlans: attr('number'),
		maxNumOfLifePlans: attr('number'),
		maxNumOfAdndPlans: attr('number'),
		maxNumOfStdPlans: attr('number'),
		maxNumOfLtdPlans: attr('number'),
		medicalPlanIds: attr('string'),
		dentalPlanIds: attr('string'),
		visionPlanIds: attr('string'),
		lifePlanIds: attr('string'),
		adndPlanIds: attr('string'),
		stdPlanIds: attr('string'),
		ltdPlanIds: attr('string'),

		type: function () {
			return 'Exclusionary Package';
		}.property(),

		historyUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.ExclusionaryPlanPackage";
		}.property('id'),
	});

	App.BuddyPlanPackage = DS.Model.extend({
		name: attr('string'),
		carrier: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		number: attr('string'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		notes: attr('string'),
		maxNumOfMedicalPlans: attr('number'),
		maxNumOfDentalPlans: attr('number'),
		maxNumOfVisionPlans: attr('number'),
		maxNumOfLifePlans: attr('number'),
		maxNumOfAdndPlans: attr('number'),
		maxNumOfStdPlans: attr('number'),
		maxNumOfLtdPlans: attr('number'),
		medicalPlanIds: attr('string'),
		dentalPlanIds: attr('string'),
		visionPlanIds: attr('string'),
		lifePlanIds: attr('string'),
		adndPlanIds: attr('string'),
		stdPlanIds: attr('string'),
		ltdPlanIds: attr('string'),

		type: function () {
			return 'Buddy Package';
		}.property(),

		historyUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.BuddyPlanPackage";
		}.property('id'),
	});


	var _PlanAbstractModel = DS.Model.extend({
		// RELATIONS
		compositeFactorSet: DS.belongsTo('App.CompositeFactorSet'),
		compositeRuleSet: DS.belongsTo('App.CompositeRuleSet'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		stateCarrier_id: attr('number'),

		// ATTRIBUTES
		carrier: attr('string'),
		consoleUrl: attr('string'),
		largeGroup: attr('boolean'),
		customPlan: attr('boolean'),
		liveQuote: attr('boolean'),
		liveQuoteForRenewal: attr('boolean'),
		displayName: attr('string'),
		displayNote: attr('string'),
		expiryDate: attr('string'),
		group: attr('string'),
		isShortCircuitPlan: attr('boolean'),
		isVoluntary: attr('boolean'),
		lastCommentDate: attr('date'),
		newGroupExpiryDate: attr('string'),
		planUrl: attr('string'),
		postACA: attr('boolean'),
		rateStyle: attr('string'),
		realName: attr('string'),
		shortCircuitPlanType: attr('string'),
		showName: attr('string'),
		state: attr('string'),
		benefitsData: attr('raw'),

		getBenefit: function (field) {
			var benefitsData = this.get('benefitsData');
			return benefitsData && benefitsData[field] ? benefitsData[field] : 'See SBC';
		},

		// PROPERTIES
		isBlueCrossSelected: function () {
			return (
				(/Blue Cross/.test(this.get('carrier')) && this.get('carrier') != 'Blue Cross RI') ||
				/Regence/.test(this.get('carrier')) ||
				/Lifewise/.test(this.get('carrier')) ||
				/Optima/.test(this.get('carrier')) ||
				/Providence/.test(this.get('carrier')) ||
				/Moda Health/.test(this.get('carrier')) ||
				/Excellus/.test(this.get('carrier')) ||
				/Coventry/.test(this.get('carrier')) ||
				/HealthPartners/.test(this.get('carrier')) ||
				/Highmark/.test(this.get('carrier')) ||
				/Anthem/.test(this.get('carrier')) ||
				/Cigna/.test(this.get('carrier')) ||
				/GHC/.test(this.get('carrier')) ||
				/SelectHealth/.test(this.get('carrier')) ||
				/AmeriHealth/.test(this.get('carrier')) ||
				/PreferredOne/.test(this.get('carrier')) ||
				/Assurant/.test(this.get('carrier')) ||
				/Guidestone/.test(this.get('carrier')) ||
				/Health Services Administrators/.test(this.get('carrier')) ||
				/Starmark/.test(this.get('carrier')) ||
				/Humana/.test(this.get('carrier')) ||
				/Independence/.test(this.get('carrier')) ||
				/Geoblue/.test(this.get('carrier')) ||
				/Priority Health/.test(this.get('carrier')) ||
				/ConnectiCare CT/.test(this.get('carrier')) ||
				/Presbyterian/.test(this.get('carrier')) ||
				(/HealthNet/.test(this.get('carrier')) && this.get('carrier') != 'HealthNet') ||
				/Guardian/.test(this.get('selectedCarrier')) ||
				/EMI Health/.test(this.get('selectedCarrier'))
			);
		}.property('carrier'),
		isBlueCrossRISelected: Ember.computed.equal('carrier', 'Blue Cross RI'),
		isHarvardPilgrimSelected: Ember.computed.match('carrier', /Harvard Pilgrim/),
		isSeeChangeSelected: Ember.computed.match('carrier', /SeeChange/),
		isUnitedNYSelected: Ember.computed.equal('carrier', 'United NY'),
		isOxfordSelected: Ember.computed.equal('carrier', 'Oxford'),
		isCalChoiceSelected: Ember.computed.equal('carrier', 'CalChoice'),
		isAetnaNYSelected: function () {
			return (
				/Aetna/.test(this.get('carrier')) ||
				/Medical Mutual/.test(this.get('carrier')) ||
				/Tufts Health/.test(this.get('carrier')) ||
				/Neighborhood Health/.test(this.get('carrier')) ||
				/Health Republic/.test(this.get('carrier')) ||
				/HealthPass/.test(this.get('carrier'))
			);
		}.property('carrier'),
		isEmblemSelected: Ember.computed.equal('carrier', 'Emblem Health'),
		isBlueShieldSelected: Ember.computed.equal('carrier', 'Blue Shield'),
		isUnitedSelected: function () {
			return (
				(/United/.test(this.get('carrier')) && this.get('carrier') != 'United NY') ||
				/UHC River Valley/.test(this.get('carrier')) ||
				/NHP/.test(this.get('carrier'))
			);
		}.property('carrier'),
		isHealthNetSelected: Ember.computed.equal('carrier', 'HealthNet'),
		isKaiserSelected: Ember.computed.match('carrier', /Kaiser Permanente/),
		idStr: Ember.computed(function () {
			return String(this.get('id'));
		}).property('id'),
		ruleSetName: Ember.computed.alias('compositeRuleSet.name'),
		factorSetName: Ember.computed.alias('compositeFactorSet.name'),
		compositeRules: Ember.computed.alias('compositeRuleSet.compositeRules'),

		shortCircuitPlanTypeWithRatesNotBenefits: Ember.computed.equal('shortCircuitPlanType', 'R'),
		shortCircuitPlanWithoutBenefits: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'isShortCircuitPlan'),
		compositeRulesAreLoaded: Ember.computed(function () {
			return this.get('compositeRules').every(function (rule) {
				return rule.get('isLoaded');
			});
		}).property('compositeRules.@each.isLoaded'),
		nameLimited: function () {
			var name = this.get('name');
			if (name && name.length > 15) {
				return this.get('name').substring(0, 12) + '...';
			}
			return name;
		}.property('name'),
		nameLimitedBig: function () {
			var name = this.get('name');
			if (name && name.length > 21) {
				return this.get('name').substring(0, 18) + '...';
			}
			return name;
		}.property('name'),
		nameLimitedDouble: function () {
			var name = this.get('name');
			if (name && name.length > 30) {
				return name.substring(0, 18) + '...' + name.substr(name.length - 9);
			}
			return name;
		}.property('name'),
		nameAndId: Ember.computed(function () {
			return this.get('id') + ' - ' + this.get('name');
		}).property('id', 'name'),
	});

	App.Plan = _PlanAbstractModel.extend({
		// ATTRIBUTES
		minLives: attr('number'),
		maxLives: attr('number'),
		planOrder: attr('number'),
		HMOPPO: attr('string'),
		networkSize: attr('number'),
		needsPCP: attr('boolean'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),
		network: DS.belongsTo('App.Network'),
		metalTier: attr('string'),
		planType: attr('string'),
		applyRaf: attr('boolean'),
		preferredForPlanShopping: attr('boolean'),
		inaccurateRates: attr('boolean'),

		afterDeductibles: attr('string'),
		deductibleIndividualPreferredNetwork: attr('number'),
		deductibleIndividual: attr('number'),
		deductibleIndividualOutOfNetwork: attr('number'),
		deductibleFamilyPreferredNetwork: attr('number'),
		deductibleFamily: attr('number'),
		deductibleFamilyOutOfNetwork: attr('number'),
		pharmacyDeductiblePreferredNetwork: attr('number'),
		pharmacyDeductible: attr('number'),
		pharmacyDeductibleOutOfNetwork: attr('number'),
		oopMaxIndividualPreferredNetwork: attr('number'),
		oopMaxIndividual: attr('number'),
		oopMaxIndividualOutOfNetwork: attr('number'),
		oopMaxFamilyPreferredNetwork: attr('number'),
		oopMaxFamily: attr('number'),
		oopMaxFamilyOutOfNetwork: attr('number'),
		oopMaxIncludeDeductible: attr('boolean'),
		hasNetworkProviders: attr('boolean'),

		coPayPreferredNetwork: attr('number'),
		coPay: attr('number'),
		coPayOutOfNetwork: attr('number'),
		specialistCoPayPreferredNetwork: attr('number'),
		specialistCoPay: attr('number'),
		specialistCoPayOutOfNetwork: attr('number'),
		preventativeCarePreferredNetwork: attr('number'),
		preventativeCare: attr('number'),
		preventativeCareOutOfNetwork: attr('number'),
		preferredNetworkCoInsurance: attr('number'),
		coInsurance: attr('number'),
		outOfNetworkCoInsurance: attr('number'),

		pharmacyCoverage: attr('boolean'),
		rxSupplyDaysRetail: attr('number'),
		rxCoPayGenericRetail: attr('string'),
		rxCoPayGenericRetailCondition: attr('number'),
		rxCoPayGenericRetailSecondary: attr('number'),
		rxCoPayBrandRetail: attr('string'),
		rxCoPayBrandRetailCondition: attr('number'),
		rxCoPayBrandRetailSecondary: attr('number'),
		rxCoPayNonFormularyRetail: attr('string'),
		rxCoPayNonFormularyRetailCondition: attr('number'),
		rxCoPayNonFormularyRetailSecondary: attr('number'),
		specialtyPharmacy: attr('string'),
		specialtyPharmacyCondition: attr('number'),
		specialtyPharmacySecondary: attr('number'),

		hospitalOutpatientPreferredNetwork: attr('number'),
		hospitalOutpatientConditionPreferredNetwork: attr('number'),
		hospitalOutpatientSecondaryPreferredNetwork: attr('number'),

		hospitalOutpatient: attr('number'),
		hospitalOutpatientCondition: attr('number'),
		hospitalOutpatientSecondary: attr('number'),

		emergencyServicePrimaryPreferredNetwork: attr('number'),
		emergencyServiceConditionPreferredNetwork: attr('number'),
		emergencyServiceSecondaryPreferredNetwork: attr('number'),

		emergencyServicePrimary: attr('number'),
		emergencyServiceCondition: attr('number'),
		emergencyServiceSecondary: attr('number'),

		urgentCarePreferredNetwork: attr('number'),
		urgentCareConditionPreferredNetwork: attr('number'),
		urgentCareSecondaryPreferredNetwork: attr('number'),

		urgentCare: attr('number'),
		urgentCareCondition: attr('number'),
		urgentCareSecondary: attr('number'),

		hospitalInpatient: attr('number'),
		hospitalInpatientCondition: attr('number'),
		hospitalInpatientSecondary: attr('number'),

		hospitalInpatientPreferredNetwork: attr('number'),
		hospitalInpatientConditionPreferredNetwork: attr('number'),
		hospitalInpatientSecondaryPreferredNetwork: attr('number'),

		dailyLimitsPreferredNetwork: attr('number'),
		dailyLimits: attr('number'),
		maxDaysPreferredNetwork: attr('number'),
		maxDays: attr('number'),

		HSA: attr('boolean'),
		minHSAContribution: attr('number'),
		maxHSAContribution: attr('number'),
		HRA: attr('boolean'),
		minHRAContribution: attr('number'),
		maxHRAContribution: attr('number'),
		bundledOldStyleDentalPlan: DS.belongsTo('App.DentalPlan'),
		isDentalBundled: attr('boolean'),
		isVisionBundled: attr('boolean'),
		fundingType: attr('string'),
		customPlanCompanyId: attr('number'),
		customPlanReason: attr('string'),
		associatedCompanies: DS.hasMany('App.Company', { inverse: null }),

		productCode: attr('string'),
		carrierCode: attr('string'),
		carrierRxCode: attr('string'),
		carrierInternalCode: attr('string'),
		recommendedRenewalPlan: DS.belongsTo('App.Plan'),
		sourcePlanId: attr('number'),
		planRequestLink: attr('string'),

		inProgress: attr('boolean'),
		genderBandedStyle: attr('number'),
		useDependentAge: attr('boolean'),
		useGenderBandedPrefixForRegionMapping: attr('boolean'),
		overrideCarrierDefaultRegion: attr('boolean'),
		useEEZipAvailability: attr('boolean'),

		// Deductible indicator
		coPayDedWaived: attr('number'),
		coPayOutDedWaived: attr('number'),
		coPayPrefDedWaived: attr('number'),
		specialistCoPayDedWaived: attr('number'),
		specialistCoPayOutDedWaived: attr('number'),
		specialistCoPayPrefDedWaived: attr('number'),
		preventativeCareDedWaived: attr('number'),
		preventativeCareOutDedWaived: attr('number'),
		preventativeCarePrefDedWaived: attr('number'),
		coInsuranceDedWaived: attr('number'),
		coInsuranceOutDedWaived: attr('number'),
		coInsurancePrefDedWaived: attr('number'),
		rxCoPayGenericRetailDedWaived: attr('number'),
		rxCoPayBrandRetailDedWaived: attr('number'),
		rxCoPayNonFormularyRetailDedWaived: attr('number'),
		specialtyPharmacyDedWaived: attr('number'),
		hospitalOutpatientDedWaived: attr('number'),
		hospitalOutpatientPrefDedWaived: attr('number'),
		emergencyServiceDedWaived: attr('number'),
		emergencyServicePrefDedWaived: attr('number'),
		urgentCareDedWaived: attr('number'),
		urgentCarePrefDedWaived: attr('number'),
		hospitalInpatientDedWaived: attr('number'),
		hospitalInpatientPrefDedWaived: attr('number'),
		dailyLimitsDedWaived: attr('number'),
		dailyLimitsPrefDedWaived: attr('number'),

		// Level Funding fields
		deductibleReset: attr('string'),
		stopLossPolicy: attr('string'),
		stopLossCarrierName: attr('string'),
		individualStopLossLevel: attr('number'),
		islIncludesPrescriptions: attr('boolean'),
		aggregateStopLossLevel: attr('string'),
		stopLossContractTerms: attr('string'),
		surplusReturnPercentage: attr('number'),
		surplusReturnDelivery: attr('string'),
		stopLossCarrierId: attr('number'),

		// PROPERTIES
		name: attr('string'),
		group: attr('string'),
		groupDisplay: Ember.computed.alias('group'),
		isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
		isPPO: Ember.computed.equal('HMOPPO', 'PPO'),
		isEmpireSelected: Ember.computed.equal('carrier', 'Empire Blue Cross'),
		emergencyService: attr('string'),
		lineOfCoverage: function () {
			return 'medical';
		}.property(),
		companyIds: function () {
			return this.get('associatedCompanies').getEach('id');
		}.property('associatedCompanies.@each.id'),

		isKaiserSelectedForPreviousCoverage: function () {
			return this.get('carrier') == 'Kaiser Permanente' || this.get('carrier') == 'Kaiser Permanente OR';
		}.property('carrier'),
		planViewUrl: function () {
			return "/console/plan/view?planId=" + this.get('id');
		}.property('id'),
		newPlanFormViewUrl: function () {
			return "/console/dashboard/#/medical-plan/" + this.get('id') + "/edit";
		}.property('id'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.Plan";
		}.property('id'),
		planRateHistoryUrl: function () {
			return "/console/medical_plan_rate_history/" + this.get('id');
		}.property('id'),
		planAPIConnectionHistory: function () {
			return "/console/plan/" + this.get('id') + "/connection_records";
		}.property('id'),
		isDentalAndVisionBundled: Ember.computed.and('isDentalBundled', 'isVisionBundled'),
		comments: function () {
			var commentId = this.get('id');
			return DS.PromiseArray.create({
				promise: window.App.store.find('comment', { relatedModelTypeKey: 'plan', relatedModelId: commentId }, function (newComment) {
					return newComment.get('relatedModelTypeKey') === 'plan' && newComment.get('relatedModelId') === commentId;
				})
			});
		}.property(),
		displayNameOrName: Ember.computed.or('displayName', 'name'),
	});

	App.DentalPlan = _PlanAbstractModel.extend({
		// ATTRIBUTES
		name: attr('string'),
		customPlanCompanyId: attr('number'),
		customPlanReason: attr('string'),
		associatedCompanies: DS.hasMany('App.Company', { inverse: null }),

		minLives: attr('number'),
		maxLives: attr('number'),
		planOrder: attr('number'),
		HMOPPO: attr('string'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),
		preferredForPlanShopping: attr('boolean'),
		inaccurateRates: attr('boolean'),

		benefitPeriod: attr('string'),
		deductibleIndividual: attr('number'),
		deductibleIndividualOutOfNetwork: attr('number'),
		deductibleFamily: attr('number'),
		deductibleFamilyOutOfNetwork: attr('number'),
		maxBenefits: attr('number'),
		hasNetworkProviders: attr('boolean'),
		network: DS.belongsTo('App.Network'),

		coInsurancePreventative: attr('number'),
		coInsurancePreventativeCondition: attr('number'),
		coInsurancePreventativeSecondary: attr('number'),
		coInsurancePreventativeOutOfNetwork: attr('number'),
		coInsurancePreventativeConditionOutOfNetwork: attr('number'),
		coInsurancePreventativeSecondaryOutOfNetwork: attr('number'),
		oralExam: attr('number'),
		oralExamOutOfNetwork: attr('number'),

		coInsuranceBasic: attr('number'),
		coInsuranceBasicCondition: attr('number'),
		coInsuranceBasicSecondary: attr('number'),
		coInsuranceBasicOutOfNetwork: attr('number'),
		coInsuranceBasicConditionOutOfNetwork: attr('number'),
		coInsuranceBasicSecondaryOutOfNetwork: attr('number'),
		filling: attr('number'),
		fillingOutOfNetwork: attr('number'),

		coInsuranceEndo: attr('number'),
		coInsuranceEndoCondition: attr('number'),
		coInsuranceEndoSecondary: attr('number'),
		coInsuranceEndoOutOfNetwork: attr('number'),
		coInsuranceEndoConditionOutOfNetwork: attr('number'),
		coInsuranceEndoSecondaryOutOfNetwork: attr('number'),
		rootCanal: attr('number'),
		rootCanalOutOfNetwork: attr('number'),

		coInsurancePerio: attr('number'),
		coInsurancePerioCondition: attr('number'),
		coInsurancePerioSecondary: attr('number'),
		coInsurancePeriodOutOfNetwork: attr('number'),
		coInsurancePerioConditionOutOfNetwork: attr('number'),
		coInsurancePerioSecondaryOutOfNetwork: attr('number'),
		scalingRootPlaning: attr('number'),
		scalingRootPlaningOutOfNetwork: attr('number'),

		coInsuranceMajor: attr('number'),
		coInsuranceMajorCondition: attr('number'),
		coInsuranceMajorSecondary: attr('number'),
		coInsuranceMajorOutOfNetwork: attr('number'),
		coInsuranceMajorConditionOutOfNetwork: attr('number'),
		coInsuranceMajorSecondaryOutOfNetwork: attr('number'),
		crown: attr('number'),
		crownOutOfNetwork: attr('number'),

		orthoCoverage: attr('boolean'),
		orthoDeductible: attr('number'),
		coInsuranceOrtho: attr('number'),
		coInsuranceOrthoOutOfNetwork: attr('number'),
		coInsuranceOrthoChild: attr('number'),
		coInsuranceOrthoOutOfNetworkChild: attr('number'),
		orthoMaxBenefits: attr('string'),
		orthoMaxAge: attr('string'),

		isVisionBundled: attr('boolean'),
		hasWaitingPeriods: attr('boolean'),
		progressiveBenefitLevels: attr('boolean'),
		fundingType: attr('string'),
		needsPCP: attr('boolean'),

		productCode: attr('string'),
		sourcePlanId: attr('number'),
		planRequestLink: attr('string'),
		recommendedRenewalPlan: DS.belongsTo('App.DentalPlan'),
		group: attr('string'),
		overrideCarrierDefaultRegion: attr('boolean'),
		genderBandedStyle: attr('number'),
		useSicRaf: attr('boolean'),
		freePlan: attr('boolean'),

		isNewStyle: attr('string'),
		useDependentAge: attr('boolean'),
		useGenderBandedPrefixForRegionMapping: attr('boolean'),
		singleTierRate: attr('boolean'),
		inProgress: attr('boolean'),
		benefitFeeStructure: attr('string'),

		adjustableRates: attr('boolean'),

		// PROPERTIES
		groupDisplay: Ember.computed.alias('group'),
		isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
		isPPO: Ember.computed.equal('HMOPPO', 'PPO'),
		isDeltaDentalSelected: Ember.computed.equal('carrier', 'Delta Dental'),
		isRelianceSelected: Ember.computed.equal('carrier', 'Reliance Standard'),
		isGuardianSelected: Ember.computed.equal('carrier', 'Guardian'),
		isChoiceBuilderSelected: Ember.computed.equal('carrier', 'Choice Builder'),
		lineOfCoverage: function () {
			return 'dental';
		}.property(),
		companyIds: function () {
			return this.get('associatedCompanies').getEach('id');
		}.property('associatedCompanies.@each.id'),
		planViewUrl: function () {
			return "/console/dental-plan/view?planId=" + this.get('id');
		}.property('id'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.DentalPlan";
		}.property('id'),
		newPlanFormViewUrl: function () {
			return "/console/dashboard/#/dental-plan/" + this.get('id') + "/edit";
		}.property('id'),
		planRateHistoryUrl: function () {
			return "/console/dental_plan_rate_history/" + this.get('id');
		}.property('id'),

		comments: function () {
			var commentId = this.get('id');
			return DS.PromiseArray.create({
				promise: window.App.store.find('comment', { relatedModelTypeKey: 'dentalPlan', relatedModelId: commentId }, function (newComment) {
					return newComment.get('relatedModelTypeKey') === 'dentalPlan' && newComment.get('relatedModelId') === commentId;
				})
			});
		}.property(),
		displayNameOrName: Ember.computed.or('displayName', 'name'),
	});

	App.VisionPlan = _PlanAbstractModel.extend({
		// ATTRIBUTES
		name: attr('string'),
		coPay: attr('number'),
		examFrequency: attr('number'),
		contactsFrequency: attr('number'),
		frameAllowable: attr('number'),
		frameAllowableCondition: attr('number'),
		frameAllowableSecondary: attr('number'),
		frameFrequency: attr('number'),
		lensAllowable: attr('number'),
		lensAllowableCondition: attr('number'),
		lensAllowableSecondary: attr('number'),
		lensFrequency: attr('number'),
		contacts: attr('string'),
		contactsAllowable: attr('number'),
		contactsAllowableCondition: attr('number'),
		contactsAllowableSecondary: attr('number'),
		frames: attr('string'),
		lasikCoverage: attr('boolean'),
		retailDiscountAvailable: attr('boolean'),
		hasNetworkProviders: attr('boolean'),
		lens: attr('string'),
		HMOPPO: attr('string'),
		isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
		isPPO: Ember.computed.equal('HMOPPO', 'PPO'),
		minLives: attr('number'),
		maxLives: attr('number'),
		availableOOS: attr('boolean'),
		availableOOSStates: attr('string'),
		fundingType: attr('string'),
		customPlanCompanyId: attr('number'),
		customPlanReason: attr('string'),
		associatedCompanies: DS.hasMany('App.Company', { inverse: null }),
		network: DS.belongsTo('App.Network'),
		recommendedRenewalPlan: DS.belongsTo('App.VisionPlan'),

		productCode: attr('string'),
		sourcePlanId: attr('number'),
		planRequestLink: attr('string'),
		inProgress: attr('boolean'),
		useSicRaf: attr('boolean'),
		freePlan: attr('boolean'),
		overrideCarrierDefaultRegion: attr('boolean'),
		genderBandedStyle: attr('number'),
		useDependentAge: attr('boolean'),
		useGenderBandedPrefixForRegionMapping: attr('boolean'),
		preferredForPlanShopping: attr('boolean'),
		inaccurateRates: attr('boolean'),

		adjustableRates: attr('boolean'),

		// PROPERTIES
		groupDisplay: Ember.computed.oneWay('group'),
		isDeltaSelected: Ember.computed.equal('carrier', 'Delta Dental'),
		isRelianceSelected: Ember.computed.equal('carrier', 'Reliance Standard'),
		lineOfCoverage: function () {
			return 'vision';
		}.property(),
		companyIds: function () {
			return this.get('associatedCompanies').getEach('id');
		}.property('associatedCompanies.@each.id'),
		planViewUrl: function () {
			return "/console/vision-plan/view?planId=" + this.get('id');
		}.property('id'),
		newPlanFormViewUrl: function () {
			return "/console/dashboard/#/vision-plan/" + this.get('id') + "/edit";
		}.property('id'),
		planHistoryUrl: function () {
			return "/console/object_history/" + this.get('id') + "/company_health_qem.models.VisionPlan";
		}.property('id'),
		planRateHistoryUrl: function () {
			return "/console/vision_plan_rate_history/" + this.get('id');
		}.property('id'),
		comments: function () {
			var commentId = this.get('id');
			return DS.PromiseArray.create({
				promise: window.App.store.find('comment', { relatedModelTypeKey: 'visionPlan', relatedModelId: commentId }, function (newComment) {
					return newComment.get('relatedModelTypeKey') === 'visionPlan' && newComment.get('relatedModelId') === commentId;
				})
			});
		}.property(),
		displayNameOrName: Ember.computed.or('displayName', 'name'),
	});

	App.CompanySettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		openEnrollmentStartDate: attr('string'),
		openEnrollmentEndDate: attr('string'),
		openEnrollmentMonth: attr('string'),
		waitingPeriod: attr('string'),
		isAnyEnrollmentInProgress: attr('boolean'),
		isOpenEnrollmentInProgress: attr('boolean'),
		postACA: attr('boolean'),
		groupID: attr('string'),
		dentalGroupID: attr('string'),
		visionGroupID: attr('string'),
		approvalLetterUrl: attr('string'),
		approvedDate: attr('string'),
		censusUrl: attr('string'),
		hasEmployerAccess: attr('raw'),
		doWeRunPayroll: attr('raw'),
		doWeAutoPay: attr('raw'),
		showStaffDirectory: attr('boolean'),
		showStaffDirectoryInMultiOrg: attr('boolean'),
		showEmployeePhoneToPeers: attr('boolean'),
		showStaffTimeOff: attr('boolean'),
		cobraType: attr('string'),
		cobraTakeoverDate: attr('string'),
		cobraMaxMonthsCoverage: attr('number'),
		areEmployeesFedCobraEligible: Ember.computed.equal('cobraType', 'Federal'),
		pushContributionsToPayroll: attr('boolean'),
		domesticPartnerPostTax: attr('boolean'),
		firstOfMonthSameEffectiveDate: attr('string'),
		carriers: DS.hasMany('App.AdditionalCarrier'),
		waitingPeriodHireDate: Ember.computed.equal('waitingPeriod', 'Hire Date'),
		hideCompanyContributions: attr('raw'),
		showCustomEEQuoteContributionsExampleText: attr('boolean'),
		employeeQuoteContributionsExampleText: attr('string'),
		overrideProRatingStrategy: attr('string'),
		isAdminDeductionsAuditLive: attr('boolean'),
		isInAudit: attr('boolean'),
		reviewQualifyingLifeEventDocument: attr('boolean'),
		reviewCoverageCancellationDocument: attr('boolean'),
	});

	App.EmployeeSettings = DS.Model.extend({
		openEnrollmentStartDate: attr('string'),
		openEnrollmentEndDate: attr('string'),
		medicalRenewalDate: attr('string'),
		dentalRenewalDate: attr('string'),
		visionRenewalDate: attr('string'),
		groupID: attr('string'),
		planType: attr('string'),
		dentalPlanType: attr('string'),
		visionPlanType: attr('string'),
		medicalCarrier: DS.belongsTo('App.Carrier'),
		dentalCarrier: DS.belongsTo('App.Carrier'),
		visionCarrier: DS.belongsTo('App.Carrier'),
		dentalGroupID: attr('string'),
		dentalCompanyGroupID: attr('string'),
		dentalRxBinNumber: attr('string'),
		dentalRxPCN: attr('string'),
		dentalRxGroup: attr('string'),
		visionGroupID: attr('string'),
		visionCompanyGroupID: attr('string'),
		visionRxBinNumber: attr('string'),
		visionRxPCN: attr('string'),
		visionRxGroup: attr('string'),
		medicalCompanyGroupID: attr('string'),
		medicalRxBinNumber: attr('string'),
		medicalRxPCN: attr('string'),
		medicalRxGroup: attr('string'),
		medicalCost: attr('string'),
		dependentMedicalCost: attr('string'),
		dentalCost: attr('string'),
		dependentDentalCost: attr('string'),
		visionCost: attr('string'),
		dependentVisionCost: attr('string'),
		approvedDate: attr('string'),
		dentalApprovedDate: attr('string'),
		visionApprovedDate: attr('string'),
		medicalEffectiveDate: attr('string'),
		dentalEffectiveDate: attr('string'),
		visionEffectiveDate: attr('string'),
		completeDate: attr('string'),
		companyCompleteDate: attr('string'),
		medicalContribCost: attr('string'),
		dependentMedicalContribCost: attr('string'),
		totalMedicalCost: attr('string'),
		totalMedicalContribution: attr('string'),
		youPayMedical: attr('string'),
		totalMedicalContributionPerPayPeriod: attr('string'),
		totalMedicalDeductionPerPayPeriod: attr('string'),
		spanningContribution: attr('boolean'),
		spanningEmployeeContribution: attr('string'),
		hasSpanningMaxLimit: attr('boolean'),
		employeeContribution: attr('string'),
		dependentContribution: attr('string'),
		basePlan: DS.belongsTo('App.Plan'),
		basePlanYouCost: attr('string'),
		basePlanYourDependentCost: attr('string'),
		totalDentalCost: attr('string'),
		totalDentalContribution: attr('string'),
		youPayDental: attr('string'),
		totalDentalContributionPerPayPeriod: attr('string'),
		totalDentalDeductionPerPayPeriod: attr('string'),
		dentalEmployeeContribution: attr('string'),
		dentalDependentContribution: attr('string'),
		dentalBasePlan: DS.belongsTo('App.DentalPlan'),
		dentalBasePlanYouCost: attr('string'),
		dentalBasePlanYourDependentCost: attr('string'),
		totalVisionCost: attr('string'),
		totalVisionContribution: attr('string'),
		youPayVision: attr('string'),
		totalVisionContributionPerPayPeriod: attr('string'),
		totalVisionDeductionPerPayPeriod: attr('string'),
		visionEmployeeContribution: attr('string'),
		visionDependentContribution: attr('string'),
		numberDependentsMedical: attr('number'),
		numberDependentsDental: attr('number'),
		numberDependentsVision: attr('number'),
		isSelfMedicalEnrolled: attr('boolean'),
		isSelfDentalEnrolled: attr('boolean'),
		isSelfVisionEnrolled: attr('boolean'),
		dependentAdditionalContribution: attr('string'),
		hsaAdditionalContribution: attr('string'),
		medicalPlan: DS.belongsTo('App.EPlan'),
		dentalPlan: DS.belongsTo('App.EDentalPlan'),
		visionPlan: DS.belongsTo('App.EVisionPlan'),
		medicalCompanyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		dentalCompanyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		visionCompanyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		medicalNewHireApplicationStatus: attr('string'),
		dentalNewHireApplicationStatus: attr('string'),
		visionNewHireApplicationStatus: attr('string'),
		havePersonalInfo: attr('boolean'),
		overrideMedicalWaitingPeriod: attr('string'),
		medicalWaitingPeriodDisplayText: attr('string'),
		overrideDentalWaitingPeriod: attr('string'),
		dentalWaitingPeriodDisplayText: attr('string'),
		overrideVisionWaitingPeriod: attr('string'),
		visionWaitingPeriodDisplayText: attr('string'),
		calculatedMedicalEffectiveDate: attr('string'),
		calculatedDentalEffectiveDate: attr('string'),
		calculatedVisionEffectiveDate: attr('string'),
		pushDeductionToPayroll: attr('boolean'),
		reason: attr('string'),
		// boolean or null is expected
		generalIsPublished: attr(),
		medicalIsPublished: attr(),
		dentalIsPublished: attr(),
		visionIsPublished: attr(),
		isMedicalNewHireApplicationYetToBeSent: Ember.computed.equal('medicalNewHireApplicationStatus', 'waiting_to_send'),
		isMedicalNewHireApplicationSent: Ember.computed.equal('medicalNewHireApplicationStatus', 'sent_to_carrier'),
		isMedicalNewHireApplicationSentViaEdi: Ember.computed.equal('medicalNewHireApplicationStatus', 'sent_via_edi'),
		isMedicalNewHireApplicationSentViaStp: Ember.computed.equal('medicalNewHireApplicationStatus', 'sent_via_stp'),
		isMedicalNewHireApplicationSentElectronic: Ember.computed.or('isMedicalNewHireApplicationSentViaEdi', 'isMedicalNewHireApplicationSentViaStp'),
		showMedicalTempCard: Ember.computed.or('groupID', 'isMedicalNewHireApplicationSentElectronic'),
		isDentalNewHireApplicationYetToBeSent: Ember.computed.equal('dentalNewHireApplicationStatus', 'waiting_to_send'),
		isDentalNewHireApplicationSent: Ember.computed.equal('dentalNewHireApplicationStatus', 'sent_to_carrier'),
		isDentalNewHireApplicationSentViaEdi: Ember.computed.equal('dentalNewHireApplicationStatus', 'sent_via_edi'),
		isDentalNewHireApplicationSentViaStp: Ember.computed.equal('dentalNewHireApplicationStatus', 'sent_via_stp'),
		isDentalNewHireApplicationSentElectronic: Ember.computed.or('isDentalNewHireApplicationSentViaEdi', 'isDentalNewHireApplicationSentViaStp'),
		showDentalTempCard: Ember.computed.or('dentalGroupID', 'isDentalNewHireApplicationSentElectronic'),
		isVisionNewHireApplicationYetToBeSent: Ember.computed.equal('visionNewHireApplicationStatus', 'waiting_to_send'),
		isVisionNewHireApplicationSent: Ember.computed.equal('visionNewHireApplicationStatus', 'sent_to_carrier'),
		isVisionNewHireApplicationSentViaEdi: Ember.computed.equal('visionNewHireApplicationStatus', 'sent_via_edi'),
		isVisionNewHireApplicationSentViaStp: Ember.computed.equal('visionNewHireApplicationStatus', 'sent_via_stp'),
		isVisionNewHireApplicationSentElectronic: Ember.computed.or('isVisionNewHireApplicationSentViaEdi', 'isVisionNewHireApplicationSentViaStp'),
		showVisionTempCard: Ember.computed.or('visionGroupID', 'isVisionNewHireApplicationSentElectronic'),
		hasMedicalWaitingPeriod: function () {
			var mWP = this.get('overrideMedicalWaitingPeriod');
			if (!mWP || mWP == 'First of the month following hire date' || mWP == 'Default' || mWP == 'Hire Date') {
				return false;
			}

			return true;
		}.property('overrideMedicalWaitingPeriod'),
		hasDentalWaitingPeriod: function () {
			var dWP = this.get('overrideDentalWaitingPeriod');
			if (!dWP || dWP == 'First of the month following hire date' || dWP == 'Default' || dWP == 'Hire Date') {
				return false;
			}

			return true;
		}.property('overrideDentalWaitingPeriod'),
		hasVisionWaitingPeriod: function () {
			var vWP = this.get('overrideVisionWaitingPeriod');
			if (!vWP || vWP == 'First of the month following hire date' || vWP == 'Default' || vWP == 'Hire Date') {
				return false;
			}

			return true;
		}.property('overrideVisionWaitingPeriod'),
		hsaAdditionalContributionEnabled: function () {
			var hsaContrib = this.get('hsaAdditionalContribution');
			hsaContrib = hsaContrib ? parseFloat(hsaContrib) : 0;
			return hsaContrib ? true : false;
		}.property('hsaAdditionalContribution'),
		youTotalCost: function () {
			var total = 0;
			if (this.get('approvedDate')) {
				var medicalCost = this.get('youPayMedical');
				if (!medicalCost) {
					medicalCost = 0;
				}
				total += parseInt(medicalCost);
			}
			if (this.get('dentalApprovedDate')) {
				var dentalCost = this.get('youPayDental');
				if (dentalCost) {
					total += parseInt(dentalCost);
				}
			}
			if (this.get('visionApprovedDate')) {
				var visionCost = this.get('youPayVision');
				if (visionCost) {
					total += parseInt(visionCost);
				}
			}

			return total;
		}.property('youPayMedical', 'approvedDate', 'youPayDental', 'dentalApprovedDate', 'youPayVision', 'visionApprovedDate'),
		totalCost: function () {
			var medicalCost = this.get('totalMedicalCost');
			if (!medicalCost) {
				medicalCost = 0;
			}
			var total = parseInt(medicalCost);
			var dentalCost = this.get('totalDentalCost');
			if (dentalCost) {
				total += parseInt(dentalCost);
			}
			var visionCost = this.get('totalVisionCost');
			if (visionCost) {
				total += parseInt(visionCost);
			}

			return total;
		}.property('totalMedicalCost', 'approvedDate', 'totalDentalCost', 'dentalApprovedDate', 'totalVisionCost', 'visionApprovedDate'),
		showCostsBreakdown: function () {
			if (this.get('spanningContribution')) {
				return false;
			}

			return true;
		}.property('spanningContribution'),
		isDifferentDentalCarrier: function () {
			if (this.get('medicalCarrier') && this.get('dentalCarrier')) {
				return (this.get('medicalCarrier.id') != this.get('dentalCarrier.id'));
			}

			return false;
		}.property('medicalCarrier', 'dentalCarrier'),
		isSameDentalVisionCarrier: function () {
			if (this.get('visionCarrier') && this.get('dentalCarrier')) {
				return (this.get('dentalCarrier.id') == this.get('visionCarrier.id'));
			}

			return false;
		}.property('visionCarrier', 'dentalCarrier'),
		isDifferentVisionCarrier: function () {
			if (this.get('medicalCarrier') && this.get('dentalCarrier') && this.get('visionCarrier')) {
				return (this.get('medicalCarrier.id') != this.get('visionCarrier.id') && this.get('dentalCarrier.id') != this.get('visionCarrier.id'));
			}

			return false;
		}.property('medicalCarrier', 'dentalCarrier', 'visionCarrier'),
		isYouPremium: function () { return this.get('planType') == 'youPremium'; }.property('planType'),
		isYouAndSpousePremium: function () { return this.get('planType') == 'youAndSpousePremium'; }.property('planType'),
		isYouAndChildPremium: function () { return this.get('planType') == 'youAndChildPremium'; }.property('planType'),
		isFamilyPremium: function () { return this.get('planType') == 'familyPremium'; }.property('planType'),
		isSpouseCoveredMedical: function () {
			return (this.get('planType') == 'youAndSpousePremium' || this.get('planType') == 'familyPremium');
		}.property('planType'),
		isSpouseCoveredDental: function () {
			return (this.get('dentalPlanType') == 'youAndSpousePremium' || this.get('dentalPlanType') == 'familyPremium');
		}.property('dentalPlanType'),
		isSpouseCoveredVision: function () {
			return (this.get('visionPlanType') == 'youAndSpousePremium' || this.get('visionPlanType') == 'familyPremium');
		}.property('visionPlanType'),
		isChildCoveredMedical: function () {
			return (this.get('planType') == 'youAndChildPremium' || this.get('planType') == 'familyPremium');
		}.property('planType'),
		isChildCoveredDental: function () {
			return (this.get('dentalPlanType') == 'youAndChildPremium' || this.get('dentalPlanType') == 'familyPremium');
		}.property('dentalPlanType'),
		isChildCoveredVision: function () {
			return (this.get('visionPlanType') == 'youAndChildPremium' || this.get('visionPlanType') == 'familyPremium');
		}.property('visionPlanType'),
		spouseOnPlan: Ember.computed.or('isSpouseCoveredMedical', 'isSpouseCoveredDental', 'isSpouseCoveredVision'),
		childOnPlan: Ember.computed.or('isChildCoveredMedical', 'isChildCoveredDental', 'isChildCoveredVision'),
		isMedicalPercentage: function () {
			return (this.get('employeeContribution') && this.get('employeeContribution').indexOf('%') != -1);
		}.property('employeeContribution'),
		carriers: function () {
			return ["medicalCarrier", "dentalCarrier", "visionCarrier"].
				map(function (carrierType) { return this.get(carrierType + ".displayName"); }, this).
				filter(function (name, i, array) { return name && array.indexOf(name, i + 1) === -1; }).
				join(", ");
		}.property('medicalCarrier.displayName', 'dentalCarrier.displayName', 'visionCarrier.displayName'),
		spanningEmployeeContributionCommas: function () {
			return this.get('spanningEmployeeContribution').toLocaleString();
		}.property('spanningEmployeeContribution'),
		nextOpenEnrollment: function () {
			var openEnrollmentStartDate = moment(this.get('openEnrollmentStartDate'), 'MM/DD/YYYY');
			if (moment().isBefore(openEnrollmentStartDate)) {
				return openEnrollmentStartDate.format('MMMM YYYY');
			}
			else {
				return openEnrollmentStartDate.add(1, 'y').format('MMMM YYYY');
			}
		}.property('openEnrollmentStartDate'),
		employerContribution: function () {
			return (this.get('totalMedicalCost') - this.get('youPayMedical'));
		}.property('totalMedicalCost', 'youPayMedical'),
		employerDentalContribution: function () {
			return (this.get('totalDentalCost') - this.get('youPayDental'));
		}.property('totalDentalCost', 'youPayDental'),
		employerVisionContribution: function () {
			return (this.get('totalVisionCost') - this.get('youPayVision'));
		}.property('totalVisionCost', 'youPayVision'),
	});


	App.SuspendUserItAction = DS.Model.extend({
		itServiceUser: DS.belongsTo('App.ItServiceUser'),
		requestingUser: DS.belongsTo('App.User')
	});

	App.TransferUserItAction = DS.Model.extend({
		itServiceUser: DS.belongsTo('App.ItServiceUser'),
		requestingUser: DS.belongsTo('App.User')
	});

	App.ChangeMemberStatus = DS.Model.extend({
		itSubserviceUser: DS.belongsTo('App.ItSubserviceUser'),
		requestingUser: DS.belongsTo('App.User'),
		action_type: attr('string'),
	});


	App.ItSubserviceUser = DS.Model.extend({
		companySubservice: DS.belongsTo('App.CompanyItSubservice', { inverse: 'itSubserviceUsers' }),
		serviceUser: DS.belongsTo('App.ItServiceUser', { inverse: 'itSubserviceUsers' }),
		isLeadAdmin: attr('boolean'),
		isAdmin: attr('boolean'),
		status: attr('string'),
		isShadow: attr('boolean'),
		lastRemovalDate: attr('string'),
		isApproved: attr('boolean'),
		isNotApproved: Ember.computed.not('isApproved'),
		employeeRequestingCreation: DS.belongsTo('App.AllEmployee'),
		isMember: Ember.computed.not('isAdmin'),
		isActive: Ember.computed.equal('status', 'Act'),
		isSettingUp: Ember.computed.equal('status', 'Set'),
		isSettingUpNonShadow: Ember.computed.and('isSettingUp', 'isNonShadow'),
		isRequested: Ember.computed.and('isSettingUp', 'isNotApproved'),
		isInactive: Ember.computed.equal('status', 'Ina'),
		isAddingOrActive: Ember.computed.or('isAdding', 'isActive'),
		isNonShadow: Ember.computed.not('isShadow'),
		isActiveNonShadow: Ember.computed.and('isNonShadow', 'isActive'),
		isActiveOrSettingUpNonShadow: Ember.computed.or('isSettingUpNonShadow', 'isActiveNonShadow'),
		dateToRemove: function () {
			var today = moment().subtract(1, 'days');
			var formatToday = today.format('YYYY-MM-DD');
			if (this.get('lastRemovalDate') == formatToday) {
				return today.subtract(1, 'days').format('YYYY-MM-DD');
			} else {
				return formatToday;
			}
		}.property('lastRemovalDate')
	});

	App.MembershipsToApprove = App.ItSubserviceUser.extend({
	});


	App.CompanyItSubservice = DS.Model.extend({
		companyService: DS.belongsTo('App.CompanyItService'),
		itSubserviceUsers: DS.hasMany('App.ItSubserviceUser', { inverse: 'companySubservice' }),
		isDisplaying: attr('boolean'),
		leadadmins: DS.hasMany('App.ItSubserviceUser'),
		admins: DS.hasMany('App.ItSubserviceUser'),
		users: DS.hasMany('App.ItSubserviceUser'),
		isJoinableByAll: attr('boolean'),
		whoCanSee: attr('string'),
		isVisibleByAll: Ember.computed.equal('whoCanSee', 'ALL'),
		status: attr('string'),
		type: attr('string'),
		name: attr('string'),
		options: attr('string'),
		username: attr('string'),
		description: attr('string'),
		apiKey: attr('string'),
		associatedDepartment: DS.belongsTo('App.Department'),
		associatedLocation: DS.belongsTo('App.CompanyLocation'),
		associatedTeamOwner: DS.belongsTo('App.AllEmployee'),
		associationConfirmed: attr('boolean'),
		displayName: Ember.computed.alias('name'),
		subserviceUnremovedPeople: Ember.computed.filterByProperty('itSubserviceUsers', 'isRemoved', false),
		usernamesInGroup: Ember.computed.mapByProperty('subserviceUnremovedPeople', 'serviceUser.username'),
		realNamesInGroup: Ember.computed.mapByProperty('subserviceUnremovedPeople', 'serviceUser.fullName'),
		activeMembersCount: attr('number'),
		isActive: Ember.computed.equal('status', 'Act'),
		displayGroupName: function () {
			var name = this.get('name');
			var username = this.get('username') ? " (" + this.get('username') + ") " : "";
			var departmentOrLocation = this.get('associatedDepartment.name') || this.get('associatedLocation.name');
			var departmentOrLocationDisplay = departmentOrLocation ? " (" + departmentOrLocation + ")" : "";
			return (name + username + departmentOrLocationDisplay) || " ";
		}.property('name', 'username', 'associatedDepartment.name', 'associatedLocation.name'),
		membersCountOne: Ember.computed.equal('activeMembersCount', 1)

	});


	App.ManagedGroups = App.CompanyItSubservice.extend({
	});


	App.ItServiceUser = DS.Model.extend({
		companyService: DS.belongsTo('App.CompanyItService'),
		companySubservice: DS.belongsTo('App.CompanyItSubservice'),
		itSubserviceUsers: DS.hasMany('App.ItSubserviceUser', { inverse: 'serviceUser' }),
		employee: DS.belongsTo('App.AllEmployee', { inverse: 'itServiceUsers' }),
		employeeID: attr('string'),
		employee_ID_for_mod: attr('string'),
		isLeadAdmin: attr('boolean'),
		isAdmin: attr('boolean'),
		isEditing: attr('boolean'),
		insideOrganization: attr('boolean'),
		username: attr('string'),
		status: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		userChoiceSet: attr('string'),
		matchAccuracy: attr('number'),
		dateToCreate: attr('string'),
		serviceDeactivationDate: "",
		zenefitsDeactivationDate: "",
		inactivationType: attr('string'),
		inactivationDate: attr('string'),
		swapLocation2: Ember.computed.oneWay('employee'),
		suspendInProgress: function () {
			return this.get('inactivationType') == 'suspend' && this.get('inactivationDate') && this.get('status') == 'Act';
		}.property('inactivationDate', 'inactivationType'),
		transferInProgress: function () {
			return this.get('inactivationType') == 'transfer' && this.get('inactivationDate') && this.get('status') == 'Act';
		}.property('inactivationDate', 'inactivationType', 'status'),
		isSuspended: function () {
			return this.get('inactivationType') == 'suspend' && this.get('inactivationDate') && this.get('status') == 'Ina';
		}.property('inactivationDate', 'inactivationType'),
		isTransferred: function () {
			return this.get('inactivationType') == 'transfer' && this.get('inactivationDate') && this.get('status') == 'Ina';
		}.property('inactivationDate', 'inactivationType', 'status'),
		isCreating: Ember.computed.equal('status', 'Set-Immediately'),
		isSettingUp: Ember.computed.equal('status', 'Set'),
		isDeactivated: Ember.computed.equal('status', 'Deac'),
		fullName: function () {
			var firstName = this.get('firstName') ? this.get('firstName') + ' ' : '';
			var lastName = this.get('lastName') ? this.get('lastName') : '';
			return firstName + lastName;
		}.property('firstName', 'lastName'),
		isSettingUpOrDeactivated: Ember.computed.or('isSettingUp', 'isDeactivated'),
		// exists so that save() is called on users in the provisioning flow
		throwawayField: attr('string'),
		showSavedIcon: attr('boolean'),
		displayGroupName: attr('string'),
		name: Ember.computed.alias('displayName'),
		isSettingUpOrActive: Ember.computed.or('isSettingUp', 'isActive'),
		isActive: Ember.computed.equal('readableStatus', 'Active'),
		canForceCreation: function () {
			return (this.get('isSettingUp') &&
				this.get('matchAccuracy') &&
				(this.get('employee.status') == 'Act' ||
					this.get('employee.newHires').some(function (newHire) {
						return newHire.get('status') == 'complete' || newHire.get('status') == 'signed' || newHire.get('signingCompleteTimestamp');
					})
				));
		}.property('isSettingUp', 'matchAccuracy', 'employee.status', 'employee.newHires.@each.status', 'employee.newHires.@each.signingCompleteTimestamp'),
		readableStatus: function () {
			var status = this.get('status');
			if (status == 'Set') {
				if (this.get('creatingInFuture')) {
					return this.get('firstName') + "'s email account will be created on " + this.get('dateToCreate');
				} else {
					return brandName+" will create this employee's email account after they've finished onboarding";
				}
			} else if (this.get('suspendInProgress')) {
				return "Suspension in progress";
			} else if (this.get('transferInProgress')) {
				return "Transfer in progress";
			} else if (this.get('isSuspended')) {
				return "Account suspended";
			} else if (this.get('isTransferred')) {
				return "Account transferred";
			} else if (status == 'Act') {
				return "Active";
			} else if (status == 'Ina') {
				return "Inactive";
			}
		}.property('status', 'creatingInFuture', 'dateToCreate', 'suspendInProgress', 'transferInProgress', 'isSuspended', 'isTransferred'),
		waitingForOnboarding: function () {
			return this.get('employee.status') == 'Set' || this.get('employee.status') == 'Req';
		}.property('employee.status'),
		creatingInFuture: function () {
			var now = moment();
			return moment(this.get('dateToCreate'), 'MM/DD/YYYY').isAfter(now);
		}.property('dateToCreate'),
		settingUpMessage: function () {
			var message = '';
			if (!this.get('isSettingUp')) {
				return message;
			}
			if (this.get('creatingInFuture')) {
				message = "Scheduled for: " + this.get('dateToCreate');
				if (this.get('waitingForOnboarding')) {
					message += ", also waiting for user to finish onboarding";
				}
			} else {
				if (this.get('waitingForOnboarding')) {
					message = "Waiting for user to finish onboarding";
				}
			}
			return message;
		}.property('isSettingUp', 'creatingInFuture', 'dateToCreate', 'waitingForOnboarding'),
		confirmMatch: function () {
			this.set('matchAccuracy', 99);
			this.save();
		},
		cancelMatch: function () {
			this.set('employee', null);
			this.save();
		},
		displayName: function () {
			var firstName = this.get('firstName') ? this.get('firstName') + ' ' : '';
			var lastName = this.get('lastName') ? this.get('lastName') + ", " : '';
			return firstName + lastName + this.get('username');
		}.property('firstName', 'lastName', 'username'),
		displayUsername: function () {
			return this.get('displayHandle') + '@' + this.get('domain');
		}.property('displayHandle', 'domain'),
		displayHandle: function () {
			var username = this.get('username');
			var parts = username.split("@");
			return parts.length > 1 ? parts[0] : '';
		}.property('username'),
		displayHandleOriginal: function () {
			var username = this.get('username');
			var parts = username.split("@");
			return parts.length > 1 ? parts[0] : '';
		}.property('username'),
		domain: function () {
			var username = this.get('username');
			var parts = username.split("@");
			return parts.length > 1 ? parts[1] : '';
		}.property('username'),
		toggleShowSavedIcon: function () {
			if (this.get('showSavedIcon')) {
				setTimeout(function () {
					this.set('showSavedIcon', false);
				}.bind(this), 3000);
			}
		}.observes('showSavedIcon'),
		isConfirmedMatch: function () {
			return this.get('matchAccuracy') >= 80;
		}.property('matchAccuracy'),
		isConfirmedMatchAndActive: Ember.computed.and('isActive', 'isConfirmedMatch'),
		random: function () {
			return Math.random();
		}.property(),
		displayEmployeeSelector: function () {
			return (this.get('optionsArray') || []).length > 1;
		}.property('optionsArray'),
		observesEmployeedID: function () {
			console.log('mod change');
			console.log(this.get('id'));
			console.log(this.get('employee_id'));
		}.observes('employee_id'),
		isRemovedOrDeleted: function () {
			var stat = this.get('status');
			return stat == 'Rem' || stat == 'Del';
		}.property('status'),
		selectedEmployeeID: function (key, value) {
			// acts as both getter and setter

			console.log('selected Term reason');
			console.log(arguments);
			if (arguments.length > 1) {
				this.set('firstName', value.toString());
				this.set('employee_ID_for_mod', value.toString());
				//this.save()
			}
		}.property('employeeID'),
		displayElement: function () {

			var user = this.get('employee');
			var overrideUserID = this.get('employee_ID_for_mod');
			if (overrideUserID === 0) {
				return "None of the above";
			}
			else if (overrideUserID) {
				// TODO: Chnage userModifiedChoiceSet formatting to append "|score|status"
				var myArray = this.get('optionsArray').filter(function (emp) {
					return (emp[0] == overrideUserID);
				});
				if (myArray.length > 0) {
					return myArray[0][1];
				}
			}
			else if (user) {
				var firstName = user.get('first_name');
				var lastName = user.get('last_name');
				var displayFullName = firstName && lastName ? firstName + " " + lastName : "";
				return displayFullName;
			}
			else {
				//this.set("employee_ID_for_mod", this.get('defaultElement')[0])
				return this.get('defaultElement')[1];
			}
		}.property('selectedUser', 'defaultDisplayElement', 'employeeID', 'employee', 'employee.first_name', 'employee.last_name', 'employee_ID_for_mod'),
		defaultDisplayElement: function () {
			return this.get('defaultElement')[1];
		}.property('defaultElement'),
		defaultElement: function () {
			return this.get('optionsArray')[0];
		}.property('optionsArray'),
		optionsArray: function () {
			var choiceSet = this.get('userChoiceSet');
			if (!choiceSet) {
				return [['', '']];
			}
			var parsedString = choiceSet.replace(/\(/g, '[').replace(/\)/g, ']').replace(/'/g, '"');
			return JSON.parse(parsedString);
		}.property('userChoiceSet'),
		userModifiedChoiceSet: function () {

			var choices = Ember.A();

			var employee_name = this.get('employee.fullName');

			if (this.get('userChoiceSet')) {
				var optionsArray = this.get('optionsArray');

				//choices.pushObject(Ember.Object.create({'name': "Select", 'id': null}));
				//last array element is a status
				for (var i = 0; i < optionsArray.length; i++) {
					if (employee_name && employee_name == optionsArray[i][1] && i > 0) {
						// put the selected choice first and put that choice in the current position
						choices.pushObject(choices[0]);
						choices[0] = Ember.Object.create({ 'name': optionsArray[i][1], 'id': optionsArray[i][0] });
					} else {
						choices.pushObject(Ember.Object.create({ 'name': optionsArray[i][1], 'id': optionsArray[i][0] }));
					}
				}
				if (this.get('employee_ID_for_mod') == "0") {
					choices = [Ember.Object.create({ 'name': 'None of the above', 'id': 0 })].concat(choices);
				}
				else {
					choices.pushObject(Ember.Object.create({ 'name': 'None of the above', 'id': 0 }));
				}
				return choices;
			}
			return;
		}.property('optionsArray'),
		selectedUser: function () {
			console.log(this.get('employee'));
			console.log(this.get('employeeID'));
		}.property('user', 'userID'),
		isSyncedObject: function () {
			var name = this.get('displayGroupName') || '';
			var left = name.substr(0, 4);
			var right = name.substr(name.length - 10, name.length);
			return left == 'All ' && right == ' employees';
		}.property('displayGroupName'),
		isInactive: Ember.computed.equal('status', 'Ina'),
		removedInactivationType: Ember.computed.equal('inactivationType', 'delete'),
		isRemoved: Ember.computed.and('removedInactivationType', 'isInactive')

	});

	App.CompanyItService = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		itService: DS.belongsTo('App.ItService'),
		status: attr('string'),
		client_secret: attr('number'),
		companyItSubservices: DS.hasMany('App.CompanyItSubservice'),
		itServiceUsers: DS.hasMany('App.ItServiceUser'),
		monthsAfterTerminationToDelete: attr('number'),
		serviceURLs: attr('string'),
		lockUserOutAfterTermination: attr('boolean'),
		suspendAccountAfterTermination: attr('boolean'),
		postTerminationSuffix: attr('string'),
		postTerminationSuffix2: Ember.computed.oneWay('postTerminationSuffix'),
		lastSyncTime: attr('string'),
		mainAdminEmail: attr('string'),
		mainAdminEmail2: Ember.computed.oneWay('mainAdminEmail'),
		hasSufficientCredentials: attr('boolean'),
		mainAdminDefaultEmail: attr('string'),
		syncReversible: attr('boolean'),
		isSyncing: Ember.computed.equal('status', 'syncing people'),
		isSyncingGroups: Ember.computed.equal('status', 'syncing groups'),
		hideFromHiringAndTermination: attr('boolean'),
		requireInHiringFlow: attr('boolean'),
		disableStartDateCreationOption: attr('boolean'),
		disableStartDateCreationOption2: Ember.computed.oneWay('disableStartDateCreationOption'),
		allowStartDateCreationOption: attr('boolean'),
		allowWeekBeforeCreationOption: attr('boolean'),
		allowPostAgreementCreationOption: attr('boolean'),
		twoFactorAuthEnabled: attr('boolean'),
		terminationPolicy: "per",
		terminationPolicy2: Ember.computed.oneWay('terminationPolicy'),
		isWeekOutCreationAllowed: true,
		isImmediateCreationAllowed: true,
		domainNeeded: function () {
			return true;
		}.property('itService.name'),
		willEventuallyDeleteDeactivated: function () {
			return this.get('monthsAfterTerminationToDelete') == null;
		},
		firstServiceURL: function () {
			var URLs = this.get('serviceURLs');
			if (!URLs) {
				return '';
			}
			return URLs.slice(0, URLs.indexOf(','));
		}.property('serviceURLs'),
		isMoreThanOneServiceURL: function () {
			if (!this.get('serviceURLs')) {
				return false;
			}
			return this.get('serviceURLs').length > this.get('firstServiceURL').length + 1;
		}.property('serviceURLs'),
		listOfServiceURLs: function () {
			var urls = Ember.A();
			var URLs = this.get('serviceURLs');
			var commaPosition = URLs.indexOf(',');
			while (commaPosition != -1) {
				var firstURL = URLs.slice(0, commaPosition);
				URLs = URLs.slice(commaPosition + 1);
				commaPosition = URLs.indexOf(',');
				urls.pushObject(Ember.Object.create({ 'name': firstURL, 'id': firstURL }));
			}
			return URLs;
		}.property('serviceURLs'),

	});

	App.ItService = DS.Model.extend({
		name: attr('string'),
		displayName: function () {
			var name = this.get('name');
			if (name == "Google Apps") {
				return "Google Apps for Work";
			}
			return name;
		}.property('name'),
		canManagerRequestService: attr('boolean'),
		credential_class: attr('string'),
		logoURL: function () {
			if (this.get('name')) {
				return "/static/img/company_logos/" + this.get('name').split(' ').join('_') + "_logo.png";
			}
		}.property('logoURL'),
		strID: function () {
			return this.get('id').toString();
		}.property('id')
	});

	App.ApiUser = DS.Model.extend({
		enabled: attr('boolean'),
		key: attr('string'),
		url: attr('string'),
	});

	App.BgcEmployerAgreement = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		status: attr('string'),
		pdfUrl: attr('string'),
		htmlUrl: attr('string'),
		displayDefaultHtmlUrl: attr('string'),
		displayHtmlUrl: function () {
			if (Boolean(this.get('htmlUrl'))) {
				return this.get('htmlUrl');
			}
			return this.get('displayDefaultHtmlUrl');
		}.property('htmlUrl'),
		sendBGCheckEmailsToManager: attr('boolean'),
		backgroundCheckRequired: attr('boolean'),
		backgroundCheckTypesAllowed: attr('string'),
		premiumOnly: function () {
			return this.get('backgroundCheckTypesAllowed') === 'premium';
		}.property('backgroundCheckTypesAllowed'),
		standardOnly: function () {
			return this.get('backgroundCheckTypesAllowed') === 'standard';
		}.property('backgroundCheckTypesAllowed'),
		motorVehicleReportAllowed: attr('boolean'),
		motorVehicleReportRequired: attr('boolean'),
		motorVehicleReportHelper: function (key, value) {
			/*
				state will be 1, 2, 3
				1: MVR required
				2. MVR allowed but not required
				3. MVR not allowed
			 */

			// SETTER
			// set booleans depending on the 1,2,3 state
			if (value === '1') {
				this.setProperties({
					motorVehicleReportRequired: true,
					motorVehicleReportAllowed: true,
				});
			} else if (value === '2') {
				this.setProperties({
					motorVehicleReportRequired: false,
					motorVehicleReportAllowed: true,
				});
			} else if (value === '3') {
				this.setProperties({
					motorVehicleReportRequired: false,
					motorVehicleReportAllowed: false,
				});
			}

			if (this.get('motorVehicleReportAllowed')) {
				if (this.get('motorVehicleReportRequired')) {
					return 1;
				} else {
					return 2;
				}
			} else {
				return 3;
			}
		}.property('motorVehicleReportRequired', 'motorVehicleReportAllowed'),
		runBackgroundCheckAnywayWithoutMVR: attr('nullBoolean'),
		progress: attr('string'),
		progressIndex: attr('number'),
		progressJSON: function () {
			var progress = this.get('progress');
			return progress ? JSON.parse(progress) : {};
		}.property('progress'),
		isComplete: attr('boolean')
	});

	App.BgcEmployeeAgreement = DS.Model.extend({
		newHire: DS.belongsTo('App.NewHire'),
		status: attr('string'),
		backgroundEmployeeSignatureName: attr('string'),
		backgroundEmployeeSignature: attr('string'),
		hasAgreedDisclosure: attr('boolean'),
		pdfUrl: attr('string'),
		htmlUrl: attr('string'),
		template: attr('string'),
		disclosurePdfUrl: attr('string'),
		disclosureHtmlUrl: attr('string'),
		disclosureTemplate: attr('string'),
	});

	App.OnboardingSettings = DS.Model.extend({
		customFieldSections: DS.hasMany('App.CustomFieldSection'),
		customFields: DS.hasMany('App.CustomField'),
		company: DS.belongsTo('App.Company'),
		offerLetters: DS.hasMany('App.OfferLetter'),
		ipAssignments: DS.hasMany('App.IpAssignment'),
		employeeHandbook: DS.belongsTo('App.EmployeeHandbook'),
		nonExemptNotice: DS.belongsTo('App.NonExemptNotice'),
		fromName: attr('string'),
		fromTitle: attr('string'),
		fromSignature: attr('string'),
		backgroundEmployerSignatureName: attr('string'),
		backgroundEmployerTitle: attr('string'),
		backgroundEmployerSignature: attr('string'),
		isEeo: attr('boolean'),
		isSkipEligibilityProofUpload: attr('boolean'),
		isDietary: attr('boolean'),
		isTshirtSize: attr('boolean'),
		isManualPaycheck: attr('boolean'),
		collectVaccinationInfo: attr('boolean'),
		isOfferLetterAuthorized: attr('boolean'),
		bgcEmployerAgreementID: attr('number'),
		onboardingBackgroundCheckExampleUrl: attr('string'),
		currentBackgroundCheckExampleUrl: attr('string'),
		scheduledReminders: DS.hasMany('App.ScheduledReminder'),
		activeIpAssignments: Ember.computed.filterByProperty('ipAssignments', 'isActive', true),
		ipTemplates: Ember.computed.filterByProperty('activeIpAssignments', 'type', 'A'),
		ehTemplates: Ember.computed.filterByProperty('activeIpAssignments', 'type', 'E'),
		crTemplates: Ember.computed.filterByProperty('activeIpAssignments', 'type', 'C'),
		changeLetterTemplates: Ember.computed.filterByProperty('activeIpAssignments', 'type', 'R'),
		isBackgroundCheckAgreementComplete: function () {
			return Boolean(this.get('backgroundEmployerSignatureName')) && Boolean(this.get('backgroundEmployerTitle')) &&
				Boolean(this.get('backgroundEmployerSignature')) && this.get('company.isBackgroundCheckInfoComplete');
		}.property('backgroundEmployerSignatureName', 'backgroundEmployerTitle', 'backgroundEmployerSignature', 'company.isBackgroundCheckInfoComplete'),
		hasSetupOfferLetters: function () {
			return this.get('offerLetters.length') > 0 && this.get('offerLetters').findProperty('isTemplatized');
		}.property('offerLetters.length', 'offerLetters.@each.isTemplatized'),
		hasSetupIpAssignments: function () {
			return this.get('ipTemplates.length') > 0 && this.get('ipTemplates').findProperty('isTemplatized');
		}.property('ipTemplates.length', 'ipTemplates.@each.isTemplatized'),
		hasSetupEmployeeHandbook: function () {
			return this.get('ehTemplates.length') > 0 && this.get('ehTemplates').findProperty('isTemplatized');
		}.property('ehTemplates.length', 'ehTemplates.@each.isTemplatized'),
		isSetupComplete: function () {
			return (this.get('hasSetupOfferLetters') || this.get('hasSetupIpAssignments') || this.get('hasSetupEmployeeHandbook')) && this.get('fromName') && this.get('fromSignature');
		}.property('hasSetupOfferLetters', 'hasSetupIpAssignments', 'hasSetupEmployeeHandbook', 'fromName', 'fromSignature'),
		hasSetupInternationalOfferLetters: function () {
			return this.get('offerLetters.length') > 0 && this.get('offerLetters').findProperty('isInternational');
		}.property('offerLetters.length', 'offerLetters.@each.isInternational'),
	});

	App.NewPayroll = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		employeeCount: attr('number'),
		payrollProvider: attr('string'),

		newPayFrequency: attr('string'),
		firstPayrollDate: attr('string'),
		payEmployeesArrear: attr('string'),
		arrearDays: attr('string'),

		principalFirstName: attr('string'),
		principalLastName: attr('string'),
		principalEmail: attr('string'),
		principalSocialSecurity: attr('string'),
		principalSocialSecurityEnc: attr('string'),
		principalDob: attr('string'),

		previousPayroll: attr('string'),
		previousProvider: attr('string'),

		getPayFrequeny: attr('string'),
		getPreviousPayrollProvider: attr('string'),
		getPEOProvider: attr('string'),

		spreadsheetUrl: attr('string'),
		bulkUploadStatus: attr('string'),
		spreadsheetParsingStatusData: attr('string'),
		useBulk: attr('boolean'),

		stateTaxAccountNumber: attr('string'),

		nyPromptTaxAccessCode: attr('string'),
		tristateNJStateTaxID: attr('string'),
		tristateCTStateTaxID: attr('string'),
		tristateCTStateTaxAccountNumber: attr('string'),
		eddIndustry: attr('string'),
		authorizationSignature: attr('string'),

		// fake field
		refresh: attr('number'),

		hasStarted: attr('boolean'),
		status: attr('string'),
		inFilingOut: Ember.computed.equal('status', 'filling-out'),
		inIncomepleteEmailed: Ember.computed.equal('status', 'incomplete-emailed'),
		inIncomepleteSkipEmail: Ember.computed.equal('status', 'incomplete-email-skipped'),
		inPreSubmit: Ember.computed.or('inFilingOut', 'inIncomepleteEmailed', 'inIncomepleteSkipEmail'),
		inWaitingForEmployees: Ember.computed.equal('status', 'waiting-for-employees'),
		inWaitingOnProvider: function () {
			var state = this.get('status');
			return state == 'ready-to-send' || state == 'waiting-on-provider';
		}.property('status'),
		inApproved: Ember.computed.equal('status', 'approved'),
		remainingEmployeesCount: attr('number'),
		isBasicInfoComplete: attr('boolean'),
		isLegalAddressComplete: attr('boolean'),
		isLocationsComplete: attr('boolean'),
		isBankInfoComplete: attr('boolean'),
		isPrincipalInfoComplete: attr('boolean'),
		isPayrollFrequencyComplete: attr('boolean'),
		isPreviousPayrollComplete: attr('boolean'),
		isStateTaxInfoComplete: attr('boolean'),
		isEmployeesComplete: attr('boolean'),
		isEmploymentInfoComplete: attr('boolean'),
		isBulkUploadComplete: attr('boolean'),
		isBulkValidationComplete: attr('boolean'),
		isBulkEmailComplete: attr('boolean'),
		hasNotEmailed: attr('boolean'),
		isComplete: attr('boolean')
	});

	App.PayrollSwitch = DS.Model.extend({
		company: DS.belongsTo('App.Company'),

		status: attr('string'),
		previousProvider: attr('string'),
		newProvider: attr('string'),
		principalFirstName: attr('string'),
		principalLastName: attr('string'),
		principalEmail: attr('string'),
		principalSocialSecurity: attr('string'),
		principalDob: attr('string'),
		terminateDate: attr('string'),
		effectiveDate: attr('string'),
		hasSetup: attr('raw'),
		payFrequency: attr('string'),
		firstPayrollDate: attr('string'),
		arrearDays: attr('number'),

		priorPayrollUsername: attr('string'),
		priorPayrollPassword: attr('string'),
		priorPayrollPin: attr('string'),
		payrollUsername: attr('string'),
		payrollPassword: attr('string'),
		payrollPin: attr('string'),

		previousProviderName: attr('string'),
		newProviderName: attr('string'),
		isSupport: attr('boolean'),
		// ADP Run to Intuit fields
		isPrincipalinfoComplete: attr('boolean'),
		isPayrollinfoComplete: attr('boolean'),
		isPriorCredentialComplete: attr('boolean'),

		isBasicComplete: attr('boolean'),
		isProviderComplete: attr('boolean'),
		isCredentialComplete: attr('boolean'),
		isComplete: attr('boolean'),

		employeeCount: attr('number'),
		stateCount: attr('number'),

		isPaychex: Ember.computed.equal('newProvider', 'PX')
	});

	App.Smp = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: attr('string'),
		payrollProvider: attr('string'),
		bulkUpload: DS.belongsTo('App.BulkUpload'),
		bulkUploadUrl: attr('string'), //deprecated
		blockPeriod: attr('string'),

		hasStarted: attr('boolean'),
		isActive: attr('boolean'),
		isNative: attr('boolean'),
		syncType: attr('string'),
		isFileSync: attr('boolean'),
		isPayrollReports: attr('boolean'),

		isBasicCompanyInfoComplete: attr('boolean'),
		isCompanyLocationsComplete: attr('boolean'),
		isAddEmployeesComplete: attr('boolean'),
		isBulkValidationComplete: attr('boolean'),
		isBulkEmailComplete: attr('boolean'),
		badModCount: attr('number'),

		// Pay Connect Fields
		isFirstTimeOnPC: attr('boolean'),
		isUsingPayConnect: attr('boolean'),
		payConnectAutomationLevel: attr('string'),
		payConnectSyncSetupStatus: attr('string'),
		isDeductionInfoComplete: attr('boolean'),
		errorDiagnostics: attr('raw'),
	});

	App.SmpRun = DS.Model.extend({
		companyPaySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		isOpen: attr('boolean'),
		startDate: attr('string'),
		endDate: attr('string'),
		checkDate: attr('string'),
		currentRunDay: attr('string'),

		newHireReportUrl: attr('string', { defaultValue: null }),
		newHireReportCreateOn: attr('string', { defaultValue: "" }),
		eeModReportUrl: attr('string', { defaultValue: null }),
		eeModReportCreateOn: attr('string', { defaultValue: "" }),
		eeTerminationReportUrl: attr('string', { defaultValue: null }),
		eeTerminationReportCreateOn: attr('string', { defaultValue: "" }),
		eeDeductionsReportUrl: attr('string', { defaultValue: null }),
		eeDeductionsReportCreateOn: attr('string', { defaultValue: "" }),
		taReportUrl: attr('string', { defaultValue: null }),
		taReportCreateOn: attr('string', { defaultValue: "" }),
		fullReportUrl: attr('string', { defaultValue: null }),
		fullReportCreateOn: attr('string', { defaultValue: "" }),
		missingPayrollIdEmployeeIds: attr('string', { defaultValue: "" }),
		terminatedEmployeeIds: attr('string', { defaultValue: "" }),
		nonPushableEmployeeModificationDetails: attr('string', { defaultValue: "[]" }),

		_getDateRangeString: function (formatStr) {
			var initialFormat = 'MM/DD/YYYY';
			var formatStr = formatStr || 'MMM. D, YYYY';

			return moment(this.get('startDate'), initialFormat).format(formatStr) + " - " +
				moment(this.get('endDate'), initialFormat).format(formatStr);
		},

		dateRangeString: function () {
			return this._getDateRangeString();
		}.property("startDate", "endDate"),

		dateRangeStringShort: function () {
			return this._getDateRangeString('MM/DD');
		}.property("startDate", "endDate"),


		newHireCount: attr('number'),
		terminationCount: attr('number'),
		terminatedEmployeeModificationCount: attr('number'),
		modificationCount: attr('number'),
		deductionCount: attr('number'),
		taCount: attr('number'),

		haveNewHires: Ember.computed.gt('newHireCount', 0),
		haveTerminations: Ember.computed.gt('terminationCount', 0),
		haveTerminatedEmployeeModifications: Ember.computed.gt('terminatedEmployeeModificationCount', 0),
		haveModifications: Ember.computed.gt('modificationCount', 0),
		haveDeductions: Ember.computed.gt('deductionCount', 0),
		haveTa: Ember.computed.gt('taCount', 0),

		//TODO: Programatically do this later
		newHireReportCreateOnHuman: function () {
			return this._dateToHumanReadable("newHireReportCreateOn");
		}.property("newHireReportCreateOn"),
		eeModReportCreateOnHuman: function () {
			return this._dateToHumanReadable("eeModReportCreateOn");
		}.property("eeModReportCreateOn"),
		eeTerminationReportCreateOnHuman: function () {
			return this._dateToHumanReadable("eeTerminationReportCreateOn");
		}.property("eeTerminationReportCreateOn"),
		eeDeductionsReportCreateOnHuman: function () {
			return this._dateToHumanReadable("eeDeductionsReportCreateOn");
		}.property("eeDeductionsReportCreateOn"),
		taReportCreateOnHuman: function () {
			return this._dateToHumanReadable("taReportCreateOn");
		}.property("taReportCreateOn"),
		fullReportCreateOnHuman: function () {
			return this._dateToHumanReadable("fullReportCreateOn");
		}.property("fullReportCreateOn"),
		_dateToHumanReadable: function (propKey) {
			return moment(this.get(propKey)).format("MMM. DD, YYYY");
		},
		startDateValue: function () {
			return (new Date(this.get('startDate'))).valueOf();
		}.property('startDate')
	});

	App.EmployeeBadRecords = DS.Model.extend({
		companyId: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		resolved: attr('boolean'),
		key: attr('string'),
		verbose_key: attr('string'),
		pushType: attr('string'),
		reason: attr('string')
	});

	App.PayrollAction = DS.Model.extend({
		termAction: DS.belongsTo('App.EmployeeTerminationAction'),
		isAckedByAdmin: attr('boolean'),
	});

	App.ZenefitsContacts = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		type: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		fullName: attr('string'),
		email: attr('string'),
	});

	App.StateTax = DS.Model.extend(zen._isStateMixin, {
		company: DS.belongsTo('App.Company'),
		state: attr('string'),
		isActive: attr('boolean'),
		taxAccountNumber: attr('string'),
		taxDepositSchedule: attr('string'),
		hasTaxAccountNumber: attr('raw'),
		suiNumber: attr('string'),
		suiRate: attr('string'),
		suiDepositSchedule: attr('string'),
		hasSUINumber: attr('raw'),
		otherAccountNumber: attr('string'),
		otherRate: attr('string'),
		hasOtherAccountNumber: attr('raw'),
		eddIndustry: attr('string'),
		isTaxAccountNumberComplete: attr('boolean'),
		isSUINumberComplete: attr('boolean'),
		isOtherAccountNumberComplete: attr('boolean'),
		isComplete: attr('boolean'),
		isSupported: attr('boolean')
	});

	App.BulkUpload = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: attr('string'),
		type: attr('string'),
		fileUrl: attr('string'),

		message: attr('string'),
		warning: attr('string'),
		errors: attr('string'),
		duplicates: attr('string'),
		content: attr('string'),
		changes: attr('string'),
		updateOn: attr('date'),

		emptyStatus: Ember.computed.none('status'),
		isDone: Ember.computed.equal('status', 'Done'),
		isSuccess: function () {
			var state = this.get('status');
			return (state == 'Success' || state == 'Processing');
		}.property('status'),
		isError: Ember.computed.equal('status', 'Error'),
		isFail: Ember.computed.equal('status', 'Fail'),
		haveError: function () {
			return this.get('errors') != null;
		}.property('errors'),
		haveDup: function () {
			return this.get('duplicates') != null;
		}.property('duplicates'),
		haveWarning: function () {
			return this.get('warning') != null;
		}.property('warning'),
		fileErrors: function () {
			var parseError = null;
			if (this.get('isError') && this.get('haveError')) {
				parseError = JSON.parse(this.get('errors'));
				parseError.forEach(function (e) {
					e.fields = e.fields.map(function (f) {
						var errorMessage = zen.SPREADSHEET_COLUMN_TO_ERROR[f];
						return errorMessage ? errorMessage : f;
					});
				});
			}
			return parseError;
		}.property('errors'),
		duplicateUsers: function () {
			if (this.get('isError') && this.get('haveDup')) {
				return JSON.parse(this.get('duplicates'));
			}
		}.property('duplicates'),
		fileContents: function () {
			if (this.get('isSuccess')) {
				return JSON.parse(this.get('content'));
			}
		}.property('content'),
		changeLogs: function () {
			if (this.get('isSuccess')) {
				return JSON.parse(this.get('changes'));
			}
			return null;
		}.property('changes')
	});

	App.ConsultantContract = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		name: attr('string'),
		title: attr('string'),
		intro: attr('string'),
		terms: attr('string'),
		services: attr('string'),
		term: attr('string'),
		fees: attr('string'),
		hasServices: function () {
			// NB can be "" (which means it's present but doesn't have default content)
			return this.get('services') != null;
		}.property('services'),
		hasTerm: function () {
			// NB can be "" (which means it's present but doesn't have default content)
			return this.get('term') != null;
		}.property('term'),
		hasFees: function () {
			// NB can be "" (which means it's present but doesn't have default content)
			return this.get('fees') != null;
		}.property('fees'),
		uploadUrl: attr('string'),
		isActive: attr('boolean'),
		askForAddress: attr('boolean'),
		isTemplatized: attr('boolean'),
		previewUrl: Ember.computed.prefix('/preview/template/consultant_contract/', 'id')
	});



	App.InstantiatedConsultantContract = DS.Model.extend({
		consultant_contract: DS.belongsTo('App.ConsultantContract'),
		contractor: DS.belongsTo('App.Contractor'),
		// required
		effectiveDate: attr('string'),
		services: attr('string'),
		term: attr('string'),
		fees: attr('string'),
		// generated
		html: attr('string'),
		pdf: attr('string'),
		// signature
		signature: attr('string'),
		signatureName: attr('string'),
		signatureDate: attr('string'),
		// computed
		isUnsigned: function () {
			return !this.get('signature') || !this.get('signatureName');
		}.property('signature', 'signatureName'),
		effectiveDateFormatted: function () {
			return zen.longDate(this.get('effectiveDate'));
		}.property('effectiveDate'),
	});

	App.IpAgreement = DS.Model.extend({
		name: attr('string'),
		title: attr('string'),
		intro: attr('string'),
		terms: attr('string'),
		exhibits: attr('string'),
		hasConflicts: attr('boolean'),
		hasCompanies: attr('boolean'),
		hasInnovations: attr('boolean'),
		askForAddress: attr('boolean'),
		uploadUrl: attr('string'),
		isActive: attr('boolean'),
		isTemplatized: attr('boolean'),
		previewUrl: Ember.computed.prefix('/preview/template/ip_agreement/', 'id')
	});

	App.InstantiatedIpAgreement = DS.Model.extend({
		ip_agreement: DS.belongsTo('App.IpAgreement'),
		contractor: DS.belongsTo('App.Contractor'),
		// optional
		conflicts: attr('string'),
		innovations: attr('string'),
		companies: attr('string'),
		// generated
		html: attr('string'),
		pdf: attr('string'),
		// signature
		signature: attr('string'),
		signatureName: attr('string'),
		signatureDate: attr('string'),
		// computed
		isUnsigned: function () {
			return !this.get('signature') || !this.get('signatureName');
		}.property('signature', 'signatureName'),
		effectiveDateFormatted: function () {
			return zen.longDate(this.get('effectiveDate'));
		}.property('effectiveDate'),
	});

	App.Contractor = DS.Model.extend({
		// required
		email: attr('string'),
		name: attr('string'),
		// filled by contractor
		legalName: attr('string'),
		taxId: attr('string'),
		newTaxIdTearSheet: attr('string'),
		entityType: attr('string'),
		llcClassification: attr('string'),
		identification: attr('string'),
		address: attr('string'),
		city: attr('string'),
		state: attr('string'),
		country: attr('string'),
		zip: attr('string'),
		phone: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountNumberHuman: attr('string'),
		bankRoutingNumberHuman: attr('string'),
		taxIdHuman: attr('string'),
		bankAccountType: attr('string'),
		authSignature: attr('string'),
		authSignatureName: attr('string'),
		w9Permission: attr('boolean'),
		w9Signature: attr('string'),
		w9SignatureName: attr('string'),
		previousPaymentFailureReason: attr('string'),
		// server side computed properties
		hasUnsignedContracts: attr('boolean'),
		isInformationComplete: attr('boolean'),
		isBasicInfoComplete: attr('boolean'),
		isBankInfoComplete: attr('boolean'),
		isExemptionsComplete: attr('boolean'),
		isW9Complete: attr('boolean'),
		total2018PayAmount: attr('number'),
		isZenefits1099File: attr('boolean'),
		isRegistered: attr('boolean'),
		willFile1099ThroughZenefits2018: attr('boolean'),
		userToken: attr('string'),
		// relationships
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		contracts: DS.hasMany('App.InstantiatedConsultantContract'),
		ipAgreements: DS.hasMany('App.InstantiatedIpAgreement'),
		// soft delete
		isActive: attr('boolean'),
		// This corresponds to the python variable isDeleted
		//   because that is a reserved variable in Ember model that causes
		//   save() to send a delete request to the api
		//   See http://emberjs.com/api/data/classes/DS.Model.html#property_isDeleted
		softDeleted: attr('boolean'),
		// w9
		w9Url: attr('string'),
		fatcaCodes: attr('string'),
		exemptPayeeCodes: attr('string'),
		fatcaAndWhExempt: attr('string'),
		// for 1099 filings
		additionalPayments: attr('number'),
		additional2014Payments: attr('number'),
		additional2015Payments: attr('number'),
		additional2016Payments: attr('number'),
		additional2017Payments: attr('number'),
		additional2018Payments: attr('number'),

		// 1099 tax forms url
		misc1099Url2015: attr('string'),
		misc1099Url2016: attr('string'),
		misc1099Url2017: attr('string'),
		misc1099Url2018: attr('string'),

		// Aliases to make this model to be inline with Employee model.
		fullName: Ember.computed.alias('name'),
		first_name: Ember.computed.alias('name'),
		last_name: '',

		// computed properties
		hasMinimalFields: function () {
			return this.get('name') && this.get('email');
		}.property('name', 'email'),
		// Disabled for performance reasons
		//	payments: DS.hasMany('App.ContractorPayment'),
		//	lastPayment: function() {
		//		return this.get('payments.lastObject');
		//	}.property('payments.lastObject'),
		lastPayment: DS.belongsTo('App.ContractorPayment'),
		isComplete: function () {
			if (this.get('country') == "United States") {
				return this.get('isBasicInfoComplete') && this.get('isBankInfoComplete') && this.get('isW9Complete');
			}
			return this.get('isBasicInfoComplete');
		}.property('country', 'isBasicInfoComplete', 'isBankInfoComplete'),
		isMigrated: function () {
			return this.get('employee.type') == 'RE';
		}.property('employee.type'),
		isBusiness: function () {
			var type = this.get('entityType');
			var id;
			if (type == 'SP' || type == 'LC') {
				id = this.get('identification');
			}
			return (id && (id == 'EIN')) || (type && type != 'IN' && type != 'SP' && type != 'LC');
		}.property('entityType', 'identification'),
		entityTypeHuman: function () {
			return {
				"IN": "Individual",
				"CO": "Corporation",
				"LC": "LLC",
				"PA": "Partnership",
				"SC": "S-Corporation",
				"SP": "Sole Proprietorship",
				"LP": "LLP"
			}[this.get('entityType')] || "N/A";
		}.property('entityType'),
		bankAccountTypeHuman: function () {
			var bankAccountType = this.get('bankAccountType');
			if (bankAccountType == 'C') {
				return "Checking";
			}
			else if (bankAccountType == 'S') {
				return "Savings";
			}
			else if (bankAccountType == 'B') {
				return "Business";
			}
			return bankAccountType;
		}.property('bankAccountType'),
		// hack for contractor/pay page
		payAmount: attr('string'),
		payAmountHuman: function () {
			var amount = this.get('payAmount');
			if (!amount) { return null; }
			return Number(amount.replace(/[,$]/g, '')).toLocaleString();
		}.property('payAmount'),
		// 2018 fields
		aggregate2018Pay: function () {
			return Number(this.get('total2018PayAmount')) + Number(this.get('additional2018Payments'));
		}.property('total2018PayAmount', 'additional2018Payments'),
		is2018ContractorPaid: function () {
			return (Number(this.get('total2018PayAmount')) > 0.00);
		}.property('total2018PayAmount'),
		isInternational: function () {
			return this.get('country') && !this.get('isCountryUsa');
		}.property('country', 'isCountryUsa'),
		isCountryUsa: Ember.computed.equal('country', 'United States'),
		isEligibleForPayments: Ember.computed.and('isRegistered', 'isComplete', 'isCountryUsa'),
		hasResentRegistrationEmail: false,
		resendRegistrationEmailPromise: Ember.RSVP.resolve(),
		showResendRegistrationLink: function () {
			return !this.get('isRegistered') && !this.get('hasResentRegistrationEmail');
		}.property('isRegistered', 'hasResentRegistrationEmail')
	});

	App.ContractorSettingsBankAccount = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		bankRoutingNumber: attr('string'),
		bankAccountNumber: attr('string'),
		bankAccountVerification_id: attr('number'),
		isVerified: attr('boolean'),
		isVerificationWaiting: attr('boolean'),
		hasVerificationFailed: attr('boolean'),
	});

	App.ContractorSettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		bankAccount: DS.belongsTo('App.ContractorSettingsBankAccount'),
		authSignature: attr('string'),
		authSignatureName: attr('string'),
		authSignatureDate: attr('string'),
		fromSignature: attr('string'),
		fromSignatureTitle: attr('string'),
		fromSignatureName: attr('string'),
		paymentsStatus: attr('string'),
		earliestPaymentDate: attr('string'),
		earliestFastTrack: attr('string'),
		hireContractorOnly: attr('boolean'),
		isPypOnboarded: attr('boolean'),
		is1099ThruZenefits2015: attr('boolean'),
		is1099ThruZenefits2016: attr('boolean'),
		is1099ThruZenefits2017: attr('boolean'),
		is1099ThruZenefits2018: attr('boolean'),
		isBlacklisted: Ember.computed.equal('paymentsStatus', 'blacklisted'),
		showTestTransactionNotice: Ember.computed.equal('paymentsStatus', 'test_required'),
		isWaitingForTestTransaction: Ember.computed.equal('paymentsStatus', 'test_waiting'),
		didTestTransactionFail: Ember.computed.equal('paymentsStatus', 'test_failed'),
		isMissingBank: Ember.computed.equal('paymentsStatus', 'missing_bank'),
		isPaymentsApproved: Ember.computed.equal('paymentsStatus', 'approved'),
		usingDefaultTemplates: attr('boolean'),
		hasSetupTemplates: attr('boolean'),
		hasAuthorizedACH: attr('boolean'),
		canFastTrack: attr('boolean'),
		debitWindow: attr('number'),
		transactionWindow: attr('number'),
		daysToFirstFastTrack: attr('number'),
		fastTrackAllowance: attr('number'),
		paymentLimit: attr('number'),
		payDate: attr('string'),
		totalSentThisWeek: attr('number'),
		totalProcessing: attr('number'),
		hasCompanyVerificationRequest: attr('boolean'),
		isCompanyVerified: attr('boolean'),
		isCompanyPendingVerification: attr('boolean'),
		isBankAccountVerified: attr('boolean'),
		hasBankAccountVerification: attr('boolean'),
		hasBankAccounts: attr('boolean'),
		needsBankAccountVerification: function () {
			return !this.get('hasBankAccountVerification');
		}.property('hasBankAccountVerification'),
		remainingDistribution: function () {
			return this.get('paymentLimit') - this.get('totalProcessing') - this.get('totalSentThisWeek');
		}.property('paymentLimit', 'totalSentThisWeek', 'totalProcessing'),
		canSendPayments: function () {
			return !this.get('hireContractorOnly') && this.get('isCompanyVerified');
		}.property('hireContractorOnly', 'isCompanyVerified'),
	});

	App.ContractorPayment = DS.Model.extend({
		contractor: DS.belongsTo('App.Contractor'),
		created: attr('string'),
		amount: attr('number'),
		status: attr('string'),
		dueDate: attr('string'),
		companyDebitedDate: attr('string'),
		paymentDeployDate: attr('string'),
		amountHuman: function () {
			var amount = this.get('amount');
			if (Ember.isNone(amount)) { return 0; }
			return Number(amount).toLocaleString();
		}.property('amount'),
		createdFormattedDate: function () {
			return formatDate(zen.parseISODateTime(this.get('created')));
		}.property('created'),
		statusHuman: function () {
			var status = this.get('status');
			if (status == "E") {
				return "Returned";
			}
			if (status == "N") {
				return "Not Sent";
			}
			if (status == "F") {
				return "Failed";
			}
			if (status == "C") {
				return "Completed";
			}
			if (status == "S" || status == "A") {
				return "Sent";
			}
			if (status == "X") {
				return "Cancelled";
			}
			if (status == "P") {
				if (this.get('isDeployed')) {
					return "Processing";
				}
				return "Pending";
			}
			return status;
		}.property('status'),
		consoleUrl: Ember.computed.prefix('/console/contractor_payment/', 'id'),
		isDeployed: function () {
			var deploy = this.get('deployDate');
			if (deploy) {
				return moment().diff(moment(deploy), 'days', true) >= 0;
			}
		}.property('deployDate'),
		isFastTrack: attr('boolean'),
		isPending: function () {
			return this.get('isProcessing') && !this.get('isDeployed');
		}.property('isProcessing', 'isDeployed'),
		isProcessing: Ember.computed.equal('status', 'P'),
		isSent: Ember.computed.equal('status', 'S'),
		isFailed: function () {
			var status = this.get('status');
			if (status == 'F' || status == 'E' || status == 'N') {
				return true;
			} else {
				return false;
			}
		}.property('status'),
		isComplete: Ember.computed.equal('status', 'C'),
		reasonForFailure: attr('string'),
		deployDate: function () {
			if (this.get('isFastTrack')) {
				return this.get('created');
			}

			return this.get('paymentDeployDate');
		}.property('isFastTrack', 'paymentDeployDate'),
		isNotPastDebit: function () {
			var debit = this.get('companyDebitedDate');
			if (debit) {
				return moment(debit).diff(moment(), 'days', true) >= 0;
			}
		}.property('companyDebitedDate')
	});

	App.OfferLetter = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		name: attr('string'),
		nameNumber: attr('string'),
		uploadUrl: attr('string'),
		template: attr('string'),
		options: attr('string'),
		isTemplatized: attr('boolean'),
		isActive: attr('boolean'),
		templateHash: attr('string'),
		isDefault: attr('boolean'),
		isUsingNewHtmlEditor: attr('boolean'),
		lastEdited: attr('string'),
		isInternational: function () {
			var parsedOptions = Ember.$.parseJSON(this.get('options'));
			return parsedOptions && parsedOptions.isInternational == true && this.get('isTemplatized');
		}.property('options', 'isTemplatized'),
		isReady: function () {
			return this.get('isActive') && this.get('isTemplatized');
		}.property('isActive', 'isTemplatized'),
		isEditable: function () {
			return this.get('isUsingNewHtmlEditor') && !this.get('isDefault');
		}.property('isUsingNewHtmlEditor', 'isDefault'),
		previewUrl: Ember.computed.prefix('/preview/template/offer_letter/', 'id'),
		previewOrUploadLink: function () {
			if (this.get('isTemplatized')) {
				return this.get('previewUrl');
			}
			return this.get('uploadUrl');
		}.property('isTemplatized', 'uploadUrl', 'previewUrl'),
		previewOrUploadText: function () {
			if (this.get('isTemplatized')) {
				return "Preview";
			}
			return "Uploaded Document";
		}.property('isTemplatized', 'uploadUrl', 'previewUrl'),
	});

	App.BaseCustomField = DS.Model.extend({
		rank: attr('number'),
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		name: attr('string'),
		employerDuringHiring: attr('boolean'),
		employeeDuringOnboarding: attr('boolean'),
		fieldCompleterEmployee: attr('boolean'),
		canEmployeeViewField: attr('boolean'),
		canEmployeeEditField: attr('boolean'),
		canManagerViewField: attr('boolean'),
		isFieldRequired: attr('boolean'),
		isSystemField: attr('boolean'),
		isSensitive: attr('boolean'),
		constraintType: attr('string'),

		constraintTypeSelection: function () {
			var constraintType = this.get('constraintType');
			if (constraintType) {
				return constraintType;
			}
		}.property('constraintType'),
	});

	App.CustomFieldSection = App.BaseCustomField.extend({
		customFields: DS.hasMany('App.CustomField'),
		customFieldSectionConstraints: DS.hasMany('App.CustomFieldSectionConstraint'),
		hasCustomFields: Ember.computed.gt('customFields.length', 0),
		hasFieldCompleterEmployeeCustomFields: Ember.computed.gt('arrangedFieldCompleterEmployeeCustomFields.length', 0),
		arrangedFieldCompleterEmployeeCustomFields: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['rank'],
				content: this.get('customFields').filterProperty('fieldCompleterEmployee'),
			});
		}.property('customFields'),
		arrangedCustomFields: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['rank'],
				content: this.get('customFields'),
			});
		}.property('customFields'),
		hasEEFillCustomFields: function () {
			return this.get('customFields').filterProperty('fieldCompleterEmployee', true).get('length') > 0;
		}.property('customFields'),
		isFirst: function () {
			var ranks = this.get('onboardingSettings.customFieldSections').mapProperty('rank');
			var minRank = Math.min.apply(null, ranks);
			return minRank == this.get('rank');
		}.property('rank', 'onboardingSettings.customFieldSections', 'onboardingSettings.customFieldSections.@each'),
		isLast: function () {
			var ranks = this.get('onboardingSettings.customFieldSections').mapProperty('rank');
			var maxRank = Math.max.apply(null, ranks);
			return maxRank == this.get('rank');
		}.property('rank', 'onboardingSettings.customFieldSections', 'onboardingSettings.customFieldSections.@each'),
	});

	App.CustomField = App.BaseCustomField.extend({
		isTagged: attr('boolean'),
		customFieldSection: DS.belongsTo('App.CustomFieldSection'),
		type: attr('string'),
		helpText: attr('string'),
		helpUrl: attr('string'),
		helpUrlMedia: attr('string'),
		mediaFileType: attr('string'),
		customFieldSelectChoices: DS.hasMany('App.CustomFieldSelectChoices'),
		customFieldConstraints: DS.hasMany('App.CustomFieldConstraint'),
		selectContent: function () {
			return this.get('customFieldSelectChoices').map(function (choice) {
				return {
					label: choice.get('name'),
					value: choice.get('name'),
					id: choice.get('id'),
				};
			}).sort(function (a, b) {
				return a.id - b.id;
			});
		}.property('customFieldSelectChoices.@each.name', 'customFieldSelectChoices.@each.id'),
		helpTextFormatted: function () {
			if (!this.get('helpText')) {
				return '';
			}

			var helpText = this.get('helpText').replace(/\n/g, "<br/>");
			var helpUrl = this.get('helpUrl');
			if (helpUrl) {
				helpText += '&nbsp;&nbsp; <a href="' + helpUrl + '" target="_blank" rel="noopener noreferrer">Download File</a>';
			}
			return helpText;
		}.property('helpText', 'helpUrl'),
		typeHuman: function () {
			switch (this.get('type')) {
				case 'S': return "Single-line text";
				case 'M': return "Multi-line text";
				case 'D': return "Date";
				case 'N': return "Number";
				case 'SS': return "Select";
				case 'F': return "File";
				case 'B': return "Yes or No";
				case 'SI': return "Signature";
			}
		}.property('type'),
		isSelectType: Ember.computed.equal('type', 'SS'),
		isMultiLineType: Ember.computed.equal('type', 'M'),
		isDateType: Ember.computed.equal('type', 'D'),
		isNumberType: Ember.computed.equal('type', 'N'),
		isTextFieldType: Ember.computed.equal('type', 'S'),
		isFileType: Ember.computed.equal('type', 'F'),
		isBooleanType: Ember.computed.equal('type', 'B'),
		isSigType: Ember.computed.equal('type', 'SI'),
		isMediaFileVideo: function () {
			var pre = "";
			if (this.get('mediaFileType') && this.get('mediaFileType.length') > 5) {
				pre = this.get('mediaFileType').substring(0, 5);
			}
			return pre == "video";
		}.property('mediaFileType'),
		isMediaFileImage: function () {
			var pre = "";
			if (this.get('mediaFileType') && this.get('mediaFileType.length') > 5) {
				pre = this.get('mediaFileType').substring(0, 5);
			}
			return pre == "image";
		}.property('mediaFileType'),
		isMediaFilePdf: function () {
			var end = "";
			if (this.get('mediaFileType') && this.get('mediaFileType.length') > 3) {
				end = this.get('mediaFileType').substring(this.get('mediaFileType.length') - 3);
			}
			return end == "pdf";
		}.property('mediaFileType'),
		isFirst: function () {
			var customFieldSection = this.get('customFieldSection');
			if (customFieldSection) {
				var ranks = customFieldSection.get('customFields').mapProperty('rank');
				var minRank = Math.min.apply(null, ranks);
				return minRank == this.get('rank');
			}
			var ranks = null;
			var customField = this.get('onboardingSettings.customFields');
			if(customField){
				ranks = customField.filterProperty('customFieldSection', null).mapProperty('rank');
			}
			var minRank = Math.min.apply(null, ranks);
			return minRank == this.get('rank');
		}.property('customFieldSection', 'rank'),
		isLast: function () {
			var customFieldSection = this.get('customFieldSection');
			if (customFieldSection) {
				var ranks = customFieldSection.get('customFields').mapProperty('rank');
				var maxRank = Math.max.apply(null, ranks);
				return maxRank == this.get('rank');
			}
			var ranks = null;
			var customField = this.get('onboardingSettings.customFields');
			if(customField){
				ranks = customField.filterProperty('customFieldSection', null).mapProperty('rank');
			}
			var maxRank = Math.max.apply(null, ranks);
			return maxRank == this.get('rank');
		}.property('customFieldSection', 'rank'),
	});

	App.CustomFieldSelectChoices = DS.Model.extend({
		customField: DS.belongsTo('App.CustomField'),
		tieredContribution: DS.belongsTo('App.TieredContributionScheme'),
		tieredWaitingPeriods: DS.hasMany('App.TieredWaitingPeriod'),
		name: attr('string'),

		sortedTieredWaitingPeriods: Ember.computed(function () {
			var data = Ember.A();
			allLocs.forEach(function (line) {
				this.get('tieredWaitingPeriods').forEach(function (waitingPeriodItem) {
					if (waitingPeriodItem.get('lineOfCoverage') == line.value) {
						data.push(waitingPeriodItem);
					}
				}.bind(this));
			}.bind(this));
			return data;
		}).property('tieredWaitingPeriods.@each.waitingPeriod'),
	});

	App.CustomFieldValue = DS.Model.extend({
		customField: DS.belongsTo('App.CustomField'),
		employee: DS.belongsTo('App.AllEmployee', { inverse: null }), // TODO: verify inverse works
		value: attr('string'),
		previousValues: attr('raw'),
		isNotFiltered: attr('boolean'),
		isFieldRequired: Ember.computed.or('customField.isFieldRequired'),
		isFieldOptional: Ember.computed.not('isFieldRequired'),
		valueOrLink: function () {
			var valueOrLink = this.get('value');
			if (this.get('customField.isFileType')) {
				valueOrLink = "<a href=\"" + valueOrLink + "\" target=\"_blank\">" + "Download File" + "</a>";
			}
			return valueOrLink;
		}.property('value'),
		customFieldLoaded: function () {
			return this.get('customField.isLoaded');
		}.property('customField.isLoaded'),
	});

	App.CustomFieldRequest = DS.Model.extend({
		customFieldValues: DS.hasMany('App.CustomFieldValue'),
		employee: DS.belongsTo('App.AllEmployee'),
		isRequiredCustomFieldsComplete: attr('boolean'),
		arrangedCustomFieldValues: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['customField.rank'],
				content: this.get('customFieldValues'),
			});
		}.property('customFieldValues'),
	});

	App.CustomFieldConstraintPropertiesMixin = Ember.Mixin.create({
		nameIsDepartment: Ember.computed.equal('name', 'dept'),
		nameIsLocation: Ember.computed.equal('name', 'loc'),
		nameIsEmpType: Ember.computed.equal('name', 'empType'),
		nameIsCompType: Ember.computed.equal('name', 'compType'),
		nameIsJobTitle: Ember.computed.equal('name', 'title'),
		nameIsPositions: Ember.computed.equal('name', 'position'),
	});

	App.BaseCustomFieldConstraint = DS.Model.extend(App.CustomFieldConstraintPropertiesMixin, {
		name: attr('string'),
		value: attr('string'),
		equality: attr('string'),
	});

	App.CustomFieldConstraint = App.BaseCustomFieldConstraint.extend({
		customField: DS.belongsTo('App.CustomField'),
	});

	App.CustomFieldSectionConstraint = App.BaseCustomFieldConstraint.extend({
		customFieldSection: DS.belongsTo('App.CustomFieldSection'),
	});

	App.CustomFieldConstraintObject = Ember.Object.extend(App.CustomFieldConstraintPropertiesMixin, {
		name: null,
		value: null,
		equality: null,
	});

	App.CustomFieldRuleObject = Ember.Object.extend({
		record: null,
		isActive: null,
		recordNumber: null,

		recordName: Ember.computed.or('record.name'),
	});

	App.Bill = DS.Model.extend({
		company: DS.belongsTo('company'),
		ach: DS.belongsTo('ach'),
		amount: attr('string'),
		billDate: attr('date'),
		invoicePdf: attr('string'),
		billDateDisplay: Ember.computed.prettyDate('billDate', 'MM/DD/YYYY'),
		hasPaymentFailed: attr('hasPaymentFailed'),
		billAmountHuman: function () {
			var amount = this.get('amount');
			return prettyCurrency(amount, true, 2);
		}.property('amount'),
		wasPaymentSuccessful: function () {
			return this.get('hasPaymentFailed') ? 'No' : 'Yes';
		}.property('hasPaymentFailed')
	});

	App.BillComponents = DS.Model.extend({
		company: DS.belongsTo('company'),
		bill: DS.belongsTo('bill'),
		product: DS.belongsTo('product'),
		ruleParams: attr('string'),
		modelVariables: attr('string'),
		amount: attr('string'),
		billDate: attr('date'),
		numOfMonths: attr('number'),
		ruleParamsArray: function () {
			var ruleParams = this.get('ruleParams');
			if (!ruleParams) {
				return [['', '']];
			}
			var parsedString = ruleParams.replace(/\(/g, '[').replace(/\)/g, ']').replace(/'/g, '"');
			var optionsArray = JSON.parse(parsedString);
			var choices = Ember.A();

			for (var i = 0; i < optionsArray.length; i++) {
				choices.pushObject(Ember.Object.create({ 'name': optionsArray[i][0], 'valueInside': optionsArray[i][1] }));
			}
			return choices;
		}.property('ruleParams'),
		modelVariablesArray: function () {
			var modelVariables = this.get('modelVariables');
			if (!modelVariables) {
				return [['', '']];
			}
			var parsedString = modelVariables.replace(/\(/g, '[').replace(/\)/g, ']').replace(/'/g, '"');
			var optionsArray = JSON.parse(parsedString);
			var choices = Ember.A();

			for (var i = 0; i < optionsArray.length; i++) {
				choices.pushObject(Ember.Object.create({ 'name': optionsArray[i][0], 'valueInside': optionsArray[i][1] }));
			}
			return choices;
		}.property('modelVariables'),
	});

	App.BillAdjustment = DS.Model.extend({
		company: DS.belongsTo('company'),
		bill: DS.belongsTo('bill'),
		product: DS.belongsTo('product'),
		amount: attr('string'),
		notes: attr('string'),
		date: attr('date')
	});

	App.BillingPromotion = DS.Model.extend({
		type: attr('string'),
		product: DS.belongsTo('product'),
		campaignName: attr('string'),
		couponName: attr('string'),
		discountPercent: attr('string'),
		discountNumMonths: attr('number'),
		redeemByDate: attr('string'),
		maxRedemptions: attr('string'),
	});


	App.BillingOffer = DS.Model.extend({
		creationType: DS.attr('string'),
		expiresDate: DS.attr('string'),
		product: DS.belongsTo('App.Product'),
		billingStartDate: DS.attr('string'),
		company: DS.belongsTo('App.FilterCompany'),
		freeMonths: DS.attr('number'),
		customDiscountPercent: DS.attr('string'),
		acceptedDate: DS.attr('string'),
		totalContractLength: DS.attr('number'),
		realizedDate: DS.attr('string'),
		effectiveDiscountPercent: DS.attr('string'),
	});


	App.ClientBillingOffer = DS.Model.extend({
		expiresDate: DS.attr('string'),
		product: DS.belongsTo('App.Product'),
		billingStartDate: DS.attr('string'),
		company: DS.belongsTo('App.Company'),
		freeMonths: DS.attr('number'),
		customDiscountPercent: DS.attr('string'),
		expiresDate: DS.attr('string'),
		acceptedDate: DS.attr('string'),
		totalContractLength: DS.attr('number'),
		realizedDate: DS.attr('string'),
		effectiveDiscountPercent: DS.attr('string'),
		baseCostPerEmployee: DS.attr('string'),
		isAccepted: DS.attr('boolean'),
		estimatedEmployees: DS.attr('number'),
		creationType: DS.attr('string'),
		isRealized: Ember.computed('realizedDate', function () {
			return !!this.get('realizedDate');
		}),
	});



	App.PromotionRedemption = DS.Model.extend({
		company: DS.belongsTo('App.FilterCompany'),
		promotion: DS.belongsTo('App.BillingPromotion'),
		isApplied: attr('boolean'),
		productName: attr('string'),
	});

	App.FilterCompany = DS.Model.extend({
		id: attr('number'),
		name: attr('string'),
	});

	App.Product = DS.Model.extend({
		name: attr('string'),
		billingVariablesCallbackMethod: attr('string'),
		pricingModel: attr('string'),
		nameDisplay: function () {
			switch (this.get('name')) {
				case 'CON': return "Consulting Fee";
				case 'CONTRACTOR_PAYMENT': return "Contractor Payment Fee";
				case 'SO': return "Stock Options";
				case 'FSA': return "Flexible Spending Accounts";
				case 'HRA': return "Health Reimbursement Accounts";
				case 'HR_ADVISOR': return "HR Advisory Services";
				case 'HSA': return "Health Savings Accounts";
				case 'COM_BEN': return "Commuter Benefits";
				case 'FSA-ANNUAL': return "Flexible Spending Accounts Annual Fee";
				case 'HRA-ANNUAL': return "Health Reimbursement Accounts Annual Fee";
				case 'TA': return "Time and Attendance";
				case 'ZP': return "Gusto (ZenPayroll)";
				case 'BGC': return "Background Checks";
				case 'VER': return "Verifications";
				case 'ZENEFITS_PAYROLL_CRE_VER_FEE': return brandName+' Payroll Credit Verification Fee';
				case 'ZENEFITS_PAYROLL': return "Payroll";
				case 'BI': return "Business Insurance";
				case 'COBRA': return "Cobra";
				case 'OPERATIONS': return "Operations";
				case 'BRONZE': return 'Bronze';
				case 'SILVER': return 'Silver';
				case 'GOLD': return 'Gold';
				case 'PLATINUM': return 'Platinum';
				case 'BRONZE_BACKFILLED': return 'Bronze (backfill)';
				case 'SILVER_BACKFILLED': return 'Silver (backfill)';
				case 'GOLD_BACKFILLED': return 'Gold (backfill)';
				case 'BRONZE_PLUS': return 'Bronze Plus';
				case 'SILVER_PLUS': return 'Silver Plus';
				case 'GOLD_PLUS': return 'Gold Plus';
			}
		}.property('name'),
		isOfferable: Ember.computed.in('name', [
			'BRONZE',
			'SILVER',
			'GOLD',
			'PLATINUM',
		]),
	});

	App.AccountLock = DS.Model.extend({
		company_id: attr('string'),
		isLocked: attr('boolean'),
		reason: attr('string'),
		lockDate: attr('string'),
		data: attr('string'),
		dataJson: Ember.computed('data', function () {
			return Ember.isEmpty(this.get('data')) ? {} : JSON.parse(this.get('data'));
		}),
	});


	App.IpAssignment = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		associatedDocuments: DS.hasMany('App.AssociatedDocument'),
		templateBlob_id: DS.attr('number'),
		name: attr('string'),
		nameNumber: attr('string'),
		uploadUrl: attr('string'),
		template: attr('string'),
		isTemplatized: attr('boolean'),
		isActive: attr('boolean'),
		askForInventions: attr('boolean'),
		askForFormerAgreements: attr('boolean'),
		askForProprietaryInformation: attr('boolean'),
		askForAddress: attr('boolean'),
		isDefault: attr('boolean'),
		isUsingNewHtmlEditor: attr('boolean'),
		previewUrl: Ember.computed.prefix('/preview/template/ip_assignment/', 'id'),
		options: attr('string'),
		isTemplateComplete: attr('boolean'),
		type: attr('string'),
		isUploaded: Ember.computed.or('template', 'uploadUrl'),
		lastEdited: attr('string'),
		previewOrUploadLink: function () {
			if (this.get('type') == 'A' && this.get('isTemplatized')) {
				return this.get('previewUrl');
			}

			if (this.get('type') == 'R') {
				return this.get('previewUrl');
			}

			return this.get('uploadUrl');
		}.property('isTemplatized', 'uploadUrl', 'previewUrl'),
		previewOrUploadText: function () {
			if (this.get('isTemplatized')) {
				return "Preview";
			}
			return "Uploaded Document";
		}.property('isTemplatized', 'uploadUrl', 'previewUrl'),
		associatedDocumentsInfo: function () {
			var associatedDocuments = this.get('associatedDocuments').filterBy('isActive', true);
			var length = associatedDocuments.get('length');
			if (length == 0) {
				return "";
			}
			var names = associatedDocuments.reduce(function (names, doc) {
				return names + doc.get('name') + ', ';
			}, "");
			return "Includes " + associatedDocuments.get('length') + " attachments (" + names.slice(0, -2) + ")";
		}.property('associatedDocuments.@each.name', 'associatedDocuments.@each.isActive'),
		isEditable: function () {
			return this.get('isUsingNewHtmlEditor') && !this.get('isDefault');
		}.property('isUsingNewHtmlEditor', 'isDefault'),
	});

	App.AssociatedDocument = DS.Model.extend({
		name: attr('string'),
		fileName: attr('string'),
		uploadUrl: attr('string'),
		description: attr('string'),
		ipAssignment: DS.belongsTo('App.IpAssignment'),
		isActive: attr('boolean'),
	});

	App.EmployeeHandbook = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		name: attr('string'),
		uploadUrl: attr('string'),
		template: attr('string'),
		isTemplatized: attr('boolean'),
		isUploaded: function () {
			return !!this.get('template') || !!this.get('uploadUrl');
		}.property('template', 'uploadUrl'),
		previewUrl: Ember.computed.prefix('/preview/template/employee_handbook/', 'id'),
	});

	App.WelcomeEmail = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		original: attr('string'),
		template: attr('string'),
		attachments: attr('string'),
		isTemplatized: attr('boolean')
	});

	App.NonExemptNotice = DS.Model.extend({
		onboardingSettings: DS.belongsTo('App.OnboardingSettings'),
		latestWorkersCompInfoRequest: DS.belongsTo('App.BusinessInsuranceInfoRequest'),
		dbas: attr('string'),
		providesPayRates: attr('string'),
		isAllProvidesPayRates: Ember.computed.equal('providesPayRates', 'ALL'),
		offerLetterIncludeAllPayRate: Ember.computed.oneWay('isAllProvidesPayRates'),
		isSomeProvidesPayRates: Ember.computed.equal('providesPayRates', 'SOME'),
		isNoneProvidesPayRates: Ember.computed.equal('providesPayRates', 'NONE'),
		offerLetterIncludePayRate: Ember.computed.or('isAllProvidesPayRates', 'isSomeProvidesPayRates'),
		payday: attr('string'),
		payFrequency: attr('string'),
		payFrequencyHuman: function () {
			switch (this.get('payFrequency')) {
				case 'SM': return "Semi-monthly";
				case 'BW': return "Bi-weekly";
				case 'Mo': return "Monthly";
				case 'We': return "Weekly";
			}
		}.property('payFrequency'),
		workersCompCarrierName: attr('string'),
		workersCompAddress: attr('string'),
		workersCompPhone: attr('string'),
		workersCompPolicyNumber: attr('string'),
		isSetup: attr('boolean'),
		reissueNoticeInBulk: attr('boolean'),
		isHiringCAEmployee: attr('boolean'),
		caSickLeavePolicy: attr('string'),
		caSickLeavePolicyHuman: function () {
			switch (this.get('caSickLeavePolicy')) {
				case 'P1': return "Employees accrue the absolute minimum paid sick leave time that California state law requires. ";
				case 'P2': return "Employees accrue paid sick leave time according to company's policy which meets or exceeds California state law requirements. ";
				case 'P3': return "Employees receive at least 3 work days of paid sick leave time front-loaded every 12 months.";
				case 'P4': return "Employees are exempt.";
			}
		}.property('caSickLeavePolicy'),
		isCaSickLeavePolicy4: Ember.computed.equal('caSickLeavePolicy', 'P4'),
		caSickLeavePolicyExemptReason: attr('string'),
		caSickLeavePolicyExemptReasonHuman: function () {
			switch (this.get('caSickLeavePolicyExemptReason')) {
				case 'R1': return "Employees are covered by a valid collective bargaining agreement (CBA) that meets or exceeds California state law requirements. (Section 245.5, subsection 1)";
				case 'R2': return "Employees are in the construction industry and are covered by a valid collective bargaining agreement (CBA) that meets or exceeds California state law requirements. (Section 245.5, subsection 2)";
				case 'R3': return "Employees are in-home support services (IHSS) employees. (Section 245.5, subsection 3)";
				case 'R4': return "Employees are airline flight deck or cabin crew employees under the Railway Labor Act and are provided with paid time off that meets or exceeds California state law requirements. (Section 245.5, subsection 4)";
			}
		}.property('caSickLeavePolicyExemptReason'),
		hasCaActiveNonExemptEmployees: attr('boolean'),
		isSelfInsured: attr('boolean'),
		staffingBusiness: attr('boolean'),
		includeInOnboarding: attr('boolean'),
		selfInsuredCertificateNum: attr('string'),
		progressIndex: attr('number'),
		progress: attr('string'),
		progressJSON: function () {
			var progress = this.get('progress');
			return progress ? JSON.parse(progress) : {};
		}.property('progress'),
	});

	App.Department = DS.Model.extend({
		name: attr('string'),
		company: DS.belongsTo('App.Company'),
		triggeredBy: attr('string'),
		wfCoCode: attr('string'),
		isEditing: false,
		readyToSync: function () {
			return this.get('itSubserviceGroup') && (this.get('currentGroup') == this.get('itSubserviceGroup'));
		}.property('currentGroup', 'itSubserviceGroup'),
		itSubserviceGroup: function (key, value) {
			if (value == undefined) {
				// GETTER
				return this.get('currentGroup');
			}

			// SETTER
			this.set('hiddenGroup', value);
		}.property('currentGroup'),
		allAvailableGroups: function () {
			var groups = this.get('company.companyItServices.firstObject.companyItSubservices') || [];
			return groups.filter(function (group) {
				return (group.get('associatedDepartment.id') == this.get('id') || !group.get('associatedDepartment.id'));
			});
		}.property('company.companyItServices.firstObject.companyItSubservices.@each.associatedDepartment'),
		currentGroup: function () {
			// HACK HACK
			var groups = this.get('company.companyItServices.firstObject.companyItSubservices') || [];
			var department_id = this.get('id');
			var group = groups.find(function (group) {
				return (group.get('associatedDepartment.id') == department_id);
			});

			return group;
		}.property('company.companyItServices.firstObject.companyItSubservices.@each'),
	});

	App.ActionNotification = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		actionType: attr('string'),
		notificationTitle: attr('string'),
		notificationActionText: attr('string'),
		notificationLinkText: attr('string'),
		name: attr('string'),
		signature: attr('string'),
		adminComment: attr('string'),
		completionDate: attr('string'),
		destRoute: attr('string'),
		comment: attr('string'),
		pdfUrl: attr('string'),
		status: attr('string'),
		isPending: function () {
			if (this.get('status') && this.get('status') == 'P') {
				return true;
			} else {
				return false;
			}
		}.property('status'),
		isAccepted: function () {
			if (this.get('status') && this.get('status') == 'A') {
				return true;
			} else {
				return false;
			}
		}.property('status'),
		isDenied: function () {
			if (this.get('status') && this.get('status') == 'R') {
				return true;
			} else {
				return false;
			}
		}.property('status'),
		isReject: function () {
			if (this.get('status') && this.get('status') == 'T') {
				return true;
			} else {
				return false;
			}
		}.property('status'),
		// given the current way destRoute is used, we are unable to link to
		// specific page that requires a model. As a work around, we just want
		// to create a link that points to destRoute instead of using link-to
		useRawURL: function () {
			var destRoute = this.get('destRoute') || '';
			return destRoute.indexOf('payroll') > 0;
		}.property('destRoute')
	});

	App.CompanyLocation = DS.Model.extend({
		name: attr('string'),
		nameOrAddress: function () {
			var name = this.get('name');
			if (name) {
				return name;
			}

			return this.get('city') + ', ' + this.get('state');
		}.property('name', 'city', 'state'),
		address: function () {
			return this.get('city') + ', ' + this.get('state') + ' ' + this.get('zip');
		}.property('city', 'state', 'zip'),
		street1: attr('string'),
		street2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		countryHumanReadable: attr('string'),
		country: attr('string'),
		zip: attr('string'),
		triggeredBy: attr('string'),
		wfCoCode: attr('string'),
		isHeadquarters: attr('boolean'),
		isVirtual: attr('boolean'),
		company: DS.belongsTo('App.Company'),
		eeoId: attr('string'),
		eeoFiledLastYear: attr('raw'),
		officialName: attr('string'),
		officialTitle: attr('string'),
		officialPhone: attr('string'),
		officialEmail: attr('string'),
		naicsCode: attr('string'),
		getTimeZone: attr('string'),
		isAddressComplete: attr('boolean'),
		isOfficialComplete: attr('boolean'),
		isEeoReportComplete: attr('boolean'),
		inactive: attr('boolean'),
		phone: attr('string'),
		intlAddress: DS.belongsTo('App.Address'),
		intlCompanyTax: DS.belongsTo('App.CompanyFederalTax'),
		readyToSync: function () {
			return this.get('itSubserviceGroup') && (this.get('currentGroup') == this.get('itSubserviceGroup'));
		}.property('currentGroup', 'itSubserviceGroup'),
		itSubserviceGroup: function (key, value) {
			if (value == undefined) {
				// GETTER
				return this.get('currentGroup');
			}

			// SETTER
			this.set('hiddenGroup', value);
		}.property('currentGroup'),
		stateDisplay: function () {
			if (this.get('state')) {
				return STATE_ABBREV_TO_NAME[this.get('state')];
			}
			return this.get('state');
		}.property('state'),
		isInternational: function () {
			if (this.get('country') && this.get('country') != 'US') {
				return true;
			} else {
				return false;
			}
		}.property('country'),
		allAvailableGroups: function () {
			var groups = this.get('company.companyItServices.firstObject.companyItSubservices') || [];
			return groups.filter(function (group) {
				return group.get('associatedLocation.id') == this.get('id') || !group.get('associatedLocation.id');
			});
		}.property('company.companyItServices.firstObject.companyItSubservices.@each.associatedLocation'),
		currentGroup: function () {
			// HACK HACK
			var groups = this.get('company.companyItServices.firstObject.companyItSubservices') || [];
			var location_id = this.get('id');
			var group = groups.find(function (group) {
				return group.get('associatedLocation.id') == location_id;
			});

			return group;
		}.property('company.companyItServices.firstObject.companyItSubservices.@each'),

	});

	App.SignedIpAssignment = DS.Model.extend({
		newHire: DS.belongsTo('App.NewHire'),
		attachments: DS.hasMany('App.AssociatedDocument'),
		isComplete: attr('boolean'),
		isSelected: attr('boolean'),
		ipAssignmentHtmlUrl: attr('string'),
		ipAssignmentPdfUrl: attr('string'),
		ipAssignmentSig: DS.belongsTo('App.Signature'),
		askForInventions: attr('boolean'),
		askForFormerAgreements: attr('boolean'),
		askForProprietaryInformation: attr('boolean'),
		askForAddress: attr('boolean'),
		hasInventions: attr('raw'),
		hasFormerAgreements: attr('raw'),
		hasPropietaryInformation: attr('raw'),
		isTemplatized: attr('boolean'),
		inventions: attr('string'),
		formerAgreements: attr('string'),
		propietaryInformation: attr('string'),
		address: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		name: attr('string'),
		options: attr('string'),
		isSigned: Ember.computed.bool('ipAssignmentSig'),
		isSealed: attr('boolean'),
		isSignedOrSelected: Ember.computed.or('ipAssignmentSig', 'isSelected'),
		base: DS.belongsTo('App.IpAssignment'),
	});

	App.DrivingInfo = DS.Model.extend({
		newHire: DS.belongsTo('App.NewHire'),
		drivingLicenseNumber: attr('string'),
		drivingLicenseState: attr('string'),
		hasDriversLicense: attr('boolean'),
		isComplete: Ember.computed(
			'drivingLicenseNumber',
			'drivingLicenseState',
			'hasDriversLicense',
			function () {
				return !this.get('hasDriversLicense') ||
					this.get('drivingLicenseNumber') &&
					this.get('drivingLicenseState');
			}
		),
	});

	App.Bgcheck = DS.Model.extend({
		newHire: DS.belongsTo('App.NewHire'),
		bgcSsnTrace: DS.belongsTo('App.BgcSsnTrace'),
		bgcSexOffenderReport: DS.belongsTo('App.BgcSexOffenderReport'),
		bgcCriminalReports: DS.hasMany('App.BgcCriminalReport'),
		bgcMotorVehicleReport: DS.belongsTo('App.BgcMotorVehicleReport'),
		pdfUrl: attr('string'),
		detailedStatus: attr('string'),
		htmlUrl: attr('string'),
		status: attr('string'),
		isComplete: attr('boolean'),
		time_submitted: attr('string'),
		sex_offender_report_loaded: Ember.computed.bool('bgcSexOffenderReport'),
		sex_offender_report_loading: Ember.computed.empty('bgcSexOffenderReport'),
		ssn_trace_loaded: Ember.computed.bool('bgcSsnTrace'),
		ssn_trace_loading: Ember.computed.empty('bgcSsnTrace'),
		criminal_report_loaded: Ember.computed.bool('bgcCriminalReports'),
		criminal_report_loading: Ember.computed.empty('bgcCriminalReports'),
		motor_vehicle_report_loaded: Ember.computed.bool('bgcMotorVehicleReport'),
		motor_vehicle_report_loading: Ember.computed.empty('bgcMotorVehicleReport'),
		secretPdfUrl: function () {
			return "/background_check/" + this.get('id');
		}.property('id'),
		displayStatus: function () {
			var status = this.get('status');
			if (status === 'clear' || status === 'consider' || status === 'short_circuited') {
				return 'Complete';
			} else if (status === 'canceled') {
				return 'Canceled';
			} else if (status === 'suspended') {
				return 'Checkr has requested documentation directly from this employee';
			} else {
				return 'Results pending';
			}
		}.property('status'),
	});

	App.BgcSsnTrace = DS.Model.extend({
		parent_model: DS.belongsTo('App.Bgcheck'),
		bgcSsnAddresses: DS.hasMany('App.BgcSsnAddress'),
		status: attr('string'),
		state: attr('string'),
		turnaround_time: attr('number'),
		report_type: attr('string'),
		report_id: attr('string'),
		county: attr('string'),
		isClear: Ember.computed.equal('status', 'clear'),
	});

	App.BgcSsnAddress = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcSsnTrace'),
		county: attr('string'),
		city: attr('string'),
		state: attr('string'),
		street: attr('string'),
		to_date: attr('string'),
		from_date: attr('string'),
		zipcode: attr('string'),
		unit: attr('string')
	});

	App.BgcSexOffenderReport = DS.Model.extend({
		parent_model: DS.belongsTo('App.NewHire'),
		sexOffenderRecords: DS.hasMany('App.BgcSexOffenderRecord'),
		status: attr('string'),
		turnaround_time: attr('number'),
		report_type: attr('string'),
		report_id: attr('string'),
		isClear: Ember.computed.equal('status', 'clear')

	});

	App.BgcSexOffenderRecord = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcSexOffenderReport'),
		sexOffenderCharges: DS.hasMany('App.BgcSexOffenderCharge'),
		weight: attr('number'),
		photo: attr('string'),
		hair_color: attr('string'),
		height: attr('number'),
		eye_color: attr('string'),
		registration_start: attr('string'),
		registry: attr('string'),
		street: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zipcode: attr('string'),
		charges: attr('string'),
		gender: attr('string'),
		age: attr('string'),
		race: attr('string'),
		registration_end: attr('string'),

		dob: attr('string'),
		court_of_record: attr('string'),
		full_name: attr('string'),
		case_number: attr('string'),
		arresting_agency: attr('string'),
		file_date: attr('string'),
		additional_info: attr('string'),
		court_jurisdiction: attr('string')
	});


	App.BgcSexOffenderCharge = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcSexOffenderReport'),
		charge: attr('string'),
		victim: attr('string'),
		classification: attr('string'),
		disposition: attr('string'),
		disposition_date: attr('string'),
	});

	App.BgcCriminalReport = DS.Model.extend({
		parent_model: DS.belongsTo('App.Bgcheck'),
		bgcCriminalRecords: DS.hasMany('App.BgcCriminalRecord'),
		status: attr('string'),
		state: attr('string'),
		turnaround_time: attr('number'),
		report_type: attr('string'),
		report_id: attr('string'),
		county: attr('string'),
		isClear: Ember.computed.equal('status', 'clear'),
	});

	App.BgcCriminalRecord = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcCriminalRecord'),
		bgcCriminalCharges: DS.hasMany('App.BgcCriminalCharge'),
		dob: attr('string'),
		court_of_record: attr('string'),
		full_name: attr('string'),
		case_number: attr('string'),
		arresting_agency: attr('string'),
		file_date: attr('string'),
		additional_info: attr('string'),
		court_jurisdiction: attr('string')
	});


	App.BgcCriminalCharge = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcCriminalReport'),
		charge_type: attr('string'),
		classification: attr('string'),
		sentence: attr('string'),
		deposition: attr('string'),
		charge_date: attr('string'),
		notes: attr('string'),
		charge_id: attr('string'),
		additional_info: attr('string'),
		plaintiff: attr('string'),
		charge: attr('string'),
		disposition_date: attr('string'),
		offense_date: attr('string'),
		disposition: attr('string'),
		sentence_date: attr('string'),
		deposition_date: attr('string'),
		probation_status: attr('string'),
		defendant: attr('string'),
		arrest_date: attr('string')
	});

	App.BgcMotorVehicleReport = DS.Model.extend({
		parent_model: DS.belongsTo('App.Bgcheck'),
		bgcMotorVehicleAccidents: DS.hasMany('App.BgcMotorVehicleAccident'),
		bgcMotorVehicleViolations: DS.hasMany('App.BgcMotorVehicleViolation'),
		status: attr('string'),
		turnaround_time: attr('number'),
		report_type: attr('string'),
		report_id: attr('string'),
		license_number: attr('string'),
		license_state: attr('string'),
		license_status: attr('string'),
		license_type: attr('string'),
		license_class: attr('string'),
		expiration_date: attr('string'),
		issued_date: attr('string'),
		restrictions: attr('string'),
		isClear: Ember.computed.equal('status', 'clear'),
	});

	App.BgcMotorVehicleAccident = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcMotorVehicleReport'),
		description: attr('string'),
		fine_amount: attr('string'),
		jurisdiction: attr('string'),
		license_plate: attr('string'),
		county: attr('string'),
		reinstatement_date: attr('string'),
		enforcing_agency: attr('string'),
		action_taken: attr('string'),
		issue_date: attr('string'),
		severity: attr('string'),
		violation_number: attr('string'),
		points: attr('number'),
		vehicle_speed: attr('number'),
		ticket_number: attr('string'),
		order_number: attr('string')
	});

	App.BgcMotorVehicleViolation = DS.Model.extend({
		parent_model: DS.belongsTo('App.BgcMotorVehicleReport'),
		conviction_date: attr('string'),
		issue_date: attr('string'),
		description: attr('string'),
		county: attr('string'),
		points: attr('number'),
		ticket_number: attr('string'),
		type: attr('string')
	});


	App.NewHire = DS.Model.extend({
		version_id: attr('number'),
		employee: DS.belongsTo('App.AllEmployee', { inverse: 'newHires' }),
		company: DS.belongsTo('App.Company'),
		offer_letter: DS.belongsTo('App.OfferLetter'),
		// NOTE: Used heavily in hiring flow
		agreements: DS.hasMany('App.SignedIpAssignment'),
		// ipAssignments and agreements should be loaded before using these.
		// NOTE: Used heavily in hiring flow
		ipAgreements: Ember.computed.filterByProperty('agreements', 'base.type', 'A'),
		// NOTE: Used heavily in hiring flow
		ehAgreements: Ember.computed.filterByProperty('agreements', 'base.type', 'E'),
		// NOTE: Used in contractor hiring flow
		crAgreements: Ember.computed.filterByProperty('agreements', 'base.type', 'C'),
		employerFlow: DS.belongsTo('App.Flow'),
		employeeFlow: DS.belongsTo('App.Flow'),
		signingCompleteTimestamp: attr('string'),
		documentsSentTimestamp: attr('string'),
		status: attr('string'),
		otherNames: attr('string'),
		companyPto: DS.belongsTo('App.EmployerPto'),
		stockOptionType: attr('string'),
		numStockOptions: attr('string'),
		percentStockOptions: attr('string'),
		employeeOptionType: attr('string'),
		grantDate: attr('string'),
		sendOfferLetter: attr('boolean'),
		offerExpiryDate: attr('string'),
		bonusPercent: attr('string'),
		duties: attr('string'),
		bonusAbsolute: attr('string'),
		relocationBonus: attr('string'),
		ptoWeekly: attr('string'),
		additionalTerms: attr('string'),
		isFromAddExistingFlow: attr('boolean'),
		fromBulkRequest: attr('boolean'),
		isDoingOfferLetter: attr('boolean'),
		isDoingAgreements: attr('boolean'),
		isDoingEmployeeHandbook: attr('boolean'),
		isDoingBackgroundCheck: attr('boolean'),
		isDoingOnboarding: attr('boolean'),
		isDoingTax: attr('boolean'),
		isDoingCustomFields: attr('boolean'),
		isDoingEligibility: attr('boolean'),
		isDoingEligibilityProofUpload: attr('boolean'),
		skipEmailingOfferLetter: attr('raw'),
		offerLetterHtmlUrl: attr('string'),
		offerLetterPdfUrl: attr('string'),
		offerLetterSig: DS.belongsTo('App.Signature'),
		isOfferLetterSealed: attr('boolean'),
		employeeHandbookSig: DS.belongsTo('App.Signature'),
		drivingInfo: DS.belongsTo('App.DrivingInfo'),
		bgcEmployeeAgreement: DS.belongsTo('App.BgcEmployeeAgreement'),
		bgcheck: DS.belongsTo('App.Bgcheck'),
		isBackgroundCheckPremium: attr('boolean'),
		isBackgroundCheckIncludingMotorVehicleReport: attr('boolean'),
		isCheckrAndPersonalInfoComplete: Ember.computed.and('isCheckrInfoComplete', 'isPersonalInfoComplete'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		hasAgreedBackgroundCheckDisclosure: Ember.computed.equal('bgcEmployeeAgreement.hasAgreedDisclosure', true),
		isBackgroundCheckAuthorized: Ember.computed.and('bgcEmployeeAgreement', 'bgcEmployeeAgreement.backgroundEmployeeSignatureName', 'bgcEmployeeAgreement.backgroundEmployeeSignature'),
		//EJS: The below properties are generally deprecated, going forward use flow variables:
		isMotorVechicleBackgroundCheckComplete: function () {
			return !this.get('isBackgroundCheckIncludingMotorVehicleReport') || this.get('drivingInfo.isComplete');
		}.property('isBackgroundCheckIncludingMotorVehicleReport', 'drivingInfo.isComplete'),
		isCheckrInfoCompleteOld: Ember.computed.and('employee.hasMinimalFields', 'employee.dob', 'employee.hasSufficientSSNInfo', 'employee.phone', 'employee.zip', 'isMotorVechicleBackgroundCheckComplete'),
		//\EJS
		checkShouldHaveBeenSentToCheckr: Ember.computed.and('isBackgroundCheckAuthorized', 'isCheckrInfoCompleteOld'),
		isDoingOnboardingOrCustomFields: Ember.computed.or('isDoingOnboarding', 'isDoingCustomFields'),
		isHourly: Ember.computed.equal('employee.currentEmployment.compType', 'H'),
		isSalary: Ember.computed.equal('employee.currentEmployment.compType', 'S'),
		isFulltime: Ember.computed.equal('employee.currentEmployment.employmentType', 'FT'),
		isTP: Ember.computed.equal('employee.currentEmployment.employmentType', 'TP'),
		isIN: Ember.computed.equal('employee.currentEmployment.employmentType', 'IN'),
		isTemp: Ember.computed.or('isTP', 'isIN'),
		isParttime: Ember.computed.equal('employee.currentEmployment.employmentType', 'PT'),
		hasStockOptions: function () { return !Ember.isEmpty(this.get('stockOptionType')); }.property('stockOptionType'),
		showOfferLetterFields: Ember.computed.oneWay('isDoingOfferLetter'),
		showDocumentFields: Ember.computed.or('isDoingOfferLetter', 'isDoingAgreements'),
		is401kStatusComplete: Ember.computed.equal('employee.company.company401kEnrollment.status', 'complete'),
		isFsaStatusComplete: Ember.computed.equal('employee.company.fsa.status', 'complete'),
		showBenefitsInfo: Ember.computed.gt('employee.company.healthEnrollments.length', 0),
		showProvisioningPage: Ember.computed.and('isHiringBasicComplete', 'employee.currentEmployment.hireDate'),
		//Holding spots for promises from simiple function calls
		resendRegistrationEmailPromise: Ember.RSVP.resolve(),

		// Show Payroll settings only when the employee will be added to payroll
		// and the company is using workforce or paychex
		isUnderWFCustomProtocolBetaTest: Ember.computed.equal('employee.company.payroll.isUnderWFCustomProtocolBetaTest', true),
		isPayrollProviderPaychex: Ember.computed.equal('employee.company.payrollProvider', 'PX'),

		// Compute it in this model to have access to state of selected work location
		isPXCompClassValid: function () {
			var state = this.get('employee.location.state');
			var compClasses = this.get('employee.company.compClasses').filterProperty('isActive', true).filterProperty('state', state);
			if (compClasses && compClasses.length > 0) {
				return true;
			}

			return false;

		}.property('employee.location.state', 'employee.company.compClasses', 'employee.company.compClasses.@each.isActive', 'employee.company.compClasses.@each.state'),

		isPayrollProviderPaylocity: Ember.computed.equal('employee.company.payrollProvider', 'PL'),

		isPayrollProviderParagon: Ember.computed.equal('employee.company.payrollProvider', 'PG'),

		isPayrollProviderIntuit: function () {
			var provider = this.get('employee.company.payrollProvider');
			return (provider == 'IN' || provider == 'MP' || provider == 'QB');
		}.property('employee.company.payrollProvider'),

		showPayrollSettings: function () {
			return this.get('showWFPayrollSettings') || this.get('showPXPayrollSettings') || this.get('showPLPayrollSettings') || this.get('showPGPayrollSettings') || this.get('showINPayrollSettings');
		}.property('showWFPayrollSettings', 'showPXPayrollSettings', 'showPLPayrollSettings', 'showPGPayrollSettings', 'showINPayrollSettings'),

		showWFPayrollSettings: function () {
			return this.get('isDoingOnboarding') && this.get('isHiringBasicComplete') && this.get('employee.company.payroll.showWFPayrollSettings');
		}.property('isDoingOnboarding', 'employee.company.payroll.showWFPayrollSettings', 'isHiringBasicComplete'),

		showPXPayrollSettings: function () {
			return this.get('isDoingOnboarding') && this.get('isHiringBasicComplete') && this.get('isPayrollProviderPaychex') && (this.get('employee.company.isPXComplete') || this.get('isPXCompClassValid'));
		}.property('isDoingOnboarding', 'isPayrollProviderPaychex', 'isHiringBasicComplete', 'employee.company.isPXComplete'),

		showINPayrollSettings: function () {
			return this.get('isDoingOnboarding') && this.get('isHiringBasicComplete') && this.get('isPayrollProviderIntuit') && this.get('employee.company.isIntuitComplete');
		}.property('isDoingOnboarding', 'isHiringBasicComplete', 'isPayrollProviderIntuit', 'employee.company.isIntuitComplete'),

		showPLPayrollSettings: function () {
			return this.get('isDoingOnboarding') && this.get('isHiringBasicComplete') && this.get('isPayrollProviderPaylocity') && this.get('employee.company.isPLComplete');
		}.property('isDoingOnboarding', 'isPayrollProviderPaylocity', 'isHiringBasicComplete', 'employee.company.isPLComplete'),

		showPGPayrollSettings: function () {
			return this.get('isDoingOnboarding') && this.get('isHiringBasicComplete') && this.get('isPayrollProviderParagon');
		}.property('isDoingOnboarding', 'isPayrollProviderParagon', 'isHiringBasicComplete'),
		// this is whether we have all the data
		isSigningAnyAgreement: Ember.computed.or('isDoingAgreements', 'isDoingOfferLetter', 'isDoingEmployeeHandbook'),
		isHiringInfoComplete: function () {
			return this.get('isHiringBasicComplete') &&
				(!this.get('showPayrollSettings') || this.get('isHiringPayrollSettingsComplete')) &&
				this.get('isHiringEmploymentInfoComplete') &&
				(!this.get('employee.company.installedITServices') || this.get('isHiringProvisioningComplete')) &&
				(!this.get('showBenefitsInfo') || this.get('isHiringBenefitsInfoComplete'));
		}.property('isHiringBasicComplete', 'isHiringComplianceFieldsComplete', 'isHiringEmploymentInfoComplete', 'isHiringPayrollSettingsComplete', 'isHiringBenefitsInfoComplete'),
		areOfferDetailsComplete: Ember.computed.or('isHiringInfoComplete', 'isHiringSent'),
		showEmployerSideEligibility: Ember.computed.not('employee.isInternational'),
		// this is whether the flow is completed (and the email sent)
		isHiringComplete: function () {
			return this.get('isHiringAllComplete') && this.get('isHiringSent');
		}.property('isHiringAllComplete', 'isHiringSent'),
		isHiringAllComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'complete'),
		isPhantom: function () {
			return ((!this.get('employee.first_name') || !this.get('employee.last_name') || !this.get('employee.email')) && this.get('status') == 'setting_up') || this.get('isDeleted');
		}.property('employee.first_name', 'employee.last_name', 'employee.email', 'status', 'isDeleted'),
		showInList: function () {
			return !this.get('isPhantom') && !this.get('isComplete');
		}.property('isPhantom', 'isComplete'),
		isHiringBasicComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'basicinfo'),
		isHiringJobDetailsComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'jobdetails'),
		isHiringProvisioningComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'provision'),
		isSomeOrRequiredProvisioningComplete: function () {
			if (this.get('employee.company.requireProvisioningInHiringAndTermination')) {
				return this.get('isHiringProvisioningComplete');
			} else {
				return (this.get('employee.itServiceUsers.content') || []).length;
			}
		}.property('isHiringProvisioningComplete', 'company.requireProvisioningInHiringAndTermination', 'employee.itServiceUsers'),
		isHiringComplianceFieldsComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'compliancefields'),
		isHiringEmploymentInfoComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'employment'),
		isHiringPayrollSettingsComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'payrollsettings'),
		isHiringCustomFieldsComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'customfields'),
		isHiringIntegrationsComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'integrations'),
		isHiringBenefitsInfoComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'benefitsinfo'),
		isHiringPreviewComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'preview'),
		isHiringNotificationComplete: Ember.computed.flowSectionIsComplete('employerFlow', 'notification'),
		doesHiringHaveBenefitsInfoSection: Ember.computed.flowSectionIsPresent('employerFlow', 'benefitsinfo'),
		doesHiringHaveCustomFieldsSection: Ember.computed.flowSectionIsPresent('employerFlow', 'customfields'),
		doesHiringHavePayrollSettingsSection: Ember.computed.flowSectionIsPresent('employerFlow', 'payrollsettings'),
		doesHiringHaveIntegrationsSection: Ember.computed.flowSectionIsPresent('employerFlow', 'integrations'),
		enableHiringBenefitsInfoLink: function () {
			return ((!this.get('employee.isEmployerHiringCustomFieldsPresent') || this.get('employee.isEmployerHiringCustomFieldsComplete')) &&
				this.get('isHiringEmploymentInfoComplete') &&
				this.get('isHiringBasicComplete'));
		}.property('isHiringComplianceFieldsComplete', 'isHiringPayrollSettingsComplete',
			'isHiringEmploymentInfoComplete', 'isHiringBasicComplete',
			'employee.isEmployerHiringCustomFieldsPresent', 'employee.isEmployerHiringCustomFieldsPresent'),
		isHiringSent: function () {
			return this.get('documentsSentTimestamp') || this.get('status') != 'setting_up';
		}.property('status'),
		isHiringSentButNotSigned: function () {
			return this.get('status') == 'no_action';
		}.property('status'),
		isInProcess: function () {
			return this.get('status') == 'no_action' || this.get('status') == 'signed';
		}.property('status'),
		isAfterStartDate: function () {
			var isAfterStartDate = false;
			var hireDate = this.get('employee.hireDate');
			var diff = moment().diff(hireDate, 'days');
			isAfterStartDate = diff > 0;
			return isAfterStartDate;
		}.property('employee.status', 'employee.hireDate'),
		isInPayroll: function () {
			return this.get('employee.isInPayroll');
		}.property('employee.isInPayroll'),
		// EEO (optional)
		eeoAsked: attr('boolean'),
		eeoJobCategory: function () {
			return this.get('employee.employeeEeo.eeoJobCategory');
		}.property('employee.employeeEeo.eeoJobCategory'),
		eeoRace: function () {
			return this.get('employee.employeeEeo.eeoRace');
		}.property('employee.employeeEeo.eeoRace'),
		// Non-exempt notice (only if setup and non-exempy)
		showNonExemptNotice: attr('boolean'),
		nonExemptNoticeSig: DS.belongsTo('App.Signature'),
		nonExemptNoticeUrl: attr('string'),

		// Eligibility
		eligibility: DS.belongsTo('App.EmployeeEligibility'),
		/*
		This function has to consider both old and revamped i9 flows.
		It must mimic the functionality of company_onboarding/models.py: NewHire.isEligibilityVerificationComplete
		That property isn't surfaced in API for performance reasons.
		 */
		isEligibilityVerificationComplete: function () {
			const NEW_HIRE_EMPLOYEE_FLOW_V2 = 'new_hire_employee_flow_v2';
			const V2_I9_SECTIONS = ['eligibility-part-1', 'eligibility-part-2', 'review-eligibility', 'upload'];
			if (this.get('employeeFlow.name') === NEW_HIRE_EMPLOYEE_FLOW_V2) {  // New V2 flow
				var allSectionsComplete = true;
				var mappedNames = this.get('employeeFlow.sections').mapBy('name');
				var intersection = Ember.EnumerableUtils.intersection;
				var hasI9RelatedSections = !!intersection(mappedNames, V2_I9_SECTIONS).length;
				if (hasI9RelatedSections) {
					this.get('employeeFlow.sections').forEach(function (section) {
						if (V2_I9_SECTIONS.indexOf(section.get('name')) > -1 && section.get('isComplete') === false) {
							allSectionsComplete = false;
						}
					})
				} else {
					allSectionsComplete = false;
				}
				return allSectionsComplete;
			} else {  // We must be using V1 flow
				return Ember.computed.flowSectionIsComplete('employeeFlow', 'eligibility');  // Re-use untouched old code
			}
		}.property('employeeFlow.name', 'employeeFlow.sections.@each.isComplete'),
		isDocumentVerificationComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'upload'),
		isContractorDoingOfferLetter: Ember.computed.flowSectionIsPresent('employeeFlow', 'contract'),
		isDoingFederalTax: Ember.computed.flowSectionIsPresent('employeeFlow', 'federaltax'),
		isContractorDoingTax: Ember.computed.flowSectionIsPresent('employeeFlow', 'exemptions'),
		isDoingPersonalInfo: Ember.computed.flowSectionIsPresent('employeeFlow', 'personal'),
		showIntegrations: Ember.computed.anyProperty('employerFlow.sections', 'name', 'integrations'),

		// links
		gbStarterChecklistUrl: function () {
			return '/forms/' + this.get('id') + '/gbStarterChecklist';
		}.property('id'),
		nlWageTaxFormUrl: function () {
			return '/forms/' + this.get('id') + '/nlWageTaxForm';
		}.property('id'),
		caTd1FederalUrl: function () {
			return '/forms/' + this.get('id') + '/caTd1Federal';
		}.property('id'),
		caTd1StateUrl: function () {
			return '/forms/' + this.get('id') + '/caTd1State';
		}.property('id'),
		// status
		isComplete: Ember.computed.equal('status', 'complete'),
		isPersonalInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'personal'),
		isCheckrInfoComplete: function () {
			var sections = this.get('employeeFlow.sections');
			if (!sections) {
				return this.get('isCheckrInfoCompleteOld');
			}
			else {
				return this.get('isPersonalInfoComplete');
			}
		}.property('employeeFlow', 'isCheckrInfoCompleteOld', 'isPersonalInfoComplete'),
		isEeoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'eeo'),
		isEmergencyContactsComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'emergency'),
		isEmployeeCustomFieldsComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'customfields'),
		isFederalTaxInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'federaltax'),
		isStateTaxInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'statetax'),
		isTaxInfoComplete: Ember.computed.and('isFederalTaxInfoComplete', 'isFederalTaxInfoComplete'),
		isBankInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'bank'),
		isDistributionInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'paycheckdistribution'),
		isBackgroundCheckSigned: Ember.computed.flowSectionIsComplete('employeeFlow', 'background'),
		isContractorDoingOfferLetterComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'contract'),
		isW9TaxInfoComplete: Ember.computed.flowSectionIsComplete('employeeFlow', 'previewcontractor'),
		commission: attr('string'),
		hasCommission: function () { return !Ember.isEmpty(this.get('commission')); }.property('commission'),
		isAgreementsComplete: function () {
			if (this.get('isDoingAgreements') == false) {
				return true;
			}
			return this.get('agreements').filterProperty('isSealed', false).length == 0;
		}.property('agreements.@each.isSealed', 'ipAgreements.@each.isSealed'),
		hasEligibilityProofs: function () {
			return this.get('eligibility.eligibilityDocuments.length') > 0;
		},
		hasAgreements: Ember.computed.gt('agreements.length', 0),
	});


	// ATS models

	App.AtsNewHire = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		fullName: function () {
			return this.get('firstName') + ' ' + this.get('lastName');
		}.property('firstName', 'lastName'),
		firstName: attr('string'),
		lastName: attr('string'),
		email: attr('string'),
		jobTitle: attr('string'),
		jobLocation: attr('string'),
		location: DS.belongsTo('App.CompanyLocation'),
		jobStartDate: attr('string'),
		department: attr('string'),
		salary: attr('number'),
		employee: DS.belongsTo('App.AllEmployee'),
		manager: DS.belongsTo('App.AllEmployee'),
		atsProvider: DS.belongsTo('App.AtsProvider'),
		applicantTrackingSystem: attr('string'),
		created: attr('string'),
		importedResumeUrl: attr('string'),
		isEmployee: true,
		hasMinimalFields: true,
		isAutoMatched: attr('boolean'),
		resumeImported: attr('boolean'),
	});

	App.AtsAdditionalDocuments = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		name: attr('string'),
		documentUrl: attr('string'),
		importedDocumentUrl: attr('string'),
	});

	App.AtsProvider = DS.Model.extend({
		name: attr('string'),
		displayName: attr('string'),
		url: attr('string'),
		quoteUrl: attr('string'),
		connectUrl: attr('string'),
		logoUrl: attr('string'),
		description: attr('string'),
	});

	App.CompanyAts = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		atsProvider: DS.belongsTo('App.AtsProvider'),
	});

	App.AtsSecretKey = DS.Model.extend({
		secretKey: attr('string'),
		company: DS.belongsTo('App.Company'),
	});


	App.EmergencyContacts = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		primaryContactName: attr('string'),
		primaryContactRelationship: attr('string'),
		primaryContactPhone1: attr('string'),
		primaryContactPhone2: attr('string'),
		secondaryContactName: attr('string'),
		secondaryContactRelationship: attr('string'),
		secondaryContactPhone1: attr('string'),
		secondaryContactPhone2: attr('string'),
	});

	App.PayrollCompanySettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		isUnderWFCustomProtocolBetaTest: attr('boolean'),
		showWFPayrollSettings: attr('boolean'),
		usesWfTimeAndAttendance: attr('boolean'),
		wfFileNumberAutoGen: attr('boolean'),
		wfPayrollCodeType: attr('number'),
		wfNewHireTemplates: attr('string'),
		wfPayClassCodes: attr('string'),
		wfBadgeNumberFormat: attr('string'),
		wfPreNotification: attr('number'),
		uniqueCompanyCode: attr('string'),
		wfHourlyCoCode: attr('string'),
		wfSalariedCoCode: attr('string'),
		wfPartTimeCoCode: attr('string'),
		wfFullTimeCoCode: attr('string'),
		customBadgeNumberRequired: attr('boolean'),
		customWfCompanyCodeRequired: attr('boolean'),
		syncWfJobTitle: attr('boolean'),
		syncWfBusinessUnit: attr('boolean'),
		syncWfLocation: attr('boolean'),
		syncWorkersComp: attr('boolean'),
		syncDepartment: attr('boolean'),
		departmentMapping: attr('string'),
		fieldAliases: DS.hasMany('App.CompanyPayrollFieldAlias'),
		fullSyncOptOut: attr('boolean'),
		prepopulateWfProposedFileNumber: attr('boolean'),
		wfDataConnectorAppSubscriptionStatus: attr('string'),
		showWFSyncActiveMessage: attr('boolean'),
		includeWfCoCodes: attr('string')
	});

	App.CompanyPayrollFieldAlias = DS.Model.extend({
		company_payroll: DS.belongsTo('App.PayrollCompanySettings'),
		company: DS.belongsTo('App.Company'),
		payrollName: attr('string'),
		zenefitsName: attr('string'),
		humanReadableName: attr('string'),
		isActive: attr('boolean'),
	});

	App.EmployeeTaxFields = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),

		GAstateDependentAllowance: attr('number'),
		GAstatePersonalAllowance: attr('number'),
		ILstateAdditionalAllowance: attr('number'),

		GAresidenceStateDependentAllowance: attr('number'),
		GAresidenceStatePersonalAllowance: attr('number'),
		ILresidenceStateAdditionalAllowance: attr('number'),

		LAstateNumberOfDependents: attr('number'),
		LAresidenceStateNumberOfDependents: attr('number'),
		VAstateAgeAndBlindnessExemptions: attr('number'),
		VAresidenceStateAgeAndBlindnessExemptions: attr('number'),

		NJstateWageChartLetter: attr('string'),
		NJresidenceStateWageChartLetter: attr('string'),

		INstateAdditionalDependentExemptions: attr('number'),
		INresidenceStateAdditionalDependentExemptions: attr('number'),

		INstateAdditionalDependentExemptionsFirstClaim: attr('number'),
		INresidenceStateAdditionalDependentExemptionsFirstClaim: attr('number'),

		INstateAdditionalCountyWithholdings: attr('number'),
		INresidenceStateAdditionalCountyWithholdings: attr('number'),

		WVstateOneEarnerWithholdAtLowerRate: attr('raw'),
		WVresidenceStateOneEarnerWithholdAtLowerRate: attr('raw'),

		MAstatePersonalBlindness: attr('raw'),
		MAresidenceStatePersonalBlindness: attr('raw'),

		MAstateSpouseBlindness: attr('raw'),
		MAresidenceStateSpouseBlindness: attr('raw'),

		MAstateFullTimeStudent: attr('raw'),
		MAresidenceStateFullTimeStudent: attr('raw'),

		MOstateSpouseWorks: attr('raw'),
		MOresidenceStateSpouseWorks: attr('raw'),

		ORmetroOptInAmount: attr('number'),
		ORmultnomahOptInAmount: attr('number'),

		MTstateReducedWithholdings: attr('number'),
		MTresidenceStateReducedWithholdings: attr('number'),

		stateTotalAllowanceValue: attr('string'),
		residenceStateTotalAllowanceValue: attr('string'),
	});

	App.EmployeeFederalTax = DS.Model.extend({
		version_id: attr('number'),
		country: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		isActive: attr('boolean'),
		federalFilingStatus: attr('string'),
		federalWithholdingAllowance: attr('number'),
		additionalFederalWitholdings: attr('number'),
		employeeSignature: DS.belongsTo('App.Signature'),
		taxFormUrl: attr('string'),
		formVersion: attr('string'),
		hasTwoJobs: attr('boolean'),
		dependentsAmount: attr('number'),
		otherIncomeAmount: attr('number'),
		deductionsAmount: attr('number'),
		hasAdditionalFederalWitholdings: Ember.computed.gt('additionalFederalWitholdings', 0),
		federalWithholdingAllowanceisNone: function () {
			return !this.get("federalWithholdingAllowance") && this.get("federalWithholdingAllowance") !== 0;
		}.property('federalWithholdingAllowance'),
		additionalFederalWitholdingsisNone: function () {
			return !this.get("additionalFederalWitholdings") && this.get("additionalFederalWitholdings") !== 0;
		}.property('additionalFederalWitholdings'),

		federalFilingStatusDescription: function () {
			var federalFilingStatus = this.get('federalFilingStatus');
			var formVersion = this.get('formVersion');
			var statusOptions = zen.FEDERAL_FILING_STATUS_OPTIONS[formVersion]
			var statusObject = statusOptions.filter(function (o) {
				return o.id == federalFilingStatus;
			});
			return statusObject.length && statusObject[0].name;
		}.property('federalFilingStatus', 'formVersion'),
	});

	App.EmployeeStateTax = DS.Model.extend({
		version_id: attr('number'),
		employee: DS.belongsTo('App.AllEmployee'),
		isActive: attr('boolean'),
		isWorkState: attr('boolean'),
		taxState: attr('string'),
		stateFilingStatus: attr('string'),
		stateWithholdingAllowance: attr('string'),
		additionalStateWitholdings: attr('number'),
		localWithholdingAllowance: attr('number'),
		additionalLocalWithholdings: attr('number'),
		employeeSignature: DS.belongsTo('App.Signature'),
		GAstatePersonalAllowance: attr('number'),
		GAstateDependentAllowance: attr('number'),
		ILstateAdditionalAllowance: attr('number'),
		INstateAdditionalDependentExemptions: attr('number'),
		INstateAdditionalDependentExemptionsFirstClaim: attr('number'),
		INstateAdditionalCountyWithholdings: attr('number'),
		LAstateNumberOfDependents: attr('number'),
		MAstatePersonalBlindness: attr('raw'),
		MAstateSpouseBlindness: attr('raw'),
		MAstateFullTimeStudent: attr('raw'),
		NJstateWageChartLetter: attr('string'),
		VAstateAgeAndBlindnessExemptions: attr('number'),
		WVstateOneEarnerWithholdAtLowerRate: attr('raw'),
		MOstateSpouseWorks: attr('raw'),
		ORmetroOptInAmount: attr('number'),
		ORmultnomahOptInAmount: attr('number'),
		MTstateReducedWithholdings: attr('number'),
		stateTotalAllowanceValue: attr('string'),
		taxFormUrl: attr('string'),
		formVersion: attr('string'),
		hasTwoJobs: attr('boolean'),
		dependentsAmount: attr('number'),
		otherIncomeAmount: attr('number'),
		deductionsAmount: attr('number'),
		PRmarried: attr('boolean'),
		PRyouthExemption: attr('boolean'),
		PRpersonalExemption: attr('string'),
		PRveteranExemption: attr('string'),
		PRcompleteDependents: attr('number'),
		PRjointDependents: attr('number'),
		PRallowance: attr('number'),
		PRadditionalWithholdingPercentage: attr('number'),
		PRpersonalExemptionDescription: function () {
			return {
				'SingleComplete': "Single - Complete",
				'MarriedComplete': "Married - Complete",
				'MarriedHalf': "Married - Half",
				'SingleNone': "Single - None",
				'MarriedNone': "Married - None",
			}[this.get('PRpersonalExemption')];
		}.property('PRpersonalExemption'),
		PRveteranExemptionDescription: function () {
			return {
				'VeteranComplete': "Complete (less withholding)",
				'VeteranNone': "None (more withholding)",
			}[this.get('PRveteranExemption')];
		}.property('PRveteranExemption'),
		isRequiredFieldsComplete: function () {
			return this.get('stateFilingStatus') && this.get('stateWithholdingAllowance') && this.get('employeeSignature.signature');
		}.property('stateFilingStatus', 'stateWithholdingAllowance', 'employeeSignature.signature'),
		stateFilingStatusDescription: function () {
			var stateFilingStatus = this.get('stateFilingStatus');
			var state = this.get('taxState');
			if (state === 'MO') {
				var spouseWorks = this.get('MOstateSpouseWorks');
				stateFilingStatus = this.get('MOstateFilingStatus')(state, stateFilingStatus, spouseWorks);
			}
			var formVersion = this.get('formVersion');
			var statusOptions = STATE_FILING_STATUSES[state] || zen.FEDERAL_FILING_STATUS_OPTIONS[formVersion];
			var statusObject = statusOptions.filter(function (o) {
				return o.id == stateFilingStatus;
			});
			var statusDescription = this.get('translatedFilingStatusDescription')(state, stateFilingStatus) || statusObject.length && statusObject[0].name;
			return statusDescription;
		}.property('stateFilingStatus', 'taxState', 'formVersion', 'MOstateSpouseWorks'),

		translatedFilingStatusDescription: function (state, filingStatus) {
			// Translations are based on existing state-specific filing statuses that are no longer applicable to W4 form
			const filingStatusMap = {
				'CA': { 'MJ': "Married dual income" },
				'NE': { 'MS': "Married, but withhold at higher Single rate" },
			};

			if (state in filingStatusMap && filingStatus in filingStatusMap[state]) {
				return filingStatusMap[state][filingStatus];
			}
		},

		MOstateFilingStatus: function (state, filingStatus, spouseWorks) {
			if (state === 'MO' && filingStatus === 'MJ' && spouseWorks) return 'S';
			return filingStatus;
		},
	});

	App.PayrollEmployeeSettings = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		wfCustomFileNumber: attr('string'),
		wfOnboardingTemplateCode: attr('string'),
		wfCoCode: attr('string'),
		wfFileNumber: attr('string'),
		usesWfTimeAndAttendance: attr('raw'),
		wfBadgeNumberFormat: attr('number'),
		wfBadgeNumber: attr('string'),
		isWfSupervisor: attr('raw'),
		wfSupervisorPayrollId: attr('string'),
		wfPayClassCode: attr('string'),
		departmentCode: attr('string'),
		wfCustomCoCode: attr('string'),
		workersCompCode: attr('string'),
		wfJobTitleCode: attr('string'),
		wfBusinessUnitCode: attr('string'),
		wfLocationCode: attr('string'),
		wfWorkerCategoryCode: attr('string'),
		wfAcaBenefitsEligibilityCode: attr('string'),
		wfPayrollScheduleGroupCode: attr('string'),
		pxLocationCode: attr('string'),
		pxLaborAssignmentCode: attr('string'),
		plCostCenter1: attr('string'),
		plCostCenter2: attr('string'),
		plCostCenter3: attr('string'),
		positionCode: attr('string'),
		intuitPayScheduleId: attr('string'),
	});

	App.PayrollDirectDeposit = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
		accountType: attr('string'),
	});

	App.PayrollDepartment = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		companyCodes: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.WfCoCode = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.CompClass = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
		state: attr('string'),
	});

	App.WfJobTitle = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.WfLocation = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.WfWorkerCategory = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.WfBusinessUnit = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.WfTerminationReason = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		codeAndDescription: attr('string'),
		payrollProvider: attr('string'),
	});

	App.WfPayClass = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.PxBusinessLocation = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.PxLaborAssignment = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		code: attr('string'),
		description: attr('string'),
		isActive: attr('boolean'),
	});

	App.EmployeeHrContact = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		hrContactName: attr('string'),
		hrContactEmail: attr('string'),
		hrContactPhone: attr('string'),
	});

	App.ShoppingCartPlan = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		plan: DS.belongsTo('App.Plan'),
	});

	App.CompanyHealthPlan = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		planId: attr('number'),
		lineOfCoverage: attr('string'),
		plan: attr('string'),
		// client facing MDV plans
		medicalPlan: DS.belongsTo('App.CPlan'),
		dentalPlan: DS.belongsTo('App.CDentalPlan'),
		visionPlan: DS.belongsTo('App.CVisionPlan'),
		// console facing MDV plans
		rawMedicalPlan: DS.belongsTo('App.Plan'),
		rawDentalPlan: DS.belongsTo('App.DentalPlan'),
		rawVisionPlan: DS.belongsTo('App.VisionPlan'),

		lifePlan: DS.belongsTo('App.LifePlanNew'),
		adndPlan: DS.belongsTo('App.AddPlan'),
		stdPlan: DS.belongsTo('App.StdPlanNew'),
		ltdPlan: DS.belongsTo('App.LtdPlanNew'),
		suppPlan: DS.belongsTo('App.SupplementalPlan'),
		prevLifeDisabilityPlanId: DS.attr('number'),
		isHraEnabled: DS.attr('boolean'),
		summaryPlanDescriptionDocument: DS.belongsTo('App.Document'),

		planKey: function () {
			return this.get('planId') + ':' + this.get('lineOfCoverage');
		}.property('planId', 'lineOfCoverage'),

		normalizedPlan: function () {
			var loc = this.get('lineOfCoverage');
			if (loc === 'medical') { return this.get('medicalPlan'); }
			else if (loc === 'dental') { return this.get('dentalPlan'); }
			else if (loc === 'vision') { return this.get('visionPlan'); }
			else if (loc === 'lifenew') { return this.get('lifePlan'); }
			else if (loc === 'add') { return this.get('adndPlan'); }
			else if (loc === 'std') { return this.get('stdPlan'); }
			else if (loc === 'ltd') { return this.get('ltdPlan'); }
			else if (['acc', 'cancer', 'ci', 'hi'].contains(loc)) { return this.get('suppPlan'); }
		}.property('medicalPlan', 'dentalPlan', 'visionPlan', 'lifePlan', 'adndPlan', 'stdPlan', 'ltdPlan', 'suppPlan', 'lineOfCoverage'),
		normalizedRawPlan: function () {
			var loc = this.get('lineOfCoverage');
			if (loc === 'medical') {
				return this.get('rawMedicalPlan');
			} else if (loc === 'dental') {
				return this.get('rawDentalPlan');
			} else if (loc === 'vision') {
				return this.get('rawVisionPlan');
			} else {
				return this.get('normalizedPlan');
			}
		}.property('lineOfCoverage', 'normalizedPlan', 'rawMedicalPlan', 'rawDentalPlan', 'rawVisionPlan'),
		largeGroupPlan: function () {
			var loc = this.get('lineOfCoverage');
			if (loc === 'medical') {
				return this.get('rawMedicalPlan.largeGroup');
			} else if (loc === 'dental') {
				return this.get('rawDentalPlan.largeGroup');
			} else if (loc === 'vision') {
				return this.get('rawVisionPlan.largeGroup');
			} else {
				return false;
			}
		}.property('lineOfCoverage', 'rawMedicalPlan.largeGroup', 'rawDentalPlan.largeGroup', 'rawVisionPlan.largeGroup'),
	});

	App.PlanContributionScheme = DS.Model.extend({
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		contributionEmployee: attr('number'),
		contributionDependents: attr('number'),
		contributionType: attr('string'),
		lineOfCoverage: attr('string'),
		plan: attr('string'),
		planId: attr('number'),
		isPercent: Ember.computed.equal('contributionType', 'P')
	});

	App.DepTypeContributionScheme = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		contributionType: attr('string'),
		familyContribution: attr('number'),
		youAndChildContribution: attr('number'),
		youAndSpouseContribution: attr('number'),
		youContribution: attr('number'),
		isPercent: Ember.computed.equal('contributionType', 'P')
	});

	App.PlanDepTypeContributionScheme = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		contributionType: attr('string'),
		familyContribution: attr('number'),
		youAndChildContribution: attr('number'),
		youAndSpouseContribution: attr('number'),
		youContribution: attr('number'),
		plan: attr('string'),
		planId: attr('number'),
		isPercent: Ember.computed.equal('contributionType', 'P')
	});

	App.DependentFixedAdditionalContribution = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		childContribution: attr('number'),
		familyContribution: attr('number'),
		spouseContribution: attr('number')
	});

	App.DependentCountFixedAdditionalDeduction = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		youFee: attr('number'),
		youPlusOneDepFee: attr('number'),
		youPlusTwoDepFee: attr('number'),
		youPlusThreeDepFee: attr('number'),
		youPlusFourDepFee: attr('number'),
		youPlusFivePlusDepFee: attr('number'),
	});

	App.EmployeeFixedAdditionalDeduction = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		familyFee: attr('number'),
		youAndChildFee: attr('number'),
		youAndChildrenFee: attr('number'),
		youAndChildAndSpouseFee: attr('number'),
		youAndSpouseFee: attr('number'),
		youFee: attr('number')
	});

	App.PlanFixedAdditionalDeduction = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		lineOfCoverage: attr('string'),
		familyFee: attr('number'),
		youAndChildFee: attr('number'),
		youAndSpouseFee: attr('number'),
		youAndChildrenFee: attr('number'),
		youAndChildAndSpouseFee: attr('number'),
		youFee: attr('number'),
		plan: attr('string'),
		planId: attr('number')
	});

	App.EmployeeContributionScheme = DS.Model.extend({
		changeEffectiveDate: attr('string'),
		changeReason: attr('string'),
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		company: DS.belongsTo('App.Company'),
		contributionEmployee: attr('number'),
		contributionDependents: attr('number'),
		contributionType: attr('string'),
		lineOfCoverage: attr('string'),
		plan: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		isPercent: Ember.computed.equal('contributionType', 'P'),
		isFixed: Ember.computed.equal('contributionType', 'F'),
	});

	App.ContributionScheme = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		rules: DS.hasMany('App.ContributionSchemeRule'),
		order: attr(),
		isActive: attr('boolean'),
		isEditable: attr('boolean'),
		effectiveDate: attr('string'),
		description: attr(),

		isDeactivated: Ember.computed('isActive', 'isEditable', function () {
			return !(this.get('isActive') || this.get('isEditable'));
		}),

		status: Ember.computed('isActive', 'isEditable', function () {
			if (this.get('isActive')) {
				return 'Published';
			}
			return this.get('isEditable') ? 'Draft' : 'Deleted';
		}),

		numberOfPlanGroupsForChcId: function (companyHealthCarrierId) {
			var description = this.get('description');
			if (!description) {
				return false;
			}
			var companyHealthCarrierSpecificDescription = description[companyHealthCarrierId];
			return companyHealthCarrierSpecificDescription.get('length');
		},

		isPlanBasedForChcId: function (companyHealthCarrierId) {
			return this.numberOfPlanGroupsForChcId(companyHealthCarrierId) > 1;
		},

		splitRulesDescription: function (ruleDescription) {
			if (!ruleDescription) {
				return null;
			}
			return ruleDescription.replace(/(,)\s*|(and)\s*/gi, function (m, g1, g2) {
				return (g1 || g2) + '\n';
			}).split('\n');
		},

		descriptionLinesForChcAndPlan: function (companyHealthCarrierId, planId) {
			var NA = ['N/A'];
			var description = this.get('description');
			if (!description) {
				return NA;
			}
			var companyHealthCarrierSpecificDescription = description[companyHealthCarrierId];
			var numberOfGroups = companyHealthCarrierSpecificDescription.get('length');
			if (!numberOfGroups) {
				return NA;
			}
			if (numberOfGroups == 1) {
				var group = companyHealthCarrierSpecificDescription.get('firstObject');
				return this.splitRulesDescription(group.ruleDescription) || NA;
			}
			if (!planId) {
				return NA;
			}
			var groupsMatchingPlanId = companyHealthCarrierSpecificDescription.filter(function (group) {
				return group.companyHealthPlans.mapBy('planId').contains(planId);
			});
			if (groupsMatchingPlanId.get('length') != 1) {
				return NA;
			}
			var group = groupsMatchingPlanId.get('firstObject');
			return this.splitRulesDescription(group.ruleDescription) || NA;
		},
	});

	App.ContributionSchemeRule = DS.Model.extend({
		scheme: DS.belongsTo('App.ContributionScheme'),
		// LHS: Employee Pattern
		employeePredicateFormula: attr('string'),
		// LHS: CHP Pattern
		lineOfCoverage: attr('string'),
		carrierId: attr('number'),
		planId: attr('number'),
		companyHealthCarrierId: attr('number'),
		companyHealthPlanId: attr('number'),
		// RHS: Contributions Row
		employeeContributionFormula: attr('string'),
		childrenContributionFormula: attr('string'),
		spouseContributionFormula: attr('string'),
		dependentsContributionFormula: attr('string'),
		totalContributionFormula: attr('string'),
		// RHS: Deductions Row
		employeeDeductionFormula: attr('string'),
		childrenDeductionFormula: attr('string'),
		spouseDeductionFormula: attr('string'),
		dependentsDeductionFormula: attr('string'),
		totalDeductionFormula: attr('string'),

		priority: attr('string'),

		description: attr('string'),
		employeeTemplate: attr(),
		template: attr(),

		insertAfterRule: attr('number'),

		planKey: function (key, value, oldValue) {
			if (arguments.length > 1) {
				var parts = value ? value.split(':') : [];
				var planId = Number(parts[0]) || null;
				this.set('planId', planId);
				if (parts.length > 1) {
					var lineOfCoverage = parts[1] || null;
					this.set('lineOfCoverage', lineOfCoverage);
				}
			}
			return this.get('planId') + ':' + this.get('lineOfCoverage');
		}.property('planId', 'lineOfCoverage'),

		planPatternDescriptionShort: function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			var carrierId = this.get('carrierId');
			var planId = this.get('planId');

			var allArgs = [lineOfCoverage, carrierId, planId];

			var getNonNullArgs = function (array) {
				return array.filter(function (arg) { return arg !== null; });
			};

			var nonNullArgs = getNonNullArgs(allArgs);
			if (!nonNullArgs) {
				return '';
			}

			var descriptors = [];
			var addDescriptor = function (value, label, identGetter) {
				if (value !== null) {
					if (value) {
						descriptors.push(label + '(' + identGetter(value) + ')');
					} else {
						descriptors.push(label + '(Err)');
					}
				}
			};
			addDescriptor(lineOfCoverage, 'L', function (v) { return v[0].capitalize(); });
			addDescriptor(carrierId, 'C', function (v) { return v; });
			addDescriptor(planId, 'P', function (v) { return v; });
			return descriptors.join(' ');
		}.property('lineOfCoverage', 'carrierId', 'planId'),

		clone: function () {
			var attrs = this.toJSON();
			attrs.scheme = this.get('scheme');
			delete attrs.id;
			return App.store.createRecord('ContributionSchemeRule', attrs);
		},
	});

	App.CompanyHealthCarrier = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		companyHealthPlans: DS.hasMany('App.CompanyHealthPlan'),
		medicalPlans: DS.hasMany('App.Plan'),
		dentalPlans: DS.hasMany('App.DentalPlan'),
		visionPlans: DS.hasMany('App.VisionPlan'),
		openEnrollmentStart: attr('string'),
		openEnrollmentPeriod: attr('number'),
		openEnrollmentEndDate: attr('string'),
		renewalDate: attr('string'),
		lineOfCoverage: attr('string'),
		carrier: DS.belongsTo('App.Carrier'),
		isPrimaryCarrier: attr('boolean'),
		isSelfAdministered: attr('boolean'),
		carrierType: attr('string'),
		groupID: attr('string'),
		status: attr('string'),
		borStatus: attr('string'),
		approvedDate: attr('string'),
		approvalLetterUrl: attr('string'),
		contractLength: attr('number'),
		contributionType: attr('string'),
		contributionTypeDisplay: attr('string'),
		basePlan: DS.belongsTo('App.Plan'),
		dentalBasePlan: DS.belongsTo('App.DentalPlan'),
		basePlanName: attr('string'),
		contributionEmployee: attr('number'),
		contributionDependents: attr('number'),
		autoPayAckName: attr('string'),
		autoPayAckSignature: attr('string'),
		raf: attr('number'), // Applicable for 2013 plans
		invoiceUrl: attr('string'),
		renewalPacketUrl: attr('string'),
		hasEmployerAccess: attr('boolean'),
		doWeAutoPay: attr('boolean'),
		isOpenEnrollment: attr('boolean'),
		openEnrollmentMonth: attr('string'),
		eligibleForShortCircuitOpenEnrollment: attr('boolean', { defaultValue: false }),
		carrierName: attr('string'),
		carrierDisplayName: attr('string'),
		waitingPeriod: attr('string'),
		waitingPeriodDisplayText: attr('string'),
		ptWaitingPeriod: attr('string'),
		ptWaitingPeriodDisplayText: attr('string'),
		onlineAccessUsername: attr('string'),
		onlineAccessPassword: attr('string'),
		terminationPolicy: attr('string'),
		terminationPolicyDisplayText: attr('string'),

		hasOnlineAccessCredentials: attr('boolean'), // We donot send username and password down the wire. So a flag to indicate whether we actually have their username and password.
		disableDependentCoverageChanges: attr('boolean'),

		planContributionSchemes: DS.hasMany('App.PlanContributionScheme'),
		depTypeContributionSchemes: DS.hasMany('App.DepTypeContributionScheme'),
		planDepTypeContributionSchemes: DS.hasMany('App.PlanDepTypeContributionScheme'),
		/*TODO: depCountContributionSchemes: DS.hasMany('App.DepCountContributionScheme'),*/
		/*TODO: planDepCountContributionSchemes: DS.hasMany('App.PlanDepCountContributionScheme'),*/
		employeeFixedDeductions: DS.hasMany('App.EmployeeFixedAdditionalDeduction'),
		depFixedContributions: DS.hasMany('App.DependentFixedAdditionalContribution'),
		depCountFixedDeductions: DS.hasMany('App.DependentCountFixedAdditionalDeduction'),
		planFixedDeductions: DS.hasMany('App.PlanFixedAdditionalDeduction'),
		employeeContributionSchemes: DS.hasMany('App.EmployeeContributionScheme'),
		hasCustomContributions: attr('boolean'),
		isPercentOfPlan: Ember.computed.equal('contributionType', 'B'),
		isFixed: Ember.computed.equal('contributionType', 'F'),
		isPercent: Ember.computed.equal('contributionType', 'P'),
		isFixedEEPercentDep: Ember.computed.equal('contributionType', 'FP'),
		isPercentEEFixedDep: Ember.computed.equal('contributionType', 'PF'),
		isOneFixedCost: Ember.computed.equal('contributionType', 'O'),
		isOneFixedMedicalCost: Ember.computed.equal('contributionType', 'M'),
		isSubmitted: Ember.computed.equal('status', 'submitted'),
		isApproved: Ember.computed.equal('status', 'approved'),
		isMedical: Ember.computed.equal('lineOfCoverage', 'medical'),
		isDental: Ember.computed.equal('lineOfCoverage', 'dental'),
		isVision: Ember.computed.equal('lineOfCoverage', 'vision'),
		isUnderImplementation: attr('boolean'),
		isOpenEnrollmentInProgress: attr('boolean'),
		skipPackageSizeValidation: attr('boolean'),
		customContributionDocument: DS.belongsTo('App.Document'),
		supportedWaitingPeriods: Ember.A(),


		prettyLineOfCoverage: Ember.computed(function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			if (lineOfCoverage == 'lifenew') {
				lineOfCoverage = 'life';
			}
			return Ember.String.capitalize(lineOfCoverage);
		}).property('lineOfCoverage'),

		// We don't actually filter any values out on the Model level. This needs to be done on the Controller level.
		// The blacklisted values are defined on Confluence 'Waiting Periods Displayed in Admin Flows' page https://confluence.inside-zen.com/x/B64SAw
		sortedSupportedWaitingPeriods: Ember.computed(function () {
			var data = Ember.A();
			var WAITING_PERIODS = [
				'',
				'Default',
				'Hire Date',
				'1 Day',
				'7 Days',
				'One Month',
				'29 Days',
				'30 Days',
				'31 Days',
				'45 Days',
				'59 Days',
				'60 Days',
				'61 Days',
				'90 Days',
				'180 Days',
				'Two Months',
				'Three Months',
				'Six Months',
				'Nine Months',
				'One Year',
				'Eighteen Months',
				'Two Years',
				'Hire + 1',
				'Hire + 10',
				'Hire + 14',
				'Hire + 15',
				'Hire + 20',
				'Hire + 25',
				'Hire + 29',
				'Hire + 30',
				'Hire + 31',
				'Hire + 45',
				'Hire + 60',
				'Hire + 65',
				'Hire + 89',
				'Hire + 90',
				'Hire + 91',
				'Hire + 180',
				'Hire + 1 Year',
				'Hire + 1 Year + 1 Day',
				'Hire + 2 Years',
				'Hire + 3 Years',
				'Hire + 5 Years',
				'7 After 30 days',
				'15 Default',
				'15 After 30 days',
				'15 After 60 days',
				'15 After 90 days',
				'15 After 1 month',
				'15 After 2 months',
				'15 After 3 months',
				'Hire + 4 Weeks',
				'Hire + 1 Month',
				'Hire + 2 Months',
				'Hire + 3 Months',
				'Hire + 4 Months',
				'Hire + 6 Months',
				'Same Month',
			];
			WAITING_PERIODS.forEach(function (waitingPeriod) {
				this.get('supportedWaitingPeriods').forEach(function (supportedWaitingPeriod) {
					if (supportedWaitingPeriod.get('waitingPeriod') == waitingPeriod) {
						data.push(supportedWaitingPeriod);
					}
				}.bind(this));
			}.bind(this));
			return data;
		}).property('supportedWaitingPeriods.[]'),

		displayName: function () {
			return this.get('carrier.name') + " (" + this.get('companyHealthEnrollment.enrollmentType') + ")" + " (ID - " + this.get('id') + ")";
		}.property('carrier.name', 'companyHealthEnrollment.enrollmentType'),

		confusedCarrier: DS.belongsTo('App.Carrier'),
		renewalDateDisplay: function () {
			return moment(this.get('renewalDate')).format('MM/DD/YYYY');
		}.property('renewalDate'),

		terminationPolicyPrettyText: Ember.computed(function () {
			var policy = this.get('terminationPolicy');
			if (policy) {
				return this.get('terminationPolicyDisplayText');
			}
			return '';
		}).property('terminationPolicy'),
	});

	var lineOfCoveragePrettyMap = {
		'medical': 'Medical',
		'dental': 'Dental',
		'vision': 'Vision',
		'life': 'Life',
		'lifenew': 'Life New',
		'std': 'Short Term Disability',
		'ltd': 'Long Term Disability',
		'add': 'AD&D',
	};

	var enrollmentTypePrettyMap = {
		IE: 'Initial Enrollment',
		OE: 'Open Enrollment',
		QE: 'Qualifying Event',
		SW: 'Switch Carrier',
		AC: 'Add Carrier',
		BoR: 'Broker of Record',
		// TODO: change these up when EHE edit/review is launched
		new_hire_initial_enrollment: 'New Hire',
		initial_enrollment: 'New Group',
		new_hire_exception_enrollment: 'New Hire Exception',
		new_group_exception_enrollment: 'New Group Exception',
		qualifying_event_enrollment: 'Qualifying Event',
		open_enrollment: 'Open',
		switch_carrier: 'Switch Carrier',
	};

	App.NewGroupsIncomingDashboardRow = DS.Model.extend({
		che_id: attr('string'),
		company_id: attr('string'),
		company__name: attr('string'),
		company__state: attr('string'),
		healthCarrier__carrier__name: attr('string'),
		healthCarrier__carrier_id: attr('string'),
		lineOfCoverage: attr('string'),
		enrollmentStatus: attr('string'),
		enrollmentType: attr('string'),
		endDate: attr('rawdatetime'),
		desiredEffectiveDate: attr('rawdatetime'),
		documentsReminderTimestamp: attr('rawdatetime'),
		enrollmentCompleteTimestamp: attr('rawdatetime'),
		zenefitsContactsAsString: attr('string'),
		contact: attr('string'),
		inStateState: DS.belongsTo('App.InStateState', { async: true }),
		participationState: DS.belongsTo('App.ParticipationState', { async: true }),

		carrierDisplayName: Ember.computed.alias('healthCarrier__carrier__name'),

		zenefitsContacts: Ember.computed(function () {
			var str = this.get('zenefitsContactsAsString');
			return str ? str.split(',') : []; // "".split(',') is [""].  In that case, we want [], not [""].
		}).property('zenefitsContactsAsString'),

		lineOfCoverageDisplayName: Ember.computed(function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			return lineOfCoverage.charAt(0).toUpperCase() + lineOfCoverage.slice(1);
		}).property('lineOfCoverage'),


		enrollmentTypeDisplayName: Ember.computed(function () {
			return enrollmentTypePrettyMap[this.get('enrollmentType')];
		}).property('enrollmentType'),

		documentState: Ember.computed(function () {
			return CheDocumentState.create({
				che: this,
			});
		}).property(),

		areDocumentsComplete: Ember.computed.bool('documentState.isFullyPassing'),
		areDocumentsCompletePretty: Ember.computed(function () {
			return this.get('areDocumentsComplete') ? 'Complete' : 'Incomplete';
		}).property('areDocumentsComplete'),

		isDocumentsReminderSent: Ember.computed.bool('documentsReminderTimestamp'),
		documentsReminderTimestampPretty: Ember.computed(function () {
			return this.get('documentsReminderTimestamp'); // do some fancy shit here? //or not
		}).property('documentsReminderTimestamp'),
	}).reopenClass({
		noBatch: true
	});

	var CheDocumentState = Ember.Object.extend({
		che: null,

		isLoading: Ember.computed.alias('che.isLoading'),

		isFullyFailing: Ember.computed.equal('che.enrollmentStatus', 'document'),
		isFullyPassing: Ember.computed.not('isFullyFailing'),

		displayStatus: Ember.computed(function () {
			return this.get('isFullyPassing') ? 'Fully Passing' : 'Fully Failing';
		}).property('isFullyPassing'),
	});

	App.NewGroupsInProgressDashboardRow = DS.Model.extend({
		che_id: attr('string'),
		company_id: attr('string'),
		company__name: attr('string'),
		company__state: attr('string'),
		healthCarrier__carrier__name: attr('string'),
		healthCarrier__carrier_id: attr('string'),
		linesOfCoverageAsString: attr('string'),
		enrollmentType: attr('string'),
		desiredEffectiveDate: attr('rawdatetime'),
		zenefitsContactsAsString: attr('string'),
		contact: attr('string'),
		applicationReadyGeneric: attr('string'),
		applicationReadyGeneric_id: attr('string'),
		applicationReadyFollowUp: attr('string'),
		applicationReadyFollowUp_id: attr('string'),
		enrollmentApproved: attr('string'),
		enrollmentApproved_id: attr('string'),
		companyApproved: attr('string'),
		companyApproved_id: attr('string'),
		newGroupAudit: attr('string'),
		newGroupAudit_id: attr('string'),
		participationState_remainingNonNewHires: attr('string'),
		participationState_qualifiedNonNewHires: attr('string'),

		displayRemainingOutOfTotal: Ember.computed(function () {
			return this.get('participationState_remainingNonNewHires') + '/' + this.get('participationState_qualifiedNonNewHires') + ' EEs';
		}).property('participationState_remainingNonNewHires', 'participationState_qualifiedNonNewHires'),

		linesOfCoverageShorthandMap: {
			Medical: 'M',
			Dental: 'D',
			Vision: 'V',
			Add: 'A',
			Lifenew: 'L',
			Ltd: 'Ltd',
			Std: 'Std',
			Life: 'L',
		},
		linesOfCoverageOrder: ['Medical', 'Dental', 'Vision', 'Add', 'Lifenew', 'Std', 'Ltd', 'Life'],

		linesOfCoverage: Ember.computed(function () { // Sort this?  MDV
			var str = this.get('linesOfCoverageAsString');
			var lines = str ? str.split(',') : [""];

			return lines.sort(function (a, b) {
				return this.linesOfCoverageOrder.indexOf(a) - this.linesOfCoverageOrder.indexOf(b);
			}.bind(this));
		}).property('linesOfCoverageAsString'),

		linesOfCoverageDisplayName: Ember.computed(function () {
			var lines = this.get('linesOfCoverage');
			if (lines.length == 1) {
				return lines[0]; // Show the full name if there's just one line.
			}

			lines = lines.map(function (line) {
				return this.linesOfCoverageShorthandMap[line] || '?';
			}.bind(this));

			var joinedLines = lines.join('/');
			if (joinedLines.length < 12) { // Short enough to display separated by slashes
				return joinedLines;
			}
			return lines.join(''); // Crazy number of lines, have to squish them together to display all.
		}).property('linesOfCoverage', 'linesOfCoverageAsString'),

		linesOfCoverageLongDisplayName: Ember.computed(function () {
			return this.get('linesOfCoverage').join(', ');
		}).property('linesOfCoverage', 'linesOfCoverageAsString'),

		applicationReadyGenericState: Ember.computed(function () {
			return WorkItemState.create({
				isLoading: this.get('isLoading'),
				status: this.get('applicationReadyGeneric'),
			});
		}).property('isLoading', 'applicationReadyGeneric'),

		applicationReadyFollowUpState: Ember.computed(function () {
			return WorkItemState.create({
				isLoading: this.get('isLoading'),
				status: this.get('applicationReadyFollowUp'),
			});
		}).property('isLoading', 'applicationReadyFollowUp'),

		enrollmentApprovedState: Ember.computed(function () {
			return WorkItemState.create({
				isLoading: this.get('isLoading'),
				status: this.get('enrollmentApproved'),
			});
		}).property('isLoading', 'enrollmentApproved'),

		companyApprovedState: Ember.computed(function () {
			return WorkItemState.create({
				isLoading: this.get('isLoading'),
				status: this.get('companyApproved'),
			});
		}).property('isLoading', 'companyApproved'),

		newGroupAuditState: Ember.computed(function () {
			return WorkItemState.create({
				isLoading: this.get('isLoading'),
				status: this.get('newGroupAudit'),
			});
		}).property('isLoading', 'newGroupAudit'),

		carrierDisplayName: Ember.computed.alias('healthCarrier__carrier__name'),

		zenefitsContacts: Ember.computed(function () {
			var str = this.get('zenefitsContactsAsString');
			return str ? str.split(',') : []; // "".split(',') is [""].  In that case, we want [], not [""].
		}).property('zenefitsContactsAsString'),

		enrollmentTypeDisplayName: Ember.computed(function () {
			return enrollmentTypePrettyMap[this.get('enrollmentType')];
		}).property('enrollmentType'),
	}).reopenClass({
		noBatch: true
	});

	var WorkItemState = Ember.Object.extend({
		status: null,
		isLoading: null,

		displayStatus: Ember.computed(function () {
			return this.get('status') || "Not Generated";
		}).property('status'),
	});

	App.CompanyHealthEnrollment = DS.Model.extend({
		/*
		Attributes
		*/
		company: DS.belongsTo('App.Company'),
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		lineOfCoverage: attr('string'),
		enrollmentStatus: attr('string'),
		isEnrollmentComplete: attr('boolean'),
		isActive: attr('boolean'),
		isReviewing: attr('boolean'),
		enrollmentType: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		desiredEffectiveDate: attr('string'),
		authName: attr('string'),
		companyCheAgreement: DS.belongsTo('App.AgreementChe'),
		authTitle: attr('string'),
		authPhone: attr('string'),
		authWebsite: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		censusUrl: attr('string'),
		escalationTimestamp: attr('date'),
		enrollmentBegunTimestamp: attr('date'),
		enrollmentCompleteTimestamp: attr('date'),
		enrollmentCompleteEmailTimestamp: attr('date'),
		enrollmentDocumentsTimestamp: attr('date'),
		documentsReminderTimestamp: attr('date'),
		addExistingTimestamp: attr('date'),
		implementationCompleteDate: attr('string'),
		previousEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		participationRuleAgreement: DS.belongsTo('App.ParticipationRuleAgreement'),
		inStateRuleAgreement: DS.belongsTo('App.InStateRuleAgreement'),
		confirmedPayrollHistory: attr('boolean'),
		confirmedWorkersComp: attr('boolean'),
		confirmedPreviousCoverage: attr('boolean'),
		confirmedVspContract: attr('boolean'),
		confirmedGuardianContract: attr('boolean'),
		planMapping: attr('string'),
		isAdminOpenEnrollmentActive: attr('boolean'),
		participationState: DS.belongsTo('App.ParticipationState', { async: true }),
		inStateState: DS.belongsTo('App.InStateState', { async: true }),
		isCompositeRated: attr('boolean'),
		progress: attr('string'),
		/*
			Properties
		*/
		lineOfCoverageDisplayName: Ember.computed(function () {
			return lineOfCoveragePrettyMap[this.get('lineOfCoverage')];
		}).property('lineOfCoverage'),

		lineOfCoverageDisplayNameForMainAdminChange: Ember.computed(function () {
			return this.get('lineOfCoverageDisplayName') + ' Insurance';
		}).property('lineOfCoverageDisplayName'),

		enrollmentTypeDisplayName: Ember.computed(function () {
			return enrollmentTypePrettyMap[this.get('enrollmentType')];
		}).property('enrollmentType'),
		enrollmentTypeDisplayNameAndAbbreviation: function () {
			return this.get('enrollmentType') + ' (' + this.get('enrollmentTypeDisplayName') + ')';
		}.property('enrollmentTypeDisplayName'),

		isDocumentsReminderSent: Ember.computed.bool('documentsReminderTimestamp'),
		documentsReminderTimestampPretty: Ember.computed(function () {
			return this.get('documentsReminderTimestamp'); // do some fancy shit here?
		}).property('documentsReminderTimestamp'),

		carrierDisplayName: Ember.computed.alias('companyHealthCarrier.carrier.displayName'),
		largeGroupEnrollment: function () {
			return this.get('companyHealthCarrier.companyHealthPlans').any(function (chp) {
				return chp.get('largeGroupPlan');
			});
		}.property('companyHealthCarrier.companyHealthPlans.@each.largeGroupPlan'),

		enrollmentDisplayName: function () {
			return this.get('lineOfCoverageDisplayName') + ' (' + this.get('carrierDisplayName') + ')';
		}.property('lineOfCoverageDisplayName', 'carrierDisplayName'),

		areDocumentsComplete: Ember.computed.bool('documentState.isFullyPassing'),
		areDocumentsCompletePretty: Ember.computed(function () {
			return this.get('areDocumentsComplete') ? 'Complete' : 'Incomplete';
		}).property('areDocumentsComplete'),

		documentState: Ember.computed(function () {
			return CheDocumentState.create({
				che: this,
			});
		}).property(),

		employeeLifeDisabilityEnrollments: DS.hasMany("App.EmployeeLifeDisabilityEnrollment"),
		thinEmployeeLifeDisabilityEnrollments: DS.hasMany("App.ThinEmployeeLifeDisabilityEnrollment"),

		effectiveDate: Ember.computed.or('companyHealthCarrier.approvedDate', 'desiredEffectiveDate'),

		totalLifeDisabilityBasicVolume: function () {
			var total = 0;

			this.get('thinEmployeeLifeDisabilityEnrollments').rejectBy('status', _LDEAbstractModel.prototype.CANCELED)
				.forEach(function (lde) {
					if (lde.get('employee.isEligibleForLifeDisability')) {
						total += lde.get('totalBasicVolume');
					}
				});
			return total;
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.totalBasicVolume'),

		totalLifeDisabilityBasicPremium: function () {
			var total = 0;

			this.get('thinEmployeeLifeDisabilityEnrollments').rejectBy('status', _LDEAbstractModel.prototype.CANCELED)
				.forEach(function (lde) {
					if (lde.get('employee.isEligibleForLifeDisability')) {
						total += lde.get('totalBasicPremium');
					}
				});
			return total;
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.totalBasicVolume'),

		totalLifeDisabilityVoluntaryVolume: function () {
			var total = 0;

			this.get('thinEmployeeLifeDisabilityEnrollments').rejectBy('status', _LDEAbstractModel.prototype.CANCELED)
				.forEach(function (lde) {
					if (lde.get('employee.isEligibleForLifeDisability')) {
						total += lde.get('totalVoluntaryVolume');
					}
				});
			return total;
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.totalVoluntaryVolume'),

		totalLifeDisabilityVoluntaryPremium: function () {
			var total = 0;

			this.get('thinEmployeeLifeDisabilityEnrollments').rejectBy('status', _LDEAbstractModel.prototype.CANCELED)
				.forEach(function (lde) {
					if (lde.get('employee.isEligibleForLifeDisability')) {
						total += lde.get('totalVoluntaryPremium');
					}
				});
			return total;
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.totalVoluntaryPremium'),

		hasLifeDisabilityBasicPlan: function () {
			return this.get('thinEmployeeLifeDisabilityEnrollments').some(function (lde) {
				return lde.get('employee.isEligibleForLifeDisability') && lde.get('basicPlan');
			});
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.basicPlan'),

		hasLifeDisabilityVoluntaryPlan: function () {
			return this.get('thinEmployeeLifeDisabilityEnrollments').some(function (lde) {
				return lde.get('employee.isEligibleForLifeDisability') && lde.get('voluntaryPlan');
			});
		}.property('thinEmployeeLifeDisabilityEnrollments.@each.voluntaryPlan'),
	});


	App.CarrierAndEmployerCredential = DS.Model.extend({
		username: attr('string'),
		password: attr('string'),
		url: attr('string'),
		loginType: attr('string'),
		isActive: attr('boolean'),
		carrier: DS.belongsTo('App.Carrier'),
		notes: attr('string'),
		groupType: attr('string'),
		company_id: attr('string'),
		verificationStatus: attr('string'),
		hasOnlineAccessCredentials: attr('boolean'),
		removeBrokerForCompany: attr('boolean', { defaultValue: false }),
		companyHealthCarriers: DS.hasMany('App.CompanyHealthCarrier'),
	});

	App.CarrierAndEmployerCredentialStat = DS.Model.extend({
		loginType: attr('string'),
		isActive: attr('boolean'),
		carrier: DS.belongsTo('App.Carrier'),
		groupType: attr('string'),
		verificationStatus: attr('string'),
		hasOnlineAccessCredentials: attr('boolean'),
	});


	App.TieredContributionScheme = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		companyHealthCarriers: DS.hasMany('App.CompanyHealthCarrier', { inverse: null }),
		tier: DS.belongsTo('App.CustomFieldSelectChoices'),
		contributionType: attr('string'),
		basePlan: attr('number'),
		contributionEmployee: attr('number'),
		contributionDependents: attr('number'),
	});

	App.EdiCompanySetting = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		carrier: DS.belongsTo('App.Carrier'),
		ediCarrierConfig: DS.belongsTo('App.EdiCarrierConfig'),
		ediValues: DS.hasMany('App.EdiCarrierFieldValue'),
		carrierSpecificData: attr('string'),
		prodFilename: attr('string'),
		testFilename: attr('string'),
		status: attr('string'),
		status_verbose: attr('string'),
		runEvery: attr('number'),
		ediSenderId: attr('number'),
		ediReceiverId: attr('number'),
		filename: attr('string'),
	});
	App.EdiCarrierConfig = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		ediSenderId: attr('string'),
		ediFields: DS.hasMany('App.EdiCarrierField'),
		generateForAllGroups: attr('boolean'),
		module: attr('string'),
		prodFolder: attr('string'),
		prodInterface: attr('string'),
		prodInterface_verbose: attr('string'),
		prodPassword: attr('string'),
		prodUrl: attr('string'),
		prodUser: attr('string'),
		reconciliationFileUrl: attr('string'),
		responseTime: attr('string'),
		responseTime_verbose: attr('string'),
		template: attr('string'),
		testFolder: attr('string'),
		testInterface: attr('string'),
		testInterface_verbose: attr('string'),
		testPassword: attr('string'),
		testUrl: attr('string'),
		testUser: attr('string'),
	});
	App.FilterCarrier = DS.Model.extend({
		name: attr('string'),
		displayName: attr('string'),
		state: attr('string'),
	});
	App.EdiValidationSummary = DS.Model.extend({
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		companyValidations: attr('number'),
		carrierValidations: attr('number'),
		employeeValidations: attr('number'),
		dependentValidations: attr('number'),
		totalValidations: attr('number'),
	});

	App.EdiCarrierField = DS.Model.extend({
		name: attr('string'),
	});

	App.EdiCarrierFieldValue = DS.Model.extend({
		description: attr('string'),
		name: attr('string'),
		value: attr('string'),
	});

	App.TieredWaitingPeriod = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		tier: DS.belongsTo('App.CustomFieldSelectChoices'),
		lineOfCoverage: attr('string'),
		waitingPeriod: attr('string'),
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),

		prettyLineOfCoverage: Ember.computed(function () {
			var lineOfCoverage = this.get('lineOfCoverage');
			var newLoc = allLocs.find(function(loc) {
				return loc.value === lineOfCoverage;
			});
			if (newLoc) {
				lineOfCoverage = newLoc.prettyValue
			}
			return Ember.String.capitalize(lineOfCoverage);
		}).property('lineOfCoverage'),

		lineOfCoverageDisplayName: function () {
			var lineOfCoverageKey = ['medical', 'dental', 'vision', 'life', 'lifenew', 'std', 'ltd'];
			var lineOfCoverageValue = ['Medical', 'Dental', 'Vision', 'Life', 'Life New', 'Short Term Disability', 'Long Term Disability'];
			return (lineOfCoverageValue.objectAt(lineOfCoverageKey.indexOf(this.get('lineOfCoverage'))));
		}.property('lineOfCoverage'),
	});

	App._UnicardCompanyEnrollmentMixin = Ember.Mixin.create({
		startDate: attr('string'),
		endDate: attr('string'),
		planYearEndDate: Ember.computed.alias('endDate'),
		originalPlanStartDate: attr('string'),
		shouldShowInvoices: attr('boolean'),
		isYetToStart: function () {
			var originalPlanStartDate = this.get('originalPlanStartDate'),
				startDate = this.get('startDate');
			if (!startDate) {
				return false;
			}
			if (zen.parseAmericanDate(startDate)) {
				startDate = zen.americanDateAsDate(startDate);
				originalPlanStartDate = originalPlanStartDate && zen.americanDateAsDate(originalPlanStartDate);
				if (startDate > new Date() && !originalPlanStartDate) {
					return true;
				}
			}
			return false;
		}.property('startDate', 'originalPlanStartDate'),
	});

	App.HsaCompanyContributionChange = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: attr('string'),
		employerContributionForEmployee: attr('number'),
		employerContributionForDependents: attr('number'),
		effectiveDate: attr('string'),
	});

	App.HsaCompanyEnrollment = DS.Model.extend(App._CompanyEnrollmentValidationMixin, App._UnicardCompanyEnrollmentMixin, App._EnrollmentStatusMixin, {
		companyEnrollmentProviderDisplay: attr('string'),
		minEmployeesForBilling: 5,
		planFee: 0,
		costPerEmployeePerMonthForMonthlyBilling: 2.50,
		estimatedMonthlyCost: function () {
			var costPerEmployeePerMonth = this.get('costPerEmployeePerMonthForMonthlyBilling');
			var minEmployeesForBilling = this.get('minEmployeesForBilling');
			if (costPerEmployeePerMonth && minEmployeesForBilling) {
				return costPerEmployeePerMonth * minEmployeesForBilling * 12;
			}
			return 0;

		}.property('costPerEmployeePerMonthForMonthlyBilling', 'minEmployeesForBilling'),
		agreement: DS.belongsTo('App.Document'),
		startDate: attr('string'),
		firstPreTaxWithholdingDate: attr('string'),
		effectiveDate: attr('string'),
		effectiveEndDate: attr('string'),
		computedEffectiveDate: attr('string'),
		employeeMinComputedEffectiveDate: attr('string'),
		employerContributionForEmployee: attr('string'),
		employerContributionForDependents: attr('string'),
		pendingContributionChanges: DS.hasMany('App.HsaCompanyContributionChange'),
		upcomingContributionDate: attr('string'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		isCompanyHealthInsuranceNotCompleted: attr('boolean'),
		optForFrontLoading: attr('boolean'),
		isCompanyHsaWithAlegeus: attr('boolean'),
		deadlineCutoffDayForEmployees: attr('string'),
		currentMaxMonthlyEmployeeContribution: attr('number'),
		currentMaxMonthlyEmployeeWithDependentsContribution: attr('number'),
		currentMaxMonthlyDependentContribution: function () {
			if (this.get('currentMaxMonthlyEmployeeContribution') == null ||
				this.get('currentMaxMonthlyEmployeeWithDependentsContribution') == null) {
				return 0;
			}
			return Math.max(0, this.get('currentMaxMonthlyEmployeeWithDependentsContribution') - this.get('currentMaxMonthlyEmployeeContribution'));
		}.property('currentMaxMonthlyEmployeeContribution', 'currentMaxMonthlyEmployeeWithDependentsContribution'),
		maxMonthlyEmployeeContributionForNextChange: attr('number'),
		maxMonthlyEmployeeWithDependentsContributionForNextChange: attr('number'),
		maximumAnnualEmployeeContribution: attr('number'),
		maximumAnnualEmployeeWithDependentsContribution: attr('number'),
		maxMonthlyDependentsContributionForNextChange: function () {
			if (this.get('maxMonthlyEmployeeWithDependentsContributionForNextChange') == null ||
				this.get('maxMonthlyEmployeeContributionForNextChange') == null) {
				return 0;
			}
			return Math.max(0, this.get('maxMonthlyEmployeeWithDependentsContributionForNextChange') - this.get('maxMonthlyEmployeeContributionForNextChange'));
		}.property('maxMonthlyEmployeeWithDependentsContributionForNextChange', 'maxMonthlyEmployeeContributionForNextChange'),
		contributionForEmployee: function () {
			// This function is for formatting values nicely
			var contribution = this.get('employerContributionForEmployee');
			if (contribution == null) {
				contribution = 0;
			}
			else {
				contribution = parseFloat(contribution);
			}
			return contribution;
		}.property('employerContributionForEmployee'),
		contributionForDependents: function () {
			// This function is for formatting values nicely
			var contribution = this.get('employerContributionForDependents');
			if (contribution == null) {
				contribution = 0;
			}
			else {
				contribution = parseFloat(contribution);
			}
			return contribution;
		}.property('employerContributionForDependents'),
		futureEffectiveEndDate: function () {
			 var today = moment()
			if (moment(this.get('effectiveEndDate')).isAfter(today)){
				return true;
			}
			if (moment(this.get('effectiveEndDate')).isBefore(today)){
				return false;
			}
			return false;
		}.property('effectiveEndDate'),
		employerContributionForEmployeesWithDependents: function () {
			var contributionForEmployee = this.get('employerContributionForEmployee');
			if (!contributionForEmployee) {
				contributionForEmployee = 0;
			}
			else {
				contributionForEmployee = parseFloat(contributionForEmployee);
			}

			var contributionForDependents = this.get('employerContributionForDependents');
			if (!contributionForDependents) {
				contributionForDependents = 0;
			}
			else {
				contributionForDependents = parseFloat(contributionForDependents);
			}

			return parseFloat(contributionForEmployee + contributionForDependents);
		}.property('employerContributionForEmployee', 'employerContributionForDependents'),
		company: DS.belongsTo('App.Company'),
	});

	App._UnicardEmployeeEnrollmentMixin = Ember.Mixin.create({
		startDate: attr('string'),
		originalPlanStartDate: attr('string'),
		isYetToStart: function () {
			var originalPlanStartDate = this.get('originalPlanStartDate'),
				startDate = this.get('startDate');
			if (!startDate) {
				return false;
			}
			if (zen.parseAmericanDate(startDate)) {
				startDate = zen.americanDateAsDate(startDate);
				originalPlanStartDate = originalPlanStartDate && zen.americanDateAsDate(originalPlanStartDate);
				if (startDate > new Date() && !originalPlanStartDate) {
					return true;
				}
			}
			return false;
		}.property('startDate', 'originalPlanStartDate'),
	});

	App.HsaEmployeeContributionChange = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		status: attr('string'),
		employeeContribution: attr('number'),
		effectiveDate: attr('string'),
		reasonCode: attr('string'),
	});

	App.HsaEmployeeEnrollment = DS.Model.extend(App._EmployeeEnrollmentValidationMixin, App._UnicardEmployeeEnrollmentMixin, App._EnrollmentStatusMixin, {
		agreement: DS.belongsTo('App.Document'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		companyEnrollmentProviderDisplay: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		hsaBankUsername: attr('string'),
		hsaBankPassword: attr('string'),
		contribution: attr('string'),
		employeeContribution: attr('string'),
		overrideEmployerContributionForEmployee: attr('number'),
		overrideUpcomingEmployerEmployeeContribution: attr('number'),
		overrideEmployerEmployeeContributionForNextChange: attr('number'),
		overrideEmployerContributionForDependents: attr('number'),
		overrideUpcmoingEmployerDependentsContribution: attr('number'),
		overrideEmployerDependentsContributionForNextChange: attr('number'),
		overrideEmployerTotalContribution: attr('number'),
		overrideCurrentEmployerTotalContribution: attr('number'),
		overrideUpcomingEmployerTotalContribution: attr('number'),
		overrideEmployerTotalContributionForNextChange: attr('number'),
		upcomingDeduction: attr('number'),
		deductionForNextChange: attr('number'),
		pendingContributionChanges: DS.hasMany('App.HsaEmployeeContributionChange'),
		previousYTDContribution: attr('string'),
		frontLoadedCompanyContribution: attr('number'),
		maxMonthlyTotalContributionForNextChange: attr('number'),
		maximumComputedAnnualHsaContribution: attr('number'),
		maximumComputedAnnualFamilyHsaContribution: attr('number'),
		overrideFrontLoadedCompanyContribution: attr('number'),
		totalMonthsForEmployeeContribution: attr('number'),
		previousYTDContributionRequired: attr('boolean'),
		deductionPerPayPeriod: attr('string'),
		contributionPerPayPeriod: attr('string'),
		startDate: attr('string'),
		enrolledDate: attr('string'),
		effectiveDate: attr('string'),
		computedStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		currentHsaYearStartDate: attr('string'),
		shouldShowContributionChangeForCompany: function () {
			return this.get('isEmployeeContributionChangedForUpcomingMonth') && this.get('employee.hasHsa') && this.get('employee.shouldShowAsEnrolledInHsa');
		}.property('isEmployeeContributionChangedForUpcomingMonth', 'employee.hasHsa', 'employee.shouldShowAsEnrolledInHsa'),
		isEmployeeContributionChangedForUpcomingMonth: function () {
			return this.get('employeeContribution') != this.get('upcomingDeduction');
		}.property('employeeContribution', 'upcomingDeduction'),
		isEmployerContributionChangedForUpcomingMonth: function () {
			return this.get('overrideEmployerTotalContribution') != this.get('overrideUpcomingEmployerTotalContribution');
		}.property('overrideEmployerTotalContribution', 'overrideUpcomingEmployerTotalContribution'),
		isEmployerContributionChangedForStartDate: function () {
			return this.get('isYetToStart') && (this.get('overrideEmployerTotalContribution') != this.get('overrideCurrentEmployerTotalContribution'));
		}.property('isYetToStart', 'overrideEmployerTotalContribution', 'overrideCurrentEmployerTotalContribution'),
		isEmployeeContributionChangedForNextChangeDate: function () {
			return this.get('upcomingDeduction') != this.get('deductionForNextChange');
		}.property('upcomingDeduction', 'deductionForNextChange'),
		isEmployerContributionChangedForNextChangeDate: function () {
			return this.get('overrideUpcomingEmployerTotalContribution') != this.get('overrideEmployerTotalContributionForNextChange');
		}.property('overrideUpcomingEmployerTotalContribution', 'overrideEmployerTotalContributionForNextChange'),
		currentHsaYear: function () {
			if (this.get('currentHsaYearStartDate') == null) {
				return null;
			}
			return new Date(this.get('currentHsaYearStartDate')).getFullYear();
		}.property('currentHsaYearStartDate'),
		hsaEffectiveYear: function () {
			return new Date(this.get('computedStartDate')).getFullYear();
		}.property('computedStartDate'),
		citizenshipSelection: function () {
			if (this.get('isUSCitizen') == null || this.get('isUSCitizen') == undefined) {
				return null;
			}
			if (this.get('isUSCitizen')) {
				return 'yes';
			}

			return 'no';
		}.property('isUSCitizen'),
		authorizedSignerSelection: null,
		eStatementsSelection: null,
		duplicateChecksSelection: null,
		hasNoUSCitizenship: function () {
			return this.get('citizenshipSelection') != null && this.get('citizenshipSelection') == 'no';
		}.property('citizenshipSelection'),
		hasAuthorizedSigner: function () {
			return this.get('authorizedSignerSelection') != null && this.get('authorizedSignerSelection') == 'yes';
		}.property('authorizedSignerSelection'),
		isUSCitizen: attr('raw'),
		isResidentAlien: attr('boolean'),
		countryOfCitizenship: attr('string'),
		eStatementsRequired: attr('boolean'),
		duplicateChecksRequired: attr('boolean'),
		// computed properties
		isFillingOut: Ember.computed.equal('status', 'filling-out'),
		isSetupComplete: Ember.computed.equal('status', 'complete'),
		isCanceled: Ember.computed.equal('status', 'canceled'),
		isSetupCompleteOrCancelled: function () {
			return this.get('status') == 'canceled' || this.get('status') == 'complete';
		}.property('status'),
		isPastCancelationDate: function () {
			var today = new Date();
			var lastdate = new Date(this.get('effectiveEndDate'));
			if (today < lastdate) {
				return false;
			}
			return true;
		}.property('effectiveEndDate'),
		overrideEmployerTotalContributionCommas: function () {
			// Start of Weebly Hack - this can be removed on or after January 1, 2016
			if (this.get('employee.company.id') == 1739 && this.get('employee.compType') == 'H') {
				var totalAmount = 83.33;
				if (this.get('hasDependents')) {
					totalAmount = 125.00;
				}
				return totalAmount.toLocaleString();
			}
			// End of Weebly Hack
			return this.get('overrideEmployerTotalContribution').toLocaleString();
		}.property('overrideEmployerTotalContribution', 'employee.company.id', 'employee.compType', 'hasDependents'),
	});

	App._UnicardPlanCostMixin = Ember.Mixin.create({
		costPerEmployeePerMonthForAnnualBilling: 4,
		costPerEmployeePerMonthForMonthlyBilling: 5,

		contractLength: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));
			var endDate = zen.americanDateAsDate(this.get('endDateForBilling'));
			if (startDate && endDate) {
				startDate = moment(startDate);
				endDate = moment(endDate);
				return endDate.add(1, 'days').diff(startDate, 'months');
			}
			return null;
		}.property('startDate', 'endDateForBilling'),

		costPerEmployeePerMonth: function () {
			if (this.get('billingPlan') == 'MON') {
				return this.get('costPerEmployeePerMonthForMonthlyBilling');
			}
			return this.get('costPerEmployeePerMonthForAnnualBilling');
		}.property('billingPlan'),

		costPerEmployeePerMonthForAlternateBillingPlan: function () {
			if (this.get('billingPlan') == 'MON') {
				return this.get('costPerEmployeePerMonthForAnnualBilling');
			}
			return this.get('costPerEmployeePerMonthForMonthlyBilling');;
		}.property('billingPlan'),

		alternateBillingMonthlyCost: function () {
			var costPerEmployeePerMonth = this.get('costPerEmployeePerMonthForAlternateBillingPlan');
			var minEmployeesForBilling = this.get('minEmployeesForBilling');
			if (costPerEmployeePerMonth && minEmployeesForBilling) {
				return costPerEmployeePerMonth * minEmployeesForBilling;
			}
			return 0;
		}.property('costPerEmployeePerMonthForAlternateBillingPlan', 'minEmployeesForBilling'),

		estimatedPlanCost: function () {
			var planFee = this.get('planFee') || 0;
			return planFee + this.get('estimatedMonthlyCost') * this.get('contractLength');
		}.property('estimatedMonthlyCost', 'contractLength', 'planFee'),

		estimatedMonthlyCost: function () {
			var costPerEmployeePerMonth = this.get('costPerEmployeePerMonth');
			var minEmployeesForBilling = this.get('minEmployeesForBilling');
			if (costPerEmployeePerMonth && minEmployeesForBilling) {
				return costPerEmployeePerMonth * minEmployeesForBilling;
			}
			return 0;

		}.property('costPerEmployeePerMonth', 'minEmployeesForBilling'),
	});


	App._FsaCompanyMixin = Ember.Mixin.create({
		endDate: attr('string'),
		planYearEndDate: Ember.computed.alias('endDate'),
		firstPayrollAfterStart: attr('string'),
		medicalCareContribution: attr('string'),
		lpfsaContribution: attr('string'),
		dependentCareContribution: attr('string'),
		isRollover: attr('raw'),
		rolloverAmount: attr('number'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		securityDepositName: attr('string'),
		securityDepositTitle: attr('string'),
		securityDepositDate: attr('string'),
		securityDepositSignature: attr('string'),
		name: attr('string'),
		title: attr('string'),
		date: attr('string'),
		signature: attr('string'),
		planUrl: attr('string'),
		arePlanDocumentsAvailable: attr('boolean'),
		participatingEmployeeCount: attr('number'),
		complianceDone: attr('boolean'),
		keyEmployeesDone: attr('boolean'),
		highEmployeesDone: attr('boolean'),
		billingPlan: attr('string'),
		isPastEnrollmentDeadlineForContributionChange: attr('boolean'),
		isWithinDeadlineForContributionChange: Ember.computed.not('isPastEnrollmentDeadlineForContributionChange'),
		isAnnualPlan: function () {
			return this.get('billingPlan') == 'ANN';
		}.property('billingPlan'),
		isMonthlyPlan: function () {
			return this.get('billingPlan') == 'MON';
		}.property('billingPlan'),
		daysLeftToRenew: function () {
			var planYearEndDate = zen.americanDateAsDate(this.get('planYearEndDate'));
			return moment(planYearEndDate).date(15).endOf('day').diff(moment(), 'days');
		}.property('planYearEndDate'),
		monthlyMedical: function () {
			var amount = this.get('medicalCareContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('medicalCareContribution'),
		monthlyDependent: function () {
			var amount = this.get('dependentCareContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('dependentCareContribution'),
		fullPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).add(1, 'years').subtract(1, 'days').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		shortPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).endOf('year').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		shortPlanDifferentFromFullPlan: function () {
			return this.get('shortPlanYearEndDate') != this.get('fullPlanYearEndDate');
		}.property('startDate'),
		startDateMonth: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).format('MMMM');
		}.property('startDate'),
		monthAfterStartDateMonth: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).add(1, 'months');
		}.property('startDate'),
		fifteenthOfMonthBeforeStartDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).subtract(1, 'month').date(15);
		}.property('startDate'),
		finalCalculationsDate: function () {
			var planYearEndDate = zen.americanDateAsDate(this.get('planYearEndDate'));

			return planYearEndDate && moment(planYearEndDate).add(3, 'months').add(1, 'days');
		}.property('planYearEndDate'),
		claimsEndDate: function () {
			var planYearEndDate = this.get('planYearEndDate');
			return planYearEndDate && moment(planYearEndDate).add(3, 'months');
		}.property('planYearEndDate'),
		endOfTwoAndHalfMonths: function () {
			var planYearEndDate = this.get('planYearEndDate');
			return planYearEndDate && moment(planYearEndDate).add(2, 'months').add(15, 'days');
		}.property('planYearEndDate'),
	});

	App.FsaCompanyEnrollment = DS.Model.extend(App._CompanyEnrollmentValidationMixin, App._UnicardCompanyEnrollmentMixin, App._EnrollmentStatusMixin, App._FsaCompanyMixin, {
		planDocument: DS.belongsTo('App.Document'),
		summaryPlanDescription: DS.belongsTo('App.Document'),
		agreement: DS.belongsTo('App.Document'),
		provider: attr('string'),
		companyEnrollmentProviderDisplay: attr('string'),
		authDate: attr('string'),
		company: DS.belongsTo('App.Company'),
		amfCode: attr('string'),
		nextOpenEnrollment: attr('string'),
		openEnrollmentMonth: attr('string'),
		payFrequencies: attr('string'),
		maxMedicalCareContribution: attr('number'),
		maxLPFSAContribution: attr('number'),
		maxDependentCareContribution: attr('number'),
		tests: attr('raw'),
		current: DS.belongsTo('App.FsaCompanyPlan'),
		renewal: DS.belongsTo('App.FsaCompanyPlan'),
		previous: DS.belongsTo('App.FsaCompanyPlan'),
		fsaGracePeriod: attr('string'),
		fsaRunOutDate: attr('string'),
	});


	App._FsaCompanyPlanPropertiesMixin = Ember.Mixin.create({
		// TODO(nvojvodic): not all of these may be needed - cleanup
		minEmployeesForBilling: 5,
		planFee: 150,
		endDateForBilling: Ember.computed.alias('endDate'),
		company: Ember.computed.alias('fsa.company'),
		planYearEndDate: Ember.computed.alias('endDate'),
		isWithinDeadlineForContributionChange: Ember.computed.not('isPastEnrollmentDeadlineForContributionChange'),
		openEnrollmentMonthAsDate: attr('string'),
		isAnnualPlan: Ember.computed.equal('billingPlan', 'ANN'),
		isMonthlyPlan: Ember.computed.equal('billingPlan', 'MON'),
		daysLeftToRenew: function () {
			var endDate = zen.americanDateAsDate(this.get('endDate'));
			return moment(endDate).date(15).endOf('day').diff(moment(), 'days');
		}.property('endDate'),
		monthlyMedical: function () {
			var amount = this.get('medicalCareContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('medicalCareContribution'),
		monthlyDependent: function () {
			var amount = this.get('dependentCareContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('dependentCareContribution'),
		fullPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).add(1, 'years').subtract(1, 'days').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		shortPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).endOf('year').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		shortPlanDifferentFromFullPlan: function () {
			return !(this.get('shortPlanYearEndDate') == this.get('fullPlanYearEndDate'));
		}.property('startDate'),
		startDateMonth: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).format('MMMM');
		}.property('startDate'),
		monthAfterStartDateMonth: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).add(1, 'months');
		}.property('startDate'),
		fifteenthOfMonthBeforeStartDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));

			return startDate && moment(startDate).subtract(1, 'month').date(15);
		}.property('startDate'),
		finalCalculationsDate: function () {
			var endDate = zen.americanDateAsDate(this.get('endDate'));

			return endDate && moment(endDate).add(3, 'months').add(1, 'days');
		}.property('endDate'),
		claimsEndDate: function () {
			return this.get('fsaRunOutDate');
		}.property('endDate'),
		isPastClaimsEndDate: function () {
			var claimsEndDate = this.get('claimsEndDate');
			return claimsEndDate && moment().isAfter(claimsEndDate);
		}.property('claimsEndDate'),
		endOfTwoAndHalfMonths: function () {
			var endDate = this.get('endDate');
			return endDate && moment(endDate).add(2, 'months').add(15, 'days');
		}.property('endDate'),
		isWithinDeadline: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate'));
			return moment().isBefore(moment(startDate).subtract(1, 'days').date(15).endOf('day'));
		}.property('startDate'),
		standardEmployeeEnrollmentDeadline: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY').subtract(1, 'months').date(25).format('MM/DD/YYYY');
		}.property('startDate'),
	});

	App.FsaCompanyPlan = DS.Model.extend(App._EnrollmentStatusMixin, App._FsaCompanyPlanPropertiesMixin, App._UnicardPlanCostMixin, {
		fsa: DS.belongsTo('App.FsaCompanyEnrollment'),
		agreement: DS.belongsTo('App.Document'),
		agreementSignature: DS.belongsTo('App.Signature'),
		authSignature: DS.belongsTo('App.Signature'),
		isBankInfoComplete: attr('boolean'),
		isCompanyInfoComplete: attr('boolean'),
		startDate: attr('string'),
		maxMedicalCareContribution: attr('number'),
		maxLPFSAContribution: attr('number'),
		isLPFSAPlan: attr('boolean'),
		maxDependentCareContribution: attr('number'),
		placement: attr('string'),
		endDate: attr('string'),
		effectiveEndDate: attr('string'),
		firstPayrollAfterStart: attr('string'),
		medicalCareContribution: attr('string'),
		lpfsaContribution: attr('string'),
		dependentCareContribution: attr('string'),
		isRollover: attr('raw'),
		isDcaRollover: attr('raw'),
		rolloverAmount: attr('number'),
		dcaRolloverAmount: attr('number'),
		participatingEmployeeCount: attr('number'),
		complianceDone: attr('boolean'),
		keyEmployeesDone: attr('boolean'),
		highEmployeesDone: attr('boolean'),
		billingPlan: attr('string'),
		isPastEnrollmentDeadlineForContributionChange: attr('boolean'),
		gracePeriodEndDate: attr('string'),
		maxFsaRollOverLimit: attr('string'),
		effectiveEndDate: attr('string'),
		fsaGracePeriod: attr('string'),
		fsaGracePeriodEndDate: attr('string'),
		dcaGracePeriodEndDate: attr('string'),
		fsaRunOutDate: attr('string'),
		maxAnnualDcaContribution: attr('string'),
		maxDcaYearlyLimit: attr('string'),
		isPastEffectiveEndDate: function () {
			var effectiveEndDate = moment(this.get('effectiveEndDate'));
			return effectiveEndDate && moment().isAfter(effectiveEndDate);
		}.property('effectiveEndDate'),

	});

	App._FsaEmployeeMixin = Ember.Mixin.create({
		isOptedOutOfMedical: attr('boolean'),
		isOptedOutOfLPFSA: attr('boolean'),
		isOptedOutOfDependent: attr('boolean'),
		computedStartDate: attr('string'),
		contribution: attr('string'),
		medicalCareContribution: attr('string'),
		dependentCareContribution: attr('string'),
		name: attr('string'),
		date: attr('string'),
		signature: attr('string'),
		medicalContribution: attr('string'),
		lpfsaContribution: attr('string'),
		dependentContribution: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		payPeriodDivisor: attr('string'),
		employerMaxMedicalContribution: attr('string'),
		enrollmentDeadline: attr('string'),
		employerDependentContribution: attr('string'),
		isEnrolledPlan: attr('boolean'),
		isPastEnrollmentDeadlineForContributionChange: attr('boolean'),
		employerMedicalContribution: function () {
			var min = Math.min,
				max = Math.max,
				employeeContribution = +this.get('medicalCareContribution'),
				employerContribution = +this.get('companyCurrFsa.medicalCareContribution');

			if (Number.isNaN(employeeContribution) || Number.isNaN(employerContribution)) {
				return 0;
			}

			return min(max(min(employeeContribution, employerContribution), 500), employerContribution);
		}.property('companyCurrFsa.medicalCareContribution', 'medicalCareContribution'),
		employerLPFSAContribution: function () {
			const LPFSA_ER_MATCHABLE_CONTRIBUTION = 500;
			var min = Math.min,
				max = Math.max,
				employeeContribution = +this.get('lpfsaContribution'),
				employerContribution = +this.get('companyCurrFsa.lpfsaContribution');

			if (Number.isNaN(employeeContribution) || Number.isNaN(employerContribution)) {
				return 0;
			}

			return min(max(min(employeeContribution, employerContribution), LPFSA_ER_MATCHABLE_CONTRIBUTION), employerContribution);
		}.property('companyCurrFsa.lpfsaContribution', 'lpfsaContribution'),
		employeeContribution: function () {
			var total = 0;
			var medicalCareContribution = this.get('medicalCareContribution');
			if (medicalCareContribution) {
				total += parseFloat(medicalCareContribution);
			}

			var lpfsaContribution = this.get('lpfsaContribution');
			if (lpfsaContribution) {
				total += parseFloat(lpfsaContribution);
			}

			var dependentCareContribution = this.get('dependentCareContribution');
			if (dependentCareContribution) {
				total += parseFloat(dependentCareContribution);
			}

			return parseFloat(total.toFixed(2));
		}.property('medicalCareContribution', 'dependentCareContribution', 'lpfsaContribution'),
		payperiodMedical: function () {
			var amount = parseFloat(this.get('medicalCareContribution') / this.get('payPeriodDivisor'));
			if (amount) {
				return parseFloat(amount.toFixed(2));
			}

			return 0;
		}.property('medicalCareContribution', 'payPeriodDivisor'),
		payperiodLpfsa: function () {
			var amount = parseFloat(this.get('lpfsaContribution') / this.get('payPeriodDivisor'));
			if (amount) {
				return parseFloat(amount.toFixed(2));
			}

			return 0;
		}.property('lpfsaContribution', 'payPeriodDivisor'),
		payperiodDependent: function () {
			var amount = parseFloat(this.get('dependentCareContribution') / this.get('payPeriodDivisor'));
			if (amount) {
				return parseFloat(amount.toFixed(2));
			}

			return 0;
		}.property('dependentCareContribution', 'payPeriodDivisor'),
		payperiodTotal: function () {
			var medicalAmount = parseFloat(this.get('medicalCareContribution') / this.get('payPeriodDivisor'));
			var lpfsaAmount = parseFloat(this.get('lpfsaContribution') / this.get('payPeriodDivisor'));
			var dependentAmount = parseFloat(this.get('dependentCareContribution') / this.get('payPeriodDivisor'));
			var total = parseFloat(medicalAmount + lpfsaAmount + dependentAmount);
			return parseFloat(total.toFixed(2));
		}.property('medicalCareContribution', 'lpfsaContribution', 'dependentCareContribution', 'payPeriodDivisor'),
		formattedEffectiveDate: function () {
			var startDate = this.get('startDate');
			if (!startDate) {
				return 'N/A';
			}
			return moment(zen.americanDateAsDate(startDate)).format("MMM Do, YYYY");
		}.property('startDate'),
		daysLeftToRenew: function () {
			var planYearEndDate = zen.americanDateAsDate(this.get('employee.company.fsa.planYearEndDate'));
			return moment(planYearEndDate).date(25).endOf('day').diff(moment(), 'days');
		}.property('employee.company.fsa.planYearEndDate'),
		canEmployeeSignupNow: function () {
			// Don't display FSA Card unless FsaEmployeeEnrollment has been loaded
			if (!this.get('employee.fsa')) {
				return false;
			}

			var companyRenewalStatus = this.get('employee.company.fsa.renewal.status'),
				isComplete = this.get('isComplete'),
				employeeRenewal = this.get('renewal'),
				computedStartDate = zen.americanDateAsDate(this.get('computedStartDate')),
				planYearEndDate = zen.americanDateAsDate(this.get('employee.company.fsa.planYearEndDate'));

			if (isComplete) {
				return true;
			}

			if (!employeeRenewal && companyRenewalStatus == 'complete' && computedStartDate > planYearEndDate) {
				// Employee hasn't completed and doesn't have a renewal.
				// Company has an active renewal and employee has a computed start date that is past the current plan's plan year end date.
				return true;
			}

			var isPastEnrollmentDeadline = this.get('isPastEnrollmentDeadline');
			if (isPastEnrollmentDeadline && computedStartDate < planYearEndDate) {
				return false;
			}

			return true;
		}.property('employee.fsa', 'employee.company.fsa.renewal.status', 'renewal', 'isPastEnrollmentDeadline', 'isComplete', 'computedStartDate', 'employee.company.fsa.planYearEndDate'),
		useCompanyRenewalContribution: function () {
			// Not returning false when status == 'complete' so that employees enrolling in this flow
			// see the company's renewal contributions in their manage page
			var companyRenewalStatus = this.get('employee.company.fsa.renewal.status'),
				employeeRenewal = this.get('renewal'),
				startDateAfterPlanYearEndDate = this.get('startDateAfterPlanYearEndDate');

			if (!employeeRenewal && companyRenewalStatus == 'complete' && startDateAfterPlanYearEndDate) {
				return true;
			}

			return false;
		}.property('employee.company.fsa.renewal.status', 'renewal', 'startDateAfterPlanYearEndDate'),
		startDateAfterPlanYearEndDate: function () {
			// Considering both startDate and computedStartDate so completed enrollments are also considered.
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				computedStartDate = zen.americanDateAsDate(this.get('computedStartDate')),
				planYearEndDate = zen.americanDateAsDate(this.get('employee.company.fsa.planYearEndDate'));

			return (startDate || computedStartDate) > planYearEndDate;
		}.property('startDate', 'computedStartDate', 'employee.company.fsa.planYearEndDate'),
		hasDependentCareContribution: Ember.computed.gt('dependentCareContribution', 0),
		isWithinDeadlineForContributionChange: Ember.computed.not('isPastEnrollmentDeadlineForContributionChange'),
	});

	App.FsaEmployeeEnrollment = DS.Model.extend(App._EmployeeEnrollmentValidationMixin, App._UnicardEmployeeEnrollmentMixin, App._EnrollmentStatusMixin, App._FsaEmployeeMixin, {
		agreement: DS.belongsTo('App.Document'),
		summaryPlanDescriptionUrl: attr('string'),
		companyCurrFsa: Ember.computed.alias('employee.company.fsa'),
		companyEnrollmentProviderDisplay: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		medicalContributionPerPayPeriod: attr('string'),
		dependentContributionPerPayPeriod: attr('string'),
		employerMedicalContributionPerMonth: attr('string'),
		employerDependentContributionPerMonth: attr('string'),
		employerMedicalContributionPerPayPeriod: attr('string'),
		employerDependentContributionPerPayPeriod: attr('string'),
		totalContributionPerPayPeriod: attr('string'),
		isOpenEnrollmentMonth: attr('string'),
		openEnrollmentMonth: attr('string'),
		isPastEnrollmentDeadline: attr('boolean'),
		isWithinDeadline: Ember.computed.not('isPastEnrollmentDeadline'),
		renewal: DS.belongsTo('App.FsaEmployeePlan'),
		isKeyEmployee: attr('raw'),
		isHighlyCompensatedEmployee: attr('raw'),
		date: attr('string'),
		current: DS.belongsTo('App.FsaEmployeePlan'),
		previous: DS.belongsTo('App.FsaEmployeePlan'),
		hasTransactionsToSubstantiate: attr('boolean'),

		lastDayAcrossPlansToSubmitClaims: function () {
			var currentPlanLastDayToSubmitClaims = this.get('current.lastDayToSubmitClaims') ? moment(this.get('current.lastDayToSubmitClaims')) : null;

			var previousPlanLastDayToSubmitClaims = this.get('previous.lastDayToSubmitClaims') ? moment(this.get('previous.lastDayToSubmitClaims')) : null;

			if (currentPlanLastDayToSubmitClaims && previousPlanLastDayToSubmitClaims) {
				return moment.max(currentPlanLastDayToSubmitClaims, previousPlanLastDayToSubmitClaims);
			}
			else {
				return currentPlanLastDayToSubmitClaims ? currentPlanLastDayToSubmitClaims : previousPlanLastDayToSubmitClaims;
			}
		}.property('current.lastDayToSubmitClaims', 'previous.lastDayToSubmitClaims', 'current.isComplete', 'previous.isComplete', 'employee.isEligibleForFSA'),

		lastDayOfEligibility: function () {
			var currentPlanEffectiveEndDate = this.get('current.effectiveEndDate') ? moment(this.get('current.effectiveEndDate')) : null;
			var previousPlanEffectiveEndDate = this.get('previous.effectiveEndDate') ? moment(this.get('previous.effectiveEndDate')) : null;

			if (currentPlanEffectiveEndDate && previousPlanEffectiveEndDate) {
				return moment.max(currentPlanEffectiveEndDate, previousPlanEffectiveEndDate);
			}
			else {
				return currentPlanEffectiveEndDate ? currentPlanEffectiveEndDate : previousPlanEffectiveEndDate;
			}
		}.property('current.effectiveEndDate', 'previous.effectiveEndDate', 'current.isComplete', 'previous.isComplete', 'employee.isEligibleForFSA'),

		hasRunOutPeriodExpired: function () {
			if (!this.get('lastDayAcrossPlansToSubmitClaims')) {
				return false;
			}
			return this.get('lastDayAcrossPlansToSubmitClaims').isBefore(moment(), 'day');
		}.property('lastDayAcrossPlansToSubmitClaims'),

		isEligibleToSubmitClaims: function () {
			return !this.get('hasRunOutPeriodExpired');
		}.property('hasRunOutPeriodExpired'),
	});

	App.FsaEmployeePlan = DS.Model.extend(App._EnrollmentStatusMixin, App._FsaEmployeeMixin, {
		agreementSignature: DS.belongsTo('App.Signature'),
		companyCurrFsa: Ember.computed.alias('companyPlan'),
		companyPlan: DS.belongsTo('App.FsaCompanyPlan'),
		isBankInfoComplete: attr('boolean'),
		startDate: attr('string'),
		payPeriodDivisor: attr('number'),
		fsa: DS.belongsTo('App.FsaEmployeeEnrollment'),
		planLength: function () {
			if (this.get('startDate') && this.get('companyPlan.endDate')) {
				return moment(this.get('companyPlan.endDate')).add(1, 'days').diff(moment(this.get('startDate')), 'months');
			} else {
				return null;
			}
		}.property('startDate', 'companyPlan.endDate'),
		medicalContributionPerPayPeriod: function () {
			var medicalCareContribution = this.get('medicalCareContribution') || 0.0;
			return medicalCareContribution / this.get('payPeriodDivisor');
		}.property('medicalCareContribution', 'payPeriodDivisor'),
		dependentContributionPerPayPeriod: function () {
			var dependentCareContribution = this.get('dependentCareContribution') || 0.0;
			return dependentCareContribution / this.get('payPeriodDivisor');
		}.property('dependentCareContribution', 'payPeriodDivisor'),
		isWithinDeadline: Ember.computed.not('isPastEnrollmentDeadlineForContributionChange'),
		employee: Ember.computed.alias('fsa.employee'),
		placement: attr('string'),
		showContributionBreakdownByMonth: Ember.computed.equal('employee.payFrequency', 'BW'),
		contributionPerPayCheckOrMonthly: function (contribution) {
			if (!Ember.isEmpty(contribution)) {
				var divisor = this.get('payPeriodDivisor');
				if (this.get('showContributionBreakdownByMonth')) {
					divisor = this.get('planLength');
				}
				if (divisor) {
					return contribution / divisor;
				}
			}
			return null;
		},
		medicalCarePerPayPeriodOrMonthly: Ember.computed('medicalCareContribution', 'showContributionBreakdownByMonth', function () {
			return this.contributionPerPayCheckOrMonthly(this.get('medicalCareContribution'));
		}),
		lpfsaPerPayPeriodOrMonthly: Ember.computed('lpfsaContribution', 'showContributionBreakdownByMonth', function () {
			return this.contributionPerPayCheckOrMonthly(this.get('lpfsaContribution'));
		}),
		dependentCarePerPayPeriodOrMonthly: Ember.computed('dependentCareContribution', 'showContributionBreakdownByMonth', function () {
			return this.contributionPerPayCheckOrMonthly(this.get('dependentCareContribution'));
		}),
		effectiveEndDate: attr('string'),
		lastDayToSubmitClaims: attr('string'),
		isPastEffectiveEndDate: function () {
			var effectiveEndDate = moment(this.get('effectiveEndDate'));
			return effectiveEndDate && moment().isAfter(effectiveEndDate);
		}.property('effectiveEndDate'),
		employeeMedicalContribution: attr('string'),
		employeeLPFSAContribution: attr('string'),
		employeeDependentContribution: attr('string')
	});

	App._HraEnrollmentMixin = Ember.Mixin.create({
		startDateWithZenefits: attr('string'),
		isYetToStart: function () {
			var startDateWithZenefits = this.get('startDateWithZenefits');
			if (!startDateWithZenefits) {
				return true;
			}
			if (zen.parseAmericanDate(startDateWithZenefits)) {
				startDateWithZenefits = zen.americanDateAsDate(startDateWithZenefits);
				if (startDateWithZenefits > new Date()) {
					return true;
				}
			}
			return false;
		}.property('startDateWithZenefits'),
	});

	App.HraCompanyEnrollment = DS.Model.extend(App._HraEnrollmentMixin, App._CompanyEnrollmentValidationMixin, {
		shouldShowHraCard: attr('boolean'),
		planDocument: DS.belongsTo('App.Document'),
		summaryPlanDescription: DS.belongsTo('App.Document'),
		companyEnrollmentProviderDisplay: attr('string'),
		provider: attr('string'),
		company: DS.belongsTo('App.Company'),
		amfCode: attr('string'),
		payFrequencies: attr('string'),
		planUrl: attr('string'),
		arePlanDocumentsAvailable: attr('boolean'),
		complianceDone: attr('boolean'),
		highEmployeesDone: attr('boolean'),
		shouldShowInvoices: attr('boolean'),
		next: DS.belongsTo('App.HraCompanyPlan'),
		current: DS.belongsTo('App.HraCompanyPlan'),
		firstAvailablePlan: DS.belongsTo('App.HraCompanyPlan'),
	});

	App.HraCompanyPlan = DS.Model.extend(App._EnrollmentStatusMixin, App._UnicardCompanyEnrollmentMixin, App._UnicardPlanCostMixin, {
		hra: DS.belongsTo('App.HraCompanyEnrollment'),
		firstPayrollAfterStart: attr('string'),
		employeeContribution: attr('string'),
		dependentContribution: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		isBankInfoComplete: attr('boolean'),
		autoEnrollEmployees: attr('boolean'),
		agreement: DS.belongsTo('App.Document'),
		agreementSignature: DS.belongsTo('App.Signature'),
		authSignature: DS.belongsTo('App.Signature'),
		openEnrollmentMonth: attr('string'),
		participatingEmployeeCount: attr('number'),
		isPastEnrollmentDeadline: attr('boolean'),
		isWithinDeadlineForContributionChange: Ember.computed.not('isPastEnrollmentDeadline'),
		minEmployeesForBilling: 8,
		planFee: 150,
		endDateForBilling: Ember.computed.alias('endDate'),
		employeeContributionLimit: attr('number'),
		dependentContributionLimit: attr('number'),
		billingPlan: 'MON',
		totalContribution: function () {
			return parseFloat(this.get('employeeContribution') || 0) + parseFloat(this.get('dependentContribution') || 0);
		}.property('employeeContribution', 'dependentContribution'),
		monthlyEmployee: function () {
			var amount = this.get('employeeContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('employeeContribution'),
		monthlyDependent: function () {
			var amount = this.get('dependentContribution') / 12;
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('dependentContribution'),
		employeeContributionCommas: function () {
			var amount = this.get('employeeContribution');
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('employeeContribution'),
		dependentContributionCommas: function () {
			var amount = this.get('dependentContribution');
			if (amount) {
				return Math.round(String(amount).replace(/,/g, '')).toLocaleString();
			}

			return 0;
		}.property('dependentContribution'),
		shortPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).endOf('year').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		fullPlanYearEndDate: function () {
			var startDate = zen.americanDateAsDate(this.get('startDate')),
				endDate = startDate && moment(startDate).add(1, 'years').subtract(1, 'days').format('MM/DD/YYYY');

			return endDate;
		}.property('startDate'),
		shortPlanDifferentFromFullPlan: function () {
			return !(this.get('shortPlanYearEndDate') == this.get('fullPlanYearEndDate'));
		}.property('startDate'),
		standardEmployeeEnrollmentDeadline: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY').subtract(1, 'months').date(25).format('MM/DD/YYYY');
		}.property('startDate'),

	});

	App.HraEmployeeEnrollment = DS.Model.extend(App._HraEnrollmentMixin, App._EmployeeEnrollmentValidationMixin, {
		computedStartDate: attr('string'),
		summaryPlanDescriptionUrl: attr('string'),
		companyEnrollmentProviderDisplay: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		enrolledInMedicare: attr('string'),
		medicareID: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		isHighlyCompensatedEmployee: attr('boolean'),
		isMedicareEnrolled: Ember.computed.equal('enrolledInMedicare', 'yes'),
		isMedicareComplete: function () {
			if (this.get('enrolledInMedicare') == "no") {
				return true;
			}

			if (this.get('enrolledInMedicare') == "yes") {
				if (this.get('medicareID')) {
					return true;
				}
			}
			return false;
		}.property('enrolledInMedicare', 'medicareID'),
		next: DS.belongsTo('App.HraEmployeePlan'),
		current: DS.belongsTo('App.HraEmployeePlan'),
		previous: DS.belongsTo('App.HraEmployeePlan'),
		firstAvailablePlan: DS.belongsTo('App.HraEmployeePlan'),
		isEligibleToSubmitClaims: true,
	});

	App.HraEmployeePlan = DS.Model.extend(App._EnrollmentStatusMixin, {
		hra: DS.belongsTo('App.HraEmployeeEnrollment'),
		companyPlan: DS.belongsTo('App.HraCompanyPlan'),
		startDate: attr('string'),
		endDate: attr('string'),
		agreement: DS.belongsTo('App.Document'),
		agreementSignature: DS.belongsTo('App.Signature'),
		autoEnrolled: attr('boolean'),
		isWithinDeadline: Ember.computed.not('isPastEnrollmentDeadline'),
		isPastEnrollmentDeadline: attr('boolean'),
		enrollmentDeadline: attr('string'),
		lastDayToSubmitClaims: attr('string'),
		employerTotalContribution: function () {
			return (this.get('employerEmployeeContribution') + this.get('employerDependentContribution'));
		}.property('employerEmployeeContribution', 'employerDependentContribution'),
		employerTotalContributionCommas: function () {
			return this.get('employerTotalContribution').toLocaleString();
		}.property('employerTotalContribution'),
		hasRunOutPeriodExpired: function () {
			if (!this.get('lastDayToSubmitClaims')) {
				return false;
			}
			return moment(this.get('lastDayToSubmitClaims')).isBefore(moment(), 'day');
		}.property('lastDayToSubmitClaims'),
	});

	App.UnicardCommuterCompanyContributionChange = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: attr('string'),
		contribution: attr('number'),
		effectiveDate: attr('string'),
	});

	App.UnicardCommuterCompanyEnrollment = DS.Model.extend(App._CompanyEnrollmentValidationMixin, App._UnicardCompanyEnrollmentMixin, App._EnrollmentStatusMixin, App._UnicardPlanCostMixin, {
		minEmployeesForBilling: 5,
		planFee: 0,
		endDateForBilling: function () {
			// We estimate for a year
			var startDate = zen.americanDateAsDate(this.get('startDate'));
			return moment(startDate).add(1, 'years').add('-1', 'days').format("MM/DD/YYYY");
		}.property('startDate'),
		pendingContributionChanges: DS.hasMany('App.UnicardCommuterCompanyContributionChange'),
		agreement: DS.belongsTo('App.Document'),
		companyEnrollmentProviderDisplay: attr('string'),
		provider: attr('string'),
		company: DS.belongsTo('App.Company'),
		amfCode: attr('string'),
		firstPayrollAfterStart: attr('string'),
		contribution: attr('string'),
		authName: attr('string'),
		authDate: attr('string'),
		authSignature: attr('string'),
		name: attr('string'),
		title: attr('string'),
		date: attr('string'),
		signature: attr('string'),
		participatingEmployeeCount: attr('number'),
		payFrequencies: attr('string'),
		optedForFirstOfMonthFlow: attr('boolean'),
		effectiveDate: attr('string'),
		calculatedEffectiveDate: attr('string'),
		calculatedUpcomingContributionDate: attr('string'),
		isCustomEligibility: attr('boolean'),
		billingPlan: attr('string'),
		employerContributionLimit: attr('number'),
		employeeTransitContributionLimit: attr('number'),
		employeeParkingContributionLimit: attr('number'),
		isAnnualPlan: function () {
			return this.get('billingPlan') == 'ANN';
		}.property('billingPlan'),
		isMonthlyPlan: function () {
			return this.get('billingPlan') == 'MON';
		}.property('billingPlan'),
		isCompanyContributing: function () {
			var contribution = parseFloat(this.get('contribution'));
			if (!contribution) {
				return false;
			}
			contribution = parseFloat(contribution);
			if (contribution == 0) {
				return false;
			}
			return true;
		}.property('contribution')
	});

	App.UnicardCommuterEmployeeContributionChange = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		status: attr('string'),
		massTransitContribution: attr('number'),
		parkingContribution: attr('number'),
		employerTransitContribution: attr('number'),
		employerParkingContribution: attr('number'),
		effectiveDate: attr('string'),
		reasonCode: attr('string'),
	});

	App.UnicardCommuterEmployeeEnrollment = DS.Model.extend(App._EmployeeEnrollmentValidationMixin, App._UnicardEmployeeEnrollmentMixin, App._EnrollmentStatusMixin, {
		pendingContributionChanges: DS.hasMany('App.UnicardCommuterEmployeeContributionChange'),
		agreement: DS.belongsTo('App.Document'),
		companyEnrollmentProviderDisplay: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		effectiveDate: attr('string'),
		computedStartDate: attr('string'),
		calculatedEffectiveDate: attr('string'),
		calculatedUpcomingContributionDate: attr('string'),
		massTransitContribution: attr('string'),
		parkingContribution: attr('string'),
		name: attr('string'),
		date: attr('string'),
		signature: attr('string'),
		payPeriodDivisor: attr('number'),
		massTransitContributionPerPayPeriod: attr('string'),
		parkingContributionPerPayPeriod: attr('string'),
		totalMassTransitPreTaxDeduction: attr('string'),
		totalMassTransitPreTaxDeductionPerPayperiod: attr('string'),
		totalEmployerMassTransitContributionPreTaxPerPayPeriod: attr('string'),
		totalMassTransitPostTaxDeduction: attr('string'),
		totalMassTransitPostTaxDeductionPerPayperiod: attr('string'),
		totalEmployerMassTransitContributionPostTaxPerPayPeriod: attr('string'),
		totalParkingPreTaxDeduction: attr('string'),
		totalParkingPreTaxDeductionPerPayperiod: attr('string'),
		totalEmployerParkingContributionPreTaxPerPayPeriod: attr('string'),
		totalParkingPostTaxDeduction: attr('string'),
		totalParkingPostTaxDeductionPerPayperiod: attr('string'),
		totalEmployerParkingContributionPostTaxPerPayPeriod: attr('string'),
		totalContributionPerPayPeriod: attr('string'),
		employerMassTransitAllocation: attr('string'),
		employerMassTransitAllocationPreTax: attr('string'),
		employerMassTransitAllocationPostTax: attr('string'),
		employeeMassTransitContribution: attr('string'),
		employerParkingAllocation: attr('string'),
		employerParkingAllocationPreTax: attr('string'),
		employerParkingAllocationPostTax: attr('string'),
		employeeParkingContribution: attr('string'),
		employerContribution: attr('string'),
		employerParkingContribution: attr('string'), // persisted field
		employerTransitContribution: attr('string'), // persisted field
		isEligible: attr('boolean'),
		employeeEligibility: function () {
			return Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function (result) {
				return result;
			});
		},
		previousMonthOfStartDate: function () {
			var startDate = this.get('startDate');
			if (!startDate) {
				return '';
			}
			if (zen.parseAmericanDate(startDate)) {
				return moment(zen.americanDateAsDate(startDate)).subtract(1, 'M').format('MMMM');
			}
			return '';
		}.property('startDate'),
		threeWeeksFromStartDate: function () {
			var startDate = this.get('startDate');
			if (!startDate) {
				return '';
			}
			if (zen.parseAmericanDate(startDate)) {
				return moment(zen.americanDateAsDate(startDate)).add(3, 'w').format('L');
			}
			return '';
		}.property('startDate'),
		employeeContribution: function () {
			var total = 0;
			var employeeMassTransitContribution = this.get('employeeMassTransitContribution');
			if (employeeMassTransitContribution) {
				total += parseFloat(employeeMassTransitContribution);
			}

			var employeeParkingContribution = this.get('employeeParkingContribution');
			if (employeeParkingContribution) {
				total += parseFloat(employeeParkingContribution);
			}

			return total;
		}.property('employeeMassTransitContribution', 'employeeParkingContribution'),
		payperiodMassTransit: function () {
			var contribution = this.get('employeeMassTransitContribution');
			if (!contribution) {
				return 0;
			}
			contribution = parseFloat(contribution) * 12;
			var amount = contribution / this.get('payPeriodDivisor');
			if (amount) {
				return amount;
			}

			return 0;
		}.property('employeeMassTransitContribution', 'payPeriodDivisor'),
		payperiodParking: function () {
			var contribution = this.get('employeeParkingContribution');
			if (!contribution) {
				return 0;
			}
			contribution = parseFloat(contribution) * 12;
			var amount = contribution / this.get('payPeriodDivisor');
			if (amount) {
				return amount;
			}

			return 0;
		}.property('employeeParkingContribution', 'payPeriodDivisor'),
		payperiodTotal: function () {
			var massTransitAmount = parseFloat(this.get('employeeMassTransitContribution'));
			var parkingAmount = parseFloat(this.get('employeeParkingContribution'));
			var total = massTransitAmount + parkingAmount;

			var employeeContributionPerPayPeriod = 0;

			if (total) {
				employeeContributionPerPayPeriod = (total * 12) / this.get('payPeriodDivisor');
			}

			return employeeContributionPerPayPeriod;
		}.property('employeeMassTransitContribution', 'employeeParkingContribution', 'payPeriodDivisor'),
		isEmployeeContributionChangedForUpcomingMonth: function () {
			var massTransitContribution = this.get('massTransitContribution');
			if (!massTransitContribution) {
				massTransitContribution = 0;
			}
			var parkingContribution = this.get('parkingContribution');
			if (!parkingContribution) {
				parkingContribution = 0;
			}
			var employerTransitContribution = this.get('employerTransitContribution');
			if (!employerTransitContribution) {
				employerTransitContribution = 0;
			}
			var employerParkingContribution = this.get('employerParkingContribution');
			if (!employerParkingContribution) {
				employerParkingContribution = 0;
			}
			return parseFloat(massTransitContribution) != parseFloat(this.get('upcomingMassTransitContribution')) || parseFloat(parkingContribution) != parseFloat(this.get('upcomingParkingContribution')) || parseFloat(employerTransitContribution) != parseFloat(this.get('upcomingEmployerTransitContribution')) || parseFloat(employerParkingContribution) != parseFloat(this.get('upcomingEmployerParkingContribution'));
		}.property('massTransitContribution', 'upcomingMassTransitContribution', 'parkingContribution', 'upcomingParkingContribution', 'employerTransitContribution', 'upcomingEmployerTransitContribution', 'employerParkingContribution', 'upcomingEmployerParkingContribution'),
		shouldShowContributionChangeForCompany: function () {
			return this.get('isEmployeeContributionChangedForUpcomingMonth') && this.get('employeeEligibility.isEligibleForCommuterBenefits') && this.get('isComplete');
		}.property('isEmployeeContributionChangedForUpcomingMonth', 'employeeEligibility.isEligibleForCommuterBenefits', 'isComplete'),
		calculateUpcoming: function (propertyName) {
			var contributionChange = this.get('pendingContributionChanges').findBy('effectiveDate', this.get('calculatedUpcomingContributionDate'));
			if (contributionChange) {
				return contributionChange.get(propertyName);
			} else {
				// get current amount or 0 if missing
				return this.get(propertyName) || 0.00;
			}
		},
		upcomingParkingContribution: Ember.computed(
			'calculatedUpcomingContributionDate',
			'pendingContributionChanges.@each.effectiveDate',
			function () {
				return this.calculateUpcoming('parkingContribution');
			}
		),
		upcomingMassTransitContribution: Ember.computed(
			'calculatedUpcomingContributionDate',
			'pendingContributionChanges.@each.effectiveDate',
			function () {
				return this.calculateUpcoming('massTransitContribution');
			}
		),
		upcomingEmployerParkingContribution: Ember.computed(
			'calculatedUpcomingContributionDate',
			'pendingContributionChanges.@each.effectiveDate',
			function () {
				return this.calculateUpcoming('employerParkingContribution');
			}
		),
		upcomingEmployerTransitContribution: Ember.computed(
			'calculatedUpcomingContributionDate',
			'pendingContributionChanges.@each.effectiveDate',
			function () {
				return this.calculateUpcoming('employerTransitContribution');
			}
		),
		upcomingEmployeeParkingContribution: Ember.computed(
			'upcomingEmployerParkingContribution',
			'upcomingParkingContribution',
			function () {
				return Math.max(0.00, this.get('upcomingParkingContribution') - this.get('upcomingEmployerParkingContribution'));
			}
		),
		upcomingEmployeeMassTransitContribution: Ember.computed(
			'upcomingEmployerTransitContribution',
			'upcomingMassTransitContribution',
			function () {
				return Math.max(0.00, this.get('upcomingMassTransitContribution') - this.get('upcomingEmployerTransitContribution'));
			}
		),

		lastDayOfEligibility: function () {
			if (!this.get('effectiveEndDate')) {
				return null;
			}
			return moment(this.get('effectiveEndDate'));
		}.property('effectiveEndDate'),

		hasRunOutPeriodExpired: function () {
			if (!this.get('lastDayToSubmitClaims')) {
				return false;
			}
			return moment(this.get('lastDayToSubmitClaims')).isBefore(moment(), 'day');
		}.property('lastDayToSubmitClaims'),

		isEnrolledPlan: attr('boolean'),
		effectiveEndDate: attr('string'),
		lastDayToSubmitClaims: attr('string'),
		isPastEffectiveEndDate: function () {
			var effectiveEndDate = moment(this.get('effectiveEndDate'));
			return effectiveEndDate && moment().isAfter(effectiveEndDate);
		}.property('effectiveEndDate'),
		isEligibleToSubmitClaims: function () {
			return !this.get('hasRunOutPeriodExpired');
		}.property('hasRunOutPeriodExpired'),
	});

	App.AlegeusCompanySettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		availableInAlegeus: attr('boolean'),
		ameriflexToAlegeusMigrationTime: attr('string'),
		alegeusMigrationMidYearTakeOverDate: attr('string'),
		alegeusEmployerId: attr('string'),
		isHraCompanySetupWorkItemClosed: attr('boolean'),
		isCommuterCompanySetupWorkItemClosed: attr('boolean'),
		isFsaCompanySetupWorkItemClosed: attr('boolean'),
		isHsaCompanySetupWorkItemClosed: attr('boolean'),
		isBorElsewhere: attr('boolean'),
	});

	App.AlegeusEmployeeSettings = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		bank: DS.belongsTo('App.EmployeeBank'),
		availableInAlegeus: attr('boolean'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		isBankInfoComplete: attr('boolean'),
		ssoTarget: attr('string'),
	});

	App.ZfbTransactionsMonth = DS.Model.extend({
		count: attr('number'),
		month: attr('string'),
	}).reopenClass({
		noBatch: true
	});


	App.ZfbTransactionDocument = DS.Model.extend({
		id: attr('string'),
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		zfbTransaction: DS.belongsTo('App.ZfbTransaction'),
		fileKey: attr('string'),
		fileName: attr('string'),
		fileUrl: attr('string'),
		status: attr('string'),
		createdAt: attr('string'),
	});

	App.ZfbTransaction = DS.Model.extend({
		id: attr('string'),
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		zfbTransactionDocuments: DS.hasMany('App.ZfbTransactionDocument'),
		employeeName: attr('string'),
		accountType: attr('string'),
		merchantName: attr('string'),
		settlementNumber: attr('number'),
		invoiceDate: attr('string'),
		settlementDate: attr('string'),
		transactionAmount: attr('number'),
		deniedAmount: attr('number'),
		transactionCode: attr('number'),
		transactionStatus: attr('string'),
		transactionStatusDisplay: attr('string'),
		notes: attr('string'),
		deniedAmountTransactionMessage: function () {
			if (this.get('transactionStatusDisplay') == 'Declined') {
				return 'Declined Amount: $' + this.get('deniedAmount');
			}
			return null;
		}.property('deniedAmount', 'transactionStatusDisplay'),
		isManualClaim: function () {
			return this.get('transactionCode') == 10;
		}.property('transactionCode'),
		isCardSwipe: function () {
			return this.get('transactionCode') == 12;
		}.property('transactionCode'),
		isRefund: function () {
			return this.get('transactionCode') == 14;
		}.property('transactionCode'),
		isDeposit: function () {
			return this.get('transactionCode') == 22;
		}.property('transactionCode'),
		transactionDisplayType: function () {
			if (this.get('isManualClaim')) {
				return 'Claim';
			} else if (this.get('isCardSwipe')) {
				return 'Card Swipe';
			} else if (this.get('isRefund')) {
				return 'Repayment';
			} else if (this.get('isDeposit')) {
				return 'Deposit';
			} else {
				return 'Unknown';
			}
		}.property('isManualClaim', 'isCardSwipe', 'isRefund', 'isDeposit'),
		formattedInvoiceDate: function () {
			return moment(this.get('invoiceDate')).format('MM/DD/YY');
		}.property('invoiceDate'),
		accountTypeGroup: function () {
			switch (this.get('accountType')) {
				case 'TRN':
				case 'TRP':
				case 'PKG':
				case 'PKP':
					return 'trn';
				case 'DCA':
				case 'FSA':
				case 'LPF':
					return 'fsa';
				case 'HRA':
					return 'hra';
				default:
					return 'unk';
			}
		}.property('accountType'),
		accountTypeHint: function () {
			switch (this.get('accountType')) {
				case 'TRN':
					return 'Pre-tax Transit';
				case 'TRP':
					return 'Post-tax Transit';
				case 'PKG':
					return 'Pre-tax Parking';
				case 'PKP':
					return 'Post-tax Parking';
				case 'FSA':
					return 'Health Care Account';
				case 'DCA':
					return 'Child & Elderly Account';
				case 'LPF':
					return 'Limited Purpose Account';
				case 'HRA':
					return 'Health Reimbursement Account';
				default:
					return '';
			}
		}.property('accountType'),
		accountTypeDisplay: function () {
			switch (this.get('accountType')) {
				case 'TRN':
				case 'TRP':
					return 'Transit';
				case 'PKG':
				case 'PKP':
					return 'Parking';
				case 'FSA':
					return 'Health Care';
				case 'ROL':
				case 'RO1':
				case 'RO2':
				case 'RO3':
					return 'Rollover Funds';
				case 'DCA':
					return 'Child & Elderly Care';
				case 'HRA':
					return 'HRA';
				case 'ABH':
					return 'HSA';
				case 'LPF':
					return 'Limited Purpose';
				default:
					return '';
			}
		}.property('accountType'),
		merchantNameDisplay: function () {
			if (this.get('isDeposit')) {
				return 'Contribution';
			} else {
				return this.get('merchantName') || 'N/A';
			}
		}.property('merchantNameDisplay', 'isDeposit'),

	});

	App.ZFBAccountBalances = DS.Model.extend({
		balances: attr('raw'),
		transactions: attr('raw'),
		error: attr('boolean'),
		error_message: attr('string'),
	});

	App.HsaCompanyEnrollmentDetail = DS.Model.extend({
		numberOfEnrolledEmployeesWithDependents: attr('number'),
		numberOfEnrolledEmployeesWithoutDependents: attr('number'),
	});

	App.LifePlanRestrictions = DS.Model.extend({
		dependentType: attr('string'),
		increment: attr('number'),
		maxAmount: attr('number'),
		maxMultiplier: attr('number'),
		maxStyle: attr('string'),
		plan: DS.belongsTo('App.LifePlan'),
		setValues: attr('string'),
		setupStyle: attr('string'),
		type: attr('string'),
		isEmployee: Ember.computed.equal('dependentType', 'employee'),
		isSpouse: Ember.computed.equal('dependentType', 'spouse'),
		isChild: Ember.computed.equal('dependentType', 'child'),
		isMaxFixed: Ember.computed.equal('maxStyle', 'fixed'),
		isMaxMultiplierOfEESalary: Ember.computed.equal('maxStyle', 'multiplier_of_ee_salary'),
		isMaxMultiplierOfEEVolume: Ember.computed.equal('maxStyle', 'multiplier_of_ee_volume'),
		isMaxBothEESalaryAndFixed: Ember.computed.equal('maxStyle', 'lesser_of_ee_salary_and_fixed'),
		isMaxBothEEVolumeAndFixed: Ember.computed.equal('maxStyle', 'lesser_of_ee_life_volume_and_fixed'),
		isStyleIncrement: Ember.computed.equal('setupStyle', 'increment'),
		isStyleSetValues: Ember.computed.equal('setupStyle', 'set_values'),
		isLifePlan: Ember.computed.equal('type', 'life'),
		isAddPlan: Ember.computed.equal('type', 'add'),
		isEELife: Ember.computed.and('isEmployee', 'isLifePlan'),
		isSpouseLife: Ember.computed.and('isSpouse', 'isLifePlan'),
		isChildLife: Ember.computed.and('isChild', 'isLifePlan'),
		isEEAdd: Ember.computed.and('isEmployee', 'isAddPlan'),
		isSpouseAdd: Ember.computed.and('isSpouse', 'isAddPlan'),
		isChildAdd: Ember.computed.and('isChild', 'isAddPlan')
	});

	App.LifePlan = DS.Model.extend({
		lifePlanRestrictions: DS.hasMany('App.LifePlanRestrictions'),
		carrier: attr('string'),
		stateCarrier: DS.belongsTo("App.Carrier"),
		name: attr('string'),
		type: attr('string'),
		planUrl: attr('string'),
		multiplier: attr('number'),
		maxAmount: attr('string'),
		guaranteeIssue: attr('string'),
		supplementalCoverage: attr('boolean'),
		supplementalMax: attr('string'),
		supplementalGenderRated: attr('boolean'),
		benefitsReduction: attr('string'),
		price: attr('string'),
		genderRated: attr('boolean'),
		ratePerThousand: attr('string'),
		isVoluntary: attr('boolean'),

		eeLife: Ember.computed.filterByProperty('lifePlanRestrictions', 'isEELife'),
		spouseLife: Ember.computed.filterByProperty('lifePlanRestrictions', 'isSpouseLife'),
		childLife: Ember.computed.filterByProperty('lifePlanRestrictions', 'isChildLife'),
		eeAdd: Ember.computed.filterByProperty('lifePlanRestrictions', 'isEEAdd'),
		spouseAdd: Ember.computed.filterByProperty('lifePlanRestrictions', 'isSpouseAdd'),
		childAdd: Ember.computed.filterByProperty('lifePlanRestrictions', 'isChildAdd'),

		maxAmountCommas: function () {
			var maxAmount = this.get('maxAmount');
			if (maxAmount == null || maxAmount == "") {
				return "";
			}
			return Math.round(String(maxAmount).replace(/,/g, '')).toLocaleString();
		}.property('maxAmount'),
		supplementalMaxCommas: function () {
			var maxAmount = this.get('supplementalMax');
			if (maxAmount || maxAmount == '') {
				return '';
			}
			return Math.round(String(maxAmount).replace(/,/g, '')).toLocaleString();
		}.property('supplementalMax'),
		isStock: Ember.computed.equal('type', 'Stock'),
	});

	var ENTITY_TYPE_DISPLAY_MAPPING = {
		CH: 'Charity',
		ES: 'Estate',
		TR: 'Trust',
	};
	App.Beneficiary = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		// This field is DEPRECATED!
		isEnrolledInInsurance: attr('boolean'),
		isInternational: attr('boolean'),
		firstName: attr('string'),
		lastName: attr('string'),
		fullName: function () {
			return [this.get('firstName'), this.get('lastName')].join(' ').trim();
		}.property('firstName', 'lastName'),
		relationship: attr('string'),
		otherRelationship: attr('string'),
		percentage: attr('string'),
		maxBenefits: attr('string'),
		type: attr('string'),
		address: DS.belongsTo('App.Address'),
		status: attr('string'),
		hasSocialSecurity: attr('boolean'),
		socialSecurity: attr('string'),
		phone: attr('string'),
		dateOfBirth: attr('string'),
		entityType: attr('string'),
		entityName: attr('string'),
		entityDateEstablished: attr('string'),
		maxBenefitsCommas: function () {
			var maxBenefits = this.get('maxBenefits');
			if (maxBenefits == null || maxBenefits == "") {
				return "";
			}
			return Math.round(String(maxBenefits).replace(/,/g, '')).toLocaleString();
		}.property('maxBenefits'),
		percentageDisplay: function () {
			var perc = this.get('percentage');
			if (perc == null || perc == "") {
				return 0;
			}

			return Number(perc).toFixed(1);
		}.property('percentage'),
		name: function () {
			if (this.get('isPerson')) {
				return this.get('fullName');
			} else {
				return this.get('entityName');
			}
		}.property('fullName', 'entityName', 'isPerson'),
		isMissingInformation: function () {
			if (this.get('isPerson')) {
				var ssnIsValid = this.get('hasSocialSecurity') ? !!this.get('socialSecurity') : true;
				return !(this.get('firstName') && this.get('lastName') &&
					this.get('relationship') && this.get('address') && ssnIsValid &&
					this.get('phone') && this.get('dateOfBirth'));
			} else {
				return !(this.get('entityType') && this.get('entityName') && this.get('entityDateEstablished') &&
					this.get('address') && this.get('phone'));
			}
		}.property('isPerson', 'firstName', 'lastName', 'relationship', 'address',
			'socialSecurity', 'phone', 'dateOfBirth', 'entityType', 'entityName', 'entityDateEstablished'),
		isPerson: Ember.computed.equal('type', 'PE'),
		isEntity: Ember.computed.equal('type', 'EN'),
		isActive: Ember.computed.equal('status', 'AC'),
		displayType: function () {
			if (this.get('isPerson')) {
				return this.get('relationship').toLowerCase().capitalize();
			} else {
				return ENTITY_TYPE_DISPLAY_MAPPING[this.get('entityType')];
			}
		}.property('isPerson', 'relationship', 'entityType'),
		isNameEditable: function () {
			if (this.get('isNew')) {
				return true;
			}
			return this.get('isPerson') ? (!this.get('firstName') || !this.get('lastName')) : !this.get('entityName');
		}.property('isPerson', 'firstName', 'lastName', 'entityName'),
		doesNotHaveSocialSecurity: function (key, value, previousValue) {
			// setter
			if (arguments.length > 1) {
				this.set('hasSocialSecurity', !value);
			}
			// getter
			return !this.get('hasSocialSecurity');
		}.property('hasSocialSecurity'),
	});

	App.EmployeePto = DS.Model.extend({
		accounts: DS.hasMany('App.EmployeePtoAccount'),
		employee: DS.belongsTo('App.AllEmployee'),
		companyPto: DS.belongsTo('App.EmployerPto', { inverse: "employeePtos" }),
		newCompanyPto: DS.belongsTo('App.EmployerPto', { inverse: "newEmployeePtos" }),
		balanceHistoryItems: DS.hasMany('App.BalanceHistoryItem'),
		policyChangeTrigger: attr('string'),
		canHourlyReaccrue: attr('boolean'),
		isPtoManager: attr('boolean'),
		isEmployeeTimeAttendanceComplete: Ember.computed.alias('employee.ta.isReporting'),
		isEligibleForPTO: Ember.computed.alias('employee.isEligibleForPTO'),
		useableVacation: Ember.computed.alias('vacationAccount.useable'),
		truncatedVacationBalance: Ember.computed.alias('vacationAccount.truncatedBalance'),
		truncatedUseableVacation: Ember.computed.alias("vacationAccount.truncatedUseable"),
		canHourlyReaccrueYesNo: Ember.computed.boolToYesNo('canHourlyReaccrue'),
		hourlyTrackingEnabled: Ember.computed.anyProperty('availableTrackingAccounts', 'isHourlyAccrued'),

		accrualFrequencyHumanized: function () {
			var frequencies = {
				"Mo": "Monthly",
				"SM": "Semi-monthly",
				"We": "Weekly",
				"BW": "Bi-weekly",
				"Qt": "Quarterly",
				"Ye": "Annually"
			};
			return frequencies[this.get('accrualFrequency')];
		}.property('accrualFrequency'),
		hasOwnPto: Ember.computed.and('isEligibleForPTO', 'companyPto'),
		canViewPto: Ember.computed.or('hasOwnPto', 'isPtoManager'),
		regularTrackingEnabled: function () {
			return this.get('ptoTrackingEnabled') && !this.get('hourlyTrackingEnabled');
		}.property('ptoTrackingEnabled', 'hourlyTrackingEnabled'),
		allOrderedAccounts: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['order'],
				content: this.get('accounts')
			});
		}.property('accounts'),
		orderedAccounts: Ember.computed.rejectByProperty('allOrderedAccounts', 'isStagingAccount'),
		availableAccounts: Ember.computed.filterByProperty('orderedAccounts', 'isAvailable'),
		availableTrackingAccounts: Ember.computed.filterByProperty('availableAccounts', 'isTrackedAndAvailable'),
		reloadWithAccounts: function () {
			return this.reload().then(function (self) {
				return Ember.RSVP.all(self.get('accounts').map(function (account) {
					return account.get('isLoaded') ? account.reload() : account;
				}).filter(identity));
			});
		},
		reloadWithAccountsAndBalanceHistory: function () {
			return this.reloadWithAccounts().then(function () {
				return Ember.RSVP.all(this.get('balanceHistoryItems').map(function (balanceHistoryItem) {
					return balanceHistoryItem.get('isLoaded') ? balanceHistoryItem.reload() : balanceHistoryItem;
				}).filter(identity));
			}.bind(this));
		},
		account: function (type, isStagingAccount) {
			return this.get('accounts').find(function (account) {
				return account.get('type') == type && Boolean(account.get('isStagingAccount')) == Boolean(isStagingAccount);
			});
		},
		vacationAccount: function () {
			return this.account('VACATION');
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		newVacationAccount: function () {
			return this.account('VACATION', true);
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		sickLeaveAccount: function () {
			return this.account('SICK');
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		newSickLeaveAccount: function () {
			return this.account('SICK', true);
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		personalLeaveAccount: function () {
			return this.account('PERSONAL');
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		newPersonalLeaveAccount: function () {
			return this.account('PERSONAL', true);
		}.property('accounts.@each.type', 'accounts.@each.isStagingAccount'),
		ptoTrackingEnabled: true, //Ember.computed.and('availableTrackingAccounts.length', 'companyPto'),
		legacyAccountsHavingItems: function () {
			var balanceHistoryItems = this.get('balanceHistoryItems');
			var possibleAccounts = Ember.A([
				['SICK', 2015, this.get('sickLeaveAccount.name') + ' (2015)'],
				['PERSONAL', 2015, this.get('personalLeaveAccount.name') + ' (2015)'],
				['SICK', 2014, this.get('sickLeaveAccount.name') + ' (2014)'],
				['PERSONAL', 2014, this.get('personalLeaveAccount.name') + ' (2014)'],
			].map(function (attributes, index) {
				return Ember.Object.create({
					type: attributes[0],
					year: attributes[1],
					name: attributes[2],
					id: index,
				});
			}));
			return possibleAccounts.filter(function (account) {
				return balanceHistoryItems && balanceHistoryItems.any(function (balanceHistoryItem) {
					return balanceHistoryItem.get('year') == account.get('year') && balanceHistoryItem.get('accountType') == account.get('type');
				});
			});
		}.property('balanceHistoryItems.@each.year', 'balanceHistoryItems.@each.type', 'sickLeaveAccount.name', 'personalLeaveAccount.name'),
		legacyTrackedAccountsHavingItems: function () {
			return this.get('legacyAccountsHavingItems').filter(function (account) {
				return account.get('type') === 'SICK' ? this.get('sickLeaveAccount.isTrackedAndAvailable') : this.get('personalLeaveAccount.isTrackedAndAvailable');
			}.bind(this));
		}.property('legacyAccountsHavingItems.@each.type', 'sickLeaveAccount.isTrackedAndAvailable', 'personalLeaveAccount.isTrackedAndAvailable'),
		legacyLimitedAccountsHavingItems: function () {
			return this.get('legacyAccountsHavingItems').filter(function (account) {
				return account.get('type') === 'SICK' ? this.get('sickLeaveAccount.isLimited') : this.get('personalLeaveAccount.isLimited');
			}.bind(this));
		}.property('legacyAccountsHavingItems.@each.type', 'sickLeaveAccount.isLimited', 'personalLeaveAccount.isLimited'),
	});

	App.EmployeePtoAccount = DS.Model.extend({
		accrualRate: attr('string'),
		accrualFrequency: attr('string'),
		isStagingAccount: attr('boolean'),
		pto: DS.belongsTo('App.EmployeePto'),
		type: attr('string'),
		usesTenuredAccrualRate: attr('boolean'),
		balance: attr('string'),
		tenureAccrualBonus: attr('number'),
		useable: attr('string'),
		hoursUsedYTD: attr('string'),
		scheduledHours: attr('string'),
		accruedUntil: attr('date'),
		tenureBonusHourlyAccrualHours: attr('number'),
		truncatedHoursUsedYTD: Ember.computed.truncated("hoursUsedYTD", 2),
		truncatedScheduledHours: Ember.computed.truncated("scheduledHours", 2),
		truncatedUseable: Ember.computed.truncated("useable", 2),
		truncatedBalance: Ember.computed.truncated("balance", 2),
		tenureAccrualBonusDaysPerYear: Ember.computed.hoursPerMonthToDaysPerYear('tenureAccrualBonus', 'pto.companyPto.defaultWorkdayHours'),
		tenureAccrualBonusHoursPerHoursWorked: function () {
			var converted = this.get('tenureAccrualBonus') * (12 / 2080);
			converted = converted.toFixed(10);
			return Math.floor(converted * 100) / 100;
		}.property('tenureAccrualBonus', 'companyAccount.hourlyAccrualRateHoursWorkedDivisor'),
		accrualDaysPerYear: Ember.computed.hoursPerMonthToDaysPerYear("accrualRate", 'pto.companyPto.defaultWorkdayHours'),
		accrualRateWithTenureDays: Ember.computed.hoursPerMonthToDaysPerYear('accrualRateWithTenure', 'pto.companyPto.defaultWorkdayHours'),
		isAvailable: Ember.computed.alias('companyAccount.isAvailable'),
		isTrackedAndAvailable: Ember.computed.alias('companyAccount.isTrackedAndAvailable'),
		isLimited: Ember.computed.alias('companyAccount.isLimited'),
		accrualRateDaysPerYear: attr('number'),
		hourlyAccrualHoursWithTenure: function () {
			var accrualHours = this.get('companyAccount.hourlyAccrualHours') + this.get('tenureBonusHourlyAccrualHours');
			return accrualHours;
		}.property('companyAccount.hourlyAccrualHours', 'tenureBonusHourlyAccrualHours'),
		isVacation: Ember.computed.equal('type', 'VACATION'),
		pendingHours: function () {
			if (this.get('isLimited')) {
				return (Number(this.get('truncatedBalance') || '0.00') - Number(this.get('truncatedUseable') || '0.00')).toFixed(2);
			} else {
				return this.get('truncatedScheduledHours');
			}
		}.property('truncatedBalance', 'truncatedUseable', 'isLimited', 'scheduledHours'),
		truncatedPendingHours: Ember.computed.truncated('pendingHours', 2),
		order: function () {
			return ['VACATION', 'SICK', 'PERSONAL'].indexOf(this.get('type'));
		}.property('type'),
		companyAccount: function () {
			var companyAccounts = this.get('pto.companyPto.accounts');
			return companyAccounts && companyAccounts.findProperty('type', this.get('type'));
		}.property('pto.companyPto.accounts.@each.type', 'type'),
		companyAccountId: Ember.computed.alias('companyAccount.id'),
		accrualRateWithTenure: function () {
			if (this.get('usesTenuredAccrualRate')) {
				return parseFloat(this.get('accrualRate')) + parseFloat(this.get('tenureAccrualBonus'));
			} else {
				return this.get('accrualRate');
			}
		}.property('accrualRate', 'tenureAccrualBonus'),
		truncatedAccrualRate: Ember.computed.truncated("accrualRate", 2),
		usesTenuredAccrualRateYesNo: Ember.computed.boolToYesNo('usesTenuredAccrualRate'),
		name: function () {
			return this.get('companyAccount.name') || {
				'VACATION': 'Vacation',
				'SICK': 'Sick Leave',
				'PERSONAL': 'Personal Leave',
				'FLOATING': 'Floating',
				'BEREAVEMENT': 'Bereavement',
				'JURY_DUTY': 'Jury Duty'
			}[this.get('type')];
		}.property('companyAccount.name', 'type'),
		hasTenureBonus: function () {
			return this.get('usesTenuredAccrualRate') && parseFloat(this.get('tenureAccrualBonus'));
		}.property('usesTenuredAccrualRate', 'tenureAccrualBonus'),
		accrualFrequencyHumanized: function () {
			var frequencies = {
				"Mo": "Monthly",
				"SM": "Semi-monthly",
				"We": "Weekly",
				"BW": "Bi-weekly",
				"Qt": "Quarterly",
				"Ye": "Annually"
			};
			return frequencies[this.get('accrualFrequency')];
		}.property('accrualFrequency'),
		requestsAllowedSince: function () {
			var waitingPeriodDays = this.get('companyAccount.waitingPeriodDays');
			var hireDateString = this.get('pto.employee.hireDate');
			if (hireDateString !== null) {
				return moment(hireDateString).add(waitingPeriodDays, 'days').format("MM/DD/YYYY");
			}
			else {
				return null;
			}
		}.property('companyAccount.waitingPeriodDays', 'pto.employee.hireDate'),
		liability: function () {
			if (this.get('type') != 'VACATION') {
				return '0.00';
			}
			var balance = this.get('balance');
			var hourlySalary = this.get('pto.employee.hourlySalary');
			if (!Ember.$.isNumeric(balance) || !Ember.$.isNumeric(hourlySalary)) {
				return '0.00';
			}
			var balanceNumber = Number(this.get('balance'));
			if (balanceNumber < 0) {
				return '0.00';
			}
			return (balanceNumber * Number(hourlySalary)).toFixed(2);
		}.property('balance', 'pto.employee.hourlySalary'),
		lowerCaseType: function () {
			return this.get('type').toLowerCase();
		}.property('type'),
	});

	App.RequestQuotes = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		uploadUrl: attr('string')
	});

	App.RequestLifeQuotes = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		dateOfIncorporation: attr('string'),
		uploadUrl: attr('string')
	});

	App.EmployerPto = DS.Model.extend({
		accounts: DS.hasMany('App.EmployerPtoAccount'),
		company: DS.belongsTo('App.Company'),
		vacationTypes: DS.hasMany('App.VacationType'),
		holidays: DS.hasMany('App.CompanyHolidayCalendar'),
		newEmployeePtos: DS.hasMany('App.EmployeePto'),
		employeePtos: DS.hasMany('App.EmployeePto'),
		switchEmployeesToPto: DS.belongsTo('App.EmployerPto'),
		name: attr('string'),
		companyTimeAttendanceComplete: attr('boolean'),
		existingDataUrl: attr('string'),
		isExistingDataParsed: attr('boolean'),
		isImportSkipped: attr('boolean'),
		hasChosenEmployees: attr('boolean'),
		isVacationTypesComplete: attr('boolean'),
		isSickPersonalComplete: attr('boolean'),
		isAdvancedComplete: attr('boolean'),
		isSecondaryComplete: attr('boolean'),
		dataParsingStatus: attr('string'),
		status: attr('string'),
		ptoUploadTimestamp: attr('string'),
		defaultWorkdayHours: attr('string'),
		defaultWorkdays: attr('number'),
		employeePayFrequencies: attr('raw'),
		needsAnchorDate: attr('boolean'),
		companyAnchorStartDate: attr('string'), //This is in case the company needs a payroll anchor date, and is used to set the anchor date on the Company object
		importEffectiveDate: attr('string'),
		quickChangePolicies: attr('boolean'),
		changeCalendarWarningYear: attr('number'),
		setupCanHourlyReaccrue: attr('boolean'),
		isCompanyTimeAttendanceComplete: attr('boolean'),
		isCompanyZPComplete: attr('boolean'),
		blockEmailTrigger: attr('boolean'),
		setupProgress: attr('string'),
		setupCanHourlyReaccrueYesNo: Ember.computed.boolToYesNo('setupCanHourlyReaccrue'),
		toTrackTimeEnabled: Ember.computed.anyProperty('accounts', 'isTracked'),
		activeEmployeePtos: Ember.computed.filterByProperty('employeePtos', 'employee.status', 'Act'),
		activeTaEmployeePtos: Ember.computed.filterByProperty('activeEmployeePtos', 'isEmployeeTimeAttendanceComplete'),
		vacationEnabled: Ember.computed.alias("vacationAccount.isAvailable"),
		sickLeaveEnabled: Ember.computed.alias("sickLeaveAccount.isAvailable"),
		personalLeaveEnabled: Ember.computed.alias("personalLeaveAccount.isAvailable"),
		quickChangePoliciesYesNo: Ember.computed.boolToYesNo('quickChangePolicies'),
		isMondayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 0),
		isTuesdayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 1),
		isWednesdayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 2),
		isThursdayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 3),
		isFridayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 4),
		isSaturdayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 5),
		isSundayWorkday: Ember.computed.bitwiseBooleanAtIndex('defaultWorkdays', 6),
		isMondayOff: Ember.computed.inverseBoolean("isMondayWorkday"),
		isTuesdayOff: Ember.computed.inverseBoolean("isTuesdayWorkday"),
		isWednesdayOff: Ember.computed.inverseBoolean("isWednesdayWorkday"),
		isThursdayOff: Ember.computed.inverseBoolean("isThursdayWorkday"),
		isFridayOff: Ember.computed.inverseBoolean("isFridayWorkday"),
		isSaturdayOff: Ember.computed.inverseBoolean("isSaturdayWorkday"),
		isSundayOff: Ember.computed.inverseBoolean("isSundayWorkday"),
		sickOrPersonalLeaveEnabled: function () {
			return this.get("sickLeaveEnabled") || this.get("personalLeaveEnabled");
		}.property("sickLeaveEnabled", "personalLeaveEnabled"),
		isComplete: Ember.computed.equal('status', "complete"),
		isProcessing: Ember.computed.equal('status', "processing"),
		inFilingOut: Ember.computed.equal('status', 'filling-out'),
		toSendEmails: Ember.computed.equal('status', 'confirm-send-to-employees'),
		isStatusDeleted: Ember.computed.equal('status', 'deleted'),
		vacationAccount: Ember.computed.findByProperty('accounts', 'type', 'VACATION'),
		sickLeaveAccount: Ember.computed.findByProperty('accounts', 'type', 'SICK'),
		personalLeaveAccount: Ember.computed.findByProperty('accounts', 'type', 'PERSONAL'),
		sickLeaveAvailable: Ember.computed.alias('sickLeaveAccount.isAvailable'),
		personalLeaveAvailable: Ember.computed.alias('personalLeaveAccount.isAvailable'),
		vacationAvailable: Ember.computed.alias('vacationAccount.isAvailable'),
		orderedAccounts: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				content: this.get('accounts'),
				sortProperties: ['order'],
			});
		}.property('accounts'),
		availableAccounts: Ember.computed.filterByProperty('orderedAccounts', 'isAvailable'),
		availableTrackingAccounts: Ember.computed.filterByProperty('orderedAccounts', 'isTrackedAndAvailable'),
		availableLimitedAccounts: Ember.computed.filterByProperty('availableTrackingAccounts', 'isLimited'),
		isTimeAttendancePolicy: Ember.computed.anyProperty('availableTrackingAccounts', 'isHourlyAccrued'),
		account: function (accountType) {
			return this.get('accounts') && this.get('accounts').findProperty('type', accountType);
		},
		vacationTitleLower: function () {
			if (this.get('vacationTitle') == "Vacation") {
				return "vacation";
			} else {
				return "PTO";
			}
		}.property('vacationTitle'),
		setupTemplateUrl: function () {
			return '/pto_template/' + this.get('id') + '/run';
		}.property('id'),
		isAccountsIncomplete: Ember.computed.anyProperty('isIncomplete'),
		isAccountsComplete: Ember.computed.not('isAccountsIncomplete'),
		allEmployeeAccounts: function () {
			var ptos = this.get('isComplete') ? this.get('employeePtos') : this.get('newEmployeePtos');
			return ptos && ptos.reduce(function (accounts, pto) {
				return accounts.concat(pto.get('accounts').toArray() || []);
			}.bind(this), []);
		}.property('employeePtos.@each.accounts', 'newEmployeePtos.@each.accounts'),
		employeeAccounts: function () {
			return this.get('allEmployeeAccounts') && this.get('allEmployeeAccounts').filterProperty('isStagingAccount', !this.get('isComplete'));
		}.property('isComplete', 'allEmployeeAccounts.@each', 'allEmployeeAccounts.@each.isStagingAccount'),
		minimumIncrementTable: {
			'0.25': "15 minutes",
			'0.50': "30 minutes",
			'1.00': "1 hour",
			'2.00': "2 hours",
			'3.00': "3 hours",
			'4.00': "4 hours",
			'5.00': "5 hours",
		},
	});

	App.EmployerPtoAccount = DS.Model.extend({
		accrualPeriod: attr('string'),
		anchorStartDate: attr('string'),
		balanceCap: attr('string'),
		accrualCap: attr('string'),
		usageCap: attr('string'),
		blackoutDateRanges: DS.hasMany('App.BlackoutDateRange'),
		companyPto: DS.belongsTo('App.EmployerPto'),
		defaultAccrualRate: attr('string'),
		isAvailable: attr('boolean'),
		isPaidInAdvance: attr('boolean'),
		isTenureProrated: attr('nullBoolean'),
		isHireDateProrated: attr('boolean'),
		isTracked: attr('boolean'),
		accrueWaitingPeriod: attr('boolean'),
		holidayWaitingPeriod: attr('boolean'),
		waitingPeriodDays: attr('number'),
		maximumNegativeBalance: attr('string'),
		minimumIncrementHours: attr('string'),
		rolloverCap: attr('string'),
		isAnnuallyReset: attr('boolean'),
		tenureLevels: DS.hasMany('App.PtoTenureLevel'),
		hasTenureLevels: attr('boolean'),
		type: attr('string'),
		name: attr('string'),
		isHourlyAccrued: attr('boolean'),
		isSecondaryComplete: attr('boolean'),
		isYearHireDateBased: attr('boolean'),
		yearStartDate: attr('string'),
		resetAccrual: attr('boolean'),
		resetAccrualYesNo: Ember.computed.boolToYesNo('resetAccrual'),
		previousIsYearHireDateBased: attr('boolean'),
		previousAccrualPeriod: attr('string'),
		previousYearStartDate: attr('string'),
		previousIsTrackedAndAvailable: attr('boolean'),
		setupProgress: attr('string'), // Deprecated
		hourlyAccrualHours: attr('number'),
		hourlyAccrualRateHoursWorkedDivisor: attr('number'), // Only used to calculate defaultAccrualRate if on hourly policy
		accrueOnPto: attr('boolean'),
		previousIsAnnuallyAccrued: Ember.computed.equal('previousAccrualPeriod', 'Ye'),
		isAnnuallyAccrued: Ember.computed.equal('accrualPeriod', 'Ye'),
		isAccrualYearBased: Ember.computed.in('accrualPeriod', ['Ye', 'Qt']),
		isVacation: Ember.computed.equal('type', 'VACATION'),
		isSickLeave: Ember.computed.equal('type', 'SICK'),
		isPersonalLeave: Ember.computed.equal('type', 'PERSONAL'),
		yearStartDateAsMoment: Ember.computed.asMoment('yearStartDate'),
		previousYearStartDateAsMoment: Ember.computed.asMoment('previousYearStartDate'),
		accountTemplate: DS.belongsTo('App.TemplateEmployerPtoAccount'),
		accrualPeriodHumanized: function () {
			// fits grammaticall in 'on a ____ basis'
			return {
				"Mo": "Monthly",
				"SM": "Semi-monthly",
				"We": "Weekly",
				"BW": "Bi-weekly",
				"Qt": "Quarterly",
				"Ye": "Annual",
				"PP": 'Pay Period',
			}[this.get('accrualPeriod')];
		}.property('accrualPeriod'),
		isLimited: function () {
			return this.get('isTrackedAndAvailable') && this.get('defaultAccrualRate') !== null;
		}.property('isTrackedAndAvailable', 'defaultAccrualRate'),
		isUnlimited: function () {
			return this.get('isAvailable') && !this.get('isLimited');
		}.property('isAvailable', 'isLimited'),
		isHourlyAccruedYesNo: Ember.computed.boolToYesNo('isHourlyAccrued'),
		isTrackedYesNo: Ember.computed.boolToYesNo('isTracked'),
		isAvailableYesNo: Ember.computed.boolToYesNo('isAvailable'),
		isTrackedAndAvailable: Ember.computed.and('isTracked', 'isAvailable'),
		isHireDateProratedYesNo: Ember.computed.boolToYesNo('isHireDateProrated'),
		allowNegativeBalance: function () {
			return !$.isNumeric(this.get('maximumNegativeBalance')) || this.get('maximumNegativeBalance') != 0;
		}.property('maximumNegativeBalance'),
		defaultAccrualDaysPerYear: Ember.computed.hoursPerMonthToDaysPerYear('defaultAccrualRate', 'companyPto.defaultWorkdayHours'),
		hourlyAccrualPeriod: attr('string'),
		defaultAccrualDaysPerYearIfAvailable: function () {
			if (this.get('isLimited')) {
				return this.get('defaultAccrualDaysPerYear');
			} else if (this.get('isAvailable')) {
				return '\u221E'; //infinity symbol
			}
		}.property('defaultAccrualDaysPerYear', 'isAvailable', 'isLimited'),
		defaultAccrualHoursPerYear: function () {
			var defaultAccrualDaysPerYear = this.get('defaultAccrualDaysPerYear');
			if ($.isNumeric(defaultAccrualDaysPerYear)) {
				return zen.toAtMostNDigits(defaultAccrualDaysPerYear * 8);
			}
		}.property('defaultAccrualDaysPerYear'),
		defaultAccrualHoursPerTenHours: function () {
			return zen.toAtMostNDigits(this.get('defaultAccrualRate') * 12 * 10 / 2080, 5);
		}.property('defaultAccrualRate'),
		balanceCapDays: Ember.computed.hoursToDays("balanceCap"),
		rolloverCapDays: Ember.computed.hoursToDays("rolloverCap"),
		maximumNegativeBalanceDays: Ember.computed.hoursToDays("maximumNegativeBalance"),
		halfDayDisabled: function () {
			var defaultWorkdayHours = this.get('companyPto.defaultWorkdayHours');
			var minimumIncrementHours = this.get('minimumIncrementHours');
			if (this.get('isTrackedAndAvailable') && $.isNumeric(defaultWorkdayHours) && $.isNumeric(minimumIncrementHours) &&
				Number(defaultWorkdayHours) / 2 < Number(minimumIncrementHours)) {
				return true;
			}
			return false;
		}.property('companyPto.defaultWorkdayHours', 'minimumIncrementHours', 'isTrackedAndAvailable'),
		truncatedBalanceCap: Ember.computed.truncated('balanceCap', 2),
		truncatedAccrualCap: Ember.computed.truncated('accrualCap', 2),
		truncatedUsageCap: Ember.computed.truncated('usageCap', 2),
		truncatedRolloverCap: Ember.computed.truncated('rolloverCap', 2),
		hasRolloverCap: function () {
			return $.isNumeric(this.get('rolloverCap'));
		}.property('rolloverCap'),
		hasBalanceCap: function () {
			return $.isNumeric(this.get('balanceCap'));
		}.property('balanceCap'),
		hasAccrualCap: function () {
			return $.isNumeric(this.get('accrualCap'));
		}.property('accrualCap'),
		hasUsageCap: function () {
			return $.isNumeric(this.get('usageCap'));
		}.property('usageCap'),
		needsManualAnchorDate: Ember.computed.in('accrualPeriod', ['BW', 'We']),
		order: function () {
			return ['VACATION', 'SICK', 'PERSONAL'].indexOf(this.get('type'));
		}.property('type'),
		defaultTitle: function () {
			return {
				'VACATION': 'Vacation',
				'SICK': 'Sick Leave',
				'PERSONAL': 'Personal Leave',
				'FLOATING': 'Floating',
				'BEREAVEMENT': 'Bereavement',
				'JURY_DUTY': 'Jury Duty',
			}[this.get('type')];
		}.property('type'),
		isComplete: function () {
			return (this.get('isBasicComplete') && this.get('isSecondaryComplete')) || !this.get('isTracked');
		}.property('isBasicComplete', 'isSecondaryComplete', 'isTracked'),
		isIncomplete: function () {
			return !this.get('isComplete') && this.get('isAvailable');
		}.property('isComplete', 'isAvailable'),
		isBasicComplete: function () {
			if (!this.get('isTracked')) {
				return true;
			}
			if (!this.get('isHourlyAccrued')) {
				if (!this.get('accrualPeriod')) {
					return false;
				}
				if (this.get('accrualPeriod') === "BW" && !this.get('anchorStartDate')) {
					return false;
				}
			}
			return this.get('defaultAccrualRate');
		}.property('isTracked', 'isAvailable', 'defaultAccrualRate', 'accrualPeriod', 'anchorStartDate'),
		employeeAccounts: function () {
			return this.get('companyPto.employeeAccounts') && this.get('companyPto.employeeAccounts').filterProperty('type', this.get('type'));
		}.property('type', 'companyPto.employeeAccounts.@each.type'),
		isProratingRelevant: function () {
			return this.get('isTrackedAndAvailable') && (this.get('isHourlyAccrued') || !this.get('isAccrualYearBased') || !this.get('isYearHireDateBased'));
		}.property('isTrackedAndAvailable', 'isHourlyAccrued', 'isAccrualYearBased', 'isYearHireDateBased'),
		isUntracked: function (key, value) {
			// setter
			if (arguments.length > 1) {
				this.set('isTracked', !value);
				return value;
			}

			return !this.get('isTracked');
		}.property('isTracked'),
		isUnavailableOnCompletePto: function () {
			return this.get('companyPto.isComplete') && !this.get('isAvailable');
		}.property('companyPto.isComplete', 'isAvailable'),
		lowerCaseType: function () {
			return this.get('type').toLowerCase();
		}.property('type'),
	});

	App.BlackoutDateRange = DS.Model.extend({
		startDate: DS.attr('string'),
		endDate: DS.attr('string'),
		startDateAsMoment: Ember.computed('startDate', function () {
			return moment(this.get('startDate'));
		}),
		endDateAsMoment: Ember.computed('endDate', function () {
			return moment(this.get('endDate'));
		}),
		employerPTOAccount: DS.belongsTo('App.EmployerPtoAccount')
	});

	App.BalanceHistoryItem = DS.Model.extend({
		pto: DS.belongsTo("App.EmployeePto"),
		type: attr('string'),
		scheduledDate: attr('string'),
		isScheduled: attr('boolean'),
		amount: attr('number'),
		vacationRequest: DS.belongsTo("App.VacationRequest"),
		accountType: attr('string'),
		year: attr('number'),
		postTime: attr('string'),
		isPosted: attr('boolean'),
		isPending: Ember.computed.not('isPosted'),
		balance: attr('number'),
		blameName: attr('string'),
		reason: attr('string'),
		vacationStartDate: Ember.computed.alias('vacationRequest.startDate'),
		vacationEndDate: Ember.computed.alias('vacationRequest.endDate'),
		periodStartDate: attr('string'),
		periodEndDate: attr('string'),
		hours: attr('number'),
		liability: attr('number'),
		periodStartDateAsMoment: Ember.computed.asMoment('periodStartDate'),
		periodEndDateAsMoment: Ember.computed.asMoment('periodEndDate'),
		vacationStartDateAsMoment: Ember.computed.asMoment('vacationStartDate'),
		vacationEndDateAsMoment: Ember.computed.asMoment('vacationEndDate'),
		prettyPeriodStartDate: Ember.computed.prettyDate('periodStartDate', "M/D/YYYY"),
		prettyPeriodEndDate: Ember.computed.prettyDate('periodEndDate', "M/D/YYYY"),
		prettyVacationStartDate: Ember.computed.prettyDate('vacationStartDate', "M/D/YYYY"),
		prettyVacationEndDate: Ember.computed.prettyDate('vacationEndDate', "M/D/YYYY"),
		prettyLiability: Ember.computed.commas('liability'),
		prettyDate: Ember.computed.prettyDate('date'),
		truncatedBalance: Ember.computed.truncated('balance', 2),
		truncatedAmount: Ember.computed.truncated('amount', 2),
		account: function () {
			return this.get('pto') && this.get('pto').account(this.get('accountType'));
		}.property('pto', 'pto.accounts.@each.type', 'pto.accounts.@each.isStagingAccount'),
		accountName: Ember.computed.alias('account.name'),
		date: function () {
			if (this.get("isPosted")) {
				return moment(this.get('postTime')).format("MM/DD/YYYY");
			} else {
				return moment(this.get('scheduledDate')).add(-1, "day").format("MM/DD/YYYY");
			}
		}.property('isPosted', 'postTime', 'scheduledDate'),
		mainDescription: function () {
			switch (this.get('type')) {
				case 'edit':
					return "Edited by " + this.get("blameName");
				case 'request':
				case 'record':
					var startDate = this.get('vacationStartDateAsMoment');
					var endDate = this.get('vacationEndDateAsMoment');
					var periodStartDate = this.get('periodStartDateAsMoment');
					var periodEndDate = this.get('periodEndDateAsMoment');
					var start = null;
					var end = null;
					var isPartial = false;
					var description;
					if (periodStartDate && periodEndDate) {
						start = this.get('prettyPeriodStartDate');
						end = this.get('prettyPeriodEndDate');
						isPartial = !(startDate && startDate.isSame(periodStartDate) && endDate && endDate.isSame(periodEndDate));
					} else {
						start = this.get('prettyVacationStartDate');
						end = this.get('prettyVacationEndDate');
					}
					var dates;
					if (start == end) {
						dates = start;
					} else {
						dates = start + ' \u2013 ' + end;
					}

					if (this.get('isPosted')) {
						description = this.get('accountName') + ' Taken';
					} else if (this.get('isScheduled')) {
						description = this.get('accountName') + ' Scheduled';
					} else {
						description = this.get('accountName') + ' Requested';
					}
					if (isPartial) {
						description += ' (partial charge)';
					}

					return description;
				case 'adjustment':
					return 'Balance adjustment';
				case 'delete':
					return this.get('accountName') + " deleted by " + this.get("blameName");
				case 'accrual':
					return 'Accrual';
				case 'hourly accrual':
					return 'Accrual for ' + this.get('hours') + ' hours worked';
				case 'rollover':
					return 'Annual rollover adjustment';
				case 'hourly refund':
					return 'Refund for unpaid hours';
				case 'start':
					return 'Account started';
				case 'script':
					return 'Beginning of balance history';
				case 'import':
					return 'Initial balance set by ' + this.get('blameName');
				case 'set':
					return 'Balance set by ' + this.get('blameName');
				case 'migrate':
					return 'Balance now shows remaining leave';
				case 'new start':
					return 'New Policy Assigned'
			}
		}.property(
			'type',
			'blameName',
			'reason',
			'prettyPeriodStartDate',
			'prettyPeriodEndDate',
			'accountName',
			'prettyVacationStartDate',
			'prettyVacationEndDate',
			'vacationStartDateAsMoment',
			'vacationEndDateAsMoment'
		),
		secondaryDescription: function () {
			switch (this.get('type')) {
				case 'record':
					return 'recorded by ' + this.get("blameName");
				case 'adjustment':
					if (this.get('reason')) {
						return 'reason: ' + this.get('reason');
					}
			}
		}.property('type', 'blameName', 'reason'),
		statusOld: function () {
			if (this.get('isScheduled')) {
				return 'Scheduled';
			} else if (this.get('isPosted')) {
				return 'Posted';
			} else if (this.get('isPending')) {
				return 'Pending';
			} else {
				return 'N/A';
			}
		}.property('isScheduled', 'isPosted', 'isPending'),
		status: function () {
			var vacationStatus = this.get('vacationRequest.status');

			if (vacationStatus) {
				return vacationStatus;
			} else if (this.get('isScheduled')) {
				return 'scheduled';
			} else if (this.get('isPosted')) {
				return 'posted';
			} else if (this.get('isPending')) {
				return 'pending';
			} else {
				return 'n/a';
			}
		}.property('isScheduled', 'isPosted', 'isPending', 'vacationRequest.status'),
		isApproved: Ember.computed.equal('status', 'approved'),
		isRequested: Ember.computed.equal('status', 'requested'),
		hasDateRange: function () {
			switch (this.get('type')) {
				case 'record':
					return true;
				case 'accrual':
					return true;
				case 'request':
					return true;
				case 'hourly accrual':
					return true;
				default:
					return false;
			}
		}.property('type'),
		isNegative: Ember.computed.lt('amount', 0),
		prettyDates: function () {
			var startDate = null;
			var endDate = null;
			switch (this.get('type')) {
				case 'accrual':
					startDate = moment(this.get('periodStartDate'), 'MM/DD/YYYY');
					endDate = moment(this.get('periodEndDate'), 'MM/DD/YYYY');
					break;
				case 'request':
				case 'record':
					startDate = moment(this.get('vacationStartDate'), 'MM/DD/YYYY');
					endDate = moment(this.get('vacationEndDate'), 'MM/DD/YYYY');
					break;
				default:
					startDate = moment(this.get('date'), 'MM/DD/YYYY');
					endDate = moment(this.get('date'), 'MM/DD/YYYY');
					break;
			}

			if (startDate && !endDate) {
				return startDate.format('MMM D, YYYY');
			} else if (startDate && endDate) {
				if (startDate.isSame(endDate)) {
					return startDate.format('MMM D, YYYY');
				} else {
					return startDate.format('MMM D, YYYY') + '\u2013' + endDate.format('MMM D, YYYY');
				}
			} else {
				return '-';
			}
		}.property('type', 'periodStartDate', 'periodEndDate', 'vacationStartDate', 'vacationEndDate', 'date'),
	});

	App.VacationType = DS.Model.extend({
		vacationRequests: DS.hasMany('App.VacationRequest'),
		pto: DS.belongsTo("App.EmployerPto"),
		name: attr('string'),
		countsAs: attr('string'),
		status: attr('string'),
		isLocked: attr('boolean'),
		isNameUnlocked: attr('boolean'),
		hasRequests: attr('boolean'),
		order: attr('number'),
		account: function () {
			if (!this.get('countsAs')) {
				return null;
			}
			return this.get('pto') && this.get('pto').account(this.get('countsAs'));
		}.property('pto.accounts.@each.type', 'countsAs'),
		isAccountAvailable: function () {
			return this.get('countsAs') === '' || this.get('account.isAvailable');
		}.property('countsAs', 'account.isAvailable'),
		isAvailable: Ember.computed.and('isAccountAvailable', 'isActive'),
		accountName: function () {
			if (this.get('account')) {
				return this.get('account.name');
			}
			return 'Not counted';
		}.property('account', 'account.name'),
		defaultAccountName: function () {
			if (this.get('account')) {
				return this.get('account.defaultTitle');
			}

			return 'Not Counted';
		}.property('account', 'account.name'),
		isActive: Ember.computed.equal('status', 'active'),
		isEditable: function () {
			return this.get('isNameUnlocked') || !this.get('isLocked');
		}.property('isNameUnlocked', 'isLocked'),
		countsAsYesNo: function () {
			return this.get('countsAs') ? 'yes' : 'no';
		}.property('countsAs'),
	});

	App.PtoTenureLevel = DS.Model.extend({
		pto: DS.belongsTo('App.EmployerPto'),
		account: DS.belongsTo('App.EmployerPtoAccount'),
		years: attr('number'),
		extraAccrual: attr('number'),
		extraHourlyAccrualHours: attr('number'),
		extraHourlyAccrual: attr('number'), // Deprecated
		extraAccrualDays: Ember.computed.hoursPerMonthToDaysPerYear('extraAccrual', 'account.companyPto.defaultWorkdayHours'),
		extraHourlyAccrualDays: Ember.computed.hoursPerMonthToDaysPerYear('extraHourlyAccrual', 'account.companyPto.defaultWorkdayHours'),
	});

	App.VacationRequest = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		startDate: attr('string'),
		endDate: attr('string'),
		createdDate: attr('string'),
		hours: attr('number'),
		vacationType: DS.belongsTo('App.VacationType'),
		status: attr('string'),
		approvedDate: attr('string'),
		availableAsOf: attr('string'),
		asOfDate: attr('string'),
		asOfHours: attr('number'),
		isPostedOrPast: attr('boolean'),
		isHalfDay: attr('boolean'),
		isPartialDay: attr('boolean'),
		isManagerRecorded: attr('boolean'),
		reason: attr('string'),
		denyReason: attr('string'),
		vacationTypeName: Ember.computed.alias('vacationType.name'),
		countsAs: Ember.computed.alias('vacationType.countsAs'),
		truncatedAvailableAsOf: Ember.computed.truncated('availableAsOf', 2),
		prettyStartDate: Ember.computed.prettyDate('startDate'),
		prettyEndDate: Ember.computed.prettyDate('endDate'),
		loaRequestId: attr('number'),
		associatedLoaReason: attr('raw'),
		loaReasonsForEmployee: attr('raw'),
		capitalizedStatus: function () {
			return Ember.String.capitalize(this.get('status') || '');
		}.property('status'),
		prettyDates: function () {
			if (this.get('prettyStartDate') == this.get('prettyEndDate')) {
				return this.get('prettyStartDate');
			} else {
				return this.get('prettyStartDate') + '\u2013' + this.get('prettyEndDate');
			}
		}.property('prettyStartDate', 'prettyEndDate'),
		employeeAccountId: attr('number'),
		companyAccountId: attr('number'),
		employeeAccount: function () {
			var pto = this.get('employee.pto');
			return pto && pto.account(this.get('countsAs'));
		}.property('employee.pto.accounts.@each.type', 'employee.pto.accounts.@each.isStagingAccount', 'countsAs'),
		companyAccount: Ember.computed.alias('employeeAccount.companyAccount'),
		prettyCreatedDate: Ember.computed.prettyDate('createdDate'),
		startDateAsDate: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY').toDate();
		}.property('startDate'),
		startDateAsEpoch: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY').toDate().getTime() / 1000;
		}.property('startDate'),
		endDateAsEpoch: function () {
			return moment(this.get('endDate'), 'MM/DD/YYYY').toDate().getTime() / 1000;
		}.property('endDate'),
		isRequested: Ember.computed.equal('status', 'requested'),
		isApproved: Ember.computed.equal('status', 'approved'),
		statusDeleted: Ember.computed.equal('status', 'deleted'),
		statusGroup: function () {
			return {
				'approved': 'approvedOrRequested',
				'requested': 'approvedOrRequested',
				'cancelled': 'cancelled',
				'deleted': 'deniedOrDeleted',
				'denied': 'deniedOrDeleted',
			}[this.get('status')];
		}.property('status'),
		statusOrder: function () {
			return {
				'requested': 5,
				'approved': 4,
				'cancelled': 3,
				'denied': 2,
				'deleted': 1,
			}[this.get('status')];
		}.property('status'),
		isVisible: function () {
			return this.get('status') != 'hidden' && !this.get('isNew');
		}.property('status', 'isNew'),
		accountName: function () {
			return this.get("vacationType.accountName");
		}.property("vacationType.accountName"),
		isSickOrPersonalLeave: function () {
			return this.get('vacationType.countsAs') === "SICK" || this.get('vacationType.countsAs') === "PERSONAL";
		}.property('vacationType.countsAs'),
		isVacation: function () {
			return this.get('vacationType.countsAs') === "VACATION";
		}.property('vacationType.countsAs'),
		canRequestPartial: function () {
			return this.get('startDate') == this.get('endDate') || !this.get('endDate') || !this.get('startDate');
		}.property('startDate', 'endDate', 'vacationType.countsAs'),
		_isPartialDay: function () {
			return this.get('requestPartialDay') && this.get('canRequestPartial');
		}.property('requestPartialDay', 'canRequestPartial'),
		_isHalfDay: function () {
			return this.get('requestHalfDay') && this.get('canRequestPartial');
		}.property('requestHalfDay', 'canRequestPartial'),
		startDateIsValid: function () {
			var startDate = moment(this.get("startDate"), "MM/DD/YYYY", true);
			return startDate.isValid() && startDate.year() >= 1000;
		}.property('startDate'),
		endDateIsValid: function () {
			var endDate = moment(this.get("endDate"), "MM/DD/YYYY", true);
			return endDate.isValid() && endDate.year() >= 1000;
		}.property('endDate'),
		datesAreOrdered: function () {
			var dateStart = new Date(this.get('startDate'));
			var dateEnd = new Date(this.get('endDate'));
			if (dateEnd < dateStart) {
				return false;
			}
			return true;
		}.property('startDate', 'endDate'),
		datesAreValid: function () {
			return this.get('startDateIsValid') && this.get('endDateIsValid') && this.get('datesAreOrdered');
		}.property('startDateIsValid', 'endDateIsValid', 'datesAreOrdered'),
		reasonIsValid: function () {
			return !/[\uD800-\uDFFF]/.test(this.get('reason'));
		}.property('reason'),
		hoursAreValid: function (limit) {
			return !this.get('hours') || this.get('hours') <= limit;
		},
		validateRequestHours: function () {
			this.set('errorText', '');
			if (!this.hoursAreValid(24)) {
				this.set('errorText', 'Invalid custom hours');
				return false;
			}
			return true;
		},
		validateRecordHours: function () {
			this.set('errorText', '');
			if (!this.hoursAreValid(365 * 24)) {
				this.set('errorText', 'Invalid number of hours');
				return false;
			}
			return true;
		},
		validateMinimumIncrement: function () {
			var minimumIncrementString = this.get('vacationType.account.minimumIncrementHours');
			var hours = this.get('hours');
			if ($.isNumeric(minimumIncrementString) && $.isNumeric(hours) && Number(minimumIncrementString) > Number(hours)) {
				var companyName = this.get('employee.pto.companyPto.company.name');
				var accountName = this.get('vacationType.account.name');
				var minimumIncrementTable = this.get('employee.pto.companyPto.minimumIncrementTable');
				var errorText = "Please enter a number of hours greater than " + minimumIncrementTable[minimumIncrementString] + ". This PTO policy requires employees to take at least " + minimumIncrementTable[minimumIncrementString] + " off for " + accountName + " requests.";
				this.set('errorText', errorText);
				return false;
			}
			else {
				return true;
			}
		},
		validateAndSetErrorMessage: function () {
			this.set('errorText', '');
			if (!this.get('startDateIsValid')) {
				this.set('errorText', App.PtoWarningMessages.invalid_start_date);
				return false;
			}
			if (!this.get('endDateIsValid')) {
				this.set('errorText', App.PtoWarningMessages.invalid_end_date);
				return false;
			}
			var vacationType = this.get('vacationType.id');
			if (!vacationType) {
				this.set('errorText', App.PtoWarningMessages.vacation_type_not_selected);
				return false;
			}
			if (!this.get('datesAreOrdered')) {
				this.set('errorText', App.PtoWarningMessages.date_order_invalid);
				return false;
			}
			if (!this.get('reasonIsValid')) {
				this.set('errorText', App.PtoWarningMessages.special_characters_not_allowed);
				return false;
			}
			return true;
		},
		clearUnmatchingVacationType: function () {
			//this keeps the admin/manager from adding an inappropriate vacation type to a vacation request.
			if (!this.get('isNew') || !this.get('employee.pto.companyPto.id') || !this.get('vacationType.pto.id')) {
				return;
			}
			if (this.get('employee.pto.companyPto.id') != this.get('vacationType.pto.id')) {
				console.warn("RESETTTING");
				console.warn('employee.pto.companyPto.id');
				console.warn('vacationType.pto.id');
				this.set('vacationType', null);
			}
		}.observes('vacationType', 'employee'),
	});

	App.CompanyHolidayCalendar = DS.Model.extend({
		companyPto: DS.belongsTo('App.EmployerPto'),
		name: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		type: attr('string'),
		isActive: attr('boolean'),
		isSameDay: function () {
			return this.get('startDate') == this.get('endDate');
		}.property('startDate', 'endDate'),
		isFederal: Ember.computed.equal('type', 'Federal'),
		isCustom: Ember.computed.equal('type', 'Custom'),
		isVisible: function () {
			return this.get('isActive') || this.get('isFederal');
		}.property('isActive', 'isFederal'),
		year: function () {
			if (this.get('startDate')) {
				var date = zen.parseAmericanDate(this.get('startDate'));
				if (date) {
					return date['year'] + '';
				}
			}

			return null;
		}.property('startDate')
	});

	App.TemplateEmployerPtoAccount = DS.Model.extend({
		account: DS.belongsTo('App.EmployerPtoAccount'),
		defaultAccrualRate: attr('string'),
		isTracked: attr('boolean'),
		isAvailable: attr('boolean'),
		accrualPeriod: attr('string'),
		yearStartDate: attr('string'),
		name: attr('string'),
		availableForType: attr('string'),
		balanceCap: attr('string'),
		accrualCap: attr('string'),
		usageCap: attr('string'),
		minimumIncrementHours: attr('string'),
		isAnnuallyReset: attr('boolean'),
		waitingPeriodDays: attr('number'),
		isPaidInAdvance: attr('boolean'),
		accrueWaitingPeriod: attr('boolean'),
		holidayWaitingPeriod: attr('boolean'),

		templateCode: attr('string'),
		zipCode: attr('string'),
		state: attr('string'),
		isCompliant: attr('boolean'),
		tenureLevels: DS.hasMany('App.TemplatePtoTenureLevel'),
		defaultAccrualDaysPerYear: Ember.computed.hoursPerMonthToDaysPerYear('defaultAccrualRate', 'account.companyPto.defaultWorkdayHours'),
		hasTenureLevels: function () {
			return !!this.get('tenureLevels.length');
		}.property('tenureLevels.[]'),
		accrualPeriodHumanized: function () {
			// fits grammaticall in 'on a ____ basis'
			return {
				"Mo": "Monthly",
				"SM": "Semi-monthly",
				"We": "Weekly",
				"BW": "Bi-weekly",
				"Qt": "Quarterly",
				"Ye": "Annual",
				"PP": 'Pay Period',
			}[this.get('accrualPeriod')];
		}.property('accrualPeriod'),
	});

	App.TemplatePtoTenureLevel = DS.Model.extend({
		account: DS.belongsTo('App.TemplateEmployerPtoAccount'),
		years: attr('number'),
		extraAccrual: attr('number'),
	});

	App.EmployeeImpersonation = DS.Model.extend({
		impersonatedEmployee: DS.belongsTo('App.AllEmployee'),
		impersonatorEmployee: DS.belongsTo('App.AllEmployee'),
		createAt: attr('string'),
		expireAt: attr('string'),
		status: attr('string'),
	}).reopenClass({
		noBatch: true
	});

	App.CustomReport = DS.Model.extend({
		name: attr('string'),
		fields: attr('string'),
		headersJson: attr('string'),
		headers: function () {
			return JSON.parse(this.get('headersJson'));
		}.property('headersJson'),
		status: attr('string'),
		runCount: attr('number'),
		company: DS.belongsTo('App.Company'),
		reportDocuments: DS.hasMany('App.ReportDocument'),
		includeInternationalEmployees: attr('boolean'),
		includeTerminatedEmployees: attr('boolean'),
		includeEmployeeDependentsAsRows: attr('boolean'),
		isPointInTime: attr('boolean'),
		containsSensitiveInfo: attr('boolean'),
		description: attr('string'),
		showRunreport: false,
		showLastUpdate: false,
		showDownload: true,
		createdBy: attr('number'),
		timestamp: attr('string'),
		fileFormat: attr('string'),
		fieldFiltersJson: attr('string'),
		fieldFilters: function () {
			return JSON.parse(this.get('fieldFiltersJson'));
		}.property('fieldFiltersJson'),
		filtersJson: attr('string'),
		filters: function () {
			return JSON.parse(this.get('filtersJson'));
		}.property('filtersJson'),
	});

	App.ReportDocument = DS.Model.extend({
		customReport: DS.belongsTo('App.CustomReport'),
		company: DS.belongsTo('App.Company'),
		name: attr('string'),
		description: attr('string'),
		status: attr('string'),
		url: attr('string'),
		date: attr('date'),
		timeZone: attr('string'),
		generatedTime: attr('string'),
		prettyStartDate: function () {
			return moment(this.get('generatedTime'), 'YYYY-MM-DDTHH:mm:ssZ').tz(this.get('timeZone') || "America/Los_Angeles").format('MMM Do YYYY');
		}.property('generatedTime', 'timeZone'),
		prettyStartTime: function () {
			return moment(this.get('generatedTime'), 'YYYY-MM-DDTHH:mm:ssZ').tz(this.get('timeZone') || "America/Los_Angeles").format('h:mm a');
		}.property('generatedTime', 'timeZone'),
		generatedBy: DS.belongsTo('App.User'),
	});

	App.FieldMetadata = DS.Model.extend({
		name: attr('string'),
		model: attr('string'),
		section: attr('string'),
		isSensitive: attr('boolean'),
		effectiveDateable: attr('boolean'),
		isGroup: attr('boolean'),
	}).reopenClass({
		noBatch: true
	});

	App.EmployeeHistory = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		fields: attr('raw')
	}).reopenClass({
		noBatch: true
	});

	App.CompanyStockOptionAdditionalDetails = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		minimumNumOfExercisableOptions: attr('number'),
		planDocument: attr('string'),
		enrollmentStatus: attr('string'),
		isSetupComplete: Ember.computed.equal('enrollmentStatus', 'complete'),
		hasPermissionToViewGrants: attr('boolean'),
	});

	App.CompanyStockOption = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		documentSets: DS.hasMany('App.StockOptionDocumentSet'),
		approverName: attr('string'),
		approverEmail: attr('string'),
		approverTitle: attr('string'),
		approverSignature: attr('string'),
		attorneyName: attr('string'),
		attorneyEmail: attr('string'),
		attorneyTitle: attr('string'),
		attorneySignature: attr('string'),
		requiresAttorneyApprovals: attr('string'),
		reasonForCancellation: attr('string'),
		hideStockOptionCard: attr('boolean'),
		totalNumOfFullyDilutedOutstandingStocks: attr('number'),
		totalNumOfStocks: attr('number'),
		totalNumOfGrantedStocks: attr('number'),
		totalNumOfVestedStocks: attr('number'),
		totalNumOfExcercisedStocks: attr('number'),
		totalNumOfActualExercisedStocks: attr('number'),
		totalNumOfUnapprovedGrants: attr('number'),
		allowEarlyExerciseAllowed: attr('string'),
		hasPermissionToViewGrants: attr('boolean'),
		buyBackOnTermination: attr('string'),
		allowAcceleration: attr('boolean'),
		enrollmentStatus: attr('string'),
		existingGrantUrl: attr('string'),
		hasExistingGrants: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		authorizingPersonName: attr('string'),
		signature: attr('string'),
		marketPrice: attr('string'),
		parValue: attr('string'),
		volatility: attr('string'),
		accReportStartDate: attr('string'),
		accReportEndDate: attr('string'),
		ticker1: attr('string'),
		ticker2: attr('string'),
		ticker3: attr('string'),
		ticker4: attr('string'),
		ticker5: attr('string'),
		isBankInfoComplete: attr('boolean'),
		isDocumentsPageComplete: attr('boolean'),
		isCompanyInfoComplete: attr('boolean'),
		isExistingGrantInfoComplete: attr('boolean'),
		isPermissionComplete: attr('boolean'),
		isSetUpInfoComplete: attr('boolean'),
		unapprovedGrant: attr('string'),
		remaingStocksInPool: attr('number'),
		postTerminationValidity: attr('number'),
		grantedStocksPercentage: attr('number'),
		availableStocksPercentage: attr('number'),
		vestedStocksPercentage: attr('number'),
		exercisedStocksPercentage: attr('number'),
		getToken: attr('string'),
		areTemplatesReady: attr('boolean'),
		isExistingGrantParsed: attr('boolean'),
		grantParsingStatus: attr('string'),
		deleteExistingGrants: attr('boolean'),
		sendExerciseLapseNotification: attr('boolean'),
		isSetupInProcess: Ember.computed.equal('enrollmentStatus', 'begin'),
		isSetupProcessing: Ember.computed.equal('enrollmentStatus', 'processing'),
		isSetupComplete: Ember.computed.equal('enrollmentStatus', 'complete'),
		bankAccountTypeDisplay: function () {
			if (this.get('bankAccountType') == 'S') {
				return 'Saving';
			}
			if (this.get('bankAccountType') == 'C') {
				return 'Checking';
			}
			return this.get('bankAccountType');
		}.property('bankAccountType'),
		totalNumOfRemainingStocks: Ember.computed.difference('totalNumOfStocks', 'totalNumOfGrantedStocks'),
		totalNumOfRemainingStocksDisplay: Ember.computed.localeString('totalNumOfRemainingStocks'),
		totalNumOfGrantedStocksDisplay: Ember.computed.localeString('totalNumOfGrantedStocks'),
		totalNumOfVestedStocksDisplay: Ember.computed.localeString('totalNumOfVestedStocks'),
		totalNumOfActualExercisedStocksDisplay: Ember.computed.localeString('totalNumOfActualExercisedStocks'),
		totalNumOfStocksDisplay: Ember.computed.localeString('totalNumOfStocks'),
		EarlyExcerciseOptionAllowedForAll: Ember.computed.equal('allowEarlyExerciseAllowed', 'all'),
		EarlyExcerciseOptionAllowedForSome: Ember.computed.equal('allowEarlyExerciseAllowed', 'some'),
		EarlyExcerciseOptionAllowedForNone: Ember.computed.equal('allowEarlyExerciseAllowed', 'none'),
		requiresAttorneyDetails: function () {
			if (this.get('requiresAttorneyApprovals') == 'yes' || this.get('requiresAttorneyApprovals') == 'yesWithApproval') {
				return true;
			}
			return false;
		}.property('requiresAttorneyApprovals'),
		showManage: function () {
			if (this.get('isSetupComplete')) {
				return true;
			}
			return false;
		}.property('isSetupComplete'),
		totalStockPercentage: function () {
			if (this.get('totalNumOfStocks')) {
				var num = this.get('totalNumOfStocks') * 100 / this.get('totalNumOfFullyDilutedOutstandingStocks');
				return num.toFixed(2);
			}
			return null;
		}.property(),
		buyBackOnTerminationBoolValue: Ember.computed.equal('buyBackOnTermination', 'yes'),
	});

	App.EmployeeOptionGrant = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		agreement: DS.belongsTo('App.StockOptionAgreement'),
		exercises: DS.hasMany('App.StockOptionExerciseAction'),
		documentSet: DS.belongsTo('App.StockOptionDocumentSet'),
		grantDate: attr('string'),
		vestingStartDate: attr('string'),
		expirationDate: attr('string'),
		strikePrice: attr('string'),
		noOfAllocatedStocks: attr('number'),
		noOfSoldStocks: attr('number'),
		noOfVestedStocks: attr('number'),
		noOfExcercisedStocks: attr('number'),
		noOfActualExercisedStocks: attr('number'),
		noOfPreSetupExercisedStocks: attr('number'),
		noOfPreSetupEarlyExercisedStocks: attr('number'),
		totalNumVestOnTermination: attr('number'),
		vestingPeriod: attr('number'),
		cliffPeriod: attr('number'),
		vestingFrequency: attr('string'),
		stockType: attr('string'),
		allowEarlyExercise: attr('string'),
		accelerationType: attr('string'),
		grantStatus: attr('string'),
		isAccepted: attr('boolean'),
		isUnsigned: attr('boolean'),
		lastUpdateDate: attr('string'),
		finalVestmentDate: attr('string'),
		grantExpiryDate: attr('string'),
		token: attr('string'),
		isDoingAgreement: attr('boolean'),
		isExerciseAllowed: attr('boolean'),
		interestRate: attr('string'),
		expectedTerm: attr('string'),
		vestingScheduleDisplay: attr('string'),
		accelerationTypeDisplay: attr('string'),
		numCanExercise: attr('number'),
		isAllInfoPresent: attr('boolean'),
		state: attr('string'),
		hasExerciseDetails: attr('boolean'),
		newHire: DS.belongsTo('App.NewHire'),
		overridePostTerminationValidity: attr('number'),
		grantStatusHelper: function () {
			if (this.get('grantStatus') == 'allocated') {
				return 'Pending Board Approval';
			}
			if (this.get('grantStatus') == 'sentForApproval') {
				return 'Pending Board Approval';
			}
			if (this.get('grantStatus') == 'approved') {
				return 'Approved';
			}
			if (this.get('grantStatus') == 'accepted') {
				return 'Approved';
			}
		}.property('grantStatus'),
		possibleDuplicateGrant: DS.belongsTo('App.EmployeeOptionGrant'),
		grantName: function () {
			return "Grant dated: " + this.get('vestingStartDate');
		}.property('vestingStartDate'),
		formattedStrikePrice: function () {
			var strikePrice = this.get('strikePrice'),
				formatted = parseFloat(strikePrice, 10).toFixed(4).replace(/0{0,2}$/, "");
			return formatted;
		}.property('strikePrice'),
		totalNumReturnToPool: function () {
			return this.get('noOfAllocatedStocks') - this.get('totalNumVestOnTermination');
		}.property('noOfAllocatedStocks', 'totalNumVestOnTermination'),
		grantAdditionStatus: attr('string'),
		isGrantUploaded: Ember.computed.equal('grantAdditionStatus', 'existing'),
		allocatedStocksDisplay: function () {
			if (this.get('noOfAllocatedStocks')) {
				return this.get('noOfAllocatedStocks').toLocaleString();
			}
			return this.get('noOfAllocatedStocks');
		}.property('noOfAllocatedStocks'),
		soldStocksDisplay: function () {
			if (this.get('noOfSoldStocks')) {
				return this.get('noOfSoldStocks').toLocaleString();
			}
			return this.get('noOfSoldStocks');
		}.property('noOfSoldStocks'),
		vestedStocksDisplay: function () {
			if (this.get('noOfVestedStocks')) {
				return this.get('noOfVestedStocks').toLocaleString();
			}
			return this.get('noOfVestedStocks');
		}.property('noOfVestedStocks'),
		excercisedStocksDisplay: function () {
			if (this.get('noOfExcercisedStocks')) {
				return this.get('noOfExcercisedStocks').toLocaleString();
			}
			return this.get('noOfExcercisedStocks');
		}.property('noOfExcercisedStocks'),
		excercisedStocksDetailsDisplay: function () {
			if (this.get('noOfExcercisedStocks') == 0) {
				return '';
			}
			var earlyExercised = this.get('noOfExcercisedStocks') - this.get('noOfActualExercisedStocks');
			if (earlyExercised == 0) {
				return '';
			}
			if (this.get('noOfActualExercisedStocks') == 0) {
				return '(All early exercised)';
			}
			return '(' + earlyExercised.toLocaleString() + ' early exercised)';
		}.property('noOfExcercisedStocks', 'noOfActualExercisedStocks'),
		numCanExerciseDisplay: function () {
			if (this.get('numCanExercise')) {
				return this.get('numCanExercise').toLocaleString();
			}
			return this.get('numCanExercise');
		}.property('numCanExercise'),
		isIncomplete: function () {
			return (!this.get('employee') || !this.get('vestingStartDate') || !this.get('noOfAllocatedStocks') ||
				(this.get('cliffPeriod') == null) || !this.get('stockType') || (this.get('vestingPeriod') == null) ||
				!this.get('allowEarlyExercise') || !this.get('accelerationType') || (!this.get('vestingFrequency') && this.get('vestingPeriod') != 0) ||
				!this.get('state') || !this.get('documentSet'));
		}.property('employee', 'vestingStartDate', 'noOfAllocatedStocks', 'cliffPeriod', 'stockType', 'vestingPeriod', 'allowEarlyExercise', 'accelerationType', 'vestingSchedule'),
		canSignGrant: Ember.computed.equal('grantStatus', 'approved'),
		isApproved: function () {
			return this.get('grantStatus') == 'approved' || this.get('grantStatus') == 'accepted';
		}.property('grantStatus'),
		isAllocated: function () {
			return this.get('grantStatus') == 'allocated';
		}.property('grantStatus'),
		canEmployeeGrant: Ember.computed.or('isApproved', 'employee.company.stockOptionAdditionalDetails.hasPermissionToViewGrants'),
		noOfLapsedStocks: function () {
			var validity = null;
			if (this.get('overridePostTerminationValidity')) {
				validity = this.get('overridePostTerminationValidity');
			} else {
				validity = this.get('employee.company.stockOption.postTerminationValidity');
			}
			var today = moment();
			if (today.diff(moment(this.get('employee.terminationDate')), 'days') > validity) {
				return this.get('noOfAllocatedStocks') - this.get('noOfExcercisedStocks');
			}
			return 0;
		}.property('noOfAllocatedStocks', 'noOfExcercisedStocks', 'overridePostTerminationValidity', 'employee.company.stockOption.postTerminationValidity', 'employee.terminationDate'),
		noOfUnVestedStocksOnTermination: function () {
			return this.get('noOfAllocatedStocks') - this.get('noOfVestedStocks');
		}.property('noOfAllocatedStocks', 'noOfVestedStocks'),
	});

	App.StockOptionTemplate = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		uploadUrl: attr('string'),
		template: attr('string'),
		isTemplatized: attr('boolean'),
		sampleUrl: attr('string'),
		docType: attr('string'),
		isTemplateChanged: attr('boolean'),
	});

	App.StockOptionDocumentSet = DS.Model.extend({
		companyStockOption: DS.belongsTo('App.CompanyStockOption'),
		documentSetName: attr('string'),
		optionType: attr('string'),
		isEarlyExercise: attr('boolean'),
		accelerationType: attr('string'),
		agreementTemplate: DS.belongsTo('App.StockOptionTemplate'),
		exerciseTemplate: DS.belongsTo('App.StockOptionTemplate'),
		earlyExerciseTemplate: DS.belongsTo('App.StockOptionTemplate'),
		isInfoComplete: attr('boolean'),
		updateDate: attr('string'),
		accelerationTypeDisplay: attr('string'),
		status: attr('string'),
		isTemplatized: attr('boolean'),
		isActive: attr('boolean'),
		earlyExerciseDisplay: attr('string'),
		documentSetDetails: attr('string'),
		isDocSetInUse: attr('boolean'),
		displayName: function () {
			var tmp = this.get('documentSetName');
			return tmp;
		}.property('documentSetName', 'optionType', 'isEarlyExercise', 'accelerationTypeDisplay')
	});

	App.StockOptionAgreement = DS.Model.extend({
		grant: DS.belongsTo('App.EmployeeOptionGrant'),
		// generated
		htmlUrl: attr('string'),
		pdfUrl: attr('string'),
		// signature
		signature: attr('string'),
		signatureName: attr('string'),
		signatureDate: attr('string'),
		// spouse signature
		isAskSpouse: attr('boolean'),
		hasSpouseSignature: attr('boolean'),
		spouseSignature: attr('string'),
		spouseSignatureName: attr('string'),
		spouseSignatureDate: attr('string'),
		// computed
		isUnsigned: function () {
			if (this.get('hasSpouseSignature')) {
				return !(this.get('spouseSignature') && this.get('spouseSignatureName') && this.get('signature') && this.get('signatureName'));
			}
			return !(this.get('signature') && this.get('signatureName'));
		}.property('signature', 'signatureName', 'hasSpouseSignature', 'spouseSignature', 'spouseSignatureName')
	});


	App.StockOptionExerciseAction = DS.Model.extend({
		grant: DS.belongsTo('App.EmployeeOptionGrant'),
		isEarlyExercise: attr('boolean'),
		stocksExercised: attr('number'),
		stocksExercisedFromVested: attr('number'),
		stocksExercisedEarly: attr('number'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		authorizingPersonName: attr('string'),
		authorizingPersonSignature: attr('string'),
		amount: attr('number'),
		// generated
		htmlUrl: attr('string'),
		pdfUrl: attr('string'),
		// signature
		signature: attr('string'),
		signatureName: attr('string'),
		signatureDate: attr('string'),
		// spouse signature
		isAskSpouse: attr('boolean'),
		hasSpouseSignature: attr('boolean'),
		spouseSignature: attr('string'),
		spouseSignatureName: attr('string'),
		spouseSignatureDate: attr('string'),
		isExerciseInfoComplete: attr('boolean'),
		// computed
		isUnsigned: function () {
			return !this.get('signature') || !this.get('signatureName');
		}.property('signature', 'signatureName'),
		excercisedStocksDisplay: function () {
			if (this.get('stocksExercised')) {
				return this.get('stocksExercised').toLocaleString();
			}
			return this.get('stocksExercised');
		}.property('stocksExercised'),
		formattedAmount: function () {
			var amount = this.get('amount');
			return prettyCurrency(amount, true, 2);
		}.property('amount'),
	});


	// New Paystubs Models
	App.PaystubCompanyPayrollRun = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		payrollId: attr('string'),
		startDate: attr('string'),
		endDate: attr('string'),
		payDate: attr('string'),
		reasonCode: attr('string'),
		processedAtDate: attr('string'),
		isOffCycle: attr('boolean'),
		offCycleReason: attr('string'),
		isManual: attr('boolean'),
		dataUrl: attr('string'),
		createdAt: attr('string'),
		runStatus: attr('string'),
		overtimeCalculationMethod: attr('string'),
		paystub: DS.hasMany('App.PaystubEmployee'),
	});

	App.PaystubEmployee = DS.Model.extend({
		payrollRun: DS.belongsTo('App.PaystubCompanyPayrollRun'),
		employee: DS.belongsTo('App.AllEmployee'),
		dataUrl: attr('string'),
		hashValue: attr('string'),
		areAllDeductionsMapped: attr('boolean'),
		areAllEarningsMapped: attr('boolean'),
		isActive: attr('boolean'),
		isFake: attr('boolean'),
		isReversal: attr('boolean'),
		createdAt: attr('string'),
		relatedPaystub: DS.belongsTo('App.PaystubEmployee'),
		grossEarnings: attr('number'),
		federalTax: attr('number'),
		stateTax: attr('number'),
		ssTax: attr('number'),
		medicareTax: attr('number'),
		// totals
		totalEarnings: attr('number'),
		totalEarningsYtd: attr('number'),
		totalEmployeeDeductions: attr('number'),
		totalEmployeeDeductionsYtd: attr('number'),
		totalReimbursements: attr('number'),
		totalReimbursementsYtd: attr('number'),
		totalCompanyDeductions: attr('number'),
		totalCompanyDeductionsYtd: attr('number'),
		totalEmployeeTaxes: attr('number'),
		totalEmployeeTaxesYtd: attr('number'),
		totalCompanyTaxes: attr('number'),
		totalCompanyTaxesYtd: attr('number'),
		totalUncollectedTaxes: attr('number'),
		// pto and sick leave policies
		ptoJson: attr('string'),
		sickLeaveJson: attr('string'),
		pto: function () {
			return JSON.parse(this.get('ptoJson'));
		}.property('ptoJson'),
		sickLeave: function () {
			return JSON.parse(this.get('sickLeaveJson'));
		}.property('sickLeaveJson'),
		// from PayrollRun
		reasonCode: attr('string'),
		payrollProvider: attr('string'),
		offCycleReason: attr('string'),
		memo: attr('string'),
		showPaystub: function () {
			return App.switches.isActive('experimental_paystubs') || /^(ZN|YP)$/.test(this.get('payrollProvider'));
		}.property('payrollProvider'),
		startDate: attr('string'),
		endDate: attr('string'),
		payDate: attr('string'),
		payDateSortable: attr('string'),
		isFuturePaystub: attr('boolean'),
		overtimeCalculationMethod: attr('string'),
		// company info
		companyName: attr('string'),
		companyPhone: attr('string'),
		companyFEIN: attr('string'),
		companyStreet1: attr('string'),
		companyStreet2: attr('string'),
		companyCity: attr('string'),
		companyState: attr('string'),
		companyZipCode: attr('string'),
		// employee info
		compType: attr('string'),
		employmentSubType: attr('string'),
		employeeName: attr('string'),
		employeeSsn: attr('string'),
		employeeAddress: attr('string'),
		employeeCity: attr('string'),
		employeeState: attr('string'),
		employeeZip: attr('string'),

		description: function () {
			return "%@".fmt(this.get('payDate'));
		}.property('payDate'),
		// Smart
		smartstub: DS.belongsTo('App.SmartstubEmployee'),
		// Related line items
		deductions: DS.hasMany('App.PaystubDeductions'),
		summaries: DS.hasMany('App.PaystubSummary'),
		ptoSummaries: DS.hasMany('App.PaystubPtoSummary'),
		earnings: DS.hasMany('App.PaystubEarnings'),
		taxes: DS.hasMany('App.PaystubTaxes'),
		employeeTaxCredits: DS.hasMany('App.PaystubTaxCredits'),
	});

	App.PaystubTaxCredits = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		taxType: attr('string'),
		amount: attr('number'),
		currentAmount: attr('number'),
	});

	App.PaystubEarnings = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		smartDescription: DS.belongsTo('App.SmartstubEarningDescription'),
		smartChange: DS.belongsTo('App.SmartstubEarningChange'),
		loaSummariesJson: attr('string'),
		loaSummariesList: function () {
			var loaSummariesJson = this.get('loaSummariesJson');
			return loaSummariesJson ? JSON.parse(loaSummariesJson) : [];
		}.property('loaSummariesJson'),
		type: attr('string'),
		description: attr('string'),
		hourlyRate: attr('number'),
		numHours: attr('number'),
		amount: attr('number'),
		amountYtd: attr('number'),
		calculatedAmountYtd: attr('number'),
		isReimbursement: attr('boolean'),
		totalEarnings: attr('number'),
		totalEarningsYtd: attr('number'),
	});

	App.PaystubTaxes = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		name: attr('string'),
		employeeAmount: attr('number'),
		employeeAmountYtd: attr('number'),
		calculatedEmployeeAmountYtd: attr('number'),
		companyAmount: attr('number'),
		companyAmountYtd: attr('number'),
		calculatedCompanyAmountYtd: attr('number'),
		taxType: attr('string'),
	});

	App.PaystubDeductions = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		payrollCompanyDeduction: DS.belongsTo('App.PayrollCompanyDeduction'),
		smartDescription: DS.belongsTo('App.SmartstubDeductionDescription'),
		smartChange: DS.belongsTo('App.SmartstubDeductionChange'),
		isMappingIgnorable: attr('boolean'),
		isReimbursement: attr('boolean'),
		description: attr('string'),
		name: attr('string'),
		employeeAmount: attr('number'),
		employeeAmountYtd: attr('number'),
		calculatedEmployeeAmountYtd: attr('number'),
		companyAmount: attr('number'),
		companyAmountYtd: attr('number'),
		calculatedCompanyAmountYtd: attr('number'),
		isPureDeduction: attr('boolean'),
		isCheckingDeposit: attr('boolean'),
		checkDate: attr('string'),
		deductionType: attr('string'),
		deductionTypeHuman: attr('string'),
		checkDateSortable: function () {
			var _date = this.get('checkDate');
			if (_date) {
				return moment(_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
			} else {
				return '';
			}
		}.property('checkDate'),
	});

	App.PaystubSummary = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		description: attr('string'),
		amount: attr('number'), // models-unification: consolemodelsnew specifies string; models is newer
		amountYtd: attr('number'), // models-unification: consolemodelsnew specifies string; models is newer
		value: attr('string'),
	});

	App.PaystubPtoSummary = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		description: attr('string'),
		balanceUsed: attr('number'),
		balanceAtStart: attr('number'),
		balanceAtEnd: attr('number'),
	});

	App.SmartstubEmployee = DS.Model.extend({
		paystub: DS.belongsTo('App.PaystubEmployee'),
		payFrequency: attr('string'),
		nWeeklyAsNWeekly: attr('boolean'),
		compensationType: attr('string'),
		earningDescriptions: DS.hasMany('App.SmartstubEarningDescription'),
		earningChanges: DS.hasMany('App.SmartstubEarningChange'),
		deductionDescriptions: DS.hasMany('App.SmartstubDeductionDescription'),
		deductionChanges: DS.hasMany('App.SmartstubDeductionChange'),
	});

	App.SmartstubEarningDescription = DS.Model.extend({
		smartstub: DS.belongsTo('App.SmartstubEmployee'),
		paystubEarning: DS.belongsTo('App.paystubEarnings'),
		earningType: attr('string'),
	});

	App.SmartstubEarningChange = DS.Model.extend({
		smartstub: DS.belongsTo('App.SmartstubEmployee'),
		paystubEarning: DS.belongsTo('App.paystubEarnings'),
		earningType: attr('string'),
	});

	App.SmartstubDeductionDescription = DS.Model.extend({
		smartstub: DS.belongsTo('App.SmartstubEmployee'),
		paystubDeduction: DS.belongsTo('App.PaystubDeductions'),
		deductionType: attr('string'),
		preTaxCutOff: attr('number'),
		deductionPerMonth: attr('number'),
		contributionPerMonth: attr('number'),
		deductionPayPeriod: attr('number'),
		contributionPayPeriod: attr('number'),
		deductionAnnualMaximum: attr('number'),
		contributionAnnualMaximum: attr('number'),
		planDeductionTotal: attr('number'),
		planContributionTotal: attr('number'),
		planStartDate: attr('number'),
		planEndDate: attr('number'),
	});

	App.SmartstubDeductionChange = DS.Model.extend({
		smartstub: DS.belongsTo('App.SmartstubEmployee'),
		paystubDeduction: DS.belongsTo('App.PaystubDeductions'),
		deductionType: attr('string'),
		expectedTotalAmount: DS.attr('number'),
		actualTotalAmount: DS.attr('number'),
		normalAmount: DS.attr('number'),
		catchupPayPeriods: DS.attr('number'),
		catchupPayPeriodIndex: DS.attr('number'),
		catchupFirstPPAmount: DS.attr('number'),
		catchupLastPPAmount: DS.attr('number'),
		reviewStartDate: DS.attr('date'),
		reviewEndDate: DS.attr('date'),
		isInThirdOrFifthPayPeriod: DS.attr('boolean'),
	});

	// T&A
	App.TaCompany = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		aca: DS.belongsTo('App.CompanySettingsAca'),
		settingsComplete: attr('boolean'),
		isComplete: attr('boolean'),
		state: attr('string'),
		isCompleteOrPending: function () {
			return this.get('isComplete') || this.get('state') == 'pending';
		}.property('isComplete', 'state'),
		isTaSettingsReportingComplete: attr('boolean'),
		isTaSettingsSchedulingComplete: attr('boolean'),
		isTaOvertimeSettingsComplete: attr('boolean'),
		hasProjectCodes: attr('boolean'),
		roundingMinutes: attr('number'),
		overtimeRule: attr('string'),
		overtimeDaily: attr('number'),
		doubleOvertimeDaily: attr('number'),
		nevadaBenefitsOffered: attr('boolean'),
		payStartWeekDay: attr('number'),
		overtimeWeekly: attr('number'),
		geolocationEnabled: attr('boolean'),

		hasActiveProjectCodes: function () {
			return this.get('hasProjectCodes') && this.get('projectCodes.length');
		}.property('hasProjectCodes', 'projectCodes.[]'),
		reportsSelfReport: function () {
			return this.get('reportingMethod') == 'SR' || this.get('reportingMethod') == 'SRH';
		}.property('reportingMethod'),
		reportsClockIn: function () {
			return this.get('reportingMethod') == 'ET' || this.get('reportingMethod') == 'TK';
		}.property('reportingMethod'),
		reportsSR: function () {
			return this.get("reportingMethod") == 'SR';
		}.property('reportingMethod'),
		reportsSRH: function () {
			return this.get("reportingMethod") == 'SRH';
		}.property('reportingMethod'),
		reportsZenefitsWeb: function () {
			return this.get('reportingMethod') == 'ET';
		}.property('reportingMethod'),
		reportsTimeKeeper: function () {
			return this.get('reportingMethod') == 'TK';
		}.property('reportingMethod'),

		hasActiveSyncedPayroll: attr('boolean'),
		isPayScheduleValid: attr('boolean'),
		reportingMethod: attr('string'),
		providerSync: attr('boolean'),
		approvalHours: attr('number'),
		approvalType: attr('string'),
		hasNotesFlag: attr('boolean'),
		hasMealBreaks: attr('boolean'),
		firstMealBreakPenaltyHours: attr('number'),
		secondMealBreakPenaltyHours: attr('number'),
		unpaidMealBreakMinutes: attr('number'),
		daysAfterApproval: attr('number'),
		hasPaidLunch: attr('boolean'),
		paidLunchMinutes: attr('number'),
		hasPaidHolidays: attr('boolean'),
		hasAcceptedOvertimePolicy: attr('boolean'),
		overrideAnchorPayDate: attr('string'),
		payArrears: attr('number'),
		payFirstCheckDay: attr('number'),
		paySecondCheckDay: attr('number'),
		currentPayPeriodStartDate: attr('string'),
		currentPayPeriodEndDate: attr('string'),
		currentPayPeriodCheckDate: attr('string'),
		missedMealBreakPenaltyPayout: attr('boolean'),

		isAutoApproval: Ember.computed.equal('approvalType', 'auto'),
		isManualApproval: Ember.computed.equal('approvalType', 'manual'),
		laborFieldsString: attr('string'),
		laborFields: function () {
			const laborFieldsString = this.get('laborFieldsString');
			return laborFieldsString ? JSON.parse(laborFieldsString) : [];
		}.property('laborFieldsString'),

		hasPayDateValues: function () {
			var hasAllValues = this.get('payFirstCheckDay') !== null;
			if (this.get('isSemiMonthlyPayFrequency')) {
				hasAllValues = this.get('payFirstCheckDay') !== null && this.get('paySecondCheckDay') !== null;
			}
			return hasAllValues;
		}.property('payFirstCheckDay', 'paySecondCheckDay', 'payFrequency'),

		hasValidPayDays: function () {
			if (this.get('isSemiMonthlyPayFrequency')) {
				return this.get('hasPayDateValues') && (this.get('paySecondCheckDay') > this.get('payFirstCheckDay'));
			}
			return this.get('payFirstCheckDay') !== null;
		}.property('payFirstCheckDay', 'paySecondCheckDay', 'isSemiMonthlyPayFrequency'),
		hasSelectedPayStartWeekDay: Ember.computed.allNotEmpty('payStartWeekDay'),
		payFrequency: attr('string'),
		payFrequencyHuman: function () {
			switch (this.get('payFrequency')) {
				case 'SM': return "Semi-monthly";
				case 'BW': return "Bi-weekly";
				case 'Mo': return "Monthly";
				case 'We': return "Weekly";
			}
		}.property('payFrequency'),

		isMonthlyPayFrequency: Ember.computed.equal('payFrequency', 'Mo'),
		isSemiMonthlyPayFrequency: Ember.computed.equal('payFrequency', 'SM'),
		needsArrearsAndPayDates: Ember.computed.or('isMonthlyPayFrequency', 'isSemiMonthlyPayFrequency'),

		isAutoPush: function () {
			return this.get('approvalHours') === 0;
		}.property('approvalHours'),

		employeeStates: attr('string'),
		employeeStatesList: function () {
			var employeeStates = this.get('employeeStates');
			return employeeStates ? JSON.parse(employeeStates) : [];
		}.property('employeeStates'),

		bankName: attr('string'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		bankAccountType: attr('string'),
		hasBankInformation: Ember.computed.allNotEmpty('bankAccountNumber', 'bankRoutingNumber', 'bankName', 'bankAccountType'),
		billingPlan: attr('string'),
		authorizingPersonSignature: attr('string'),
		authorizingPersonName: attr('string'),

		// TODO BR: Copy pasta! (backwards compat with old flow)
		isSelfReporting: function () {
			return this.get('reportingMethod') == 'SRH' || this.get('reportingMethod') == 'SR';
		}.property('reportingMethod'),

		isSelfReportingHourly: function () {
			return this.get('reportingMethod') == 'SRH';
		}.property('reportingMethod'),

		isReportingInOut: function () {
			return this.get('reportingMethod') == 'TK' || this.get('reportingMethod') == 'SR' || this.get('reportingMethod') == 'ET';
		}.property('reportingMethod'),

		isNotReporting: Ember.computed.equal('reportingMethod', 'NR'),

		isEnrollable: function () {
			// Already enrolled
			if (this.get('enrolled')) {
				return true;
			}

			return this.get('reportingMethod') &&
				this.get('approvalHours') !== undefined;
		},
		// END COMPAT

		isBilledMonthly: function () {
			return this.get('billingPlan') == 'MON';
		}.property('billingPlan'),
		isBilledAnnually: function () {
			return this.get('billingPlan') == 'YR';
		}.property('billingPlan'),

		enrolled: attr('boolean'),

		progress: attr('string'),
		progressJSON: function () {
			var progress = this.get('progress');
			return progress ? JSON.parse(progress) : {};
		}.property('progress'),

		averageSyncDuration: attr('number'),
		formattedAverageSyncDuration: function () {
			var duration = this.get('averageSyncDuration');
			return moment.duration(duration, 'minutes').humanize();
		}.property('averageSyncDuration'),
		canSync: attr('boolean'),
		isSyncSupported: attr('boolean'),
		isCompanyOnZenefitsPayroll: attr('boolean')
	});

	App.TaCompanyEnrollment = DS.Model.extend({
	}).reopenClass({
		noBatch: true
	});

	App.TaEmployee = DS.Model.extend({
		settings: DS.belongsTo('App.TaCompany'),
		enrolled: attr('boolean'),
		employee: DS.belongsTo('App.AllEmployee'),
		approvedBy: DS.belongsTo('App.AllEmployee'),
		reportingMethod: attr('string'),
		reportingMethodBeforeTermination: attr('string'),
		overrideReportingMethod: attr('string'),
		isComplete: attr('boolean'),
		isReporting: attr('boolean'),
		hasReported: attr('boolean'),
		taStatus: attr('string'), // NOT_ENROLLED, NEVER_USED, ON_LUNCH, CLOCKED_OUT, CLOCKED_IN
		hasForgotClockOut: attr('boolean'),
		latestDuration: DS.belongsTo('App.TaEmployeeTimeDuration'),
		currentTime: attr('string'),
		lastTimeReported: attr('string'),
		hasMealBreaks: attr('boolean'),
		firstMealBreakPenaltyHours: attr('number'),
		secondMealBreakPenaltyHours: attr('number'),
		unpaidMealBreakMinutes: attr('number'),
		allMealBreakSettingsDefault: attr('boolean'),
		overtimeRule: attr('string'),
		overtimeDaily: attr('number'),
		payStartWeekDay: attr('number'),
		doubleOvertimeDaily: attr('number'),
		overtimeWeekly: attr('number'),
		nevadaBenefitsOffered: attr('boolean'),
		allOvertimeSettingsDefault: attr('boolean'),
		hasProjectCodes: attr('boolean'),
		projectCodes: DS.hasMany('App.TaProjectCode'),
		allProjectCodeSettingsDefault: attr('boolean'),
		geolocationEnabled: attr('boolean'),
		allGeolocationSettingsDefault: attr('boolean'),
		allLaborFieldSelectionsDefault: attr('boolean'),
		laborFieldsString: attr('string'),
		missedMealBreakPenaltyPayout: attr('boolean'),
		laborFields: function () {
			const laborFieldsString = this.get('laborFieldsString');
			return laborFieldsString ? JSON.parse(laborFieldsString) : [];
		}.property('laborFieldsString'),

		lastTimeReportedMoment: function () {
			return moment(this.get('lastTimeReported'), 'YYYY-MM-DD HH:mm:ssz'); //2016-04-03 05:00:00-07:00"
		}.property('lastTimeReported'),
		lastProjectCode: DS.belongsTo('App.TaProjectCode'),

		reportingMethodFull: function () {
			if (this.get('reportingMethod') == 'SR') {
				return "Self Reporting In/Out";
			}
			else if (this.get('reportingMethod') == 'SRH') {
				return "Self Reporting Hours";
			}
			else if (this.get('reportingMethod') == 'TK') {
				return "iPad TimeKeeper";
			}
			else if (this.get('reportingMethod') == 'ET') {
				return "Web/Mobile TimeKeeper";
			}
			else {
				return "Not Reporting";
			}
		}.property('reportingMethod'),

		isReportingInOut: function () {
			return this.get('reportingMethod') == 'TK' || this.get('reportingMethod') == 'SR' || this.get('reportingMethod') == 'ET';
		}.property('reportingMethod'),
		isNotReporting: Ember.computed.equal('reportingMethod', 'NR'),
		timeApprover: function (key, value, previousValue) {
			if (arguments.length > 1) {
				if (value.get('id') == this.get('employee.reportToEmployee.id')) {
					this.set('approvedBy', null);
				}
				else {
					this.set('approvedBy', value);
				}
			}
			var approver = this.get('approvedBy');
			if (approver) {
				return approver;
			}
			else {
				return this.get('employee.reportToEmployee');
			}

		}.property('approvedBy', 'employee.reportToEmployee'),
	});

	App.TaTimeApprovedDay = DS.Model.extend({
		version_id: attr('number'),
		employee: DS.belongsTo('App.AllEmployee'),
		currentClockInStatus: attr('string'),
		payPeriodEmployee: DS.belongsTo('App.TaPayPeriodEmployee'),
		timeDurations: DS.hasMany('App.TaEmployeeTimeDuration'),
		numberOfHours: attr('number'),
		numberOfOvertimeHours: attr('number'),
		numberOfDoubleOvertimeHours: attr('number'),
		approvedPtoForDay: attr('number'),
		approvedPtoVacationHours: attr('number'),
		approvedPtoPersonalHours: attr('number'),
		approvedPtoSickHours: attr('number'),
		numberOfHolidayHours: attr('number'),
		numberOfTotalHours: attr('number'),
		locked: attr('boolean'),
		submitted: attr('boolean'),
		date: attr('string'),
		missingAction: attr('string'),
		employeeTimezone: attr('string'),
		calculateWorkedHoursForDay: attr('number'),
		numberOfPenaltyHours: attr('number'),
		totalPayableHours: function () {
			return this.get('numberOfTotalHours') + this.get('numberOfHolidayHours') + this.get('approvedPtoForDay');
		}.property('numberOfTotalHours', 'numberOfHolidayHours', 'approvedPtoForDay'),

		hasHoursOverridden: Ember.computed.alias('locked'),

		empName: Ember.computed(function () {
			return this.get('employee.last_name') + ', ' + this.get('employee.first_name');
		}).property('employee.last_name', 'employee.first_name'),

		approverName: Ember.computed.alias('employee.ta.approvedBy.fullName'),

		ptoBreakdownEqualsTotal: function () {
			var ptoBreakdownsSum = this.get('approvedPtoVacationHours') + this.get('approvedPtoPersonalHours') + this.get('approvedPtoSickHours');
			return ptoBreakdownsSum === this.get('approvedPtoForDay');
		}.property('approvedPtoVacationHours', 'approvedPtoPersonalHours', 'approvedPtoSickHours', 'approvedPtoForDay'),

		dateMoment: function () {
			return moment.tz(this.get('date'), 'MM/DD/YYYY', this.get('employeeTimezone'));
		}.property('date', 'employeeTimezone'),

		numberOfHoursWithPto: Ember.computed(function () {
			return Number(this.get('numberOfHours')) + Number(this.get('approvedPtoForDay'));
		}).property('numberOfHours', 'approvedPtoForDay'),

		hasOvertimeToday: function () {
			return this.get('numberOfOvertimeHours') > 0.00;
		}.property('numberOfOvertimeHours'),
		hasDoubleOvertimeHours: function () {
			return this.get('numberOfDoubleOvertimeHours') > 0.00;
		}.property('numberOfDoubleOvertimeHours'),
		hasHolidayHours: function () {
			return this.get('numberOfHolidayHours') > 0.00;
		}.property('numberOfHolidayHours'),
		totalHours: function (key, value) {
			if (value !== undefined) {
				if ($.isNumeric(value) == true) {
					this.set('numberOfOvertimeHours', 0.00);
					this.set('numberOfDoubleOvertimeHours', 0.00);
					this.set('numberOfHours', value);
				}
				return this.get('numberOfHours');
			}
			else {
				return this.get('calculateWorkedHoursForDay');
			}
		}.property('numberOfHours', 'numberOfOvertimeHours', 'numberOfDoubleOvertimeHours'),

		formatDateLong: function () {
			return moment(this.get('date'), 'MM/DD/YYYY').format('MMMM Do YYYY');
		}.property('date'),
		formatDateLongDay: function () {
			return moment(this.get('date'), 'MM/DD/YYYY').format('dddd');
		}.property('date'),
		isToday: function () {
			var now = moment();
			return moment(this.get('date'), 'MM/DD/YYYY').isSame(now);
		}.property('date'),

		formatDate: function () {
			return moment(this.get('date'), 'MM/DD/YYYY').format('dddd, MMMM DD');
		}.property('date'),
		formatDateShort: function () {
			return moment(this.get('date'), 'MM/DD/YYYY').format('MMM DD');
		}.property('date'),
		formatDateShortWithDay: function () {
			var d = this.get('date');
			return d ? moment(d, 'MM/DD/YYYY').format('ddd, MMM DD') : '';
		}.property('date')
	});

	App.TaPayPeriodEmployee = DS.Model.extend({
		//version_id: attr('number'),
		employee: DS.belongsTo('App.AllEmployee'),
		payPeriod: DS.belongsTo('App.TaPayPeriod'),
		employeeTimeApproved: DS.hasMany('App.TaTimeApprovedDay'),
		employeeTimeApprovedSorted: Ember.computed.dateSorted('employeeTimeApproved', 'date'),
		lastAction: function () {
			var today = moment().format('MM/DD/YYYY');
			var tad = this.get('employeeTimeApproved').filterProperty('date', today);
			return tad.get('lastObject');
		}.property('employeeTimeApproved.@each.date'),
		totalReportedHours: attr('number'),
		totalRegularHours: attr('number'),
		totalOvertimeHours: attr('number'),
		totalDoubleOvertimeHours: attr('number'),
		totalPtoHours: attr('number'),
		totalHolidayHours: attr('number'),
		durationIssuesCount: attr('number'),
		missedMealBreaksCount: attr('number'),
		isApproved: attr('boolean'),
		approvedBy: DS.belongsTo('App.AllEmployee'),
		approvedAt: attr('string'),
		approvedAtFormatted: function () {
			return moment(this.get('approvedAt')).format('MMM D, YYYY hh:mm A');
		}.property('approvedAt'),
		isSubmitted: attr('boolean'),
		getStartOfPeriod: attr('string'),
		payPeriodTimes: DS.hasMany('App.TaTimeApprovedDay'),
		hasTimeToReport: Ember.computed.gt('employeeTimeApproved.length', 0),
		totalPtoAndHoliday: function () {
			return this.get('totalPtoHours') + this.get('totalHolidayHours');
		}.property('totalPtoHours', 'totalHolidayHours'),
		getCardinalWeek: attr('string'),
		hasFinishedLoading: function () {
			return this.get('employeeTimeApproved').filterProperty('isLoaded', false).length == 0;
		}.property('employeeTimeApproved.@each.isLoaded'),
		getFirstWeekTimeObjects: function () {
			return this.get('payPeriodTimes').slice(0, 7);
		}.property('payPeriodTimes'),
		approverName: function () {
			var name = this.get('employee.ta.approvedBy.informalFullName');
			if (name) {
				return name;
			}
			return this.get('employee.reportToEmployee.informalFullName');
		}.property('employee.ta.approvedBy.informalFullName', 'employee.reportToEmployee.informalFullName'),
		approverType: function () {
			var name = this.get('employee.ta.approvedBy.informalFullName');
			if (name) {
				return "approver";
			}
			return "manager";
		}.property('employee.ta.approvedBy.informalFullName'),
		formatHours: function (n) {
			if (n && n.toFixed) {
				return n.toFixed(2);
			}
			else {
				return '-';
			}
		},
		totalReportedHoursFormatted: function () {
			return this.formatHours(this.get('totalReportedHours'));
		}.property('totalReportedHours'),
		totalRegularHoursFormatted: function () {
			return this.formatHours(this.get('totalRegularHours'));
		}.property('totalRegularHours'),
		totalOvertimeHoursFormatted: function () {
			return this.formatHours(this.get('totalOvertimeHours'));
		}.property('totalOvertimeHours'),
		totalDoubleOvertimeHoursFormatted: function () {
			return this.formatHours(this.get('totalDoubleOvertimeHours'));
		}.property('totalDoubleOvertimeHours'),
		totalPtoHoursFormatted: function () {
			return this.formatHours(this.get('totalPtoHours'));
		}.property('totalPtoHours'),
		totalHolidayHoursFormatted: function () {
			return this.formatHours(this.get('totalHolidayHours'));
		}.property('totalHolidayHours'),
	});

	App.TaPayPeriod = DS.Model.extend({
		_tzinfo: attr('string'),
		company: DS.belongsTo('App.Company'),
		taCompany: DS.belongsTo('App.TaCompany'),
		isApproved: attr('boolean'),
		locked: attr('boolean'),
		startDate: attr('string'),
		endDate: attr('string'),
		submitEnd: attr('string'),
		payType: attr('string'),
		processed: attr('boolean'),
		taPayPeriodEmployees: DS.hasMany('App.TaPayPeriodEmployee'),
		estPushDate: attr('string'),
		prettyStartDate: Ember.computed.prettyDate('startDate'),
		prettyEndDate: Ember.computed.prettyDate('endDate'),
		runs: DS.hasMany('App.TaPayPeriodRun'),
		totalHours: attr('number'),
		totalOvertimeHours: attr('number'),
		totalEmployees: attr('number'),
		state: attr('string'),
		pushState: attr('string'),
		pushedAt: attr('string'),
		lockedAt: attr('string'),
		lockedBy_id: attr('number'),
		lastReminderEvent: DS.attr('string'),
		lastReminderEventTime: DS.attr('string'),
		lastReminderEventApprovers: DS.hasMany('App.AllEmployee'),
		laborFieldsEnabled: attr('boolean'),

		formattedLockedAt: function () {
			return this._formatDateTime(this.get('lockedAt'));
		}.property('lockedAt'),
		formattedPushedAt: function () {
			return this._formatDateTime(this.get('pushedAt'));
		}.property('pushedAt'),
		payPeriodNumDays: function () {
			var startDate = new Date(this.get('startDate'));
			var endDate = new Date(this.get('endDate'));

			if (!startDate || !endDate) {
				return 0;
			}

			return Math.ceil((endDate - startDate + 1) / (1000 * 3600 * 24));
		}.property('startDate', 'endDate'),

		payTypeFull: function () {
			var payType = this.get('payType');
			if (payType == 'BW') {
				return 'Bi-Weekly';
			}
			if (payType == 'SM') {
				return 'Semi-Monthly';
			}
			if (payType == 'Mo') {
				return 'Monthly';
			}
			if (payType == 'We') {
				return 'Weekly';
			}
		}.property('payType'),

		url: function () {
			var id = this.get('id');
			return '/tareports/' + id + '/run';
		}.property('id'),

		getStartOfFirstWeekDate: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY').format('YYYY-MM-DD');
		}.property('startDate'),
		startDateMoment: function () {
			return moment(this.get('startDate'), 'MM/DD/YYYY');
		}.property('startDate'),
		startDateSortValue: function () {
			var m = this.get('startDateMoment');
			if (m) {
				return m.unix();
			}
			else {
				return 0;
			}
		}.property('startDate'),
		endDateMoment: function () {
			return moment(this.get('endDate'), 'MM/DD/YYYY');
		}.property('endDate'),
		estPushDateMoment: function () {
			return moment(this.get('estPushDate'), 'YYYY-MM-DD HH:mm:ssZ');
		}.property('estPushDate'),
		pushedAtMoment: function () {
			return moment(this.get('pushedAt'), 'YYYY-MM-DD HH:mm:ssZ');
		}.property('pushedAt'),
		submitEndMoment: function () {
			return moment(this.get('submitEnd'), 'YYYY-MM-DD HH:mm:ssZ');
		}.property('submitEnd'),
		_formatDateTime: function (dt) {
			return moment.tz(dt, this.get('_tzinfo')).format('MMM DD h:mma z');
		},

		_formatSubmitEnd: function (dt) {
			var date = moment(dt, 'YYYY-MM-DD HH:mm').format('dddd, MMMM DD');
			var time = moment(dt, 'YYYY-MM-DD HH:mm').format('h:mma');
			return date + ' at ' + time;
		},

		formatEstimatedDueDate: function () {
			return this._formatDateTime(this.get('estPushDate'));
		}.property('estPushDate'),

		formatSubmitEnd: function () {
			return this._formatSubmitEnd(this.get('submitEnd'));
		}.property('submitEnd'),

		formatStart: Ember.computed.prettyDate('startDate', 'MMMM DD'),
		formatEnd: Ember.computed.prettyDate('endDate', 'MMMM DD'),
		formatStartFull: Ember.computed.prettyDate('startDate', 'MMM Do, YYYY'),
		formatEndFull: Ember.computed.prettyDate('endDate', 'MMM Do, YYYY'),
		formatStartFullClean: Ember.computed.prettyDate('startDate', 'MMM DD, YYYY'),
		formatEndFullClean: Ember.computed.prettyDate('endDate', 'MMM DD, YYYY'),
		formatStartShort: Ember.computed.prettyDate('startDate', 'MMM DD'),
		formatEndShort: Ember.computed.prettyDate('endDate', 'MMM DD'),
		totalRegularHours: function () {
			return this.get('taPayPeriodEmployees').reduce(function (sum, entry) {
				return sum + Number(entry.get('totalRegularHours'));
			}, 0);
		}.property('taPayPeriodEmployees.@each.totalRegularHours'),
		totalPtoHours: function () {
			return this.get('taPayPeriodEmployees').reduce(function (sum, entry) {
				return sum + Number(entry.get('totalPtoHours'));
			}, 0);
		}.property('taPayPeriodEmployees.@each.totalPtoHours'),

		latestRun: function () {
			var runs = this.get('runs');
			if (!runs) { return null; }
			return runs.get('lastObject');
		}.property('runs.[]'),
	});

	App.TaPayPeriodRun = DS.Model.extend({
		payPeriod: DS.belongsTo('App.TaPayPeriod'),
		state: attr('string'),
		regularHours: attr('number'),
		overtimeHours: attr('number'),
		doubleOvertimeHours: attr('number'),
		downloadUrl: attr('string'),
		ptoHours: attr('number'),
		holidayHours: attr('number'),
		createdAt: attr('string'),
		endedAt: attr('string'),
		company: DS.belongsTo('App.Company'),
		totalEmployees: attr('number'),
		totalHours: attr('number'),
		reportPath: attr('string'),
		errorDetails: attr('string'),
		formattedErrorDetails: attr('string'),
		syncDuration: attr('string'),

		formattedCreatedAt: function () {
			var dateTime = this.get('createdAt');
			var date = moment(dateTime, 'YYYY-MM-DD HH:mm').format('dddd, MMMM DD');
			var payPeriod = this.get('payPeriod');
			if (!payPeriod) { return null; }
			var time = moment.tz(dateTime, payPeriod.get('_tzinfo')).format('h:mma z');
			return date + ' at ' + time;
		}.property('createdAt'),
	});

	App.TaProjectCode = DS.Model.extend({
		taCompany: DS.belongsTo('App.TaCompany'),
		taEmployee: DS.belongsTo('App.TaEmployee'),
		code: attr('string'),
		description: attr('string'),
		state: attr('string'),
		enabled: function (key, value, previousValue) {
			if (arguments.length > 1) {
				this.set('state', value ? 'active' : 'inactive');
			}
			// getter
			return this.get('state') == 'active';
		}.property('state')
	});

	App.TaEmployeeTimeDuration = DS.Model.extend({
		timeApproved: DS.belongsTo('App.TaTimeApprovedDay'),
		employee: DS.belongsTo('App.AllEmployee'),
		notes: DS.hasMany('App.TaEmployeeTimeDurationNote'),
		hasMissingNoteFlag: attr('number'),
		hours: attr('string'),
		startTime: attr('string'),
		startDateTime: attr('string'),
		endTime: attr('string'),
		endDateTime: attr('string'),
		photoUrlIn: attr('string'),
		photoUrlOut: attr('string'),
		latitudeIn: attr('string'),
		longitudeIn: attr('string'),
		accuracyIn: attr('string'),
		geolocationSkippedIn: attr('boolean'),
		reportSourceIn: attr('string'),
		latitudeOut: attr('string'),
		longitudeOut: attr('string'),
		accuracyOut: attr('string'),
		geolocationSkippedOut: attr('boolean'),
		reportSourceOut: attr('string'),
		activity: attr('string'),
		modifiedBy: DS.belongsTo('App.AllEmployee'),
		lastModified: attr('string'),
		matchingDuration: DS.belongsTo('App.TaEmployeeTimeDuration'),
		state: attr('string'), //'effective', 'overridden', 'deleted', 'correction', 'deleted_correction'
		validStatus: attr('string'), // 'valid','exceedsmax', 'overlapping'
		isNextDay: attr('boolean'),
		notice: attr('string'),
		missedMealBreaksCount: attr('number'),
		laborFieldsString: attr('string'),
		laborFields: function () {
			var laborFieldsString = this.get('laborFieldsString');
			return laborFieldsString ? JSON.parse(laborFieldsString) : {};
		}.property('laborFieldsString'),
		flagMessages: function () {
			const messages = [];
			if (this.get('hasMissingNoteFlag')) {
				messages.push('Missing Note');
			}
			return messages;
		}.property('hasMissingNoteFlag'),
		isApproverOverride: function () {
			return this.get('state') == 'effective' && !!this.get('matchingDuration');
		}.property('state', 'matchingDuration'),
		isApproverDeleted: function () {
			return this.get('state') == 'deleted';
		}.property('state'),
		isApproverCorrectionDeleted: function () {
			return this.get('state') == 'deleted_correction';
		}.property('state'),
		isApproverCorrection: function () {
			return this.get('state') == 'correction';
		}.property('state'),
		isApproverOverridden: function () {
			return this.get('state') == 'overridden';
		}.property('state'),
		isApproverAdded: function () {
			return !this.get('matchingDuration') && this.get('state') == 'effective' && this.get('modifiedBy.id') != this.get('employee.id');
		}.property('state', 'modifiedBy.id', 'employee.id', 'matchingDuration'),
		startTimeMoment: function () {
			if (!this.get('startDateTime')) { return null; }
			return moment(this.get('startDateTime'));
		}.property('startDateTime'),
		endTimeMoment: function () {
			if (!this.get('endDateTime')) { return null; }
			return moment(this.get('endDateTime'));
		}.property('endDateTime'),
		lastModifiedMoment: function () {
			if (!this.get('lastModified')) { return null; }
			return moment(this.get('lastModified')).format('MM/DD/YYYY hh:mm A')
		}.property('lastModified'),
		lastModifiedText: function () {
			if (this.get('lastModifiedMoment')) {
				return 'on ' + this.get('lastModifiedMoment');
			} else {
				return '';
			}
		}.property('lastModifiedMoment'),
		startTimeSortValue: function () {
			var m = this.get('startTimeMoment');
			return m ? m.valueOf() : 0;
		}.property('startDateTime'),
		endTimeSortValue: function () {
			var m = this.get('endTimeMoment');
			return m ? m.valueOf() : 0;
		}.property('endDateTime'),
	});

	App.TaEmployeeTimeDurationNote = DS.Model.extend({
		taEmployeeTimeDuration: DS.belongsTo('App.TaEmployeeTimeDuration'),
		author: DS.belongsTo('App.AllEmployee'),
		content: attr('string'),
		isRestricted: attr('boolean'),
	});

	App.EmployeeAca = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		initialPeriod: DS.belongsTo('App.EmployeeAcaInitialPeriod'),
		standardPeriods: DS.hasMany('App.EmployeeAcaStandardPeriod'),
	});

	var _EmployeeAcaPeriodMixin = Ember.Mixin.create({
		employeeAca: DS.belongsTo('App.EmployeeAca'),
		hours: attr('number'),
		averageHours: attr('number'),
		isReportingComplete: attr('boolean'),
		firstReportedDate: attr('date'),
		lastReportedDate: attr('date'),
		isOverThreshold: Ember.computed.gte('averageHours', 30),
	});

	App.EmployeeAcaStandardPeriod = DS.Model.extend(_EmployeeAcaPeriodMixin, {
		companyPeriod: DS.belongsTo('App.CompanyAcaPeriod'),
		stabilityStartDate: attr('date'),
		endDate: attr('date'),
	});

	App.EmployeeAcaInitialPeriod = DS.Model.extend(_EmployeeAcaPeriodMixin, {
		measurementStartDate: attr('date'),
		adminStartDate: attr('date'),
		stabilityStartDate: attr('date'),
		endDate: attr('date'),
	});

	App.CompanyAcaPeriod = DS.Model.extend({
		measurementStartDate: attr('date'),
		adminStartDate: attr('date'),
		stabilityStartDate: attr('date'),
		endDate: attr('date'),
		employeePeriods: DS.hasMany('App.EmployeeAcaStandardPeriod'),
		status: attr('string'),
		employeesOverThresholdCount: Ember.computed.count('employeePeriods', 'isOverThreshold'),
		measurementEndDate: function () {
			return moment(this.get('adminStartDate')).add('days', -1);
		}.property('adminStartDate'),
	});

	App.CompanySettingsAca = DS.Model.extend({
		status: attr('string'),
		standardMeasurementPeriodMonths: attr('number'),
		startStandardPeriodsDate: attr('date'),
		planYearChangedAfterCutoff: attr('boolean'),
		periods: DS.hasMany('App.CompanyAcaPeriod'),
		company: DS.belongsTo('App.Company'),
	});

	// Stores all the short circuit plans for the company. This model is only applicable when the
	// corresponding line of coverage is in switched state.
	App.ShortCircuitPlansApi = DS.Model.extend({
		borMedicalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borAdditionalMedicalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borDentalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borVisionShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borLifenewShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borStdShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		borLtdShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),

		oeMedicalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeAdditionalMedicalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeDentalShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeVisionShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeLifenewShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeStdShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
		oeLtdShortCircuitPlans: DS.hasMany('App.ShortCircuitPlan'),
	}).reopenClass({
		noBatch: true
	});

	App.ShortCircuitPlan = DS.Model.extend({
		// id is chp.
		name: attr('string'),
		displayName: attr('string'),
		carrier: attr('string'),
		HMOPPO: attr('string'),
		HSA: attr('boolean', { defaultValue: false }),
		lineOfCoverage: attr('string'),
		enrollmentType: attr('string'),
		planType: attr('string'), // L&D field
		isShortCircuitPlan: attr('boolean', { defaultValue: true }),
		planId: attr('number'),

		cheLineOfCoverage: function () {
			if (this.get('lineOfCoverage') == 'additionalMedical') {
				return 'medical';
			}
			return this.get('lineOfCoverage');
		}.property('lineOfCoverage'),

		HMOPPOHSA: function () {
			if (this.get('HSA')) {
				return 'HSA';
			}
			return this.get('HMOPPO');
		}.property('HMOPPO', 'HSA'),

		nonShortCircuitPlan: function () {
			return !this.get('isShortCircuitPlan');
		}.property('isShortCircuitPlan'),
	});

	App.ImplementationFees = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		bankAccountNumber: attr('string'),
		bankRoutingNumber: attr('string'),
		authorizingPersonName: attr('string'),
		authorizingPersonSignature: attr('string'),
		amount: attr('number'),
		isActive: attr('boolean'),
		reasonCode: attr('string'),
		numEmployees: attr('number'),
	});

	App.BorStats = DS.Model.extend({
		hasMedicalPlans: attr('boolean'),
		hasDentalPlans: attr('boolean'),
		hasVisionPlans: attr('boolean'),
		medicalBoRCompleteDate: attr('string'),
		dentalBoRCompleteDate: attr('string'),
		visionBoRCompleteDate: attr('string'),
		hasAtleastOneMedicalSwitched: attr('boolean'),
		hasDentalSwitched: attr('boolean'),
		hasVisionSwitched: attr('boolean'),
		hasLifeSwitched: attr('boolean'),
		hasStdSwitched: attr('boolean'),
		hasLtdSwitched: attr('boolean'),
		hasBrokenMedicalCredentials: attr('boolean'),
		hasBrokenDentalCredentials: attr('boolean'),
		hasBrokenVisionCredentials: attr('boolean'),
		hasAnyBorPlans: Ember.computed.or('hasMedicalPlans', 'hasDentalPlans', 'hasVisionPlans'),
		medicalBoRCompleteDateMoment: Ember.computed.asMoment('medicalBoRCompleteDate'),
		dentalBoRCompleteDateMoment: Ember.computed.asMoment('dentalBoRCompleteDate'),
		visionBoRCompleteDateMoment: Ember.computed.asMoment('visionBoRCompleteDate'),
		earliestBoRCompleteDateMoment: function () {
			var _this = this;
			var properties = ['medicalBoRCompleteDateMoment', 'dentalBoRCompleteDateMoment', 'visionBoRCompleteDateMoment'];
			var vals = properties.map(function (property) {
				return _this.get(property);
			}).filter(function (val) {
				return val;
			});
			return moment.min(vals);
		}.property('medicalBoRCompleteDateMoment', 'dentalBoRCompleteDateMoment', 'visionBoRCompleteDateMoment'),
	});

	App.EmployeeSyncDifference = DS.Model.extend({
		key: attr('string'),
		inZenefits: attr('string'),
		inPayroll: attr('string'),
		ignored: attr('boolean'),
		created: attr('string'),
		keyPretty: attr('string'),
		employeeName: attr('string'),
		employeeEmail: attr('string'),
		companyName: attr('string'),
		companyLink: attr('string'),
		employeeEditLink: attr('string'),
		keyHistory: attr('string'),
		employeeId: Ember.attr('string'),
		first_name: Ember.attr('string'),
		last_name: Ember.attr('string'),
		fullName: Ember.attr('string'),
		photoUrl: Ember.attr('string'),

		diffed: function () {
			return diffString(this.get('inPayroll'), this.get('inZenefits'));
		}.property('inZenefits', 'inPayroll'),

		isKeySalaryOrPayrate: function () {
			return this.get('key') === 'salary' || this.get('key') === 'payRate';
		}.property('key'),
	});

	App.ShortCircuitOpenEnrollmentStats = DS.Model.extend({
		medicalShortCircuitOEEligible: attr('boolean'),
		additionalMedicalShortCircuitOEEligible: attr('boolean'),
		dentalShortCircuitOEEligible: attr('boolean'),
		visionShortCircuitOEEligible: attr('boolean'),

		medicalRenewalDate: attr('string'),
		additionalMedicalRenewalDate: attr('string'),
		dentalRenewalDate: attr('string'),
		visionRenewalDate: attr('string'),

		medicalShortCircuitOECreated: attr('boolean'),
		additionalMedicalShortCircuitOECreated: attr('boolean'),
		dentalShortCircuitOECreated: attr('boolean'),
		visionShortCircuitOECreated: attr('boolean'),

		medicalSCOEStepsComplete: attr('boolean'),
		additionalMedicalSCOEStepsComplete: attr('boolean'),
		dentalSCOEStepsComplete: attr('boolean'),
		visionSCOEStepsComplete: attr('boolean'),

		hasNonBoROECHEs: attr('boolean'),

		leastMedicalRenewalDate: function () {
			if (this.get('medicalRenewalDate') && this.get('additionalMedicalRenewalDate')) {
				var medicalRenewalDate = moment(this.get('medicalRenewalDate'));
				var addlMedicalRenewalDate = moment(this.get('additionalMedicalRenewalDate'));
				return medicalRenewalDate.diff(addlMedicalRenewalDate, 'days') <= 0 ? this.get('medicalRenewalDate') : this.get('additionalMedicalRenewalDate');
			}
			return this.get('medicalRenewalDate') || this.get('additionalMedicalRenewalDate');
		}.property('medicalRenewalDate', 'additionalMedicalRenewalDate'),

		leastSCOERenewalDate: function () {
			var datesToConsider = [];
			var oeEligibleProperties = ['medicalShortCircuitOEEligible', 'additionalMedicalShortCircuitOEEligible', 'dentalShortCircuitOEEligible', 'visionShortCircuitOEEligible'];
			var renewalDatesProperties = ['medicalRenewalDate', 'additionalMedicalRenewalDate', 'dentalRenewalDate', 'visionRenewalDate'];

			for (var i = 0; i < renewalDatesProperties.length; i++) {
				if (this.get(oeEligibleProperties[i]) && this.get(renewalDatesProperties[i])) {
					datesToConsider.push(this.get(renewalDatesProperties[i]));
				}
			}
			if (datesToConsider.length == 0) {
				return null;
			}
			var minMomentDate = moment(datesToConsider[0]);
			var minDate = datesToConsider[0];
			datesToConsider.forEach(function (date) {
				var momentDate = moment(date);
				if (momentDate.isBefore(minMomentDate)) {
					minMomentDate = momentDate;
					minDate = date;
				}
			}.bind(this));
			return minDate;
		}.property('medicalShortCircuitOEEligible', 'additionalMedicalShortCircuitOEEligible', 'dentalShortCircuitOEEligible', 'visionShortCircuitOEEligible', 'medicalRenewalDate', 'additionalMedicalRenewalDate', 'dentalRenewalDate', 'visionRenewalDate'),

		atleastOneMedicalShortCircuitOEEligible: function () {
			return this.get('medicalShortCircuitOEEligible') ||
				this.get('additionalMedicalShortCircuitOEEligible');
		}.property('medicalShortCircuitOEEligible',
			'additionalMedicalShortCircuitOEEligible'),

		// used for ZApps, grep and update all similar properties if you change this
		atleastOneMedicalShortCircuitOECreated: function () {
			return this.get('medicalShortCircuitOECreated') || this.get('additionalMedicalShortCircuitOECreated');
		}.property('medicalShortCircuitOECreated', 'additionalMedicalShortCircuitOECreated'),

		// used for ZApps, grep and update all similar properties if you change this
		atleastOneMedicalSCOEStepsComplete: function () {
			return this.get('medicalSCOEStepsComplete') ||
				this.get('additionalMedicalSCOEStepsComplete');
		}.property('medicalSCOEStepsComplete', 'additionalMedicalSCOEStepsComplete'),

		// Tells if atleast one of the line of coverage has short circuit OE.
		shortCircuitOEEligible: function () {
			return this.get('medicalShortCircuitOEEligible') ||
				this.get('additionalMedicalShortCircuitOEEligible') ||
				this.get('dentalShortCircuitOEEligible') ||
				this.get('visionShortCircuitOEEligible');
		}.property('medicalShortCircuitOEEligible',
			'additionalMedicalShortCircuitOEEligible',
			'dentalShortCircuitOEEligible',
			'visionShortCircuitOEEligible'),

		// Tells if short circuit OE is created for atleast one of the line of coverage
		shortCircuitOECreated: function () {
			return this.get('medicalSCOEStepsComplete') ||
				this.get('additionalMedicalSCOEStepsComplete') ||
				this.get('dentalSCOEStepsComplete') ||
				this.get('visionSCOEStepsComplete');
		}.property('medicalSCOEStepsComplete',
			'additionalMedicalSCOEStepsComplete',
			'dentalSCOEStepsComplete',
			'visionSCOEStepsComplete'),
	});

	App.ParticipationRule = DS.Model.extend({
		lineOfCoverage: attr('string'),
		planGroup: attr('string'),
		minEmployees: attr('number'),
		maxEmployees: attr('number'),
		percentage: attr('string'),
		andOrOr: attr('string'),
		isStrict: attr('boolean'),
		minEmployeesCount: attr('number'),
		softDeleted: attr('boolean'),
		contributoryType: attr('string'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.ParticipationRule';
		}).property('id'),

		allowsValidWaivers: Ember.computed(function () {
			return this.get('isStrict') ? 'No' : 'Yes';
		}).property('isStrict'),

		description: Ember.computed(function () {
			var minNumber = this.get('minEmployeesCount');
			var minPercent = this.get('percentage');
			var waiversAccepted = !this.get('isStrict');
			var andOrOr = this.get('andOrOr');

			var ret = '';

			if (minNumber && andOrOr && minPercent) {
				ret += ('%@%, %@ %@ '.fmt(minPercent, andOrOr, minNumber) + (minNumber == 1 ? 'employee' : 'employees'));
			} else if (minNumber) {
				ret += ('%@ '.fmt(minNumber) + (minNumber == 1 ? 'employee' : 'employees'));
			} else if (minPercent) {
				ret += '%@%'.fmt(minPercent);
			} else {
				ret += 'Unknown';
			}

			if (waiversAccepted) {
				ret += ', with valid waivers accepted';
			} else {
				ret += ', valid waivers NOT accepted';
			}
			return ret;
		}).property('minEmployeesCount', 'percentage', 'isStrict'),

	});

	App.PackageSize = DS.Model.extend({
		lineOfCoverage: attr('string'),
		minEmployees: attr('number'),
		maxEmployees: attr('number'),
		numberOfPlans: attr('number'),
		softDeleted: attr('boolean'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.PackageSize';
		}).property('id'),
	});

	App.NetworkCompatibilityRule = DS.Model.extend({
		lineOfCoverage: attr('string'),
		planType: attr('string'),
		numberOfNetworks: attr('number'),
		softDeleted: attr('boolean'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.NetworkCompatibilityRule';
		}).property('id'),
	});

	App.InStateRule = DS.Model.extend({
		lineOfCoverage: attr('string'),
		minEmployees: attr('number'),
		minStyle: attr('string'),
		softDeleted: attr('boolean'),
		minGroupSize: attr('number'),
		maxGroupSize: attr('number'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.InStateRule';
		}).property('id'),

		minStylePretty: Ember.computed(function () {
			return {
				'total': 'Eligible',
				'enrolling': 'Enrolling',
			}[this.get('minStyle')];
		}).property('minStyle'),

		description: Ember.computed(function () {
			var minEmployees = this.get('minEmployees');
			var ret = '';

			ret += '%@% of '.fmt(minEmployees);

			if (this.get('minStyle') == 'total') {
				ret += 'all ';
			} else {
				ret += 'enrolling ';
			}

			ret += 'employees living in-state';

			return ret;
		}).property(),
	});

	App.InStateRuleV2 = DS.Model.extend({
		ruleName: attr('string'),
		minReqPercentByEligible: attr('number'),
		minReqPercentByEnrolled: attr('number'),
		consideredInStateList: attr('string'),
		pluralityRule: attr('string'),
		hqInState: attr('string'),
	});

	App.ContributionRule = DS.Model.extend({
		lineOfCoverage: attr('string'),
		minEmployees: attr('number'),
		maxEmployees: attr('number'),
		minPercentage: attr('string'),
		minAmount: attr('number'),
		softDeleted: attr('boolean'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.ContributionRule';
		}).property('id'),

		decimalPercent: Ember.computed(function () {
			return Number(this.get('minPercentage'));
		}),
	});

	App.RoundingRule = DS.Model.extend({
		lineOfCoverage: attr('string'),
		roundingLocation: attr('string'),
		roundingType: attr('string'),
		softDeleted: attr('boolean'),
		additionalNotes: attr('string'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.RoundingRules';
		}).property('id'),
	});

	App.ParticipationRuleAgreement = DS.Model.extend({
		enrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		participationRule: DS.belongsTo('App.ParticipationRule'),
		participationRuleV2: attr('string'),
	});

	App.InStateRuleAgreement = DS.Model.extend({
		enrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		inStateRule: DS.belongsTo('App.InStateRule'),
		inStateRuleV2: attr('string'),
	});

	// Should refactor all of the computed helpers into a single file...
	Ember.computed.number = function (key) {
		return Ember.computed(function () {
			var val = this.get(key);

			return Number(val);
		}).property(key);
	};

	App.CarrierMetadata = DS.Model.extend({
		leadTime: attr('number'),
		middleOfMonthEnrollment: attr('boolean'),
		supportsOwnerOnlyCompanies: attr('boolean'),
		isAddresChangeEffectiveAtRenewal: attr('boolean'),
		isAgeChangeEffectiveAtRenewal: attr('boolean'),
		quotedByEmployersZip: attr('nullable-boolean'),
		medicalSicCodeDisallowList: attr('string'),
		dentalSicCodeDisallowList: attr('string'),
		visionSicCodeDisallowList: attr('string'),
		medicalDefaultRegion: attr('string'),
		dentalDefaultRegion: attr('string'),
		visionDefaultRegion: attr('string'),
		spousePartnerAllowed: attr('boolean'),
		dependentMaxAge: attr('number'),
		minHoursWorked: attr('number'),
		studentDependentAllowed: attr('boolean'),
		requiredParticipationFlexible: attr('boolean'),
		isPPOHMOPlanRequired: attr('nullable-boolean'),
		allowOtherCarriers: attr('nullable-boolean'),
		allowsOnlyAdjacentMetalTiers: attr('nullable-boolean'),
		ownershipProofResponsibility: attr('string'),
		earningsRedetermination: attr('string'),
		ageRedetermination: attr('string'),
		additionalNotes: attr('string'),
	});

	App.AbstractCarrierMetadata = DS.Model.extend({
		leadTime: attr('number'),
		quotedByEmployersZip: attr('nullable-boolean'),
		isPPOHMOPlanRequired: attr('nullable-boolean'),
		allowOtherCarriers: attr('nullable-boolean'),
		allowsOnlyAdjacentMetalTiers: attr('nullable-boolean'),

		participationRules: DS.hasMany('App.ParticipationRule'),
		inStateRules: DS.hasMany('App.InStateRule'),
		packageSizes: DS.hasMany('App.PackageSize'),
		networkCompatibilityRules: DS.hasMany('App.NetworkCompatibilityRule'),
		waivers: DS.hasMany('App.CarrierWaiver'),
		roundingRules: DS.hasMany('App.RoundingRule'),

		additionalNotes: attr('string'),
		addSpouseEffectiveDate: attr('string'),
		addChildEffectiveDate: attr('string'),
		addDependentLossOfCoverageEffectiveDate: attr('string'),
		subscriberLossOfCoverageEffectiveDate: attr('string'),
		defaultWaitingPeriod: attr('string'),
		contributionRules: DS.hasMany('App.ContributionRule'),
		salaryChangeRenewal: attr('string'),
		underwritingDocUrl: attr('string'),
		newhireBillingStrategy: attr('string'),
		marriageBillingStrategy: attr('string'),
		memberTerminationBillingStrategy: attr('string'),
		cobraBillingStrategy: attr('string'),
		newbornBillingStrategy: attr('string'),
		firstOfMonthSameEffectiveDate: attr('boolean'),
		requiresContract: attr('string'),
		maxSmallGroupThreshold: attr('number'),
		maxMidGroupThreshold: attr('number'),
		transferLargeGroupThreshold: attr('number'),
		largeGroupEligibilityCheck: attr('string'),
		cancelCoverageEffectiveDate: attr('string'),
		oeLeadTime: attr('number'),
		isPediatricDentalCostEmbedded: attr('boolean'),
		allowPediatricDentalOptOut: attr('boolean'),
		allowRetroactiveTermination: attr('boolean'),
		retroactiveTerminationDeadline: attr('string'),
		autoPlanDropWhenNoEnrolees: attr('boolean'),
		newHirePreEffectiveDateSubmissionDays: attr('number'),
		oePlanInstallationTime: attr('number'),

		minPTDaysToWaiveWaitingPeriod: attr('number'),
		dependentAgeOutPolicy: attr('string'),
	});

	// NOTE: copied over from modelsnew.js
	App.CarrierYearMetadata = App.AbstractCarrierMetadata.extend({
		carrier: DS.belongsTo('App.Carrier'),
		year: attr('number'),
		consideredInStateList: attr('string')
	});

	App.AbstractCarrierMetadataException = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionFieldName: attr('string'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
		softDeleted: attr('boolean'),
	});

	App.CarrierMetadataCharacterException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: attr('string'),
	});

	App.CarrierMetadataIntegerException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: attr('number'),
	});

	App.CarrierMetadataBooleanException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: attr('boolean'),
	});

	App.CarrierMetadataTextException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: attr('string'),
	});

	App.CarrierMetadataParticipationRuleException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.ParticipationRule'),
	});

	App.CarrierMetadataInStateRuleException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.InStateRule'),
	});

	App.CarrierMetadataPackageSizeException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.PackageSize'),
	});

	App.CarrierMetadataNetworkCompatibilityRuleException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.NetworkCompatibilityRule'),
	});

	App.CarrierMetadataWaiverException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.CarrierWaiver'),
	});

	App.CarrierMetadataRoundingRuleException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.RoundingRule'),
	});

	App.CarrierMetadataContributionRuleException = App.AbstractCarrierMetadataException.extend({
		carrier: DS.belongsTo('App.Carrier'),
		exceptionValue: DS.belongsTo('App.ContributionRule'),
	});

	App.ApproverCompanySettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		isEnabled: attr('boolean'),
		enableCompAccessToManagers: attr('string'),
		enableEquityAccessToManagers: attr('string'),
		enableI9toManagers: attr('boolean'),
		suppressManagerInviteEmail: attr('boolean'),
	});


	App.ApproverDetail = DS.Model.extend({
		tier: attr('number'),
		company: DS.belongsTo('App.Company'),
		approver: DS.belongsTo('App.AllEmployee'),
		canApproveHires: attr('boolean'),
		canApproveSalary: attr('boolean'),
		canApproveTransfers: attr('boolean'),
		canApproveStockOptionAddition: attr('boolean'),
		canApproveTermination: attr('boolean'),
		approvedBy: DS.belongsTo('App.User'),
		employeeMappings: DS.hasMany('App.ApproverEmployeeMapping'),
		locationMappings: DS.hasMany('App.ApproverLocationMapping'),
		departmentMappings: DS.hasMany('App.ApproverDepartmentMapping'),
		tierGroups: DS.hasMany('App.TierGroup'),
		arrangedTierGroups: function () {
			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['tier'],
				content: this.get('tierGroups'),
			});
		}.property('tierGroups.@each.tier', 'tierGroups.length'),
	});

	App.TierGroup = DS.Model.extend({
		tier: attr('number'),
		approverTiers: DS.hasMany('App.ApproverTier'),
		approverDetail: DS.belongsTo('App.ApproverDetail'),
		selectedEmployees: Ember.computed.mapByProperty('approverTiers', 'approver'),
		addedEmployees: Ember.A(),
		removedEmployees: Ember.A(),
		hasFinalEmployees: function () {
			var finalObjects = [];
			return finalObjects.addObjects(this.get('selectedEmployees'))
				.addObjects(this.get('addedEmployees'))
				.removeObjects(this.get('removedEmployees')).length > 0;
		}.property('selectedEmployees', 'addedEmployees', 'removedEmployees'),
	});

	App.ApproverTier = DS.Model.extend({
		approver: DS.belongsTo('App.AllEmployee'),
		tierGroup: DS.belongsTo('App.TierGroup'),
	});

	App.StockVestingSchedule = DS.Model.extend({
		grant: DS.belongsTo('App.EmployeeOptionGrant'),
		vestingDate: attr('string'),
		stockVested: attr('number'),
		vestedTotal: attr('number'),
	});

	App.ApproverEmployeeMapping = DS.Model.extend({
		approverDetail: DS.belongsTo('App.ApproverDetail'),
		employee: DS.belongsTo('App.AllEmployee')
	});


	App.ApproverLocationMapping = DS.Model.extend({
		approverDetail: DS.belongsTo('App.ApproverDetail'),
		location: DS.belongsTo('App.CompanyLocation')
	});


	App.ApproverDepartmentMapping = DS.Model.extend({
		approverDetail: DS.belongsTo('App.ApproverDetail'),
		department: DS.belongsTo('App.Department')
	});


	App.SalaryChange = DS.Model.extend({
		compType: attr('string'),
		compCurrency: attr('string'),
		salary: attr('string'),
		payRate: attr('string'),
		compensation: attr('raw'),  // Compensation array
		hasSalary: Ember.computed.notNone('salary'),
		hasPayRate: Ember.computed.notNone('payRate'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		effectiveDateWithCurrentPP: attr('string'),
		doesEffectiveDateAling: attr('boolean'),
		canPushInEffectivePP: attr('string'),
		effectivePPStartDate: attr('string'),
		effectivePPEndDate: attr('string'),
		nextEffectivePPStartDate: attr('string'),
		nextEffectivePPEndDate: attr('string'),
		isEffectiveDateInPastPP: Ember.computed.equal('effectiveDateWithCurrentPP', 'past'),
		isEffectiveDateInCurrentPP: Ember.computed.equal('effectiveDateWithCurrentPP', 'current'),
		isEffectiveDateInFuturePP: Ember.computed.equal('effectiveDateWithCurrentPP', 'future'),
		canAffirmativelyPushInEffectivePP: Ember.computed.equal('canPushInEffectivePP', 'yes'),
		isFlsaExempt: attr('raw'),
		isFlsaExemptSystemComputed: attr('boolean'),
		hasNonExemptJobDuties: attr('raw'),
		zeroSalaryReason: attr('string'),
		zeroSalaryReasonForOtherType: attr('string'),
		hasZeroSalaryReason: Ember.computed.notEmpty('zeroSalaryReason'),
		zeroSalaryReasonFormatted: Ember.computed('zeroSalaryReason', 'zeroSalaryReasonForOtherType', function () {
			const formatted = {
				'owner': 'Owner',
				'partner': 'Partner',
				'officer': 'Officer',
				'other': 'Other',
			};
			const zeroSalaryReason = this.get('zeroSalaryReason');
			const reasonForOtherType = this.get('zeroSalaryReasonForOtherType');
			if (zeroSalaryReason === 'other' && reasonForOtherType) {
				return Ember.String.fmt("%@ - %@", [formatted[zeroSalaryReason], reasonForOtherType]);
			}
			return zeroSalaryReason ? formatted[zeroSalaryReason] : '';
		}),
		salaryByPayFrequency: function () {
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			var payFreq = this.get('changeRequest.employee.payFrequency');
			if (payFreq == 'SM') {
				salary *= 0.5;
			} else if (payFreq == 'BW') {
				salary *= (1 / 26) * 12;
			} else if (payFreq == 'We') {
				salary *= (1 / 52) * 12;
			} else if (payFreq == 'Mo') {
				salary *= 1;
			}
			return Number(Number(String(salary).replace(/,/g, '')).toFixed(2)).toLocaleString();
		}.property('salary', 'payFrequency'),
		salaryAnnual: function (key, value) {
			if (value) {
				this.set('salary', (Number(value.replace(/,/g, '')) / 12).toFixed(4));
			}
			var salary = this.get('salary');
			if (salary == null || salary == "") {
				return "";
			}
			return Number(Number(String(salary).replace(/,/g, '') * 12).toFixed(2)).toLocaleString();
		}.property('salary'),
		compTypeHuman: function () {
			var compType = this.get('compType');
			if (compType == 'H') { return 'Hourly'; }
			if (compType == 'S') { return 'Salaried'; }
			if (compType == 'A') { return 'Fixed Amount'; }
			if (compType == 'N') { return 'Not Applicable'; }
			return compType;
		}.property('compType'),
		salaryChangeDesc: function () {
			if (this.get('compType') == 'H') {
				return this.get('payRate') + ' per Hour';
			} else {
				return this.get('salaryAnnual') + ' per Annum';
			}
		}.property('compType', 'payRate', 'salaryAnnual'),
		isSalaryChange: true,
		isSeasonal: attr('boolean'),
	});


	App.DepartmentChange = DS.Model.extend({
		department: DS.belongsTo('App.Department'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		moveSubDepartment: attr('boolean'),
		payrollDepartmentCode: attr('string'),
		isDepartmentChange: true,
		departmentNameHelper: Ember.computed('department', function () {
			return this.get('department') ? this.get('department.name') : 'No Department';
		}),
	});

	App.LocationChange = DS.Model.extend({
		location: DS.belongsTo('App.CompanyLocation'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		isRemote: attr('boolean'),
		isLocationChange: true,
	});


	App.TitleChange = DS.Model.extend({
		title: attr('string'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		isTitleChange: true,
	});

	App.PayScheduleChange = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		paySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		forZPOnboarding: attr('boolean'),
		isPayScheduleChange: true,
	});

	App.TaxExemptionChange = DS.Model.extend({
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		requestedEmployeeExemption: DS.belongsTo('App.RequestedEmployeeExemption'),
	});

	App.ManagerChange = DS.Model.extend({
		manager: DS.belongsTo('App.AllEmployee'),
		reportsNewManager: DS.belongsTo('App.AllEmployee'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		moveWFSupervisor: attr('boolean'),
		wfSupervisorPayrollId: attr('string'),
		isManagerChange: true,
	});

	App.HireDateChange = DS.Model.extend({
		hireDate: attr('string'),
		maintainMedicalEffectiveDate: attr('boolean'),
		oldHireDate: attr('string'),
		isEmployeeAlreadyInPayroll: attr('boolean'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		changeType: attr('string'),
		isHireDateChange: true,
	});

	App.EmploymentTypeChange = DS.Model.extend({
		employmentType: attr('string'),
		administerCOBRA: attr('boolean'),
		coverFullCobra: attr('boolean'),
		cobraMonths: attr('string'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		movingOutOfFullTime: Ember.computed.unequal('employmentType', 'FT'),
		employmentTypeHelper: attr('string'),
		companyPtoId: attr('string'),
		changePtoPolicy: attr('boolean'),
		isEmploymentTypeChange: Ember.computed.notEmpty('employmentType'),
		isMovingToFullTime: Ember.computed.equal('employmentType', 'FT'),
		contractorWorkerType: attr('string'),
		contractorWorkerTypeHelper: attr('string'),
		employmentTypeIncludingWorkerTypesHelper: attr('string'),
	});

	App.TakeoverBenefitsChange = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),

		borTerminateMedical: attr('boolean'),
		borTerminateDental: attr('boolean'),
		borTerminateVision: attr('boolean'),
		borTerminateLife: attr('boolean'),
		borTerminateSTD: attr('boolean'),
		borTerminateLTD: attr('boolean'),
		borTerminateADD: attr('boolean'),

		borBenefitsMedicalPlanType: attr('string'),
		borBenefitsMedicalEffectiveDate: attr('string'),
		borBenefitsMedicalPlan_id: attr('number'),
		borBenefitsMedicalCost: attr('number'),

		borBenefitsDentalPlanType: attr('string'),
		borBenefitsDentalEffectiveDate: attr('string'),
		borBenefitsDentalPlan_id: attr('number'),
		borBenefitsDentalCost: attr('number'),

		borBenefitsVisionPlanType: attr('string'),
		borBenefitsVisionEffectiveDate: attr('string'),
		borBenefitsVisionPlan_id: attr('number'),
		borBenefitsVisionCost: attr('number'),
	});

	App.EndDateChange = DS.Model.extend({
		endDate: attr('string'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		isEndDateChange: true,
	});

	App.GrantAddition = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		vestingStartDate: attr('string'),
		noOfAllocatedStocks: attr('number'),
		vestingPeriod: attr('number'),
		cliffPeriod: attr('number'),
		vestingFrequency: attr('string'),
		vestingScheduleDisplay: attr('string'),
		documentSet: DS.belongsTo('App.StockOptionDocumentSet'),
		isComplete: function () {
			return this.get('vestingStartDate') && this.get('noOfAllocatedStocks') && this.get('vestingFrequency') && (this.get('cliffPeriod') != null || this.get('cliffPeriod') != 'undefined') && (this.get('vestingPeriod') != null || this.get('vestingPeriod') != 'undefined');
		}.property('vestingStartDate', 'noOfAllocatedStocks', 'vestingPeriod', 'vestingFrequency', 'cliffPeriod'),
		allocatedStocksDisplay: function () {
			if (this.get('noOfAllocatedStocks')) {
				return this.get('noOfAllocatedStocks').toLocaleString();
			}
			return this.get('noOfAllocatedStocks');
		}.property('noOfAllocatedStocks'),
	});

	App.FederalTaxChange = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		oldFederalTax: DS.belongsTo('App.EmployeeFederalTax'),
		newFederalTax: DS.belongsTo('App.EmployeeFederalTax'),
	});

	App.StateTaxChange = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		oldStateTax: DS.belongsTo('App.EmployeeStateTax'),
		newStateTax: DS.belongsTo('App.EmployeeStateTax'),
	});

	App.AddressChange = DS.Model.extend({
		requestedBy: DS.belongsTo('App.AllEmployee'),
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		oldAddress: DS.belongsTo('App.Address'),
		newAddress: DS.belongsTo('App.Address'),
	});

	App.ChangeRequestGroup = DS.Model.extend({
		version_id: attr('number'),
		sendEmployeeEmail: attr('boolean'),
		sendManagerEmail: attr('boolean'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
		effectiveDate: attr('string'),
		creationDate: attr('string'),
		applyImmediately: attr('boolean'),
		reason: attr('string'),
		requests: DS.hasMany('App.ChangeRequest'),
		isReadyToProcess: attr('boolean'),
		type: attr('string'),
		status: attr('string'),
		approvals: DS.hasMany('App.ApprovalAction'),
		isPrimaryRequestGroup: attr('boolean'),
		isApproved: attr('boolean'),
		requestCompletionDate: attr('string'),
		isBackFilled: attr('boolean'),
		isEffectiveDateOrInFuture: function () {
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			var effectiveDate = moment(this.get('effectiveDate'));
			if (!this.get('applyImmediately') && effectiveDate > today) {
				return true;
			}
			return false;
		}.property('applyImmediately', 'effectiveDate'),
		calculatedEffectiveDate: function () {
			var currentDate = moment().format('MM/DD/YYYY');
			return this.get('applyImmediately') ? currentDate : this.get('effectiveDate');
		}.property('applyImmediately', 'effectiveDate'),
		mainRequest: function () {
			return this.get('requests').findProperty('isMainRequest');
		}.property('requests.@each.isMainRequest'),
		isNewHireRequest: Ember.computed.equal('type', 'new_hire'),
		isTransferRequest: Ember.computed.equal('type', 'transfer'),
		isTerminationRequest: Ember.computed.equal('type', 'termination'),
	});


	App.ChangeRequest = DS.Model.extend({
		group: DS.belongsTo('App.ChangeRequestGroup'),
		employee: DS.belongsTo('App.AllEmployee', { inverse: null }),
		addressChange: DS.belongsTo('App.AddressChange'),
		managerChange: DS.belongsTo('App.ManagerChange'),
		salaryChange: DS.belongsTo('App.SalaryChange'),
		titleChange: DS.belongsTo('App.TitleChange'),
		departmentChange: DS.belongsTo('App.DepartmentChange'),
		locationChange: DS.belongsTo('App.LocationChange'),
		hireDateChange: DS.belongsTo('App.HireDateChange'),
		federalTaxChange: DS.belongsTo('App.FederalTaxChange'),
		stateTaxChange: DS.belongsTo('App.StateTaxChange'),
		employmentTypeChange: DS.belongsTo('App.EmploymentTypeChange'),
		payScheduleChange: DS.belongsTo('App.PayScheduleChange'),
		taxExemptionChange: DS.belongsTo('App.TaxExemptionChange'),
		endDateChange: DS.belongsTo('App.EndDateChange'),
		grant: DS.belongsTo('App.GrantAddition'),
		newHireObject: DS.belongsTo('App.NewHire'),
		terminationRequest: DS.belongsTo('App.EmployeeTerminationAction'),
		benefitsChange: DS.belongsTo('App.TakeoverBenefitsChange'),
		benefitsEligibilityChange_id: attr('number'),
		isApplied: attr('boolean'),
		notApplied: Ember.computed.not('isApplied'),
		isMainRequest: attr('boolean'),
		isGroupReadyToProcess: Ember.computed.and('group.isReadyToProcess', 'group.isTransferRequest', 'notApplied'),
		hasAddressChange: Ember.computed.notEmpty('addressChange'),
		isAddressGroupReadyToProcess: Ember.computed.and('hasAddressChange', 'notApplied', 'group.isReadyToProcess'),
		cancelModalTitle: Ember.computed(
			'managerChange',
			'salaryChange',
			'titleChange',
			'departmentChange',
			'locationChange',
			'hireDateChange',
			'employmentTypeChange',
			'endDateChange',
			'group.isApproved',
			function () {
				var number = Number(Boolean(this.get('managerChange'))) +
					Number(Boolean(this.get('salaryChange'))) +
					Number(Boolean(this.get('titleChange'))) +
					Number(Boolean(this.get('departmentChange'))) +
					Number(Boolean(this.get('locationChange'))) +
					Number(Boolean(this.get('hireDateChange'))) +
					Number(Boolean(this.get('employmentTypeChange'))) +
					Number(Boolean(this.get('endDateChange')));
				var changeOrChanges = number > 1 ? 'Changes' : 'Change';
				var requestOrScheduled = this.get('group.isApproved') ? 'Scheduled' : 'Requested';
				return requestOrScheduled + ' ' + changeOrChanges;
			}
		),
	});

	App.ApproverChange = DS.Model.extend({
		changeRequest: DS.belongsTo('App.ChangeRequest'),
		approverEmployeeMapping: DS.belongsTo('App.ApproverEmployeeMapping'),
		newApprover: DS.belongsTo('App.AllEmployee'),
		requestedBy: DS.belongsTo('App.AllEmployee'),
	});

	App.TokenTaskUrl = DS.Model.extend({
		token: attr('string'),
		user: DS.belongsTo('App.User'),
		creationDate: attr('string'),
		runDate: attr('string'),
	});

	App.ApprovalAction = DS.Model.extend({
		group: DS.belongsTo('App.ChangeRequestGroup'),
		approver: DS.belongsTo('App.AllEmployee'),
		isApprovingSalaryChange: attr('boolean'),
		isApprovingTransferChange: attr('boolean'),
		isApprovingNewHireChange: attr('boolean'),
		isApprovingSOChange: attr('boolean'),
		isApprovingTermination: attr('boolean'),
		isEmailSent: attr('boolean'),
		isApproved: attr('boolean'),
		approvalToken: DS.belongsTo('App.TokenTaskUrl'),
		declineToken: DS.belongsTo('App.TokenTaskUrl'),
		autoDeclined: attr('boolean')
	});


	App.CarrierWaitingPeriod = DS.Model.extend({
		waitingPeriod: attr('string'),
		waitingPeriodReadable: attr('string'),
		isDefault: attr('boolean'),
	});

	App.CarrierPolicyNumberMetadata = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		invoiceUrl: attr('string'),
		policyNumberPattern: attr('string'),
		policyNumberSample: attr('string'),
		commonlyConfusedCarrier: DS.belongsTo('App.Carrier'),
	});

	App.BenefitsPreview = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		uuid: attr('string'),
		previewUrl: attr('string'),
		fullUrl: attr('string'),
		softDeleted: attr('boolean'),
	});

	App.ScoeAdminSignOff = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		isActive: attr('boolean'),
		isAdminSignedOff: attr('boolean'),
		isComplete: attr('boolean'),
		isSCOEEmailsSent: attr('boolean'),
		endDate: attr('string'),
		scoeMaxEndDate: attr('string'),
		authTimestamp: attr('date'),
		authName: attr('string'),
		authTitle: attr('string'),
		authSignature: attr('string'),
		healthEnrollments: DS.hasMany('App.CompanyHealthEnrollment'),

		freezeSignOff: function () {
			return this.get('isAdminSignedOff') && this.get('isComplete') && this.get('isSCOEEmailsSent');
		}.property('isComplete', 'isSCOEEmailsSent'),
	});

	App.BorCompanyNav = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		borCardsToRoutes: attr('string'),
		currentBoRRouteStatus: attr('string'),
		oldBoRRouteStatus: attr('string'),
		routeNameToEmberRoute: attr('string'),
		routeNameToDynamicParams: attr('string'),
		mandatoryRoutesToMinStepsNeeded: attr('string'),
		mandatoryCardsOrder: attr('raw'),

		// used only on client side
		enableMandatoryRoutes: attr('boolean', { defaultValue: true }),

		// getters to get string as JSON.
		borCardsToRoutesObj: function () {
			if (!this.get('borCardsToRoutes')) {
				return {};
			}
			return JSON.parse(this.get('borCardsToRoutes'));
		}.property('borCardsToRoutes'),
		borCardNamesToNavigationObjects: function () {
			if (!this.get('borCardsToRoutesObj')) {
				return {};
			}

			var cardNames = Object.keys(this.get('borCardsToRoutesObj')).concat(this.get('mandatoryCardsOrder'));
			return cardNames.reduce(function (hash, cardName) {
				// Currently keep only cardname, we will be adding more and more attributes wherever needed.
				hash[cardName] = Ember.Object.create({ cardName: cardName });
				return hash;
			}, {});
		}.property('borCardsToRoutesObj', 'mandatoryCardsOrder'),
		borRoutesStatusesObj: function () {
			if (this.get('currentBoRRouteStatus')) {
				return JSON.parse(this.get('currentBoRRouteStatus'));
			}
			return {};
		}.property('currentBoRRouteStatus'),
		borRoutesOldStatusesObj: function () {
			if (this.get('oldBoRRouteStatus')) {
				return JSON.parse(this.get('oldBoRRouteStatus'));
			}
			return {};
		}.property('oldBoRRouteStatus'),
		routeNameToEmberRouteObj: function () {
			if (this.get('routeNameToEmberRoute')) {
				return JSON.parse(this.get('routeNameToEmberRoute'));
			}
			return {};
		}.property('routeNameToEmberRoute'),
		routeNameToDynamicParamsObj: function () {
			if (this.get('routeNameToDynamicParams')) {
				return JSON.parse(this.get('routeNameToDynamicParams'));
			}
			return {};
		}.property('routeNameToDynamicParams'),
		mandatoryCardsToRoutes: function () {
			if (this.get('mandatoryRoutesToMinStepsNeeded')) {
				return JSON.parse(this.get('mandatoryRoutesToMinStepsNeeded'));
			}
		}.property('mandatoryRoutesToMinStepsNeeded'),
		mandatoryRoutesToMinStepsNeededObj: function () {
			var mandatoryCardsToRoutes = this.get('mandatoryCardsToRoutes');
			var routes = {};
			if (!mandatoryCardsToRoutes) {
				return routes;
			}

			Object.keys(mandatoryCardsToRoutes).forEach(function (card) {
				var cardRoutes = mandatoryCardsToRoutes[card];
				for (var route in cardRoutes) {
					routes[route] = cardRoutes[route];
				}
			});

			return routes;
		}.property('mandatoryCardsToRoutes'),
		// Setters which accept json object and sets the string value.
		setCurrentBoRRouteStatus: function (jsObj) {
			this.set('currentBoRRouteStatus', JSON.stringify(jsObj));
		},
		setOldBoRRouteStatus: function (jsObj) {
			this.set('oldBoRRouteStatus', JSON.stringify(jsObj));
		},
		mandateSCOEAdminSignOffRoute: function () {
			// Mark the route as incomplete first.
			var currentBoRRoutes = this.get('borRoutesStatusesObj');
			currentBoRRoutes['open-enrollment-plan-summary'] = 0;
			this.setCurrentBoRRouteStatus(currentBoRRoutes);

			// Now mark the route as mandatory.
			var mandatoryCardsToRoutes = this.get('mandatoryCardsToRoutes');
			// Force admin to sign-off the document again.
			if (!mandatoryCardsToRoutes) {
				mandatoryCardsToRoutes = {};
			}

			if (!mandatoryCardsToRoutes['open-enrollment-review']) {
				mandatoryCardsToRoutes['open-enrollment-review'] = {};
			}
			mandatoryCardsToRoutes['open-enrollment-review']['open-enrollment-plan-summary'] = 1;
			this.set('mandatoryRoutesToMinStepsNeeded', JSON.stringify(mandatoryCardsToRoutes));
		},
	});

	App.BorPersistenceNav = DS.Model.extend({
		allActionString: attr('string'),
		planCardString: attr('string'),
		allActions: function () {
			if (!this.get('allActionString')) {
				return [];
			}
			return JSON.parse(this.get('allActionString'));
		}.property('allActionString'),

		planCards: function () {
			if (!this.get('planCardString')) {
				return [];
			}
			return JSON.parse(this.get('planCardString'));
		}.property('planCardString'),

	});

	App.BorPlanPremiums = DS.Model.extend({
		planPremiumsString: attr('string'),
		planPremiums: function () {
			if (!this.get('planPremiumsString')) {
				return [];
			}
			return JSON.parse(this.get('planPremiumsString'));
		}.property('planPremiumsString'),
	});

	App.BorEmployeeWizardNav = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		isActive: attr('boolean'),
		wizardStepsJson: attr('string'),
		// The following need to be in sync - AllEmployee.isUpdated, BorEmployeeWizardNav isComplete in django and ember, EmployeeDAO.isEmployeeDetailsComplete
		isComplete: function () {
			var wizardSteps = this.get('wizardStepsObj');
			for (var step in wizardSteps) {
				if (step != 'photo' && step != 'welcome') {
					if (!wizardSteps[step]) {
						return false;
					}
				}
			}
			return true;
		}.property('wizardStepsObj'),

		wizardStepsObj: function () {
			return JSON.parse(this.get('wizardStepsJson'));
		}.property('wizardStepsJson'),
		setWizardStepsJson: function (jsObj) {
			this.set('wizardStepsJson', JSON.stringify(jsObj));
		},
	});

	App.InitialEnrollmentWizardNav = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		isActive: attr('boolean'),
		desiredEffectiveDate: attr('string'),
		wizardStepsJson: attr('string'),
	});

	App.EmployeeLifeDisabilityEnrollmentConsoleApi = DS.Model.extend({
		/******** Life *********/
		/* Current enrollment */
		companyLifeEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		lifePlans: DS.hasMany("App.LifePlanNew"),
		employeeLifeEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentLifeEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeLifePlans: DS.hasMany("App.EmployeeLifePlanNew"),
		dependentLifePlans: DS.hasMany("App.DependentLifePlan"),

		/* Previous enrollment */
		prevCompanyLifeEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevLifePlans: DS.hasMany("App.LifePlanNew"),
		prevEmployeeLifeEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentLifeEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeLifePlans: DS.hasMany("App.EmployeeLifePlanNew"),
		prevDependentLifePlans: DS.hasMany("App.DependentLifePlan"),

		/******** AD&D *******/
		/* Current enrollment */
		companyAddEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		addPlans: DS.hasMany("App.AddPlan"),
		employeeAddEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentAddEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeAddPlans: DS.hasMany("App.EmployeeAddPlan"),
		dependentAddPlans: DS.hasMany("App.DependentAddPlan"),

		/* Previous enrollment */
		prevCompanyAddEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevAddPlans: DS.hasMany("App.AddPlan"),
		prevEmployeeAddEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentAddEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeAddPlans: DS.hasMany("App.EmployeeAddPlan"),
		prevDependentAddPlans: DS.hasMany("App.DependentAddPlan"),

		/******** STD *********/
		/* Current enrollment */
		companyStdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		stdPlans: DS.hasMany("App.StdPlanNew"),
		employeeStdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentStdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeStdPlans: DS.hasMany("App.EmployeeStdPlanNew"),
		dependentStdPlans: DS.hasMany("App.DependentStdPlan"),

		/* Previous enrollment */
		prevCompanyStdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevStdPlans: DS.hasMany("App.StdPlanNew"),
		prevEmployeeStdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentStdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeStdPlans: DS.hasMany("App.EmployeeStdPlanNew"),
		prevDependentStdPlans: DS.hasMany("App.DependentStdPlan"),

		/******** LTD *********/
		/* Current enrollment */
		companyLtdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		ltdPlans: DS.hasMany("App.LtdPlanNew"),
		employeeLtdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		dependentLtdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		employeeLtdPlans: DS.hasMany("App.EmployeeLtdPlanNew"),
		dependentLtdPlans: DS.hasMany("App.DependentLtdPlan"),

		/* Previous enrollment */
		prevCompanyLtdEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		prevLtdPlans: DS.hasMany("App.LtdPlanNew"),
		prevEmployeeLtdEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevDependentLtdEnrollments: DS.hasMany("App.DependentLifeDisabilityEnrollment"),
		prevEmployeeLtdPlans: DS.hasMany("App.EmployeeLtdPlanNew"),
		prevDependentLtdPlans: DS.hasMany("App.DependentLtdPlan"),
	}).reopenClass({
		noBatch: true
	});

	////////////////////////////////////////////
	///// Life & Disability new models /////////
	////////////////////////////////////////////

	/* ENUM CLASSES */
	var _LifeDisabilityApplicableEnrollees;
	_LifeDisabilityApplicableEnrollees = window._LifeDisabilityApplicableEnrollees = Ember.Object.create({
		EMPLOYEE_ONLY: 'employee_only',
		SPOUSE_ONLY: 'spouse_only',
		CHILD_ONLY: 'child_only',
		EMPLOYEE_SPOUSE_ONLY: 'employee_spouse_only',
		EMPLOYEE_CHILD_ONLY: 'employee_child_only',
		EMPLOYEE_SPOUSE_CHILD: 'employee_spouse_child'
	});

	window._LifeDisabilityApplicableClasses = App._LifeDisabilityApplicableClasses = Ember.Object.create({
		DEPENDENT_ENROLLMENT: 'dependent_enrollment',
		EMPLOYEE_ENROLLMENT: 'employee_enrollment',
		LOC: 'line_of_coverage',
		PLAN: 'plan',
		RATE: 'rate',
		RESTRICTION: 'restriction',
		EMPLOYEE_PLAN: 'employee_plan',
		DEPENDENT_PLAN: 'dependent_plan',
		PLAN_FORM: 'plan_form',
		PLAN_RATE_FORM: 'plan_rate_form',
		BASIC_PLAN_RESTRICTION_FORM: 'basic_plan_restriction_form',
		VOLUNTARY_PLAN_RESTRICTION_FORM: 'voluntary_plan_restriction_form',
	});


	/* Plan Models */
	App._LifeDisabilityPlanAbstractModel = DS.Model.extend({
		BASIC: 'basic',
		VOLUNTARY: 'voluntary',

		MIGRATED: 'migrated',
		VERIFIED: 'verified',
		BUNDLED: 'bundled',

		name: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		planUrl: attr('string'),
		planType: attr('string'),
		productCode: attr('string'),
		applicableEnrollees: attr('string'),
		participationRequirement: attr('number'),
		migrationStatus: attr('string'),

		isShortCircuitPlan: attr('boolean'),
		shortCircuitPlanType: attr('string'),

		ageDetermination: attr('string'),
		ageRedetermination: attr('string'),

		salaryRedetermination: attr('string'),

		shortCircuitPlanTypeWithRatesNotBenefits: Ember.computed.equal('shortCircuitPlanType', 'R'),
		shortCircuitPlanWithoutBenefits: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'isShortCircuitPlan'),

		employee: DS.belongsTo("App.AllEmployee"),
		isBasic: function () {
			return this.get('planType') == App._LifeDisabilityPlanAbstractModel.prototype.BASIC;
		}.property('planType'),

		isVoluntary: function () {
			return this.get('planType') == App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY;
		}.property('planType'),

		noPlanType: function () {
			return !this.get('planType');
		}.property('planType'),

		/* TODO: Will be used when we support multiple voluntary plans. Add watcher */
		//	isSelected: function() {
		//		return !!this.get('employee').getLifeDisabilityEnrollment(this.get('planLineOfCoverage')).get('employeePlans').findProperty('plan', this);
		//	}.property(''),

		eeRestriction: function () {
			return this.get('restrictions').findProperty('enrolleeType', _LifeDisabilityApplicableEnrollees.EMPLOYEE_ONLY);
		}.property('restrictions.@each'),
		spRestriction: function () {
			return this.get('restrictions').findProperty('enrolleeType', _LifeDisabilityApplicableEnrollees.SPOUSE_ONLY);
		}.property('restrictions.@each'),
		chRestriction: function () {
			return this.get('restrictions').findProperty('enrolleeType', _LifeDisabilityApplicableEnrollees.CHILD_ONLY);
		}.property('restrictions.@each'),

		enrolleeInfo: function () {
			var enrollees = [];
			this.get('employee').getLifeDisabilityEnrollment(this.get('planLineOfCoverage')).get('employeePlans').forEach(function (ep) {
				if (ep.get('plan') == this) {
					enrollees.push({
						"name": ep.get('enrollment.employee.fullName'),
						"type": "Self",
						"amount": ep.get('amount'),
						"premium": ep.get('premium')
					});
				}
			}.bind(this));

			this.get('employee.dependents').forEach(function (dependent) {
				if (!dependent.get('isSelf')) {
					var dependentEnrollment = dependent.getLifeDisabilityEnrollment(this.get('planLineOfCoverage'));
					if (dependentEnrollment && dependentEnrollment.get('dependentPlans.length')) {
						dependentEnrollment.get('dependentPlans').forEach(function (dp) {
							if (dp.get('plan') == this) {
								enrollees.push({
									"name": dp.get('enrollment.dependent.fullName'),
									"dependentType": dp.get('enrollment.dependent.type'),
									"type": dp.get('enrollment.type'),
									"amount": dp.get('amount'),
									"premium": dp.get('premium')
								});
							}
						}.bind(this));
					}
				}
			}.bind(this));

			return enrollees;
		}.property('employee.lifeEnrollment.employeePlans.@each.amount', 'employee.addEnrollment.employeePlans.@each.amount',
			'employee.dependents.@each.lifeEnrollment.dependentPlans.@each.amount', 'employee.dependents.@each.addEnrollment.dependentPlans.@each.amount'),

		isBundled: function () {
			return this.get('migrationStatus') == this.get('BUNDLED');
		}.property('migrationStatus'),

		employeeOnly: function () {
			return this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_ONLY;
		}.property('applicableEnrollees'),

		isEmployeeEligible: function () {
			// determine if applicable
			var isApplicable = this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_SPOUSE_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_CHILD_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_SPOUSE_CHILD;
			if (!isApplicable) { return false; }

			// determine if restriction exists (existing restriction + applicable determine eligibility)
			// Also if the plan is short circuit plan, just make sure applicableEnrollees includes the enrollee
			return !!this.get('eeRestriction') || this.get('isShortCircuitPlan');
		}.property('applicableEnrollees', 'eeRestriction'),

		isSpouseEligible: function () {
			// determine if applicable
			var isApplicable = this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.SPOUSE_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_SPOUSE_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_SPOUSE_CHILD;
			if (!isApplicable) { return false; }

			// determine if restriction exists (existing restriction + applicable determine eligibility)
			// Also if the plan is short circuit plan, just make sure applicableEnrollees includes the enrollee
			return !!this.get('spRestriction') || this.get('isShortCircuitPlan');
		}.property('applicableEnrollees', 'spRestriction'),

		isChildEligible: function () {
			// determine if applicable
			var isApplicable = this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.CHILD_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_CHILD_ONLY ||
				this.get('applicableEnrollees') == _LifeDisabilityApplicableEnrollees.EMPLOYEE_SPOUSE_CHILD;
			if (!isApplicable) { return false; }

			// determine if restriction exists (existing restriction + applicable determine eligibility)
			// Also if the plan is short circuit plan, just make sure applicableEnrollees includes the enrollee
			return !!this.get('chRestriction') || this.get('isShortCircuitPlan');
		}.property('applicableEnrollees', 'chRestriction'),

		isDependentEligible: function (dependent) {
			if (!dependent) {
				return false;
			}

			// check dependent type to see which eligibility to check
			if (dependent.get('isSpouse')) {
				return this.get('isSpouseEligible');
			} else if (dependent.get('isChild')) {
				return this.get('isChildEligible');
			} else {
				return false;
			}
		},
	});

	App.LifePlanNew = App._LifeDisabilityPlanAbstractModel.extend({
		restrictions: DS.hasMany('App.LifePlanRestriction'),
		rates: DS.hasMany("App.LifePlanRate"),
		evidenceOfInsurabilityForm: attr('string'),
		planType: attr('string'),

		planLineOfCoverage: "lifenew",
		consoleBaseUrl: function () {
			return "lifeplans";
		}.property(''),
	});

	App.AddPlan = App._LifeDisabilityPlanAbstractModel.extend({
		TIERED_RATE: 'tiered_rate',
		SEPARATE_RATE: 'separate_rate',

		restrictions: DS.hasMany('App.AddPlanRestriction'),
		rates: DS.hasMany("App.AddPlanRate"),
		evidenceOfInsurabilityForm: attr('string'),
		planType: attr('string'),

		planLineOfCoverage: "add",
		consoleBaseUrl: function () {
			return "addplans";
		}.property(''),
	});

	App._DisabilityPlanAbstractModel = Ember.Mixin.create({
		eliminationPeriod: attr('string'),
		benefitsDuration: attr('string'),
		ownOccPeriod: attr('string'),
		preExistingCondition: attr('string'),
		evidenceOfInsurabilityForm: attr('string'),
	});

	App.StdPlanNew = App._LifeDisabilityPlanAbstractModel.extend(App._DisabilityPlanAbstractModel, {
		restrictions: DS.hasMany('App.StdPlanRestriction'),
		rates: DS.hasMany("App.StdPlanRate"),
		planLineOfCoverage: "std",
		planType: attr('string'),

		consoleBaseUrl: function () {
			return "stdplans";
		}.property(''),
	});

	App.LtdPlanNew = App._LifeDisabilityPlanAbstractModel.extend(App._DisabilityPlanAbstractModel, {
		restrictions: DS.hasMany('App.LtdPlanRestriction'),
		rates: DS.hasMany("App.LtdPlanRate"),
		planLineOfCoverage: "ltd",
		planType: attr('string'),

		consoleBaseUrl: function () {
			return "ltdplans";
		}.property(''),
	});

	App.SupplementalPlan = DS.Model.extend({
		stateCarrier: DS.belongsTo('App.Carrier'),
		stateCarrier_id: attr('number'),
		carrier: attr('string'),
		lineOfCoverage: attr('string'),
		name: attr('string'),
		planUrl: attr('string'),
		videoUrl: attr('string'),
	});

	/* Plan rate models */
	App._LifeDisabilityAgeAndGenderAbstractModel = DS.Model.extend({
		gender: attr('string'),
		ageMin: attr('number'),
		ageMax: attr('number'),
	});

	App._LifeDisabilityPlanRateAbstractModel = Ember.Mixin.create({
		enrolleeType: attr('string'),
		effectiveStartDate: attr('date'),
		effectiveEndDate: attr('date'),
	});

	App.LifePlanRate = App._LifeDisabilityAgeAndGenderAbstractModel.extend(App._LifeDisabilityPlanRateAbstractModel, {
		plan: DS.belongsTo('App.LifePlanNew'),
		ratePerThousand: attr('number'),
	});

	App.AddPlanRate = DS.Model.extend(App._LifeDisabilityPlanRateAbstractModel, {
		plan: DS.belongsTo('App.AddPlan'),
		ratePerThousand: attr('number'),
	});

	App.StdPlanRate = App._LifeDisabilityAgeAndGenderAbstractModel.extend(App._LifeDisabilityPlanRateAbstractModel, {
		plan: DS.belongsTo('App.StdPlanNew'),
		ratePerTen: attr('number'),
	});

	App.LtdPlanRate = App._LifeDisabilityAgeAndGenderAbstractModel.extend(App._LifeDisabilityPlanRateAbstractModel, {
		plan: DS.belongsTo('App.LtdPlanNew'),
		ratePerHundred: attr('number'),
	});

	/* Plan restriction models */
	App._LifeDisabilityRestrictionMixin = Ember.Mixin.create({
		rateType: attr('string'),
		guaranteeIssue: attr('number'),
	});

	App._LifeDisabilityPlanRestrictionAbstractModel = DS.Model.extend({
		FIXED: 'fixed',
		MULTIPLE_OF_EE_SALARY: 'multiple_of_ee_salary',
		MULTIPLE_OF_EE_SELECTED_AMOUNT: 'multiple_of_ee_selected_amount',

		COMPOSITE: 'composite',
		AGE_BANDED: 'age_banded',
		GENDER_BANDED: 'gender_banded',
		AGE_AND_GENDER_BANDED: 'age_and_gender_banded',

		enrolleeType: attr('string'),
		incrementalUnits: attr('number'),
		flatAmounts: attr('string'),
		planAmountStyle: attr('string'),
		enrolleeMaxAmountStyle: attr('string'),
		maxMultiplier: attr('number'),
		planMinAmount: attr('number'),
		planMaxAmount: attr('number'),
		// always need to call "frontendhelper" to get the value of this field, also need to reload when employee changes the amount on EELP
		maxAmount: attr('string'),

		isFixed: attr('boolean'),
		isIncrements: attr('boolean'),
		isSetValues: attr('boolean'),
		isMultiplesOfEarnings: attr('boolean'),

		guaranteeIssueBandType: attr('string'),
		benefitsReductionBandType: attr('string'),

		planMinAmountExceptZero: function () {
			if (this.get('planMinAmount') === 0) {
				return 1;
			}
			return this.get('planMinAmount');
		}.property('planMinAmount'),

		flatAmountsObj: function () {
			var applicableFlatAmounts = [];
			if (this.get('isSetValues')) {
				this.get('flatAmounts').split(',').forEach(function (item) {
					if (this.get('planMinAmountExceptZero') != null || this.get('planMinAmountExceptZero') != undefined) {
						if (Number(item) >= Number(this.get('planMinAmountExceptZero')) && Number(item) <= Number(this.get('maxAmount'))) {
							applicableFlatAmounts.push(item);
						}
					}
					else if (Number(item) <= Number(this.get('maxAmount'))) {
						applicableFlatAmounts.push(item);
					}

				}.bind(this));
			}
			return applicableFlatAmounts;
		}.property('flatAmounts', 'maxAmount', 'planMinAmountExceptZero'),

		getEmployeeSelectedAmount: function () {
			var employeeSelectedAmount = "0";
			var employeePlan = this.get('plan.employee').getLifeDisabilityEnrollment(this.get('plan.planLineOfCoverage')).get('employeePlans').findProperty('plan', this.get('plan'));
			if (employeePlan) {
				employeeSelectedAmount = employeePlan.get('amount');
			}
			return employeeSelectedAmount;
		},
	});

	App.LifePlanRestriction = App._LifeDisabilityPlanRestrictionAbstractModel.extend(App._LifeDisabilityRestrictionMixin, {
		plan: DS.belongsTo('App.LifePlanNew'),
	});

	App.AddPlanRestriction = App._LifeDisabilityPlanRestrictionAbstractModel.extend(App._LifeDisabilityRestrictionMixin, {
		plan: DS.belongsTo('App.AddPlan'),
	});

	App.StdPlanRestriction = App._LifeDisabilityPlanRestrictionAbstractModel.extend(App._LifeDisabilityRestrictionMixin, {
		plan: DS.belongsTo('App.StdPlanNew'),
	});

	App.LtdPlanRestriction = App._LifeDisabilityPlanRestrictionAbstractModel.extend(App._LifeDisabilityRestrictionMixin, {
		plan: DS.belongsTo('App.LtdPlanNew'),
	});

	/* Life & Disability enrollment models for employee and dependent */
	var _LifeEOIMixin = Ember.Mixin.create({
		isEnrollmentOverGI: function () {
			if (this.get('employee')) {
				return !!(this.get('isOverGI') || this.get('dependentLifeDisabilityEnrollments').someProperty('isOverGI', true));
			}
			else if (this.get('dependent')) {
				return this.get('isOverGI');
			}
		}.property('isOverGI', 'dependentPlans.@each.isOverGI'),

		isAnyVoluntaryEnrollmentOverGI: function () {
			if (this.get('employee')) {
				return !!(this.get('isAnyVoluntaryOverGI') || this.get('dependentLifeDisabilityEnrollments').someProperty('isAnyVoluntaryOverGI', true));
			}
			else if (this.get('dependent')) {
				return this.get('isAnyVoluntaryOverGI');
			}
		}.property('isAnyVoluntaryOverGI', 'dependentPlans.@each.isAnyVoluntaryOverGI'),

		isOverGI: function () {
			if (this.get('employee')) {
				return this.get('employeePlans').someProperty('isOverGI', true);
			}
			else if (this.get('dependent')) {
				return this.get('dependentPlans').someProperty('isOverGI', true);
			}
		}.property('employeePlans.@each.isOverGI', 'dependentPlans.@each.isOverGI'),

		isAnyVoluntaryOverGI: function () {
			if (this.get('employee')) {
				var volPlan = this.get('employeePlans').filterBy('plan.isVoluntary', true);
				return !!(volPlan && volPlan.someProperty('isOverGI', true));
			}
			else if (this.get('dependent')) {
				var volPlan = this.get('dependentPlans').filterBy('plan.isVoluntary', true);
				return !!(volPlan && volPlan.someProperty('isOverGI', true));
			}
		}.property('employeePlans.@each.isOverGI', 'dependentPlans.@each.isOverGI'),

		isStatusColorGreen: function () {
			return (this.get('status') == _LDEAbstractModel.prototype.APPROVED);
		}.property('status'),
		isStatusColorOrange: function () {
			return (this.get('status') == _LDEAbstractModel.prototype.COMPLETE);
		}.property('status'),
		isStatusColorRed: function () {
			return (this.get('status') == _LDEAbstractModel.prototype.DECLINE);
		}.property('status'),
		isStatusColorGrey: function () {
			return (this.get('status') == _LDEAbstractModel.prototype.BEGIN || this.get('status') == '');
		}.property('status'),
	});

	var _LDEAbstractModel;
	_LDEAbstractModel = window._LDEAbstractModel = DS.Model.extend({
		LIFE: 'lifenew',
		ADD: 'add',
		STD: 'std',
		LTD: 'ltd',

		LIFE_DISABILITY_LINES_OF_COVERAGE: ['lifenew', 'add', 'std', 'ltd'],

		BEGIN: 'begin',
		COMPLETE: 'complete',
		DECLINE: 'decline',
		APPROVED: 'approved',
		CANCELED: 'canceled',

		lineOfCoverage: attr('string'),
		isActive: attr('boolean'),
		isEnrollmentComplete: attr('boolean'),
		status: attr('string'),
		effectiveDate: attr('date'),
		// the following signature related field are depreated
		authName: attr('string'),
		authTitle: attr('string'),
		authDate: attr('date'),
		//authSignature is not available in the Thin Model, which is excluded in the tastypie, but leave it here for Now
		authSignature: attr('string'),
		// use the signature object instead
		signature: DS.belongsTo('App.Signature'),
		enrollmentBegunTimestamp: attr('date'),
		enrollmentCompleteTimestamp: attr('date'),
		effectiveDateDisplay: function () {
			return moment(this.get('effectiveDate')).utc().format("MMM Do YY");
		}.property('effectiveDate'),
		hasEnrollmentBegun: function () {
			return !!this.get('status');
		}.property('status'),
		isDeclinedOrCancelled: function () {
			return (
				this.get('status') === _LDEAbstractModel.prototype.DECLINE ||
				this.get('status') === _LDEAbstractModel.prototype.CANCELED
			);
		}.property('status'),
	});

	App.ThinEmployeeLifeDisabilityEnrollment = _LDEAbstractModel.extend(_LifeEOIMixin, {
		INITIAL_ENROLLMENT: 'IE',
		OPEN_ENROLLMENT: 'OE',
		SWITCH_CARRIER: 'SW',

		employee: DS.belongsTo('App.AllEmployee'),
		enrolleePlans: function () {
			return this.get('employeePlans');
		}.property('employeePlans'),
		employeePlans: function () {
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LIFE) {
				return this.get('employeeLifePlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.ADD) {
				return this.get('employeeAddPlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.STD) {
				return this.get('employeeStdPlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LTD) {
				return this.get('employeeLtdPlans');
			}
		}.property('employeeLifePlans.@each', 'employeeAddPlans.@each', 'employeeStdPlans.@each', 'employeeLtdPlans.@each', 'lineOfCoverage'),
		isSwitchCarrierEnrollment: function () {
			return this.get('enrollmentType') == this.SWITCH_CARRIER || this.get('enrollmentType') == this.OPEN_ENROLLMENT;
		}.property('enrollmentType'),
		companyHealthEnrollment: DS.belongsTo("App.CompanyHealthEnrollment"),
		enrollmentType: attr('string'),
		granularEnrollmentType: attr('string'),
		startDate: attr('date'),
		endDate: attr('date'),
		isEnrollmentPendingEOI: attr('boolean'),
		isEnrollmentOngoing: attr('boolean'),
		isShortCircuitEnrollment: attr('boolean'),
		payPeriodDivisor: attr('number'),
		progress: attr('json'),

		isVisible: function () {
			if (this.get('isActive')) {
				return true;
			}
			if (this.get('companyHealthEnrollment.enrollmentType') == 'BoR') {
				return (this.get('status') == _LDEAbstractModel.prototype.APPROVED ||
					this.get('status') == _LDEAbstractModel.prototype.DECLINE);
			}
			return true;
		}.property('companyHealthEnrollment.enrollmentType'),

		/* Use employeePlans instead of these fields */
		employeeLifePlans: DS.hasMany('App.ThinEmployeeLifePlanNew'),
		employeeAddPlans: DS.hasMany('App.ThinEmployeeAddPlan'),
		employeeStdPlans: DS.hasMany('App.ThinEmployeeStdPlanNew'),
		employeeLtdPlans: DS.hasMany('App.ThinEmployeeLtdPlanNew'),
		dependentLifeDisabilityEnrollments: DS.hasMany('App.ThinDependentLifeDisabilityEnrollment'),

		//This property is used to display if the employee's spouse and how many children are covered by an enrollment
		enrolleeText: function () {
			var hasSpouse = false;
			var childrenCounter = 0;

			var dldes = this.get('dependentLifeDisabilityEnrollments');
			if (dldes) {
				dldes.forEach(function (dlde) {
					var type = dlde.get('dependent.type');
					switch (type) {
						case 'Spouse':
							hasSpouse = true;
							break;
						case 'Child':
							childrenCounter++;
							break;
					}
				});
			}

			var enrolleeText = '';
			if (hasSpouse) {
				enrolleeText = enrolleeText.concat('w/ Spouse');
			}
			if (childrenCounter > 0) {
				var childrenText = (childrenCounter === 1) ? '1 Child' : childrenCounter + ' Children';

				enrolleeText = enrolleeText.concat((hasSpouse ? ' + ' : 'w/') + childrenText);
			}
			return enrolleeText;
		}.property('dependentLifeDisabilityEnrollments.@each', 'dependentLifeDisabilityEnrollments.@each.dependent', 'dependentLifeDisabilityEnrollments.[]'),

		basicPlan: function () {
			var plans = this.get('employeePlans');
			var ret = null;
			if (plans) {
				plans.forEach(function (plan) {
					if (plan.get('plan.planType') === App._LifeDisabilityPlanAbstractModel.prototype.BASIC) {
						//If there is bad data, multiple plans, choose the latest one
						if (!ret || ret.get("id") < plan.get("id")) {
							ret = plan;
						}
					}
				});
			}
			return ret;
		}.property('employeePlans', 'employeePlans.[]', 'employeePlans.@each.amount', 'employeePlans.@each.plan.planType'),

		voluntaryPlan: function () {
			var plans = this.get('employeePlans');
			var ret = null;
			if (plans) {
				plans.forEach(function (plan) {
					if (plan.get('plan.planType') === App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY) {
						//If there is bad data, multiple plans, choose the latest one
						if (!ret || ret.get("id") < plan.get("id")) {
							ret = plan;
						}
					}
				});
			}
			return ret;
		}.property('employeePlans', 'employeePlans.[]', 'employeePlans.@each.amount', 'employeePlans.@each.plan.planType'),

		totalBasicVolume: function () {
			var total = 0;

			var basicVolume = this.get('basicPlan.amount');
			if (basicVolume) {
				total += basicVolume;
			}

			this.get('dependentLifeDisabilityEnrollments').forEach(function (dlde) {
				var dependentBasicVolume = dlde.get('basicPlan.amount');
				if (dependentBasicVolume) {
					total += dependentBasicVolume;
				}
			});
			return total;
		}.property('employeePlans.[]', 'employeePlans.@each.amount', 'employeePlans.@each.plan.planType', 'dependentLifeDisabilityEnrollments.@each.basicPlan', 'dependentLifeDisabilityEnrollments.[]'),

		totalBasicPremium: function () {
			var total = 0;

			var basicPremium = this.get('basicPlan.premium');
			if (basicPremium) {
				total += Number(basicPremium);
			}

			this.get('dependentLifeDisabilityEnrollments').forEach(function (dlde) {
				var dependentBasicPremium = dlde.get('basicPlan.premium');
				if (dependentBasicPremium) {
					total += Number(dependentBasicPremium);
				}
			});
			return total;
		}.property('employeePlans.[]', 'employeePlans.@each.premium', 'employeePlans.@each.plan.planType', 'dependentLifeDisabilityEnrollments.@each.basicPlan', 'dependentLifeDisabilityEnrollments.[]'),

		totalVoluntaryVolume: function () {
			var total = 0;

			var voluntaryVolume = this.get('voluntaryPlan.amount');
			if (voluntaryVolume) {
				total += voluntaryVolume;
			}

			this.get('dependentLifeDisabilityEnrollments').forEach(function (dlde) {
				var dependentVoluntaryVolume = dlde.get('voluntaryPlan.amount');
				if (dependentVoluntaryVolume) {
					total += dependentVoluntaryVolume;
				}
			});
			return total;
		}.property('employeePlans.[]', 'employeePlans.@each.amount', 'employeePlans.@each.plan.planType', 'dependentLifeDisabilityEnrollments.@each.voluntaryPlan', 'dependentLifeDisabilityEnrollments.[]'),

		totalVoluntaryPremium: function () {
			var total = 0;

			var voluntaryPremium = this.get('voluntaryPlan.premium');
			if (voluntaryPremium) {
				total += Number(voluntaryPremium);
			}

			this.get('dependentLifeDisabilityEnrollments').forEach(function (dlde) {
				var dependentVoluntaryPremium = dlde.get('voluntaryPlan.premium');
				if (dependentVoluntaryPremium) {
					total += Number(dependentVoluntaryPremium);
				}
			});
			return total;
		}.property('employeePlans.[]', 'employeePlans.@each.premium', 'employeePlans.@each.plan.planType', 'dependentLifeDisabilityEnrollments.@each.voluntaryPlan', 'dependentLifeDisabilityEnrollments.[]'),
	});

	App.EmployeeLifeDisabilityEnrollment = App.ThinEmployeeLifeDisabilityEnrollment.extend(_LifeEOIMixin, {
		isCardBlocked: attr('boolean'),
		prevEnrollment: DS.belongsTo('App.EmployeeLifeDisabilityEnrollment'),
		employeeLifePlans: DS.hasMany('App.EmployeeLifePlanNew'),
		employeeAddPlans: DS.hasMany('App.EmployeeAddPlan'),
		employeeStdPlans: DS.hasMany('App.EmployeeStdPlanNew'),
		employeeLtdPlans: DS.hasMany('App.EmployeeLtdPlanNew'),
		dependentLifeDisabilityEnrollments: DS.hasMany('App.DependentLifeDisabilityEnrollment'),

		changedPlans: function () {
			var plansChanged = [];

			var currPlans = this.get('employeePlans');
			var prevPlans = this.get('prevEnrollment.employeePlans');
			Ember.RSVP.all([currPlans, prevPlans]).then(function () {
				if (!currPlans) { currPlans = []; }
				if (!prevPlans) { prevPlans = []; }

				// traverse backwards to find new elections / edited elections
				currPlans.forEach(function (currPlan) {
					plansChanged.pushObject({
						'oldPlan': currPlan.get('prevPlan'),
						'newPlan': currPlan
					});
				});

				// traverse forwards to find removed elections
				prevPlans.forEach(function (prevPlan) {
					// these plans already handled above
					if (currPlans.findBy('prevPlanId', prevPlan.get('id'))) { return; }

					plansChanged.pushObject({
						'oldPlan': prevPlan,
						'newPlan': null
					});
				});
			});

			return plansChanged;
		}.property('employeePlans.[]', 'prevEnrollment.employeePlans.[]'),

		changedDependents: function () {
			var depsChanged = [];

			var currDeps = this.get('dependentLifeDisabilityEnrollments');
			var prevDeps = this.get('prevEnrollment.dependentLifeDisabilityEnrollments');
			Ember.RSVP.all([currDeps, prevDeps]).then(function () {
				if (!currDeps) { currDeps = []; }
				if (!prevDeps) { prevDeps = []; }

				// load the world
				var need = [];
				[currDeps, prevDeps].forEach(function (enrollments) {
					enrollments.forEach(function (enrollment) {
						need.pushObject(enrollment.get('dependent'));
						need.pushObject(enrollment.get('dependentPlans'));
					});
				});

				Ember.RSVP.all(need).then(function () {
					// only consider dep enrollments with plans
					var onlyEnrollmentsWithPlans = function (dlde) { return dlde.get('dependentPlans.length') > 0; };
					currDeps = currDeps.filter(onlyEnrollmentsWithPlans);
					prevDeps = prevDeps.filter(onlyEnrollmentsWithPlans);

					var additions = [];
					var edits = [];
					var removals = [];

					currDeps.forEach(function (currDep) {
						var prevDep = currDep.get('prevEnrollment');

						// addition
						if (!prevDep) {
							additions.pushObject({
								'changeType': 'Addition',
								'dependent': currDep.get('dependent'),
								'delps': currDep.get('dependentPlans')
							});

							// edits
						} else {
							edits.pushObject({
								'changeType': 'Changed',
								'dependent': currDep.get('dependent'),
								'delps': currDep.get('dependentPlans').filter(function (delp) {
									return delp.get('amount') != delp.get('prevPlan.amount');
								})
							});
						}
					});

					prevDeps.forEach(function (prevDep) {
						var currDep = currDeps.findBy('prevEnrollment', prevDep);

						// removal
						if (!currDep) {
							removals.pushObject({
								'changeType': 'Removal',
								'dependent': prevDep.get('dependent')
							});
						}
					});

					// add all items to depsChanged in order
					depsChanged.pushObjects(additions);
					depsChanged.pushObjects(edits);
					depsChanged.pushObjects(removals);
				});
			});

			return depsChanged;
		}.property('dependentLifeDisabilityEnrollments.[]', 'dependentLifeDisabilityEnrollments.@each.dependentPlans.[]', 'prevEnrollment.dependentLifeDisabilityEnrollments.[]', 'prevEnrollment.dependentLifeDisabilityEnrollments.@each.dependentPlans.[]')
	});

	App.ThinDependentLifeDisabilityEnrollment = _LDEAbstractModel.extend(_LifeEOIMixin, {
		ADDITION: '+',
		REMOVAL: '-',

		dependent: DS.belongsTo('App.Dependent'),
		employeeLifeDisabilityEnrollment: DS.belongsTo("App.ThinEmployeeLifeDisabilityEnrollment"),
		enrolleePlans: function () {
			return this.get('dependentPlans');
		}.property('dependentPlans'),
		companyHealthEnrollment: function () {
			return this.get('employeeLifeDisabilityEnrollment.companyHealthEnrollment');
		}.property('employeeLifeDisabilityEnrollment.companyHealthEnrollment'),
		dependentPlans: function () {
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LIFE) {
				return this.get('dependentLifePlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.ADD) {
				return this.get('dependentAddPlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.STD) {
				return this.get('dependentStdPlans');
			}
			if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LTD) {
				return this.get('dependentLtdPlans');
			}
		}.property('lineOfCoverage', 'dependentLifePlans.@each', 'dependentAddPlans.@each', 'dependentStdPlans.@each', 'dependentLtdPlans.@each'),
		type: attr('string'),
		enrollee: attr('string'),

		/* Use dependentPlans instead of these fields */
		dependentLifePlans: DS.hasMany('App.ThinDependentLifePlan'),
		dependentAddPlans: DS.hasMany('App.ThinDependentAddPlan'),
		dependentStdPlans: DS.hasMany('App.ThinDependentStdPlan'),
		dependentLtdPlans: DS.hasMany('App.ThinDependentLtdPlan'),

		basicPlan: function () {
			var plans = this.get('dependentPlans');
			var ret = null;
			if (plans) {
				plans.forEach(function (plan) {
					if (plan.get('plan.planType') === App._LifeDisabilityPlanAbstractModel.prototype.BASIC) {
						//If there is bad data, multiple plans, choose the latest one
						if (!ret || ret.get("id") < plan.get("id")) {
							ret = plan;
						}
					}
				});
			}
			return ret;
		}.property('dependentPlans', 'dependentPlans.[]', 'dependentPlans.@each.amount', 'dependentPlans.@each.plan.planType'),

		voluntaryPlan: function () {
			var plans = this.get('dependentPlans');
			var ret = null;
			if (plans) {
				plans.forEach(function (plan) {
					if (plan.get('plan.planType') === App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY) {
						//If there is bad data, multiple plans, choose the latest one
						if (!ret || ret.get("id") < plan.get("id")) {
							ret = plan;
						}
					}
				});
			}
			return ret;
		}.property('dependentPlans', 'dependentPlans.[]', 'dependentPlans.@each.amount', 'dependentPlans.@each.plan.planType'),
	});

	App.DependentLifeDisabilityEnrollment = App.ThinDependentLifeDisabilityEnrollment.extend(_LifeEOIMixin, {
		employeeLifeDisabilityEnrollment: DS.belongsTo("App.EmployeeLifeDisabilityEnrollment"),
		prevEnrollment: DS.belongsTo("App.DependentLifeDisabilityEnrollment"),
		dependentLifePlans: DS.hasMany('App.DependentLifePlan'),
		dependentAddPlans: DS.hasMany('App.DependentAddPlan'),
		dependentStdPlans: DS.hasMany('App.DependentStdPlan'),
		dependentLtdPlans: DS.hasMany('App.DependentLtdPlan'),
	});

	/* Life & Disability plan models for employee and dependent */
	App._EnrolleeLifeDisabilityPlanAbstractModel = DS.Model.extend({
		electedAmount: attr('number'),
		amount: attr('number'),
		premium: attr('string'),
		contribution: function () {
			return this.get('plan.planType') == 'basic' ? Number(0) : this.get('premium');
		}.property('plan.planType', 'premium'),
		computedPremium: function () {
			return this.get('premium');
		}.property('premium'),
		guaranteeIssue: attr('number'),

		isCoverageAmountGreaterThanGuaranteeIssue: function () {
			var guaranteeIssue = this.get('guaranteeIssue');
			if (Ember.isNone(guaranteeIssue) || isNaN(guaranteeIssue)) {
				return false;
			}
			return this.get('electedAmount') > guaranteeIssue;
		}.property('electedAmount', 'guaranteeIssue'),

		evidenceOfInsurabilityForm: function () {
			return this.get('evidenceOfInsurabilityFormDocument.url');
		}.property('evidenceOfInsurabilityFormDocument.url'),

		showGuaranteeIssueDetails: function () {
			var gi = this.get('guaranteeIssue');
			if (gi === null) {
				return false;
			}

			if (gi >= this.get('restriction.planMinAmountExceptZero')) {
				return true;
			}
			return false;
		}.property('restriction.planMinAmountExceptZero', 'guaranteeIssue'),

		restriction: function () {
			var restriction = null;
			if (this.get('enrollment.employee')) {
				restriction = this.get('plan.eeRestriction');
			}
			else {
				if (this.get('enrollment.dependent.isSpouse')) {
					restriction = this.get('plan.spRestriction');
				}
				else if (this.get('enrollment.dependent.isChild')) {
					restriction = this.get('plan.chRestriction');
				}
			}
			if (restriction && restriction.get('isFixed')) {
				this.set("amount", Number(restriction.get('maxAmount')));
			}
			return restriction;
		}.property('plan.eeRestriction', 'plan.spRestriction', 'plan.chRestriction'),
	});

	var _DependentChildTieredLifeDisabilityMixin = Ember.Mixin.create({
		isChildTiered: function () {
			return this.get('enrollment.dependent.type') === 'Child' && this.get('restriction.rateType') === 'child_tiered';
		}.property('enrollment.dependent.type', 'restriction.rateType'),
	});

	var _EnrolleeLifeDisabilityPlanGIMixin = Ember.Mixin.create({
		isOverGI: function () {
			// distinguish between a null GI (never over GI) and 0 GI (always over GI)
			var gi = this.get('guaranteeIssue');
			if (gi === null) {
				return false;
			}
			if (this.get('electedAmount')) {
				return Number(this.get('electedAmount')) > Number(gi);
			}
			return this.get('amount') && this.get('amount') > Number(gi);
		}.property('amount', 'restriction', 'guaranteeIssue'),
	});

	App.EmployeeLifePlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, {
		plan: DS.belongsTo("App.LifePlanNew"),
		enrollment: DS.belongsTo('App.EmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.EmployeeLifePlanNew'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinEmployeeLifePlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend({
		plan: DS.belongsTo("App.LifePlanNew"),
		enrollment: DS.belongsTo('App.ThinEmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	App.EmployeeAddPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, {
		enrollment: DS.belongsTo('App.EmployeeLifeDisabilityEnrollment'),
		plan: DS.belongsTo("App.AddPlan"),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.EmployeeAddPlan'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinEmployeeAddPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend({
		enrollment: DS.belongsTo('App.ThinEmployeeLifeDisabilityEnrollment'),
		plan: DS.belongsTo("App.AddPlan"),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});


	App.EmployeeStdPlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, {
		plan: DS.belongsTo("App.StdPlanNew"),
		enrollment: DS.belongsTo('App.EmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.EmployeeStdPlanNew'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinEmployeeStdPlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend({
		plan: DS.belongsTo("App.StdPlanNew"),
		enrollment: DS.belongsTo('App.ThinEmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});


	App.EmployeeLtdPlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, {
		plan: DS.belongsTo("App.LtdPlanNew"),
		enrollment: DS.belongsTo('App.EmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.EmployeeLtdPlanNew'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinEmployeeLtdPlanNew = App._EnrolleeLifeDisabilityPlanAbstractModel.extend({
		plan: DS.belongsTo("App.LtdPlanNew"),
		enrollment: DS.belongsTo('App.ThinEmployeeLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	App.DependentLifePlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, _DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.LifePlanNew"),
		enrollment: DS.belongsTo('App.DependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.DependentLifePlan'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinDependentLifePlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.LifePlanNew"),
		enrollment: DS.belongsTo('App.ThinDependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	App.DependentAddPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_DependentChildTieredLifeDisabilityMixin, _EnrolleeLifeDisabilityPlanGIMixin, {
		enrollment: DS.belongsTo('App.DependentLifeDisabilityEnrollment'),
		plan: DS.belongsTo("App.AddPlan"),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.DependentAddPlan'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinDependentAddPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_DependentChildTieredLifeDisabilityMixin, _EnrolleeLifeDisabilityPlanGIMixin, {
		enrollment: DS.belongsTo('App.ThinDependentLifeDisabilityEnrollment'),
		plan: DS.belongsTo("App.AddPlan"),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	App.DependentStdPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, _DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.StdPlanNew"),
		enrollment: DS.belongsTo('App.DependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.DependentStdPlan'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinDependentStdPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.StdPlanNew"),
		enrollment: DS.belongsTo('App.ThinDependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	App.DependentLtdPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, _DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.LtdPlanNew"),
		enrollment: DS.belongsTo('App.DependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
		prevPlan: DS.belongsTo('App.DependentLtdPlan'),
		prevPlanId: DS.attr('number'),
	});

	App.ThinDependentLtdPlan = App._EnrolleeLifeDisabilityPlanAbstractModel.extend(_EnrolleeLifeDisabilityPlanGIMixin, _DependentChildTieredLifeDisabilityMixin, {
		plan: DS.belongsTo("App.LtdPlanNew"),
		enrollment: DS.belongsTo('App.ThinDependentLifeDisabilityEnrollment'),
		evidenceOfInsurabilityFormDocument: DS.belongsTo('App.Document'),
	});

	/* Employee life & disability selections */
	var _EnrolleeLDSelectionAbstractModel = DS.Model.extend({
		lifePlans: attr('string'),
		addPlans: attr('string'),
		stdPlans: attr('string'),
		ltdPlans: attr('string'),
	});

	App.EmployeeLifeDisabilitySelection = _EnrolleeLDSelectionAbstractModel.extend({
		employee: DS.belongsTo('App.AllEmployee'),
	});

	App.DependentLifeDisabilitySelection = _EnrolleeLDSelectionAbstractModel.extend({
		dependent: DS.belongsTo('App.Dependent'),
	});

	App.WaiverMetadata = DS.Model.extend({
		name: attr('string'),
		description: attr('string'),
		applicableDependentTypes: attr('string'),
		isDefaultValid: attr('boolean'),
		requiresCarrierInfo: attr('boolean'),

		softDeleted: attr('boolean'),
		category: attr('string'),

		isDefaultValidWord: Ember.computed(function () {
			return this.get('isDefaultValid') ? 'Yes' : 'No';
		}).property('isDefaultValid'),

		requiresCarrierInfoWord: Ember.computed(function () {
			return this.get('requiresCarrierInfo') ? 'Yes' : 'No';
		}).property('requiresCarrierInfo'),
	});

	App.CarrierWaiver = DS.Model.extend({
		groupSize: attr('string'),
		waiver: DS.belongsTo('App.WaiverMetadata'),
		carrierYearMeta: DS.belongsTo('App.CarrierYearMetadata'),
		isValid: attr('boolean'),

		historyUrl: Ember.computed(function () {
			return '/console/object_history/' + this.get('id') + '/implementation.models.CarrierWaiver';
		}).property('id'),
	});

	App.MailboxMessage = DS.Model.extend({
		message: attr('string'),
		timestamp: attr('string'),
		status: attr('number'),
		sender: attr('string'),
		isRead: Ember.computed.equal('mailStatus', 'READ'),
		mailStatus: function (key, value) {
			var MAIL_STATUS = {
				UNREAD: 1,
				READ: 2
			};

			if (value === 'UNREAD') {
				this.set('status', MAIL_STATUS.UNREAD);
			}

			if (value === 'READ') {
				this.set('status', MAIL_STATUS.READ);
			}

			return (this.get('status') === MAIL_STATUS.UNREAD) ? 'UNREAD' : 'READ';
		}.property('status'),
		safeMessage: function () {
			return Ember.String.htmlSafe(this.get('message'));
		}.property('message'),
		isNewNotificationAtFirst: function () {
			return this.get('mailStatus') === 'UNREAD';
		}.property(),
	});

	App.EmployeeHealthEnrollmentAudit = DS.Model.extend({
		employeeHealthEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
		participationRuleStatus: attr('string'),
		inStateRuleStatus: attr('string'),
	});

	App.ParticipationState = DS.Model.extend({
		/*
			Attributes
		*/
		total: attr('number'),

		qualified: attr('number'),
		qualifiedNonNewHires: attr('number'),
		qualifiedNewHires: attr('number'),

		enrolled: attr('number'),
		enrolledNonNewHires: attr('number'),
		enrolledNewHires: attr('number'),

		validDeclined: attr('number'),
		validDeclinedNonNewHires: attr('number'),
		validDeclinedNewHires: attr('number'),

		invalidDeclined: attr('number'),
		invalidDeclinedNonNewHires: attr('number'),
		invalidDeclinedNewHires: attr('number'),

		remaining: attr('number'),
		remainingNonNewHires: attr('number'),
		remainingNewHires: attr('number'),
		participationRuleName: attr('string'),

		participationRule: DS.belongsTo('App.ParticipationRule'),
		participationRuleOverride: DS.belongsTo('App.ParticipationRuleOverride'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),

		status: attr('string'),
		isFailing: attr('boolean'),
		isUnsure: attr('boolean'),
		percentEnrolled: attr('number'),

		/*
			Properties
		*/
		percentEnrolledHundred: Ember.computed(function () {
			return Math.round(this.get('percentEnrolled') * 100) / 100;
		}).property('percentEnrolled'),

		percentEnrolledHundredDisplay: Ember.computed(function () {
			return this.get('percentEnrolledHundred') + '%';
		}).property('percentEnrolled'),

		declined: Ember.computed(function () {
			return this.get('validDeclined') + this.get('invalidDeclined');
		}).property('validDeclined', 'invalidDeclined'),

		displayStatus: Ember.computed(function () {
			var isUnsure = this.get('isUnsure');
			var isFailing = this.get('isFailing');
			var anyRemaining = this.get('remainingNonNewHires') > 0;

			if (isUnsure) {
				return 'Unknown';
			} else if (!isFailing) {
				if (anyRemaining) {
					return 'Partly Passing';
				} else {
					return 'Fully Passing';
				}
			} else {
				if (anyRemaining) {
					return 'Partly Failing';
				} else {
					return 'Fully Failing';
				}
			}
		}).property('isUnsure', 'isFailing', 'remainingNonNewHires'),

		displayRemainingOutOfTotal: Ember.computed(function () {
			return this.get('remainingNonNewHires') + '/' + this.get('qualifiedNonNewHires') + ' EEs';
		}).property('remainingNonNewHires', 'qualifiedNonNewHires'),
	});

	var safeDivide = function (a, b) {
		if (!b) {
			return 0;
		}

		return a / b;
	};

	App.InStateState = DS.Model.extend({
		/*
			Attributes
		*/
		qualifiedInState: attr('number'),
		qualifiedOutOfState: attr('number'),
		qualified: attr('number'),

		enrollingInState: attr('number'),
		enrollingOutOfState: attr('number'),
		enrolling: attr('number'),

		decliningInState: attr('number'),
		decliningOutOfState: attr('number'),
		declining: attr('number'),

		inStateRule: DS.belongsTo('App.InStateRule'),
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		inStateStates: attr('string'),

		status: attr('string'),
		isFailing: attr('boolean'),
		percentInState: attr('number'),
		inStateRuleName: attr('string'),
		isByEnrolling: attr('boolean'),

		/*
			Properties
		*/
		percentInStateHundred: Ember.computed(function () {
			return Math.round(this.get('percentInState') * 10000) / 100;
		}).property('percentInState'),

		qualifiedPercentInState: Ember.computed(function () {
			var qualifiedInState = this.get('qualifiedInState');
			var qualified = this.get('qualified');

			return Math.round(safeDivide(qualifiedInState, qualified) * 100);
		}).property('qualifiedInState', 'qualified'),

		enrollingPercentInState: Ember.computed(function () {
			var enrollingInState = this.get('enrollingInState');
			var enrolling = this.get('enrolling');

			return Math.round(safeDivide(enrollingInState, enrolling) * 100);
		}).property('enrollingInState', 'enrolling'),

		numInState: Ember.computed(function () {
			return this.get('isByEnrolling') ?
				this.get('enrollingInState') :
				this.get('qualifiedInState');
		}).property('inStateRule.minStyle'),

		numOutOfState: Ember.computed(function () {
			return this.get('isByEnrolling') ?
				this.get('enrollingOutOfState') :
				this.get('qualifiedOutOfState');
		}).property('inStateRule.minStyle'),

		numOfEEs: Ember.computed(function () {
			return this.get('isByEnrolling') ?
				this.get('enrolling') :
				this.get('qualified');
		}).property('inStateRule.minStyle'),

		displayStatus: Ember.computed(function () {
			var isFailing = this.get('isFailing');
			if (!isFailing) {
				return 'Fully Passing';
			} else {
				return 'Fully Failing';
			}
		}).property('isFailing', 'remaining'),

	});

	App.SavedProposal = DS.Model.extend({
		company: DS.belongsTo('App.Company', { inverse: null }),
		lineOfCoverage: DS.attr('string'),
		showCurrentPlans: DS.attr('boolean'),
		showRenewalPlans: DS.attr('boolean'),
		showCurrentPlansInQt: DS.attr('boolean'),
		hiddenCarriers: DS.attr('string'),
		proposal: DS.attr('string'),
		effectiveDate: DS.attr('string'),
		name: DS.attr('string'),
		isActive: DS.attr('boolean', { default: true }),
		quotingContributionType: DS.attr('string'),
		quotingContributionEmployee: DS.attr('number'),
		quotingContributionDependents: DS.attr('number'),
	});

	App.State = DS.Model.extend({
		code: attr('string'),
		name: attr('string'),

		workersCompRequiredAtSize: attr('number'),
		defaultMedicalPlans: DS.hasMany('App.Plan'),
		defaultDentalPlans: DS.hasMany('App.DentalPlan'),
		defaultVisionPlans: DS.hasMany('App.VisionPlan'),

		defaultMedicalPlanNames: Ember.computed.mapByProperty('defaultMedicalPlans', 'name'),
		defaultDentalPlanNames: Ember.computed.mapByProperty('defaultDentalPlans', 'name'),
		defaultVisionPlanNames: Ember.computed.mapByProperty('defaultVisionPlans', 'name'),
	});


	App.CompositeRuleSet = DS.Model.extend({
		name: attr('string'),
		carrier: DS.belongsTo('App.Carrier', { inverse: null }),
		compositeRules: DS.hasMany('App.CompositeRule'),
		effectiveDate: attr('string'),

		effectiveDateMoment: Ember.computed(function () {
			return moment(this.get('effectiveDate'));
		}).property('effectiveDate'),

		effectiveDatePretty: Ember.computed(function () {
			return this.get('effectiveDateMoment').format("MMM D YYYY");
		}).property('effectiveDateMoment'),
	});

	App.CompositeRule = DS.Model.extend({
		effectiveDate: attr('string'),

		lowerBound: attr('number'),
		upperBound: attr('number'),
		ratingStyle: attr('string'),

		lowerBoundNum: Ember.computed.number('lowerBound'),
		upperBoundNum: Ember.computed.number('upperBound'),

		effectiveDateMoment: Ember.computed(function () {
			return moment(this.get('effectiveDate'));
		}).property('effectiveDate'),

		effectiveDatePretty: Ember.computed(function () {
			return this.get('effectiveDateMoment').format("MMM D YYYY");
		}).property('effectiveDateMoment'),

		ratingStylePretty: Ember.computed(function () {
			var ratingStyle = this.get('ratingStyle');

			return {
				'composite': 'Composite Rated',
				'member': 'Member Level Rated',
			}[ratingStyle];
		}).property('ratingStyle'),
	});

	App.CompositeFactorSet = DS.Model.extend({
		name: attr('string'),
		carrier: DS.belongsTo('App.Carrier', { inverse: null }),
		compositeFactors: DS.hasMany('App.CompositeFactor'),
		youFactor: attr('number'),
		youAndSpouseFactor: attr('number'),
		youAndChildFactor: attr('number'),
		familyFactor: attr('number'),
		effectiveDate: attr('string'),

		effectiveDateMoment: Ember.computed(function () {
			return moment(this.get('effectiveDate'));
		}).property('effectiveDate'),

		effectiveDatePretty: Ember.computed(function () {
			return this.get('effectiveDateMoment').format("MMM D YYYY");
		}).property('effectiveDateMoment'),
	});

	App.CompositeFactor = DS.Model.extend({
		effectiveDate: attr('string'),

		youFactor: attr('number'),
		youAndSpouseFactor: attr('number'),
		youAndChildFactor: attr('number'),
		familyFactor: attr('number'),

		effectiveDateMoment: Ember.computed(function () {
			return moment(this.get('effectiveDate'));
		}).property('effectiveDate'),

		effectiveDatePretty: Ember.computed(function () {
			return this.get('effectiveDateMoment').format("MMM D YYYY");
		}).property('effectiveDateMoment'),
	});

	App.RatedDependent = DS.Model.extend({
		parent: DS.belongsTo('App.RatedDependent'),

		employee: DS.belongsTo('App.AllEmployee'),
		dependent: DS.belongsTo('App.Dependent'),

		age: attr('number'),
		zipCode: attr('string'),
		type: attr('string'),
		gender: attr('string'),
	});

	App.CompositeCensus = DS.Model.extend({
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		ratedDependents: DS.hasMany('App.RatedDependent'),
		compositeRates: DS.hasMany('App.CompositeRate'),
	});

	App.CompositeRate = DS.Model.extend({
		companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		type: attr('string'),

		youPremium: attr('number'),
		youAndSpousePremium: attr('number'),
		youAndChildPremium: attr('number'),
		familyPremium: attr('number'),
	});

	App.EnrollmentWaiverOverride = DS.Model.extend({
		reason: attr('string'),
		baseWaiver: DS.belongsTo('App.EnrollmentWaiver'),

		waiveReason: DS.belongsTo('App.WaiverMetadata'),
		otherReason: attr('string'),
		otherCarrier: attr('string'),
		otherIDNumber: attr('string'),
		idCardUrl: attr('string'),
	});

	App.ParticipationRuleOverride = DS.Model.extend({
		companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
		reason: attr('string'),

		minPercentage: attr('number'),
		minEmployees: attr('number'),
		isStrict: attr('boolean'),
		softDeleted: attr('boolean'),

		waiversAcceptedPretty: Ember.computed(function () {
			return this.get('isStrict') ? 'No' : 'Yes';
		}).property('isStrict'),

		description: Ember.computed(function () {
			var minNumber = this.get('minEmployees');
			var minPercent = this.get('minPercentage');
			var waiversAccepted = !this.get('isStrict');

			var ret = '';

			if (minNumber && minPercent) {
				ret += ('%@% participation, or %@ '.fmt(minPercent, minNumber) + (minNumber == 1 ? 'employee' : 'employees'));
			} else if (minNumber) {
				ret += ('%@ '.fmt(minNumber) + (minNumber == 1 ? 'employee' : 'employees'));
			} else if (minPercent) {
				ret += '%@% participation'.fmt(minPercent);
			} else {
				ret += 'Unknown';
			}

			if (waiversAccepted) {
				ret += ', with valid waivers accepted';
			} else {
				ret += ', valid waivers NOT accepted';
			}
			return ret;
		}).property('minEmployees', 'minPercentage', 'isStrict'),
	});

	App.CheParticipationCoreData = DS.Model.extend({
		validCarrierWaivers: DS.hasMany('App.WaiverMetadata'),
		employeeWaivers: DS.hasMany('App.EnrollmentWaiver'),
		employeeEnrollments: DS.hasMany('App.EmployeeHealthEnrollment'),

		participationRule: DS.belongsTo('App.ParticipationRule'),
		participationRuleOverride: DS.belongsTo('App.ParticipationRuleOverride'),

		validCarrierWaiverIds: Ember.computed.mapBy('validCarrierWaivers', 'id'),

		employeeWaiversPlusIsValid: Ember.computed(function () {
			// Need to determine isValid here because each employeeWaiver lacks the information to judge its own validity.
			return this.get('employeeWaivers').map(function (employeeWaiver) {
				var overrideOrWaiver = employeeWaiver.get('override') || employeeWaiver;
				return {
					overrideOrWaiver: overrideOrWaiver,
					isValid: this.get('validCarrierWaiverIds').contains(overrideOrWaiver.get('waiveReason.id')),
					employeeWaiver: employeeWaiver,
				};
			}.bind(this));
		}).property('employeeWaivers.@each.waiveReason.id', 'employeeWaivers.@each.override', 'validCarrierWaiversIds'),
	});

	App.PayrollDiffActionLog = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: DS.attr('string'),
		token: DS.attr('string'),
		modified: DS.attr('string')
	});

	App.EmployeeDeductionContext = DS.Model.extend({
		employeeNumber: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		fullName: attr('string'),
		photoUrl: attr('string'),
		payFrequency: attr('string'),
		nWeeklyAsNWeekly: attr('boolean'),
		isInThirdOrFifthPayPeriod: attr('boolean'),
		nextCheckDate: attr('string'),
		currentPayPeriod: attr(),
		deductionLines: attr(),
		optedInLines: attr(),
		catchUpLines: attr(),
		doNotPushLines: attr(),
		mismatchedLines: attr(),
		catchUpContribLines: attr(),
		doNotPushContribLines: attr(),
		mismatchedContribLines: attr(),
		matchedLines: attr(),
		unmatchedLines: attr(),
	});

	///////////////////////////////////////////////////////////////////////////////
	// Zenefits Payroll Models Start
	///////////////////////////////////////////////////////////////////////////////

	App.ZPayrollPayPeriod = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		startDate: DS.attr('string'),
		endDate: DS.attr('string'),
		zpPayPeriodSettings: DS.belongsTo('App.ZPayrollPayPeriodSettings'),
		payPeriodSettings: Ember.computed.alias('zpPayPeriodSettings'),

		// if same month: Monthly Payroll for Jan 1-30
		// if different year: Monthly Payroll for Dec 16 2015-Jan 1 2016
		formattedDates: Ember.computed('startDate', 'endDate', 'zpPayPeriodSettings.payFrequencyDisplayName', function () {
			var startDate = this.get('startDate');
			var endDate = this.get('endDate');
			var startDateObject = moment(startDate, 'MM/DD/YYYY');
			var endDateObject = moment(endDate, 'MM/DD/YYYY');
			var payFrequency = this.get('zpPayPeriodSettings.payFrequencyDisplayName');
			var isSameYear = (startDateObject.year() === endDateObject.year());
			var isSameMonth = (startDateObject.month() === endDateObject.month());
			var formattedStartDate, formattedEndDate;
			if (isSameYear && isSameMonth) {
				formattedStartDate = startDateObject.format("MMM D");
				formattedEndDate = endDateObject.format("D");
			} else if (isSameYear && !isSameMonth) {
				formattedStartDate = startDateObject.format("MMM D");
				formattedEndDate = endDateObject.format("MMM D");
			} else {
				formattedStartDate = startDateObject.format("MMM D YYYY");
				formattedEndDate = endDateObject.format("MMM D YYYY");
				return "%@-%@".fmt(formattedStartDate, formattedEndDate);
			}
			return "%@-%@".fmt(formattedStartDate, formattedEndDate);
		}),

		displayName: Ember.computed('formattedDates', function () {
			return "Payroll for %@".fmt(this.get('formattedDates'));
		}),
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollPayPeriodSettings = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		_payFrequency: DS.attr('string'),
		companyPaySchedule: DS.belongsTo('App.CompanyPaySchedule'),
		anchorStartDate: DS.attr('string'),
		_arrearsDays: DS.attr('number'),
		_arrearsDaysType: DS.attr('string'),
		_holidayShift: DS.attr('string'),
		blockPeriod: DS.attr('number'),
		isCruiseControlOn: DS.attr('boolean'),
		isCruiseControlOnChangedBySystem: DS.attr('boolean'),

		// TODO(Peter): should use enum route when ready
		payFrequencyDisplayName: function () {
			var nameMap = {
				'BW': 'Every two weeks',
				'SM': 'Twice a month',
				'Mo': 'Monthly',
				'We': 'Weekly',
			};
			var payFrequency = this.get('_payFrequency');
			return nameMap[payFrequency] || payFrequency;
		}.property('_payFrequency')
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanySuspension = DS.Model.extend({
		status: DS.attr('string'),
		suspensionReason: DS.attr('string'),
		zpCompany_id: attr('number'),
	});

	App.ZPayrollRun = DS.Model.extend({
		version_id: attr('number'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		zpPayPeriod: DS.belongsTo('App.ZPayrollPayPeriod'),
		zprEmployees: DS.hasMany('App.ZPayrollRunEmployee'),
		zpLedgerEntries: DS.hasMany('App.ZPayrollLedgerEntry'),
		zPayrollRunTaxes: DS.hasMany('zPayrollRunTax'),
		zPayrollRunSubTaxes: DS.hasMany('zPayrollRunSubTax'),
		payrollRunState: DS.attr('string'),
		approvalDeadline: DS.attr('string'),
		approvedDate: DS.attr('string'),
		checkDate: DS.attr('string'),
		effectiveBlockPeriod: DS.attr('number'),
		overrideBlockPeriod: DS.attr('number'),
		originalCheckDate: DS.attr('string'),
		description: DS.attr('string'),
		approver: DS.belongsTo('App.AllEmployee'),
		isSupplementalFlatRate: DS.attr('boolean'),
		offCyclePayFrequency: DS.attr('string'),
		paystubNotification: DS.attr('string'),
		shouldIncludeDeductions: DS.attr('boolean'),

		totalCost: attr('number'),
		totalEmployeeDeduction: attr('number'),
		totalEmployeeTax: attr('number'),
		totalEmployerTax: attr('number'),
		totalGrossPay: attr('number'),
		totalNetPay: attr('number'),
		totalNetWages: attr('number'),
		totalReimbursement: attr('number'),
		totalGarnishment: attr('number'),
		totalTax: attr('number'),
		totalTaxPaidByEmployer: attr('number'),
		totalWithdrawalAmount: attr('number'),
		totalEstimatedWithdrawalAmount: attr('number'),
		totalPayableGarnishment: attr('number'),

		totalDDNetPay: attr('number'),
		totalDDNetWages: attr('number'),
		totalDDReimbursement: attr('number'),

		totalTaxCreditApplied: attr('number'),
		totalDebitAchAmount: attr('number'),
		totalNetPayAchAmount: attr('number'),
		totalTaxAchAmount: attr('number'),
		totalWithTaxAchAmount: attr('number'),
		totalUITaxAchAmount: attr('number'),

		totalCovid19TaxDeferralAmount: attr('number'),
		totalCovid19TaxCredit: attr('number'),
		totalEligibleCovid19TaxCredit: attr('number'),
		totalEligibleCovid19LeaveTaxCredit: attr('number'),
		totalEligibleCovid19EmployeeRetentionTaxCredit: attr('number'),
		totalEligibleCovid19EmployeeRetentionMarchTaxCredit: attr('number'),
		totalEligibleCovid19CobraTaxCredit: attr('number'),

		batches: DS.hasMany('App.ZPayrollBatch'),
		isOffCycle: DS.attr('boolean'),
		isCorrection: DS.attr('boolean'),
		isLate: DS.attr('boolean'),
		lastReminderEvent: DS.attr('string'),
		payrollType: DS.attr('string'),
		payrollSubType: DS.attr('string'),

		payPeriod: Ember.computed.alias('zpPayPeriod'),
		runEmployees: Ember.computed.alias('zprEmployees'),
		state: Ember.computed.alias('payrollRunState'),

		isDraft: Ember.computed.equal('payrollRunState', 'draft'),
		isClosed: Ember.computed.equal('payrollRunState', 'closed'),
		isRegular: Ember.computed.equal('payrollType', 'REG'),
		isHistorical: Ember.computed.equal('payrollType', 'INIT'),

		isAmendmentAdjustment: Ember.computed('payrollType', function () {
			var type = this.get('payrollType');
			return ['AMENDMENT', 'ADJUSTMENT'].contains(type);
		}),
		isCorrectionRequestPayRun: Ember.computed(
			'payrollType',
			function () {
				return this.get('payrollType') === 'CORRECTION_REQUEST';
			}),
		isThirdPartySickPay: Ember.computed('payrollSubType', function () {
			return this.get('payrollSubType') === 'THIRD_PARTY_SICK_PAY';
		}),

		approvalDeadlineDateTime: Ember.computed(
			'approvalDeadline',
			'isAllSelectedEmployeesPaidByCheck',
			function () {
				const approvalDeadlineDate = this.get('approvalDeadline');
				const approvalDeadlineTimeHour = this.get('isAllSelectedEmployeesPaidByCheck') ? 17 : 14;
				return moment.tz(approvalDeadlineDate, 'America/Los_Angeles').hour(approvalDeadlineTimeHour).second(59);
			}),

		unapprovalDeadlineDateTime: Ember.computed(
			'approvalDeadline',
			'isAllSelectedEmployeesPaidByCheck',
			function () {
				const approvalDeadlineDate = this.get('approvalDeadline');
				return moment.tz(approvalDeadlineDate, 'America/Los_Angeles').hour(14).second(59);
			}),

		isAllSelectedEmployeesPaidByCheck: Ember.computed(
			'zprEmployees.@each.isPaidByCheck',
			'zprEmployees.@each.isIncluded',
			function () {
				const includedEmployees = this.get('zprEmployees').filterBy('isIncluded');
				return Ember.isEmpty(includedEmployees) ? false : includedEmployees.isEvery('isPaidByCheck');
			}),

		employeesIncluded: Ember.computed('zprEmployees.@each.isIncluded', function () {
			return this.get('zprEmployees').filterBy('isIncluded');
		}),

		// console
		zpCompanyName: DS.attr('string'),

		displayName: function () {
			if (this.get('isOffCycle') || this.get('isCorrection') || this.get('isAmendmentAdjustment')) {
				return this.get('zpCompany.company.isContractorPaymentsCompany') ? this.get('description').replace('Payroll run', 'Payment') :
					this.get('description');
			} else if (this.get('isHistorical')) {
				return "Historical Payroll - Check Date %@".fmt(this.get('checkDate'));
			} else {
				return this.get('zpCompany.company.isContractorPaymentsCompany') ? this.get('payPeriod.displayName').replace('Payroll', 'Payment') :
					this.get('payPeriod.displayName');
			}
		}.property('payPeriod.displayName', 'description', 'isHistorical'),

		// see: https://app.asana.com/0/41971119922149/53022550966646
		// change unapprovable_preview to 'Preview'
		formattedPayrollRunState: function () {
			var payrollRunState = this.get('payrollRunState');
			if (payrollRunState === 'unapprovable_preview') {
				return 'Preview';
			} else if (payrollRunState === 'transactions_failed') {
				return 'Transactions Failed';
			}
			return payrollRunState;
		}.property('payrollRunState')
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompany = DS.Model.extend({
		version_id: attr('number'),
		company_id: attr('number'),
		company: DS.belongsTo('App.Company'),
		zpCompanyBankAccount: DS.belongsTo('App.ZPayrollCompanyBankAccount'),
		blockPeriod: DS.attr('number', { defaultValue: 4 }),
		cruiseControlPayPeriodSettings: DS.belongsTo('App.ZPayrollPayPeriodSettings'),
		zpCompanyJurisdictions: DS.hasMany('App.ZPayrollCompanyJurisdiction'),
		zpCompanyBenefitsSurvey: DS.belongsTo('App.ZPayrollCompanyBenefitsSurvey'),
		zpCompanyImplementation: DS.belongsTo('App.ZPayrollCompanyImplementation'),
		onboardingState: DS.attr('string', { defaultValue: 'SETUP' }),
		bulkUploadUrl: DS.attr('string'),
		status: DS.attr('string'),
		suspensionReason: DS.attr('string'),
		hasPriorPayroll: DS.attr('boolean'),
		onboardedForNewYearStart: DS.attr('raw'),
		joinWaitlist: DS.attr('boolean'),
		priorPayrollProvider: DS.attr('string'),
		signatory: DS.belongsTo('App.AllEmployee'),
		signatory_id: DS.attr('number'),
		signatoryTitle: DS.attr('string'),
		name: DS.attr('string'),
		billingType: DS.attr('string'),
		billingCycleType: DS.attr('string'),
		creditLimit: DS.attr('number'),
		isCreditLimitExceeded: DS.attr('boolean'),
		numOfEmployees: attr('number'),
		shiftedAnchorCheckDate: DS.attr('string'),
		overtimeCalculationMethod: DS.attr('string'),
		isContractorPaymentsCompany: DS.attr('boolean'),
		isDcpPayrollOptedIn: DS.attr('boolean'),
		isInSelfDrivingMode: Ember.computed.equal('billingType', 'SELF_DRIVING'),
		isSetup: Ember.computed.equal('onboardingState', 'SETUP'),
		isPending: Ember.computed.equal('onboardingState', 'PENDING'),
		isInactive: Ember.computed.equal('onboardingState', 'INACTIVE'),
		isComplete: Ember.computed.equal('onboardingState', 'COMPLETE'),
		isPendingOrPostponed: function () {
			return this.get('isPending') || (this.get('isComplete') && this.get('suspensionReason') == 'POSTPONE');
		}.property('isPending', 'isComplete', 'suspensionReason'),

		isPypOnboardingPendingOrComplete: function () {
			return this.get('onboardingState') == 'PENDING' || this.get('onboardingState') == 'COMPLETE';
		}.property('onboardingState'),

		// used in onboarding to determine setup flow steps, cps black out dates, copy in pending mode, etc
		startInNextYear: function () {
			return this.get('onboardedForNewYearStart') && moment().quarter() === 4;
		}.property('onboardedForNewYearStart'),

	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanyBankAccount = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		accountHolderName: DS.attr('string'),
		bankAccountNumber: DS.attr('string'),
		maskedAccountNumber: DS.attr('string'),
		hasTokenizedAccountNumber: DS.attr('boolean'),
		bankRoutingNumber: DS.attr('string'),
		decryptedBankAccountNumber: DS.attr('string'),
		decryptedBankRoutingNumber: DS.attr('string'),
		bankAccountVerification_id: DS.attr('number'),
		bankAccountStatementVerificationStatus: DS.attr('string'),
		voidCheckUrl: DS.attr('string'),
		voidCheckUploadedOn: attr('string'),
		additionalProofUrl: DS.attr('string'),
		additionalProofUploadedOn: attr('string'),
		verificationType: DS.attr('string'),
		status: DS.attr('string'),
		isVerified: DS.attr('boolean'),
		isPlaidVerified: DS.attr('boolean'),
		hasFailed: DS.attr('boolean'),
		isWaiting: DS.attr('boolean'),
		voidCheckVerified: DS.attr('string'),
		overrideVerification: DS.attr('boolean'),
		isVerificationTypePlaid: Ember.computed.equal('verificationType', 'P'),
		isVerificationTypeTest: Ember.computed.equal('verificationType', 'T'),
		verificationTypeDisplay: function () {
			var overrideVerification = this.get('overrideVerification');
			var verificationType = this.get('verificationType');
			return overrideVerification ? 'Override'
				: Ember.isEmpty(verificationType) ? '-'
					: verificationType == 'P' ? 'Plaid'
						: verificationType == 'T' ? 'Test Deposits'
							: 'Unknown';
		}.property('verificationType', 'overrideVerification'),
		plaidOrTestDepositVerificationStatus: function () {
			var overrideVerification = this.get('overrideVerification');
			var isVerified = this.get('isVerified');
			var hasFailed = this.get('hasFailed');
			return overrideVerification ? 'Override'
				: hasFailed ? 'Fail'
					: isVerified ? 'Pass'
						: 'More Info Needed';
		}.property('isVerified', 'hasFailed', 'overrideVerification'),
		voidCheckUploadedOnHuman: function () {
			return this.get("voidCheckUploadedOn") ? moment(this.get("voidCheckUploadedOn")).format("MM/DD/YYYY HH:mm:ss") : null;
		}.property("voidCheckUploadedOn"),
		additionalProofUploadedOnHuman: function () {
			return this.get("additionalProofUploadedOn") ? moment(this.get("additionalProofUploadedOn")).format("MM/DD/YYYY HH:mm:ss") : null;
		}.property("additionalProofUploadedOn"),
		voidCheckUploadedOnDateHuman: function () {
			return this.get("voidCheckUploadedOn") ? moment(this.get("voidCheckUploadedOn")).format("MM/DD/YYYY") : null;
		}.property("voidCheckUploadedOn"),
	});

	App.ZPayrollCompanyBenefitsSurvey = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		hasMedical: DS.attr('boolean'),
		hasDental: DS.attr('boolean'),
		hasVision: DS.attr('boolean'),
		hasADD: DS.attr('boolean'),
		hasLTD: DS.attr('boolean'),
		hasSTD: DS.attr('boolean'),
		hasLife: DS.attr('boolean'),
		hasHSA: DS.attr('boolean'),
		hasHRA: DS.attr('boolean'),
		has401k: DS.attr('boolean'),
		hasFSA: DS.attr('boolean'),
		hasPTO: DS.attr('boolean'),
		hasCommuter: DS.attr('boolean'),
		hasTA: DS.attr('boolean'),
		hasStockOption: DS.attr('boolean'),
		hasWorkersComp: DS.attr('boolean'),
		isInterestedInBOR: DS.attr('boolean'),
	});

	App.ZPayrollCompanyImplementation = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		assignedTo: DS.belongsTo('App.User'),
		assignedToFullName: function () {
			var first = this.get('assignedTo.first_name');
			var last = this.get('assignedTo.last_name');
			return (first || '') + ' ' + (last || '');
		}.property('assignedTo.first_name', 'assignedTo.last_name'),
		isStartAsap: DS.attr('boolean'),
		status: DS.attr('string'),
		notes: DS.attr('string'),
		bankingVerificationStatus: DS.attr('string'),
		bankAccountStatementVerification: DS.attr('string'),
		companyVerificationStatus: DS.attr('string'),
		experianVerification: DS.attr('string'),
		experianAddressVerification: DS.attr('string'),
		experianActiveBusinessIndicatorVerification: DS.attr('string'),
		experianOFACMatchVerification: DS.attr('string'),
		experianCreditRiskClass: DS.attr('string'),
		experianFinancialStabilityRiskClass: DS.attr('string'),
		experianJudgements: DS.attr('string'),
		blockscoreVerification: DS.attr('string'),
		blockscoreOFACMatchVerification: DS.attr('string'),
		blockscoreRedFlags: DS.attr('string'),
		aoiCompanyNameVerification: DS.attr('string'),
		aoiPrincipalsNameVerification: DS.attr('string'),
		principalPhotoIDVerification: DS.attr('string'),
		companyAddressesVerification: DS.attr('string'),
		idologyVerification: DS.attr('string'),
		interviewee: DS.belongsTo('App.AllEmployee'),
		interviewee_id: DS.attr('number'),
		employeeAndTaxVerificationStatus: DS.attr('string'),
		employeeReviewDataVerification: DS.attr('string'),
		employeeSyncDiffsVerification: DS.attr('string'),
		poaFederalVerification: DS.attr('string'),
		poaStateVerification: DS.attr('string'),
		eftpsVerification: DS.attr('string'),
		stateRegistrationVerification: DS.attr('string'),
		priorPayrollVerificationStatus: attr('string'),
		closedQTsSubjectWagesVerification: attr('string'),
		closedQTsTotalTaxAmountVerification: attr('string'),
		currentQTEarningTaxVerification: attr('string'),
		desiredStartDate: attr('string'),
		appCreatedOn: attr('string'),
		appCompletedOn: attr('string'),
		checklistDocumentCompletedOn: attr('string'),
		employeeDataCompletedOn: attr('string'),
		idologyVerifiedOn: attr('string'),
		priorPayrollCompletedOn: attr('string'),
		pendingTaxLiabilitiesApprovedOn: attr('string'),
		implementationCompletedOn: attr('string'),
		imFieldsUpdatedOn: attr('string'),
		_toHumanReadableTimestamp: function (key) {
			return this.get(key) ? moment(this.get(key)).format("MM/DD/YYYY HH:mm:ss") : null;
		},
		_toHumanReadableDate: function (key) {
			return this.get(key) ? moment(this.get(key)).format("MM/DD/YYYY") : null;
		},
		appCreatedOnDateHuman: function () {
			return this._toHumanReadableDate('appCreatedOn');
		}.property("appCreatedOn"),
		appCompletedOnDateHuman: function () {
			return this._toHumanReadableDate('appCompletedOn');
		}.property("appCompletedOn"),
		appCompletedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('appCompletedOn');
		}.property("appCompletedOn"),
		desiredStartDateHuman: function () {
			return this._toHumanReadableDate('desiredStartDate');
		}.property('desiredStartDate'),
		imFieldsUpdatedOnDateHuman: function () {
			return this._toHumanReadableDate('imFieldsUpdatedOn');
		}.property("imFieldsUpdatedOn"),
		imFieldsUpdatedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('imFieldsUpdatedOn');
		}.property("imFieldsUpdatedOn"),
		implementationCompletedOnDateHuman: function () {
			return this._toHumanReadableDate('implementationCompletedOn');
		}.property("implementationCompletedOn"),
		implementationCompletedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('implementationCompletedOn');
		}.property("implementationCompletedOn"),
		checklistDocumentCompletedOnHuman: function () {
			return this._toHumanReadableDate('checklistDocumentCompletedOn');
		}.property("checklistDocumentCompletedOn"),
		employeeDataCompletedOnDateHuman: function () {
			return this._toHumanReadableDate('employeeDataCompletedOn');
		}.property("employeeDataCompletedOn"),
		employeeDataCompletedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('employeeDataCompletedOn');
		}.property("employeeDataCompletedOn"),
		idologyVerifiedOnDateHuman: function () {
			return this._toHumanReadableDate('idologyVerifiedOn');
		}.property("idologyVerifiedOn"),
		idologyVerifiedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('idologyVerifiedOn');
		}.property("idologyVerifiedOn"),
		priorPayrollCompletedOnDateHuman: function () {
			return this._toHumanReadableDate('priorPayrollCompletedOn');
		}.property("priorPayrollCompletedOn"),
		priorPayrollCompletedOnTimestampHuman: function () {
			return this._toHumanReadableTimestamp('priorPayrollCompletedOn');
		}.property("priorPayrollCompletedOn"),
		pendingTaxLiabilitiesApprovedOnDateHuman: function () {
			return this._toHumanReadableDate('pendingTaxLiabilitiesApprovedOn');
		}.property("pendingTaxLiabilitiesApprovedOn"),
		clientLastActionOn: function () {
			var allTimestamps = [
				this.get('appCreatedOn'),
				this.get('appCompletedOn'),
				this.get('checklistDocumentCompletedOn'),
				this.get('employeeDataCompletedOn'),
				this.get('idologyVerifiedOn'),
				this.get('priorPayrollCompletedOn'),
				this.get('zpCompany.zpCompanyBankAccount.voidCheckUploadedOn')
			];
			allTimestamps = allTimestamps.filter(function (timestamp) {
				return timestamp != null;
			});
			if (Ember.isEmpty(allTimestamps)) {
				return null;
			}
			return moment.max(allTimestamps.map(function (timestamp) {
				return moment(timestamp);
			}));
		}.property('appCreatedOn', 'appCompletedOn', 'checklistDocumentCompletedOn', 'employeeDataCompletedOn', 'idologyVerifiedOn', 'priorPayrollCompletedOn', 'zpCompany.zpCompanyBankAccount.voidCheckUploadedOn'),
		clientLastActionDateHuman: function () {
			return this.get('clientLastActionOn') ? this.get('clientLastActionOn').format("MM/DD/YYYY") : null;
		}.property('clientLastActionOn'),
		clientLastActionTimestampHuman: function () {
			return this.get('clientLastActionOn') ? this.get('clientLastActionOn').format("MM/DD/YYYY HH:mm:ss") : null;
		}.property('clientLastActionOn'),
		overrideNaicsVerification: DS.attr('boolean'),
	});

	App.ZPayrollCompanyPriorPayrollDocument = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		url: DS.attr('string'),
		uploadedOn: attr('string'),
		documentName: DS.attr('string'),
		type: DS.attr('string'),
		isValid: DS.attr('boolean'),
		uploadedOnHuman: function () {
			return this.get('uploadedOn') ? moment(this.get('uploadedOn')).format("MM/DD/YYYY HH:mm:ss") : null;
		}.property('uploadedOn'),
	});

	App.ZPayrollRunBulkEditDocument = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		zPayrollRun: DS.belongsTo('App.ZPayrollRun'),
		url: DS.attr('string'),
		uploadedFileKey: DS.attr('string'),
		uploadedOn: attr('string'),
		documentName: DS.attr('string'),
		isValid: DS.attr('boolean'),
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanyDeductionType = DS.Model.extend({
		version_id: attr('number'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		category: DS.attr('string'),
		annualMax: DS.attr('number'),
		deduction: DS.attr('number'),
		isPercentage: DS.attr('boolean'),
		isGenerated: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		name: DS.attr('string'),
		shouldLock: DS.attr('boolean'),

		accountingAccounts: DS.hasMany('App.ZPayrollAccountingAccount'),

		deductionRateTypeName: function () {
			if (this.get('isPercentage')) {
				return '% of gross';
			} else {
				return 'Fixed Amount';
			}
		}.property('isPercentage')

	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanyContributionType = DS.Model.extend({
		version_id: attr('number'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		category: DS.attr('string'),
		annualMax: DS.attr('number'),
		contribution: DS.attr('number'),
		isPercentage: DS.attr('boolean'),
		isGenerated: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		name: DS.attr('string'),
		shouldLock: DS.attr('boolean'),

		accountingAccounts: DS.hasMany('App.ZPayrollAccountingAccount'),

		contributionRateTypeName: function () {
			if (this.get('isPercentage')) {
				return '% of gross';
			} else {
				return 'Fixed Amount';
			}
		}.property('isPercentage')

	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanyEarningType = DS.Model.extend({
		version_id: attr('number'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		name: DS.attr('string'),
		accountingCode: DS.attr('string'),
		category: DS.attr('string'),
		accrueTimeOff: DS.attr('boolean'),
		isSupplemental: DS.attr('boolean'),
		isIncludedInRegularRateOfPay: DS.attr('boolean'),

		isMultipleOfRegularEarning: DS.attr('boolean'),
		regularEarningMultiplier: DS.attr('number'),

		isRatePerUnit: DS.attr('boolean'),
		unitName: DS.attr('string'),
		ratePerUnit: DS.attr('number'),

		isGenerated: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		shouldLock: DS.attr('boolean'),

		accountingAccounts: DS.hasMany('App.ZPayrollAccountingAccount'),
		isCompatibleWithEmployees: DS.attr('boolean'),
		isCompatibleWithContractors: DS.attr('boolean'),

		isReimbursement: function () {
			var categories = ['allowances', 'reimbursement', 'adoption_reimbursement'];
			return categories.indexOf(this.get('category')) >= 0;
		}.property('category'),

		isCashTip: function () {
			return this.get('category') == 'cash_tips';
		}.property('category'),

		isReportedTip: function () {
			return this.get('category') == 'reported_tips';
		}.property('category'),

		isImputedPay: function () {
			var categories = ['scorp_combined', 'imputed_income_life', 'rsu_deferral', 'general_imputed_income'];
			return categories.indexOf(this.get('category')) >= 0;
		}.property('category'),

		isThirdPartySickPay: function () {
			var categories = ['tpsp_non_taxable', 'tpsp_first_six_months', 'tpsp_after_six_months'];
			return categories.indexOf(this.get('category')) >= 0;
		}.property('category'),

		rateTypeName: function () {
			// customizing for regular and holiday as they do not have isRatePerUnit set to true on creation
			// created a tech debt pypcore PYPCORE-6122
			var categories = ['regular', 'holiday'];
			if (this.get('isRatePerUnit') || categories.indexOf(this.get('category')) >= 0) {
				return 'Rate per Unit';
			} else if (this.get('isMultipleOfRegularEarning')) {
				return "Multiple of employee's Regular Earnings";
			} else {
				return 'Fixed Amount';
			}
		}.property('isRatePerUnit', 'category', 'isMultipleOfRegularEarning')
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollEmployee = DS.Model.extend({
		version_id: attr('number'),
		includeInRuns: DS.attr('boolean'),
		employee: DS.belongsTo('App.AllEmployee'),
		employee_id: attr('number'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		_zpPayPeriodSettings: DS.belongsTo('App.ZPayrollPayPeriodSettings'),
		deductions: DS.hasMany('App.ZPayrollEmployeeDeduction'),
		contributions: DS.hasMany('App.ZPayrollEmployeeContribution'),
		earnings: DS.hasMany('App.ZPayrollEmployeeEarning'),
		garnishments: DS.hasMany('App.ZPayrollEmployeeGarnishment'),
		hasEmployment: DS.attr('boolean'),
		isPaymentMethodDirectDeposit: DS.attr('boolean'),
		isSalaried: DS.attr('boolean'),
		nextPayDay: DS.attr('string'),
		nextImmediatePayDay: DS.attr('string'),
		normalEarnings: Ember.computed.filterBy('earnings', 'isReimbursement', false),
		reimbursements: Ember.computed.filterBy('earnings', 'isReimbursement', true),
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollEmployeeDeduction = DS.Model.extend({
		version_id: attr('number'),
		zpEmployee: DS.belongsTo('App.ZPayrollEmployee', { inverse: null }),
		zpCompanyDeductionType: DS.belongsTo('App.ZPayrollCompanyDeductionType'),
		_deduction: DS.attr('number'),
		_annualMax: DS.attr('number'),
		priorPayrollYTD: DS.attr('number'),
		amount: DS.attr('number'),
		category: Ember.computed.oneWay('zpCompanyDeductionType.category'),
		isPercentage: attr('boolean'),
		isSyncedFromZenefits: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		name: Ember.computed.oneWay('zpCompanyDeductionType.name'),

		deduction: function (key, value, previousValue) {
			if (arguments.length > 1) {  // Setter
				this.set('_deduction', value);
				return value;
			}
			if (Ember.isEmpty(this.get('_deduction'))) {
				return !!this.get('zpCompanyDeductionType') && this.get('zpCompanyDeductionType').get('deduction');
			} else {
				return this.get('_deduction');
			}
		}.property('_deduction', 'zpCompanyDeductionType'),

		annualMax: function (key, value, previousValue) {
			if (arguments.length > 1) {  // Setter
				this.set('_annualMax', value);
				return value;
			}
			if (Ember.isEmpty(this.get('_annualMax'))) {
				return !!this.get('zpCompanyDeductionType') && this.get('zpCompanyDeductionType').get('annualMax');
			} else {
				return this.get('_annualMax');
			}
		}.property('_annualMax', 'zpCompanyDeductionType')
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollEmployeeContribution = DS.Model.extend({
		version_id: attr('number'),
		zpEmployee: DS.belongsTo('App.ZPayrollEmployee', { inverse: null }),
		zpCompanyContributionType: DS.belongsTo('App.ZPayrollCompanyContributionType'),
		_contribution: DS.attr('number'),
		_annualMax: DS.attr('number'),
		priorPayrollYTD: DS.attr('number'),
		amount: DS.attr('number'),
		category: Ember.computed.oneWay('zpCompanyContributionType.category'),
		isPercentage: attr('boolean'),
		isActive: DS.attr('boolean'),
		isSyncedFromZenefits: DS.attr('boolean'),
		name: Ember.computed.oneWay('zpCompanyContributionType.name'),
		matchTiers: DS.hasMany('App.ZPayrollEmployeeContributionMatchTier'),

		contribution: function (key, value, previousValue) {
			if (arguments.length > 1) {  // Setter
				this.set('_contribution', value);
				return value;
			}
			if (Ember.isEmpty(this.get('_contribution'))) {
				return !!this.get('zpCompanyContributionType') && this.get('zpCompanyContributionType').get('contribution');
			} else {
				return this.get('_contribution');
			}
		}.property('_contribution', 'zpCompanyContributionType'),

		annualMax: function (key, value, previousValue) {
			if (arguments.length > 1) {  // Setter
				this.set('_annualMax', value);
				return value;
			}
			if (Ember.isEmpty(this.get('_annualMax'))) {
				return !!this.get('zpCompanyContributionType') && this.get('zpCompanyContributionType').get('annualMax');
			} else {
				return this.get('_annualMax');
			}
		}.property('_annualMax', 'zpCompanyContributionType'),

		displayMatchFormula: function () {
			var formula = this.get('matchTiers').map(
				function (tier) {
					var matchPercent = tier.get('matchPercent');
					var capPercent = tier.get('capPercent');
					return matchPercent + "% up to " + capPercent + "%";
				}
			).join(", ");

			return formula;

		}.property('matchTiers.[]', 'matchTiers.@each.capPercent', 'matchTiers.@each.matchPercent')
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollEmployeeContributionMatchTier = DS.Model.extend({
		version_id: attr('number'),
		zpEmployeeContribution: DS.belongsTo('App.ZPayrollEmployeeContribution', { inverse: null }),
		capPercent: DS.attr('number'),
		matchPercent: DS.attr('number'),
	});

	App.ZPayrollEmployeeLoa = DS.Model.extend({
		loaRequestId: DS.attr('number'),
		zprEmployeeEarning: DS.belongsTo('App.ZPayrollEmployeeEarning'),
	});

	App.ZPayrollEmployeeEarning = DS.Model.extend({
		version_id: attr('number'),
		zpEmployee: DS.belongsTo('App.ZPayrollEmployee', { inverse: null }),
		zpCompanyEarningType: DS.belongsTo('App.ZPayrollCompanyEarningType'),
		amount: DS.attr('number'),
		ratePerUnit: DS.attr('number'),
		numUnits: DS.attr('number'),
		isReimbursement: Ember.computed.oneWay('zpCompanyEarningType.isReimbursement'),
		isActive: DS.attr('boolean'),
		isSyncedFromZenefits: DS.attr('boolean'),
		zprEmployeeLoa: DS.belongsTo('App.ZPayrollEmployeeLoa'),

		canEditHourAndRate: function () {
			// Respect isRatePerUnit at company level first
			var isRatePerUnit = this.get('zpCompanyEarningType.isRatePerUnit');
			if (isRatePerUnit) { return true; }

			// False if category is regular for EEs and EE is Salaried
			var category = this.get('zpCompanyEarningType.category');
			var isSalary = this.get('zpEmployee.employee.isSalary');
			if (category === 'regular' && isSalary) { return false; }

			// False if category is regular for CWs and EE is Fixed Amount
			var isFixedAmount = this.get('zpEmployee.employee.isFixedAmount');
			if (category === 'co_regular' && isFixedAmount) { return false; }

			var categories = [
				'regular',
				'overtime',
				'double_overtime',
				'co_regular',
				'co_overtime',
				'co_double_overtime',
				'premium_overtime',
				'premium_double_overtime',
				'co_premium_overtime',
				'co_premium_double_overtime',
				'pto',
				'holiday',
				'VACATION',
				'SICK',
				'PERSONAL'
			];
			return categories.indexOf(category) >= 0;
		}.property('zpCompanyEarningType.category', 'zpEmployee.employee.isSalary', 'zpEmployee.employee.isFixedAmount'),

		unitsAndRatePerUnitsDidChange: Ember.observer(
			'isDirty',
			'canEditHourAndRate',
			'numUnits',
			'ratePerUnit',
			function () {
				if (this.get('isDirty') && this.get('canEditHourAndRate')) {
					var numUnits = this.get('numUnits') || 0;
					var ratePerUnit = this.get('ratePerUnit') || 0;
					this.set('amount', (numUnits * ratePerUnit).toFixed(2));
				}
			}),
	});

	App.ZPayrollEmployeeGarnishment = DS.Model.extend({
		version_id: attr('number'),
		zpEmployee: DS.belongsTo('App.ZPayrollEmployee', { inverse: null }),
		isActive: DS.attr('boolean'),
		category: DS.attr('string'),
		amount: DS.attr('number'),
		isAmountPercentage: DS.attr('boolean'),
		name: DS.attr('string'),
		isCourtOrdered: DS.attr('boolean'),
		recurring: DS.attr('boolean'),
		timesDeductGarnishment: DS.attr('number'),
		annualMax: DS.attr('number'),
		payPeriodMax: DS.attr('number'),
		totalAmount: DS.attr('number'),
		processing: DS.attr('string'),
		sequenceNumber: DS.attr('number'),
		amountType: DS.attr('string'),
		limitType: DS.attr('string'),
		isReconCompleted: DS.attr('boolean'),
		reconId: DS.attr('string'),
		serviceLevel: DS.attr('string'),
		questionnaires: DS.attr('raw'),
		isSystemGenerated: DS.attr('boolean'),
		displayAmount: function () {
			var isAmountPercentage = this.get('isAmountPercentage');
			var amount = parseFloat(this.get('amount'));
			return isAmountPercentage ? amount + '%' : '$' + amount.toFixed(2);
		}.property('isAmountPercentage', 'amount')
	});

	App.ZPayrollRunEmployee = DS.Model.extend({
		version_id: attr('number'),
		zPayrollRun: DS.belongsTo('App.ZPayrollRun'),
		employee: DS.belongsTo('App.AllEmployee'),
		notes: DS.attr('string'),
		isExcluded: DS.attr('boolean'),
		isPaidByCheck: DS.attr('boolean'),
		isUserModified: DS.attr('boolean'),
		isUserModifiedPaymentMethod: DS.attr('boolean'),
		employmentType: DS.attr('string'),
		compType: DS.attr('string'),

		grossPay: DS.attr('number'),
		netPay: DS.attr('number'),
		employeeDeduction: DS.attr('number'),
		companyContribution: DS.attr('number'),
		employeeTax: DS.attr('number'),
		employerTax: DS.attr('number'),
		regularEarnings: DS.attr('number'),
		zpEmployee_id: DS.attr('number'),
		taxCredits: DS.belongsTo('App.ZPayrollRunEmployeeTaxCredit'),

		paymentMethod: function () {
			if (this.get('zPayrollRun.isThirdPartySickPay')) {
				return 'Third-Party Earnings';
			}
			if (this.get('isPaidByCheck')) {
				return 'Check';
			}
			return this.get('employee.paymentMethodHelper');
		}.property('isPaidByCheck', 'employee.paymentMethodHelper', 'zPayrollRun.isThirdPartySickPay'),

		isPaidByCheckOnly: function () {
			// if the employee payment method is check, then they are only payable by check
			return this.get('employee.paymentMethodHelper') === 'Check';
		}.property('employee.paymentMethodHelper'),

		isIncluded: function (key, value) {
			if (arguments.length === 1) {
				return !this.get('isExcluded');
			} else {
				this.set('isExcluded', !value);
				return value;
			}
		}.property('isExcluded'),

		// TODO(Peter): we probably should put this in a wrapper. However, this messes
		// with the master details panel. Need to investigate
		rowStyle: function () {
			return this.get('isExcluded') ? 'et-employee-excluded' : undefined;
		}.property('isExcluded'),
		workType: Ember.computed('employmentType', 'employee.currentEmployment.employmentSubType', function () {
			var employmentType = this.get('employmentType');
			if (!employmentType) {
				employmentType = this.get('employee.currentEmployment.employmentSubType');
			}
			return {
				'FT': 'FTE', //Full Time
				'PT': 'PTE', //Part Time
				'TP': 'IN', // Legacy Intern
				'IN': 'IN', // Intern
				'AW': 'AT', //Agency-paid Temp
				'CW': 'TPW', //Company-paid Temp
				'VE': 'VE', // Vendor Employee
				'IC': 'IC', //Independent Contractor
				'VO': 'VO', //Volunteer
				'CO': 'CO', // Contingent Worker
			}[employmentType] || "N/A";
		})
	});

	App.ZPayrollRunEmployeeTaxCredit = DS.Model.extend({
		version_id: DS.attr('number'),
		uncollectedType: DS.attr('string'),
		taxType: DS.attr('string'),
		amount: DS.attr('number'),
		currentAmount: DS.attr('number'),
		effectiveEndDate: DS.attr('string'),
		zprEmployee: DS.belongsTo('App.ZPayrollRunEmployee', { inverse: null }),
	});

	App.ZPayrollJurisdictionTaxInfo = DS.Model.extend({
		jurisdiction: DS.attr('string'),
		jurisdictionName: DS.attr('string'),
		taxCategory: DS.attr('string'),
		taxCategoryName: DS.attr('string'),
		accountNumber: DS.attr('string'),
		routingNumber: DS.attr('string'),
		taxPaymentMethod: DS.attr('string'),
		taxCode: DS.attr('string'),
		amountType: DS.attr('string'),
		taxFilingMethod: DS.attr('string'),
		_commaSeparatedTaxDepositFrequency: DS.attr('string'),
		taxCollectorName: DS.attr('string'),
		taxCollectorAddress: DS.attr('string'),
		taxCollectorCity: DS.attr('string'),
		taxCollectorState: DS.attr('string'),
		taxCollectorZip: DS.attr('string'),
		taxCollectorPhone: DS.attr('string'),
		taxCollectorPayee: DS.attr('string'),
		taxCollectorWebsite: DS.attr('string'),
		taxDepositFrequencyAndTaxCodesDict: DS.attr('object'),
	});

	App.ZPayrollTaxMeta = DS.Model.extend({
		category: DS.attr('string'),
		categoryName: DS.attr('string'),
		dueFrequency: DS.attr('string'),
		earningType: DS.attr('string'),
		isActive: DS.attr('boolean'),
		jurisdiction: DS.attr('string'),
		name: DS.attr('string'),
		taxCode: DS.attr('string'),
		taxPayee: DS.attr('string'),
		type: DS.attr('string'),
		zpFilingMetas: DS.hasMany('App.ZPayrollFilingMeta'),
		isEmployerTax: Ember.computed.equal('taxPayee', 'ER'),
		isEmployeeTax: Ember.computed.equal('taxPayee', 'EE'),
	});

	App.ZPayrollFilingMeta = DS.Model.extend({
		dueFrequency: DS.attr('string'),
		isSupportedByZenefitsPayroll: DS.attr('boolean'),
		jurisdiction: DS.attr('string'),
		s3Url: DS.attr('string'),
		type: DS.attr('string'),
		zpTaxMetas: DS.hasMany('App.ZPayrollTaxMeta'),
		isLocal: DS.attr('boolean'),
		isFederal: DS.attr('boolean'),
		isState: DS.attr('boolean'),
	});

	App.ZPayrollCompanyTaxFiling = DS.Model.extend({
		dueDate: DS.attr('string'),
		endDate: DS.attr('string'),
		jurisdiction: DS.attr('string'),
		quarter: DS.attr('string'),
		startDate: DS.attr('string'),
		status: DS.attr('string'),
		dnfReason: DS.attr('string'),
		zpFilingMeta: DS.belongsTo('App.ZPayrollFilingMeta'),
		formattedStatus: Ember.computed.oneWay('status'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		description: function () {
			var type = this.get('zpFilingMeta.type');
			var jurisdiction = this.get('jurisdiction');
			var startDate = this.get('startDate');
			var endDate = this.get('endDate');
			return "%@ %@ Filing for periods covering %@ %@"
				.fmt(jurisdiction, type, startDate, endDate);
		}.property('zpFilingMeta.type', 'jurisdiction', 'startDate', 'endDate'),
		isStatusFiled: Ember.computed.equal('status', 'FILED'),
		isStatusDNF: Ember.computed.equal('status', 'DNF'),
		year: Ember.computed('startDate', function () {
			var startDate = this.get('startDate');
			return moment(startDate).format('YYYY');
		}),
	});

	App.ZPayrollLedgerEntry = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		zPayrollRun: DS.belongsTo('App.ZPayrollRun'),
		zPayrollRun_id: DS.attr('number'),
		zpCompany_id: DS.attr('number'),
		zpTaxNotice: DS.belongsTo('App.ZPayrollTaxNotice'),
		creationDate: DS.attr('string'),
		type: DS.attr('string'),
		ledgerTaxType: DS.attr('string'),
		dueDate: DS.attr('string'),
		checkDate: DS.attr('string'),
		executionDate: DS.attr('string'),
		ach_id: DS.attr('string'),
		achAmount: DS.attr('string'),
		achStatus: DS.attr('string'),
		svbPaymentStatus: DS.attr('string'),
		federalLiabilityPeriodStartDate: DS.attr('string'),
		federalLiabilityPeriodEndDate: DS.attr('string'),
		amount: DS.attr('number'),
		credit: DS.attr('number'),
		netAmount: DS.attr('number'),
		ledgerPaymentMethod: DS.attr('string'),
		ledgerPaymentReason: DS.attr('string'),
		status: DS.attr('string'),
		recipient: DS.attr('string'),
		recipientId: DS.attr('string'),
		relatedCompanyName: DS.attr('string'),
		formattedStatus: function () {
			var status = this.get('status');
			switch (status) {
				case 'P': return 'Pending';
				case 'S': return 'Sent';
				case 'F': return 'Failed';
				case 'C': return 'Completed';
				case 'X': return 'Cancelled';
			}
			return 'Unknown';
		}.property('status')
	});

	App.ZPayrollTaxNotice = DS.Model.extend({
		zpLedgerEntries: DS.hasMany('App.zPayrollLedgerEntry'),
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		zpCompanyName: DS.attr('string'),
		jurisdiction: DS.attr('string'),
		taxCategory: DS.attr('string'),
		title: DS.attr('string'),
		notes: DS.attr('string'),
		status: DS.attr('string'),
		cause: DS.attr('string'),
		year: DS.attr('number'),
		period: DS.attr('string'),
		issueDate: DS.attr('string'),
		receivedDate: DS.attr('string'),
		debitDate: DS.attr('string'),
		totalAmount: DS.attr('number'),
		penaltyAmount: DS.attr('number'),
		interestAmount: DS.attr('number'),
		taxAmount: DS.attr('number'),
		companyPenaltyAmount: DS.attr('number'),
		companyInterestAmount: DS.attr('number'),
		companyTaxAmount: DS.attr('number'),
		s3Path: DS.attr('string'),
		documentFileKey: DS.attr('string'),
		amountPaidByZenefits: DS.attr('number'),
		amountPaidByCompany: DS.attr('number'),
		zenefitsPenaltyAmount: DS.attr('number'),
		zenefitsInterestAmount: DS.attr('number'),
		zenefitsTaxAmount: DS.attr('number'),
	});

	App.ZPayrollSvbPayment = DS.Model.extend({
		type: DS.attr('string'),
		payeeName: DS.attr('string'),
		payeeNumber: DS.attr('string'),
		payeeStreet1: DS.attr('string'),
		payeeStreet2: DS.attr('string'),
		payeeCity: DS.attr('string'),
		payeeState: DS.attr('string'),
		payeeZip: DS.attr('string'),
		payeeBankName: DS.attr('string'),
		payeeBankRoutingNumber: DS.attr('string'),
		payeeBankAccountNumber: DS.attr('string'),
		payeeBankStreet1: DS.attr('string'),
		payeeBankStreet2: DS.attr('string'),
		payeeBankCity: DS.attr('string'),
		payeeBankState: DS.attr('string'),
		payeeBankZip: DS.attr('string'),
		mailCode: DS.attr('number'),

		createdMethod: DS.attr('string'),
		payDate: Ember.attr('string'),
		amount: DS.attr('number'),
		memo: DS.attr('string'),
		status: DS.attr('string'),
		referenceNumber: DS.attr('string'),
		completedDate: DS.attr('string'),
		mailedDate: DS.attr('string'),
		uploadBatch: DS.belongsTo('App.ZPayrollSvbPaymentUploadBatch'),

	});

	App.ZPayrollSvbPaymentUploadBatch = Ember.Model.extend({
		createdDate: DS.attr('string'),
		totalAmount: DS.attr('number'),
		batchName: DS.attr('string'),
		fileName: DS.attr('string'),
		type: DS.attr('string'),
		seqId: DS.attr('number'),
		status: DS.attr('string'),
	});
	App.ZPayrollCompanyJurisdiction = Ember.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		jurisdiction: DS.attr('string'),
		businessStartDate: DS.attr('string'),
		businessEndDate: DS.attr('string'),
		employmentTaxId: DS.attr('string'),
		unemploymentTaxId: DS.attr('string'),
		federalFilingType: DS.attr('string'),
		federalExemptFrom940: DS.attr('boolean'),
		legalName: DS.attr('string'),
		nameControl: DS.attr('string'),
		accessCode: DS.attr('string'),
		pinCode: DS.attr('string'),
		sic: DS.attr('string'),
		isReimbursable: DS.attr('boolean'),
		employmentTaxDepositFrequency: DS.attr('string'),
		status: DS.attr('string'),
		// Read-only array on front-end
		employmentTaxDepositFrequencyChoices: DS.attr('raw'),
		federalFilingTypeChoices: DS.attr('raw'),
		mbtTypeChoices: DS.attr('raw'),
		zpCompanyJurisdictionTaxRates: DS.hasMany('App.ZPayrollCompanyJurisdictionTaxRate'),
		zpCompanyJurisdictionTaxCategories: DS.hasMany('App.ZPayrollCompanyJurisdictionTaxCategory'),
		isFederal: Ember.computed.equal('jurisdiction', 'FED'),
		eft: DS.attr('string'),
		localTaxId: DS.attr('string'),
		businessEFileNumber: DS.attr('string'),
		uiPaymentAccountNumber: DS.attr('string'),
		mbtType: DS.attr('raw'),
		tapPassphrase: DS.attr('string'),
		authorizeZenefitsPayTax: DS.attr('boolean'),
		workersCompActCoverage: DS.attr('boolean'),
		workersCompId: DS.attr('string'),
		participationActivationCode: DS.attr('string'),
		unifiedBusinessId: DS.attr('string'),
		notFullTimeMedicalBenefitsEmployees: DS.attr('number'),
		isWorkLocationJurisdiction: DS.attr('boolean'),

		// TODO(ed): denormalize this into columns in the db
		hasLegalName: function () {
			var jurisdiction = this.get('jurisdiction');
			return ~['FED', 'MA', 'NJ'].indexOf(jurisdiction);
		}.property('jurisdiction'),

		hasNameControl: function () {
			var jurisdiction = this.get('jurisdiction');
			return ~['FED', 'MA', 'NJ'].indexOf(jurisdiction);
		}.property('jurisdiction'),

		hasAccessCode: function () {
			var jurisdiction = this.get('jurisdiction');
			return ~['AL', 'NY'].indexOf(jurisdiction);
		}.property('jurisdiction'),

		hasPinCode: function () {
			var jurisdiction = this.get('jurisdiction');
			return ~['GA', 'NJ'].indexOf(jurisdiction);
		}.property('jurisdiction'),

		suta: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'SUTA').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		sutaSc: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'SUTA_SC').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		sutaSc2: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'SUTA_SC_2').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		sutaSc3: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'SUTA_SC_3').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		emac: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'EMAC').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		sdi: function () {
			var year = moment.tz('America/Los_Angeles').year();
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('taxType', 'SDI').findBy('year', year);
		}.property('zpCompanyJurisdictionTaxRates.@each.taxType', 'zpCompanyJurisdictionTaxRates.@each.year'),

		wcTaxRatesHaveDuplicateWorkersCompClassCodes: function () {
			return this.get('zpCompanyJurisdictionTaxRates').filterBy('isDuplicate').filterBy('isWorkersComp').get('length') > 0;
		}.property('zpCompanyJurisdictionTaxRates.@each.isDuplicate', 'zpCompanyJurisdictionTaxRates.@each.isWorkersComp'),

		stateComponentKey: function () {
			var jurisdiction = this.get('jurisdiction');
			if (jurisdiction.length === 2) {
				return 'payroll/state-taxes/' + jurisdiction.toLowerCase() + '-state-tax';
			}
			return 'empty-dummy';
		}.property('jurisdiction'),
	});

	App.ZPayrollCompanyJurisdictionTaxRate = Ember.Model.extend({
		zpCompanyJurisdiction: DS.belongsTo('App.ZPayrollCompanyJurisdiction'),
		year: DS.attr('number'),
		isCurrentYear: Ember.computed.equal('year', moment().year()),
		taxType: DS.attr('string'),
		rate: DS.attr('number'),
		taxPayee: DS.attr('string'),
		isTaxPayeeEmployee: Ember.computed.equal('taxPayee', 'EE'),
		workersCompClassCode: DS.attr('string'),
		isWorkersComp: Ember.computed.equal('taxType', 'WC'),
		historicalTaxRates: attr(),
		hasFutureTaxRateRequested: DS.attr('boolean'),
		futureTaxRate: DS.attr('number'),
		futureTaxRateAvailableEffectiveDates: attr(),
		futureTaxRateEffectiveDate: attr(),
		isDuplicate: function () {
			var workersCompClassCode = this.get('workersCompClassCode');
			var year = this.get('year');
			var taxPayee = this.get('taxPayee');
			return this.get('zpCompanyJurisdiction.zpCompanyJurisdictionTaxRates').filterBy('taxPayee', taxPayee).filterBy('workersCompClassCode', workersCompClassCode).filterBy('year', year).filterBy('isWorkersComp').get('length') > 1;
		}.property('zpCompanyJurisdiction.zpCompanyJurisdictionTaxRates.@each.workersCompClassCode', 'zpCompanyJurisdiction.zpCompanyJurisdictionTaxRates.@each.year', 'year', 'workersCompClassCode', 'zpCompanyJurisdiction.zpCompanyJurisdictionTaxRates.@each.isWorkersComp'),
	});

	App.ZPayrollCompanyJurisdictionTaxCategory = Ember.Model.extend({
		zpCompanyJurisdiction: DS.belongsTo('App.ZPayrollCompanyJurisdiction'),
		taxCategory: DS.attr('string'),
		serviceLevel: DS.attr('string'),
		isSupported: DS.attr('boolean'),
	});

	App.ZPayrollAccountingOrganization = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		accountingProvider: DS.attr('string'),
		accountingOrganizationId: DS.attr('string'),
		automaticExportOn: DS.attr('boolean'),
		isBankAccountMapped: DS.attr('boolean'),
		displayName: Ember.computed('accountProvider', function () {
			var provider = this.get('accountingProvider') || '';
			if (provider == 'qbo' || provider == 'qbd') {
				return provider.toUpperCase();
			}
			else if (provider == 'sage') {
				return 'Sage Intacct'
			}
			else {
				return provider.capitalize();
			}
		}),
	});

	App.ZPayrollAccountingAccount = DS.Model.extend({
		accountingOrganization: DS.belongsTo('App.ZPayrollAccountingOrganization'),
		accountID: DS.attr('string'),
		accountName: DS.attr('string'),
		accountNumber: DS.attr('string'),
		accountType: DS.attr('string'),
		description: DS.attr('string'),
		isReferenceBankAccount: DS.attr('boolean'),
		zpCompanyOtherExpenseTypes: DS.hasMany('App.ZPayrollCompanyOtherExpenseType'),
		zpCompanyContributionTypes: DS.hasMany('App.ZPayrollCompanyDeductionType'),
		zpEmployeeDeductionTypes: DS.hasMany('App.ZPayrollCompanyDeductionType'),
		zpCompanyEarningTypes: DS.hasMany('App.ZPayrollCompanyEarningType'),

		displayName: function () {
			if (this.get('accountNumber')) {
				return this.get('accountNumber') + " - " + this.get('accountName');
			} else {
				return this.get('accountID') + " - " + this.get('accountName');
			}
		}.property('accountNumber', 'accountID', 'accountName'),
	});

	App.ZPayrollCompanyOtherExpenseType = DS.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		expenseName: DS.attr('string'),
		accountingAccounts: DS.hasMany('App.ZPayrollAccountingAccount'),
		name: DS.attr('string'),
		displayName: Ember.computed.alias('name'),
	});

	App.ZPayrollEnums = DS.Model.extend({
		type: DS.attr('string'),
		value: DS.attr('string'),
		verboseName: DS.attr('string'),
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollValidationError = Ember.Model.extend({
		errorCode: Ember.attr('string'),
		errorDescription: Ember.attr('string'),
		errorType: Ember.attr('string'),
		fields: Ember.attr('raw'), // This is immutable and the front-end will not be adding to this
		relatedModelId: Ember.attr('number'),
		relatedModelTypeKey: Ember.attr('string'),
		validatingModelId: Ember.attr('number'),
		validatingModelTypeKey: Ember.attr('string'),
		skipValidate: Ember.attr('string'),

		relatedModelType: function () {
			return DS.appStore.modelFor(this.get('relatedModelTypeKey'));
		}.property('relatedModelTypeKey'),

		validatingModelType: function () {
			return DS.appStore.modelFor(this.get('validatingModelTypeKey'));
		}.property('validatingModelTypeKey'),
	});

	App.ZPayrollOnboardingProgress = Ember.Model.extend({
		hasErrors: Ember.attr('boolean'),
		inProgress: Ember.attr('boolean'),
		isActive: Ember.attr('boolean'),
		isComplete: Ember.attr('boolean'),
		ordering: Ember.attr('number'),
		page: Ember.attr('string'),
		zpCompany: Ember.belongsTo('App.ZPayrollCompany'),
	});

	App.ZPayrollCompanyPendingProgress = Ember.Model.extend({
		state: Ember.attr('string'),
		task: Ember.attr('string'),
		zpCompany: Ember.belongsTo('App.ZPayrollCompany'),
		isHiddenToClient: Ember.attr('boolean'),

		isComplete: Ember.computed.equal('state', 'COMPLETE'),
		taskNameToComponentMap: {
			EMPLOYEE_ONBOARDING: 'pending-employee-onboarding',
			VOIDED_CHECK: 'pending-voided-check',
			PRIOR_PAYROLL: 'pending-prior-payroll',
			TAX_LIABILITIES: 'pending-tax-liabilities',
			PAY_ITEMS: 'pending-pay-items',
			TAX_SETUP: 'pending-tax-setup',
			FORMS: 'pending-forms',
			TEST_DEPOSIT: 'pending-test-deposits',
			CHECKLIST_DOCUMENTS: 'pending-checklist-documents',
		},
		componentName: function () {
			// Should not access to Company Pay Schedule since it's hidden.
			var taskNameToComponentMap = this.get('taskNameToComponentMap');
			return taskNameToComponentMap[this.get('task')];
		}.property('task'),
	}).reopenClass({
		noBatch: true
	});

	App.ZPayrollCompanyDocument = Ember.Model.extend({
		zpCompany: DS.belongsTo('App.ZPayrollCompany'),
		category: Ember.attr('string'),
		signatureDate: Ember.attr('string'),
		signature: Ember.attr('string'),
		signatureName: Ember.attr('string'),
		isSigned: Ember.attr('boolean'),
		isNotarized: Ember.attr('boolean'),
		notarizedDate: Ember.attr('string'),
		requireSignature: Ember.attr('boolean'),
		title: Ember.attr('string'),
		description: Ember.attr('string'),
		needsNotary: Ember.attr('boolean'),
		isJurisdictionDocument: Ember.attr('boolean'),
		notarizedFormUrl: Ember.attr('string'),
		isComplete: Ember.attr('boolean'),

		completionDate: function () {
			if (this.get('isSigned')) {
				return this.get('signatureDate');
			} else if (this.get('isNotarized')) {
				return this.get('notarizedDate');
			}
		}.property('isSigned', 'isNotarized', 'signatureDate', 'notarizedDate'),

		url: function () {
			if (this.get('isNotarized')) {
				return this.get('notarizedFormUrl');
			} else {
				return '/z_payroll/get_zp_company_document/' + this.get('id');
			}
		}.property('id', 'isNotarized', 'notarizedFormUrl')
	});

	App.ZPayrollBenefitsLimit = DS.Model.extend({
		category: DS.attr('string'),
		limitType: DS.attr('string'),
		employeeFilingStatus: DS.attr('string'),
		year: attr('number'),
		amount: attr('number')
	}).reopenClass({
		noBatch: true
	});

	///////////////////////////////////////////////////////////////////////////////
	// Zenefits Payroll Models End
	///////////////////////////////////////////////////////////////////////////////


	App.CompanyProductTag = DS.Model.extend({
		productName: DS.attr('string'),
		subcategory: DS.attr('string'),
		company: DS.belongsTo('App.Company'),
		mutuallyExclusiveAllowed: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		tags: DS.hasMany('App.CompanyTag'),
		contentObject: DS.attr('raw')
	});

	App.CompanyTag = DS.Model.extend({
		name: DS.attr('string'),
		description: DS.attr('string'),
		companyProductTag: DS.belongsTo('App.CompanyProductTag', { inverse: 'tags' }),
		isDisplayableToAdmin: DS.attr('boolean'),
		isOverwritableByAdmin: DS.attr('boolean'),
		displayableEmployeeGroups: DS.attr('raw'),
		isNonDeterministicTag: DS.attr('boolean'),
		isEligible: DS.attr('boolean'),
		isActive: DS.attr('boolean'),
		ruleExpressionStr: DS.attr('string'),
		isEligibilityNegatedTag: DS.attr('boolean'),
		eligibilityNegatedTag: DS.belongsTo('App.CompanyTag', { inverse: null }),
	});

	App.EmployeeTag = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee', { inverse: 'employeeTags' }),
		companyProductTag: DS.belongsTo('App.CompanyProductTag'),
		companyTag: DS.belongsTo('App.CompanyTag'),
		isActive: DS.attr('boolean'),
	});

	App.EmployeeProductEligibilityTag = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee', { inverse: 'productEligibilityTag' }),
		isEligibleForMedical: DS.attr('boolean'),
		isEligibleForDental: DS.attr('boolean'),
		isEligibleForVision: DS.attr('boolean'),
		isEligibleForHealth: DS.attr('boolean'),
		isEligibleForLife: DS.attr('boolean'),
		isEligibleForAdd: DS.attr('boolean'),
		isEligibleForSTD: DS.attr('boolean'),
		isEligibleForLTD: DS.attr('boolean'),
		isEligibleForFSA: DS.attr('boolean'),
		isEligibleForHSA: DS.attr('boolean'),
		isEligibleForTA: DS.attr('boolean'),
		isEligibleForPTO: DS.attr('boolean'),
		isEligibleForCommuterBenefits: DS.attr('boolean'),
		isEligibleForAccident: DS.attr('boolean'),
		isEligibleForCancer: DS.attr('boolean'),
		isEligibleForCriticalIllness: DS.attr('boolean'),
		isEligibleForHospitalIndemnity: DS.attr('boolean'),
	});

	App.TagsProductMeta = DS.Model.extend({
		name: DS.attr('string'),
		isProductName: DS.attr('boolean'),
		lineOfCoverage: DS.attr('string'),
		isDisplayableToAdmin: DS.attr('boolean'),
		adminDisplayName: DS.attr('string'),
		adminDisplayContext: DS.attr('raw'),
		contentObjectDetails: DS.attr('raw'),
		isActive: DS.attr('boolean'),
	});

	App.CompensationPayOut = DS.Model.extend({
		variableCompensation: DS.belongsTo('App.VariableCompensation', {
			inverse: null
		}),
		payOutEffectiveDate: DS.attr('string'),
		isPushedToPayRoll: DS.attr('boolean'),
		payOutPercent: DS.attr('string'),
		payOutAmount: DS.attr('string'),
		approvedBy: DS.belongsTo("App.AllEmployee", {
			inverse: null
		}),
		isFirstReminderSent: DS.attr('boolean'),
		isSecondReminderSent: DS.attr('boolean'),
		status: DS.attr('string'),
	});

	App.CompensationSchedule = DS.Model.extend({
		compensation: DS.belongsTo('App.VariableCompensation', {
			inverse: null
		}),
		payOutType: DS.attr('string'),
		recurrenceType: DS.attr('string'),
		onOnboardingComplete: DS.attr('boolean'),
		onFirstCheckDateHireDate: DS.attr('boolean'),
		onCheckDatePostHireDate: DS.attr('boolean'),
		postMonthValue: DS.attr('number'),
		onEveryFirstCheckDateOfMonth: DS.attr('boolean'),
		onEveryLastCheckDateOfMonth: DS.attr('boolean'),
		onEveryFirstCheckDateOfQuarter: DS.attr('boolean'),
		onEveryLastCheckDateOfQuarter: DS.attr('string'),
		onEveryFixedCheckDateIndexOfQuarter: DS.attr('number'),
		onEveryCheckDateClosestToDateOfYear: DS.attr('string'),
	});

	App.VariableCompensation = DS.Model.extend({
		employee: DS.belongsTo("App.AllEmployee", {
			inverse: null
		}),
		schedule: DS.belongsTo("App.CompensationSchedule", {
			inverse: null
		}),
		payOuts: DS.hasMany("App.CompensationPayOut", {
			inverse: null
		}),
		name: attr('string'),
		isContingent: attr('boolean'),
		amount: attr('string'),
		approverType: attr('string'),
		approver: DS.belongsTo("App.AllEmployee", {
			inverse: null
		}),
		status: attr('string'),
		payOutCreatedTill: attr('string'),
	});

	App.S3File = DS.Model.extend({
		random_key: DS.attr('string'),
		s3_key: DS.attr('string'),
		uploader: DS.belongsTo('App.AllEmployee'),
		employee: DS.belongsTo('App.AllEmployee'),
	});

	App.CustomerBillingHistory = DS.Model.extend({
		billDate: attr('date'),
		billAmount: attr('string'),
		billPdfUrl: attr('string')
	});
	App.IntuitPaySchedule = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		description: DS.attr('string'),
		code: DS.attr('string'),
		payFrequency: DS.attr('string'),
		isActive: attr('boolean')
	});

	//////////////////////////////////////
	// ACA Compliance Models Start
	//////////////////////////////////////

	App.AcaCompanySetting = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		acaCompanyFileUploads: DS.hasMany('App.AcaCompanyFileUploads'),
		acaMedicalPlanShortcuts: DS.hasMany('App.AcaMedicalPlanShortcut'),
		acaCompanyForm: DS.belongsTo('App.AcaCompanyForm'),
		affordabilityCalculationMethod: attr('string'),
		companySizeReliefStatus: attr('string'),
		groupType: attr('string'),
		initialAdminLen: attr('number'),
		initialMeasurementLen: attr('number'),
		isActive: attr('boolean'),
		isInitialMeasurementStartOnHireDate: attr('boolean'),
		isTransitionalPeriodPresent: attr('boolean'),
		largeEmployerStatus: attr('string'),
		qualifyForNonCalendarPlanRelief: attr('string'),
		stabilityLen: attr('number'),
		standardMeasurementLen: attr('number'),
		standardMeasurementStartDate: attr('string'),
		standardStabilityStartDate: attr('string'),
		status: attr('string'),
		transitionMeasurementStartDate: attr('string'),
		fullTimeAndEquivalentSize: attr('string'),
		version_id: attr('number'),
		setupPageIndex: attr('number'),
		year: attr('number'),
		dismissedSwitchToW2: attr('boolean'),
		isSetupComplete: Ember.computed.equal('status', 'c'),
		isSettingComplete: attr('boolean'),

		qualifyForNonCalendarPlanReliefHuman: function () {
			return JSON.parse(this.get('qualifyForNonCalendarPlanRelief'));
		}.property('qualifyForNonCalendarPlanRelief'),

		// For caching all loaded acaEmployees associated with current setting on front end
		loadedAcaEmployees: [],
	});

	App.AcaEmployee = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		acaCompanySetting: DS.belongsTo('App.AcaCompanySetting'),
		acaEmployeeW2Form: DS.belongsTo('App.AcaEmployeeW2Form'),
		acaEmployeeForm: DS.belongsTo('App.AcaEmployeeForm'),
		acaEmployeeCycles: DS.hasMany('App.AcaEmployeeCycle'),
		acaEmployeeMonths: DS.hasMany('App.AcaEmployeeMonth'),
		isActive: attr('boolean'),
		inactivatedByAdmin: attr('boolean'),
		version_id: attr('number'),
		first_name: attr('string'),
		last_name: attr('string'),
		fullName: attr('string'),
		socialSecurity: attr('string'),
		address: attr('string'),
		address2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		photoUrl: attr('string'),
		isFulltime: attr('boolean'),
		email: attr('string'),
		isTerminated: attr('boolean'),
		isExcluded: false,
		monthsRequiringCoverageDisplayString: 'N/A',
		monthYearDatesRequiringCoverage: [],
		// Which report year this acaEmployee belongs to
		acaCompanySettingYear: Ember.computed.alias('acaCompanySetting.year'),
		////////// The common computed properties start /////////////

		qualifiedMonthObject: function () {
			return {
				monthYearDatesRequiringCoverage: this.get('monthYearDatesRequiringCoverage'),
				monthsRequiringCoverageDisplayString: this.get('monthsRequiringCoverageDisplayString'),
			};
		}.property(
			'monthYearDatesRequiringCoverage.[]',
			'monthsRequiringCoverageDisplayString'),

		fullName: function () {
			var first = this.get('first_name');
			var last = this.get('last_name');
			return first + ' ' + last;
		}.property('first_name', 'last_name'),

		isIncluded: function (key, value) {
			if (arguments.length === 1) {
				return !this.get('isExcluded');
			} else {
				this.set('isExcluded', !value);
				return value;
			}
		}.property('isExcluded'),

		hasActiveInitialCycle: function () {
			return this.get('acaEmployeeCycles').some(function (cycle) {
				return cycle.get('isActive') && cycle.get('cycleType') === 'i';
			});
		}.property(
			'acaEmployeeCycles.[]',
			'acaEmployeeCycles.@each.cycleType',
			'acaEmployeeCycles.@each.isActive'),

		// Sorted from oldest - latest, increased sequence
		// !!Important, should only include months from `acaCompanySettingYear` for this acaEmployee.
		sortedEmployeeMonths: function () {
			var acaCompanySettingYear = this.get('acaCompanySettingYear');
			var startMonthYearDate = moment({ year: acaCompanySettingYear }).format('MM/DD/YYYY');
			var endMonthYearDate = moment({ year: acaCompanySettingYear + 1 }).format('MM/DD/YYYY');
			// If `acaCompanySettingYear` is current year, we should include acaEmployeeMonths before current month
			if (moment().get('year') === acaCompanySettingYear) {
				endMonthYearDate = moment().startOf('month').format('MM/DD/YYYY');
			}
			var acaEmployeeMonths = this.get('acaEmployeeMonths');
			// The time range is [startMonthYearDate, endMonthYearDate)
			var filteredAcaEmployeeMonths = acaEmployeeMonths.filter(function (month) {
				var monthYearDate = month.get('monthYearDate');
				return moment(monthYearDate, 'MM/DD/YYYY').isBefore(endMonthYearDate, 'MM/DD/YYYY') &&
					!moment(monthYearDate, 'MM/DD/YYYY').isBefore(startMonthYearDate, 'MM/DD/YYYY');
			});
			filteredAcaEmployeeMonths.sort(function (a, b) {
				return moment(a.get('monthYearDate'), 'MM/DD/YYYY').diff(moment(b.get('monthYearDate'), 'MM/DD/YYYY'));
			});
			return filteredAcaEmployeeMonths;
		}.property(
			'acaCompanySettingYear',
			'acaEmployeeMonths.[]',
			'acaEmployeeMonths.@each.monthYearDate'),

		wasMostRecentMonthEnrolled: function () {
			var months = this.get('sortedEmployeeMonths');
			return Ember.isEmpty(months) ? false : months.get('lastObject.wasEnrolledInCoverage');
		}.property(
			'sortedEmployeeMonths.[]',
			'sortedEmployeeMonths.@each.wasEnrolledInCoverage'),

		////////// The common computed properties end /////////////
		////////// The utils methods start /////////////
		getAverage: function (array) {
			if (Ember.isEmpty(array)) {
				return null;
			}
			var hasNaN = array.every(function (value) {
				return isNaN(parseFloat(value));
			});
			if (hasNaN) {
				return NaN;
			}
			var total = array.reduce(function (sum, entry) {
				return sum + parseFloat(entry);
			}, 0);
			return total / array.length;
		},

		summaryObjectHelper: function (months, valueKey, nameKey) {
			var resultDict = {
				passNum: 0,
				failList: [],
			};
			if (Ember.isEmpty(months)) {
				return resultDict;
			}
			months.forEach(function (month) {
				if (month.get(valueKey)) {
					resultDict['passNum'] += 1;
				} else {
					resultDict['failList'].pushObject(month.get(nameKey));
				}
			});
			return resultDict;
		},

		// Three status:
		// (1) 'na': no qualified months at all
		// (2) 'pass': passed for all qualified months
		// (3) 'XX/XX MONTHS': show how many qulified months are passed
		summaryHelper: function (resultDict, showInFractionFormat) {
			if (Ember.isEmpty(resultDict)) {
				return '-';
			}
			var passNum = resultDict['passNum'];
			var totalNum = passNum + resultDict['failList'].length;
			passNum = Math.min(12, passNum);
			totalNum = Math.min(12, totalNum);

			if (totalNum === 0) {
				return 'n/a';
			}
			var totalMonthsText = totalNum > 1 ? 'months' : 'month';
			var passMonthsText = passNum === 1 ? 'month' : 'months';
			if (passNum === totalNum) {
				return 'pass';
			}
			return showInFractionFormat ? (passNum + '/' + totalNum + ' ' + totalMonthsText) :
				(passNum + ' ' + passMonthsText);
		},

		getWorkHoursEmployeeMonths: function (acaEmployeeMonths, reportStartDate, reportLen) {
			if (Ember.isEmpty(acaEmployeeMonths)) {
				return [];
			}
			var reportEndDate = moment(reportStartDate, 'MM/DD/YYYY').add(reportLen, 'months').subtract(1, 'days').format('MM/DD/YYYY');
			var filteredAcaEmployeeMonths = acaEmployeeMonths.filter(function (month) {
				var monthYearDateObject = moment(month.get('monthYearDate'), 'MM/DD/YYYY');
				return !monthYearDateObject.isBefore(reportStartDate) && !monthYearDateObject.isAfter(reportEndDate);
			});
			filteredAcaEmployeeMonths.sort(function (a, b) {
				return moment(a.get('monthYearDate'), 'MM/DD/YYYY').diff(moment(b.get('monthYearDate'), 'MM/DD/YYYY'));
			});
			return filteredAcaEmployeeMonths;
		},

		////////// The utils methods end /////////////
		////////// The summary view methods start /////////////

		// The qualified employee month
		qualifiedEmployeeMonths: function () {
			var sortedEmployeeMonths = this.get('sortedEmployeeMonths');
			var monthYearDatesRequiringCoverage = this.get('monthYearDatesRequiringCoverage') || [];
			var filteredSortedEmployeeMonths = sortedEmployeeMonths.filter(function (month) {
				var monthYearDateInDBFormat = moment(month.get('monthYearDate'), 'MM/DD/YYYY').format('YYYY-MM-DD');
				return monthYearDatesRequiringCoverage.indexOf(monthYearDateInDBFormat) > -1;
			});
			return filteredSortedEmployeeMonths;
		}.property(
			'monthYearDatesRequiringCoverage.[]',
			'sortedEmployeeMonths.[]',
			'sortedEmployeeMonths.@each.monthYearDate'),

		summarySatisfiesAffordabilityObject: function () {
			return this.summaryObjectHelper(this.get('qualifiedEmployeeMonths'), 'satisfiesAffordability', 'monthName');
		}.property(
			'qualifiedEmployeeMonths.[]',
			'qualifiedEmployeeMonths.@each.satisfiesAffordability',
			'qualifiedEmployeeMonths.@each.monthName'),

		summarySatisfiesMinimumEffectiveObject: function () {
			return this.summaryObjectHelper(this.get('qualifiedEmployeeMonths'), 'satisfiesMinimumEffective', 'monthName');
		}.property(
			'qualifiedEmployeeMonths.[]',
			'qualifiedEmployeeMonths.@each.satisfiesMinimumEffective',
			'qualifiedEmployeeMonths.@each.monthName'),

		summarySatisfiesMinimumValueObject: function () {
			return this.summaryObjectHelper(this.get('qualifiedEmployeeMonths'), 'satisfiesMinimumValue', 'monthName');
		}.property(
			'qualifiedEmployeeMonths.[]',
			'qualifiedEmployeeMonths.@each.satisfiesMinimumValue',
			'qualifiedEmployeeMonths.@each.monthName'),

		summaryWasEnrolledInCoverageObject: function () {
			var sortedEmployeeMonths = this.get('sortedEmployeeMonths');
			return this.summaryObjectHelper(sortedEmployeeMonths, 'wasEnrolledInCoverage', 'monthName');
		}.property(
			'sortedEmployeeMonths.[]',
			'sortedEmployeeMonths.@each.monthYearDate',
			'sortedEmployeeMonths.@each.wasEnrolledInCoverage',
			'sortedEmployeeMonths.@each.monthName'),

		summarySatisfiesAffordability: function () {
			return this.summaryHelper(this.get('summarySatisfiesAffordabilityObject'), true);
		}.property('summarySatisfiesAffordabilityObject'),

		summarySatisfiesMinimumEffective: function () {
			return this.summaryHelper(this.get('summarySatisfiesMinimumEffectiveObject'), true);
		}.property('summarySatisfiesMinimumEffectiveObject'),

		summarySatisfiesMinimumValue: function () {
			return this.summaryHelper(this.get('summarySatisfiesMinimumValueObject'), true);
		}.property('summarySatisfiesMinimumValueObject'),

		summaryWasEnrolledInCoverage: function () {
			return this.summaryHelper(this.get('summaryWasEnrolledInCoverageObject'), false);
		}.property('summaryWasEnrolledInCoverageObject'),

		summarySatisfiesAffordabilityFailList: function () {
			return this.get('summarySatisfiesAffordabilityObject')['failList'];
		}.property('summarySatisfiesAffordabilityObject'),

		summarySatisfiesMinimumEffectiveFailList: function () {
			return this.get('summarySatisfiesMinimumEffectiveObject')['failList'];
		}.property('summarySatisfiesMinimumEffectiveObject'),

		summarySatisfiesMinimumValueFailList: function () {
			return this.get('summarySatisfiesMinimumValueObject')['failList'];
		}.property('summarySatisfiesMinimumValueObject'),

		summaryWasEnrolledInCoverageFailList: function () {
			return this.get('summaryWasEnrolledInCoverageObject')['failList'];
		}.property('summaryWasEnrolledInCoverageObject'),

		////////// The summary view methods end /////////////
		////////// The employee work hours view methods start /////////////

		reportStartDate: null,
		reportLen: null,

		// Make it a smaller searching pool when editing and saving the work hours | wrap sortedEmployeeMonths
		// The base months array for showinf the work hours under the measurement tab
		workHourEmployeeMonths: function () {
			var reportStartDate = this.get('reportStartDate');
			var reportLen = this.get('reportLen');
			if (Ember.isEmpty(reportStartDate) || Ember.isEmpty(reportLen)) {
				return Ember.A();
			}
			var acaEmployeeMonths = this.get('acaEmployeeMonths');
			var filteredAcaEmployeeMonths = this.getWorkHoursEmployeeMonths(acaEmployeeMonths, reportStartDate, reportLen);
			return filteredAcaEmployeeMonths;
		}.property(
			'reportStartDate',
			'reportLen',
			'acaEmployeeMonths.[]',
			'acaEmployeeMonths.@each.monthYearDate'),

		measurementWorkHoursArrayAverage: function () {
			return this.getAverage(this.get('workHourEmployeeMonths').mapBy('numHoursWorked'));
		}.property(
			'workHourEmployeeMonths.[]',
			'workHourEmployeeMonths.@each.numHoursWorked'),

		////////// The employee work hours view methods end /////////////
		////////// The employee form property start /////////////

		// ShouldFile property will determine whether we show View | Download
		// For this employee, false means 'Not Required', 'null' means it's
		// not set by api
		shouldFile: null,

		////////// The employee form property end /////////////
	});

	App.AcaEmployeeCycle = DS.Model.extend({
		acaEmployee: DS.belongsTo('App.AcaEmployee'),
		acaEmployeeCycleMonths: DS.hasMany('App.AcaEmployeeCycleMonth'),
		adminEndDate: attr('string'),
		adminStartDate: attr('string'),
		createdAt: attr('string'),
		cycleType: attr('string'),
		effectiveAdminStartDate: attr('string'),
		effectiveMeasurementEndDate: attr('string'),
		effectiveMeasurementStartDate: attr('string'),
		isActive: attr('boolean'),
		measurementEndDate: attr('string'),
		measurementMethod: attr('string'),
		measurementStartDate: attr('string'),
		periodTypeAsOfToday: attr('string'),
		stabilityEndDate: attr('string'),
		stabilityStartDate: attr('string'),
		version_id: attr('number'),

		adminEndDateObject: function () {
			var adminEndDate = moment(this.get('adminEndDate'), 'YYYY-MM-DD', true);
			return adminEndDate.isValid() ? adminEndDate.format('MM/DD/YYYY') : null;
		}.property('adminEndDate'),

		effectiveMeasurementEndDateObject: function () {
			var effectiveMeasurementEndDate = moment(this.get('effectiveMeasurementEndDate'), 'YYYY-MM-DD', true);
			return effectiveMeasurementEndDate.isValid() ? effectiveMeasurementEndDate.format('MM/DD/YYYY') : null;
		}.property('effectiveMeasurementEndDate'),

		measurementEndDateObject: function () {
			var measurementEndDate = moment(this.get('measurementEndDate'), 'YYYY-MM-DD', true);
			return measurementEndDate.isValid() ? measurementEndDate.format('MM/DD/YYYY') : null;
		}.property('measurementEndDate'),
	});

	App.AcaEmployeeMonth = DS.Model.extend({
		acaEmployeeCycleMonths: DS.hasMany('App.AcaEmployeeCycleMonth'),
		acaEmployeeMonthlyPlanOffers: DS.hasMany('App.AcaEmployeeMonthlyPlanOffer'),
		planOfferToFile: DS.belongsTo('App.AcaEmployeeMonthlyPlanOffer'),
		employee: DS.belongsTo('App.AllEmployee'),
		compensationType: attr('string'),
		excludeFromMissingPlanData: attr('boolean'),
		fromDate: attr('string'),
		isLimitedNonAssessmentPeriod: attr('boolean'),
		monthYearDate: attr('string'),
		numHoursWorked: attr('string'),
		payRate: attr('string'),
		toDate: attr('string'),
		salary: attr('string'),
		wasEnrolledInCoverage: attr('boolean'),
		version_id: attr('number'),
		hasInputError: false,
		wasFullTimeQualified: attr('boolean'), // Just for rendering
		hasQualifiedOverridden: attr('boolean'), // Just for indicating the status of _wasFullTimeQualified
		_wasFullTimeQualified: attr('string'), // Just for saving

		satisfiesAffordability: function () {
			var planOfferToFile = this.get('planOfferToFile');
			if (Ember.isEmpty(planOfferToFile)) {
				return false;
			} else {
				return planOfferToFile.get('isAffordable') && planOfferToFile.get('isForFullMonth');
			}
		}.property(
			'planOfferToFile',
			'planOfferToFile.isForFullMonth',
			'planOfferToFile.isAffordable'),

		satisfiesMinimumEffective: function () {
			var planOfferToFile = this.get('planOfferToFile');
			if (Ember.isEmpty(planOfferToFile)) {
				return false;
			} else {
				return (planOfferToFile.get('isForFullMonth') &&
					planOfferToFile.get('isMinimumEffective') &&
					planOfferToFile.get('childCoverage'));
			}
		}.property(
			'planOfferToFile',
			'planOfferToFile.isMinimumEffective',
			'planOfferToFile.isForFullMonth',
			'planOfferToFile.childCoverage'),

		satisfiesMinimumValue: function () {
			var planOfferToFile = this.get('planOfferToFile');
			if (Ember.isEmpty(planOfferToFile)) {
				return false;
			} else {
				return (planOfferToFile.get('isForFullMonth') &&
					planOfferToFile.get('isMinimumValue') &&
					planOfferToFile.get('childCoverage'));
			}
		}.property(
			'planOfferToFile',
			'planOfferToFile.isMinimumValue',
			'planOfferToFile.isForFullMonth',
			'planOfferToFile.childCoverage'),

		employeeOnlyContribution: function () {
			var planOfferToFile = this.get('planOfferToFile');
			return Ember.isEmpty(planOfferToFile) ? null : planOfferToFile.get('employeeOnlyContribution');
		}.property(
			'planOfferToFile',
			'planOfferToFile.employeeOnlyContribution'),

		planName: function () {
			var planOfferToFile = this.get('planOfferToFile');
			return Ember.isEmpty(planOfferToFile) ? null : planOfferToFile.get('planName');
		}.property(
			'planOfferToFile',
			'planOfferToFile.planName'),

		monthName: function () {
			var monthYearDate = moment(this.get('monthYearDate'), 'MM/DD/YYYY', true);
			return monthYearDate.isValid() ? monthYearDate.format('MMMM') : null;
		}.property('monthYearDate'),

		isInAdminPeriod: function () {
			return this.get('acaEmployeeCycleMonths').some(function (month) {
				return month.get('isActive') && month.get('periodType') === 'a';
			});
		}.property(
			'acaEmployeeCycleMonths.[]',
			'acaEmployeeCycleMonths.@each.periodType',
			'acaEmployeeCycleMonths.@each.isActive'),

		isInStabilityPeriod: function () {
			return this.get('acaEmployeeCycleMonths').some(function (month) {
				return month.get('isActive') && month.get('periodType') === 's';
			});
		}.property(
			'acaEmployeeCycleMonths.[]',
			'acaEmployeeCycleMonths.@each.periodType',
			'acaEmployeeCycleMonths.@each.isActive'),
	});

	App.AcaEmployeeCycleMonth = DS.Model.extend({
		acaEmployeeCycle: DS.belongsTo('App.AcaEmployeeCycle'),
		acaEmployeeMonth: DS.belongsTo('App.AcaEmployeeMonth'),
		periodType: attr('string'),
		qualifiedByHours: attr('boolean'),
		version_id: attr('number'),
		stabilityStartDate: Ember.computed.alias('acaEmployeeCycle.stabilityStartDate'),
		isActive: Ember.computed.alias('acaEmployeeCycle.isActive'),
		cycleType: Ember.computed.alias('acaEmployeeCycle.cycleType'),
		numHoursWorked: Ember.computed.alias('acaEmployeeMonth.numHoursWorked'),
		monthYearDate: Ember.computed.alias('acaEmployeeMonth.monthYearDate'),
	});

	App.AcaCompanyPlan = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
		carrierName: attr('string'),
		planUrl: attr('string'),
		isActive: attr('boolean'),
		isMinimumEffective: attr('boolean'),
		isMinimumValue: attr('boolean'),
		planEffectiveDate: attr('string'),
		planEndDate: attr('string'),
		planName: attr('string'),
		selfManaged: attr('boolean'),
		spouseCoverage: attr('string'), // Defined as `models.NullBooleanField` in backend, wrapped in api layer
		childCoverage: attr('boolean'),
		version_id: attr('number'),
		wasEditedByAdmin: attr('boolean'), // Flag that indicates whether it's populated from Zenefits' plans

		planEffectiveDateObject: function () {
			var planEffectiveDate = moment(this.get('planEffectiveDate'), 'YYYY-MM-DD', true);
			return planEffectiveDate.isValid() ? planEffectiveDate.format('MM/DD/YYYY') : null;
		}.property('planEffectiveDate'),

		planEffectiveYear: function () {
			var planEffectiveDateObject = this.get('planEffectiveDateObject');
			if (Ember.isEmpty(planEffectiveDateObject)) {
				return null;
			}
			var effectiveYear = moment(planEffectiveDateObject, 'MM/DD/YYYY').get('year');
			return effectiveYear;
		}.property('planEffectiveDateObject'),

		planEndDateObject: function () {
			var planEndDate = moment(this.get('planEndDate'), 'YYYY-MM-DD', true);
			return planEndDate.isValid() ? planEndDate.format('MM/DD/YYYY') : null;
		}.property('planEndDate'),
	});

	App.AcaEmployeeMonthlyPlanOffer = DS.Model.extend({
		acaPlan: DS.belongsTo('App.AcaCompanyPlan'),
		acaEmployeeMonth: DS.belongsTo('App.AcaEmployeeMonth'),
		employeeOnlyContribution: attr('string'),
		isAffordable: attr('boolean'),
		isForFullMonth: attr('boolean'),
		version_id: attr('number'),
		isMinimumEffective: Ember.computed.alias('acaPlan.isMinimumEffective'),
		isMinimumValue: Ember.computed.alias('acaPlan.isMinimumValue'),
		planEffectiveDate: Ember.computed.alias('acaPlan.planEffectiveDate'),
		planName: Ember.computed.alias('acaPlan.planName'),
		childCoverage: Ember.computed.alias('acaPlan.childCoverage'),
		spouseCoverage: Ember.computed.alias('acaPlan.spouseCoverage'),
		// This is important! Since we should not show plan offer with not active acaPlan attached on the UI
		isActive: Ember.computed.alias('acaPlan.isActive'),
	});

	App.AcaCompanyFileUploads = DS.Model.extend({
		acaCompanySetting: DS.belongsTo('App.AcaCompanySetting'),
		fileType: attr('string'),
		status: attr('string'),
		versionUUID: attr('string'),
		version_id: attr('number'),
		isActive: attr('boolean'),
		generatedFileDate: attr('string'),
		generatedFileUrl: attr('string'),
		uploadedFileDate: attr('string'),
		uploadedFileUrl: attr('string'),
	});

	App.AcaMedicalPlanShortcut = DS.Model.extend({
		acaCompanySetting: DS.belongsTo('App.AcaCompanySetting'),
		acaPlan: DS.belongsTo('App.AcaCompanyPlan'),
		employeeOnlyContribution: attr('string'),
		waitingPeriod: attr('string'),
		isActive: attr('boolean'),
		version_id: attr('number'),

		shortcutPlanYear: function () {
			var planYearObject = moment(this.get('acaPlan.planEffectiveDateObject'), 'MM/DD/YYYY');
			return planYearObject.isValid() ? planYearObject.get('year') : null;
		}.property('acaPlan.planEffectiveDateObject'),
	});

	App.AcaEmployeeW2Form = DS.Model.extend({
		version_id: attr('number'),
		isActive: attr('boolean'),
		employee: DS.belongsTo('App.AllEmployee'),
		year: attr('number'),
		compensation: attr('string'),
	});

	App.AcaCompanyForm = DS.Model.extend({
		acaCompanySetting: DS.belongsTo('App.AcaCompanySetting'),
		acaEmployeeForms: DS.hasMany('App.AcaEmployeeForm'),
		acaAggregatedALEMembers: DS.hasMany('App.AcaAggregatedALEMember'),
		isMemberOfAggregatedALE: attr('boolean'),
		firstMonthYearDateInAggregatedALE: attr('string'),
		lastMonthYearDateInAggregatedALE: attr('string'),
		generatePageIndex: attr('number'),
		notifyPageIndex: attr('number'),
		signature: DS.belongsTo('App.Signature'),
		isActive: attr('boolean'),
		isEFiling: attr('boolean'),
		status: attr('string'),
		version_id: attr('number'),
		generatedFileDate: attr('string'),
		generatedFileUrl: attr('string'),
		uploadedFileDate: attr('string'),
		uploadedFileUrl: attr('string'),
	});

	App.AcaEmployeeForm = DS.Model.extend({
		companyForm: DS.belongsTo('App.AcaCompanyForm'),
		acaEmployee: DS.belongsTo('App.AcaEmployee'),
		isSealed: attr('boolean'),
		isActive: attr('boolean'),
		shouldFile: attr('boolean'),
		version_id: attr('number'),
		generatedFileDate: attr('string'),
		generatedFileUrl: attr('string'),
		uploadedFileDate: attr('string'),
		uploadedFileUrl: attr('string'),
	});

	App.AcaAggregatedALEMember = DS.Model.extend({
		form: DS.belongsTo('App.AcaCompanyForm'),
		name: attr('string'),
		ein: attr('string'),
		version_id: attr('number'),
	});

	//////////////////////////////////////
	// ACA Compliance Models End
	//////////////////////////////////////

	App.CarrierAndAdminCredential = DS.Model.extend({
		carriers: attr('raw'),
		portalType: attr('string'),
		subType: attr('string'),
		linesOfCoverage: attr('raw'),
		linesOfCoverageString: attr('raw'),
		functionality: attr('raw'),
		functionalityString: attr('raw'),
		requireCommissionsCredentials: attr('boolean'),
		requireSecurityQuestions: attr('boolean'),
		requireMultiplePassword: attr('boolean'),
		url: attr('string'),
		notes: attr('string'),
		isGroupCredential: attr('boolean'),
		companyHealthCarriers: attr('raw'),
		isActive: attr('boolean'),
		verificationStatus: attr('string'),
		additionalSecurityQuestionsCount: attr('number'),
		additionalSecurityQuestions: DS.hasMany('App.AdditionalSecurityQuestion'),
		statesApplicable: attr('raw'),
		carrierId: attr('string'),
		subtypes: attr('raw'),
		chcStats: attr('raw'),
		addedGroups: attr('raw', { defaultValue: Ember.A() }),
		deletedGroups: attr('raw', { defaultValue: Ember.A() }),
		parentPortalId: attr('string'),
	});

	App.PortalCredential = DS.Model.extend({
		portal: DS.belongsTo('App.CarrierAndAdminCredential'),
		zenSSOEnabled: attr('boolean'),
		zenSSOUrl: attr('string'),
		primaryUsername: attr('string'),
		primaryPassword: attr('string'),
		additionalUsername: attr('string'),
		additionalPassword: attr('string'),
		commissionsUsername: attr('string'),
		commissionsPassword: attr('string'),
		isActive: attr('boolean'),
		parentPortal_id: attr('string'),
		url: attr('string'),
		notes: attr('string'),

	});

	App.AdditionalSecurityQuestion = DS.Model.extend({
		portal: DS.belongsTo('App.CarrierAndAdminCredential'),
		securityQuestion: attr('string'),
		securityAnswer: attr('string'),
		isActive: attr('boolean'),
		parentPortal_id: attr('string'),
	});

	App.EaCompanyHealthCarrierStat = DS.Model.extend({
		carrier: DS.belongsTo('App.Carrier'),
		companyName: attr('string'),
		company_id: attr('string'),
		lineOfCoverage: attr('string'),
		lineOfCoverageId: attr('string'),
	});


	App.EmployeeBenefitsInfo = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),

		isTwoPercentOwner: attr('boolean'),
		isLlcMember: attr('boolean'),
		isSolePropOwner: attr('boolean'),
		isCompanyOfficer: attr('boolean'),
		isLlpMember: attr('boolean'),
		isPartner: attr('boolean'),
		isSingleMemberLLC: attr('boolean'),
	});

	App.EmployeeRelationship = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		relatedEmployee: DS.belongsTo('App.AllEmployee'),
		relationship: attr('string'),
		isActive: attr('boolean'),
	});

	App.ContactSupportItem = DS.Model.extend({
		problem: attr('string'),
		problemDescription: attr('string'),
		firstLevelDefinition: attr('string'),
		secondLevelDefinition: attr('string'),
		rank: attr('number'),
		totalRequests: attr('number'),
		emailRequests: attr('number'),
		phoneRequests: attr('number'),
		helpCenterLinks: DS.hasMany('App.HelpCenterLink'),
		view: attr('string'),
	});

	App.HelpCenterLink = DS.Model.extend({
		contactSupportItem: DS.belongsTo('App.ContactSupportItem'),
		title: attr('string'),
		link: attr('string'),
	});

	App.PageHelpArticle = DS.Model.extend({
		pageRoute: attr('string'),
		title: attr('string'),
		tags: attr('string'),
		searchKeyword: attr('string'),
		helpArticleInfo: DS.hasMany('App.HelpArticleInfo'),
	});

	App.HelpArticleInfo = DS.Model.extend({
		pageHelpArticle: DS.belongsTo('App.PageHelpArticle'),
		link: attr('string'),
		title: attr('string'),
	});

	App.CaseSearchResult = DS.Model.extend({
		case: DS.belongsTo('App.SupportCase'),
		case_sf_id: attr('string'),
		_score: attr('number'),
		date: attr('date'),
		link: attr('string'),
		title: attr('string'),
		text: attr('string'),
		linked: attr('boolean'),
		upvoted: attr('boolean', { defaultValue: false }),
		downvoted: attr('boolean', { defaultValue: false }),
		model_name: attr('string'),
		model_version: attr('string'),
	});

	App.SupportCase = DS.Model.extend({
		description: attr('string'),
		origin: attr('string'),
		url: attr('string'),
		sf_id: attr('string'),
		subject: attr('string'),
		createdDate: attr('date'),
		reason: attr('string'),
		specialistSubType: attr('string'),
		suppliedEmail: attr('string'),
		type: attr('string'),
		associateSubType: attr('string'),
		searchResults: DS.hasMany('App.CaseSearchResult', { embedded: true, key: 'search_results' }),
		articles: attr()
	});

	App.ZenefitsPageSuggestion = DS.Model.extend({
		title: attr('string'),
		category: attr('string'),
		url: attr('url'),
	});

	//Inbox related models

	App.InboxAction = DS.Model.extend({
		version_id: attr('string'),

		// relationships
		attachments: DS.hasMany('App.InboxAttachment'),
		completedBy: DS.belongsTo('App.AllEmployee'),
		inboxOwners: DS.hasMany('App.InboxOwner'),
		inboxSubActions: DS.hasMany('App.InboxSubAction'),
		requester: DS.belongsTo('App.User'),
		template: DS.belongsTo('App.InboxActionTemplate'),

		// attributes
		completionDate: attr('string'),
		cannotCompleteReason: attr('string'),
		completionNotificationList: attr('string'),
		deletedReason: attr('string'),
		description: attr('string'),
		descriptionWithNamesAndVariables: attr('string'),
		dueDate: attr('string'),
		hideCannotComplete: attr('boolean'),
		internalNote: attr('string'),
		isAutomated: attr('boolean'),
		isBlocking: attr('boolean'),
		isForDashboard: attr('boolean'),
		priority: attr('string'),
		requestDate: attr('string'),
		status: attr('string'),
		title: attr('string'),
		titleWithNamesAndVariables: attr('string'),
		type: attr('string'),
		variables: attr('string'),
		viewCount: attr('number'),
		zAppId: attr('string'),
		workflowRequestId: attr('number'),
		fileUploaderCategory: attr('string'),

		// properties and functions
		subActionTypes: function () {
			return [
				'addressSubActions',
				'bankSubActions',
				'confirmationSubActions',
				'contentReviewSubActions',
				'csatSubActions',
				'signatureSubActions',
				'singleValueSubActions',
			];
		}.property(''),
		employees: function () {
			return this.get('inboxSubActions').mapBy('employee');
		}.property('inboxSubActions.@.employee'),
		oxfordCommaString: function (list) {
			if (list.length === 0) {
				return '';
			} else if (list.length === 1) {
				return list[0];
			} else if (list.length === 2) {
				return list.join(' and ');
			} else if (list.length > 2) {
				// Use the oxford commma :)
				return list.slice(0, -1).join(', ') + ', and ' + list.slice(-1);
			}
		},
		employeeNames: Ember.computed(function () {
			var employeeNames = this.get('inboxSubActions').mapBy('employee.fullName');
			return this.oxfordCommaString(employeeNames);
		}).property('inboxSubActions.@each.employee.fullName'),
		ownerNames: Ember.computed(function () {
			var ownerNames = this.get('inboxOwners').mapBy('owner.fullName');
			return this.oxfordCommaString(ownerNames);
		}).property('inboxOwners.@each.owner.fullName'),
		ownerNamesAndIds: Ember.computed(function () {
			return this.oxfordCommaString(
				this.get('inboxOwners').mapBy('owner').map(function (owner) {
					return owner.get('fullName') + ' (' + owner.get('id') + ')';
				})
			);
		}).property('inboxOwners.@each.owner.fullName', 'inboxOwners.@each.owner.id'),
		ownerIds: Ember.computed(function () {
			return this.get('inboxOwners').mapBy('owner.id');
		}).property('inboxOwners.@each.owner.id'),
		isCompleted: Ember.computed.equal('status', 'completed'),
		isSent: Ember.computed.equal('status', 'sent'),
		isCanNotBeCompleted: Ember.computed.equal('status', 'cannot_complete'),
		canBeDeleted: function () {
			return this.get('status') != 'completed' && this.get('status') != 'deleted';
		}.property('status'),
		errorMsg: attr('string'),
		variablesJson: Ember.computed(function () {
			return Ember.isEmpty(this.get('variables')) ? {} : JSON.parse(this.get('variables'));
		}).property('variables'),
		workflowRequestLink: Ember.computed(function () {
			var workflowRequestId = this.get('workflowRequestId');
			if (workflowRequestId) {
				return '<a href="/console/dashboard#/workflows/requests/' + workflowRequestId + '" target="_blank" rel="noopener noreferrer">' + workflowRequestId + '</a>';
			}
			return '';
		}),
	});

	App.InboxActionPartial = DS.Model.extend({
		id: attr('number'),
		status: attr('string'),
		viewCount: attr('number'),
		titleWithNamesAndVariables: attr('string'),
		companyName: attr('string'),
		isForDashboard: attr('boolean'),
		zAppId: attr('string'),
		type: 'task',
		requestDate: attr('string'),
	});

	App.InboxNotification = DS.Model.extend({
		id: attr('number'),
		employee_id: attr('number'),
		company_id: attr('number'),
		subject: attr('string'),
		body: attr('string'),
		to: attr('string'),
		zAppId: attr('string'),
		status: attr('string'),
		type: attr('string'),
		isArchived: Ember.computed.equal('status', 'archived'),
		created_at: attr('string'),
		updated_at: attr('string'),
	}).reopenClass({
		url: "/api/inbox_notification/yp3/list_notifications"
	});

	App.InboxOwner = DS.Model.extend({
		owner: DS.belongsTo('App.AllEmployee'),
		inboxAction: DS.belongsTo('App.InboxAction'),
	});

	App.InboxSnoozeLog = DS.Model.extend({
		version_id: attr('string'),
		inboxAction: DS.belongsTo('App.InboxAction'),
		snoozedFor: attr('number'),
		reason: attr('string'),
		snoozeDate: attr('string'),
	});

	App.InboxSubAction = DS.Model.extend({
		version_id: attr('string'),
		inboxAction: DS.belongsTo('App.InboxAction'),
		employee: DS.belongsTo('App.AllEmployee'),
		description: attr('string'),

		// *SubActions
		addressSubActions: DS.hasMany('App.AddressSubAction'),
		bankSubActions: DS.hasMany('App.BankSubAction'),
		confirmationSubActions: DS.hasMany('App.ConfirmationSubAction'),
		contentReviewSubActions: DS.hasMany('contentReviewSubAction'),
		csatSubActions: DS.hasMany('App.CsatSubAction'),
		signatureSubActions: DS.hasMany('App.SignatureSubAction'),
		singleValueSubActions: DS.hasMany('App.SingleValueSubAction'),

		totalSubActions: function () {
			return this.get('inboxAction.subActionTypes').reduce(function (total, subAction) {
				return total + this.get(subAction + '.length');
			}.bind(this), 0);
		}.property(
			'inboxAction.subActionTypes',
			'addressSubActions.length',
			'bankSubActions.length',
			'confirmationSubActions.length',
			'contentReviewSubActions.length',
			'csatSubActions.length',
			'signatureSubActions.length',
			'singleValueSubActions.length'
		),
	});

	App.SubActionMixin = Ember.Mixin.create({
		version_id: attr('string'),

		// relationships
		completedBy: DS.belongsTo('App.AllEmployee'),

		// attributes
		ctaText: attr('string'),
		description: attr('string'),
		descriptionWithNamesAndVariables: attr('string'),
		completionDate: attr('string'),
		isCompleted: attr('raw'),
		defaultValue: attr('string'),

		// functions and properties
		defaultValueJson: Ember.computed('defaultValue', function () {
			return Ember.isNone(this.get('defaultValue')) ? null : JSON.parse(this.get('defaultValue'));
		}),
		setDefaultValue: function () {
			return;
		},
		isCompletedOrCannotComplete: function () {
			return this.get('isCompleted') || this.get('inboxSubAction.inboxAction.isCanNotBeCompleted') ||
				this.get('inboxSubAction.inboxAction.isCompleted');
		}.property('isCompleted', 'inboxSubAction.inboxAction.isCanNotBeCompleted'),
	});

	App.SingleValueSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),
		choices: DS.hasMany('App.InboxSubActionChoice'),

		// attributes
		name: attr('string'),
		type: attr('string'),
		value: attr('string'),

		// functions and properties
		isTextSubAction: Ember.computed.equal('type', 'text'),
		isNumberSubAction: Ember.computed.equal('type', 'integer'),
		isDateSubAction: Ember.computed.equal('type', 'date'),
		isUrlSubAction: Ember.computed.equal('type', 'file'),
		isEmailSubAction: Ember.computed.equal('type', 'email'),
		isFloatSubAction: Ember.computed.equal('type', 'float'),
		isSsnSubAction: Ember.computed.equal('type', 'ssn'),
		isDobSubAction: Ember.computed.equal('type', 'dob'),
		isGenderSubAction: Ember.computed.equal('type', 'gender'),
		isStateSubAction: Ember.computed.equal('type', 'state'),
		isZipSubAction: Ember.computed.equal('type', 'zip'),
		isSpecialSubActionType: Ember.computed.or('isSsnSubAction', 'isDobSubAction', 'isGenderSubAction', 'isStateSubAction', 'isZipSubAction'),
		hackyValue: Ember.computed('value', function () {
			// So this is a hack due to another hack introduced in `ember-1.13-hacks.js`...
			// Yes, that's actually the name of the file.  I could sit here and try to explain further,
			// but essentially the name of this column (`value`) is the match that lit the fuse to blow
			// up the bomb.
			// There is a ticket to remove `ember-1.13-hacks.js` here:
			// 	https://jira.inside-zen.com/browse/UIINFRA-45
			// TODO (bmcgue): remove `hackyValue` once UIINFRA-45 is closed.
			return this.get('_data.value');
		}),
		choicesSelectList: Ember.computed('choices.@each.value', function () {
			var selectList = [{
				label: 'Select choice',
				value: null,
			}];
			var choices = this.get('choices').map(function (choice) {
				return {
					label: choice.get('value'),
					value: choice.get('value'),
				};
			});
			return selectList.pushObjects(choices);
		}),
		setDefaultValue: function () {
			// SingleValueSubAction.value doesn't need to be parsed, so we use `defaultValue` instead of 'defaultValueJson'
			var defaultValue = this.get('defaultValue');
			if (Ember.isNone(defaultValue)) {
				return;
			}
			if (Ember.isNone(this.get('value'))) {
				if (this.get('isDateSubAction')) {
					this.set('value', moment(defaultValue).format('MM/DD/YYYY'));
				} else {
					this.set('value', defaultValue);
				}
			}
		},
		validate: function () {
			var value = this.get('value');
			if (this.get('isNumberSubAction') || this.get('isFloatSubAction')) {
				return !isNaN(value);
			} else if (this.get('isSsnSubAction')) {
				return /\d{9}/.test(value);
			} else if (this.get('isZipSubAction')) {
				return /\d{5}/.test(value);
			} else {
				return this.get('value');
			}
		},
	});

	App.InboxSubActionChoice = DS.Model.extend({
		version_id: attr('string'),

		// relationships
		singleValueSubAction: DS.belongsTo('App.SingleValueSubAction'),

		// attributes
		value: attr('string'),
	});

	App.ConfirmationSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),

		// attributes
		value: attr('raw'), // ternary, nullable-boolean
		redirectUrl: attr('string'),
		secondaryCtaText: attr('string'),
		ctaInfo: attr('string'),
		ctaInfoJson: Ember.computed('ctaInfo', function () {
			return Ember.isEmpty(this.get('ctaInfo')) ? {} : JSON.parse(this.get('ctaInfo'));
		}),

		// functions and properties
		validate: function () {
			// make sure value isn't null iff we actually need a value
			return !this.get('secondaryCtaText') || this.get('value') !== null;
		},
	});

	App.CsatSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),

		// attributes
		rating: attr('number'),
		selectedFeedback: attr('string'),
		additionalFeedback: attr('string'),

		// functions and properties
		selectedFeedbackList: Ember.computed('selectedFeedback', {
			get: function (key) {
				var selectedFeedback = this.get('selectedFeedback');
				return Ember.isNone(selectedFeedback) ? [] : selectedFeedback.split(',');
			},
			set: function (key, value) {
				this.set('selectedFeedback', Ember.isEmpty(value) ? null : value.join());
				// The Ember docs (https://guides.emberjs.com/v1.13.0/object-model/computed-properties/#toc_setting-computed-properties)
				// say, "Note that if a value is returned from the setter, it will be cached as the property's value."
				// What they don't say is if you fail to return a value, Ember will set the property's
				// value to undefined by default
				return value;
			},
		}),
		validate: function () {
			return !!this.get('rating');
		},
	});

	App.BankSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),

		// attributes
		additionalRoutingNumber: attr('string'),
		bankAccountNumber: attr('string'),
		bankAccountType: attr('string'),
		bankBranchName: attr('string'),
		bankName: attr('string'),
		bankRoutingNumber: attr('string'),
		bankSwiftCode: attr('string'),
		country: attr('string'),
		isActive: attr('boolean'),
		priority: DS.attr('number'),

		// functions and properties
		bankAccountTypeHuman: function () {
			var bankAccountType = this.get('bankAccountType');
			if (bankAccountType == 'C') {
				return "Current/Chequing/Day-to-day/Transaction";
			} else if (bankAccountType == 'S') {
				return "Savings";
			}
			return bankAccountType;
		}.property('bankAccountType'),
		setDefaultValue: function () {
			var defaultValue = this.get('defaultValueJson');
			if (Ember.isNone(defaultValue)) {
				return;
			}
			var _this = this;
			var allAttributesAreNull = ['bankAccountNumber', 'bankRoutingNumber', 'bankAccountType'].every(function (attr) {
				return Ember.isNone(_this.get(attr));
			});
			if (allAttributesAreNull) {
				this.set('bankAccountNumber', defaultValue.bankAccountNumber);
				this.set('bankRoutingNumber', defaultValue.bankRoutingNumber);
				this.set('bankAccountType', defaultValue.bankAccountType);
			}
		},
		validate: function () {
			return this.get('bankAccountNumber') && this.get('bankAccountType') && this.get('bankRoutingNumber') && !this.get('bankRoutingNumberErrorText');
		},
	});

	App.AddressSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),

		// attributes
		street1: attr('string'),
		street2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		country: attr('string'),
		postalCode: attr('string'),

		// functions and properties
		setDefaultValue: function () {
			var defaultValue = this.get('defaultValueJson');
			if (Ember.isNone(defaultValue)) {
				return;
			}
			var _this = this;
			var allAttributesAreNull = ['street1', 'street2', 'city', 'state', 'country', 'postalCode'].every(function (attr) {
				return Ember.isNone(_this.get(attr));
			});
			if (allAttributesAreNull) {
				this.set('street1', defaultValue.street1);
				this.set('street2', defaultValue.street2);
				this.set('city', defaultValue.city);
				this.set('state', defaultValue.state);
				this.set('country', defaultValue.country);
				this.set('postalCode', defaultValue.postalCode);
			}
		},
		validate: function () {
			return this.get('street1') && this.get('city') && this.get('state') && this.get('postalCode');
		},
	});

	App.SignatureSubAction = DS.Model.extend(App.SubActionMixin, {
		// relationships
		inboxSubAction: DS.belongsTo('App.InboxSubAction'),

		// attributes
		signature: attr('string'),
		name: attr('string'),

		// functions and properties
		validate: function () {
			return this.get('signature') && this.get('name');
		},
	});

	App.InboxActionTag = DS.Model.extend({
		version_id: attr('string'),
		name: attr('string'),
		template: DS.belongsTo('App.InboxActionTemplate'),
	});

	App.InboxActionVariable = DS.Model.extend({
		version_id: attr('string'),
		employee: DS.belongsTo('App.AllEmployee'),
		inboxAction: DS.belongsTo('App.InboxAction'),
		name: attr('string'),
		perEmployee: attr('boolean'),
		value: attr('string'),
	});

	App.InboxAttachment = DS.Model.extend({
		version_id: attr('string'),
		inboxAction: DS.belongsTo('App.InboxAction'),
		filename: attr('string'),
		url: attr('string'),
	});

	//Inbox template related models

	App.InboxActionTemplate = DS.Model.extend({
		version_id: attr('string'),

		// relationships
		inboxSubActionTemplates: DS.hasMany('App.InboxSubActionTemplate'),
		tags: DS.hasMany('App.InboxActionTag'),

		// attributes
		description: attr('string'),
		isActive: attr('boolean'),
		isAutomated: attr('boolean'),
		isBlocking: attr('boolean'),
		isForDashboard: attr('boolean'),
		multiActionType: attr('string'),
		name: attr('string'),
		priority: attr('string'),
		templateDescription: attr('string'),
		title: attr('string'),
		type: attr('string'),
		variables: attr('string'),
		zAppId: attr('string'),

		// properties and functions
		tagNames: Ember.computed(function () {
			return this.get('tags').mapBy('name').join(', ');
		}).property('tags'),
		subActionTemplateTypes: function () {
			return [
				'addressSubActionTemplates',
				'bankSubActionTemplates',
				'confirmationSubActionTemplates',
				'signatureSubActionTemplates',
				'singleValueSubActionTemplates',
			];
		}.property(''),
		toInboxAction: function () {
			return {
				title: this.get('title'),
				description: this.get('description'),
				titleWithNamesAndVariables: this.get('titleWithNames'),
				descriptionWithNamesAndVariables: this.get('descriptionWithNames'),
				isAutomated: this.get('isAutomated'),
				priority: this.get('priority'),
				isForDashboard: this.get('isForDashboard'),
				isBlocking: this.get('isBlocking'),
				type: this.get('type'),
			};
		},
		ownerNames: function () {
			var multiActionType = this.get('multiActionType');
			if (multiActionType === 'employee') {
				return 'Employee Name';
			} else {
				return 'Admin User (or other admin)';
			}
		}.property('multiActionType'),
		employeeNames: function () {
			var multiActionType = this.get('multiActionType');
			if (multiActionType === 'adminMultipleEmployees') {
				return 'John Doe, Jane Doe, and John Smith';
			} else if (multiActionType === 'admin') {
				return 'Admin User';
			} else {
				return 'John Doe';
			}
		}.property('multiActionType'),
		withNames: function (titleOrDescription) {
			var employeeListKeyWord = '{{employee_list}}';
			var ownerListKeyWord = '{{owner_list}}';

			if (Ember.isNone(titleOrDescription)) {
				return '';
			}

			function replaceAll(str, find, replace) {
				find = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
				return str.replace(new RegExp(find, 'g'), replace);
			}

			titleOrDescription = replaceAll(titleOrDescription, employeeListKeyWord, this.get('employeeNames'));
			return replaceAll(titleOrDescription, ownerListKeyWord, this.get('ownerNames'));
		},
		titleWithNames: function () {
			return this.withNames(this.get('title'));
		}.property('title', 'employeeNames', 'ownerNames'),
		descriptionWithNames: function () {
			return this.withNames(this.get('description'));
		}.property('description', 'employeeNames', 'ownerNames'),
		variablesJson: Ember.computed(function () {
			return Ember.isEmpty(this.get('variables')) ? {} : JSON.parse(this.get('variables'));
		}).property('variables'),
	});

	App.InboxTemplateVariable = DS.Model.extend({
		version_id: attr('string'),
		template: DS.belongsTo('App.InboxActionTemplate'),
		name: attr('string'),
		perEmployee: attr('boolean'),
	});

	App.InboxSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxActionTemplate: DS.belongsTo('App.InboxActionTemplate'),
		description: attr('string'),

		// *SubActionTemplates
		addressSubActionTemplates: DS.hasMany('App.AddressSubActionTemplate'),
		bankSubActionTemplates: DS.hasMany('App.BankSubActionTemplate'),
		confirmationSubActionTemplates: DS.hasMany('App.ConfirmationSubActionTemplate'),
		signatureSubActionTemplates: DS.hasMany('App.SignatureSubActionTemplate'),
		singleValueSubActionTemplates: DS.hasMany('App.SingleValueSubActionTemplate'),

		totalSubActionTemplates: function () {
			return this.get('inboxActionTemplate.subActionTemplateTypes').reduce(function (total, subActionTemplate) {
				return total + this.get(subActionTemplate + '.length');
			}.bind(this), 0);
		}.property(
			'inboxActionTemplate.subActionTemplateTypes',
			'addressSubActionTemplates.length',
			'bankSubActionTemplates.length',
			'confirmationSubActionTemplates.length',
			'signatureSubActionTemplates.length',
			'singleValueSubActionTemplates.length'
		),

		toInboxSubAction: function () {
			return {
				description: this.get('description'),
			};
		},
	});

	App.SingleValueSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxSubActionTemplate: DS.belongsTo('App.InboxSubActionTemplate'),
		name: attr('string'),
		type: attr('string'),
		value: attr('string'),
		description: attr('string'),
		choices: DS.hasMany('App.InboxSubActionChoiceTemplate'),
		isTextSubAction: Ember.computed.equal('type', 'text'),
		isNumberSubAction: Ember.computed.equal('type', 'integer'),
		isDateSubAction: Ember.computed.equal('type', 'date'),
		isUrlSubAction: Ember.computed.equal('type', 'file'),
		isEmailSubAction: Ember.computed.equal('type', 'email'),
		isFloatSubAction: Ember.computed.equal('type', 'float'),
		isSsnSubAction: Ember.computed.equal('type', 'ssn'),
		isDobSubAction: Ember.computed.equal('type', 'dob'),
		isGenderSubAction: Ember.computed.equal('type', 'gender'),
		isStateSubAction: Ember.computed.equal('type', 'state'),
		isZipSubAction: Ember.computed.equal('type', 'zip'),
		isSpecialSubActionType: Ember.computed.or('isSsnSubAction', 'isDobSubAction', 'isGenderSubAction', 'isStateSubAction', 'isZipSubAction'),
		toSubAction: function () {
			return {
				name: this.get('name'),
				type: this.get('type'),
				value: this.get('value'),
				description: this.get('description'),
				descriptionWithNamesAndVariables: this.get('description'),
			};
		},
	});

	App.InboxSubActionChoiceTemplate = DS.Model.extend({
		version_id: attr('string'),
		singleValueSubActionTemplate: DS.belongsTo('App.SingleValueSubActionTemplate'),
		value: attr('string'),
		toSubActionChoice: function () {
			return {
				value: this.get('value'),
			};
		},
	});

	App.ConfirmationSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxSubActionTemplate: DS.belongsTo('App.InboxSubActionTemplate'),
		description: attr('string'),
		toSubAction: function () {
			return {
				description: this.get('description'),
				descriptionWithNamesAndVariables: this.get('description'),
			};
		},
	});

	App.BankSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxSubActionTemplate: DS.belongsTo('App.InboxSubActionTemplate'),
		description: attr('string'),
		toSubAction: function () {
			return {
				description: this.get('description'),
				descriptionWithNamesAndVariables: this.get('description'),
			};
		},
	});

	App.AddressSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxSubActionTemplate: DS.belongsTo('App.InboxSubActionTemplate'),
		description: attr('string'),
		toSubAction: function () {
			return {
				description: this.get('description'),
				descriptionWithNamesAndVariables: this.get('description'),
			};
		},
	});

	App.SignatureSubActionTemplate = DS.Model.extend({
		version_id: attr('string'),
		inboxSubActionTemplate: DS.belongsTo('App.InboxSubActionTemplate'),
		description: attr('string'),
		toSubAction: function () {
			return {
				description: this.get('description'),
				descriptionWithNamesAndVariables: this.get('description'),
			};
		},
	});

	App.InboxProductIntro = DS.Model.extend({
		employeeId: attr('string'),
	});

	// Scheduled reminder models start

	App.ScheduledReminder = DS.Model.extend({
		version_id: attr('number'),
		isActive: attr('boolean'),
		object_id: attr('number'),
		createdBy: DS.belongsTo('App.AllEmployee'),
		eventKey: attr('string'),
		relativeDelta: attr('number', { defaultValue: 0 }),
		digestEmails: attr('boolean'),
		anniversaryReminder: attr('boolean'),
		recurringReminder: attr('boolean'),
		applyRetroactively: attr('boolean', { defaultValue: false }),
		notifyOthers: attr('boolean', { defaultValue: false }),
		emailSubject: attr('string'),
		emailMessage: attr('string'),
		isDeletingCustomEmail: attr('boolean'),
		isOldWelcomeEmail: attr('boolean'),
		modelName: attr('string'),
		objectId: attr('number'),
		eventIsStartDate: Ember.computed.equal('eventKey', 'HIRE_DATE'),
		eventIsOnboardingComplete: Ember.computed.equal('eventKey', 'ONBOARDING_COMPLETE'),
		attachments: attr('string'),
		anniversaryCustomEmail: Ember.computed.alias('anniversaryReminder'),
		recurringCustomEmail: Ember.computed.alias('recurringReminder'),
		sentences: Ember.computed(function () {
			return this.get('createdBy.company.isContractorPaymentsCompany') ? {
				'HIRE_DATE': {
					'onEventDate': "on a contractor's start date",
					'anniversary': "a contractor's start date",
					'relativeDelta': "a contractor's start date"
				},
				'ONBOARDING_COMPLETE': {
					'onEventDate': "on the day contractor completes onboarding",
				},
				'CUSTOM_FIELD_VALUE': {
					'onEventDate': "on the event date",
					'anniversary': "the event date",
					'relativeDelta': "the event date"
				},
				'TERMINATION_DATE': {
					'onEventDate': "on the day contractor is terminated",
					'relativeDelta': "a contractor's termination date"
				},
				'OFFER_DOCUMENTS_SIGNED': {
					'onEventDate': "on the day contractor signs contract documents",
				},
				'BIRTHDAY': {
					'onEventDate': "on a contractor's birthday",
					'relativeDelta': "a contractor's birthday",
				},
				'AGREEMENT_END_DATE': {
					'onEventDate': "on a contractor's agreement end date",
					'relativeDelta': "a contractor's agreement end date",
				}
			} : {
				'HIRE_DATE': {
					'onEventDate': "on an employee's start date",
					'anniversary': "an employee's start date",
					'relativeDelta': "an employee's start date"
				},
				'ONBOARDING_COMPLETE': {
					'onEventDate': "on the day employee completes onboarding",
				},
				'CUSTOM_FIELD_VALUE': {
					'onEventDate': "on the event date",
					'anniversary': "the event date",
					'relativeDelta': "the event date"
				},
				'TERMINATION_DATE': {
					'onEventDate': "on the day employee is terminated",
					'relativeDelta': "an employee's termination date"
				},
				'OFFER_DOCUMENTS_SIGNED': {
					'onEventDate': "on the day employee signs offer documents",
				},
				'BIRTHDAY': {
					'onEventDate': "on an employee's birthday",
					'relativeDelta': "an employee's birthday",
				},
				'AGREEMENT_END_DATE': {
					'onEventDate': "on an employee's agreement end date",
					'relativeDelta': "an employee's agreement end date",
				},
				'LOA_REQUEST_APPROVED': {
					'onEventDate': "on an employee's LOA request approved date",
					'relativeDelta': "an employee's LOA request approved date",
				},
				'LOA_REQUEST_DATE_CHANGED': {
					'onEventDate': "on an employee's LOA request's date changed date",
					'relativeDelta': "an employee's LOA request's date changed",
				},
				'LOA_ESTIMATED_START_DATE': {
					'onEventDate': "on an employee's LOA estimated start date",
					'relativeDelta': "an employee's LOA dates",
				},
				'LOA_ESTIMATED_END_DATE': {
					'onEventDate': "on an employee's LOA estimated end date",
					'relativeDelta': "an employee's LOA dates",
				},
				'LOA_ACTUAL_START_DATE': {
					'onEventDate': "on an employee's LOA estimated start date",
					'relativeDelta': "an employee's LOA dates",
				},
				'LOA_ACTUAL_END_DATE': {
					'onEventDate': "on an employee's LOA estimated end date",
					'relativeDelta': "an employee's LOA dates",
				}
			}
		}).property('createdBy.company.isContractorPaymentsCompany'),
		titles: Ember.computed(function () {
			return this.get('createdBy.company.isContractorPaymentsCompany') ? {
				'HIRE_DATE': 'New Contract Reminder',
				'ONBOARDING_COMPLETE': 'Finished Onboarding',
				'CUSTOM_FIELD_VALUE': 'Custom Field',
				'TERMINATION_DATE': 'Contractor Terminated',
				'OFFER_DOCUMENTS_SIGNED': 'Contract Documents Signed',
				'BIRTHDAY': 'Contractor Birthday Reminder',
				'AGREEMENT_END_DATE': 'Contractor\'s Agreement End Date'
			} : {
				'HIRE_DATE': 'New Hire Reminder',
				'ONBOARDING_COMPLETE': 'Finished Onboarding',
				'CUSTOM_FIELD_VALUE': 'Custom Field',
				'TERMINATION_DATE': 'Employee Terminated',
				'OFFER_DOCUMENTS_SIGNED': 'Offer Documents Signed',
				'BIRTHDAY': 'Employee Birthday Reminder',
				'AGREEMENT_END_DATE': 'Worker\'s Agreement End Date',
				'LOA_REQUEST_APPROVED': 'Worker\'s LOA Request Approved Date',
				'LOA_REQUEST_DATE_CHANGED': 'Worker\'s LOA Request Date Changed',
				'LOA_ESTIMATED_START_DATE': 'Worker\'s LOA Estimated Start Date',
				'LOA_ESTIMATED_END_DATE': 'Worker\'s LOA Estimated End Date',
				'LOA_ACTUAL_START_DATE': 'Worker\'s LOA Actual Start Date',
				'LOA_ACTUAL_END_DATE': 'Worker\'s LOA Actual End Date',
			}
		}).property('createdBy.company.isContractorPaymentsCompany'),
		title: function () {
			var eventKey = this.get('eventKey');
			var title = this.get('titles')[eventKey];
			var customFieldId = this.get('object_id');
			return new Ember.RSVP.Promise(function (resolve) {
				if (eventKey == 'CUSTOM_FIELD_VALUE') {
					App.CustomField.find(customFieldId).then(function (customField) {
						resolve(title + ': ' + customField.get('name'));
					});
				} else {
					resolve(title);
				}
			});
		}.property('eventKey', 'titles'),
		attachmentsArray: function () {
			return JSON.parse(this.get('attachments') || '[]');
		}.property('attachments'),
		eventTimeAsString: function () {
			if (!this.get('eventKey')) {
				return 'New custom email';
			}

			var ret = "Sent ";
			if (this.get('sendOnEventDate')) {
				ret += this.get('sentences')[this.get('eventKey')]['onEventDate'];
			} else if (this.get('anniversaryCustomEmail')) {
				ret += "on the anniversary of " + this.get('sentences')[this.get('eventKey')]['anniversary'];
			} else {
				ret += this.get('absRelativeDelta') + " day(s)";
				ret += this.get('sendBeforeEventDate') ? " before" : " after";
				ret += " " + this.get('sentences')[this.get('eventKey')]['relativeDelta'];
			}
			ret += ".";
			return ret;
		}.property('anniversaryCustomEmail', 'sendOnEventDate', 'sendBeforeEventDate', 'eventKey'),
		existingCustomEmail: function () {
			return this.get('id');
		}.property('id'),
		resetAnniversaryCustomEmail: function () {
			if (!this.get('recurringCustomEmail')) {
				this.set('anniversaryCustomEmail', false);
			}
		}.observes('recurringCustomEmail'),
		customEmailRuleGroup: function (key, value) {
			if (arguments.length > 1) {
				if (value == 'before') {
					this.set('relativeDelta', this.get('relativeDelta') != 0 ? -Math.abs(this.get('relativeDelta')) : -1);
				}

				if (value == 'after') {
					this.set('relativeDelta', this.get('relativeDelta') != 0 ? Math.abs(this.get('relativeDelta')) : 1);
				}

				if (value == 'on') {
					this.set('relativeDelta', 0);
				}

				return value;
			}
			if (this.get('relativeDelta') != null && this.get('relativeDelta') != undefined && this.get('relativeDelta') === 0) {
				return 'on';
			}
			else if (this.get('relativeDelta') < 0) {
				return 'before';
			}
			else if (this.get('relativeDelta') > 0) {
				return 'after';
			}
		}.property('relativeDelta'),
		sendOnEventDate: Ember.computed.equal('customEmailRuleGroup', 'on'),
		sendBeforeEventDate: Ember.computed.equal('customEmailRuleGroup', 'before'),
		absRelativeDelta: function (key, value) {
			if (arguments.length > 1) {
				// When user clears out the absRelativeDelta field, we set relativeDelta to +1 or -1
				// This is to ensure that relativeDelta retains the 'before' or 'after' selection the user has made while still deciding on a value
				var retval = value === '' ? this.get('sendBeforeEventDate') ? -1 : 1 : this.get('sendBeforeEventDate') ? -Math.abs(value) : Math.abs(value);
				this.set('relativeDelta', retval);
				return value === '' ? value : Math.abs(value);
			}
			return Math.abs(this.get('relativeDelta'));
		}.property('relativeDelta', 'sendBeforeEventDate'),
	});

	// Scheduled reminder models end

	App.CarrierAndAdminCredentialStat = DS.Model.extend({
		portalId: attr('raw'),
	});

	App.BrokerPolicy = DS.Model.extend({
		internalName: attr('string'),
		currentPolicy: DS.belongsTo('App.BrokerPolicyCopy'),
		applicableStates: attr('string'),
	});

	App.BrokerPolicyCopy = DS.Model.extend({
		brokerPolicy: DS.belongsTo('App.BrokerPolicy'),
		title: attr('string'),
		description: attr('string'),
	});

	App.CompanyBrokerPolicy = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		brokerPolicy: DS.belongsTo('App.BrokerPolicy'),
		viewedPolicyCopy: DS.belongsTo('App.BrokerPolicyCopy'),
		requestedOverride: attr('string'),
	});

	App.CompanySection = DS.Model.extend({
		name: attr('string'),
		path: attr('string'),
		ordering: attr('number'),
	});

	App.CompanySubsection = DS.Model.extend({
		section: DS.belongsTo('App.CompanySection'),

		name: attr('string'),
		path: attr('string'),
		medical: attr('boolean'),
		dental: attr('boolean'),
		vision: attr('boolean'),
		ordering: attr('number'),
	});

	App.CompanyAttribute = DS.Model.extend({
		subsection: DS.belongsTo('App.CompanySubsection'),

		attribute: attr('string'),
		displayName: attr('string'),
	});

	App.CarrierCompanyNav = DS.Model.extend({
		stateCarrier: DS.belongsTo('App.Carrier'),
		carrier: attr('string'),
		section: DS.belongsTo('App.CompanySection'),
		subsection: DS.belongsTo('App.CompanySubsection'),
		attributes: attr('string'),

		attributeObjects: Ember.computed(function () {
			var allAttrs = App.CompanyAttribute._findAllRecordArray.toArray();

			return this.get('attributes').split(',').map(function (attrKey) {
				return allAttrs.findBy('attribute', $.trim(attrKey));
			}).filter(function (_) { return !!_; });

			// ^^^ the filter shouldn't be necessary, but metadata seeds don't
			// have the attributes, so this page won't load locally otherwise.. :(
		}).property('attributes'),

		addAttribute: function (attribute) {
			var attrs = this.get('attributes').split(',').map(function (_) { return $.trim(_); });

			if (!attrs.contains(attribute)) {
				attrs.pushObject(attribute);
				this.set('attributes', attrs.join(', '));
			}
		},

		removeAttribute: function (attribute) {
			var attrs = this.get('attributes').split(',').map(function (_) { return $.trim(_); });

			if (attrs.contains(attribute)) {
				attrs.removeObject(attribute);
				this.set('attributes', attrs.join(', '));
			}
		},
	});

	App.EnrollmentQuestion = DS.Model.extend({
		questionCopy: attr('string'),
		dropdownContent: attr('string'),
		subquestionRules: attr('string'),
		questionType: attr('string'),
		status: attr('string'),
		questionKey: attr('string'),
		dropdownContentList: function () {
			if (!this.get('dropdownContent')) {
				return null;
			}
			return JSON.parse(this.get('dropdownContent'));
		}.property('dropdownContent'),
	});

	App.CompanyEnrollmentQuestion = DS.Model.extend({
		question: DS.belongsTo('App.EnrollmentQuestion'),
		answer: attr('string'),
		companyId: attr('number'),
		isDeleted: attr('boolean'),
		answerRows: DS.hasMany('App.AnswerRow'),
		needEmptyRow: function () {
			return Ember.isEmpty(this.get('answerRows')) || !this.get('answerRows').any(function (row) { return row.get('isRowEmpty'); });
		}.property('answerRows', 'answerRows.@each', 'answerRows.@each.isRowEmpty'),
	});

	App.AnswerRow = DS.Model.extend({
		answerCells: DS.hasMany('App.AnswerCell'),
		isRowEmpty: function () {
			return !this.get('answerCells').any(function (cell) { return !Ember.isEmpty(cell.get('value')); });
		}.property('answerCells', 'answerCells.@each.value'),
	});

	App.AnswerCell = DS.Model.extend({
		columnLabel: attr('string'),
		value: attr('string'),
	});

	App.EmployeeSection = DS.Model.extend({
		name: attr('string'),
		path: attr('string'),
		ordering: attr('number'),
	});

	App.LegacyEnrollmentQuestionRule = DS.Model.extend({
		questionKey: attr('string'),
		carrierDisplayName: attr('string'),
		carrierState: attr('string'),
		lineOfCoverage: attr('string'),
	});

	App.EmployeeSubsection = DS.Model.extend({
		section: DS.belongsTo('App.EmployeeSection'),

		name: attr('string'),
		path: attr('string'),
		medical: attr('boolean'),
		dental: attr('boolean'),
		vision: attr('boolean'),
		ordering: attr('number'),
	});

	App.EmployeeAttribute = DS.Model.extend({
		subsection: DS.belongsTo('App.EmployeeSubsection'),

		attribute: attr('string'),
		displayName: attr('string'),
	});

	App.CarrierEmployeeNav = DS.Model.extend({
		stateCarrier: DS.belongsTo('App.Carrier'),
		carrier: attr('string'),
		section: DS.belongsTo('App.EmployeeSection'),
		subsection: DS.belongsTo('App.EmployeeSubsection'),
		attributes: attr('string'),

		attributeObjects: Ember.computed(function () {
			var allAttrs = App.EmployeeAttribute._findAllRecordArray.toArray();

			return this.get('attributes').split(',').map(function (attrKey) {
				return allAttrs.findBy('attribute', $.trim(attrKey));
			}).filter(function (_) { return !!_; });

			// ^^^ the filter shouldn't be necessary, but metadata seeds don't
			// have the attributes, so this page won't load locally otherwise.. :(
		}).property('attributes'),

		addAttribute: function (attribute) {
			var attrs = this.get('attributes').split(',').map(function (_) { return $.trim(_); });

			if (!attrs.contains(attribute)) {
				attrs.pushObject(attribute);
				this.set('attributes', attrs.join(', '));
			}
		},

		removeAttribute: function (attribute) {
			var attrs = this.get('attributes').split(',').map(function (_) { return $.trim(_); });

			if (attrs.contains(attribute)) {
				attrs.removeObject(attribute);
				this.set('attributes', attrs.join(', '));
			}
		},
	});

	App.SetupTodo = DS.Model.extend({
		todoKey: attr('string'),
		status: attr('string'),
		dismisses: attr('number'),
		title: attr('string'),
		description: attr('string'),
		sectionKey: attr('string'),
		icon: attr('string'),
		angle: attr('string'),
		order: attr('number'),
		complete: function () {
			this.set('status', 'COMPLETE');
			return this.save();
		},
		start: function () {
			this.set('status', 'STARTED');
			return this.save();
		}
	});

	App.SetupTodo.reopenClass({
		isAnyComplete: function (todos) {
			return todos.content.reduce(function (isOneComplete, setupTodo) {
				if (!isOneComplete && setupTodo.get('status') === 'COMPLETE') {
					return true;
				}

				return isOneComplete;
			}, false);
		},
		getIncomplete: function () {
			return this.find().then(function (todos) {
				return todos.content.filter(function (todo) {
					return todo.get('status') !== 'COMPLETE';
				});
			});
		},
		findAll: function (fullReload) {
			if (fullReload || !this._findAllCache) {
				this._findAllCache = this._super();
			}
			return this._findAllCache;
		}
	});

	// Unicard claims models
	var _UnicardClaim = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		providerName: DS.attr('string'),
		dateSubmitted: DS.attr('string'),
		receipts: DS.attr('string'),
		status: DS.attr('string'),
		displayStatus: DS.attr('string'),
		comments: DS.attr('string'),
		items: DS.hasMany('App.ClaimItem'),
		amount: Ember.computed.sumByProperty('items', 'price'),
		receiptList: function () {
			if (!this.get('receipts')) {
				return null;
			}

			return JSON.parse(this.get('receipts'));
		}.property('receipts'),
	});

	App.FsaClaim = _UnicardClaim.extend({
		isFsa: true,
		productCode: 'fsa',
		claimType: DS.attr('string'),
		dependent: DS.belongsTo('App.Dependent'),
		dependentName: DS.attr('string'),
		dependentDOB: DS.attr('string'),
		providerTaxID: DS.attr('string'),
		providerSSN: DS.attr('string'),
		isDependentClaim: Ember.computed.equal('claimType', 'dependent'),
		claimTypeDisplay: function () {
			if (this.get('claimType') == 'medical') {
				return "Health Care FSA";
			}

			if (this.get('claimType') == 'dependent') {
				return "Child & Elderly Care FSA";
			}

			return "";
		}.property('claimType'),
	});

	App.HraClaim = _UnicardClaim.extend({
		isHra: true,
		productCode: 'hra',
		dependent: DS.belongsTo('App.Dependent'),
		dependentName: DS.attr('string'),
		dependentDOB: DS.attr('string'),
	});

	App.CommuterClaim = _UnicardClaim.extend({
		isCommuter: true,
		productCode: 'commuter_benefits',
		claimType: DS.attr('string'),
		claimTypeDisplay: function () {
			return this.get('claimType').capitalize();
		}.property('claimType'),
	});

	App.ClaimItem = DS.Model.extend({
		datePurchase: DS.attr('string'),
		dateFrom: DS.attr('string'),
		dateTo: DS.attr('string'),
		description: DS.attr('string'),
		price: DS.attr('number'),
		receipts: DS.attr('raw'),
	});

	(function () {
		function getPolymorphicType(self, meta) {
			if (meta.options.isPolymorphic) {
				var modelName = Ember.get(self, '_data.' + meta.options.modelNameKey);
				var polymorphicModel = self.container.lookup('model:' + modelName);
				if (polymorphicModel) {
					return polymorphicModel.constructor;
				} else {
					console.error('Can not find polymorphic mapping for ', modelName);
				}
			}
		}

		Ember.Model.reopen({
			getBelongsTo: function (key, type, meta, store) {
				type = getPolymorphicType(this, meta) || type;
				var storeToPass = store;
				// make `belongsTo` relations in CLI models work with non-CLI models
				// by NOT using store to find related global model (ember-model.js#L1881-L1885)
				// e.g: for `belongsTo('App.User')` ember model will use global App.User.find
				if (typeof meta.type === 'string' && meta.type.indexOf('App.') === 0) {
					storeToPass = undefined;
				}
				return this._super(key, type, meta, storeToPass);
			}
		});

		Ember.Model.reopen({
			getHasMany: function (key, type, meta, container) {
				type = getPolymorphicType(this, meta) || type;
				return this._super(key, type, meta, container);
			}
		});
	})();

	App.CompanyDeductionHeuristics = DS.Model.extend({
		company_id: attr('number'),
		manageScorp: attr('string'),
		showDeductionReviewPage: attr('boolean'),
		adminReviewStartDate: attr('date'),
		adminControlCatchup: attr('boolean'),
		adminControlReimbursement: attr('boolean'),
		company: DS.belongsTo('App.Company'),
		manualCatchup: attr('boolean'),
		nWeeklyAsNWeekly: attr('boolean'),
		manageImputedLife: attr('boolean'),
		showImputedLifeOption: attr('boolean'),
		catchupMultiplierCap: attr('number'),
		payStubOptIn: attr('boolean'),
		catchupPercentPay: attr('string'),
		isUnShiftedCheckDateInNextMonth: attr('string'),
		isUnShiftedCheckDateForSM: attr('string'),
	});

	App.EmployeeDeductionHeuristics = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		deductionType: attr('string'),
		catchupMultiplierCap: attr('number'),
		payStubOptIn: attr('boolean'),
		catchupPercentPay: attr('string'),
		doDeltaCatchup: attr('boolean'),
		isUnShiftedCheckDateInNextMonth: attr('string'),
		reconciledDeduction: attr('number'),
		reconciledContribution: attr('number'),
		reconciledDate: attr('date'),
		resetDeduction: attr('number'),
		resetContribution: attr('number'),
		resetDate: attr('date'),
		isCatchupReasonable: attr('string'),
		hasUnreasonableCatchup: attr('boolean'),
		totalActualDeductionAmount: attr('number'),
		totalRequestedDeductionAmount: attr('number'),
		normalDeductionAmount: attr('number'),
		switchToBTsDate: attr('date'),
	});

	App.PayrollReconciledDeduction = DS.Model.extend({
		employee_id: attr('number'),
		deductionType: attr('string'),
		codEligible: attr('boolean'),
		company_id: attr('number'),
		totalActualDeductionAmount: attr('number'),
		totalRequestedDeductionAmount: attr('number'),
		totalActualContributionAmount: attr('number'),
		totalRequestedContributionAmount: attr('number'),
		normalDeductionAmount: attr('number'),
		normalContributionAmount: attr('number'),
		amountToCatchupPerPP: attr('number'),
		contributionAmountToCatchupPerPP: attr('number'),
		approvedCatchupThreshold: attr('number'),
		catchupCategory: attr('string'),
		logType: attr('string'),
		offsetMonth: attr('number'),
		offsetYear: attr('number'),
		offsetType: attr('string'),
		benefitsEventId: attr('number'),
		previousDeductionAmount: attr('number'),
		currentDeductionAmount: attr('number'),
	});

	App.Flow = DS.Model.extend({
		sections: DS.hasMany('App.FlowSection'),
		version_id: attr('number'),
		isActive: attr('boolean'),
		name: attr('string'),
		isComplete: attr('boolean'),
		dispatcherArgs: attr('string'),
	});

	App.FlowSection = DS.Model.extend({
		flow: DS.belongsTo('App.Flow'),
		errors: DS.hasMany('App.FlowError'),
		version_id: attr('number'),
		isActive: attr('boolean'),
		name: attr('string'),
		isComplete: attr('boolean'),
		isEntered: attr('boolean'),
		entered: attr('number'),
		isReady: attr('boolean'),
		index: attr('number'),
		isDone: Ember.computed.and('isComplete', 'isEntered'),
		dispatcherArgs: attr('string'),
		tag: attr('string'),
	});

	App.FlowError = DS.Model.extend({
		section: DS.belongsTo('App.FlowSection'),
		version_id: attr('number'),
		isActive: attr('boolean'),
		field: attr('string'),
		code: attr('string'),
		mustChangeValue: attr('string'),
		extra: attr('string'),
	});



	var _ZAppStatusEnum = {
		DELETED: 0,
		DISABLED: 10,
		INITIALIZING: 25,
		NOT_ENROLLED: 50,
		ENROLLING: 100,
		OK: 200
	};

	var _ZAppConstants = {
		PREF_SPOOFABLE: 'appInstall.app.preferences._spoofable',
	};


	App._DisableZappSaveMixin = Ember.Mixin.create({
		save: function () {
			//
			// Disable all the save calls to zapps, we shouldn't be making any save call to the server
			// for zapps, we don't support overriding the zapp data from the front-end.
			//
			// Backend doesn't support POST/PUT/DELETE/CREATE calls for zapp objects.
			//
		},
	});

	App.ZApp = DS.Model.extend(App._DisableZappSaveMixin, {
		uniqueId: attr('string'),
		packageId: attr('packageId'),
		developerId: attr('number'),
		title: attr('string'),
		shortTitle: attr('string'),
		delegate: attr('string'),
		preferences: attr('raw'),
		appUrl: attr('string'),
		appIconSqUrl: attr('string'),
		role: attr('string'),
		primaryCategory: attr('string'),
		secondaryCategory: attr('string'),
		status: attr('number'),
		autoSubMode: attr('string'),
		// TODO: autoSubPermissions needed here?
		createdAt: attr('date'),
		updatedAt: attr('date'),
		isPublishedRollout: attr('boolean'),
		scopes: attr('raw')
	});

	App.ZApp.reopenClass(_ZAppConstants, {
		UNPUBLISHED: 0,
		MAINTENANCE: 15,
		PUBLISHED: 200,
		noBatch: true,
		url: '/api/2/z_app/',
		developerUrl: '/api/zdeveloperapplication',
		findIntegrations: function () {
			return this.find({ category: 'integrations' });
		},
		findByOauthClientId: function (clientId) {
			return this.find({ oauthClientId: clientId }).then(function (apps) {
				return apps.popObject();
			});
		},
		findIntegrationDetail: function (uniqueId) {
			return Ember.ajaxGet(this.url + uniqueId).then(function (response) {
				return response;
			});
		},
		findIntegrationDeveloperApp: function (uniqueId) {
			return Ember.ajaxGet(this.developerUrl + "?name=" + uniqueId).then(function (response) {
				return response;
			});
		},
		findSubscribers: function (uniqueId) {
			return Ember.ajaxGet(this.url + uniqueId + "/subscribers").then(function (response) {
				return response;
			});
		},
		findSubscribersByCompanyId: function (uniqueId, companyId) {
			return Ember.ajaxGet(this.url + uniqueId + "/" + companyId + "/subscribers").then(function (response) {
				return response;
			});
		},
	});

	App.ZAppInstall = DS.Model.extend(App._DisableZappSaveMixin, {
		app: DS.belongsTo('App.ZApp', { embedded: true, key: 'app' }),
		companyId: attr('number'),
		preferences: attr('raw'),
		status: attr('number'),
		forceDisable: attr('boolean'),
		statusUpdateInProgress: attr('boolean', { defaultValue: false }),
		installStatusToTextMap: {
			0: 'Deleted',
			10: 'Disabled',
			25: 'Initializing',
			50: 'Not enrolled',
			100: 'Enrolling',
			200: 'OK'
		},
		isDisabled: function () {
			return this.get('status') === 10;
		}.property('status'),
		statusText: function () {
			var installStatus = this.get('status');
			var installStatusToTextMap = this.get('installStatusToTextMap');
			return (installStatusToTextMap[installStatus] || '-');
		}.property('status'),
		autoSubMode: attr('string'),
		installedAt: attr('date'),
		createdAt: attr('date'),
		updatedAt: attr('date'),
		uninstall: function () {
			var baseUrl = this.constructor.url;
			return Ember.ajaxPost(
				baseUrl + this.get('id') + '/uninstall/',
				{}
			).then(function () {
				return true;
			}.bind(this));
		},
		sync: function () {
			var baseUrl = this.constructor.url;
			return Ember.Model.adapter.ajax(
				baseUrl + this.get('id') + '/sync/',
				{},
				'POST'
			).then(function (data) {
				var dataToLoad = data['objects'][0];
				if (dataToLoad) {
					this.load(this.get('id'), dataToLoad);
				}
				return this;
			}.bind(this));
		},
		dismissHighlight: function () {
			var baseUrl = this.constructor.url;
			return Ember.ajaxPost(
				baseUrl + this.get('id') + '/dismiss_highlight/',
				{}
			).then(function () {
				return true;
			}).catch(function () { });
		},
		uninstallFeedback: function (data) {
			var baseUrl = this.constructor.url;
			return Ember.ajaxPost(
				baseUrl + this.get('id') + '/uninstall_feedback/',
				data
			).then(function () {
				return true;
			}).catch(function () { });
		},
	});

	App.ZAppInstall.reopenClass(_ZAppStatusEnum, {
		noBatch: true,
		url: '/api/2/z_app_install/',

		findInstall: function (uniqueId) {
			return Ember.ajaxGet(this.url + uniqueId).then(function (response) {
				return response;
			}).catch(function () { });
		},
		safeFindById: function (id) {
			return this.findAll().then(function (records) {
				return records.findBy('id', id);
			});
		},
		findAll: function (fullReload) {
			if (fullReload || !this._findAllCache) {
				this._findAllCache = this._super();
			}
			return this._findAllCache;
		}
	});

	App.ZAppActionUrl = DS.Model.extend({
		id: attr('number'),
		title: attr('raw'),
		actionUrl: attr('raw'),
		role: attr('raw')
	}).reopenClass({
		url: "/api/2/z_app_action/quick_links/"
	});


	App.ZAppInstallSubscription = DS.Model.extend(App._DisableZappSaveMixin, {
		appInstall: DS.belongsTo('App.ZAppInstall', { embedded: true, key: 'appInstall' }),
		companyId: attr('number'),
		employeeId: attr('number'),
		preferences: attr('raw'),
		status: attr('number'),
		inheritedStatus: attr('number'),
		subscribedAt: attr('date'),
		createdAt: attr('date'),
		updatedAt: attr('date'),
		sync: function () {
			var baseUrl = this.constructor.url;
			return Ember.Model.adapter.ajax(
				baseUrl + this.get('id') + '/sync/',
				{},
				'POST'
			).then(function (data) {
				var dataToLoad = data['objects'][0];
				if (dataToLoad) {
					this.load(this.get('id'), dataToLoad);
				}
				return this;
			}.bind(this));
		}
	});

	App.ZAppInstallSubscription.reopenClass(_ZAppStatusEnum, {
		noBatch: true,
		url: '/api/2/z_app_install_subscription/',
		findAll: function (fullReload) {
			if (fullReload || !this._findAllCache) {
				this._findAllCache = this._super();
			}
			return this._findAllCache;
		}
	});



	App.ZFlow = DS.Model.extend({
		appId: Ember.attr('string'),
		flowModules: DS.hasMany('App.ZFlowModule'),
		stages: DS.hasMany('App.ZFlowStage'),
		flowType: Ember.attr('string'),
		contextId: Ember.attr('number'),
		companyId: Ember.attr('number'),
		installId: Ember.attr('number'),
		personId: Ember.attr('number'),
		isRequired: Ember.attr('boolean'),
		data: Ember.attr('raw'),
		subscriptionId: Ember.attr('number'),
		writePermissionSettings: Ember.attr('raw'),
		readPermissionSettings: Ember.attr('raw'),
	}).reopenClass({
		noBatch: true
	});

	App.ZModule = DS.Model.extend({
		id: Ember.attr('number'),
		appId: Ember.attr('string'),
		spec: Ember.attr('raw'),
		linkTitle: Ember.attr('string'),
	});


	App.ZFlowModule = DS.Model.extend({
		flow: DS.belongsTo('App.ZFlow'),
		module: DS.belongsTo('App.ZModule'),
		flowModuleType: Ember.attr('string'),
		isComplete: Ember.attr('boolean'),
	});

	App.ZFlowStage = DS.Model.extend({
		stageType: Ember.attr('string'),
		flow: DS.belongsTo('App.ZFlow'),
		outcome: Ember.attr('string'),
		isClosed: Ember.attr('boolean'),
		advanceTrigger: Ember.attr('boolean'),
	});

	App.ZFlowAppSubscription = DS.Model.extend({
		status: Ember.attr('number'),
		statusMessageTemplate: Ember.attr('string'),
		statusCode: Ember.attr('string'),
		install: Ember.attr('raw'),
		app: Ember.attr('raw'),
		personId: Ember.attr('number'),
	});

	App.AdminDiscrepancyMeta = DS.Model.extend({
		draftReason: attr('string'),
		draftValidationErrors: attr('raw'),

		isAdminBasedDiscrepancy: function () {
			return this.get('draftReason') && this.get('draftReason').match(/^Admin/);
		}.property('draftReason'),
	});

	App.AdminReviewSuperSession = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		status: attr('string'),
		carrierToLineNames: attr('raw'),

		carrierToLineNamesStr: function () {
			var carrierToLineNamesStr = '';
			var carrierToLineNames = this.get('carrierToLineNames');
			return Object.keys(carrierToLineNames).reduce(function (retValue, value) {
				retValue.pushObject(value + ' [' + carrierToLineNames[value] + ']');
				return retValue;
			}, []).join(', ');
		}.property('carrierToLineNames'),
	});

	App.AdminReviewSession = DS.Model.extend({
		adminReviewSuperSession: DS.belongsTo('App.AdminReviewSuperSession'),
		draftSession_id: attr('number'),
		companyHealthCarrier: DS.belongsTo('App.CompanyHealthCarrier'),
		reviewTriggeredOn: attr('date'),
		reviewTriggeredBy: DS.belongsTo('user'),
		state: attr('string'),
		reviewTriggeredAtTime: function () {
			return this.get('reviewTriggeredOn') && moment(this.get('reviewTriggeredOn')).format('HH:mm:ss');
		}.property('reviewTriggeredOn'),
		reviewTriggeredOnDate: function () {
			return this.get('reviewTriggeredOn') && moment(this.get('reviewTriggeredOn')).format('MM/DD/YYYY');
		}.property('reviewTriggeredOn'),
	});

	App.EmployeeDiscrepancy = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		adminReviewSession: DS.belongsTo('App.AdminReviewSession'),
		questionMeta: DS.belongsTo('App.AdminDiscrepancyMeta'),
		draftEmployee_id: attr('string'),
		answer: attr('string'),
		status: attr('string'),
		type: attr('string'),
		question: attr('string'),
		answerType: attr('string'),
		answerOptionList: attr('raw'),
		employeeAttribute: attr('string'),
		stagedEmployee: DS.belongsTo('App.StagedEmployee'),
	}).reopenClass({
		noBatch: true
	});

	App.StagedEmployee = DS.Model.extend({
		employee: DS.belongsTo('App.AllEmployee'),
		first_name: attr('string'),
		last_name: attr('string'),
		adminReviewSuperSession: DS.belongsTo('App.AdminReviewSuperSession'),
		employmentStatus: attr('string'),
		employmentType: attr('string'),
		hireDate: attr('string'),
		medicalPlan: DS.belongsTo('App.Plan', { inverse: null }),
		medicalDependentCount: attr('number'),
		medicalStatus: attr('string'),
		dentalPlan: DS.belongsTo('App.DentalPlan', { inverse: null }),
		dentalDependentCount: attr('number'),
		dentalStatus: attr('string'),
		visionPlan: DS.belongsTo('App.VisionPlan', { inverse: null }),
		visionDependentCount: attr('number'),
		visionStatus: attr('string'),
		realizationState: DS.belongsTo('App.RealizationState'),

		fullName: function () {
			return (this.get('first_name') || "") + " " + (this.get('last_name') || "").trim();
		}.property('first_name', 'last_name'),

		// similar to isWithin30Days on Employee
		isRecentHire: function () {
			if (!this.get('hireDate')) {
				return false;
			}
			var now = moment();
			var hireDate = moment(this.get('hireDate'), "MM/DD/YYYY");
			var diff = now.diff(hireDate, 'days');
			return diff < 30;
		}.property('hireDate'),
	});

	App.OriginalStagedEmployee = App.StagedEmployee.extend();

	App.RealizationState = DS.Model.extend({
		stagedEmployee: DS.belongsTo('App.StagedEmployee'),
		realizationInputsJsonStr: attr('string'),
		// TODO(lei): only accessible to console-user
		followUpActionsJsonStr: attr('string'),
		signedOff: attr('boolean'),
		signedOffTimestamp: attr('date'),
		// signature objs related to some realization inputs
		signatures: DS.hasMany('App.Signature'),
		// document objs realted to some realization input
		documents: DS.hasMany('App.Document'),
		realizationInputs: function () {
			return JSON.parse(this.get('realizationInputsJsonStr'));
		}.property('realizationInputsJsonStr'),
		followUpActions: function () {
			return JSON.parse(this.get('followUpActionsJsonStr'));
		}.property('followUpActionsJsonStr'),
	}).reopenClass({
		noBatch: true
	});

	App.EtaTasks = DS.Model.extend({
		id: Ember.attr('string'),
		taskName: Ember.attr('string'),
		group: Ember.attr('string'),
		eta: Ember.attr('string'),
		queuedAt: Ember.attr('string'),
		started: Ember.attr('string'),
		finished: Ember.attr('string'),
		errorId: Ember.attr('string'),
		workflowId: Ember.attr('string'),
		swfRunId: Ember.attr('string'),
		swfDomain: Ember.attr('string'),
		queryCount: Ember.attr('string'),
	});

	App.HrAdvisor = DS.Model.extend({
		company_id: attr('number'),
		isEnrolled: attr('boolean'),
		hrAdvisorUserName: attr('string'),
		userGUId: attr('string'),
		allNewsAlerts: attr('string'),
		allHealthCareReformUpdates: attr('string'),
		searchUrl: attr('string'),
		activeFullTimeEmployeeCount: attr('number'),
		monthlyPlanPerEmployeeCharge: attr('number'),
		monthlyPlanBaseFee: attr('number'),
		yearlyPlanPerEmployeeCharge: attr('number'),
		yearlyPlanBaseFee: attr('number'),
		initialSeats: attr('number'),
		hrAdvisorToolLists: attr('raw'),
		newUpdates: attr('raw'),
		healthCareReformUpdates: attr('raw'),
	});

	App.HrAdvisorSignUp = DS.Model.extend({
		hrAdvisor: DS.belongsTo('App.HrAdvisor'),
		signature: DS.belongsTo('App.Signature'),
		signupEmployee: DS.belongsTo('App.AllEmployee'),
		planType: attr('string'),
		planToCancel: attr('boolean'),
		created: attr('date'),
		reasonToLeave: attr('string'),
		isContractTerminated: attr('boolean'),
	});

	App.ProductWaitingList = DS.Model.extend({
		company: DS.belongsTo('App.Company'),
		employee: DS.belongsTo('App.AllEmployee'),
		productType: attr('string'),
	});

	App.PlanGroupApi = DS.Model.extend({
		id: Ember.computed.alias('group'),
		group: attr('string'),
	});

	var _CountyRegionMappingBase = DS.Model.extend({
		region: attr('string'),
		county: attr('string'),
		zip: attr('string'),
		isAvailable: attr('boolean'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
	});

	App.CountyRegionMapping = _CountyRegionMappingBase.extend();

	App.DentalCountyRegionMapping = _CountyRegionMappingBase.extend();

	App.VisionCountyRegionMapping = _CountyRegionMappingBase.extend();

	var _ZipCodeRegionExceptionsBase = DS.Model.extend({
		zip: attr('string'),
		carrier: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		group: attr('string'),
		region: attr('string'),
		isAvailable: attr('boolean'),
		postACA: attr('boolean'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
	});

	App.ZipCodeRegionExceptions = _ZipCodeRegionExceptionsBase.extend();

	App.DentalZipCodeRegionExceptions = _ZipCodeRegionExceptionsBase.extend();

	App.VisionZipCodeRegionExceptions = _ZipCodeRegionExceptionsBase.extend();

	App.ZipCodeEmployeeAvailabilityMapping = DS.Model.extend({
		zip: attr('string'),
		carrier: attr('string'),
		county: attr('string'),
		stateCarrier: DS.belongsTo('App.Carrier'),
		group: attr('string'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
	});

	var _RateAbstractModel = DS.Model.extend({
		region: attr('string'),
		ageMin: attr('number'),
		ageMax: attr('number'),
		youPremium: attr('number'),
		youAndSpousePremium: attr('number'),
		youAndChildPremium: attr('number'),
		familyPremium: attr('number'),
		effectiveStartDate: attr('string'),
		effectiveEndDate: attr('string'),
	});

	App.Rate = _RateAbstractModel.extend({
		plan: DS.belongsTo('App.Plan'),
		medicareStatus: attr('string'),
	});

	App.DentalRate = _RateAbstractModel.extend({
		plan: DS.belongsTo('App.DentalPlan'),
		minLives: attr('number'),
		maxLives: attr('number'),
	});

	App.VisionRate = _RateAbstractModel.extend({
		plan: DS.belongsTo('App.VisionPlan'),
		minLives: attr('number'),
		maxLives: attr('number'),
	});

	App.ZipCodeCountyMappingApi = DS.Model.extend({
		county: attr('string'),
		state: attr('string'),
		zip: attr('string'),
	});

	App.CertificateOfCoverageDocument = DS.Model.extend({
		spdInfoObj: DS.belongsTo('App.SummaryPlanDescriptionInfo'),
		company_id: attr('number'),
		companyHealthEnrollment_id: attr('number'),
		companyHealthPlan_id: attr('number'),
		url: attr('string'),
		uploadTimestamp: attr('date'),
		planName: attr('string'),
		lineOfCoverage: attr('string'),
		fileName: attr('string'),
		docKey: attr('string'),
		carrierId: attr('number'),
	});

	App.SummaryPlanDescriptionDocument = DS.Model.extend({
		url: attr('string'),
		status: attr('string'),
		generatedTimestamp: attr('date'),
		fileName: attr('string'),
		spdInfo: attr('string'),
		cocDocuments: DS.hasMany('App.CertificateOfCoverageDocument'),
	});

	App.SummaryPlanDescriptionInfo = DS.Model.extend({
		company_id: attr('number'),
		haveFiledPlanNameWithDOL: attr('boolean'),
		planName: attr('string'),
		planAdminFirstName: attr('string'),
		planAdminLastName: attr('string'),
		planAdminTitle: attr('string'),
		planAdminAddress: attr('string'),
		planAdminAddress2: attr('string'),
		planAdminCity: attr('string'),
		planAdminState: attr('string'),
		planAdminZip: attr('string'),
		planAdminPhone: attr('string'),

		legalAgentName: attr('string'),
		legalAgentAddress: attr('string'),
		legalAgentAddress2: attr('string'),
		legalAgentCity: attr('string'),
		legalAgentState: attr('string'),
		legalAgentZip: attr('string'),
		legalAgentPhone: attr('string'),

		haveFSAWithZenefits: attr('boolean'),
		haveFSAWithOtherProvider: attr('boolean'),
		fsaPlanNumber: attr('string'),
		fsaProviderName: attr('string'),
		fsaProviderAddress: attr('string'),
		fsaProviderAddress2: attr('string'),
		fsaProviderCity: attr('string'),
		fsaProviderState: attr('string'),
		fsaProviderZip: attr('string'),
		fsaProviderPhone: attr('string'),

		haveHSAWithZenefits: attr('boolean'),
		haveHSAWithOtherProvider: attr('boolean'),
		hsaPlanNumber: attr('string'),
		hsaProviderName: attr('string'),
		hsaProviderAddress: attr('string'),
		hsaProviderAddress2: attr('string'),
		hsaProviderCity: attr('string'),
		hsaProviderState: attr('string'),
		hsaProviderZip: attr('string'),
		hsaProviderPhone: attr('string'),

		authSignatureId: attr('number'),
		isPlanYearCalendarYear: attr('boolean'),
		planYearStarting: attr('string'),
		planYearEnding: attr('string'),
		planNumber: attr('string'),

		progress: attr('string'),
		status: attr('string'),
		combinedUrl: attr('string'),
		generatedDate: attr('date'),

		cocDocuments: DS.hasMany('App.CertificateOfCoverageDocument'),
		childCompanies: attr('string'),
		coverageInfos: attr('string'),
		brokerContactInfo: attr('string'),
		coverageInfosJSON: function () {
			var coverageInfos = this.get('coverageInfos');
			return coverageInfos ? JSON.parse(coverageInfos) : [];
		}.property('coverageInfos'),
		carrierContactInfos: attr('string'),
		carrierContactInfosJSON: function () {
			var carrierContactInfos = this.get('carrierContactInfos');
			return carrierContactInfos ? JSON.parse(carrierContactInfos) : [];
		}.property('coverageInfos'),
		brokerContactInfoJSON: function () {
			var brokerContactInfo = this.get('brokerContactInfo');
			return brokerContactInfo ? JSON.parse(brokerContactInfo) : {};
		}.property('brokerContactInfo'),
	});

	App.SpdSignature = DS.Model.extend({
		signature: attr('string'),
		signeeName: attr('string'),
		url: attr('string'),
		createdBy: attr('number'),
		signatureDate: attr('string'),
	});

	/*

	!!! KEEP THIS DECLARATION AT THE END OF THE FILE !!!

	Any calls to registerAdapter (and possibly other store prototype modifying methods)
	must be done before the store is created so the proper objects are used.

	 */

	// App.store = App.Store.create({
	// 	adapter: App.CoalescingAdapter
	// });

})();
